import { CurrentProfile, ImageCarousel } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Button, EscapedHtmlSpan, useTheme } from "@equiem/react-admin-ui";
import { RiArrowRightSLine, RiGroupLine } from "@equiem/react-admin-ui/icons";
import React, { useContext } from "react";
import { BookingCalendar } from "./BookingCalendar";
import { BookingResourceFeatures } from "./BookingResourceFeatures";
import { BookingResourceLocation } from "./BookingResourceLocation";
import { BookingResourceRates } from "./BookingResourceRates";
import { BookingResourceVideo } from "./BookingResourceVideo";
import { BookingModal } from "../contexts/BookingModalContext";
import { BookingModalInfoProvider } from "../contexts/BookingModalInfoProvider";
import { isAutoApproveBookings } from "../libs/isAutoApproveBookings";
import { ValuePills } from "../../../components/ValuePills";
import { BookableResourceFeatureType } from "../../../generated/gateway-client";
import { resourceTypeToString } from "../../../lib/resourceTypeToString";

export const BookingResourceDetails: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { showExpansion: info, setShowExpansion } = useContext(BookingModal);
  const { profile } = useContext(CurrentProfile);

  if (info?.resource == null) {
    return null;
  }

  const hideCost = !isAutoApproveBookings(info.resource, profile?.companyV2?.uuid) && info.resource.hiddenCost;

  return (
    <BookingModalInfoProvider resource={info.resource} booking={info.booking}>
      <div className="details-cont">
        <div className="goback pb-3">
          <Button size="lg" variant="ghost" onClick={() => setShowExpansion(undefined)}>
            {t("common.hide")} <RiArrowRightSLine size={24} />
          </Button>
        </div>

        {info.resource.images.length > 0 && <ImageCarousel images={info.resource.images} />}

        <div className="section">
          <BookingResourceLocation showAddress={true} showLabel={true} className="pb-6" />
          <BookingResourceRates className="pb-6" displayOne={hideCost} />
          <div className="type-and-capacity">
            <ValuePills>{resourceTypeToString(info.resource.typeInfo.name, t)}</ValuePills>
            {info.resource.displayCapacity != null && info.resource.displayCapacity > 0 && (
              <span className="display-capacity-pill">
                <RiGroupLine />
                {t("bookings.resources.personCapacity", { count: info.resource.displayCapacity })}
              </span>
            )}
          </div>
          <EscapedHtmlSpan html={info.resource.description} />
        </div>

        <BookingResourceVideo className="section" />

        <BookingResourceFeatures
          className="section"
          featureTypes={[BookableResourceFeatureType.RoomFeatures]}
          showLabel={true}
        />

        <BookingResourceFeatures
          className="section"
          featureTypes={[BookableResourceFeatureType.SharedFacilities]}
          showLabel={true}
        />

        <BookingCalendar className="section" />
      </div>
      <style jsx>{`
        .type-and-capacity {
          padding: ${theme.spacers.s3} 0;
        }
        .display-capacity-pill {
          display: inline-flex;
          align-items: center;
          gap: ${theme.spacers.s2};
        }
        .display-capacity-pill:before {
          content: "•";
          margin: 0 ${theme.spacers.s3};
        }
        .details-cont {
          max-width: 800px;
          margin: 0 auto;
          padding: ${theme.spacers.s4};
        }
        @media screen and (min-width: ${theme.breakpoints.sm + 1}px) {
          .details-cont {
            padding: ${theme.spacers.s6};
          }
        }
        @media screen and (min-width: ${theme.breakpoints.xl + 1}px) {
          .details-cont {
            padding: ${theme.spacers.s8} ${theme.spacers.s6};
          }
        }
        .details-cont :global(.section) {
          border-bottom: 1px solid ${theme.colors.border};
          padding: ${theme.spacers.s6} ${theme.spacers.s5};
          font-size: 16px;
        }
        .details-cont :global(.section:last-child) {
          border: 0;
        }
        .goback {
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
      `}</style>
    </BookingModalInfoProvider>
  );
};
