import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  ConstraintNumber: { input: any; output: any; }
  /** for constraint directive */
  ConstraintString: { input: any; output: any; }
  /** DateTime type. Accepts a UNIX timestamp in milliseconds. */
  DateTime: { input: number; output: number; }
  /** Email type. Accepts a string and must follow a valid email format. */
  Email: { input: string; output: string; }
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: { input: any; output: any; }
  /** A valid JSON, transported as a string */
  JSON: { input: any; output: any; }
  /**
   * A JWT represented as a string.
   *
   * A JWT is a three-tuple of data where each element is Base64 encoded that is seperated by periods.
   * The tuple looks like (header, payload, signature).
   *
   * See also: https://jwt.io/
   */
  JWT: { input: any; output: any; }
  /** MobileNumber type. Accepts a string and must follow a valid e164 format. */
  MobileNumber: { input: any; output: any; }
  RGB: { input: any; output: any; }
  /** Time type. Accepts a string of format: HH:mm:ss */
  Time: { input: any; output: any; }
  TimeZone: { input: any; output: any; }
  /** URI type that accepts a URI string. Validations apply. */
  URI: { input: string; output: string; }
  /** A valid URL, transported as a string */
  URL: { input: any; output: any; }
  /** UUID type. Accepts a string and must follow a valid UUID format. */
  UUID: { input: string; output: string; }
  _FieldSet: { input: any; output: any; }
};

export type Ac1AccessGroupKind =
  /** All accessGroups */
  | 'ALL'
  /** Only accessGroups available for employees */
  | 'USER'
  /** Only accessGroups available for visitors */
  | 'VISITOR';

/** Connection between building and integration. Defines what is enabled for this integration (employees, visitors). */
export type Ac1BuildingIntegration = {
  __typename?: 'Ac1BuildingIntegration';
  ac1Integration: Ac1Integration;
  building: Building;
  employeesEnabled: Scalars['Boolean']['output'];
  visitorsEnabled: Scalars['Boolean']['output'];
};

/** Attributes for creating or updating a BuildingIntegration */
export type Ac1BuildingIntegrationAttributes = {
  buildingUuid: Scalars['UUID']['input'];
  employeesEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  visitorsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Company AC1 configuration for Site */
export type Ac1CompanySiteConfig = {
  __typename?: 'Ac1CompanySiteConfig';
  accessGroups?: Maybe<Array<AccessGroup>>;
  company: CompanyV2;
  /** Credential setup config for a Company in Site */
  companySiteCredentialSetupConfigs?: Maybe<Array<Ac1CompanySiteCredentialSetupConfig>>;
  /** Default AccessGroup that will be assigned to Company Users registered in Site */
  defaultAccessGroup?: Maybe<AccessGroup>;
  site: Destination;
  uuid: Scalars['UUID']['output'];
};

/** Company AC1 Credential Setup configuration for Site */
export type Ac1CompanySiteCredentialSetupConfig = {
  __typename?: 'Ac1CompanySiteCredentialSetupConfig';
  /** Number of available access passes */
  accessPassesAvailableNumber: Scalars['Int']['output'];
  /** 0 means disabled. Max 2_147_483_647 for unlimited */
  accessPassesMaxNumber: Scalars['Int']['output'];
  autoAssignment: Scalars['Boolean']['output'];
  company: CompanyV2;
  /** Credential/Access pass type bound with Company for Site */
  credentialSetup: Ac1CredentialSetup;
  /** If this AccessPass is enabled for company */
  enabled: Scalars['Boolean']['output'];
  site: Destination;
  uuid: Scalars['UUID']['output'];
};

/** Attributes for creating or updating a CompanyCredentialConfig */
export type Ac1CompanySiteCredentialSetupConfigInput = {
  /** requires property manager rights */
  accessPassesMaxNumber?: InputMaybe<Scalars['Int']['input']>;
  autoAssignment: Scalars['Boolean']['input'];
  credentialSetupUuid: Scalars['UUID']['input'];
  /** If checkbox for access pass type is marked or not */
  enabled: Scalars['Boolean']['input'];
};

/** A integration user credential invitation. */
export type Ac1CredentialInvitation = {
  __typename?: 'Ac1CredentialInvitation';
  createdDate?: Maybe<Scalars['ISO8601DateTime']['output']>;
  expirationDate?: Maybe<Scalars['ISO8601DateTime']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  invitationCode?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

/** General setup for credential to be used within a site. Pass name will display on mobile in AccessPasses */
export type Ac1CredentialSetup = {
  __typename?: 'Ac1CredentialSetup';
  category: Ac1CredentialSetupCategory;
  /** Present only for QrCode category, format ie. Wiegand */
  generator?: Maybe<Ac1QrCodeGenerator>;
  /** to fetch what integrations are bound to it */
  integrationCredentialSetups?: Maybe<Array<Ac1IntegrationCredentialSetup>>;
  passName: Scalars['String']['output'];
  site: Destination;
  uuid: Scalars['UUID']['output'];
  /** Present only for virtual category to point out integration */
  virtualCredentialsProvider?: Maybe<Ac1Integration>;
};

export type Ac1CredentialSetupCategory =
  /** Lisence plate readers to match cars to enter the parking */
  | 'license_plate'
  /** Physical card/mobs assigned to user */
  | 'physical'
  /** QrCode or barcode? for access readers, mostly for guests or as backup for parkings */
  | 'qr_code'
  /** Category for example for HID Card */
  | 'virtual';

/** HidOrigo credential */
export type Ac1HidOrigo = {
  __typename?: 'Ac1HidOrigo';
  enabled: Scalars['Boolean']['output'];
  invitation?: Maybe<Ac1CredentialInvitation>;
};

/** A single installation of a Provider's ACS in a single Site. */
export type Ac1Integration = {
  __typename?: 'Ac1Integration';
  accessPassName?: Maybe<Scalars['String']['output']>;
  buildingIntegrations: Array<Ac1BuildingIntegration>;
  config?: Maybe<Scalars['JSON']['output']>;
  destination: Destination;
  /**
   * It simplifies logical relations by esposing just employee credential setup,
   * without naviating through integration_credential_setups
   */
  employeeCredentialSetups?: Maybe<Array<Ac1CredentialSetup>>;
  externalApiBaseUrl: Scalars['URL']['output'];
  externalApiDataUrl?: Maybe<Scalars['URL']['output']>;
  externalApiSuffix?: Maybe<Scalars['String']['output']>;
  externalApiTokenSuffix?: Maybe<Scalars['String']['output']>;
  /**
   * Employee and visitor credential setup relation, for UI
   * employee_credential_setup and visitor_credential_setup seems to be more easy to handle
   */
  integrationCredentialSetups?: Maybe<Array<Ac1IntegrationCredentialSetup>>;
  name: Scalars['String']['output'];
  provider: Ac1Provider;
  uuid: Scalars['UUID']['output'];
  /**
   * It simplifies logical relations by esposing just visitor credential setups,
   * without navigating through integration_credential_setups
   */
  visitorCredentialSetups?: Maybe<Array<Ac1CredentialSetup>>;
};

/**
 * It points out for relation between integration and credential setup with
 * owner_type field, selecting if it is either for visitor or employee. On the same
 * QrCode/License plate type for visitors and employees there will be duplicated
 * relations with different owner_type
 */
export type Ac1IntegrationCredentialSetup = {
  __typename?: 'Ac1IntegrationCredentialSetup';
  /** It contains info about credential setup (license_plate/qr_code), pass_name and relation to virtual/qr_code format provider */
  credentialSetup?: Maybe<Ac1CredentialSetup>;
  /** It determines for who credential relation is for */
  ownerType: Ac1IntegrationCredentialSetupOwnerTypes;
  uuid: Scalars['UUID']['output'];
};

export type Ac1IntegrationCredentialSetupOwnerTypes =
  /** Defined credential setup for users */
  | 'employee'
  /** Defined credential setup for visitors */
  | 'visitor';

/** HidOrigo credential */
export type Ac1Iseo = {
  __typename?: 'Ac1Iseo';
  domainUrl?: Maybe<Scalars['String']['output']>;
  enabled: Scalars['Boolean']['output'];
  userToken?: Maybe<Scalars['String']['output']>;
};

/** Person that can have access granted (User or Visitor) */
export type Ac1Person = FullUser | Visitor;

/** Access control system provider. */
export type Ac1Provider = {
  __typename?: 'Ac1Provider';
  builtInVirtualCredentials?: Maybe<Scalars['Boolean']['output']>;
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

/** Qr Code credential */
export type Ac1QrCode = {
  __typename?: 'Ac1QrCode';
  enabled: Scalars['Boolean']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type Ac1QrCodeGenerator =
  /** Random number below 1_000_000 */
  | 'basic'
  /** Currently 103 facility code and random number for single issue */
  | 'wiegand_24bit';

/** SaltoKs type */
export type Ac1SaltoKs = {
  __typename?: 'Ac1SaltoKs';
  configuration?: Maybe<Ac1SaltoKsConfiguration>;
  enabled: Scalars['Boolean']['output'];
};

/** SaltoKs configuration */
export type Ac1SaltoKsConfiguration = {
  __typename?: 'Ac1SaltoKsConfiguration';
  /** Defines API KEY for either STAGING or PRODUCTION environment */
  apiKey: Scalars['String']['output'];
  /** Client id provided us from SALTO KS team */
  saltoClientId: Scalars['String']['output'];
  /** Secret provided us from SALTO KS team */
  saltoClientSecret?: Maybe<Scalars['String']['output']>;
  /** Url to identity server */
  saltoIdentityServerUrl: Scalars['String']['output'];
};

/** SaltoKs credential */
export type Ac1SaltoKsCredential = {
  __typename?: 'Ac1SaltoKsCredential';
  /** Digital key(mkey) key id */
  digitalKey: Scalars['String']['output'];
};

export type AcceptDestinationTermsInput = {
  destination: Scalars['UUID']['input'];
};

export type AccessControlSettings = {
  __typename?: 'AccessControlSettings';
  solution: AccessControlSolution;
};

export type AccessControlSettingsInput = {
  solution: AccessControlSolution;
};

export type AccessControlSolution =
  | 'ACCESS_ONE'
  | 'VICINITEE';

export type AccessControlSystem =
  | 'HID'
  | 'VICINITEE';

/** A composite access group for selection in the UI. It's mapped to proper access groups in the provider system(s). */
export type AccessGroup = {
  __typename?: 'AccessGroup';
  building: Building;
  employeesEnabled: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  uuid: Scalars['UUID']['output'];
  visitorsEnabled: Scalars['Boolean']['output'];
};

export type AccessPassMethod =
  | 'ble'
  | 'nfc'
  | 'unknown';

export type AccessPassMetricInput = {
  communicationWasSuccessful: Scalars['Boolean']['input'];
  integrationSystem: AccessPassSystem;
  openingType?: InputMaybe<OpeningType>;
  passMethod?: InputMaybe<AccessPassMethod>;
  readerId?: InputMaybe<Scalars['String']['input']>;
  siteUuid: Scalars['String']['input'];
  timestamp: Scalars['DateTime']['input'];
  userUuid: Scalars['String']['input'];
};

export type AccessPassSystem =
  | 'gallagher'
  | 'hid'
  | 'openpath'
  | 'swiftconnect'
  | 'vicinitee';

/** AC1 access permit for a specified time/access_group. */
export type AccessPermit = {
  __typename?: 'AccessPermit';
  accessGroup: AccessGroup;
  base: Scalars['Boolean']['output'];
  endsAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  person: Ac1Person;
  startsAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  syncStatus?: Maybe<SyncStatus>;
  uuid: Scalars['UUID']['output'];
};

export type ActionResource = {
  __typename?: 'ActionResource';
  action: Scalars['String']['output'];
  resourceType: Scalars['String']['output'];
};

export type ActionResult = FailureResponse | SuccessResponse;

export type Activity = {
  __typename?: 'Activity';
  audience: ActivityAudience;
  change?: Maybe<ActivityChange>;
  comment?: Maybe<Scalars['String']['output']>;
  entityType: Scalars['String']['output'];
  entityUuid: Scalars['UUID']['output'];
  timestamp: Scalars['DateTime']['output'];
  user?: Maybe<Profile>;
  uuid: Scalars['UUID']['output'];
};

export type ActivityAudience =
  | 'EVERYONE'
  | 'INTERNAL';

export type ActivityChange = ActivityMultiChange | ActivitySingleChange;

export type ActivityChangeBookableResourceValue = {
  __typename?: 'ActivityChangeBookableResourceValue';
  bookableResource?: Maybe<BookableResource>;
};

export type ActivityChangeBuildingLevelValue = {
  __typename?: 'ActivityChangeBuildingLevelValue';
  buildingLevel?: Maybe<BuildingLevel>;
};

export type ActivityChangeDateTimeValue = {
  __typename?: 'ActivityChangeDateTimeValue';
  dateTime?: Maybe<Scalars['DateTime']['output']>;
};

export type ActivityChangeDocumentValue = {
  __typename?: 'ActivityChangeDocumentValue';
  document?: Maybe<Document>;
};

export type ActivityChangeFieldType =
  | 'BOOKABLE_RESOURCE'
  | 'BUILDING_LEVEL'
  | 'DATE'
  | 'DOCUMENT'
  | 'FLEX_TENANT'
  | 'INTEGER'
  | 'PROFILE'
  | 'REQUEST_ATTACHMENTS'
  | 'REQUEST_CATEGORY'
  | 'REQUEST_PRIORITY'
  | 'REQUEST_STATUS'
  | 'REQUEST_SUBCATEGORY'
  | 'SPACE'
  | 'STRING';

export type ActivityChangeFlexTenantValue = {
  __typename?: 'ActivityChangeFlexTenantValue';
  flexTenant?: Maybe<FlexTenant>;
};

export type ActivityChangeInput = {
  field: Scalars['String']['input'];
  from: Array<Scalars['String']['input']>;
  multi: Scalars['Boolean']['input'];
  to: Array<Scalars['String']['input']>;
  type: ActivityChangeFieldType;
};

export type ActivityChangeIntegerValue = {
  __typename?: 'ActivityChangeIntegerValue';
  integer?: Maybe<Scalars['Int']['output']>;
};

export type ActivityChangeProfileValue = {
  __typename?: 'ActivityChangeProfileValue';
  profile?: Maybe<Profile>;
};

export type ActivityChangeReqMgtAttachments = {
  __typename?: 'ActivityChangeReqMgtAttachments';
  attachments?: Maybe<ReqMgtAttachments>;
};

export type ActivityChangeReqMgtCategoryValue = {
  __typename?: 'ActivityChangeReqMgtCategoryValue';
  category?: Maybe<ReqMgtCategory>;
};

export type ActivityChangeReqMgtStatusValue = {
  __typename?: 'ActivityChangeReqMgtStatusValue';
  status?: Maybe<ReqMgtStatus>;
};

export type ActivityChangeReqMgtSubCategoryValue = {
  __typename?: 'ActivityChangeReqMgtSubCategoryValue';
  subCategory?: Maybe<ReqMgtSubCategory>;
};

export type ActivityChangeSpaceValue = {
  __typename?: 'ActivityChangeSpaceValue';
  space?: Maybe<Space>;
};

export type ActivityChangeStringValue = {
  __typename?: 'ActivityChangeStringValue';
  value?: Maybe<Scalars['String']['output']>;
};

export type ActivityChangeValue = ActivityChangeBookableResourceValue | ActivityChangeBuildingLevelValue | ActivityChangeDateTimeValue | ActivityChangeDocumentValue | ActivityChangeFlexTenantValue | ActivityChangeIntegerValue | ActivityChangeProfileValue | ActivityChangeReqMgtAttachments | ActivityChangeReqMgtCategoryValue | ActivityChangeReqMgtStatusValue | ActivityChangeReqMgtSubCategoryValue | ActivityChangeSpaceValue | ActivityChangeStringValue;

export type ActivityConnection = {
  __typename?: 'ActivityConnection';
  edges: Array<ActivityEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ActivityEdge = {
  __typename?: 'ActivityEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Activity>;
};

/** Activity feed result can either be OrderHistoryV2 or EventHistoryV2 */
export type ActivityFeedResultV2 = EventHistoryV2 | OrderHistoryV2 | SessionHistoryV2 | SubscriptionHistoryV2;

export type ActivityMultiChange = {
  __typename?: 'ActivityMultiChange';
  field: Scalars['String']['output'];
  from: Array<ActivityChangeValue>;
  to: Array<ActivityChangeValue>;
  type: ActivityChangeFieldType;
};

export type ActivityOfServiceInput = {
  audience: ActivityAudience;
  change?: InputMaybe<ActivityChangeInput>;
  comment?: InputMaybe<Scalars['String']['input']>;
  entityType: Scalars['String']['input'];
  entityUuid: Scalars['UUID']['input'];
  timestamp?: InputMaybe<Scalars['DateTime']['input']>;
  userUuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type ActivityOfUserInput = {
  audience: ActivityAudience;
  comment?: InputMaybe<Scalars['String']['input']>;
  entityType: Scalars['String']['input'];
  entityUuid: Scalars['UUID']['input'];
};

export type ActivityRole =
  | 'ANON'
  | 'SERVICE'
  | 'USER';

export type ActivitySingleChange = {
  __typename?: 'ActivitySingleChange';
  field: Scalars['String']['output'];
  from?: Maybe<ActivityChangeValue>;
  to?: Maybe<ActivityChangeValue>;
  type: ActivityChangeFieldType;
};

export type ActivitySort = {
  asc: Scalars['Boolean']['input'];
  field: ActivitySortField;
};

export type ActivitySortField =
  | 'TIMESTAMP';

export type AddBuildingLevelInput = {
  buildingUuid: Scalars['UUID']['input'];
  name: Scalars['String']['input'];
};

export type AddCommentResult = {
  __typename?: 'AddCommentResult';
  comment: Comment;
  contentCommentCount: Scalars['Int']['output'];
  parent?: Maybe<Comment>;
};

export type AddLevelInput = {
  destinationUuid?: InputMaybe<Scalars['UUID']['input']>;
  name: Scalars['String']['input'];
};

export type AddLevelsResult = {
  __typename?: 'AddLevelsResult';
  errors: Array<Scalars['String']['output']>;
  levels: Array<Level>;
};

export type AddOrDeductContentPopularityScoreInput = {
  contentInteraction: ContentInteractionType;
  contentType: SearchContentType;
  contentUuid: Scalars['UUID']['input'];
  siteUuid: Scalars['UUID']['input'];
  userUuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type AddRsvpResult = RsvpFailure | RsvpSuccess;

export type AddSpaceInput = {
  buildingLevelUuid: Scalars['UUID']['input'];
  /** @deprecated Use VisibilityType instead. Will be removed eventually. */
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  ownerCompanyUuid?: InputMaybe<Scalars['UUID']['input']>;
  providerSpaceName?: InputMaybe<Scalars['String']['input']>;
  siteAudiences?: InputMaybe<Array<SpaceSiteAudienceInput>>;
  visibilityType?: InputMaybe<SpaceVisibilityType>;
};

export type AddToWalletMetricInput = {
  integrationSystem: AccessPassSystem;
  timestamp: Scalars['DateTime']['input'];
};

/** A stripe failure that can happen when adjusting an order */
export type AdjustmentFailure = IPaymentFailure & {
  __typename?: 'AdjustmentFailure';
  code: PaymentFailureCodes;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type AdminAccessControlSettingsInput = {
  solution: AdminAccessControlSolution;
};

export type AdminAccessControlSolution =
  | 'ACCESS_ONE'
  | 'VICINITEE';

export type AdminAccessControlSystem =
  | 'HID'
  | 'VICINITEE';

export type AdminBookingModuleSettingsInput = {
  commissionPercentage?: InputMaybe<Scalars['Float']['input']>;
  yardiEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AdminBookingNotificationPreferences = {
  __typename?: 'AdminBookingNotificationPreferences';
  approvalRequestEmail: Scalars['Boolean']['output'];
  cancellationEmail: Scalars['Boolean']['output'];
  confirmationEmail: Scalars['Boolean']['output'];
  declinedBookingEmail: Scalars['Boolean']['output'];
  updateEmail: Scalars['Boolean']['output'];
};

export type AdminBuildingInfoCategoryInput = {
  name: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['UUID']['input']>;
  weight: Scalars['Int']['input'];
};

export type AdminClientRegion =
  | 'AU'
  | 'CA'
  | 'EU'
  | 'US';

export type AdminCookieInput = {
  expiryPolicy: Scalars['String']['input'];
  name: Scalars['String']['input'];
  type: Scalars['String']['input'];
  whyItsUsed: Scalars['String']['input'];
};

export type AdminDestinationStatus =
  | 'DEACTIVATED'
  | 'HIDDEN'
  | 'LIVE';

export type AdminFeatureModuleType =
  | 'BOOKINGS'
  | 'REQUESTS'
  | 'VISITORS';

export type AdminFeatureModulesInput = {
  enableMenu?: InputMaybe<Scalars['Boolean']['input']>;
  label: Scalars['String']['input'];
  type: AdminFeatureModuleType;
};

export type AdminImageType =
  | 'GIF'
  | 'ICO'
  | 'JPEG'
  | 'PNG';

export type AdminNotification = {
  __typename?: 'AdminNotification';
  created: Scalars['DateTime']['output'];
  from?: Maybe<Profile>;
  icon?: Maybe<Scalars['URI']['output']>;
  linkToWidget?: Maybe<EquiemOneWidgetId>;
  read: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  to: Profile;
  url?: Maybe<Scalars['URI']['output']>;
  uuid: Scalars['UUID']['output'];
};

/** AdminNotification connection type */
export type AdminNotificationConnection = {
  __typename?: 'AdminNotificationConnection';
  edges: Array<Maybe<AdminNotificationEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
  unreadCount: Scalars['Int']['output'];
};

export type AdminNotificationEdge = {
  __typename?: 'AdminNotificationEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<AdminNotification>;
};

export type AdminNotificationInput = {
  fromUserUuid?: InputMaybe<Scalars['UUID']['input']>;
  icon?: InputMaybe<Scalars['URI']['input']>;
  title: Scalars['String']['input'];
  toUserUuid: Scalars['UUID']['input'];
  url?: InputMaybe<Scalars['URI']['input']>;
};

export type AdminParkingProvider =
  | 'NONE'
  | 'PARK_OFFICE'
  | 'UBIPARK';

export type AdminPermissions = {
  __typename?: 'AdminPermissions';
  /** @deprecated Equiem/console app deprecated */
  consoleDestinations: Array<Maybe<Destination>>;
  destinations: Array<Maybe<Destination>>;
  roles: Array<Scalars['String']['output']>;
  sites: Array<Scalars['UUID']['output']>;
};

export type AdminPlatformType =
  | 'MOBILE'
  | 'WEB';

export type AdminProfileStatus =
  | 'ACTIVE'
  | 'DEACTIVATED'
  | 'PENDING_APPROVAL';

export type AdminRegistrationType =
  | 'COMMERCIAL'
  | 'RESIDENTIAL'
  | 'VISITOR';

export type AdminRole =
  | 'AUTHENTICATED';

export type AdminSocialLinkInput = {
  type: AdminSocialLinkType;
  value: Scalars['String']['input'];
};

export type AdminSocialLinkType =
  | 'EMAIL'
  | 'FACEBOOK'
  | 'INSTAGRAM'
  | 'LINKEDIN'
  | 'OTHER'
  | 'PHONE'
  | 'TWITTER';

export type AdminUiWidgetType =
  | 'BOOKING'
  | 'CURATED'
  | 'DISCOUNT'
  | 'EVENTS'
  | 'FEATURED'
  | 'FORME'
  | 'NEWS'
  | 'POPULAR'
  | 'QUICKLINKS'
  | 'REORDER'
  | 'TRANSPORT'
  | 'TRENDING';

export type AdminUpdateDestinationLegalInput = {
  cookiePolicy?: InputMaybe<Scalars['String']['input']>;
  cookiePolicyChangeReason?: InputMaybe<Scalars['String']['input']>;
  privacyPolicy?: InputMaybe<Scalars['String']['input']>;
  privacyPolicyChangeReason?: InputMaybe<Scalars['String']['input']>;
  termsAndConditions?: InputMaybe<Scalars['String']['input']>;
  termsAndConditionsChangeReason?: InputMaybe<Scalars['String']['input']>;
  uuid: Scalars['UUID']['input'];
};

export type AdminUpsertDestinationInput = {
  accessControl?: InputMaybe<AdminAccessControlSystem>;
  accessControlSettings?: InputMaybe<AdminAccessControlSettingsInput>;
  accessControls?: InputMaybe<Array<AdminAccessControlSystem>>;
  address: Scalars['String']['input'];
  androidAppId?: InputMaybe<Scalars['String']['input']>;
  appHeroImage?: InputMaybe<Scalars['URI']['input']>;
  autoDeactivate3MonthReminder?: InputMaybe<Scalars['Boolean']['input']>;
  autoDeactivate6MonthReminder?: InputMaybe<Scalars['Boolean']['input']>;
  autoDeactivate9MonthReminder?: InputMaybe<Scalars['Boolean']['input']>;
  autoDeactivateUsers?: InputMaybe<Scalars['Boolean']['input']>;
  bookingModuleSettings?: InputMaybe<AdminBookingModuleSettingsInput>;
  buildingInfoCategories?: InputMaybe<Array<AdminBuildingInfoCategoryInput>>;
  buildingInfoHeaderImage?: InputMaybe<Scalars['URI']['input']>;
  buildingInfoTitle?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  client: Scalars['UUID']['input'];
  commercialSignupCTA?: InputMaybe<Scalars['String']['input']>;
  commercialSignupEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  cookiePolicy?: InputMaybe<Scalars['String']['input']>;
  cookiePolicyChangeReason?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  customStylesheet?: InputMaybe<Scalars['URI']['input']>;
  defaultIrisAssignee?: InputMaybe<Scalars['UUID']['input']>;
  defaultIrisAuthor?: InputMaybe<Scalars['UUID']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  emailFooterImage?: InputMaybe<Scalars['URI']['input']>;
  emailHeaderImage?: InputMaybe<Scalars['URI']['input']>;
  emailVerificationRequired?: InputMaybe<Scalars['Boolean']['input']>;
  enableReporting?: InputMaybe<Scalars['Boolean']['input']>;
  featureModules?: InputMaybe<Array<AdminFeatureModulesInput>>;
  hiddenStatusMessage: Scalars['String']['input'];
  iosAppBuildId?: InputMaybe<Scalars['String']['input']>;
  iosAppId?: InputMaybe<Scalars['String']['input']>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  liveChatPK?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<Scalars['URI']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  managedByUCM: Scalars['Boolean']['input'];
  marketingOptinLabel?: InputMaybe<Scalars['String']['input']>;
  marketplaceTitle?: InputMaybe<Scalars['String']['input']>;
  migratingToUCM?: InputMaybe<Scalars['Boolean']['input']>;
  mobileColor?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  newsAndEventsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  newsletterFooter?: InputMaybe<Scalars['String']['input']>;
  parkingProvider?: InputMaybe<AdminParkingProvider>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  primaryColor?: InputMaybe<Scalars['String']['input']>;
  privacyPolicy?: InputMaybe<Scalars['String']['input']>;
  privacyPolicyChangeReason?: InputMaybe<Scalars['String']['input']>;
  residentialSignupCTA?: InputMaybe<Scalars['String']['input']>;
  residentialSignupEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  showLogoOnWhiteBackground?: InputMaybe<Scalars['Boolean']['input']>;
  socialLinks?: InputMaybe<Array<AdminSocialLinkInput>>;
  state?: InputMaybe<Scalars['String']['input']>;
  status: AdminDestinationStatus;
  storeEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  storeMobileEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  storeMobileModuleEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  storeWebEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  storeWebModuleEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  streetName?: InputMaybe<Scalars['String']['input']>;
  termsAndConditions?: InputMaybe<Scalars['String']['input']>;
  termsAndConditionsChangeReason?: InputMaybe<Scalars['String']['input']>;
  textOverAppHeroImage?: InputMaybe<Scalars['Boolean']['input']>;
  textOverBuildingPageImage?: InputMaybe<Scalars['Boolean']['input']>;
  textOverHomePageImage?: InputMaybe<Scalars['Boolean']['input']>;
  thirdPartyCookies?: InputMaybe<Array<AdminCookieInput>>;
  thirdPartyServiceProviders?: InputMaybe<Scalars['String']['input']>;
  tierLevel?: InputMaybe<DestinationTierLevel>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  uuid: Scalars['UUID']['input'];
  visitorCanSubscribeToEmails?: InputMaybe<Scalars['Boolean']['input']>;
  visitorCanSubscribeToNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  visitorSignupCTA?: InputMaybe<Scalars['String']['input']>;
  visitorSignupEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  webBrowserIconImage?: InputMaybe<Scalars['URI']['input']>;
  webBuildingPageHeaderImage?: InputMaybe<Scalars['URI']['input']>;
  webFooterLogoImage?: InputMaybe<Scalars['URI']['input']>;
  webHomePageHeaderImage?: InputMaybe<Scalars['URI']['input']>;
  webSiteLogoImage?: InputMaybe<Scalars['URI']['input']>;
  webSiteLogoImageSize?: InputMaybe<Scalars['String']['input']>;
  webWelcomePageImage?: InputMaybe<Scalars['URI']['input']>;
  welcomePageHeading?: InputMaybe<Scalars['String']['input']>;
  welcomePageSubHeading?: InputMaybe<Scalars['String']['input']>;
};

export type AdminUpsertHomeUiWidgetInput = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  type: AdminUiWidgetType;
};

export type AdminUpsertHomeUiWidgetInputV2 = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  type: AdminUiWidgetType;
  uuid: Scalars['UUID']['input'];
};

export type AdminWebDomainInput = {
  domain: Scalars['String']['input'];
  primary: Scalars['Boolean']['input'];
  redirect?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Aggregations = {
  __typename?: 'Aggregations';
  activeUsers: IntMetric;
  attendancePerUser: FloatMetric;
  attendees: IntMetric;
  avgClickRate: FloatMetric;
  avgEntityViewsPerUser: FloatMetric;
  avgOpenRate: FloatMetric;
  avgPageViewsPerUser: FloatMetric;
  avgSpendPerUser: FloatMetric;
  currentUsers: IntMetric;
  entityViews: IntMetric;
  newslettersSent: IntMetric;
  pageViews: IntMetric;
  registeredUsers: IntMetric;
  totalRevenue: FloatMetric;
};

export type AggregationsV2 = {
  __typename?: 'AggregationsV2';
  activeUsers: PeriodMetric;
  emailClicked: PeriodMetric;
  emailOpened: PeriodMetric;
  eventRSVPs: PeriodMetric;
  leastActiveCompany?: Maybe<Scalars['String']['output']>;
  mostActiveCompany?: Maybe<Scalars['String']['output']>;
  registeredUsers: PeriodMetric;
};


export type AggregationsV2ActiveUsersArgs = {
  companyUuid?: InputMaybe<Scalars['UUID']['input']>;
  periodInDays: Scalars['Int']['input'];
  siteUuid: Scalars['UUID']['input'];
};


export type AggregationsV2EmailClickedArgs = {
  companyUuid?: InputMaybe<Scalars['UUID']['input']>;
  periodInDays: Scalars['Int']['input'];
  siteUuid: Scalars['UUID']['input'];
};


export type AggregationsV2EmailOpenedArgs = {
  companyUuid?: InputMaybe<Scalars['UUID']['input']>;
  periodInDays: Scalars['Int']['input'];
  siteUuid: Scalars['UUID']['input'];
};


export type AggregationsV2EventRsvPsArgs = {
  companyUuid?: InputMaybe<Scalars['UUID']['input']>;
  periodInDays: Scalars['Int']['input'];
  siteUuid: Scalars['UUID']['input'];
};


export type AggregationsV2LeastActiveCompanyArgs = {
  periodInDays: Scalars['Int']['input'];
  siteUuid: Scalars['UUID']['input'];
};


export type AggregationsV2MostActiveCompanyArgs = {
  periodInDays: Scalars['Int']['input'];
  siteUuid: Scalars['UUID']['input'];
};


export type AggregationsV2RegisteredUsersArgs = {
  companyUuid?: InputMaybe<Scalars['UUID']['input']>;
  periodInDays: Scalars['Int']['input'];
  siteUuid: Scalars['UUID']['input'];
};

export type AiChatMessage = {
  __typename?: 'AiChatMessage';
  content: Array<AiChatMessageContent>;
  id: Scalars['String']['output'];
  role: Scalars['String']['output'];
};

export type AiChatMessageContent = AiChatMessageContentImage | AiChatMessageContentText;

export type AiChatMessageContentImage = {
  __typename?: 'AiChatMessageContentImage';
  fileId: Scalars['String']['output'];
};

export type AiChatMessageContentText = {
  __typename?: 'AiChatMessageContentText';
  value: Scalars['String']['output'];
};

export type AiChatResponse = {
  __typename?: 'AiChatResponse';
  lastMessageId: Scalars['String']['output'];
  messages: Array<AiChatMessage>;
  threadId: Scalars['ID']['output'];
};

export type AiChatSuggestion = {
  __typename?: 'AiChatSuggestion';
  category: Scalars['String']['output'];
  message: Scalars['String']['output'];
  uuid: Scalars['UUID']['output'];
};

export type AiRole =
  | 'ANON'
  | 'SERVICE'
  | 'USER';

/** A customer's profile. */
export type AllSitesProfileInput = {
  /** The customer's avatar. */
  active?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether the customer is active (or blocked). */
  avatar?: InputMaybe<Scalars['String']['input']>;
  /** The customer's job title. */
  company?: InputMaybe<Scalars['UUID']['input']>;
  /** The date when the profile was created. */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  /** A customers location eg. desk level building */
  deliveryLocation?: InputMaybe<Scalars['String']['input']>;
  /** The display name of the profile. */
  displayName?: InputMaybe<Scalars['String']['input']>;
  /** The customer's email. */
  email: Scalars['Email']['input'];
  /** The first name of the profile. */
  firstName: Scalars['String']['input'];
  /** The UUID of the company to which this customer belongs. */
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  /** The last name of the profile. */
  lastName: Scalars['String']['input'];
  /** The customer's mobile number. */
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  /** Permission assigned to this profile from UCM */
  newWorldPermissions?: InputMaybe<Array<Role>>;
  /** Permission assigned to this profile */
  permissions?: InputMaybe<Array<Scalars['String']['input']>>;
  /** The customer's phone number. */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** Whether or not the profile can be viewed by other customers. */
  public: Scalars['Boolean']['input'];
  /** Site specific profile information */
  siteProfiles: Array<SiteProfileInput>;
  /** Status is replacing active and wasActivated to instead be pending_approval, active, deactivated */
  status?: InputMaybe<ProfileStatus>;
  /** The customers Stripe account id. */
  stripeCustomerId?: InputMaybe<Scalars['String']['input']>;
  /** Unique identifier for the profile. */
  uuid: Scalars['UUID']['input'];
};

/** A customer's profile. */
export type AllSitesProfileInputV2 = {
  /** The customer's avatar. */
  active?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether the customer is active (or blocked). */
  avatar?: InputMaybe<Scalars['String']['input']>;
  /** The customer's job title. */
  company?: InputMaybe<Scalars['UUID']['input']>;
  /** The date when the profile was created. */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  /** A customers location eg. desk level building */
  deliveryLocation?: InputMaybe<Scalars['String']['input']>;
  /** The display name of the profile. */
  displayName?: InputMaybe<Scalars['String']['input']>;
  /** The customer's email. */
  email: Scalars['Email']['input'];
  /** The first name of the profile. */
  firstName: Scalars['String']['input'];
  /** The UUID of the company to which this customer belongs. */
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  /** The last name of the profile. */
  lastName: Scalars['String']['input'];
  /** The customer's mobile number. */
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  /** Permission assigned to this profile from UCM */
  newWorldPermissions?: InputMaybe<Array<Role>>;
  /** Permission assigned to this profile */
  permissions?: InputMaybe<Array<Scalars['String']['input']>>;
  /** The customer's phone number. */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** Whether or not the profile can be viewed by other customers. */
  public: Scalars['Boolean']['input'];
  /** Site specific profile information */
  siteProfiles: Array<SiteProfileInputV2>;
  /** Status is replacing active and wasActivated to instead be pending_approval, active, deactivated */
  status?: InputMaybe<ProfileStatus>;
  /** The customers Stripe account id. */
  stripeCustomerId?: InputMaybe<Scalars['String']['input']>;
  /** Unique identifier for the profile. */
  uuid: Scalars['UUID']['input'];
};

/** Represents the mutations allowed for a specific order. */
export type AllowedOrderActions = {
  __typename?: 'AllowedOrderActions';
  adjust: Scalars['Boolean']['output'];
  cancelSubscription: Scalars['Boolean']['output'];
  /** The next logical status for in a normal, successful workflow (eg. if Order is NEW, then IN_PROGRESS). */
  nextStatus?: Maybe<OrderStatus>;
  refund: Scalars['Boolean']['output'];
  /** Only applicable to orders with parking products atm. */
  retryFailedPayment: Scalars['Boolean']['output'];
  retrySubscriptionCharge: Scalars['Boolean']['output'];
  /** All the status updates which are allowed for the current user (eg. If Order is NEW, [IN_PROGRESS, CANCELLED, REJECTED]). */
  statusUpdates: Array<OrderStatus>;
};

/** Represents a list of mutation end points for Analytics. */
export type Analytics = {
  __typename?: 'Analytics';
  affiliateClick: MutationResult;
  enterCarpark: MutationResult;
  exitCarpark: MutationResult;
  viewDeal: MutationResult;
  /** A product has been viewed. */
  viewProduct: MutationResult;
  /** A vendor has been viewed. */
  viewVendor: MutationResult;
};


/** Represents a list of mutation end points for Analytics. */
export type AnalyticsAffiliateClickArgs = {
  dealUuid: Scalars['UUID']['input'];
  timeMs: Scalars['DateTime']['input'];
};


/** Represents a list of mutation end points for Analytics. */
export type AnalyticsEnterCarparkArgs = {
  beaconName: Scalars['String']['input'];
  timeMs: Scalars['DateTime']['input'];
};


/** Represents a list of mutation end points for Analytics. */
export type AnalyticsExitCarparkArgs = {
  beaconName: Scalars['String']['input'];
  timeMs: Scalars['DateTime']['input'];
};


/** Represents a list of mutation end points for Analytics. */
export type AnalyticsViewDealArgs = {
  dealUuid: Scalars['UUID']['input'];
  timeMs: Scalars['DateTime']['input'];
};


/** Represents a list of mutation end points for Analytics. */
export type AnalyticsViewProductArgs = {
  productUuid: Scalars['UUID']['input'];
  timeMs: Scalars['DateTime']['input'];
};


/** Represents a list of mutation end points for Analytics. */
export type AnalyticsViewVendorArgs = {
  timeMs: Scalars['DateTime']['input'];
  vendorUuid: Scalars['UUID']['input'];
};

export type AnalyticsViewContentInput = {
  contentUuid: Scalars['UUID']['input'];
  navPosition?: InputMaybe<Scalars['Int']['input']>;
  navSource?: InputMaybe<Scalars['String']['input']>;
  timestamp: Scalars['DateTime']['input'];
};

export type AndroidImages = {
  __typename?: 'AndroidImages';
  appIcon?: Maybe<Scalars['String']['output']>;
  compressedUrl?: Maybe<Scalars['String']['output']>;
  featureGraphic?: Maybe<Scalars['String']['output']>;
  myAccountLarge?: Maybe<Scalars['String']['output']>;
  myAccountLargest?: Maybe<Scalars['String']['output']>;
  myAccountMedium?: Maybe<Scalars['String']['output']>;
  myAccountSmall?: Maybe<Scalars['String']['output']>;
  myAccountSmallest?: Maybe<Scalars['String']['output']>;
  pushNotification?: Maybe<Scalars['String']['output']>;
};

export type AndroidImagesInput = {
  appIcon?: InputMaybe<Scalars['String']['input']>;
  featureGraphic?: InputMaybe<Scalars['String']['input']>;
  myAccountLarge?: InputMaybe<Scalars['String']['input']>;
  myAccountLargest?: InputMaybe<Scalars['String']['input']>;
  myAccountMedium?: InputMaybe<Scalars['String']['input']>;
  myAccountSmall?: InputMaybe<Scalars['String']['input']>;
  myAccountSmallest?: InputMaybe<Scalars['String']['input']>;
  pushNotification?: InputMaybe<Scalars['String']['input']>;
};

export type AnonymousLikeMetricInput = {
  appVersion?: InputMaybe<Scalars['String']['input']>;
  applicationId: Scalars['String']['input'];
  contentType: ContentType;
  contentUuid: Scalars['UUID']['input'];
  id: Scalars['UUID']['input'];
  siteUuid: Scalars['UUID']['input'];
  timestamp: Scalars['DateTime']['input'];
  type: LikeOperationType;
  userAgent?: InputMaybe<Scalars['String']['input']>;
};

export type Apartment = {
  __typename?: 'Apartment';
  apartmentDestinations: Array<ApartmentDestination>;
  destinationCount: Scalars['Int']['output'];
  destinations: Array<Destination>;
  name: Scalars['String']['output'];
  uuid: Scalars['UUID']['output'];
};

export type ApartmentConnection = {
  __typename?: 'ApartmentConnection';
  edges: Array<ApartmentEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ApartmentDestination = {
  __typename?: 'ApartmentDestination';
  apartment: Apartment;
  apartmentUuid: Scalars['UUID']['output'];
  buildingLevels: Array<BuildingLevel>;
  destination?: Maybe<Destination>;
  destinationUuid: Scalars['UUID']['output'];
  users: Array<FullUser>;
};

export type ApartmentDestinationInput = {
  buildingLevels?: InputMaybe<Array<Scalars['UUID']['input']>>;
  destinationUuid: Scalars['UUID']['input'];
};

export type ApartmentEdge = {
  __typename?: 'ApartmentEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Apartment>;
};

export type ApartmentFailure = {
  __typename?: 'ApartmentFailure';
  localisedReason?: Maybe<LocalisedMessage>;
  reason: Scalars['String']['output'];
};

export type ApartmentInput = {
  apartmentDestination?: InputMaybe<ApartmentDestinationInput>;
  destinations?: InputMaybe<Array<Scalars['UUID']['input']>>;
  name: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type ApartmentResult = ApartmentFailure | ApartmentSuccess;

export type ApartmentSort = {
  asc: Scalars['Boolean']['input'];
  field: ApartmentSortField;
};

export type ApartmentSortField =
  | 'APARTMENT_NAME';

export type ApartmentSuccess = {
  __typename?: 'ApartmentSuccess';
  apartment: Apartment;
};

export type AppEdition = {
  __typename?: 'AppEdition';
  androidAppId: Scalars['String']['output'];
  deepLinkUrl: Scalars['String']['output'];
  dynamicLinkUrl: Scalars['String']['output'];
  iosAppBuildId: Scalars['String']['output'];
  iosAppId: Scalars['String']['output'];
  uuid: Scalars['UUID']['output'];
};

export type AppleApnsKeyInput = {
  certificate: Scalars['String']['input'];
  keyId: Scalars['String']['input'];
};

export type AppleStoreKeyInput = {
  certificate: Scalars['String']['input'];
  issuerId: Scalars['String']['input'];
  keyId: Scalars['String']['input'];
};

export type AppleStoreStatus =
  | 'API_NOT_AUTHORIZED'
  | 'INVALID_BINARY'
  | 'IN_REVIEW'
  | 'LIVE'
  | 'MEMBERSHIP_EXPIRED'
  | 'MEMBERSHIP_EXPIRES'
  | 'METADATA_REJECTED'
  | 'PENDING_AGREEMENT'
  | 'PENDING_RELEASE'
  | 'PREPARE_SUBMISSION'
  | 'STORE_REJECTED'
  | 'UNKNOWN'
  | 'WAITING_FOR_REVIEW';

export type AppointmentsExportCsvInput = {
  /**
   * If specified, and the user is authorised, then the appointments returned
   * will be all those accessible to the user as the given role. Otherwise, only
   * the user's own appointments will be returned.
   */
  as?: InputMaybe<VisitorAppointmentsQueryRole>;
  beginDate?: InputMaybe<Scalars['DateTime']['input']>;
  buildingUuids?: InputMaybe<Array<Scalars['UUID']['input']>>;
  companyUuid?: InputMaybe<Scalars['UUID']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  hostCompanyUuids?: InputMaybe<Array<Scalars['UUID']['input']>>;
  isRecurring?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<VisitorAppointmentsSortInput>>;
  viewRole?: InputMaybe<VisitorAppointmentsQueryRole>;
  viewScope?: InputMaybe<AppointmentsViewScope>;
  visitorTypeUuids?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type AppointmentsViewScope =
  | 'ALL'
  | 'MY';

export type ApprovalNotificationsPreferences = {
  __typename?: 'ApprovalNotificationsPreferences';
  awaitingApproval: Scalars['Boolean']['output'];
};

export type ApprovalNotificationsPreferencesInput = {
  awaitingApproval: Scalars['Boolean']['input'];
};

export type ApproveUserInviteRegistrationResult = ApproveUserInviteRegistrationResultFailure | ApproveUserInviteRegistrationResultSuccess;

export type ApproveUserInviteRegistrationResultFailure = {
  __typename?: 'ApproveUserInviteRegistrationResultFailure';
  code?: Maybe<Scalars['String']['output']>;
  reason: Scalars['String']['output'];
};

export type ApproveUserInviteRegistrationResultSuccess = {
  __typename?: 'ApproveUserInviteRegistrationResultSuccess';
  invite: Invite;
};

export type ArchiveCmsArticleInput = {
  article: Scalars['UUID']['input'];
};

export type Area = {
  __typename?: 'Area';
  buildingLevels: Array<BuildingLevel>;
  name: Scalars['String']['output'];
  uuid: Scalars['UUID']['output'];
};

export type AreaSyncFailure = {
  __typename?: 'AreaSyncFailure';
  reason: Scalars['String']['output'];
};

export type AreaSyncResult = AreaSyncFailure | AreaSyncSuccess;

export type AreaSyncSuccess = {
  __typename?: 'AreaSyncSuccess';
  area: Area;
};

export type Article = {
  __typename?: 'Article';
  author?: Maybe<Profile>;
  body: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  downloads: Array<DocumentV2>;
  excerpt?: Maybe<Scalars['String']['output']>;
  images: Array<DocumentV2>;
  publishDate: Scalars['DateTime']['output'];
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid: Scalars['UUID']['output'];
};

export type ArticleApiRole =
  | 'ANON'
  | 'SERVICE'
  | 'USER';

export type ArticleConnection = {
  __typename?: 'ArticleConnection';
  edges: Array<ArticleEdge>;
  pageInfo: PageInfo;
};

export type ArticleEdge = ContentEdge & {
  __typename?: 'ArticleEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Article>;
};

export type ArticleInput = {
  articleUuid: Scalars['UUID']['input'];
  authorUuid?: InputMaybe<Scalars['UUID']['input']>;
  body: Scalars['String']['input'];
  createdAt: Scalars['DateTime']['input'];
  downloads?: InputMaybe<Array<DocumentInput>>;
  excerpt?: InputMaybe<Scalars['String']['input']>;
  images?: InputMaybe<Array<DocumentInput>>;
  publishDateInUtc: Scalars['DateTime']['input'];
  published: Scalars['Boolean']['input'];
  segmentIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  site: Scalars['UUID']['input'];
  siteArticleUuid: Scalars['UUID']['input'];
  title: Scalars['String']['input'];
  updatedAt: Scalars['DateTime']['input'];
};

export type AssignProfileToFlexTenantInput = {
  flexTenantUuid: Scalars['UUID']['input'];
  profileUuid: Scalars['UUID']['input'];
};

export type AssignUserToApartmentInput = {
  apartmentUuid: Scalars['UUID']['input'];
  destinationUuid: Scalars['UUID']['input'];
  /** @deprecated No longer relevant */
  overrideExistingUser?: InputMaybe<Scalars['Boolean']['input']>;
  userUuid: Scalars['UUID']['input'];
};

export type AssociateRoleToUserInput = {
  resources: Array<ResourceInput>;
  roleId: Scalars['UUID']['input'];
  userUuid: Scalars['UUID']['input'];
};

export type AssociationValidation = {
  __typename?: 'AssociationValidation';
  label?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  values: Array<AssociationValidationValue>;
};

export type AssociationValidationInput = {
  label?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  values: Array<AssociationValidationValueInput>;
};

export type AssociationValidationRule =
  | 'CONTAINS'
  | 'EXACT_EMAIL_DOMAIN'
  | 'EXACT_MATCH';

export type AssociationValidationValue = {
  __typename?: 'AssociationValidationValue';
  rule: AssociationValidationRule;
  value: Scalars['String']['output'];
};

export type AssociationValidationValueInput = {
  rule: AssociationValidationRule;
  value: Scalars['String']['input'];
};

export type AttendanceUpdate =
  | 'ATTENDING'
  | 'CANCEL';

export type Attribute = {
  __typename?: 'Attribute';
  name: Scalars['String']['output'];
  uuid: Scalars['UUID']['output'];
};

export type AttributeResult = AttributeSuccessResponse | FailureResponse;

export type AttributeSuccessResponse = {
  __typename?: 'AttributeSuccessResponse';
  attribute: Attribute;
};

export type Auth0User = {
  __typename?: 'Auth0User';
  authId: Scalars['String']['output'];
  blocked: Scalars['Boolean']['output'];
  email: Scalars['String']['output'];
  verified: Scalars['Boolean']['output'];
};

export type AuthMap = {
  __typename?: 'AuthMap';
  canManageEmail: Scalars['Boolean']['output'];
  canManagePassword: Scalars['Boolean']['output'];
  canManageProfile: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  isEquiemManagedRealm: Scalars['Boolean']['output'];
  realm: Scalars['String']['output'];
};

export type AuthProfileInput = {
  avatar?: InputMaybe<Scalars['URI']['input']>;
  email?: InputMaybe<Scalars['Email']['input']>;
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
};

export type AuthRegistrationResponse = {
  __typename?: 'AuthRegistrationResponse';
  authId: Scalars['String']['output'];
  login: LoginResponse;
};

export type AuthRelationshipTupleInput = {
  object: Scalars['String']['input'];
  relation: Scalars['String']['input'];
  user: Scalars['String']['input'];
};

export type AuthRole =
  | 'SERVICE'
  | 'USER';

export type AvailabilityDateRange = {
  __typename?: 'AvailabilityDateRange';
  endDate: Scalars['String']['output'];
  endDateTimestamp: Scalars['DateTime']['output'];
  endInclusive: Scalars['Boolean']['output'];
  startDate: Scalars['String']['output'];
  startDateTimestamp: Scalars['DateTime']['output'];
  startInclusive: Scalars['Boolean']['output'];
};

export type AvatarImageType =
  | 'GIF'
  | 'ICO'
  | 'JPEG'
  | 'PNG';

export type BarcodeType =
  | 'CODE128'
  | 'None'
  | 'QR';

export type BarrierControlAccess = {
  __typename?: 'BarrierControlAccess';
  configs: Array<Maybe<BarrierControlAccessConfig>>;
  name: Scalars['String']['output'];
  siteUuid: Scalars['UUID']['output'];
  uuid: Scalars['UUID']['output'];
};

export type BarrierControlAccessConfig = {
  __typename?: 'BarrierControlAccessConfig';
  accessValue: Scalars['String']['output'];
  barrierControlAccess: BarrierControlAccess;
  barrierControlConfig: BarrierControlConfig;
  uuid: Scalars['UUID']['output'];
};

export type BarrierControlAccessConfigInput = {
  accessValue: Scalars['String']['input'];
  barrierControlAccessUuid: Scalars['UUID']['input'];
  barrierControlConfigUuid: Scalars['UUID']['input'];
};

export type BarrierControlAccessInput = {
  name: Scalars['String']['input'];
  siteUuid: Scalars['UUID']['input'];
};

export type BarrierControlConfig = {
  __typename?: 'BarrierControlConfig';
  alarmName: Scalars['String']['output'];
  awsRegion: Scalars['String']['output'];
  buildings: Array<Maybe<Building>>;
  codeType: BarcodeType;
  iamUserName: Scalars['String']['output'];
  logGroupName: Scalars['String']['output'];
  name: Scalars['String']['output'];
  prefix?: Maybe<Scalars['String']['output']>;
  site?: Maybe<Destination>;
  snsTopicArn: Scalars['String']['output'];
  sqsUrl: Scalars['String']['output'];
  uuid: Scalars['UUID']['output'];
};

export type BarrierControlConfigCodeType = {
  __typename?: 'BarrierControlConfigCodeType';
  type?: Maybe<BarcodeType>;
};

export type BarrierControlConfigCredentials = {
  __typename?: 'BarrierControlConfigCredentials';
  keyId: Scalars['String']['output'];
  keySecret: Scalars['String']['output'];
};

export type BarrierControlConfigInput = {
  buildingUuids: Array<Scalars['UUID']['input']>;
  codeType: BarcodeType;
  name: Scalars['String']['input'];
  prefix?: InputMaybe<Scalars['String']['input']>;
  siteUuid: Scalars['UUID']['input'];
};

export type BarrierControlConfigKeys = {
  __typename?: 'BarrierControlConfigKeys';
  count: Scalars['Int']['output'];
};

export type BarrierControlMessageInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  isDeleted: Scalars['Boolean']['input'];
  isMobile: Scalars['Boolean']['input'];
  lastName: Scalars['String']['input'];
  personUuid: Scalars['UUID']['input'];
  tenantName?: InputMaybe<Scalars['String']['input']>;
  tokenData: Scalars['String']['input'];
  tokenEndDate: Scalars['DateTime']['input'];
  tokenStartDate: Scalars['DateTime']['input'];
  tokenUuid: Scalars['UUID']['input'];
};

export type BarrierControlMutationResult = {
  __typename?: 'BarrierControlMutationResult';
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type BasicDestinationIntegration = {
  __typename?: 'BasicDestinationIntegration';
  enabled: Scalars['Boolean']['output'];
  info?: Maybe<BasicDestinationIntegrationInfo>;
};

export type BasicDestinationIntegrationInfo = {
  __typename?: 'BasicDestinationIntegrationInfo';
  label?: Maybe<Scalars['String']['output']>;
};

export type BasicDestinationIntegrationInput = {
  enabled: Scalars['Boolean']['input'];
  label?: InputMaybe<Scalars['String']['input']>;
};

export type BatchPushNotificationInput = {
  edition?: InputMaybe<Scalars['String']['input']>;
  profiles: Array<Scalars['UUID']['input']>;
  site?: InputMaybe<Scalars['UUID']['input']>;
};

export type BatchSessionTemplateOccurrenceInput = {
  bookableProductUuid: Scalars['UUID']['input'];
  endDate: Scalars['DateTime']['input'];
  startDate: Scalars['DateTime']['input'];
};

export type BatchSessionTemplateResults = {
  __typename?: 'BatchSessionTemplateResults';
  bookableProductUuid: Scalars['UUID']['output'];
  occurrences: Array<Occurrence>;
  success: Scalars['Boolean']['output'];
};

export type BetaUsers = {
  __typename?: 'BetaUsers';
  emailAddress: Scalars['String']['output'];
  operatingSystem: Scalars['String']['output'];
};

export type BetaUsersInput = {
  emailAddress: Scalars['String']['input'];
  operatingSystem: Scalars['String']['input'];
};

export type BillingAddress = {
  __typename?: 'BillingAddress';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  line1?: Maybe<Scalars['String']['output']>;
  line2?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  uuid: Scalars['UUID']['output'];
};

export type BillingAddressInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  line1?: InputMaybe<Scalars['String']['input']>;
  line2?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type BillingCustomer = {
  __typename?: 'BillingCustomer';
  address?: Maybe<BillingAddress>;
  contactName: Scalars['String']['output'];
  customerUuid: Scalars['UUID']['output'];
  email: Scalars['Email']['output'];
  id: Scalars['ID']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  type: BillingCustomerType;
};

export type BillingCustomerCreateInput = {
  address: BillingAddressInput;
  contactName: Scalars['String']['input'];
  customerUuid: Scalars['UUID']['input'];
  email: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  type: BillingCustomerType;
};

export type BillingCustomerDeleteInput = {
  billingCustomerUuid: Scalars['UUID']['input'];
};

export type BillingCustomerInput = {
  address: BillingAddressInput;
  contactName: Scalars['String']['input'];
  customerUuid?: InputMaybe<Scalars['UUID']['input']>;
  email: Scalars['String']['input'];
  flexTenantUuid: Scalars['UUID']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<BillingCustomerType>;
};

export type BillingCustomerType =
  | 'COMPANY'
  | 'FLEX_TENANT'
  | 'USER';

export type BillingCustomerUpdateInput = {
  address: BillingAddressInput;
  billingCustomerUuid: Scalars['UUID']['input'];
  contactName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type BluepointDestinationIntegration = {
  __typename?: 'BluepointDestinationIntegration';
  asId?: Maybe<Scalars['Int']['output']>;
  clientId?: Maybe<Scalars['String']['output']>;
  enabled: Scalars['Boolean']['output'];
};

export type BookableProduct = Product & {
  __typename?: 'BookableProduct';
  /** Indicates whether the booking allows multiple booking / quantity. */
  allowAttendees?: Maybe<Scalars['Boolean']['output']>;
  /** Settings for the is cancellable by user */
  allowUserToCancel?: Maybe<Scalars['Boolean']['output']>;
  availability: ProductAvailability;
  /** properties after this point only exist to match interface */
  available: Scalars['Boolean']['output'];
  combinedSessions: Array<SessionUnion>;
  dateRange?: Maybe<AvailabilityDateRange>;
  /** Description of the product. */
  description?: Maybe<Scalars['String']['output']>;
  excerpt: Scalars['String']['output'];
  focalPoint?: Maybe<FocalPoint>;
  groupedSessions: Array<SessionGroup>;
  /** URL of the image for the product. */
  image?: Maybe<Scalars['URI']['output']>;
  /** S3 object key of the image for the product. */
  imageKey?: Maybe<Scalars['String']['output']>;
  /** In minutes */
  minimumNoticeTimeLimit?: Maybe<Scalars['Int']['output']>;
  mods: Array<Mod>;
  /** Name of the product. */
  name: Scalars['String']['output'];
  outOfStock: Scalars['Boolean']['output'];
  /** Fields for which values will be provided while processing an order for this product. */
  outputFields: Array<ProductOutputField>;
  portals: Array<Portal>;
  possibleSessions: Array<PossibleSession>;
  /** Product categories e.g. Breakfast, Lunch, Dinner */
  productCategories: Array<ProductCategory>;
  /** Profile fields which the customer is requested to provide when ordering this product. */
  profileFieldRequests: Array<ProductProfileField>;
  session: Session;
  sessionTemplates: Array<SessionTemplate>;
  sessions: Array<Session>;
  /** Show or hide terms and conditions. */
  showTermsAndConditions: Scalars['Boolean']['output'];
  /**
   * Portals associated with the product
   * @deprecated use sitesWithAudience endpoint
   */
  sites: Array<Portal>;
  /** Portals associated with the product with optional audience */
  sitesWithAudience: Array<SiteSegment>;
  stock?: Maybe<Scalars['Int']['output']>;
  /** Taxes applicable for this product */
  taxRates: Array<TaxRate>;
  /** Terms and conditions. */
  termsAndConditions?: Maybe<Scalars['String']['output']>;
  /** In minutes */
  timeLimit?: Maybe<Scalars['Int']['output']>;
  totalBookings: Scalars['Int']['output'];
  /** When capacity return null, then it's unlimited capacity */
  totalCapacity?: Maybe<Scalars['Int']['output']>;
  turnAroundTime?: Maybe<Scalars['Int']['output']>;
  /** Unit price in cents. May be tax inc or exc. */
  unitPrice: Scalars['Int']['output'];
  uuid: Scalars['UUID']['output'];
  /** The owner of the product. */
  vendor: Vendor;
  /** Whether the product is visible to the customer on the store */
  visibility: ProductVisibility;
};


export type BookableProductCombinedSessionsArgs = {
  endDate: Scalars['DateTime']['input'];
  startDate: Scalars['DateTime']['input'];
};


export type BookableProductGroupedSessionsArgs = {
  endDate: Scalars['DateTime']['input'];
  startDate: Scalars['DateTime']['input'];
};


export type BookableProductImageArgs = {
  input?: InputMaybe<ImageInput>;
};


export type BookableProductPossibleSessionsArgs = {
  endDate: Scalars['DateTime']['input'];
  startDate: Scalars['DateTime']['input'];
};


export type BookableProductSessionArgs = {
  uuid: Scalars['UUID']['input'];
};


export type BookableProductSessionsArgs = {
  endDate: Scalars['DateTime']['input'];
  startDate: Scalars['DateTime']['input'];
};


export type BookableProductTotalBookingsArgs = {
  endDate: Scalars['DateTime']['input'];
  startDate: Scalars['DateTime']['input'];
};


export type BookableProductTotalCapacityArgs = {
  endDate: Scalars['DateTime']['input'];
  startDate: Scalars['DateTime']['input'];
};

export type BookableProductConnection = {
  __typename?: 'BookableProductConnection';
  edges: Array<Maybe<BookableProductEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type BookableProductContentEdge = ContentEdge & {
  __typename?: 'BookableProductContentEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<BookableProduct>;
};

export type BookableProductEdge = {
  __typename?: 'BookableProductEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<BookableProduct>;
};

export type BookableResource = {
  __typename?: 'BookableResource';
  addOns: Array<BookableResourceAddOn>;
  allowRecurringBooking: Scalars['Boolean']['output'];
  allowVisitorInvites: Scalars['Boolean']['output'];
  /** @deprecated use bookingApprovalType */
  autoApproveBookings: Scalars['Boolean']['output'];
  availability: Array<BookableResourceAvailability>;
  availabilityCalendar: Array<BookableResourceCalendarEntry>;
  availabilityDateRange?: Maybe<Scalars['String']['output']>;
  barrierControlAccess?: Maybe<BarrierControlAccess>;
  barrierControlVisitorAccess?: Maybe<BarrierControlAccess>;
  basePaymentMethods: Array<BookableResourcePaymentMethod>;
  baseRateFullDay: Scalars['Int']['output'];
  baseRateHalfDay: Scalars['Int']['output'];
  baseRateHourly: Scalars['Int']['output'];
  baseRateHourlyAfterHours: Scalars['Int']['output'];
  baseRateHourlyWeekend: Scalars['Int']['output'];
  bookingApprovalType: BookableResourceBookingApprovalType;
  bookingWindowMaxInMinutes?: Maybe<Scalars['Int']['output']>;
  bookingWindowMinInMinutes?: Maybe<Scalars['Int']['output']>;
  building?: Maybe<Building>;
  businessHoursEnd: Scalars['String']['output'];
  businessHoursHalfDayDurationMinutes: Scalars['Int']['output'];
  businessHoursStart: Scalars['String']['output'];
  children: Array<BookableResource>;
  company: CompanyV2;
  deletedAfter?: Maybe<Scalars['DateTime']['output']>;
  deletedBy?: Maybe<User>;
  description: Scalars['String']['output'];
  destination: Destination;
  discounts: Array<BookingDiscount>;
  displayCapacity?: Maybe<Scalars['Int']['output']>;
  /** @deprecated use editBookingNoticePeriodInMinutes */
  editBookingNoticePeriodInDays?: Maybe<Scalars['Int']['output']>;
  editBookingNoticePeriodInMinutes?: Maybe<Scalars['Int']['output']>;
  editBookingTermsAndConditions?: Maybe<Scalars['String']['output']>;
  externalSyncCalendarUrl?: Maybe<Scalars['String']['output']>;
  externalSyncEmailAddress?: Maybe<Scalars['String']['output']>;
  features: Array<BookableResourceFeature>;
  hiddenCost: Scalars['Boolean']['output'];
  images: Array<Scalars['String']['output']>;
  lastActiveBookingEndDate?: Maybe<Scalars['DateTime']['output']>;
  level?: Maybe<BuildingLevel>;
  /** @deprecated use availabilityCalendar */
  listingTimes: Array<BookableResourceListingTime>;
  manualApprovalCompanies: Array<Scalars['UUID']['output']>;
  name: Scalars['String']['output'];
  parents: Array<BookableResource>;
  paymentMethods: Array<BookableResourcePaymentMethod>;
  paymentRateCancellation: Array<BookableResourceCancellationRate>;
  paymentRateFullDay: Scalars['Int']['output'];
  paymentRateHalfDay: Scalars['Int']['output'];
  paymentRateHourly: Scalars['Int']['output'];
  paymentRateHourlyAfterHours: Scalars['Int']['output'];
  paymentRateHourlyWeekend: Scalars['Int']['output'];
  paymentRateIsFree: Scalars['Boolean']['output'];
  prepTimeAfterInMinutes?: Maybe<Scalars['Int']['output']>;
  prepTimeBeforeInMinutes?: Maybe<Scalars['Int']['output']>;
  roomConfigurations: Array<BookableResourceRoomConfiguration>;
  siteAudiences: Array<BookableResourceSiteAudience>;
  status: BookableResourceStatus;
  /** @deprecated use availabilityCalendar */
  takenTimes: Array<BookableResourceStartEndTime>;
  taxExempt: Scalars['Boolean']['output'];
  termsAndConditions?: Maybe<Scalars['String']['output']>;
  totalActiveBookings: Scalars['Int']['output'];
  typeInfo: BookableResourceTypeV2;
  userCanEditBookings: Scalars['Boolean']['output'];
  userHasAccessToResource: Scalars['Boolean']['output'];
  uuid: Scalars['String']['output'];
  video?: Maybe<Scalars['String']['output']>;
  videoTitle?: Maybe<Scalars['String']['output']>;
  viewerFlexTenant?: Maybe<FlexTenant>;
  viewerPermissions?: Maybe<BookableResourceViewerPermissions>;
};


export type BookableResourceAvailabilityCalendarArgs = {
  end: Scalars['DateTime']['input'];
  excludeBooking?: InputMaybe<Scalars['UUID']['input']>;
  start: Scalars['DateTime']['input'];
};


export type BookableResourceListingTimesArgs = {
  date: Scalars['DateTime']['input'];
  excludeBooking?: InputMaybe<Scalars['UUID']['input']>;
};


export type BookableResourceTakenTimesArgs = {
  end: Scalars['DateTime']['input'];
  excludeBooking?: InputMaybe<Scalars['UUID']['input']>;
  start: Scalars['DateTime']['input'];
};

export type BookableResourceAddOn = {
  __typename?: 'BookableResourceAddOn';
  mandatory: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  options?: Maybe<Array<BookableResourceAddOnOption>>;
  tooltipTxt?: Maybe<Scalars['String']['output']>;
  type: BookableResourceAddOnType;
  uuid: Scalars['UUID']['output'];
};

export type BookableResourceAddOnOption = {
  __typename?: 'BookableResourceAddOnOption';
  hasQuantity: Scalars['Boolean']['output'];
  maxQuantity?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  unitPrice: Scalars['Int']['output'];
  uuid: Scalars['UUID']['output'];
};

export type BookableResourceAddOnType =
  | 'FREE_TEXT'
  | 'MULTI_OPTION'
  | 'SINGLE_CHOICE'
  | 'SINGLE_OPTION';

export type BookableResourceAvailability = BookableResourceFlexibleAvailability | BookableResourceSlotsAvailability;

export type BookableResourceAvailabilityType =
  | 'FLEXIBLE'
  | 'SLOTS';

export type BookableResourceBaseAvailability = {
  days: Array<Weekday>;
  end: Scalars['String']['output'];
  start: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type BookableResourceBookingApprovalType =
  | 'AutoForAll'
  | 'ManualForAll'
  | 'ManualForCompanies';

export type BookableResourceCalendarAvailable = BookableResourceCalendarBaseEntry & {
  __typename?: 'BookableResourceCalendarAvailable';
  availability: BookableResourceAvailability;
  endTime: Scalars['DateTime']['output'];
  maxTimeInMinutes?: Maybe<Scalars['Int']['output']>;
  minTimeInMinutes?: Maybe<Scalars['Int']['output']>;
  startTime: Scalars['DateTime']['output'];
  type: BookableResourceAvailabilityType;
};

export type BookableResourceCalendarBaseEntry = {
  endTime: Scalars['DateTime']['output'];
  startTime: Scalars['DateTime']['output'];
};

export type BookableResourceCalendarEntry = BookableResourceCalendarAvailable | BookableResourceCalendarTaken;

export type BookableResourceCalendarTaken = BookableResourceCalendarBaseEntry & {
  __typename?: 'BookableResourceCalendarTaken';
  booking?: Maybe<Booking>;
  endTime: Scalars['DateTime']['output'];
  startTime: Scalars['DateTime']['output'];
};

export type BookableResourceCancellationRate = {
  __typename?: 'BookableResourceCancellationRate';
  amount: Scalars['Float']['output'];
  daysBefore?: Maybe<Scalars['Int']['output']>;
  type: BookableResourceCancellationRateType;
};

export type BookableResourceCancellationRateInput = {
  amount: Scalars['Float']['input'];
  daysBefore?: InputMaybe<Scalars['Int']['input']>;
  type: BookableResourceCancellationRateType;
};

export type BookableResourceCancellationRateType =
  | 'FIXED_RATE'
  | 'PERCENTAGE';

export type BookableResourceCapacityRange = {
  max?: InputMaybe<Scalars['Int']['input']>;
  min?: InputMaybe<Scalars['Int']['input']>;
};

export type BookableResourceConnection = {
  __typename?: 'BookableResourceConnection';
  edges: Array<BookableResourceEdge>;
  pageInfo: PageInfo;
};

export type BookableResourceCounts = {
  __typename?: 'BookableResourceCounts';
  all: Scalars['Int']['output'];
  draft: Scalars['Int']['output'];
  published: Scalars['Int']['output'];
};

export type BookableResourceEdge = {
  __typename?: 'BookableResourceEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<BookableResource>;
};

export type BookableResourceFeature = {
  __typename?: 'BookableResourceFeature';
  iconName?: Maybe<Scalars['String']['output']>;
  locked: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  type: BookableResourceFeatureType;
  uuid: Scalars['UUID']['output'];
};

export type BookableResourceFeatureInput = {
  iconName?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  type: BookableResourceFeatureType;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type BookableResourceFeatureType =
  | 'ROOM_FEATURES'
  | 'SHARED_FACILITIES';

export type BookableResourceFilterOptions = {
  __typename?: 'BookableResourceFilterOptions';
  buildings: Array<Building>;
  levels: Array<BuildingLevel>;
  resourceFeatures: Array<BookableResourceFeature>;
  resourceTypes: Array<BookableResourceTypeV2>;
  roomConfigurations: Array<BookableResourceRoomConfiguration>;
  sites: Array<Destination>;
};


export type BookableResourceFilterOptionsResourceFeaturesArgs = {
  featureType?: InputMaybe<BookableResourceFeatureType>;
};

export type BookableResourceFilters = {
  /** @deprecated use buildingUuids */
  buildingUuid?: InputMaybe<Scalars['UUID']['input']>;
  buildingUuids?: InputMaybe<Array<Scalars['UUID']['input']>>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  durationMinutes?: InputMaybe<Scalars['Int']['input']>;
  excludeParentResources?: InputMaybe<Scalars['Boolean']['input']>;
  /** @deprecated use levelUuids */
  levelUuid?: InputMaybe<Scalars['UUID']['input']>;
  levelUuids?: InputMaybe<Array<Scalars['UUID']['input']>>;
  maximumCapacity?: InputMaybe<Scalars['Int']['input']>;
  minimumCapacity?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  resourceFeatureUuid?: InputMaybe<Array<Scalars['UUID']['input']>>;
  resourceTypeUuid?: InputMaybe<Array<Scalars['UUID']['input']>>;
  showPaidResources?: InputMaybe<Scalars['Boolean']['input']>;
  /** @deprecated use siteUuids */
  siteUuid?: InputMaybe<Scalars['UUID']['input']>;
  siteUuids?: InputMaybe<Array<Scalars['UUID']['input']>>;
  startTime?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<BookableResourceStatus>;
};

export type BookableResourceFlexibleAvailability = BookableResourceBaseAvailability & {
  __typename?: 'BookableResourceFlexibleAvailability';
  days: Array<Weekday>;
  end: Scalars['String']['output'];
  maxTimeInMinutes?: Maybe<Scalars['Int']['output']>;
  minTimeInMinutes?: Maybe<Scalars['Int']['output']>;
  start: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type BookableResourceFlexibleAvailabilityInput = {
  days: Array<Weekday>;
  end: Scalars['String']['input'];
  maxTimeInMinutes?: InputMaybe<Scalars['Int']['input']>;
  minTimeInMinutes?: InputMaybe<Scalars['Int']['input']>;
  start: Scalars['String']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

export type BookableResourceInput = {
  addOns?: InputMaybe<Array<ResourceAddOnInput>>;
  allowRecurringBooking?: InputMaybe<Scalars['Boolean']['input']>;
  allowVisitorInvites?: InputMaybe<Scalars['Boolean']['input']>;
  /** @deprecated use bookingApprovalType */
  autoApproveBookings?: InputMaybe<Scalars['Boolean']['input']>;
  availabilityDateRange?: InputMaybe<Scalars['String']['input']>;
  barrierControlAccessUuid?: InputMaybe<Scalars['UUID']['input']>;
  barrierControlVisitorAccessUuid?: InputMaybe<Scalars['UUID']['input']>;
  bookingApprovalType?: InputMaybe<BookableResourceBookingApprovalType>;
  bookingWindowMaxInMinutes?: InputMaybe<Scalars['Int']['input']>;
  bookingWindowMinInMinutes?: InputMaybe<Scalars['Int']['input']>;
  buildingUuid: Scalars['UUID']['input'];
  businessHoursEnd?: InputMaybe<Scalars['String']['input']>;
  businessHoursHalfDayDurationMinutes?: InputMaybe<Scalars['Int']['input']>;
  businessHoursStart?: InputMaybe<Scalars['String']['input']>;
  children?: InputMaybe<Array<Scalars['UUID']['input']>>;
  description: Scalars['String']['input'];
  displayCapacity?: InputMaybe<Scalars['Int']['input']>;
  /** @deprecated use editBookingNoticePeriodInMinutes */
  editBookingNoticePeriodInDays?: InputMaybe<Scalars['Int']['input']>;
  editBookingNoticePeriodInMinutes?: InputMaybe<Scalars['Int']['input']>;
  editBookingTermsAndConditions?: InputMaybe<Scalars['String']['input']>;
  externalSyncCalendarUrl?: InputMaybe<Scalars['String']['input']>;
  externalSyncEmailAddress?: InputMaybe<Scalars['String']['input']>;
  features?: InputMaybe<Array<Scalars['UUID']['input']>>;
  flexibleAvailability: Array<BookableResourceFlexibleAvailabilityInput>;
  hiddenCost?: InputMaybe<Scalars['Boolean']['input']>;
  images: Array<Scalars['String']['input']>;
  levelUuid?: InputMaybe<Scalars['UUID']['input']>;
  manualApprovalCompanies?: InputMaybe<Array<Scalars['UUID']['input']>>;
  name: Scalars['String']['input'];
  ownerCompanyUuid?: InputMaybe<Scalars['UUID']['input']>;
  paymentMethods?: InputMaybe<Array<BookableResourcePaymentMethod>>;
  paymentRateCancellation?: InputMaybe<Array<BookableResourceCancellationRateInput>>;
  paymentRateFullDay?: InputMaybe<Scalars['Int']['input']>;
  paymentRateHalfDay?: InputMaybe<Scalars['Int']['input']>;
  paymentRateHourly?: InputMaybe<Scalars['Int']['input']>;
  paymentRateHourlyAfterHours?: InputMaybe<Scalars['Int']['input']>;
  paymentRateHourlyWeekend?: InputMaybe<Scalars['Int']['input']>;
  prepTimeAfterInMinutes?: InputMaybe<Scalars['Int']['input']>;
  prepTimeBeforeInMinutes?: InputMaybe<Scalars['Int']['input']>;
  roomConfigurations?: InputMaybe<Array<Scalars['UUID']['input']>>;
  site: Scalars['UUID']['input'];
  siteAudiences?: InputMaybe<Array<BookableResourceSiteAudienceInput>>;
  slotsAvailability: Array<BookableResourceSlotsAvailabilityInput>;
  status?: InputMaybe<BookableResourceStatus>;
  taxExempt?: InputMaybe<Scalars['Boolean']['input']>;
  taxes?: InputMaybe<Array<Scalars['UUID']['input']>>;
  termsAndConditions?: InputMaybe<Scalars['String']['input']>;
  typeV2: Scalars['UUID']['input'];
  userCanEditBookings?: InputMaybe<Scalars['Boolean']['input']>;
  video?: InputMaybe<Scalars['String']['input']>;
  videoTitle?: InputMaybe<Scalars['String']['input']>;
};

export type BookableResourceListingTime = {
  __typename?: 'BookableResourceListingTime';
  times: Array<BookableResourceStartEndTime>;
  title?: Maybe<Scalars['String']['output']>;
  type: BookableResourceAvailabilityType;
};

export type BookableResourcePaymentMethod =
  | 'CreditCard'
  | 'Credits'
  | 'Invoice';

export type BookableResourcePermissionFilters = {
  canManageBookings?: InputMaybe<Scalars['Boolean']['input']>;
  canObserveBookings?: InputMaybe<Scalars['Boolean']['input']>;
  isSegmentedToViewer?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BookableResourcePost = {
  __typename?: 'BookableResourcePost';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  featuredImage: Image;
  images: Array<Image>;
  lat?: Maybe<Scalars['Float']['output']>;
  locationName: Scalars['String']['output'];
  long?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  termsAndConditions?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid: Scalars['UUID']['output'];
};

export type BookableResourcePostInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description: Scalars['String']['input'];
  images: Array<FileInput>;
  lat?: InputMaybe<Scalars['Float']['input']>;
  location: Scalars['String']['input'];
  long?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
  published: Scalars['Boolean']['input'];
  segmentIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  site: Scalars['UUID']['input'];
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid: Scalars['UUID']['input'];
};

export type BookableResourceRates = {
  __typename?: 'BookableResourceRates';
  paymentMethods: Array<BookableResourcePaymentMethod>;
  paymentRateCancellation: Array<BookableResourceCancellationRate>;
  paymentRateFullDay: Scalars['Int']['output'];
  paymentRateHalfDay: Scalars['Int']['output'];
  paymentRateHourly: Scalars['Int']['output'];
  paymentRateHourlyAfterHours: Scalars['Int']['output'];
  paymentRateHourlyWeekend: Scalars['Int']['output'];
};

export type BookableResourceRoomConfiguration = {
  __typename?: 'BookableResourceRoomConfiguration';
  locked: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  uuid: Scalars['UUID']['output'];
};

export type BookableResourceSiteAudience = {
  __typename?: 'BookableResourceSiteAudience';
  destination: Destination;
  paymentMethods: Array<BookableResourcePaymentMethod>;
  paymentRateFullDay?: Maybe<Scalars['Int']['output']>;
  paymentRateHalfDay?: Maybe<Scalars['Int']['output']>;
  paymentRateHourly?: Maybe<Scalars['Int']['output']>;
  paymentRateHourlyAfterHours?: Maybe<Scalars['Int']['output']>;
  paymentRateHourlyWeekend?: Maybe<Scalars['Int']['output']>;
  segmentIds?: Maybe<Array<Scalars['UUID']['output']>>;
  segmentSummary?: Maybe<Scalars['String']['output']>;
};

export type BookableResourceSiteAudienceInput = {
  paymentMethods?: InputMaybe<Array<BookableResourcePaymentMethod>>;
  paymentRateFullDay?: InputMaybe<Scalars['Int']['input']>;
  paymentRateHalfDay?: InputMaybe<Scalars['Int']['input']>;
  paymentRateHourly?: InputMaybe<Scalars['Int']['input']>;
  paymentRateHourlyAfterHours?: InputMaybe<Scalars['Int']['input']>;
  paymentRateHourlyWeekend?: InputMaybe<Scalars['Int']['input']>;
  segmentIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  segmentSummary?: InputMaybe<Scalars['String']['input']>;
  site: Scalars['UUID']['input'];
};

export type BookableResourceSlotsAvailability = BookableResourceBaseAvailability & {
  __typename?: 'BookableResourceSlotsAvailability';
  days: Array<Weekday>;
  durationInMinutes: Scalars['Int']['output'];
  end: Scalars['String']['output'];
  isFullSession: Scalars['Boolean']['output'];
  start: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type BookableResourceSlotsAvailabilityInput = {
  days: Array<Weekday>;
  durationInMinutes?: InputMaybe<Scalars['Int']['input']>;
  end: Scalars['String']['input'];
  isFullSession?: InputMaybe<Scalars['Boolean']['input']>;
  start: Scalars['String']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

export type BookableResourceStartEndTime = {
  __typename?: 'BookableResourceStartEndTime';
  end: Scalars['DateTime']['output'];
  start: Scalars['DateTime']['output'];
};

export type BookableResourceStatus =
  | 'DELETED'
  | 'DRAFT'
  | 'PENDING_DELETION'
  | 'PUBLISHED';

export type BookableResourceTypeDisplayMode =
  | 'EQ1'
  | 'WEBNG';

export type BookableResourceTypeV2 = {
  __typename?: 'BookableResourceTypeV2';
  locked: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  uuid: Scalars['UUID']['output'];
};

export type BookableResourceTypeV2Input = {
  name: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type BookableResourceViewerPermissions = {
  __typename?: 'BookableResourceViewerPermissions';
  canManageBookings: Scalars['Boolean']['output'];
  canObserveBookings: Scalars['Boolean']['output'];
  isSegmentedToViewer: Scalars['Boolean']['output'];
};

export type Booking = {
  __typename?: 'Booking';
  addOns: Array<BookingAddOn>;
  addOnsPrice: Scalars['Int']['output'];
  adjustments: Array<BookingPaymentAdjustment>;
  adminNote?: Maybe<Scalars['String']['output']>;
  appointment?: Maybe<VisitorAppointment>;
  approvalBy?: Maybe<User>;
  approvalStatus: BookingApprovalStatus;
  billingCustomer?: Maybe<BillingCustomer>;
  cancellationPrice?: Maybe<Scalars['Int']['output']>;
  cancelled: Scalars['Boolean']['output'];
  cancelledBy?: Maybe<User>;
  cancelledByRole?: Maybe<CancellingUserRole>;
  cancelledDate?: Maybe<Scalars['DateTime']['output']>;
  cancelledReason?: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTime']['output'];
  createdBy: User;
  createdViaSite: Destination;
  creditAccountUuid?: Maybe<Scalars['UUID']['output']>;
  currencyCode?: Maybe<Scalars['String']['output']>;
  customPrice?: Maybe<Scalars['Int']['output']>;
  discounts: Array<BookingDiscount>;
  endDate: Scalars['DateTime']['output'];
  endDateWithPrepTime: Scalars['DateTime']['output'];
  finalisedDate?: Maybe<Scalars['DateTime']['output']>;
  hasAnyAdjustments: Scalars['Boolean']['output'];
  hasAnyPartialRefunds: Scalars['Boolean']['output'];
  invoiceContact?: Maybe<BookingSelectedInvoiceContact>;
  isEditable: Scalars['Boolean']['output'];
  isPast: Scalars['Boolean']['output'];
  isPaymentAdjustmentAllowed: Scalars['Boolean']['output'];
  note?: Maybe<Scalars['String']['output']>;
  originalTotalPrice?: Maybe<Scalars['Int']['output']>;
  partialRefunds: Array<BookingPartialRefund>;
  paymentMethod?: Maybe<BookableResourcePaymentMethod>;
  paymentStatus?: Maybe<BookingPaymentStatus>;
  reference: Scalars['String']['output'];
  resource: BookableResource;
  resourcePrice: Scalars['Int']['output'];
  resourcePriceWithDiscount: Scalars['Int']['output'];
  roomConfig?: Maybe<BookingRoomConfiguration>;
  startDate: Scalars['DateTime']['output'];
  startDateWithPrepTime: Scalars['DateTime']['output'];
  status: BookingStatus;
  subtotal: Scalars['Int']['output'];
  taxes: Array<BookingTax>;
  timezone: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
  totalPrice: Scalars['Int']['output'];
  totalTax: Scalars['Int']['output'];
  updateHistory: Array<BookingHistoryChange>;
  updated: Scalars['DateTime']['output'];
  user: User;
  userAccessCode?: Maybe<BookingAccessControlCode>;
  userCompany?: Maybe<CompanyV2>;
  uuid: Scalars['UUID']['output'];
};

export type BookingAccessControlCode = {
  __typename?: 'BookingAccessControlCode';
  accessHistory: Array<BookingAccessControlHistoryEvent>;
  barrierControlAccess: BarrierControlAccess;
  building: Building;
  destination: Destination;
  emailedToUserAt?: Maybe<Scalars['DateTime']['output']>;
  status: BookingAccessControlCodeStatus;
  user: User;
  uuid: Scalars['UUID']['output'];
  validFrom: Scalars['DateTime']['output'];
  validUntil: Scalars['DateTime']['output'];
};

export type BookingAccessControlCodeStatus =
  | 'Pending'
  | 'SentToBarrierControl';

export type BookingAccessControlHistoryEvent = {
  __typename?: 'BookingAccessControlHistoryEvent';
  event: BookingAccessControlHistoryEventType;
  timestamp: Scalars['DateTime']['output'];
  user?: Maybe<User>;
  uuid: Scalars['UUID']['output'];
};

export type BookingAccessControlHistoryEventType =
  | 'BarrierArrive'
  | 'BarrierLeave'
  | 'CodeCreated'
  | 'CodeDeleteFailed'
  | 'CodeDeleted'
  | 'CodeSendFailed'
  | 'CodeSent';

export type BookingAddOn = {
  __typename?: 'BookingAddOn';
  addOnName?: Maybe<Scalars['String']['output']>;
  addOnTooltipText?: Maybe<Scalars['String']['output']>;
  addOnType: BookableResourceAddOnType;
  addOnUuid: Scalars['UUID']['output'];
  description?: Maybe<Scalars['String']['output']>;
  options: Array<BookingAddOnOption>;
  uuid: Scalars['UUID']['output'];
};

export type BookingAddOnCharge = {
  __typename?: 'BookingAddOnCharge';
  description: Scalars['String']['output'];
  price: Scalars['Int']['output'];
  quantity?: Maybe<Scalars['Int']['output']>;
};

export type BookingAddOnInput = {
  addOn: Scalars['UUID']['input'];
  option?: InputMaybe<Scalars['UUID']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type BookingAddOnOption = {
  __typename?: 'BookingAddOnOption';
  description: Scalars['String']['output'];
  hasQuantity: Scalars['Boolean']['output'];
  optionUuid?: Maybe<Scalars['UUID']['output']>;
  partialRefunds: Array<BookingPartialRefund>;
  quantity?: Maybe<Scalars['Int']['output']>;
  unitPrice: Scalars['Int']['output'];
  uuid: Scalars['UUID']['output'];
};

export type BookingAdjustmentInput = {
  amount: Scalars['Int']['input'];
  booking: Scalars['UUID']['input'];
  reason: Scalars['String']['input'];
};

export type BookingApprovalStatus =
  | 'APPROVED'
  | 'DECLINED'
  | 'PENDING';

export type BookingBlockOut = {
  __typename?: 'BookingBlockOut';
  bundle: Scalars['UUID']['output'];
  created: Scalars['DateTime']['output'];
  createdBy: User;
  endDate: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  resource?: Maybe<BookableResource>;
  startDate: Scalars['DateTime']['output'];
  uuid: Scalars['UUID']['output'];
};

export type BookingBlockOutInput = {
  bundleUuid?: InputMaybe<Scalars['UUID']['input']>;
  endDate: Scalars['DateTime']['input'];
  name: Scalars['String']['input'];
  resourceUuids: Array<Scalars['UUID']['input']>;
  startDate: Scalars['DateTime']['input'];
};

export type BookingBlockOutsFilter = {
  bundleUUID?: InputMaybe<Scalars['UUID']['input']>;
};

export type BookingCancellationCharge = {
  __typename?: 'BookingCancellationCharge';
  daysBefore?: Maybe<Scalars['Int']['output']>;
  price: Scalars['Int']['output'];
  rate: Scalars['Float']['output'];
  rateType: BookableResourceCancellationRateType;
  taxInclusive: Scalars['Boolean']['output'];
  taxes: Array<BookingTaxCharge>;
  total: Scalars['Int']['output'];
  totalTax: Scalars['Int']['output'];
};

export type BookingCart = {
  __typename?: 'BookingCart';
  discountTotal?: Maybe<Scalars['Int']['output']>;
  items: Array<SessionLineItem>;
  preDiscountTotal?: Maybe<Scalars['Int']['output']>;
  /** Profile fields which the customer is requested to provide with this order. */
  profileFieldRequests: Array<ProductProfileField>;
  subTotal: Scalars['Int']['output'];
  tax: Scalars['Int']['output'];
  /** Tax inc or exc depending on tax region */
  total: Scalars['Int']['output'];
  vendor: Vendor;
};

export type BookingCartOutput = BookingCart | CartFailure;

export type BookingChargesInput = {
  acceptTerms?: InputMaybe<Scalars['Boolean']['input']>;
  addOns?: InputMaybe<Array<BookingAddOnInput>>;
  adjustment?: InputMaybe<CreateBookingAdjustmentInput>;
  adminNote?: InputMaybe<Scalars['String']['input']>;
  creditAccount?: InputMaybe<BookingCreditsInput>;
  creditCard?: InputMaybe<BookingCreditCardInput>;
  customPrice?: InputMaybe<Scalars['Int']['input']>;
  endDate: Scalars['DateTime']['input'];
  invoiceContact?: InputMaybe<BookingInvoiceContactInput>;
  migratedBookingOriginalUuid?: InputMaybe<Scalars['UUID']['input']>;
  migratedBookingSuppressCreationEvents?: InputMaybe<Scalars['Boolean']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  paymentMethod?: InputMaybe<BookableResourcePaymentMethod>;
  proxyBookingforUser?: InputMaybe<Scalars['UUID']['input']>;
  refunds?: InputMaybe<Array<CreateBookingPartialRefundInput>>;
  resourceUuid: Scalars['String']['input'];
  roomConfiguration?: InputMaybe<Scalars['UUID']['input']>;
  startDate: Scalars['DateTime']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
  userUuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type BookingChargesResult = {
  __typename?: 'BookingChargesResult';
  addOnPrices: Array<BookingAddOnCharge>;
  cancellationPrice?: Maybe<BookingCancellationCharge>;
  currencyCode: Scalars['String']['output'];
  discountsV2: Array<BookingDiscount>;
  endDate: Scalars['DateTime']['output'];
  originalTotal?: Maybe<Scalars['Int']['output']>;
  resourcePrice: Scalars['Int']['output'];
  resourcePriceDescription: Scalars['String']['output'];
  resourcePriceWithDiscount: Scalars['Int']['output'];
  startDate: Scalars['DateTime']['output'];
  subtotal: Scalars['Int']['output'];
  taxInclusive: Scalars['Boolean']['output'];
  taxes: Array<BookingTaxCharge>;
  total: Scalars['Int']['output'];
  totalTax: Scalars['Int']['output'];
};

export type BookingConnection = {
  __typename?: 'BookingConnection';
  edges: Array<BookingEdge>;
  pageInfo: PageInfo;
};

export type BookingCreditCardInput = {
  paymentGatewayCustomerId?: InputMaybe<Scalars['String']['input']>;
  paymentGatewayPaymentMethodId?: InputMaybe<Scalars['String']['input']>;
};

export type BookingCreditsInput = {
  accountUuid: Scalars['UUID']['input'];
};

export type BookingDiscount = {
  __typename?: 'BookingDiscount';
  availability: BookingDiscountAvailability;
  percentage: Scalars['Int']['output'];
  resources: Array<BookableResource>;
  status: BookingDiscountStatus;
  title: Scalars['String']['output'];
  type: BookingDiscountType;
  uuid: Scalars['UUID']['output'];
};

export type BookingDiscountAvailability = BookingDiscountEarlyBookingAvailability | BookingDiscountPeriodicAvailability;

export type BookingDiscountAvailabilityInput = {
  dateRange: Scalars['String']['input'];
  days?: InputMaybe<Array<Weekday>>;
  daysBeforeBookingToApply?: InputMaybe<Scalars['Int']['input']>;
  endTime?: InputMaybe<Scalars['String']['input']>;
  startTime?: InputMaybe<Scalars['String']['input']>;
};

export type BookingDiscountBaseAvailability = {
  endDate: Scalars['String']['output'];
  startDate: Scalars['String']['output'];
};

export type BookingDiscountConnection = {
  __typename?: 'BookingDiscountConnection';
  edges: Array<BookingDiscountEdge>;
  pageInfo: PageInfo;
};

export type BookingDiscountEarlyBookingAvailability = BookingDiscountBaseAvailability & {
  __typename?: 'BookingDiscountEarlyBookingAvailability';
  daysBeforeBookingToApply: Scalars['Int']['output'];
  endDate: Scalars['String']['output'];
  startDate: Scalars['String']['output'];
};

export type BookingDiscountEdge = {
  __typename?: 'BookingDiscountEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<BookingDiscount>;
};

export type BookingDiscountPeriodicAvailability = BookingDiscountBaseAvailability & {
  __typename?: 'BookingDiscountPeriodicAvailability';
  days?: Maybe<Array<Weekday>>;
  endDate: Scalars['String']['output'];
  endTime?: Maybe<Scalars['String']['output']>;
  startDate: Scalars['String']['output'];
  startTime?: Maybe<Scalars['String']['output']>;
};

export type BookingDiscountStatus =
  | 'Deleted'
  | 'Expired'
  | 'Live'
  | 'Upcoming';

export type BookingDiscountType =
  | 'EARLY_BOOKING'
  | 'PERIODIC';

export type BookingEdge = {
  __typename?: 'BookingEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Booking>;
};

export type BookingFailureResponse = {
  __typename?: 'BookingFailureResponse';
  localisedReason: LocalisedMessage;
  reason: Scalars['String']['output'];
};

export type BookingFailureWithTimeResponse = {
  __typename?: 'BookingFailureWithTimeResponse';
  endDate: Scalars['DateTime']['output'];
  reason: LocalisedMessage;
  startDate: Scalars['DateTime']['output'];
};

export type BookingHistoryChange = {
  __typename?: 'BookingHistoryChange';
  adjustmentReason?: Maybe<Scalars['String']['output']>;
  adminNote?: Maybe<Scalars['String']['output']>;
  approvalStatus?: Maybe<BookingApprovalStatus>;
  cancelledReason?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  originalTotalPrice?: Maybe<Scalars['Int']['output']>;
  partialRefundReason?: Maybe<Scalars['String']['output']>;
  roomConfiguration?: Maybe<BookingRoomConfiguration>;
  time?: Maybe<BookingTime>;
  totalPrice?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy: User;
  user?: Maybe<User>;
};

export type BookingInput = {
  acceptTerms?: InputMaybe<Scalars['Boolean']['input']>;
  addOns?: InputMaybe<Array<BookingAddOnInput>>;
  adjustment?: InputMaybe<CreateBookingAdjustmentInput>;
  adminNote?: InputMaybe<Scalars['String']['input']>;
  creditAccount?: InputMaybe<BookingCreditsInput>;
  creditCard?: InputMaybe<BookingCreditCardInput>;
  endDate: Scalars['DateTime']['input'];
  invoiceContact?: InputMaybe<BookingInvoiceContactInput>;
  /** Intended for use in booking migrations only. Requires RegionalManager/Admin role. */
  migratedBookingOriginalUuid?: InputMaybe<Scalars['UUID']['input']>;
  /** Intended for use in booking migrations only. Requires RegionalManager/Admin role. */
  migratedBookingSuppressCreationEvents?: InputMaybe<Scalars['Boolean']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  paymentMethod?: InputMaybe<BookableResourcePaymentMethod>;
  proxyBookingforUser?: InputMaybe<Scalars['UUID']['input']>;
  /** Only applies to the resource cost. Cannot be applied to add-ons or adjustments at booking creation time. */
  refunds?: InputMaybe<Array<CreateBookingPartialRefundInput>>;
  resourceUuid: Scalars['String']['input'];
  roomConfiguration?: InputMaybe<Scalars['UUID']['input']>;
  startDate: Scalars['DateTime']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

export type BookingInvoiceContact = BookingInvoiceContactExisting | BookingInvoiceContactSelf;

export type BookingInvoiceContactDeleteResult = {
  __typename?: 'BookingInvoiceContactDeleteResult';
  deleted: Scalars['Boolean']['output'];
};

export type BookingInvoiceContactExisting = {
  __typename?: 'BookingInvoiceContactExisting';
  contactUuid: Scalars['UUID']['output'];
  email: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
};

export type BookingInvoiceContactInput = {
  billingCustomerId?: InputMaybe<Scalars['ID']['input']>;
  contactType: BookingInvoiceContactTypeInput;
  contactUuid?: InputMaybe<Scalars['UUID']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  saveDetails?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BookingInvoiceContactSelf = {
  __typename?: 'BookingInvoiceContactSelf';
  email: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
};

export type BookingInvoiceContactTypeInput =
  | 'BILLING_CUSTOMER'
  | 'EXISTING_CONTACT'
  | 'NEW_CONTACT'
  | 'SELF';

export type BookingModuleSettings = {
  __typename?: 'BookingModuleSettings';
  commissionPercentage: Scalars['Float']['output'];
  yardiEnabled: Scalars['Boolean']['output'];
};

export type BookingModuleSettingsInput = {
  commissionPercentage?: InputMaybe<Scalars['Float']['input']>;
  yardiEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BookingMutationResult = BookingFailureResponse | BookingSuccessResponse;

export type BookingMutations = {
  __typename?: 'BookingMutations';
  book: CheckoutOutputV2;
  cancelMyBooking: MutationResultV3;
  /** Check-in by checkIn token */
  checkInByToken: MutationResult;
};


export type BookingMutationsBookArgs = {
  order: SessionOrderInput;
};


export type BookingMutationsCancelMyBookingArgs = {
  session: Scalars['UUID']['input'];
};


export type BookingMutationsCheckInByTokenArgs = {
  token: Scalars['String']['input'];
};

export type BookingNotificationPreferences = {
  __typename?: 'BookingNotificationPreferences';
  admin: AdminBookingNotificationPreferences;
  endUser: EndUserBookingNotificationPreferences;
};

export type BookingNotificationsAdminPreferencesInput = {
  approvalRequestEmail: Scalars['Boolean']['input'];
  cancellationEmail?: InputMaybe<Scalars['Boolean']['input']>;
  confirmationEmail: Scalars['Boolean']['input'];
  declinedBookingEmail?: InputMaybe<Scalars['Boolean']['input']>;
  updateEmail: Scalars['Boolean']['input'];
};

export type BookingNotificationsEndUserPreferencesInput = {
  awaitingApprovalEmail: Scalars['Boolean']['input'];
  cancellationEmail: Scalars['Boolean']['input'];
  chargedAdjustmentEmail: Scalars['Boolean']['input'];
  confirmationEmail: Scalars['Boolean']['input'];
  declinedBookingEmail: Scalars['Boolean']['input'];
  reminderEmail: Scalars['Boolean']['input'];
  updateEmail: Scalars['Boolean']['input'];
};

export type BookingNotificationsPreferencesInput = {
  admin: BookingNotificationsAdminPreferencesInput;
  endUser: BookingNotificationsEndUserPreferencesInput;
};

export type BookingPartialRefund = {
  __typename?: 'BookingPartialRefund';
  amount: Scalars['Int']['output'];
  reason: Scalars['String']['output'];
  user: User;
  uuid: Scalars['UUID']['output'];
};

export type BookingPaymentAdjustment = {
  __typename?: 'BookingPaymentAdjustment';
  amount: Scalars['Int']['output'];
  partialRefunds: Array<BookingPartialRefund>;
  reason: Scalars['String']['output'];
  user: User;
  uuid: Scalars['UUID']['output'];
};

/** See https://stripe.com/docs/api/payment_intents/object */
export type BookingPaymentIntent = {
  __typename?: 'BookingPaymentIntent';
  /** See https://stripe.com/docs/api/payment_intents/object#payment_intent_object-client_secret */
  clientSecret: Scalars['String']['output'];
  id: Scalars['String']['output'];
  /** See https://stripe.com/docs/api/payment_intents/object#payment_intent_object-status */
  status: PaymentIntentStatus;
};

export type BookingPaymentStatus =
  | 'FAILED'
  | 'PAID'
  | 'PENDING'
  | 'REFUNDED'
  | 'REFUND_FAILED';

export type BookingRoomConfiguration = {
  __typename?: 'BookingRoomConfiguration';
  name: Scalars['String']['output'];
  site?: Maybe<Scalars['UUID']['output']>;
  uuid: Scalars['UUID']['output'];
};

export type BookingSelectedInvoiceContact = {
  __typename?: 'BookingSelectedInvoiceContact';
  email: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
};

export type BookingSetting = {
  __typename?: 'BookingSetting';
  paymentAccountId?: Maybe<Scalars['String']['output']>;
  paymentAdjustmentEnabled: Scalars['Boolean']['output'];
  paymentAdjustmentMaxDays: Scalars['Int']['output'];
  uuid: Scalars['UUID']['output'];
};

export type BookingSortOrder =
  | 'STARTDATE_ASCENDING'
  | 'STARTDATE_DESCENDING';

export type BookingStatus =
  | 'APPROVED'
  | 'CANCELLED'
  | 'DECLINED'
  | 'PAYMENT_FAILED'
  | 'PENDING_APPROVAL'
  | 'PENDING_PAYMENT'
  | 'PENDING_WORKPLACE_MANAGER_APPROVAL';

export type BookingSuccessResponse = {
  __typename?: 'BookingSuccessResponse';
  booking: Booking;
  paymentIntent?: Maybe<BookingPaymentIntent>;
};

export type BookingTax = {
  __typename?: 'BookingTax';
  description: Scalars['String']['output'];
  price: Scalars['Int']['output'];
  rate: Scalars['Float']['output'];
};

export type BookingTaxCharge = {
  __typename?: 'BookingTaxCharge';
  description: Scalars['String']['output'];
  price: Scalars['Int']['output'];
  rate: Scalars['Float']['output'];
};

export type BookingTime = {
  __typename?: 'BookingTime';
  endDate: Scalars['DateTime']['output'];
  startDate: Scalars['DateTime']['output'];
};

export type BookingsReconcilationReportFilters = {
  cancelled?: InputMaybe<Scalars['Boolean']['input']>;
  companyUuid?: InputMaybe<Scalars['UUID']['input']>;
  costOnly?: InputMaybe<Scalars['Boolean']['input']>;
  endDate: Scalars['DateTime']['input'];
  paymentMethod?: InputMaybe<BookableResourcePaymentMethod>;
  siteUuid?: InputMaybe<Array<Scalars['UUID']['input']>>;
  startDate: Scalars['DateTime']['input'];
};

export type BookingsReconciliationReport = {
  __typename?: 'BookingsReconciliationReport';
  bookings: Array<ReconciliationReportBooking>;
  currencyCode?: Maybe<Scalars['String']['output']>;
  totals: ReconciliationReportTotals;
  yardiEnabled: Scalars['Boolean']['output'];
};

export type BookingsRole =
  | 'AUTHENTICATED_NO_PERMISSION'
  | 'SERVICE';

export type BookingsSiteCalendarInterval = {
  __typename?: 'BookingsSiteCalendarInterval';
  bookings: Array<Booking>;
  date: Scalars['DateTime']['output'];
  hasMore: Scalars['Boolean']['output'];
  numMore: Scalars['Int']['output'];
};

export type BpAccess = BpAccessAllowed | BpAccessDenied;

export type BpAccessAllowed = {
  __typename?: 'BpAccessAllowed';
  allowed: Scalars['Boolean']['output'];
  userId: Scalars['Int']['output'];
};

export type BpAccessDenied = {
  __typename?: 'BpAccessDenied';
  allowed: Scalars['Boolean']['output'];
  reason: Scalars['String']['output'];
};

export type BpAppointment = {
  __typename?: 'BpAppointment';
  active: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  end: Scalars['DateTime']['output'];
  hosts: Array<BpPerson>;
  id: Scalars['Int']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  organiser: BpPerson;
  start: Scalars['DateTime']['output'];
  status: Scalars['String']['output'];
  subject?: Maybe<Scalars['String']['output']>;
  visitors: Array<BpVisitor>;
};

export type BpAppointmentConnection = {
  __typename?: 'BpAppointmentConnection';
  edges: Array<BpAppointmentEdge>;
  pageInfo: PageInfo;
};

export type BpAppointmentEdge = {
  __typename?: 'BpAppointmentEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<BpAppointmentNode>;
};

export type BpAppointmentNode = {
  __typename?: 'BpAppointmentNode';
  active?: Maybe<Scalars['Boolean']['output']>;
  end: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  organiser?: Maybe<BpPerson>;
  start: Scalars['DateTime']['output'];
  status?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  visitors: Array<BpVisitor>;
};

export type BpAppointmentSort = {
  asc: Scalars['Boolean']['input'];
  field: BpAppointmentSortField;
};

export type BpAppointmentSortField =
  | 'end'
  | 'organiser'
  | 'start'
  | 'status'
  | 'subject';

export type BpCreateAppointmentInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  end: Scalars['DateTime']['input'];
  host: Scalars['Int']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  start: Scalars['DateTime']['input'];
  subject: Scalars['String']['input'];
  visitors: Array<BpVisitorInput>;
};

export type BpMutation = {
  __typename?: 'BpMutation';
  createAppointment: BpSubmissionResult;
  deleteAppointment: BpSubmissionResult;
  ensureUserHasAccess: BpAccess;
  updateAppointment: BpSubmissionResult;
};


export type BpMutationCreateAppointmentArgs = {
  input: BpCreateAppointmentInput;
};


export type BpMutationDeleteAppointmentArgs = {
  id: Scalars['Int']['input'];
};


export type BpMutationUpdateAppointmentArgs = {
  input: BpUpdateAppointmentInput;
};

export type BpPerson = {
  __typename?: 'BpPerson';
  email?: Maybe<Scalars['String']['output']>;
  equiemUuid?: Maybe<Scalars['UUID']['output']>;
  firstName: Scalars['String']['output'];
  fullName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  lastName: Scalars['String']['output'];
};

export type BpQuery = {
  __typename?: 'BpQuery';
  appointment: BpAppointment;
  appointments: BpAppointmentConnection;
  submission: BpSubmission;
  visitors: BpVisitorConnection;
};


export type BpQueryAppointmentArgs = {
  id: Scalars['Int']['input'];
};


export type BpQueryAppointmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  end?: InputMaybe<Scalars['DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<BpAppointmentSort>;
  start?: InputMaybe<Scalars['DateTime']['input']>;
};


export type BpQuerySubmissionArgs = {
  id: Scalars['ID']['input'];
};


export type BpQueryVisitorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<BpVisitorSort>;
};

export type BpSubmission = {
  __typename?: 'BpSubmission';
  error?: Maybe<Scalars['String']['output']>;
  percentage?: Maybe<Scalars['Int']['output']>;
  stage?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type BpSubmissionResult = {
  __typename?: 'BpSubmissionResult';
  submissionId: Scalars['String']['output'];
};

export type BpUpdateAppointmentInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  end: Scalars['DateTime']['input'];
  host: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  start: Scalars['DateTime']['input'];
  subject: Scalars['String']['input'];
  visitors: Array<BpVisitorInput>;
};

export type BpVisitor = {
  __typename?: 'BpVisitor';
  email?: Maybe<Scalars['Email']['output']>;
  firstName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  lastName: Scalars['String']['output'];
};

export type BpVisitorConnection = {
  __typename?: 'BpVisitorConnection';
  edges: Array<BpVisitorEdge>;
  pageInfo: PageInfo;
};

export type BpVisitorEdge = {
  __typename?: 'BpVisitorEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<BpPerson>;
};

export type BpVisitorInput = {
  email: Scalars['Email']['input'];
  firstName: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  lastName: Scalars['String']['input'];
  sendInvite?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BpVisitorSort = {
  asc: Scalars['Boolean']['input'];
  field: BpVisitorSortField;
};

export type BpVisitorSortField =
  | 'email'
  | 'firstname'
  | 'lastname';

export type Branding = {
  __typename?: 'Branding';
  appHeroImage?: Maybe<Scalars['String']['output']>;
  email: EmailBranding;
  logo?: Maybe<Scalars['String']['output']>;
  logoSize?: Maybe<Scalars['String']['output']>;
  mobileColour: Scalars['String']['output'];
  mobileColourIsDark: Scalars['Boolean']['output'];
  mobileContrastColour: Scalars['String']['output'];
  mobileWhiteContrastColour: Scalars['String']['output'];
  primaryColour: Scalars['String']['output'];
  primaryColourIsDark: Scalars['Boolean']['output'];
  primaryContrastColour: Scalars['String']['output'];
  primaryWhiteContrastColour: Scalars['String']['output'];
  secondaryColour: Scalars['String']['output'];
  textOverAppHeroImage: Scalars['Boolean']['output'];
  web: WebBranding;
};


export type BrandingAppHeroImageArgs = {
  imageOptions?: InputMaybe<ImageOptions>;
};


export type BrandingLogoArgs = {
  imageOptions?: InputMaybe<ImageOptions>;
};

export type Building = {
  __typename?: 'Building';
  ac1BuildingIntegrations?: Maybe<Array<Ac1BuildingIntegration>>;
  address: Scalars['String']['output'];
  area: Scalars['Int']['output'];
  buildingLevels: Array<BuildingLevel>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  destination?: Maybe<Destination>;
  hasBuildingAddress: Scalars['Boolean']['output'];
  history: Array<BuildingHistoryFieldChange>;
  /** This is the queue where all the messages for AC1 for this Building should be sent to. */
  incomingSqsUrl?: Maybe<Scalars['URL']['output']>;
  integrations?: Maybe<BuildingIntegrations>;
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  occupants: Scalars['Int']['output'];
  postcode?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  streetName?: Maybe<Scalars['String']['output']>;
  taxes: BuildingTaxes;
  timezone: Scalars['String']['output'];
  units: Scalars['String']['output'];
  uuid: Scalars['UUID']['output'];
  viewerRelations: BuildingViewerRelations;
  visitorManagement?: Maybe<VisitorBuilding>;
};

export type BuildingConnection = {
  __typename?: 'BuildingConnection';
  edges: Array<BuildingEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type BuildingEdge = {
  __typename?: 'BuildingEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Building>;
};

export type BuildingHistoryFieldChange = {
  __typename?: 'BuildingHistoryFieldChange';
  changedAt: Scalars['DateTime']['output'];
  changedBy: FullUser;
  field: Scalars['String']['output'];
  newValue: Scalars['String']['output'];
  oldValue: Scalars['String']['output'];
};

export type BuildingInfo = {
  __typename?: 'BuildingInfo';
  categories: Array<BuildingInfoCategory>;
  enabled: Scalars['Boolean']['output'];
  image?: Maybe<Scalars['URI']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};


export type BuildingInfoImageArgs = {
  imageOptions?: InputMaybe<ImageOptions>;
};

export type BuildingInfoCategory = {
  __typename?: 'BuildingInfoCategory';
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  uuid: Scalars['UUID']['output'];
  weight: Scalars['Int']['output'];
};

export type BuildingInfoCategoryInput = {
  name: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['UUID']['input']>;
  weight: Scalars['Int']['input'];
};

export type BuildingInfoConnection = {
  __typename?: 'BuildingInfoConnection';
  edges: Array<BuildingInfoEdge>;
  pageInfo: PageInfo;
};

export type BuildingInfoEdge = ContentEdge & {
  __typename?: 'BuildingInfoEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<BuildingInfoPost>;
};

export type BuildingInfoPost = {
  __typename?: 'BuildingInfoPost';
  body: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  downloads: Array<File>;
  excerpt: Scalars['String']['output'];
  featuredImage: Image;
  publishDate: Scalars['DateTime']['output'];
  relatedInfo: Array<Link>;
  segmentIds: Array<Scalars['UUID']['output']>;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid: Scalars['UUID']['output'];
};

export type BuildingInfoPostInput = {
  body: Scalars['String']['input'];
  categoryUuid?: InputMaybe<Scalars['UUID']['input']>;
  contentUuid: Scalars['UUID']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  downloads?: InputMaybe<Array<FileInput>>;
  excerpt: Scalars['String']['input'];
  image: FileInput;
  order?: InputMaybe<Scalars['Int']['input']>;
  portalPostUuid: Scalars['UUID']['input'];
  /**
   * Iris incorrectly sends this timezone scoped timestamp as if it is in UTC.
   * Eg. 10:00:00AM Australia/Melbourne time is sent as 10:00:00AM UTC.
   * publishDateInUtc will contain the correct utc timestamp.
   */
  publishDate: Scalars['DateTime']['input'];
  publishDateInUtc: Scalars['DateTime']['input'];
  published: Scalars['Boolean']['input'];
  relatedInformation?: InputMaybe<Array<LinkInput>>;
  segmentIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  shareableLinkId?: InputMaybe<Scalars['String']['input']>;
  site: Scalars['UUID']['input'];
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type BuildingIntegrations = {
  __typename?: 'BuildingIntegrations';
  yardi?: Maybe<YardiBuildingIntegration>;
};

export type BuildingIntegrationsInput = {
  buildingUuid: Scalars['UUID']['input'];
  yardi?: InputMaybe<YardiBuildingIntegrationInput>;
};

export type BuildingLevel = {
  __typename?: 'BuildingLevel';
  apartmentDestinations: Array<ApartmentDestination>;
  areas: Array<Area>;
  building: Building;
  companyDestinations: Array<CompanyDestination>;
  name: Scalars['String']['output'];
  spaces: Array<Space>;
  uuid: Scalars['UUID']['output'];
  viewerRelations: BuildingLevelViewerRelations;
};


export type BuildingLevelSpacesArgs = {
  ownerCompanyUuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type BuildingLevelViewerRelations = {
  __typename?: 'BuildingLevelViewerRelations';
  canManageBookings: Scalars['Boolean']['output'];
  canManageCompanyBookings: Scalars['Boolean']['output'];
  canObserveCompanyBookings: Scalars['Boolean']['output'];
};

export type BuildingPublic = {
  __typename?: 'BuildingPublic';
  address: Scalars['String']['output'];
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  integrations?: Maybe<BuildingIntegrations>;
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  postcode?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  streetName?: Maybe<Scalars['String']['output']>;
  taxes: BuildingTaxes;
  timezone: Scalars['String']['output'];
  uuid: Scalars['UUID']['output'];
  viewerRelations: BuildingViewerRelations;
};

export type BuildingSyncFailure = {
  __typename?: 'BuildingSyncFailure';
  localisedReason?: Maybe<LocalisedMessage>;
  reason: Scalars['String']['output'];
};

export type BuildingSyncResult = BuildingSyncFailure | BuildingSyncSuccess;

export type BuildingSyncSuccess = {
  __typename?: 'BuildingSyncSuccess';
  building: Building;
};

export type BuildingTaxRate = {
  __typename?: 'BuildingTaxRate';
  /** e.g. GST */
  label: Scalars['String']['output'];
  /** e.g. 0.1 = 10% */
  rate: Scalars['Float']['output'];
  uuid: Scalars['UUID']['output'];
};

export type BuildingTaxes = {
  __typename?: 'BuildingTaxes';
  /** Applicable currency for the building. */
  currency: Currency;
  /**
   * Whether product unit prices are displayed inclusive or
   * exclusive of tax.
   */
  taxInclusive: Scalars['Boolean']['output'];
  taxRates: Array<BuildingTaxRate>;
};

export type BuildingViewerRelations = {
  __typename?: 'BuildingViewerRelations';
  canManageBookings: Scalars['Boolean']['output'];
  canManageCompanyBookings: Scalars['Boolean']['output'];
  canObserveCompanyBookings: Scalars['Boolean']['output'];
  requestAssignee: Scalars['Boolean']['output'];
  requestManager: Scalars['Boolean']['output'];
};

export type BulkSpaceInput = {
  buildingLevelUuid: Scalars['UUID']['input'];
  /** @deprecated Use VisibilityType instead. Will be removed eventually. */
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  ownerCompanyUuid?: InputMaybe<Scalars['UUID']['input']>;
  spaces: Array<Scalars['String']['input']>;
  visibilityType?: InputMaybe<SpaceVisibilityType>;
};

export type CmsArticle = {
  __typename?: 'CMSArticle';
  draftContent: CmsArticleContent;
  draftIsDifferentToPublished: Scalars['Boolean']['output'];
  draftVersionId?: Maybe<Scalars['UUID']['output']>;
  draftVersionLastSaved?: Maybe<Scalars['DateTime']['output']>;
  isArchived: Scalars['Boolean']['output'];
  isDeleteable: Scalars['Boolean']['output'];
  isPublishable: Scalars['Boolean']['output'];
  publicationAuthorType: PublicationAuthorType;
  publicationDate?: Maybe<Scalars['String']['output']>;
  publicationDateType: PublicationDateType;
  publicationTime?: Maybe<Scalars['Time']['output']>;
  publishedContent?: Maybe<CmsArticleContent>;
  publishedVersionId?: Maybe<Scalars['UUID']['output']>;
  publishedVersionLastSaved?: Maybe<Scalars['DateTime']['output']>;
  siteArticles: Array<CmsSiteArticle>;
  status: CmsArticleStatus;
  uuid: Scalars['UUID']['output'];
};

export type CmsArticleConnection = {
  __typename?: 'CMSArticleConnection';
  edges: Array<CmsArticleEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type CmsArticleContent = {
  __typename?: 'CMSArticleContent';
  body?: Maybe<Scalars['String']['output']>;
  downloads?: Maybe<Array<DocumentV2>>;
  excerpt?: Maybe<Scalars['String']['output']>;
  images?: Maybe<Array<DocumentV2>>;
  title?: Maybe<Scalars['String']['output']>;
};

export type CmsArticleEdge = {
  __typename?: 'CMSArticleEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<CmsArticle>;
};

export type CmsArticleStatus =
  | 'ARCHIVED'
  | 'DRAFT'
  | 'PUBLISHED'
  | 'UNPUBLISHED_CHANGES';

export type CmsFileInput = {
  filename: Scalars['String']['input'];
  key?: InputMaybe<Scalars['String']['input']>;
  temporaryUuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type CmsSearchFilters = {
  hasUnpublishedChanges?: InputMaybe<Scalars['Boolean']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<Array<Scalars['UUID']['input']>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type CmsSiteArticle = {
  __typename?: 'CMSSiteArticle';
  article: CmsArticle;
  audience?: Maybe<CmsSiteAudience>;
  isSyncedToSegmentation: Scalars['Boolean']['output'];
  publicationAuthor?: Maybe<Profile>;
  publicationAuthorType: PublicationAuthorType;
  siteUuid: Scalars['UUID']['output'];
  uuid: Scalars['UUID']['output'];
};

export type CmsSiteArticleAudienceInput = {
  segmentIds: Array<Scalars['String']['input']>;
  segmentSummary: Scalars['String']['input'];
};

export type CmsSiteArticleInput = {
  audience?: InputMaybe<CmsSiteArticleAudienceInput>;
  publicationAuthor?: InputMaybe<PublicationAuthorInput>;
  publicationAuthorType?: InputMaybe<PublicationAuthorType>;
  siteUuid: Scalars['UUID']['input'];
};

export type CmsSiteAudience = {
  __typename?: 'CMSSiteAudience';
  segmentIds: Array<Scalars['String']['output']>;
  segmentSummary: Scalars['String']['output'];
};

export type CacheControlScope =
  | 'PRIVATE'
  | 'PUBLIC';

export type CancellingUserRole =
  | 'HOST'
  | 'SYSTEM'
  | 'USER';

export type Card = {
  __typename?: 'Card';
  /** Card brand */
  brand: CardBrand;
  /** The date when the Card was created. */
  created: Scalars['DateTime']['output'];
  /** Is this card the default for the customer/profile? */
  default: Scalars['Boolean']['output'];
  /** Optional description for the card (eg. Visa work card) */
  description?: Maybe<Scalars['String']['output']>;
  /** Month of expiry */
  expiryMonth: Scalars['Int']['output'];
  /** Year of expiry */
  expiryYear: Scalars['Int']['output'];
  /** Last 4 of the long number on the card  */
  last4: Scalars['String']['output'];
  /** How the payment gateway identifies this card */
  paymentGatewayCardId: Scalars['String']['output'];
  /** The date when the Card was last updated. */
  updated: Scalars['DateTime']['output'];
  /** The UUID of the card for reference on the StoreNG side  */
  uuid: Scalars['UUID']['output'];
};

export type CardBrand =
  | 'AMERICAN_EXPRESS'
  | 'DINERS_CLUB'
  | 'DISCOVER'
  | 'JCB'
  | 'MASTERCARD'
  | 'UNIONPAY'
  | 'UNKNOWN'
  | 'VISA';

export type Cart = {
  __typename?: 'Cart';
  availableDeliveryMethods: Array<CartDeliveryMethod>;
  customerLocation?: Maybe<Scalars['String']['output']>;
  discountTotal?: Maybe<Scalars['Int']['output']>;
  items: Array<CartItem>;
  preDiscountTotal?: Maybe<Scalars['Int']['output']>;
  /** Profile fields which the customer is requested to provide with this order. */
  profileFieldRequests: Array<ProductProfileField>;
  selectedDeliveryMethod: CartDeliveryMethod;
  subTotal: Scalars['Int']['output'];
  tax: Scalars['Int']['output'];
  /** Tax inc or exc depending on tax region */
  total: Scalars['Int']['output'];
  turnAroundTime?: Maybe<Scalars['Int']['output']>;
  vendor: Vendor;
};

export type CartChoice = {
  __typename?: 'CartChoice';
  name: Scalars['String']['output'];
  /** Unit price * cartItem quantity */
  subTotal: Scalars['Int']['output'];
  /** Subtotal tax */
  tax: Scalars['Int']['output'];
  /** Tax inc or exc depending on tax region */
  unitPrice: Scalars['Int']['output'];
};

export type CartDeliveryMethod = {
  __typename?: 'CartDeliveryMethod';
  available: Scalars['Boolean']['output'];
  availableFrom?: Maybe<Scalars['DateTime']['output']>;
  availableUntil?: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  name: Scalars['String']['output'];
  portalCustomerInstructions?: Maybe<Scalars['String']['output']>;
  showVendorLocation: Scalars['Boolean']['output'];
  type: DeliveryType;
  /** Portal vendor delivery method uuid */
  uuid: Scalars['UUID']['output'];
  vendorCustomerInstructions?: Maybe<Scalars['String']['output']>;
  vendorInstructions?: Maybe<Scalars['String']['output']>;
};

export type CartFailure = {
  __typename?: 'CartFailure';
  code: CheckoutFailureCodes;
  message?: Maybe<Scalars['String']['output']>;
};

export type CartInput = {
  deliveryMethodUuid?: InputMaybe<Scalars['UUID']['input']>;
  discountCode?: InputMaybe<Scalars['String']['input']>;
  items: Array<OrderLineItemInput>;
};

export type CartItem = {
  __typename?: 'CartItem';
  mods: Array<CartMod>;
  product: Product;
  quantity: Scalars['Int']['output'];
  /** Tax inc or exc depending on tax region */
  subTotal: Scalars['Int']['output'];
  tax: Scalars['Int']['output'];
};

export type CartMod = {
  __typename?: 'CartMod';
  choices: Array<CartChoice>;
  name: Scalars['String']['output'];
};

export type CartOutput = CartFailure | CartSuccess;

export type CartSuccess = {
  __typename?: 'CartSuccess';
  cart: Cart;
};

export type Category = {
  __typename?: 'Category';
  label: Scalars['String']['output'];
};

export type ChangePasswordErrorCode =
  | 'PASSWORD_MIN_LENGTH'
  | 'PASSWORD_MUST_CONTAIN_LETTERS'
  | 'PASSWORD_MUST_CONTAIN_NUMBERS'
  | 'TOKEN_EXPIRED'
  | 'TOKEN_NOT_FOUND'
  | 'UNKNOWN';

export type ChangePasswordErrorResponse = {
  __typename?: 'ChangePasswordErrorResponse';
  code: ChangePasswordErrorCode;
  message: Scalars['String']['output'];
};

export type ChangePasswordResult = ChangePasswordErrorResponse | ChangePasswordSuccessResponse;

export type ChangePasswordSuccessResponse = {
  __typename?: 'ChangePasswordSuccessResponse';
  success: Scalars['Boolean']['output'];
};

export type CheckInInfo = {
  __typename?: 'CheckInInfo';
  occurrence: Occurrence;
  rsvp: UserRsvp;
};

export type CheckInMetricInput = {
  appVersion?: InputMaybe<Scalars['String']['input']>;
  applicationId: Scalars['String']['input'];
  contentId: Scalars['String']['input'];
  contentTitle: Scalars['String']['input'];
  contentType: CortexEnricherContentType;
  id: Scalars['String']['input'];
  portalUuid: Scalars['String']['input'];
  timestamp: Scalars['DateTime']['input'];
  userAgent?: InputMaybe<Scalars['String']['input']>;
  userUuid: Scalars['String']['input'];
  value: AttendanceUpdate;
};

export type CheckInviteEmailResult = {
  __typename?: 'CheckInviteEmailResult';
  email: Scalars['String']['output'];
  error?: Maybe<Scalars['String']['output']>;
};

/** A failure that can happen when creating order via checkout */
export type CheckoutFailure = ICheckoutFailure & {
  __typename?: 'CheckoutFailure';
  code: CheckoutFailureCodes;
  message?: Maybe<Scalars['String']['output']>;
};

/**
 * Enumeration of all the possible failure codes during checkout.
 * This type is a concatenation of PaymentFailureCodes and checkout
 * specific failures.
 */
export type CheckoutFailureCodes =
  | 'AMOUNT_TOO_LARGE'
  | 'AMOUNT_TOO_SMALL'
  | 'BALANCE_INSUFFICIENT'
  | 'CARD_DECLINED'
  | 'DELIVERY_METHOD_INACTIVE'
  | 'DELIVERY_NOT_AVAILABLE'
  | 'DELIVERY_TIME_OUT_OF_RANGE'
  | 'EXPIRED_CARD'
  | 'MULTIPLE_SUBSCRIPTION_PRODUCTS'
  | 'ORDER_HAS_SUBSCRIPTION_AND_OTHER_PRODUCTS'
  | 'ORDER_MINIMUM_NOT_EXCEEDED'
  | 'OTHER'
  | 'PRODUCT_HIDDEN'
  | 'PRODUCT_OUT_OF_STOCK'
  | 'PRODUCT_TIME_OUT_OF_RANGE'
  | 'SITE_NOT_ENABLED'
  | 'SITE_NOT_OPEN'
  | 'STRIPE_REQUEST_INPUT'
  | 'VENDOR_CLOSED'
  | 'VENDOR_HIDDEN'
  | 'VENDOR_TNC_NOT_ACCEPTED';

/**
 * Enumeration of all the possible failure codes during checkout.
 * This type is a concatenation of PaymentFailureCodes and checkout
 * specific failures.
 */
export type CheckoutFailureCodesV2 =
  | 'AMOUNT_TOO_LARGE'
  | 'AMOUNT_TOO_SMALL'
  | 'AUTHENTICATION_REQUIRED'
  | 'BALANCE_INSUFFICIENT'
  | 'CARD_DECLINED'
  | 'DELIVERY_METHOD_INACTIVE'
  | 'DELIVERY_NOT_AVAILABLE'
  | 'DELIVERY_TIME_OUT_OF_RANGE'
  | 'EXPIRED_CARD'
  | 'MULTIPLE_SUBSCRIPTION_PRODUCTS'
  | 'ORDER_HAS_SUBSCRIPTION_AND_OTHER_PRODUCTS'
  | 'ORDER_MINIMUM_NOT_EXCEEDED'
  | 'OTHER'
  | 'PRODUCT_HIDDEN'
  | 'PRODUCT_OUT_OF_STOCK'
  | 'PRODUCT_TIME_OUT_OF_RANGE'
  | 'SITE_NOT_ENABLED'
  | 'SITE_NOT_OPEN'
  | 'STRIPE_REQUEST_INPUT'
  | 'VENDOR_CLOSED'
  | 'VENDOR_HIDDEN'
  | 'VENDOR_TNC_NOT_ACCEPTED';

/** A failure that can happen when creating order via checkout */
export type CheckoutFailureV2 = {
  __typename?: 'CheckoutFailureV2';
  code: CheckoutFailureCodesV2;
  localisedReason?: Maybe<LocalisedMessage>;
  message?: Maybe<Scalars['String']['output']>;
};

export type CheckoutOutput = CheckoutFailure | CheckoutSuccess;

export type CheckoutOutputV2 = CheckoutFailureV2 | CheckoutSuccessV2;

export type CheckoutSuccess = {
  __typename?: 'CheckoutSuccess';
  order: Order;
};

export type CheckoutSuccessV2 = {
  __typename?: 'CheckoutSuccessV2';
  order: Order;
  paymentIntent?: Maybe<PaymentIntent>;
};

export type Choice = Node & {
  __typename?: 'Choice';
  available: Scalars['Boolean']['output'];
  /** If true choice can be delete */
  canDelete?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  /**
   * @deprecated unit price is from mod choice
   * @deprecated unit price is from mod choice
   */
  unitPrice?: Maybe<Scalars['Int']['output']>;
  uuid: Scalars['UUID']['output'];
};

export type ChoiceConnection = {
  __typename?: 'ChoiceConnection';
  edges: Array<Maybe<ChoiceEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ChoiceEdge = {
  __typename?: 'ChoiceEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Choice>;
};

export type ChoicesSearchBy =
  | 'NAME';

export type ChoicesSearchFilter = {
  by: ChoicesSearchBy;
  value: Scalars['String']['input'];
};

export type Client = {
  __typename?: 'Client';
  destinationCount: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  quickSightSessionName?: Maybe<Scalars['String']['output']>;
  region: ClientRegion;
  uuid: Scalars['UUID']['output'];
};

export type ClientRegion =
  | 'AU'
  | 'CA'
  | 'EU'
  | 'US';

export type ClientResult = ClientSuccessResponse | FailureResponse;

export type ClientSuccessResponse = {
  __typename?: 'ClientSuccessResponse';
  client: Client;
};

export type CloneBookableResourceOverrides = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type CollectionContentType =
  | 'BOOKABLE_PRODUCT'
  | 'BUILDING_INFO_POST'
  | 'DEAL_PRODUCT'
  | 'DEAL_VENDOR'
  | 'EVENT_POST'
  | 'NEWS_POST'
  | 'PLAIN_PRODUCT'
  | 'SUBSCRIPTION_PRODUCT'
  | 'VENDOR';

export type Comment = {
  __typename?: 'Comment';
  author?: Maybe<Profile>;
  authorUuid?: Maybe<Scalars['UUID']['output']>;
  body: Scalars['String']['output'];
  comments: Array<Comment>;
  contentUuid: Scalars['UUID']['output'];
  created: Scalars['DateTime']['output'];
  parent?: Maybe<Comment>;
  site?: Maybe<Destination>;
  siteUuid: Scalars['UUID']['output'];
  uuid: Scalars['UUID']['output'];
};

/** Comment connection type */
export type CommentConnection = {
  __typename?: 'CommentConnection';
  edges: Array<Maybe<CommentEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CommentEdge = {
  __typename?: 'CommentEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Comment>;
};

export type CommentMetricInput = {
  appVersion?: InputMaybe<Scalars['String']['input']>;
  applicationId: Scalars['String']['input'];
  commentBody: Scalars['String']['input'];
  contentType: ContentType;
  contentUuid: Scalars['UUID']['input'];
  id: Scalars['UUID']['input'];
  parentCommentUuid?: InputMaybe<Scalars['UUID']['input']>;
  siteUuid: Scalars['UUID']['input'];
  timestamp: Scalars['DateTime']['input'];
  type: CommentOperationType;
  userAgent?: InputMaybe<Scalars['String']['input']>;
  userUuid?: InputMaybe<Scalars['String']['input']>;
};

export type CommentOperationType =
  | 'COMMENT'
  | 'REMOVE_COMMENT';

/** An organisation to which a user's profile may belong. */
export type Company = {
  __typename?: 'Company';
  /** The name of the company. */
  name: Scalars['String']['output'];
  /** The uuid of the company. */
  uuid: Scalars['UUID']['output'];
};

export type CompanyAttribute = {
  __typename?: 'CompanyAttribute';
  name: Scalars['String']['output'];
  uuid: Scalars['UUID']['output'];
};

export type CompanyAttributeResult = CompanyAttributeSuccessResponse | FailureResponse;

export type CompanyAttributeSuccessResponse = {
  __typename?: 'CompanyAttributeSuccessResponse';
  companyAttribute: CompanyAttribute;
};

export type CompanyBookableResourceBuilding = {
  __typename?: 'CompanyBookableResourceBuilding';
  building: Building;
  buildingLevels: Array<BuildingLevel>;
  buildingUuid: Scalars['UUID']['output'];
  companyUuid: Scalars['UUID']['output'];
};

export type CompanyBookableResourceDestination = {
  __typename?: 'CompanyBookableResourceDestination';
  buildings: Array<CompanyBookableResourceBuilding>;
  companyUuid: Scalars['UUID']['output'];
  destination?: Maybe<Destination>;
  destinationUuid: Scalars['UUID']['output'];
};

export type CompanyBookingSettings = {
  __typename?: 'CompanyBookingSettings';
  companyUuid: Scalars['UUID']['output'];
  requireBillingCustomerForInvoices: Scalars['Boolean']['output'];
};

export type CompanyBookingSettingsInput = {
  companyUuid: Scalars['UUID']['input'];
  requireBillingCustomerForInvoices: Scalars['Boolean']['input'];
};

export type CompanyDestination = {
  __typename?: 'CompanyDestination';
  /** AC1 Company configuration for a Site */
  ac1Config?: Maybe<Ac1CompanySiteConfig>;
  attributes: Array<CompanyAttribute>;
  autoApproveRegistrations: Scalars['Boolean']['output'];
  buildingLevels: Array<BuildingLevel>;
  company: CompanyV2;
  companyUuid: Scalars['UUID']['output'];
  destination?: Maybe<Destination>;
  destinationUuid: Scalars['UUID']['output'];
  integrations?: Maybe<CompanyDestinationIntegrations>;
  leases?: Maybe<Array<Lease>>;
  profiles?: Maybe<FullProfileConnection>;
  validationMessage?: Maybe<Scalars['String']['output']>;
};


export type CompanyDestinationLeasesArgs = {
  includeCurrent?: InputMaybe<Scalars['Boolean']['input']>;
  includePast?: InputMaybe<Scalars['Boolean']['input']>;
};


export type CompanyDestinationProfilesArgs = {
  page: PageOptions;
};

export type CompanyDestinationInput = {
  autoApproveRegistrations?: InputMaybe<Scalars['Boolean']['input']>;
  buildingLevels?: InputMaybe<Array<Scalars['UUID']['input']>>;
  companyAttributes?: InputMaybe<Array<Scalars['UUID']['input']>>;
  destinationUuid: Scalars['UUID']['input'];
  validationMessage?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyDestinationIntegrations = {
  __typename?: 'CompanyDestinationIntegrations';
  yardi?: Maybe<YardiCompanyDestinationIntegration>;
};

export type CompanyDestinationIntegrationsInput = {
  companyUuid: Scalars['UUID']['input'];
  destinationUuid: Scalars['UUID']['input'];
  yardi?: InputMaybe<YardiCompanyDestinationIntegrationInput>;
};

export type CompanyIndustry = {
  __typename?: 'CompanyIndustry';
  companyCount: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  uuid: Scalars['UUID']['output'];
};

/** Input for creating / updating a Company. */
export type CompanyInput = {
  /** Name of the company. */
  name: Scalars['String']['input'];
  /** Unique identifier for the Company. */
  uuid: Scalars['UUID']['input'];
};

export type CompanyV2 = {
  __typename?: 'CompanyV2';
  active: Scalars['Boolean']['output'];
  billingCustomers: Array<BillingCustomer>;
  bookingSettings?: Maybe<CompanyBookingSettings>;
  companyDestination?: Maybe<CompanyDestination>;
  companyDestinations: Array<CompanyDestination>;
  destinationCount: Scalars['Int']['output'];
  destinations: Array<Destination>;
  flexOperator?: Maybe<FlexOperator>;
  flexTenants: Array<FlexTenant>;
  hasFlexOperator: Scalars['Boolean']['output'];
  industry?: Maybe<CompanyIndustry>;
  integrations?: Maybe<CompanyV2Integrations>;
  isValidEmail: ActionResult;
  name: Scalars['String']['output'];
  profiles?: Maybe<FullProfileConnection>;
  reqMgtRequestPermissions: ReqMgtRequestPermissions;
  /** @deprecated Incorrectly modeled. Use employeeCount on Lease instead. */
  size?: Maybe<Scalars['Int']['output']>;
  uuid: Scalars['UUID']['output'];
  validation?: Maybe<AssociationValidation>;
};


export type CompanyV2CompanyDestinationArgs = {
  destinationUuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type CompanyV2IsValidEmailArgs = {
  email: Scalars['String']['input'];
};


export type CompanyV2ProfilesArgs = {
  page: PageOptions;
};

export type CompanyV2Connection = {
  __typename?: 'CompanyV2Connection';
  edges: Array<CompanyV2Edge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CompanyV2Edge = {
  __typename?: 'CompanyV2Edge';
  cursor: Scalars['String']['output'];
  node?: Maybe<CompanyV2>;
};

export type CompanyV2EvolutionInput = {
  tenancyId: Scalars['String']['input'];
};

export type CompanyV2IntegrationInput = {
  companyUuid: Scalars['UUID']['input'];
  evolution?: InputMaybe<CompanyV2EvolutionInput>;
};

export type CompanyV2Integrations = {
  __typename?: 'CompanyV2Integrations';
  evolution: EvolutionCompanyV2Integration;
};

export type CompanyV2Sort = {
  asc: Scalars['Boolean']['input'];
  field: CompanyV2SortField;
};

export type CompanyV2SortField =
  | 'COMPANY_NAME'
  | 'INDUSTRY'
  | 'SITE_COUNT';

export type CompanyV2SyncFailure = {
  __typename?: 'CompanyV2SyncFailure';
  reason: Scalars['String']['output'];
};

export type CompanyV2SyncResult = CompanyV2SyncFailure | CompanyV2SyncSuccess;

export type CompanyV2SyncSuccess = {
  __typename?: 'CompanyV2SyncSuccess';
  companyV2: CompanyV2;
};

export type ConnectDisconnectBookingPaymentFailureResponse = {
  __typename?: 'ConnectDisconnectBookingPaymentFailureResponse';
  reason: Scalars['String']['output'];
};

export type ConnectDisconnectBookingPaymentResult = ConnectDisconnectBookingPaymentFailureResponse | ConnectDisconnectBookingPaymentSuccessResponse;

export type ConnectDisconnectBookingPaymentSuccessResponse = {
  __typename?: 'ConnectDisconnectBookingPaymentSuccessResponse';
  setting: BookingSetting;
};

export type ContentCollection = {
  __typename?: 'ContentCollection';
  items: Array<ContentCollectionItem>;
  name: Scalars['String']['output'];
  siteUuid: Scalars['UUID']['output'];
  uuid: Scalars['UUID']['output'];
};

export type ContentCollectionInput = {
  items?: InputMaybe<Array<ContentCollectionItemInput>>;
  name: Scalars['String']['input'];
  siteUuid: Scalars['UUID']['input'];
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type ContentCollectionItem = {
  __typename?: 'ContentCollectionItem';
  content: ContentUnion;
  type: CollectionContentType;
  uuid: Scalars['UUID']['output'];
};

export type ContentCollectionItemInput = {
  type: CollectionContentType;
  uuid: Scalars['UUID']['input'];
};

export type ContentConnection = {
  __typename?: 'ContentConnection';
  edges: Array<ContentUnion>;
  pageInfo: PageInfo;
};

export type ContentEdge = {
  cursor?: Maybe<Scalars['String']['output']>;
};

export type ContentFeaturedLevel =
  | 'COMBINED'
  | 'FEATURED'
  | 'NON_FEATURED';

export type ContentFilters = {
  /** To be used to with buildingInfo content type. */
  buildingInfoCategory?: InputMaybe<Scalars['UUID']['input']>;
  contentTypes: Array<SearchContentType>;
  featuredLevel: ContentFeaturedLevel;
  restrictionLevel: ContentRestrictionLevel;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  /** Default DISTANCE_FROM_NOW */
  sort?: InputMaybe<ContentSortType>;
};

export type ContentInteractionType =
  | 'Comment'
  | 'Like'
  | 'RemoveComment'
  | 'RemoveLike'
  | 'RemoveRsvp'
  | 'Rsvp'
  | 'View';

export type ContentRestrictionLevel =
  | 'COMBINED'
  | 'NON_RESTRICTED'
  | 'RESTRICTED';

export type ContentSortType =
  | 'DISTANCE_FROM_NOW'
  | 'RELEVANCE';

export type ContentType =
  | 'COMMUNITY_POST'
  | 'EVENT_POST'
  | 'NEWS_POST';

export type ContentUnion = ArticleEdge | BookableProductContentEdge | BuildingInfoEdge | DealContentEdge | DealVendorContentEdge | EventOccurrenceEdge | FeedbackEdge | NewsEdge | PlainProductContentEdge | SubscriptionProductContentEdge | VendorContentEdge;

export type Cookie = {
  __typename?: 'Cookie';
  expiryPolicy: Scalars['String']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
  whyItsUsed: Scalars['String']['output'];
};

export type CookieInput = {
  expiryPolicy: Scalars['String']['input'];
  name: Scalars['String']['input'];
  type: Scalars['String']['input'];
  whyItsUsed: Scalars['String']['input'];
};

/** Coordinates for a location */
export type Coordinate = {
  __typename?: 'Coordinate';
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
};

export type CortexEnricherContentType =
  | 'EVENT'
  | 'SESSION';

export type CortexEnricherProfileRegistrationType =
  | 'COMMERCIAL'
  | 'RESIDENTIAL'
  | 'VISITOR';

export type CortexEnricherRole =
  | 'AUTHENTICATED'
  | 'SERVICE';

export type CreateAvatarInput = {
  contentType: AvatarImageType;
  filename: Scalars['String']['input'];
};

export type CreateAvatarResult = {
  __typename?: 'CreateAvatarResult';
  imgixUrl: Scalars['URI']['output'];
  key: Scalars['String']['output'];
  signedUrl: Scalars['URI']['output'];
};

export type CreateBookingAdjustmentInput = {
  amount: Scalars['Int']['input'];
  reason: Scalars['String']['input'];
};

export type CreateBookingBlockOutFailureResponse = {
  __typename?: 'CreateBookingBlockOutFailureResponse';
  reason: Scalars['String']['output'];
};

export type CreateBookingBlockOutResult = CreateBookingBlockOutFailureResponse | CreateBookingBlockOutSuccessResponse;

export type CreateBookingBlockOutSuccessResponse = {
  __typename?: 'CreateBookingBlockOutSuccessResponse';
  bookingBlockOuts: Array<BookingBlockOut>;
};

export type CreateBookingPartialRefundInput = {
  amount: Scalars['Int']['input'];
  reason: Scalars['String']['input'];
};

export type CreateCmsArticleInput = {
  authorName?: InputMaybe<Scalars['String']['input']>;
  body?: InputMaybe<Scalars['String']['input']>;
  downloads?: InputMaybe<Array<CmsFileInput>>;
  excerpt?: InputMaybe<Scalars['String']['input']>;
  images?: InputMaybe<Array<CmsFileInput>>;
  portfolio: Scalars['UUID']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

export type CreateDestinationInput = {
  address: Scalars['String']['input'];
  city?: InputMaybe<Scalars['String']['input']>;
  client: Scalars['UUID']['input'];
  country?: InputMaybe<Scalars['String']['input']>;
  installation?: InputMaybe<Scalars['String']['input']>;
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  postcode?: InputMaybe<Scalars['String']['input']>;
  reportingRegion?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  streetName?: InputMaybe<Scalars['String']['input']>;
  timezone: Scalars['String']['input'];
};

export type CreateFileResult = {
  __typename?: 'CreateFileResult';
  imgixUrl: Scalars['URI']['output'];
  key: Scalars['String']['output'];
  signedUrl: Scalars['URI']['output'];
};

export type CreateImageInput = {
  contentType?: InputMaybe<AdminImageType>;
  filename: Scalars['String']['input'];
};

export type CreateImageResult = {
  __typename?: 'CreateImageResult';
  imgixUrl: Scalars['URI']['output'];
  key: Scalars['String']['output'];
  signedUrl: Scalars['URI']['output'];
};

/** Autogenerated input type of CreateIntegration */
export type CreateIntegrationInput = {
  /** Pass name will display on mobile in AccessPasses. Used only for virtual card integrations */
  accessPassName?: InputMaybe<Scalars['String']['input']>;
  buildingIntegrations?: InputMaybe<Array<Ac1BuildingIntegrationAttributes>>;
  /** @deprecated Is not going to be used, we will set in different level */
  builtInVirtualCredentials?: InputMaybe<Scalars['Boolean']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  config?: InputMaybe<Scalars['JSON']['input']>;
  connectionAndKeyPassword?: InputMaybe<Scalars['String']['input']>;
  connectionCertificate?: InputMaybe<Scalars['String']['input']>;
  connectionKey?: InputMaybe<Scalars['String']['input']>;
  externalApiBaseUrl: Scalars['URL']['input'];
  externalApiDataUrl?: InputMaybe<Scalars['URL']['input']>;
  externalApiKey?: InputMaybe<Scalars['String']['input']>;
  externalApiSuffix?: InputMaybe<Scalars['String']['input']>;
  externalApiTokenSuffix?: InputMaybe<Scalars['String']['input']>;
  externalClientId?: InputMaybe<Scalars['String']['input']>;
  externalClientPassword?: InputMaybe<Scalars['String']['input']>;
  externalClientScope?: InputMaybe<Scalars['String']['input']>;
  externalClientSecret?: InputMaybe<Scalars['String']['input']>;
  externalClientUsername?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  provider: Scalars['String']['input'];
  siteUuid: Scalars['UUID']['input'];
  /** @deprecated Is not going to be used, we will set in different level */
  virtualCredentialsProviderUuid?: InputMaybe<Scalars['UUID']['input']>;
};

/** Autogenerated return type of CreateIntegration. */
export type CreateIntegrationPayload = {
  __typename?: 'CreateIntegrationPayload';
  ac1Integration?: Maybe<Ac1Integration>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type CreateLeaseInput = {
  breakDate?: InputMaybe<Scalars['DateTime']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  businessNumberType?: InputMaybe<Scalars['String']['input']>;
  companyUuid: Scalars['UUID']['input'];
  destinationUuid: Scalars['UUID']['input'];
  employeeCount?: InputMaybe<Scalars['Int']['input']>;
  expiryDate: Scalars['DateTime']['input'];
  name: Scalars['String']['input'];
  notificationDate?: InputMaybe<Scalars['DateTime']['input']>;
  size: Scalars['Int']['input'];
  startDate: Scalars['DateTime']['input'];
};

export type CreateUpdateBookingDiscountInput = {
  availability: BookingDiscountAvailabilityInput;
  percentage: Scalars['Int']['input'];
  resourceUuids: Array<Scalars['UUID']['input']>;
  title: Scalars['String']['input'];
  type: BookingDiscountType;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type CreateWorkRequestInput = {
  attachments?: InputMaybe<Array<Scalars['URI']['input']>>;
  buildingId: Scalars['ID']['input'];
  categoryId: Scalars['ID']['input'];
  description: Scalars['String']['input'];
  locationId: Scalars['ID']['input'];
  requestedFor: Scalars['ID']['input'];
  scheduledHours?: InputMaybe<ScheduledHoursInput>;
  specificLocation?: InputMaybe<Scalars['String']['input']>;
};

export type CreditAccount = {
  __typename?: 'CreditAccount';
  balance: Scalars['Int']['output'];
  created: Scalars['DateTime']['output'];
  createdBy: Scalars['UUID']['output'];
  creditCustomer: CreditCustomer;
  creditCustomerUuid: Scalars['UUID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  type: CreditAccountType;
  updated: Scalars['DateTime']['output'];
  updatedBy: Scalars['UUID']['output'];
  uuid: Scalars['UUID']['output'];
};


export type CreditAccountBalanceArgs = {
  asAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CreditAccountAdjustCreditsInput = {
  accountUuid: Scalars['UUID']['input'];
  amount: Scalars['Int']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
};

export type CreditAccountChargeInput = {
  accountUuid: Scalars['UUID']['input'];
  amount: Scalars['Int']['input'];
  chargeDate: Scalars['DateTime']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  metadata: CreditChargeMetadataInput;
  userUuid: Scalars['UUID']['input'];
};

export type CreditAccountCreateInput = {
  closingDate: Scalars['DateTime']['input'];
  creditCustomerUuid: Scalars['UUID']['input'];
  creditProviderUuid: Scalars['UUID']['input'];
  openingDate: Scalars['DateTime']['input'];
  recurringCreditAllowance: Scalars['Int']['input'];
  type: CreditAccountType;
  userUuid: Scalars['UUID']['input'];
};

export type CreditAccountPurchaseCreditsInput = {
  accountUuid: Scalars['UUID']['input'];
  purchasableItemUuid: Scalars['UUID']['input'];
  taxRateUuid: Scalars['UUID']['input'];
};

export type CreditAccountType =
  | 'RECURRING_WITH_PURCHASE';

export type CreditChargeMetadataInput = {
  bookingUuid: Scalars['UUID']['input'];
  buildingUuid: Scalars['UUID']['input'];
};

export type CreditCustomer = {
  __typename?: 'CreditCustomer';
  customerUuid: Scalars['UUID']['output'];
  flexTenant?: Maybe<FlexTenant>;
  type: CreditCustomerType;
  uuid: Scalars['UUID']['output'];
};

export type CreditCustomerCreateInput = {
  customerUuid: Scalars['UUID']['input'];
  type: CreditCustomerType;
};

export type CreditCustomerType =
  | 'FLEX_TENANT'
  | 'USER';

export type CreditProvider = {
  __typename?: 'CreditProvider';
  creditsPurchaseEnabled: Scalars['Boolean']['output'];
  providerUuid: Scalars['UUID']['output'];
  purchasableItems: Array<CreditPurchasableItem>;
  type: CreditProviderType;
  uuid: Scalars['UUID']['output'];
};

export type CreditProviderCreateInput = {
  providerUuid: Scalars['UUID']['input'];
  type: CreditProviderType;
};

export type CreditProviderType =
  | 'FLEX_OPERATOR';

export type CreditProviderUpdateInput = {
  creditProviderUuid: Scalars['UUID']['input'];
  creditsPurchaseEnabled: Scalars['Boolean']['input'];
  purchasableItems: Array<CreditPurchasableItemInput>;
};

export type CreditPurchasableItem = {
  __typename?: 'CreditPurchasableItem';
  creditValue: Scalars['Int']['output'];
  discount?: Maybe<Scalars['Int']['output']>;
  discountedNetPrice: Scalars['Int']['output'];
  netPrice: Scalars['Int']['output'];
  uuid: Scalars['UUID']['output'];
};

export type CreditPurchasableItemInput = {
  creditValue: Scalars['Int']['input'];
  discount?: InputMaybe<Scalars['Int']['input']>;
  netPrice: Scalars['Int']['input'];
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type CreditRole =
  | 'ANON'
  | 'PROPERTY_MANAGER'
  | 'SERVICE'
  | 'USER'
  | 'WORKPLACE_MANAGER';

export type CreditStats = {
  __typename?: 'CreditStats';
  creditsAdditional: Scalars['Int']['output'];
  creditsBalance: Scalars['Int']['output'];
  creditsCarriedOver: Scalars['Int']['output'];
  creditsMonthly: Scalars['Int']['output'];
  creditsTotal: Scalars['Int']['output'];
};

export type CreditStatsQueryRole =
  | 'FLEX_MANAGER'
  | 'WORKPLACE_MANAGER';

export type CreditSubAccount = {
  __typename?: 'CreditSubAccount';
  type: CreditSubAccountType;
  uuid: Scalars['UUID']['output'];
};

export type CreditSubAccountType =
  | 'MONTHLY_RECURRING'
  | 'PURCHASED';

export type CreditTransaction = {
  __typename?: 'CreditTransaction';
  account: CreditAccount;
  amount: Scalars['Int']['output'];
  booking?: Maybe<Booking>;
  created: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  purchasedItem?: Maybe<CreditPurchasableItem>;
  runningBalance: Scalars['Int']['output'];
  taxRate?: Maybe<Scalars['Float']['output']>;
  transactionDetails: Array<CreditTransactionDetail>;
  type: CreditTransactionType;
  user: User;
  uuid: Scalars['UUID']['output'];
};

export type CreditTransactionBreakdown = {
  __typename?: 'CreditTransactionBreakdown';
  additionalCreditsCharged: Scalars['Int']['output'];
  monthlyCreditsCharged: Scalars['Int']['output'];
};

export type CreditTransactionConnection = {
  __typename?: 'CreditTransactionConnection';
  edges: Array<CreditTransactionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CreditTransactionDateFilter = {
  end: Scalars['DateTime']['input'];
  start: Scalars['DateTime']['input'];
};

export type CreditTransactionDetail = {
  __typename?: 'CreditTransactionDetail';
  amount: Scalars['Int']['output'];
  date: Scalars['DateTime']['output'];
  subAccount: CreditSubAccount;
  uuid: Scalars['UUID']['output'];
};

export type CreditTransactionEdge = {
  __typename?: 'CreditTransactionEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<CreditTransaction>;
};

export type CreditTransactionFilter = {
  created?: InputMaybe<CreditTransactionDateFilter>;
  flexTenantUuids?: InputMaybe<Array<Scalars['UUID']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  transactionDetailDate?: InputMaybe<CreditTransactionDateFilter>;
  types?: InputMaybe<Array<CreditTransactionType>>;
  userUuids?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type CreditTransactionRefundAndChargeInput = {
  chargeAmount: Scalars['Int']['input'];
  chargeDate: Scalars['DateTime']['input'];
  chargeDescription?: InputMaybe<Scalars['String']['input']>;
  refundDescription?: InputMaybe<Scalars['String']['input']>;
  transactionUuid: Scalars['UUID']['input'];
  userUuid: Scalars['UUID']['input'];
};

export type CreditTransactionRefundInput = {
  amount: Scalars['Int']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  transactionUuid: Scalars['UUID']['input'];
  userUuid: Scalars['UUID']['input'];
};

export type CreditTransactionType =
  | 'ADJUSTMENT'
  | 'CHARGE'
  | 'PURCHASE'
  | 'REFUND';

export type CreditTransactionsExportCsvInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  as: CreditTransactionsQueryRole;
  filter?: InputMaybe<CreditTransactionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type CreditTransactionsQueryRole =
  | 'FLEX_MANAGER'
  | 'WORKPLACE_MANAGER';

/** Currency label */
export type Currency = {
  __typename?: 'Currency';
  /** ISO 4217 currency code, e.g. AUD */
  code: Scalars['String']['output'];
  /** e.g. Australian Dollars */
  name: Scalars['String']['output'];
};

export type CustomerCheckInInfo = {
  __typename?: 'CustomerCheckInInfo';
  session: Session;
  userBooking: UserBooking;
};

export type DataType =
  | 'COUNT'
  | 'CURRENCY'
  | 'NUMBER'
  | 'TIMESPAN';

export type DateFilter = {
  amount: Scalars['Int']['input'];
  comparator: Scalars['String']['input'];
  interval: Scalars['String']['input'];
};

export type DateFilterOperatorV3 =
  | 'GTE'
  | 'LTE';

export type DateFilterV2 = {
  comparisonOperator: Scalars['String']['input'];
  timestamp: Scalars['DateTime']['input'];
};

export type DateFilterV3 = {
  comparisonOperator: DateFilterOperatorV3;
  timestamp: Scalars['DateTime']['input'];
};

/** Day of the week for opening hours */
export type DayOfTheWeek =
  | 'FRIDAY'
  | 'MONDAY'
  | 'SATURDAY'
  | 'SUNDAY'
  | 'THURSDAY'
  | 'TUESDAY'
  | 'WEDNESDAY';

export type DeactivateUserResponse = FailedDeactivateUserResponse | SuccessfulDeactivateUserResponse;

export type DeactivationReminderInput = {
  emailBody: Scalars['String']['input'];
  emailSubjectLine: Scalars['String']['input'];
  inactivityUnit: InactivityUnit;
  inactivityValue: Scalars['Int']['input'];
};

export type Deal = {
  __typename?: 'Deal';
  affiliateLinkName?: Maybe<Scalars['String']['output']>;
  affiliateLinkUrl?: Maybe<Scalars['String']['output']>;
  availability: ProductAvailability;
  body: Scalars['String']['output'];
  dealType: DealType;
  description?: Maybe<Scalars['String']['output']>;
  excerpt: Scalars['String']['output'];
  focalPoint?: Maybe<FocalPoint>;
  image?: Maybe<Scalars['URI']['output']>;
  imageKey?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  productCategories: Array<ProductCategory>;
  /** Whether to show the overlay icons or not */
  showOverlay: Scalars['Boolean']['output'];
  showTermsAndConditions: Scalars['Boolean']['output'];
  /** @deprecated use sitesWithAudience endpoint */
  sites: Array<Portal>;
  /** Portals associated with the product */
  sitesWithAudience: Array<SiteSegment>;
  termsAndConditions?: Maybe<Scalars['String']['output']>;
  uuid: Scalars['UUID']['output'];
  vendor: VendorInterface;
  visibility: ProductVisibility;
};


export type DealImageArgs = {
  input?: InputMaybe<ImageInput>;
};

export type DealConnection = {
  __typename?: 'DealConnection';
  edges: Array<Maybe<DealEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type DealContentEdge = ContentEdge & {
  __typename?: 'DealContentEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Deal>;
};

export type DealEdge = {
  __typename?: 'DealEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Deal>;
};

export type DealType =
  | 'AFFILIATE_LINK'
  | 'NON_REDEEMABLE'
  | 'REDEEMABLE';

export type DealVendor = VendorInterface & {
  __typename?: 'DealVendor';
  /** Combined address field */
  address: Scalars['String']['output'];
  /** City component of address */
  city?: Maybe<Scalars['String']['output']>;
  country: Scalars['String']['output'];
  countryCode: Scalars['String']['output'];
  /** Short description of the vendor */
  description?: Maybe<Scalars['String']['output']>;
  excerpt: Scalars['String']['output'];
  focalPoint?: Maybe<FocalPoint>;
  image?: Maybe<Scalars['URI']['output']>;
  imageKey?: Maybe<Scalars['String']['output']>;
  location: Coordinate;
  /** Customer friendly vendor name */
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  portals: Array<Portal>;
  /** Post code component of address */
  postCode?: Maybe<Scalars['String']['output']>;
  /** Product categories that vendor has, e.g Drinks */
  productCategories: Array<ProductCategory>;
  productCount: Scalars['Int']['output'];
  products: DealConnection;
  /** State component of address */
  state?: Maybe<Scalars['String']['output']>;
  /** Street component of address */
  street?: Maybe<Scalars['String']['output']>;
  thirdPartyUrl?: Maybe<Scalars['URI']['output']>;
  uuid: Scalars['UUID']['output'];
  /** Vendor categories e.g. Breakfast, Lunch, Dinner */
  vendorCategories: Array<VendorCategory>;
  visibilityStatus: VendorVisibilityStatus;
  website?: Maybe<Scalars['String']['output']>;
};


export type DealVendorImageArgs = {
  input?: InputMaybe<ImageInput>;
};


export type DealVendorPortalsArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
};


export type DealVendorProductCategoriesArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
};


export type DealVendorProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProductFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Array<VendorProductsSearchFilter>>;
};

export type DealVendorConnection = {
  __typename?: 'DealVendorConnection';
  edges: Array<Maybe<DealVendorEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type DealVendorContentEdge = ContentEdge & {
  __typename?: 'DealVendorContentEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<DealVendor>;
};

export type DealVendorEdge = {
  __typename?: 'DealVendorEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<DealVendor>;
};

export type DealVendorOrderBy = {
  descending: Scalars['Boolean']['input'];
  field: DealVendorSortableFields;
};

export type DealVendorSortableFields =
  | 'NAME'
  | 'SITES'
  | 'STATUS';

export type DeleteAttachment = {
  __typename?: 'DeleteAttachment';
  filename: Scalars['String']['output'];
  key: Scalars['String']['output'];
};

export type DeleteBookingBlockOutResult = {
  __typename?: 'DeleteBookingBlockOutResult';
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeleteIntegration */
export type DeleteIntegrationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  uuid: Scalars['UUID']['input'];
};

/** Autogenerated return type of DeleteIntegration. */
export type DeleteIntegrationPayload = {
  __typename?: 'DeleteIntegrationPayload';
  ac1Integration?: Maybe<Ac1Integration>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type DeleteResult = {
  __typename?: 'DeleteResult';
  successful: Scalars['Boolean']['output'];
};

export type DeleteSegmentationFilterItemInput = {
  key?: InputMaybe<Scalars['String']['input']>;
  site: Scalars['UUID']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
};

export type DeleteUsersError = {
  __typename?: 'DeleteUsersError';
  email?: Maybe<Scalars['String']['output']>;
  reason: Scalars['String']['output'];
  userUuid: Scalars['UUID']['output'];
};

export type DeleteUsersResult = {
  __typename?: 'DeleteUsersResult';
  errors: Array<DeleteUsersError>;
  success: Scalars['Boolean']['output'];
  updatedCount: Scalars['Int']['output'];
};

export type DeliveryLocation = {
  __typename?: 'DeliveryLocation';
  name: Scalars['String']['output'];
  uuid: Scalars['UUID']['output'];
};

export type DeliveryMethod = {
  __typename?: 'DeliveryMethod';
  active: Scalars['Boolean']['output'];
  availableFrom?: Maybe<Scalars['DateTime']['output']>;
  availableFromTime?: Maybe<Scalars['Time']['output']>;
  availableUntil?: Maybe<Scalars['DateTime']['output']>;
  availableUntilTime?: Maybe<Scalars['Time']['output']>;
  description: Scalars['String']['output'];
  name: Scalars['String']['output'];
  portalCustomerInstructions?: Maybe<Scalars['String']['output']>;
  showVendorLocation: Scalars['Boolean']['output'];
  type: DeliveryType;
  uuid: Scalars['UUID']['output'];
  /**
   * @deprecated field is on portal vendor delivery method
   * @deprecated field is on portal vendor delivery method
   */
  vendorCustomerInstructions?: Maybe<Scalars['String']['output']>;
  vendorInstructions?: Maybe<Scalars['String']['output']>;
};

export type DeliveryMethodInput = {
  customerLocation?: InputMaybe<Scalars['String']['input']>;
  uuid: Scalars['UUID']['input'];
};

export type DeliveryType =
  | 'DELIVERY'
  | 'PICKUP';

export type Destination = {
  __typename?: 'Destination';
  ac1Integrations?: Maybe<Array<Ac1Integration>>;
  /** @deprecated use accessControls */
  accessControl?: Maybe<AccessControlSystem>;
  accessControls: Array<AccessControlSystem>;
  /** Primary URL for the account app on this destination. */
  accountAppUrl?: Maybe<Scalars['URI']['output']>;
  active: Scalars['Boolean']['output'];
  /**
   * From address actually in use when sending emails from this destination.
   *
   * This can differ from fromEmailAddress for a number of reasons but this will always
   * be a valid email address which we are able to send from.
   */
  activeFromEmailAddress?: Maybe<Scalars['String']['output']>;
  address: Scalars['String']['output'];
  aggregations?: Maybe<SiteAggregations>;
  allowedLoginRealms: Array<Scalars['String']['output']>;
  /** @deprecated Use defaultAppEdition->androidAppId field */
  androidAppId: Scalars['String']['output'];
  apartments?: Maybe<Array<Apartment>>;
  app: DestinationApp;
  appEditions: Array<AppEdition>;
  apps: Array<DestinationApp>;
  areas?: Maybe<Array<Area>>;
  attributes?: Maybe<Array<SiteAttribute>>;
  autoDeactivate3MonthReminder: Scalars['Boolean']['output'];
  autoDeactivate6MonthReminder: Scalars['Boolean']['output'];
  autoDeactivate9MonthReminder: Scalars['Boolean']['output'];
  autoDeactivateUsers: Scalars['Boolean']['output'];
  buildings?: Maybe<Array<Building>>;
  city?: Maybe<Scalars['String']['output']>;
  client?: Maybe<Client>;
  companiesV2?: Maybe<CompanyV2Connection>;
  companyAttributes?: Maybe<Array<CompanyAttribute>>;
  cookiePolicy?: Maybe<Scalars['String']['output']>;
  /** cookie policy with original tokens intact */
  cookiePolicyRaw?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  /** Custom MX settings. */
  customMxSettings?: Maybe<Scalars['String']['output']>;
  defaultAppEdition?: Maybe<AppEdition>;
  defaultIrisAssignee?: Maybe<PartialUser>;
  defaultIrisAuthor?: Maybe<PartialUser>;
  defaultLoginRealm: Scalars['String']['output'];
  deleted: Scalars['Boolean']['output'];
  domainErrors?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  /** @deprecated endpoint may not be in working order for webNG sites. Use webAppUrl */
  endpoint: Scalars['URI']['output'];
  /**
   * Implemented as an array type so that only the enabled feature modules will be
   * returned to clients.
   */
  featureModules: Array<FeatureModule>;
  /** From address configured to use when sending emails from this destination. */
  fromEmailAddress?: Maybe<Scalars['String']['output']>;
  gatewayEndpoint?: Maybe<Scalars['URI']['output']>;
  groups?: Maybe<Array<SiteGroup>>;
  hiddenStatusMessage: Scalars['String']['output'];
  history: Array<Maybe<DestinationChangeEvent>>;
  /**
   * deprecated. will return widgets of the first generation widget types such as news, events,
   * but excludes curated widget type and any future types.
   * @deprecated use homeUIWidgetsV2()
   */
  homeUIWidgets: Array<UiWidget>;
  homeUIWidgetsV2: Array<UiWidget>;
  /**
   * Entrypoint for information about the domain infrastructure for this destination.
   *
   * May be null if this is not a destination with managed infrastructure.
   */
  infrastructure?: Maybe<DestinationInfrastructure>;
  /** @deprecated Moved to the infrastructure field */
  infrastructureGroup: DestinationInfrastructureGroup;
  integrations?: Maybe<DestinationIntegrations>;
  /** @deprecated Use defaultAppEdition->iosAppBuildId field */
  iosAppBuildId: Scalars['String']['output'];
  /** @deprecated Use defaultAppEdition->iosAppId field */
  iosAppId: Scalars['String']['output'];
  latitude: Scalars['Float']['output'];
  locale: Scalars['String']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  logoSize?: Maybe<Scalars['String']['output']>;
  longitude: Scalars['Float']['output'];
  machineName: Scalars['String']['output'];
  managedByUCM: Scalars['Boolean']['output'];
  marketingOptinDefault: Scalars['Boolean']['output'];
  /** @deprecated Field no longer supported */
  marketingOptinLabel: Scalars['String']['output'];
  migratingToUCM: Scalars['Boolean']['output'];
  /** @deprecated use gateway */
  mobileBFFEndpoint?: Maybe<Scalars['URI']['output']>;
  name: Scalars['String']['output'];
  newWorldRegistrationMetric: Scalars['Boolean']['output'];
  /** @deprecated moved to dest -> settings -> branding -> email */
  newsletterFooter?: Maybe<Scalars['String']['output']>;
  occurrences?: Maybe<OccurrenceConnection>;
  paginatedApartments?: Maybe<ApartmentConnection>;
  paymentGatewayClientId?: Maybe<Scalars['String']['output']>;
  policyChangeLog: Array<PolicyChangeLogEvent>;
  postcode?: Maybe<Scalars['String']['output']>;
  primaryColour: Scalars['String']['output'];
  privacyPolicy?: Maybe<Scalars['String']['output']>;
  /** privacy policy with original tokens intact */
  privacyPolicyRaw?: Maybe<Scalars['String']['output']>;
  /**
   * Endpoint for the Profile API
   * @deprecated use gateway
   */
  profileApiEndpoint: Scalars['URI']['output'];
  regionName: Scalars['String']['output'];
  /** Reply-To address to use when sending emails from this destination. */
  replyToEmailAddress?: Maybe<Scalars['String']['output']>;
  reportingEnabled: Scalars['Boolean']['output'];
  reportingRegion?: Maybe<Scalars['String']['output']>;
  secondaryColour: Scalars['String']['output'];
  settings: DestinationSettings;
  siteFlexOperator?: Maybe<FlexOperator>;
  /** @deprecated Using openfga for all roles */
  siteManagers?: Maybe<Array<FullUser>>;
  /**
   * Endpoint for the SMS Checkin
   * @deprecated use gateway
   */
  smsCheckinEndpoint: Scalars['URI']['output'];
  socialLinks: Array<SocialLink>;
  state?: Maybe<Scalars['String']['output']>;
  status: DestinationStatus;
  streetName?: Maybe<Scalars['String']['output']>;
  taxRegion?: Maybe<TaxRegion>;
  termsAndConditions?: Maybe<Scalars['String']['output']>;
  /** terms and conditions with original tokens intact */
  termsAndConditionsRaw?: Maybe<Scalars['String']['output']>;
  thirdPartyCookies: Array<Cookie>;
  thirdPartyServiceProviders?: Maybe<Scalars['String']['output']>;
  /** The Equiem One tier level of this destination. */
  tierLevel?: Maybe<DestinationTierLevel>;
  timezone: Scalars['String']['output'];
  transitScreen?: Maybe<TransitScreen>;
  transitScreenV2?: Maybe<TransitScreen>;
  type: DestinationType;
  usersWithRelation: DestinationUsersWithRelation;
  uuid: Scalars['UUID']['output'];
  viewerRelations: DestinationViewerRelations;
  /**
   * Endpoint for the VMS
   * @deprecated use gateway
   */
  vmsEndpoint: Scalars['URI']['output'];
  /**
   * Primary web URL for this destination.
   *
   * This will be the current primary website URL.
   */
  webAppUrl?: Maybe<Scalars['URI']['output']>;
  /** Endpoint for web sockets over MQTT. See https://www.npmjs.com/package/@equiem/user-pubsub */
  webSocketEndpoint?: Maybe<Scalars['URI']['output']>;
};


export type DestinationApartmentsArgs = {
  areVacant?: InputMaybe<Scalars['Boolean']['input']>;
};


export type DestinationAppArgs = {
  id: DestinationAppId;
};


export type DestinationCompaniesV2Args = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type DestinationFeatureModulesArgs = {
  filter?: InputMaybe<FeatureModulesFilter>;
};


export type DestinationHomeUiWidgetsArgs = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  platform?: InputMaybe<PlatformType>;
};


export type DestinationHomeUiWidgetsV2Args = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  platform?: InputMaybe<PlatformType>;
  widgetTypes: Array<UiWidgetType>;
};


export type DestinationOccurrencesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  endBefore?: InputMaybe<Scalars['DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  startAfter?: InputMaybe<Scalars['DateTime']['input']>;
};


export type DestinationPaginatedApartmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  areVacant?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type DestinationPolicyChangeLogArgs = {
  type?: InputMaybe<PolicyChangeEventLogAction>;
};

export type DestinationApp = {
  __typename?: 'DestinationApp';
  builtInDomain: DestinationAppDomain;
  domains: Array<DestinationAppDomain>;
  id: DestinationAppId;
  primaryDomain: DestinationAppDomain;
};

export type DestinationAppDomain = {
  __typename?: 'DestinationAppDomain';
  domain: Scalars['String']['output'];
};

export type DestinationAppId =
  | 'account'
  | 'web';

export type DestinationBluepointInput = {
  enabled: Scalars['Boolean']['input'];
  newAsId?: InputMaybe<Scalars['Int']['input']>;
  newClientId?: InputMaybe<Scalars['String']['input']>;
  newClientSecret?: InputMaybe<Scalars['String']['input']>;
};

export type DestinationBuildingLevelInput = {
  buildingLevels: Array<Scalars['UUID']['input']>;
  destination: Scalars['UUID']['input'];
};

export type DestinationChangeEvent = {
  __typename?: 'DestinationChangeEvent';
  changeSet: Scalars['String']['output'];
  timestamp: Scalars['DateTime']['output'];
  triggeredBy?: Maybe<PartialUser>;
  uuid: Scalars['UUID']['output'];
};

export type DestinationConnection = {
  __typename?: 'DestinationConnection';
  edges: Array<DestinationEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** The domain name of a destination. */
export type DestinationDomainName = {
  __typename?: 'DestinationDomainName';
  /** The domain for this destination. */
  domain: DomainName;
  /** The site uuid the domain belongs to. */
  uuid: Scalars['UUID']['output'];
};

export type DestinationEdge = {
  __typename?: 'DestinationEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Destination>;
};

export type DestinationEvolutionInput = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DestinationFailure = {
  __typename?: 'DestinationFailure';
  reason: Scalars['String']['output'];
};

/** A grouping of various information related to the infrastructure of a destination. */
export type DestinationInfrastructure = {
  __typename?: 'DestinationInfrastructure';
  /**
   * A custom account management domain created by the client.
   *
   * Only null if there is no primary domain.
   */
  accountDomain?: Maybe<DomainName>;
  /** A list of DNS entries that must be created by the client. */
  dnsChecks: Array<DnsCheck>;
  /**
   * Equiem one domain name for the destination
   * @deprecated This is only used to figure out the site specific Equiem One instance when the user lands on equiem.one. Will be removed when that redirection is no longer valid or needed, which is in the near future
   */
  equiemOneDomain?: Maybe<DomainName>;
  /** The infrastructure group that a destination belongs to. */
  group: InfrastructureGroup;
  /** The primary web domain for this destination. */
  primaryDomain: DomainName;
  /** The reference or internal domain name used for this destination. */
  referenceDomain: DomainName;
  /** Whether the reference domain name is the primary domain for this destination. */
  referenceDomainIsPrimary: Scalars['Boolean']['output'];
  /** A list of all the custom web domains created by the client. */
  webDomains: Array<WebDomain>;
};

export type DestinationInfrastructureGroup = {
  __typename?: 'DestinationInfrastructureGroup';
  id: Scalars['ID']['output'];
};

export type DestinationIntegrationInput = {
  bluepoint?: InputMaybe<DestinationBluepointInput>;
  destinationUuid: Scalars['UUID']['input'];
  evolution?: InputMaybe<DestinationEvolutionInput>;
  hid?: InputMaybe<BasicDestinationIntegrationInput>;
  vicinitee?: InputMaybe<BasicDestinationIntegrationInput>;
};

export type DestinationIntegrations = {
  __typename?: 'DestinationIntegrations';
  ac1HidOrigo: Ac1HidOrigo;
  ac1Iseo: Ac1Iseo;
  ac1QrCode: Ac1QrCode;
  ac1SaltoKs: Ac1SaltoKs;
  bluepoint: BluepointDestinationIntegration;
  evolution: EvolutionDestinationIntegration;
  gallagher: GallagherDestinationIntegration;
  hid: BasicDestinationIntegration;
  openpath: OpenpathDestinationIntegration;
  swiftconnect: SwiftconnectDestinationIntegration;
  uuid: Scalars['UUID']['output'];
  vicinitee: BasicDestinationIntegration;
};

export type DestinationResult = DestinationFailure | DestinationSuccess;

export type DestinationSettings = {
  __typename?: 'DestinationSettings';
  accessControl?: Maybe<AccessControlSettings>;
  booking: BookingModuleSettings;
  branding: Branding;
  buildingInfo: BuildingInfo;
  emailVerification: EmailVerificationSettings;
  liveChatPK?: Maybe<Scalars['String']['output']>;
  newsAndEvents: NewsAndEventsSettings;
  parking: ParkingSettings;
  registration: RegistrationSettings;
  store: Store;
};

export type DestinationStatus =
  | 'DEACTIVATED'
  | 'HIDDEN'
  | 'LIVE';

export type DestinationSuccess = {
  __typename?: 'DestinationSuccess';
  destination: Destination;
};

export type DestinationTierLevel =
  | 'Essentials'
  | 'Unlimited';

export type DestinationType =
  | 'AUTH_API'
  | 'EQUIEM_ONE'
  | 'IRIS'
  | 'PORTAL'
  | 'UNKNOWN'
  | 'VMS';

export type DestinationUpcomingEventsFilters = {
  toDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DestinationUsersWithRelation = {
  __typename?: 'DestinationUsersWithRelation';
  manager: Array<Maybe<FullUser>>;
};

export type DestinationViewerRelations = {
  __typename?: 'DestinationViewerRelations';
  canBeAssignedRequests: Scalars['Boolean']['output'];
  canListUsers: Scalars['Boolean']['output'];
  canManage: Scalars['Boolean']['output'];
  canManageBuildingCompanyBookings: Scalars['Boolean']['output'];
  canManageFlexTenants: Scalars['Boolean']['output'];
  canManageRequests: Scalars['Boolean']['output'];
  canManageSettings: Scalars['Boolean']['output'];
  canObserveBuildingCompanyBookings: Scalars['Boolean']['output'];
  company: SiteCompanyRelations;
  /** @deprecated Use canManage instead */
  manager: Scalars['Boolean']['output'];
};


export type DestinationViewerRelationsCompanyArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type DestinationsWithRelation = {
  __typename?: 'DestinationsWithRelation';
  member: DestinationConnection;
};


export type DestinationsWithRelationMemberArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  types?: InputMaybe<Array<DestinationType>>;
};

/** A customer's device such as a mobile phone or table. */
export type Device = {
  __typename?: 'Device';
  /** The application edition installed on this device. */
  edition: Scalars['String']['output'];
  /** The human readable name of the device. */
  name: Scalars['String']['output'];
  /** The device registration id. */
  registrationId: Scalars['String']['output'];
};

export type DisableFailure = {
  __typename?: 'DisableFailure';
  reason: Scalars['String']['output'];
};

export type DisableResult = DisableFailure | DisableSuccess;

export type DisableSuccess = {
  __typename?: 'DisableSuccess';
  regionsAffected: Scalars['Int']['output'];
  user: FullUser;
};

export type Discount = {
  __typename?: 'Discount';
  audience?: Maybe<ProductAudience>;
  availability: DiscountAvailability;
  available: Scalars['Boolean']['output'];
  changeLog: Array<DiscountChangeLog>;
  code: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  inclusions: DiscountInclusions;
  internalNotes?: Maybe<Scalars['String']['output']>;
  owningVendor?: Maybe<Vendor>;
  perUserLineItemLimit?: Maybe<QuantityLimit>;
  perUserOrderLimit?: Maybe<QuantityLimit>;
  perUserSpendLimit?: Maybe<QuantityLimit>;
  promotion: DiscountPromotion;
  /** @deprecated Use audience field */
  segment?: Maybe<Segment>;
  site: Portal;
  siteReport: SiteDiscountReport;
  status: DiscountStatus;
  termsAndConditions?: Maybe<Scalars['String']['output']>;
  totalLineItemLimit?: Maybe<QuantityLimit>;
  totalOrderLimit?: Maybe<QuantityLimit>;
  totalSpendLimit?: Maybe<QuantityLimit>;
  updated: Scalars['DateTime']['output'];
  userTermsAndConditions?: Maybe<Scalars['String']['output']>;
  uuid: Scalars['UUID']['output'];
  value: DiscountValue;
  vendorReports: Array<Maybe<VendorDiscountReport>>;
};

export type DiscountAvailability = {
  __typename?: 'DiscountAvailability';
  dateRange?: Maybe<AvailabilityDateRange>;
  groupedTimes: Array<GroupedTradingHour>;
  limitedAvailability: Scalars['Boolean']['output'];
  times?: Maybe<Array<TradingHour>>;
};

export type DiscountChangeLog = {
  __typename?: 'DiscountChangeLog';
  after: Scalars['String']['output'];
  before: Scalars['String']['output'];
  date: Scalars['DateTime']['output'];
  field: Scalars['String']['output'];
  updatedBy?: Maybe<FullUser>;
};

export type DiscountConnection = {
  __typename?: 'DiscountConnection';
  edges: Array<Maybe<DiscountEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type DiscountEdge = {
  __typename?: 'DiscountEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Discount>;
};

export type DiscountFilter = {
  code?: InputMaybe<Scalars['String']['input']>;
  product?: InputMaybe<Scalars['UUID']['input']>;
  promoted?: InputMaybe<PromotedFilter>;
  site?: InputMaybe<Scalars['UUID']['input']>;
  status?: InputMaybe<Array<DiscountStatus>>;
  vendor?: InputMaybe<Scalars['UUID']['input']>;
};

export type DiscountInclusions = {
  __typename?: 'DiscountInclusions';
  products?: Maybe<Array<Product>>;
};

export type DiscountInclusionsInput = {
  products: Array<Scalars['UUID']['input']>;
};

export type DiscountMutationFailure = {
  __typename?: 'DiscountMutationFailure';
  reason: Scalars['String']['output'];
};

export type DiscountMutationResult = DiscountMutationFailure | DiscountMutationSuccess;

export type DiscountMutationSuccess = {
  __typename?: 'DiscountMutationSuccess';
  discount: Discount;
};

export type DiscountPagination = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type DiscountPromotion = {
  __typename?: 'DiscountPromotion';
  promoted: Scalars['Boolean']['output'];
  subtitle?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type DiscountPromotionInput = {
  promoted?: InputMaybe<Scalars['Boolean']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type DiscountStatus =
  | 'ACTIVE'
  | 'COMPLETED'
  | 'INACTIVE';

export type DiscountType =
  | 'AMOUNT'
  | 'PERCENTAGE';

export type DiscountValue = DollarDiscount | PercentageDiscount;

export type DiscountVendorInclusionInput = {
  productExclusions: Array<Scalars['UUID']['input']>;
  vendorUuid: Scalars['UUID']['input'];
};

/** A single DNS entry that must be created by the client. */
export type DnsCheck = {
  __typename?: 'DnsCheck';
  /** A link to more help about this type of record if necessary. */
  helpLink?: Maybe<Scalars['URI']['output']>;
  /** The hostname for the DNS entry. */
  hostname: Scalars['String']['output'];
  /** Whether this entry has been detected as created by the client or not. */
  status: DnsCheckStatus;
  /** The type of DNS record. */
  type: DnsRecordType;
  /** The value of the DNS entry. */
  value: Scalars['String']['output'];
};

/** Current status of a DNS check. */
export type DnsCheckStatus =
  /** The client has created this entry yet, but there is pending actions on Equiem's side to provision it. */
  | 'CREATED'
  /** This entry was deprovisioned as it became inactive on the client side. */
  | 'DEPROVISIONED'
  /** The client has not created this entry yet. */
  | 'NOT_CREATED'
  /** This entry is currently active and fully provisioned. */
  | 'PROVISIONED';

export type DnsRecordType =
  | 'A'
  | 'AAAA'
  | 'ANAME'
  | 'CNAME'
  | 'MX'
  | 'TXT';

export type Document = {
  __typename?: 'Document';
  filename: Scalars['String']['output'];
  key: Scalars['String']['output'];
  metadata: Array<DocumentMetadata>;
  mimeType: Scalars['String']['output'];
  public: Scalars['Boolean']['output'];
  size: Scalars['Int']['output'];
  thumbnailUrl?: Maybe<Scalars['URI']['output']>;
  url: Scalars['URI']['output'];
};


export type DocumentThumbnailUrlArgs = {
  height?: InputMaybe<Scalars['Int']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};


export type DocumentUrlArgs = {
  height?: InputMaybe<Scalars['Int']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type DocumentConnection = {
  __typename?: 'DocumentConnection';
  after?: Maybe<Scalars['String']['output']>;
  before?: Maybe<Scalars['String']['output']>;
  edges: Array<DocumentEdge>;
  first: Scalars['Int']['output'];
  pageInfo: PageInfo;
  prefix: Scalars['String']['output'];
  rootPrefix: DocumentRootPrefix;
  totalCount: Scalars['Int']['output'];
};

export type DocumentContentDisposition =
  | 'ATTACHMENT'
  | 'INLINE';

export type DocumentEdge = {
  __typename?: 'DocumentEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Document>;
};

export type DocumentInput = {
  global: Scalars['Boolean']['input'];
  key: Scalars['String']['input'];
  public: Scalars['Boolean']['input'];
};

export type DocumentListInput = {
  prefix: Scalars['String']['input'];
  rootPrefix: DocumentRootPrefixInput;
};

export type DocumentMetadata = {
  __typename?: 'DocumentMetadata';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type DocumentMetadataInput = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type DocumentObject = FileV2 | ImageV2;

export type DocumentRole =
  | 'ANON'
  | 'SERVICE'
  | 'USER';

export type DocumentRootPrefix = {
  __typename?: 'DocumentRootPrefix';
  type: DocumentRootPrefixType;
  uuid: Scalars['UUID']['output'];
};

export type DocumentRootPrefixInput = {
  type: DocumentRootPrefixType;
  uuid: Scalars['UUID']['input'];
};

export type DocumentRootPrefixType =
  | 'BUILDING'
  | 'PORTFOLIO'
  | 'SITE';

export type DocumentV2 = {
  __typename?: 'DocumentV2';
  filename?: Maybe<Scalars['String']['output']>;
  global: Scalars['Boolean']['output'];
  key: Scalars['String']['output'];
  public: Scalars['Boolean']['output'];
  value: DocumentObject;
};

export type DollarDiscount = {
  __typename?: 'DollarDiscount';
  cents: Scalars['Int']['output'];
};

/** A single domain name with some metadata available for client usage. */
export type DomainName = {
  __typename?: 'DomainName';
  /** The full domain name. */
  hostname: Scalars['String']['output'];
  /**
   * The registered part of the domain name.
   *
   * This varies from TLD to TLD but is an attempt to match which part of the hostname was purchased by the client.
   *
   * www.example.com => example.com
   */
  registeredName: Scalars['String']['output'];
  /**
   * The shortest unique suffix within our systems for generating subdomains.
   *
   * This will never be shorter than the registeredName.
   */
  uniqueSuffix: Scalars['String']['output'];
};

/** Information about pagination in a connection. */
export type DomainServicePageInfo = {
  __typename?: 'DomainServicePageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? Implies that endCursor will have a value if true. */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? Implies that startCursor will have a value if true. */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type DomainServicePageOptions = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type DomainServiceRole =
  | 'ANON'
  | 'SERVICE';

export type EmailBranding = {
  __typename?: 'EmailBranding';
  footerImage?: Maybe<Scalars['URI']['output']>;
  headerImage?: Maybe<Scalars['URI']['output']>;
  newsletterFooter?: Maybe<Scalars['String']['output']>;
  senderDetails?: Maybe<Scalars['String']['output']>;
};


export type EmailBrandingFooterImageArgs = {
  imageOptions?: InputMaybe<ImageOptions>;
};


export type EmailBrandingHeaderImageArgs = {
  imageOptions?: InputMaybe<ImageOptions>;
};

export type EmailSearchResult = {
  __typename?: 'EmailSearchResult';
  destination: Destination;
  status: EmailStatus;
};

export type EmailStatus =
  | 'EXISTS'
  | 'EXISTS_ON_DESTINATION'
  | 'NOT_ALLOWED'
  | 'UNREGISTERED';

export type EmailVerificationFailure = {
  __typename?: 'EmailVerificationFailure';
  expired: Scalars['Boolean']['output'];
  message: Scalars['String']['output'];
};

export type EmailVerificationResult = EmailVerificationFailure | EmailVerificationSuccess;

export type EmailVerificationSettings = {
  __typename?: 'EmailVerificationSettings';
  /** @deprecated Always enabled */
  enabled: Scalars['Boolean']['output'];
};

export type EmailVerificationSuccess = {
  __typename?: 'EmailVerificationSuccess';
  profile: Profile;
};

export type EncryptedData = {
  encryptedData: Scalars['String']['input'];
  iv: Scalars['String']['input'];
};

export type EncryptedDataOutput = {
  __typename?: 'EncryptedDataOutput';
  encryptedData: Scalars['String']['output'];
  iv: Scalars['String']['output'];
};

export type EndUserBookingNotificationPreferences = {
  __typename?: 'EndUserBookingNotificationPreferences';
  awaitingApprovalEmail: Scalars['Boolean']['output'];
  cancellationEmail: Scalars['Boolean']['output'];
  chargeAdjustmentEmail: Scalars['Boolean']['output'];
  confirmationEmail: Scalars['Boolean']['output'];
  declinedBookingEmail: Scalars['Boolean']['output'];
  reminderEmail: Scalars['Boolean']['output'];
  updateEmail: Scalars['Boolean']['output'];
};

export type EndUserGroup = {
  __typename?: 'EndUserGroup';
  name: Scalars['String']['output'];
  uuid: Scalars['UUID']['output'];
};

export type EquiemOneShortcut = {
  __typename?: 'EquiemOneShortcut';
  icon: Scalars['String']['output'];
  role: Scalars['String']['output'];
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type EquiemOneShortcutInput = {
  role: Scalars['String']['input'];
  shortcuts: Array<ShortcutInput>;
};

export type EquiemOneWidgetId =
  | 'Welcome';

export type EventCheckInFailureReason =
  | 'ACCEPT_TANDC_REQUIRED'
  | 'ALREADY_CHECKED_IN'
  | 'EVENT_FULL'
  | 'MULTIPLE_USERS_FOUND'
  | 'OCCURRENCE_NOT_FOUND'
  | 'SESSION_PURCHASE_REQUIRED'
  | 'USER_NOT_FOUND';

export type EventCheckInMutationResult = FailedEventCheckInMutationResult | SuccessfulEventCheckInMutationResult;

export type EventCheckInPersonQuery = {
  email: Scalars['String']['input'];
};

export type EventHistoryV2 = {
  __typename?: 'EventHistoryV2';
  occurrence: Occurrence;
  occurrenceUuid: Scalars['UUID']['output'];
  pastOccurrence: Scalars['Boolean']['output'];
  timestamp: Scalars['DateTime']['output'];
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type EventOccurrenceEdge = ContentEdge & {
  __typename?: 'EventOccurrenceEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Occurrence>;
};

export type EventOccurrenceFilters = {
  toDate: Scalars['DateTime']['input'];
};

export type EventOccurrencesConnection = {
  __typename?: 'EventOccurrencesConnection';
  edges: Array<EventOccurrenceEdge>;
  pageInfo: PageInfo;
};

export type EventPost = {
  __typename?: 'EventPost';
  additionalLocationInformation?: Maybe<Scalars['String']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  author?: Maybe<Profile>;
  body: Scalars['String']['output'];
  categories: Array<Category>;
  cost?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  excerpt: Scalars['String']['output'];
  featured: Scalars['Boolean']['output'];
  featuredImage: Image;
  images: Array<Image>;
  organiserEmail?: Maybe<Scalars['String']['output']>;
  publishDate: Scalars['DateTime']['output'];
  relatedInfo: Array<Link>;
  tags?: Maybe<Array<Scalars['String']['output']>>;
  termsAndConditions?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid: Scalars['UUID']['output'];
};

export type EventPostInput = {
  additionalLocationInformation?: InputMaybe<Scalars['String']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  author?: InputMaybe<Scalars['Email']['input']>;
  body: Scalars['String']['input'];
  categories: Array<Scalars['String']['input']>;
  checkinEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  contentUuid: Scalars['UUID']['input'];
  cost?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  endDate: Scalars['DateTime']['input'];
  featured?: InputMaybe<Scalars['Boolean']['input']>;
  images: Array<FileInput>;
  locationName?: InputMaybe<Scalars['String']['input']>;
  occurrenceUuid: Scalars['UUID']['input'];
  organiserEmail?: InputMaybe<Scalars['String']['input']>;
  portalPostUuid: Scalars['UUID']['input'];
  /**
   * Iris incorrectly sends this timezone scoped timestamp as if it is in UTC.
   * Eg. 10:00:00AM Australia/Melbourne time is sent as 10:00:00AM UTC.
   * publishDateInUtc will contain the correct utc timestamp.
   */
  publishDate: Scalars['DateTime']['input'];
  publishDateInUtc: Scalars['DateTime']['input'];
  published: Scalars['Boolean']['input'];
  relatedInformation?: InputMaybe<LinkInput>;
  rsvpEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  segmentIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  shareableLinkId?: InputMaybe<Scalars['String']['input']>;
  site: Scalars['UUID']['input'];
  startDate: Scalars['DateTime']['input'];
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  termsAndConditions?: InputMaybe<Scalars['String']['input']>;
  timezone: Scalars['String']['input'];
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type EvolutionAccount = {
  __typename?: 'EvolutionAccount';
  database: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
};

export type EvolutionCompanyV2Integration = {
  __typename?: 'EvolutionCompanyV2Integration';
  tenancyId?: Maybe<Scalars['String']['output']>;
};

export type EvolutionDestinationIntegration = {
  __typename?: 'EvolutionDestinationIntegration';
  enabled: Scalars['Boolean']['output'];
};

export type EvolutionUser = {
  __typename?: 'EvolutionUser';
  accounts: Array<EvolutionAccount>;
  allowed: Scalars['Boolean']['output'];
};

export type ExistingUserRegistrationFailureResponse = {
  __typename?: 'ExistingUserRegistrationFailureResponse';
  code: Scalars['String']['output'];
  localisedReason: LocalisedMessage;
  reason: Scalars['String']['output'];
};

export type ExistingUserRegistrationInput = {
  acceptTermsAndConditions: Scalars['Boolean']['input'];
  apartment?: InputMaybe<Scalars['UUID']['input']>;
  company?: InputMaybe<Scalars['UUID']['input']>;
  destination: Scalars['UUID']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  registrationType?: InputMaybe<RegistrationType>;
  subscribedToEmails?: InputMaybe<Scalars['Boolean']['input']>;
  token: Scalars['String']['input'];
};

export type ExistingUserRegistrationResult = ExistingUserRegistrationFailureResponse | ExistingUserRegistrationSuccessResponse;

export type ExistingUserRegistrationSuccessResponse = {
  __typename?: 'ExistingUserRegistrationSuccessResponse';
  registeredDestination: UserDestinationEdge;
  user: User;
};

export type ExitCarParkFailure = {
  __typename?: 'ExitCarParkFailure';
  localisedReason: LocalisedMessage;
  /** @deprecated use localisedReason instead */
  message: Scalars['String']['output'];
};

export type ExitCarParkResult = ExitCarParkFailure | ExitCarParkSuccess;

export type ExitCarParkSuccess = {
  __typename?: 'ExitCarParkSuccess';
  amount: Scalars['Int']['output'];
  entryTime: Scalars['DateTime']['output'];
  secondsInCarPark: Scalars['Int']['output'];
};

export type ExportJobResponse = {
  __typename?: 'ExportJobResponse';
  jobKey: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type ExternalBookingSyncResult = {
  __typename?: 'ExternalBookingSyncResult';
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type FailedDeactivateUserResponse = {
  __typename?: 'FailedDeactivateUserResponse';
  code: Scalars['String']['output'];
  reason: Scalars['String']['output'];
};

export type FailedEventCheckInMutationResult = {
  __typename?: 'FailedEventCheckInMutationResult';
  partialUser?: Maybe<PartialUser>;
  reason: EventCheckInFailureReason;
  success: Scalars['Boolean']['output'];
  userUuid?: Maybe<Scalars['UUID']['output']>;
};

export type FailedRegistrationV2Response = {
  __typename?: 'FailedRegistrationV2Response';
  code: Scalars['String']['output'];
  localisedReason: LocalisedMessage;
  reason: Scalars['String']['output'];
};

export type FailedScopeResponse = {
  __typename?: 'FailedScopeResponse';
  error: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type FailedUpdateAttendeeLimitMutationResult = {
  __typename?: 'FailedUpdateAttendeeLimitMutationResult';
  reason: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type FailedUpdateOccurrencesFromSessionsMutationResult = {
  __typename?: 'FailedUpdateOccurrencesFromSessionsMutationResult';
  reason: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type FailureResponse = {
  __typename?: 'FailureResponse';
  code?: Maybe<Scalars['String']['output']>;
  reason: Scalars['String']['output'];
};

export type FeatureModule = {
  __typename?: 'FeatureModule';
  /** @deprecated use endUserAccessEnabled */
  enableMenu?: Maybe<Scalars['Boolean']['output']>;
  endUserAccessEnabled: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
  type: FeatureModuleType;
  url?: Maybe<Scalars['String']['output']>;
};

export type FeatureModuleType =
  | 'BOOKINGS'
  | 'REQUESTS'
  | 'VISITORS';

export type FeatureModulesFilter = {
  endUserAccessEnabled?: InputMaybe<FeatureModulesFilterValue>;
  urlExist?: InputMaybe<FeatureModulesFilterValue>;
};

export type FeatureModulesFilterValue =
  | 'IGNORE'
  | 'NO'
  | 'YES';

export type FeatureModulesInput = {
  enableMenu?: InputMaybe<Scalars['Boolean']['input']>;
  label: Scalars['String']['input'];
  type: FeatureModuleType;
};

export type Feedback = {
  __typename?: 'Feedback';
  alreadyResponded: Scalars['Boolean']['output'];
  contrastColor: Scalars['String']['output'];
  nextResponseDate: Scalars['DateTime']['output'];
  nextResponseRequired: Scalars['Boolean']['output'];
  publishDate: Scalars['DateTime']['output'];
  question: FeedbackQuestion;
  repeatDurationMonths: Scalars['Int']['output'];
  tags?: Maybe<Array<Scalars['String']['output']>>;
  title: Scalars['String']['output'];
  uuid: Scalars['UUID']['output'];
};

export type FeedbackEdge = ContentEdge & {
  __typename?: 'FeedbackEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Feedback>;
};

export type FeedbackFormat =
  | 'FACE'
  | 'NPS';

export type FeedbackInput = {
  contentUuid: Scalars['UUID']['input'];
  contrastColor?: InputMaybe<Scalars['String']['input']>;
  portalPostUuid: Scalars['UUID']['input'];
  publishDateInUtc: Scalars['DateTime']['input'];
  published: Scalars['Boolean']['input'];
  question: FeedbackQuestionInput;
  repeatDurationMonths: Scalars['Int']['input'];
  segmentIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  site: Scalars['UUID']['input'];
  title: Scalars['String']['input'];
};

export type FeedbackMetricTargetType =
  | 'FACE'
  | 'NPS';

export type FeedbackQuestion = {
  __typename?: 'FeedbackQuestion';
  format: FeedbackFormat;
  satisfiedLabelText: Scalars['String']['output'];
  title: Scalars['String']['output'];
  unsatisfiedLabelText: Scalars['String']['output'];
};

export type FeedbackQuestionInput = {
  format: FeedbackFormat;
  satisfiedLabelText: Scalars['String']['input'];
  title: Scalars['String']['input'];
  unsatisfiedLabelText: Scalars['String']['input'];
};

export type FeedbackQuestionResponse = {
  __typename?: 'FeedbackQuestionResponse';
  comment?: Maybe<Scalars['String']['output']>;
  feedbackUuid: Scalars['UUID']['output'];
  metricTargetType: FeedbackMetricTargetType;
  response: Scalars['Int']['output'];
  responseExpiryDate: Scalars['DateTime']['output'];
  siteUuid: Scalars['UUID']['output'];
  userUuid: Scalars['UUID']['output'];
  uuid: Scalars['UUID']['output'];
};

/** FeedbackResponse Input type */
export type FeedbackResponseInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  dismissed?: InputMaybe<Scalars['Boolean']['input']>;
  feedbackUuid: Scalars['UUID']['input'];
  metricTargetDescription?: InputMaybe<Scalars['String']['input']>;
  metricTargetType?: InputMaybe<FeedbackMetricTargetType>;
  response: Scalars['Int']['input'];
  responseExpiryDate?: InputMaybe<Scalars['DateTime']['input']>;
  siteUuid?: InputMaybe<Scalars['UUID']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type File = {
  __typename?: 'File';
  description: Scalars['String']['output'];
  displayCaption: Scalars['Boolean']['output'];
  url: Scalars['URI']['output'];
};

export type FileInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  displayCaption?: InputMaybe<Scalars['Boolean']['input']>;
  url: Scalars['URI']['input'];
};

export type FileV2 = {
  __typename?: 'FileV2';
  filename: Scalars['String']['output'];
  global: Scalars['Boolean']['output'];
  key: Scalars['String']['output'];
  metadata: Array<DocumentMetadata>;
  mimeType: Scalars['String']['output'];
  public: Scalars['Boolean']['output'];
  size: Scalars['Int']['output'];
  url: Scalars['URI']['output'];
};

export type FlexOperator = {
  __typename?: 'FlexOperator';
  defaultSite?: Maybe<Destination>;
  name: Scalars['String']['output'];
  operatorCompany?: Maybe<CompanyV2>;
  uuid: Scalars['UUID']['output'];
  viewerRelations: FlexOperatorViewerRelations;
};

export type FlexOperatorInput = {
  flexSiteUuid: Scalars['UUID']['input'];
  name: Scalars['String']['input'];
  operatorCompanyUuid: Scalars['UUID']['input'];
};

export type FlexOperatorViewerRelations = {
  __typename?: 'FlexOperatorViewerRelations';
  canManageFlexTenants: Scalars['Boolean']['output'];
};

export type FlexTenant = {
  __typename?: 'FlexTenant';
  billingCustomer?: Maybe<BillingCustomer>;
  company: CompanyV2;
  creditAccount?: Maybe<CreditAccount>;
  defaultWorkplaceManagerEmails: Array<Scalars['Email']['output']>;
  expirationDate: Scalars['DateTime']['output'];
  flexOperator: FlexOperator;
  locations: Array<BuildingLevel>;
  memberLimit?: Maybe<Scalars['Int']['output']>;
  membershipCount: Scalars['Int']['output'];
  monthlyCreditAllowance?: Maybe<Scalars['Int']['output']>;
  moveInDate: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  spendingCreditsRequiresApproval: Scalars['Boolean']['output'];
  status: FlexTenantStatus;
  uuid: Scalars['UUID']['output'];
};

export type FlexTenantConnection = {
  __typename?: 'FlexTenantConnection';
  edges: Array<FlexTenantEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type FlexTenantEdge = {
  __typename?: 'FlexTenantEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<FlexTenant>;
};

export type FlexTenantFilters = {
  buildings: Array<Scalars['UUID']['input']>;
  companies?: InputMaybe<Array<Scalars['UUID']['input']>>;
  sites: Array<Scalars['UUID']['input']>;
  statuses?: InputMaybe<Array<FlexTenantStatus>>;
};

export type FlexTenantInput = {
  companyUuid: Scalars['UUID']['input'];
  defaultWorkplaceManagerEmails?: InputMaybe<Array<Scalars['Email']['input']>>;
  expirationDate: Scalars['DateTime']['input'];
  locations: Array<FlexTenantLocationInput>;
  memberLimit?: InputMaybe<Scalars['Int']['input']>;
  monthlyCreditAllowance?: InputMaybe<Scalars['Int']['input']>;
  moveInDate: Scalars['DateTime']['input'];
  name: Scalars['String']['input'];
  spendingCreditsRequiresApproval?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FlexTenantLocationInput = {
  buildingLevels: Array<Scalars['UUID']['input']>;
  destination: Scalars['UUID']['input'];
};

export type FlexTenantMembership = {
  __typename?: 'FlexTenantMembership';
  created: Scalars['DateTime']['output'];
  flexTenant: FlexTenant;
  profile: Profile;
  uuid: Scalars['UUID']['output'];
};

export type FlexTenantMembershipConnection = {
  __typename?: 'FlexTenantMembershipConnection';
  edges: Array<FlexTenantMembershipEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type FlexTenantMembershipEdge = {
  __typename?: 'FlexTenantMembershipEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<FlexTenantMembership>;
};

export type FlexTenantStatus =
  | 'Active'
  | 'Expired'
  | 'NearingExpiry'
  | 'PreMoveIn';

export type FloatMetric = {
  __typename?: 'FloatMetric';
  current: Scalars['Float']['output'];
  lastMonth: Scalars['Float']['output'];
};

export type FocalPoint = {
  __typename?: 'FocalPoint';
  x: Scalars['Float']['output'];
  y: Scalars['Float']['output'];
};

export type FocalPointInput = {
  debug?: InputMaybe<Scalars['Boolean']['input']>;
  x: Scalars['Float']['input'];
  y: Scalars['Float']['input'];
};

/** A user's profile. */
export type FullProfile = {
  __typename?: 'FullProfile';
  /** Whether the customer is active (or blocked). */
  active: Scalars['Boolean']['output'];
  /** old and new world permissions */
  allPermissions?: Maybe<Array<Permission>>;
  /** The customer's avatar. */
  avatar?: Maybe<Scalars['URI']['output']>;
  /** A list of credit/debit cards associated with the profile */
  cards: Array<Card>;
  /** The company to which this customer belongs. */
  company?: Maybe<Company>;
  companyV2?: Maybe<CompanyV2>;
  /** Whether this user has access to console app */
  consoleAppAccess: Scalars['Boolean']['output'];
  /** The date when the profile was created. */
  created: Scalars['DateTime']['output'];
  /** Customer/profile's default card */
  defaultCard?: Maybe<Card>;
  /** A customers location eg. desk level building */
  deliveryLocation?: Maybe<Scalars['String']['output']>;
  /** A list of the devices associated with this customer's account. */
  devices: Array<Device>;
  /** The display name of the profile. */
  displayName: Scalars['String']['output'];
  /** The customer's email. */
  email: Scalars['Email']['output'];
  /** Whether the user's email is verified */
  emailVerified: Scalars['Boolean']['output'];
  /** The first name of the profile. */
  firstName?: Maybe<Scalars['String']['output']>;
  flexMemberships: Array<FlexTenantMembership>;
  /** A log of significant changes made to this profile */
  history: ProfileLogEventConnection;
  /** Whether the user is an Equiem employee */
  isEquiemEmployee: Scalars['Boolean']['output'];
  /** The customer's job title. */
  jobTitle?: Maybe<Scalars['String']['output']>;
  /** The last activity associated with the profile. */
  lastActivity: Scalars['String']['output'];
  /** The date when the profile was last active. */
  lastActivityTime: Scalars['DateTime']['output'];
  /** The last name of the profile. */
  lastName?: Maybe<Scalars['String']['output']>;
  /** The customer's mobile number. */
  mobileNumber?: Maybe<Scalars['String']['output']>;
  /** Permissions assigned to a profile from ucm */
  newWorldPermissions?: Maybe<Array<Permission>>;
  /** ID for customer according to the payment gateway */
  paymentGatewayCustomerId?: Maybe<Scalars['String']['output']>;
  /** Permissions assigned to a profile */
  permissions?: Maybe<Array<Permission>>;
  /** The customer's phone number. */
  phoneNumber?: Maybe<Scalars['String']['output']>;
  /** Whether or not the profile can be viewed by other customers. */
  public: Scalars['Boolean']['output'];
  /** reference id used to identify user for deal redemption */
  referenceId: Scalars['String']['output'];
  /** A profiles site specific profile connection */
  siteProfiles: ProfileSiteProfileConnection;
  status: ProfileStatus;
  /** An email address the customer is currently verifying. */
  unverifiedEmail?: Maybe<Scalars['Email']['output']>;
  /** The date when the profile was last updated. */
  updated: Scalars['DateTime']['output'];
  /** Unique identifier for the profile. */
  uuid: Scalars['UUID']['output'];
  /** Has the user been active after initial creation */
  wasActivated: Scalars['Boolean']['output'];
};


/** A user's profile. */
export type FullProfileAvatarArgs = {
  input?: InputMaybe<ImageInput>;
};


/** A user's profile. */
export type FullProfileFlexMembershipsArgs = {
  siteUuid?: InputMaybe<Scalars['UUID']['input']>;
};


/** A user's profile. */
export type FullProfileHistoryArgs = {
  destinationUuid?: InputMaybe<Scalars['UUID']['input']>;
  page: PageOptions;
  sort?: InputMaybe<ProfileHistorySort>;
};


/** A user's profile. */
export type FullProfileSiteProfilesArgs = {
  filters?: InputMaybe<ProfileSiteProfileFilters>;
  page: PageOptions;
};

export type FullProfileConnection = {
  __typename?: 'FullProfileConnection';
  edges: Array<FullProfileEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type FullProfileEdge = {
  __typename?: 'FullProfileEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<FullProfile>;
};

export type FullUser = {
  __typename?: 'FullUser';
  accessPermits?: Maybe<Array<AccessPermit>>;
  active: Scalars['Boolean']['output'];
  adminPermissions?: Maybe<AdminPermissions>;
  /** @deprecated Use the authmaps property */
  authIds: Array<Scalars['String']['output']>;
  authMaps: Array<AuthMap>;
  canChangeEmail: Scalars['Boolean']['output'];
  currentDestination?: Maybe<UserDestination>;
  /** @deprecated Default destination no longer makes sense */
  defaultDestination: UserDestination;
  destination?: Maybe<UserDestination>;
  destinationUuids: Array<Scalars['UUID']['output']>;
  /** @deprecated Use destinationsV2 to include those that haven't had T&C's accepted */
  destinations?: Maybe<UserDestinationConnection>;
  destinationsV2?: Maybe<UserDestinationConnection>;
  destinationsWithRelation: DestinationsWithRelation;
  installations: Array<Installation>;
  integrations: UserIntegrations;
  integrationsV2?: Maybe<UserIntegrationsV2>;
  locale?: Maybe<Scalars['String']['output']>;
  profile?: Maybe<FullProfile>;
  storePermissions?: Maybe<VmsStorePermissions>;
  uuid: Scalars['UUID']['output'];
};


export type FullUserDestinationArgs = {
  uuid: Scalars['UUID']['input'];
};


export type FullUserDestinationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<DestinationType>;
  types?: InputMaybe<Array<DestinationType>>;
};


export type FullUserDestinationsV2Args = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  installation?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<DestinationType>;
  types?: InputMaybe<Array<DestinationType>>;
};

export type GallagherAuthenticationFailure = {
  __typename?: 'GallagherAuthenticationFailure';
  reason: Scalars['String']['output'];
};

export type GallagherAuthenticationResult = GallagherAuthenticationFailure | GallagherAuthenticationSuccess;

export type GallagherAuthenticationSuccess = {
  __typename?: 'GallagherAuthenticationSuccess';
  info: GallagherDestinationInfo;
};

export type GallagherDestinationInfo = {
  __typename?: 'GallagherDestinationInfo';
  connectedTimestamp: Scalars['DateTime']['output'];
  label: Scalars['String']['output'];
  lastSuccessfulConnection: Scalars['DateTime']['output'];
  useIntegrationLicense: Scalars['Boolean']['output'];
  version: Scalars['String']['output'];
};

export type GallagherDestinationIntegration = {
  __typename?: 'GallagherDestinationIntegration';
  enabled: Scalars['Boolean']['output'];
  info?: Maybe<GallagherDestinationInfo>;
  /**
   * The license field is intended for individual site queries only.
   * Including it in list queries may cause performance issues and unexpected behaviour.
   * This field resolves only when requested and returns null if Gallagher is not enabled.
   * Do not request this field in list queries.
   */
  license?: Maybe<LicenseStatus>;
};

export type GallagherMobileCredentialInviteCreated = {
  __typename?: 'GallagherMobileCredentialInviteCreated';
  created: Scalars['Boolean']['output'];
};

export type GallagherMobileCredentialInviteFailure = {
  __typename?: 'GallagherMobileCredentialInviteFailure';
  localisedReason: LocalisedMessage;
  reason: Scalars['String']['output'];
};

export type GallagherMobileCredentialInviteInfo = {
  __typename?: 'GallagherMobileCredentialInviteInfo';
  invitationHref: Scalars['String']['output'];
  invitationStatus: Scalars['String']['output'];
};

export type GallagherMobileCredentialInviteResult = GallagherMobileCredentialInviteCreated | GallagherMobileCredentialInviteFailure | GallagherMobileCredentialInviteSuccess;

export type GallagherMobileCredentialInviteSuccess = {
  __typename?: 'GallagherMobileCredentialInviteSuccess';
  info: GallagherMobileCredentialInviteInfo;
};

export type GallagherMutation = {
  __typename?: 'GallagherMutation';
  connect: GallagherAuthenticationResult;
  disconnect: IntegrationServiceResult;
  /**
   * Update one or more of the fields associated with an existing connection, without overwriting the entire connection.
   *
   * API gateway and key cannot be updated and would require first disconnecting and then reconnecting with the new credentials.
   */
  patch: GallagherAuthenticationResult;
  /**
   * creates a new mobile credential and returns the invitation url and removes all the existing mobile credentials,
   * for the current user of the current site only if they have an existing Gallagher account with the same email address
   */
  requestMobileCredentialInvite: GallagherMobileCredentialInviteResult;
  updateIntegrationLicense: GallagherAuthenticationResult;
};


export type GallagherMutationConnectArgs = {
  apiGateway: Scalars['String']['input'];
  apiKey: Scalars['String']['input'];
  label?: InputMaybe<Scalars['String']['input']>;
  site: Scalars['UUID']['input'];
  useIntegrationLicense: Scalars['Boolean']['input'];
};


export type GallagherMutationDisconnectArgs = {
  site: Scalars['UUID']['input'];
};


export type GallagherMutationPatchArgs = {
  label?: InputMaybe<Scalars['String']['input']>;
  site: Scalars['UUID']['input'];
  useIntegrationLicense?: InputMaybe<Scalars['Boolean']['input']>;
};


export type GallagherMutationUpdateIntegrationLicenseArgs = {
  enable: Scalars['Boolean']['input'];
  site: Scalars['UUID']['input'];
};

export type GallagherQuery = {
  __typename?: 'GallagherQuery';
  mobileCredentialInvites: Array<GallagherMobileCredentialInviteInfo>;
};

/** Autogenerated input type of GenerateCredential */
export type GenerateCredentialInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** UUID of users device */
  deviceUid: Scalars['UUID']['input'];
  /** Generated by mobile app using getPublicKey() required to generate digital key */
  publicKey: Scalars['String']['input'];
  /** User bearer token that will be used to authotize access to Connect API */
  userToken: Scalars['String']['input'];
};

/** Autogenerated return type of GenerateCredential. */
export type GenerateCredentialPayload = {
  __typename?: 'GenerateCredentialPayload';
  ac1SaltoKsCredential: Ac1SaltoKsCredential;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type GoogleServiceKeyInput = {
  auth_provider_x509_cert_url: Scalars['String']['input'];
  auth_uri: Scalars['String']['input'];
  client_email: Scalars['String']['input'];
  client_id: Scalars['String']['input'];
  client_x509_cert_url: Scalars['String']['input'];
  private_key: Scalars['String']['input'];
  private_key_id: Scalars['String']['input'];
  project_id: Scalars['String']['input'];
  token_uri: Scalars['String']['input'];
  type: Scalars['String']['input'];
  universe_domain?: InputMaybe<Scalars['String']['input']>;
};

export type GoogleStoreStatus =
  | 'ACCOUNT_NOT_VERIFIED'
  | 'LIVE'
  | 'PENDING_RELEASE'
  | 'STORE_REJECTED'
  | 'WAITING_FOR_REVIEW';

export type GroupResult = FailureResponse | GroupSuccessResponse;

export type GroupSuccessResponse = {
  __typename?: 'GroupSuccessResponse';
  group: EndUserGroup;
};

/** Represents the opening hours of a vendor on a given day */
export type GroupedTradingHour = {
  __typename?: 'GroupedTradingHour';
  /** Closing time of the store in 24hr time. Format: 14:38  */
  close: Scalars['String']['output'];
  days: Array<DayOfTheWeek>;
  /** Opening time of the store in 24hr time. Format: 10:12  */
  open: Scalars['String']['output'];
};

export type ICheckoutFailure = {
  code: CheckoutFailureCodes;
  message?: Maybe<Scalars['String']['output']>;
};

export type IPaymentFailure = {
  code: PaymentFailureCodes;
  message?: Maybe<Scalars['String']['output']>;
};

export type Image = {
  __typename?: 'Image';
  focalPoint?: Maybe<FocalPoint>;
  imageKey?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['URI']['output']>;
};


export type ImageUrlArgs = {
  input?: InputMaybe<ImageInput>;
};

export type ImageInput = {
  focalPoint?: InputMaybe<FocalPointInput>;
  /** Desired height. */
  height?: InputMaybe<Scalars['Int']['input']>;
  /** Preset image settings */
  preset?: InputMaybe<ImagePreset>;
  /** Desired width. */
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type ImageOptions = {
  compress?: InputMaybe<Scalars['Boolean']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type ImagePreset =
  | 'THUMBNAIL';

export type ImageType =
  | 'GIF'
  | 'JPEG'
  | 'PNG';

export type ImageV2 = {
  __typename?: 'ImageV2';
  filename: Scalars['String']['output'];
  global: Scalars['Boolean']['output'];
  imageUrl: Scalars['URI']['output'];
  key: Scalars['String']['output'];
  metadata: Array<DocumentMetadata>;
  mimeType: Scalars['String']['output'];
  public: Scalars['Boolean']['output'];
  size: Scalars['Int']['output'];
};


export type ImageV2ImageUrlArgs = {
  height?: InputMaybe<Scalars['Int']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type InactivityUnit =
  | 'days'
  | 'months'
  | 'weeks';

export type IndexBookableProductInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  images?: InputMaybe<Array<FileInput>>;
  portalProductUuid: Scalars['UUID']['input'];
  priceInCents: Scalars['Int']['input'];
  productUuid: Scalars['UUID']['input'];
  published: Scalars['Boolean']['input'];
  segmentIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  site: Scalars['UUID']['input'];
  siteStoreEnabled: Scalars['Boolean']['input'];
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  vendorName: Scalars['String']['input'];
  vendorUuid: Scalars['UUID']['input'];
  vendorVisible: Scalars['Boolean']['input'];
};

export type IndexDealProductInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  dealType: DealType;
  description?: InputMaybe<Scalars['String']['input']>;
  images?: InputMaybe<Array<FileInput>>;
  portalProductUuid: Scalars['UUID']['input'];
  productUuid: Scalars['UUID']['input'];
  published: Scalars['Boolean']['input'];
  segmentIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  site: Scalars['UUID']['input'];
  siteStoreEnabled: Scalars['Boolean']['input'];
  title: Scalars['String']['input'];
  vendorName: Scalars['String']['input'];
  vendorUuid: Scalars['UUID']['input'];
  vendorVisible: Scalars['Boolean']['input'];
};

export type IndexDealVendorInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  hasProducts: Scalars['Boolean']['input'];
  images?: InputMaybe<Array<FileInput>>;
  portalVendorUuid: Scalars['UUID']['input'];
  published: Scalars['Boolean']['input'];
  site: Scalars['UUID']['input'];
  siteStoreEnabled: Scalars['Boolean']['input'];
  vendorName: Scalars['String']['input'];
  vendorUuid: Scalars['UUID']['input'];
  vendorVisible: Scalars['Boolean']['input'];
};

export type IndexPlainProductInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  images?: InputMaybe<Array<FileInput>>;
  portalProductUuid: Scalars['UUID']['input'];
  priceInCents: Scalars['Int']['input'];
  productUuid: Scalars['UUID']['input'];
  published: Scalars['Boolean']['input'];
  segmentIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  site: Scalars['UUID']['input'];
  siteStoreEnabled: Scalars['Boolean']['input'];
  title: Scalars['String']['input'];
  vendorName: Scalars['String']['input'];
  vendorUuid: Scalars['UUID']['input'];
  vendorVisible: Scalars['Boolean']['input'];
};

export type IndexSubscriptionProductInput = {
  billingCycle: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  images?: InputMaybe<Array<FileInput>>;
  portalProductUuid: Scalars['UUID']['input'];
  priceInCents: Scalars['Int']['input'];
  productUuid: Scalars['UUID']['input'];
  published: Scalars['Boolean']['input'];
  segmentIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  site: Scalars['UUID']['input'];
  siteStoreEnabled: Scalars['Boolean']['input'];
  title: Scalars['String']['input'];
  vendorName: Scalars['String']['input'];
  vendorUuid: Scalars['UUID']['input'];
  vendorVisible: Scalars['Boolean']['input'];
};

export type IndexVendorInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  hasProducts: Scalars['Boolean']['input'];
  images?: InputMaybe<Array<FileInput>>;
  portalVendorUuid: Scalars['UUID']['input'];
  published: Scalars['Boolean']['input'];
  site: Scalars['UUID']['input'];
  siteStoreEnabled: Scalars['Boolean']['input'];
  vendorName: Scalars['String']['input'];
  vendorUuid: Scalars['UUID']['input'];
  vendorVisible: Scalars['Boolean']['input'];
};

export type IndustryResult = FailureResponse | IndustrySuccessResponse;

export type IndustrySuccessResponse = {
  __typename?: 'IndustrySuccessResponse';
  industry: CompanyIndustry;
};

/** A single set of infrastructure dedicated to a group of destinations. */
export type InfrastructureGroup = {
  __typename?: 'InfrastructureGroup';
  /** The current status of the account app in this infrastructure group. */
  accountAppStatus: InfrastructureGroupStatus;
  /** The destinations assigned to this infrastructure group. */
  destinations: Array<Destination>;
  id: Scalars['UUID']['output'];
  /** A list of sites preventing updates to this infrastructure group. */
  updateFailures: Array<Destination>;
  /** The current status of the web app in this infrastructure group. */
  webAppStatus: InfrastructureGroupStatus;
};

export type InfrastructureGroupConnection = {
  __typename?: 'InfrastructureGroupConnection';
  edges: Array<InfrastructureGroupEdge>;
  pageInfo: DomainServicePageInfo;
  totalCount: Scalars['Int']['output'];
};

export type InfrastructureGroupEdge = {
  __typename?: 'InfrastructureGroupEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<InfrastructureGroup>;
};

export type InfrastructureGroupStatus =
  | 'DEPLOYED'
  | 'DEPLOYING'
  | 'ERROR'
  | 'PENDING';

export type Installation = {
  __typename?: 'Installation';
  gatewayEndpoint: Scalars['URI']['output'];
  id: Scalars['String']['output'];
  /** @deprecated Use gatewayEndpoint */
  mobileBFFEndpoint: Scalars['URI']['output'];
  /** @deprecated Use gatewayEndpoint */
  profileApiEndpoint: Scalars['URI']['output'];
  /** Endpoint for the SMS Checkin */
  smsCheckinEndpoint: Scalars['URI']['output'];
  /** Endpoint for the VMS */
  vmsEndpoint: Scalars['URI']['output'];
};

export type IntMetric = {
  __typename?: 'IntMetric';
  current: Scalars['Int']['output'];
  lastMonth: Scalars['Int']['output'];
};

export type IntegrationServiceResult = {
  __typename?: 'IntegrationServiceResult';
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type InteractionsRole =
  | 'ANON'
  | 'SERVICE'
  | 'USER';

export type Invite = {
  __typename?: 'Invite';
  company?: Maybe<CompanyV2>;
  converted: Scalars['Boolean']['output'];
  created: Scalars['DateTime']['output'];
  destination?: Maybe<Destination>;
  email: Scalars['String']['output'];
  flexTenant?: Maybe<FlexTenant>;
  groups: Array<SiteGroup>;
  history: Array<InviteHistoryLog>;
  name?: Maybe<Scalars['String']['output']>;
  profile?: Maybe<FullProfile>;
  roleTuples: Array<InviteRoleTuple>;
  status: InviteStatus;
  triggeredBy?: Maybe<PartialUser>;
  updated: Scalars['DateTime']['output'];
  uuid: Scalars['String']['output'];
};

export type InviteConnection = {
  __typename?: 'InviteConnection';
  edges: Array<InviteEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type InviteEdge = {
  __typename?: 'InviteEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Invite>;
};

export type InviteFilter = {
  converted?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  isEq1?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type InviteHistoryAction =
  | 'InvitationResent'
  | 'InvitationSent'
  | 'UserRegistered'
  | 'UserUnsubscribed'
  | 'approvePendingApproval';

export type InviteHistoryLog = {
  __typename?: 'InviteHistoryLog';
  action: InviteHistoryAction;
  timestamp: Scalars['DateTime']['output'];
  triggeredBy?: Maybe<PartialUser>;
};

export type InviteRoleTuple = {
  __typename?: 'InviteRoleTuple';
  id: Scalars['String']['output'];
  relation: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type InviteRoleTupleInput = {
  id: Scalars['String']['input'];
  relation: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type InviteSort = {
  asc: Scalars['Boolean']['input'];
  field: InviteSortField;
};

export type InviteSortField =
  | 'CREATED'
  | 'EMAIL'
  | 'NAME'
  | 'UPDATED';

export type InviteStatus =
  | 'EXPIRED'
  | 'PENDING'
  | 'PENDING_APPROVAL'
  | 'REGISTERED';

export type InviteUserInput = {
  companyUuid?: InputMaybe<Scalars['UUID']['input']>;
  email: Scalars['String']['input'];
  groupUuids?: InputMaybe<Array<Scalars['UUID']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  roleUuids?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type InviteUserV3Input = {
  companyUuid?: InputMaybe<Scalars['UUID']['input']>;
  email: Scalars['String']['input'];
  flexTenantUuid?: InputMaybe<Scalars['UUID']['input']>;
  groupUuids?: InputMaybe<Array<Scalars['UUID']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  roleTuples?: InputMaybe<Array<InviteRoleTupleInput>>;
};

export type InviteUsersResult = {
  __typename?: 'InviteUsersResult';
  existingEmails?: Maybe<Scalars['String']['output']>;
  existingInviteEmails?: Maybe<Scalars['String']['output']>;
  invalidEmails?: Maybe<Scalars['String']['output']>;
  invitedCount: Scalars['Int']['output'];
  invitedEmails?: Maybe<Scalars['String']['output']>;
};

export type IosImages = {
  __typename?: 'IosImages';
  appIcon?: Maybe<Scalars['String']['output']>;
  compressedUrl?: Maybe<Scalars['String']['output']>;
  myAccountLarge?: Maybe<Scalars['String']['output']>;
  myAccountMedium?: Maybe<Scalars['String']['output']>;
  myAccountSmall?: Maybe<Scalars['String']['output']>;
  splashScreen?: Maybe<Scalars['String']['output']>;
};

export type IosImagesInput = {
  appIcon?: InputMaybe<Scalars['String']['input']>;
  myAccountLarge?: InputMaybe<Scalars['String']['input']>;
  myAccountMedium?: InputMaybe<Scalars['String']['input']>;
  myAccountSmall?: InputMaybe<Scalars['String']['input']>;
  splashScreen?: InputMaybe<Scalars['String']['input']>;
};

export type IrisContentSearchFilters = {
  contentTypes: Array<SearchContentType>;
  sites: Array<Scalars['UUID']['input']>;
  term: Scalars['String']['input'];
};

export type IrisSearchResult = {
  __typename?: 'IrisSearchResult';
  baseContentUuid: Scalars['UUID']['output'];
  contentUuid: Scalars['UUID']['output'];
  image?: Maybe<Image>;
  publishedDate: Scalars['DateTime']['output'];
  sites: Array<Portal>;
  title: Scalars['String']['output'];
  type: SearchContentType;
  vendorName?: Maybe<Scalars['String']['output']>;
  vendorUuid?: Maybe<Scalars['UUID']['output']>;
};

export type Lease = {
  __typename?: 'Lease';
  breakDate?: Maybe<Scalars['DateTime']['output']>;
  businessNumber?: Maybe<Scalars['String']['output']>;
  businessNumberType?: Maybe<Scalars['String']['output']>;
  company?: Maybe<CompanyV2>;
  employeeCount?: Maybe<Scalars['Int']['output']>;
  expiryDate: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  notificationDate?: Maybe<Scalars['DateTime']['output']>;
  size: Scalars['Int']['output'];
  startDate: Scalars['DateTime']['output'];
  uuid: Scalars['UUID']['output'];
};

export type Level = {
  __typename?: 'Level';
  buildingLevels: Array<BuildingLevel>;
  name: Scalars['String']['output'];
  uuid: Scalars['UUID']['output'];
};

export type LicenseStatus = {
  __typename?: 'LicenseStatus';
  active: Scalars['Boolean']['output'];
};

export type Like = {
  __typename?: 'Like';
  contentUuid: Scalars['UUID']['output'];
  created: Scalars['DateTime']['output'];
  siteUuid: Scalars['UUID']['output'];
  userUuid: Scalars['UUID']['output'];
  uuid: Scalars['UUID']['output'];
};

/** Like connection type */
export type LikeConnection = {
  __typename?: 'LikeConnection';
  edges: Array<Maybe<LikeEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type LikeEdge = {
  __typename?: 'LikeEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Like>;
};

export type LikeMetricInput = {
  appVersion?: InputMaybe<Scalars['String']['input']>;
  applicationId: Scalars['String']['input'];
  contentType: ContentType;
  contentUuid: Scalars['UUID']['input'];
  id: Scalars['UUID']['input'];
  siteUuid: Scalars['UUID']['input'];
  timestamp: Scalars['DateTime']['input'];
  type: LikeOperationType;
  userAgent?: InputMaybe<Scalars['String']['input']>;
  userUuid: Scalars['String']['input'];
};

export type LikeOperationType =
  | 'LIKE'
  | 'REMOVE_LIKE';

export type LineItem = {
  __typename?: 'LineItem';
  /** comments coming in from the customer regarding this line item */
  comments?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  /** adjustment description */
  description?: Maybe<Scalars['String']['output']>;
  discount?: Maybe<Discount>;
  discountTotal: Scalars['Int']['output'];
  exTaxDiscountTotal: Scalars['Int']['output'];
  incTaxDiscountTotal: Scalars['Int']['output'];
  /** Whether this line item is a refund */
  isRefund: Scalars['Boolean']['output'];
  mods?: Maybe<Array<LineItemMod>>;
  preDiscountTotal: Scalars['Int']['output'];
  product?: Maybe<Product>;
  profile?: Maybe<Profile>;
  quantity: Scalars['Int']['output'];
  /** Sub total of line item excluding modifiers */
  subTotal: Scalars['Int']['output'];
  /** Sub total of line item including modifiers */
  subTotalIncludingMods: Scalars['Int']['output'];
  userRsvp?: Maybe<UserRsvp>;
  /** The UUID of the user who added this line item (not set for customer-added line items). */
  userUuid?: Maybe<Scalars['UUID']['output']>;
  uuid: Scalars['String']['output'];
};

export type LineItemChoice = {
  __typename?: 'LineItemChoice';
  /** Name of the choice or text value. */
  description: Scalars['String']['output'];
  /** Mod Choice combination price added. */
  unitPrice: Scalars['Int']['output'];
  /** Choice UUID, This can be null for mod free text. */
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export type LineItemMod = {
  __typename?: 'LineItemMod';
  /** List of choices added to the mod. */
  choices?: Maybe<Array<LineItemChoice>>;
  /** Mod name. */
  name: Scalars['String']['output'];
  /** Type of the mod. */
  type: ModType;
  /** Mod UUID. */
  uuid: Scalars['UUID']['output'];
};

export type Link = {
  __typename?: 'Link';
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type LinkInput = {
  title: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type LinkTrackInput = {
  position?: InputMaybe<Scalars['Int']['input']>;
  siteUuid: Scalars['UUID']['input'];
  timestamp: Scalars['DateTime']['input'];
  title: Scalars['String']['input'];
  type: LinkTrackType;
  url: Scalars['URI']['input'];
};

export type LinkTrackType =
  | 'QUICKLINK';

export type ListBookingsFilters = {
  buildingUuid?: InputMaybe<Array<Scalars['UUID']['input']>>;
  companyUuid?: InputMaybe<Array<Scalars['UUID']['input']>>;
  finalisedAfter?: InputMaybe<Scalars['DateTime']['input']>;
  finalisedBefore?: InputMaybe<Scalars['DateTime']['input']>;
  levelUuid?: InputMaybe<Array<Scalars['UUID']['input']>>;
  paymentMethod?: InputMaybe<Array<BookableResourcePaymentMethod>>;
  paymentStatus?: InputMaybe<Array<BookingPaymentStatus>>;
  resourceTypeUuid?: InputMaybe<Array<Scalars['UUID']['input']>>;
  resourceUuid?: InputMaybe<Array<Scalars['UUID']['input']>>;
  siteUuid?: InputMaybe<Array<Scalars['UUID']['input']>>;
  skipCancelled?: InputMaybe<Scalars['Boolean']['input']>;
  skipFree?: InputMaybe<Scalars['Boolean']['input']>;
  startsAfter?: InputMaybe<Scalars['DateTime']['input']>;
  startsBefore?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<Array<BookingStatus>>;
  userCompanyUuid?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type LocalisedMessage = {
  __typename?: 'LocalisedMessage';
  fallback: Scalars['String']['output'];
  key: Scalars['String']['output'];
  variablesJSON?: Maybe<Scalars['String']['output']>;
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  expiry: Scalars['DateTime']['output'];
  idToken: Scalars['String']['output'];
  refreshToken: Scalars['String']['output'];
  token: Scalars['JWT']['output'];
  tokenType: Scalars['String']['output'];
};

export type MergeCompaniesFailure = {
  __typename?: 'MergeCompaniesFailure';
  reason: Scalars['String']['output'];
};

export type MergeCompaniesResult = MergeCompaniesFailure | MergeCompaniesSuccess;

export type MergeCompaniesSuccess = {
  __typename?: 'MergeCompaniesSuccess';
  company: CompanyV2;
};

export type MessageInput = {
  data?: InputMaybe<Scalars['JSON']['input']>;
  notification?: InputMaybe<MessageNotificationInput>;
};

/** See https://firebase.google.com/docs/reference/admin/node/admin.messaging.NotificationMessagePayload */
export type MessageNotificationInput = {
  /** iOS. @deprecated */
  badge?: InputMaybe<Scalars['String']['input']>;
  /** iOS, Android, Web. */
  body?: InputMaybe<Scalars['String']['input']>;
  /** iOS, Android. @deprecated */
  bodyLocArgs?: InputMaybe<Scalars['String']['input']>;
  /** iOS, Android @deprecated */
  bodyLocKey?: InputMaybe<Scalars['String']['input']>;
  /** iOS, Android, Web. @deprecated */
  clickAction?: InputMaybe<Scalars['String']['input']>;
  /** Android. @deprecated */
  color?: InputMaybe<Scalars['String']['input']>;
  /** Android, Web. @deprecated */
  icon?: InputMaybe<Scalars['String']['input']>;
  /** iOS, Android. */
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  /** iOS, Android. @deprecated */
  sound?: InputMaybe<Scalars['String']['input']>;
  /** Android. @deprecated */
  tag?: InputMaybe<Scalars['String']['input']>;
  /** iOS, Android, Web. */
  title?: InputMaybe<Scalars['String']['input']>;
  /** iOS, Android. @deprecated */
  titleLocArgs?: InputMaybe<Scalars['String']['input']>;
  /** iOS, Android. @deprecated */
  titleLocKey?: InputMaybe<Scalars['String']['input']>;
  /** Web. */
  webIcon?: InputMaybe<Scalars['String']['input']>;
};

export type MetricInput = {
  /** Application ID */
  AI: Scalars['String']['input'];
  /** App version */
  AV?: InputMaybe<Scalars['String']['input']>;
  /** Building ID */
  BI?: InputMaybe<Scalars['UUID']['input']>;
  /** Building name */
  BN?: InputMaybe<Scalars['String']['input']>;
  /** Client ID */
  CI?: InputMaybe<Scalars['UUID']['input']>;
  /** Client name */
  CN?: InputMaybe<Scalars['String']['input']>;
  /** Currency */
  CU?: InputMaybe<Scalars['String']['input']>;
  /** Data type */
  DT: DataType;
  /** Flex operator ID */
  FI?: InputMaybe<Scalars['UUID']['input']>;
  /** Flex operator name */
  FO?: InputMaybe<Scalars['String']['input']>;
  /** Optional identifier to overwrite existing metrics */
  ID?: InputMaybe<Scalars['String']['input']>;
  /** Company ID */
  KI?: InputMaybe<Scalars['UUID']['input']>;
  /** Company name */
  KN?: InputMaybe<Scalars['String']['input']>;
  /** Level ID */
  LI?: InputMaybe<Scalars['UUID']['input']>;
  /** Level name */
  LV?: InputMaybe<Scalars['String']['input']>;
  /** Name of metric */
  ME: Scalars['String']['input'];
  /** Metric metadata */
  MM?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Nav source */
  NS?: InputMaybe<Scalars['String']['input']>;
  /** Nav position */
  NX?: InputMaybe<Scalars['Int']['input']>;
  /** Property ID */
  PI?: InputMaybe<Scalars['UUID']['input']>;
  /** Property name */
  PN?: InputMaybe<Scalars['String']['input']>;
  /** Region */
  RG?: InputMaybe<Scalars['String']['input']>;
  /** Space ID */
  SD?: InputMaybe<Scalars['UUID']['input']>;
  /** Space name */
  SP?: InputMaybe<Scalars['String']['input']>;
  /** Target Building ID */
  TBI?: InputMaybe<Scalars['String']['input']>;
  /** Target Building Name */
  TBN?: InputMaybe<Scalars['String']['input']>;
  /** Target category */
  TC?: InputMaybe<Scalars['String']['input']>;
  /** Target description */
  TD?: InputMaybe<Scalars['String']['input']>;
  /** Target extra */
  TE?: InputMaybe<Scalars['String']['input']>;
  /** Tags */
  TG?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Target company ID */
  TI?: InputMaybe<Scalars['UUID']['input']>;
  /** Target company name */
  TK?: InputMaybe<Scalars['String']['input']>;
  /** Target timestamp (eg. appointment start time) */
  TM?: InputMaybe<Scalars['DateTime']['input']>;
  /** Target parent */
  TP?: InputMaybe<Scalars['String']['input']>;
  /** Target Property ID */
  TPI?: InputMaybe<Scalars['String']['input']>;
  /** Target Property Name */
  TPN?: InputMaybe<Scalars['String']['input']>;
  /** Target reference */
  TR?: InputMaybe<Scalars['String']['input']>;
  /** Timestamp of the metric */
  TS: Scalars['DateTime']['input'];
  /** Target type */
  TT?: InputMaybe<Scalars['String']['input']>;
  /** Target user */
  TU?: InputMaybe<Scalars['String']['input']>;
  /** Timezone */
  TZ?: InputMaybe<Scalars['String']['input']>;
  /** User agent */
  UA?: InputMaybe<Scalars['String']['input']>;
  /** User Email (Can pass in this or UI) */
  UE?: InputMaybe<Scalars['String']['input']>;
  /** User UUID (Can pass in this or UE) */
  UI?: InputMaybe<Scalars['UUID']['input']>;
  /** Metric version number */
  V?: InputMaybe<Scalars['Int']['input']>;
  /** Vendor category */
  VC?: InputMaybe<Scalars['String']['input']>;
  /** Vendor ID */
  VI?: InputMaybe<Scalars['UUID']['input']>;
  /** Value */
  VL: Scalars['Float']['input'];
  /** Vendor name */
  VN?: InputMaybe<Scalars['String']['input']>;
  /** Cross reference */
  XR?: InputMaybe<Scalars['String']['input']>;
};

export type MetricInputV2 = {
  /** Application ID */
  AI: Scalars['String']['input'];
  /** App version */
  AV?: InputMaybe<Scalars['String']['input']>;
  /** Building ID */
  BI?: InputMaybe<Scalars['UUID']['input']>;
  /** Building name */
  BN?: InputMaybe<Scalars['String']['input']>;
  /** Currency */
  CU?: InputMaybe<Scalars['String']['input']>;
  /** Data type */
  DT: DataType;
  /** Flex operator ID */
  FI?: InputMaybe<Scalars['UUID']['input']>;
  /** Flex operator name */
  FO?: InputMaybe<Scalars['String']['input']>;
  /** Optional identifier to overwrite existing metrics */
  ID?: InputMaybe<Scalars['String']['input']>;
  /** Company ID */
  KI?: InputMaybe<Scalars['UUID']['input']>;
  /** Company name */
  KN?: InputMaybe<Scalars['String']['input']>;
  /** Level ID */
  LI?: InputMaybe<Scalars['UUID']['input']>;
  /** Level name */
  LV?: InputMaybe<Scalars['String']['input']>;
  /** Name of metric */
  ME: Scalars['String']['input'];
  /** Metric metadata */
  MM?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Nav source */
  NS?: InputMaybe<Scalars['String']['input']>;
  /** Nav position */
  NX?: InputMaybe<Scalars['Int']['input']>;
  /** Property ID */
  PI?: InputMaybe<Scalars['UUID']['input']>;
  /** Registration type */
  RT?: InputMaybe<CortexEnricherProfileRegistrationType>;
  /** Space ID */
  SD?: InputMaybe<Scalars['UUID']['input']>;
  /** Space name */
  SP?: InputMaybe<Scalars['String']['input']>;
  /** Target Building ID */
  TBI?: InputMaybe<Scalars['String']['input']>;
  /** Target Building Name */
  TBN?: InputMaybe<Scalars['String']['input']>;
  /** Target category */
  TC?: InputMaybe<Scalars['String']['input']>;
  /** Target description */
  TD?: InputMaybe<Scalars['String']['input']>;
  /** Target extra */
  TE?: InputMaybe<Scalars['String']['input']>;
  /** Tags */
  TG?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Target company ID */
  TI?: InputMaybe<Scalars['UUID']['input']>;
  /** Target company name */
  TK?: InputMaybe<Scalars['String']['input']>;
  /** Target timestamp (eg. appointment start time) */
  TM?: InputMaybe<Scalars['DateTime']['input']>;
  /** Target parent */
  TP?: InputMaybe<Scalars['String']['input']>;
  /** Target Property ID */
  TPI?: InputMaybe<Scalars['String']['input']>;
  /** Target Property Name */
  TPN?: InputMaybe<Scalars['String']['input']>;
  /** Target reference */
  TR?: InputMaybe<Scalars['String']['input']>;
  /** Timestamp of the metric */
  TS: Scalars['DateTime']['input'];
  /** Target type */
  TT?: InputMaybe<Scalars['String']['input']>;
  /** Target user */
  TU?: InputMaybe<Scalars['String']['input']>;
  /** Timezone */
  TZ?: InputMaybe<Scalars['String']['input']>;
  /** User agent */
  UA?: InputMaybe<Scalars['String']['input']>;
  /** User Email (Can pass in this or UI) */
  UE?: InputMaybe<Scalars['String']['input']>;
  /** User UUID (Can pass in this or UE) */
  UI?: InputMaybe<Scalars['UUID']['input']>;
  /** Metric version number */
  V?: InputMaybe<Scalars['Int']['input']>;
  /** Vendor category */
  VC?: InputMaybe<Scalars['String']['input']>;
  /** Vendor ID */
  VI?: InputMaybe<Scalars['UUID']['input']>;
  /** Value */
  VL: Scalars['Float']['input'];
  /** Vendor name */
  VN?: InputMaybe<Scalars['String']['input']>;
  /** Cross reference */
  XR?: InputMaybe<Scalars['String']['input']>;
};

export type MetricUnit =
  | 'Count'
  | 'Percentage';

export type MetricValue = {
  __typename?: 'MetricValue';
  unit: MetricUnit;
  value: Scalars['Float']['output'];
};

export type MobileDiscountValidationResult = DiscountMutationFailure | DiscountMutationSuccess | NullDiscountCode;

export type Mod = Node & {
  __typename?: 'Mod';
  /**
   * @deprecated use modChoices instead
   * @deprecated use modChoices instead
   */
  choices?: Maybe<Array<Maybe<Choice>>>;
  mandatory: Scalars['Boolean']['output'];
  modChoices?: Maybe<Array<ModChoice>>;
  name: Scalars['String']['output'];
  type: ModType;
  uuid: Scalars['UUID']['output'];
};

export type ModChoice = Node & {
  __typename?: 'ModChoice';
  choice?: Maybe<Choice>;
  unitPrice: Scalars['Int']['output'];
  uuid: Scalars['UUID']['output'];
  weight: Scalars['Int']['output'];
};

export type ModType =
  | 'FREE_TEXT'
  | 'MULTI_CHOICE'
  | 'SINGLE_CHOICE';

export type ModifyLeaseInput = {
  breakDate?: InputMaybe<Scalars['DateTime']['input']>;
  dateOfChange: Scalars['DateTime']['input'];
  employeeCount?: InputMaybe<Scalars['Int']['input']>;
  expiryDate: Scalars['DateTime']['input'];
  notificationDate?: InputMaybe<Scalars['DateTime']['input']>;
  size: Scalars['Int']['input'];
  uuid: Scalars['UUID']['input'];
};

export type MultipleBookingFailedOccurrence = {
  __typename?: 'MultipleBookingFailedOccurrence';
  endDate: Scalars['DateTime']['output'];
  startDate: Scalars['DateTime']['output'];
};

export type MultipleBookingFailureResponse = {
  __typename?: 'MultipleBookingFailureResponse';
  failedOccurrences: Array<MultipleBookingFailedOccurrence>;
  reason: LocalisedMessage;
};

export type MultipleBookingMutationResult = MultipleBookingFailureResponse | MultipleBookingSuccessResponse;

export type MultipleBookingSuccessResponse = {
  __typename?: 'MultipleBookingSuccessResponse';
  booking: Array<Booking>;
  paymentIntent?: Maybe<BookingPaymentIntent>;
};

/** Mutations that are supported by the API. */
export type Mutation = {
  __typename?: 'Mutation';
  acceptTermsForDestination: Scalars['Boolean']['output'];
  accessPassMetric: MutationResult;
  addAdminNotification: AdminNotification;
  addApartmentDestination: ActionResult;
  /** Add Booking for a session. */
  addBooking: Scalars['Boolean']['output'];
  addBuildingLevel: BuildingLevel;
  /**
   * Add check-in operation for an event.
   * @deprecated use 'checkIn' instead, bad naming.
   */
  addCheckIn: Scalars['Boolean']['output'];
  /** Add checkin token for checkin by token */
  addCheckInToken: Scalars['Boolean']['output'];
  /** Adds a comment to some content */
  addComment: AddCommentResult;
  addCompanyV2Destination: ActionResult;
  addLevel: Scalars['Boolean']['output'];
  addLevels: AddLevelsResult;
  addOrDeductContentPopularityScore: Scalars['Boolean']['output'];
  addProfileComment: Scalars['Boolean']['output'];
  addProfileHistory: Scalars['Boolean']['output'];
  /** Add RSVP operation for an event occurrence. */
  addRsvp: Scalars['Boolean']['output'];
  addRsvpV2: AddRsvpResult;
  /** @deprecated Using openfga for all roles */
  addSiteManager: ActionResult;
  addSpace: Space;
  addSpaces: Array<Space>;
  addToWalletMetric: MutationResult;
  addUserDestination: ActionResult;
  addUserEventCheckIn: EventCheckInMutationResult;
  adminCreateDestination: DestinationResult;
  adminDeleteDestination?: Maybe<ActionResult>;
  adminDeleteSegmentationUser: Scalars['Boolean']['output'];
  adminMigrateDestinationToEquiemOne: Destination;
  /** @deprecated Equiem/console repo archived */
  adminUpdateConsoleAccess: Scalars['Boolean']['output'];
  adminUpdateDestinationLegal?: Maybe<ActionResult>;
  adminUpsertDestination?: Maybe<ActionResult>;
  /**
   * deprecated by adminUpsertHomeUIWidgets()
   * @deprecated Use adminUpsertHomeUIWidgets()
   */
  adminUpsertHomeUIWidget?: Maybe<ActionResult>;
  /**
   * deprecates adminUpsertHomeUIWidget, which should have been plural as that takes a list of widgets
   * this mutation inserts/updates a list widgets and requires a uuid of each widget
   * note: you can't change the widget name with this mutation, please use system-to-system only
   * upsertHomeUIWidgetV2() mutation to change the widget name.
   */
  adminUpsertHomeUIWidgets?: Maybe<ActionResult>;
  /**
   * @deprecated use top level analytic mutations instead
   * @deprecated use top level analytic mutations instead
   */
  analytics: Analytics;
  analyticsAffiliateClick: MutationResult;
  analyticsEnterCarpark: MutationResult;
  analyticsExitCarpark: MutationResult;
  analyticsLinkTrack: MutationResult;
  analyticsPageView: MutationResult;
  analyticsPushTrack: MutationResult;
  analyticsSiteSwitch?: Maybe<MutationResult>;
  analyticsTabView: MutationResult;
  analyticsViewBuildingInfoPost: Scalars['Boolean']['output'];
  analyticsViewDeal: MutationResult;
  analyticsViewEventPost: Scalars['Boolean']['output'];
  analyticsViewNewsPost: Scalars['Boolean']['output'];
  /** A product has been viewed. */
  analyticsViewProduct: MutationResult;
  /** A vendor has been viewed. */
  analyticsViewVendor: MutationResult;
  /** A vendor category has been viewed. */
  analyticsViewVendorCategory: MutationResult;
  /** like analyticsPageView but sends an anonymous_page_view metric type without an ur down the pipeline */
  anonymousAnalyticsPageView: MutationResult;
  /** like for anonymous user, user uuid must be absent */
  anonymousLike: Scalars['Boolean']['output'];
  anonymousLikeMetric: MutationResult;
  answerPollQuestion: Scalars['Boolean']['output'];
  appointmentsExportCsv: ExportJobResponse;
  approveUserInviteRegistration: ApproveUserInviteRegistrationResult;
  archiveCMSArticle: CmsArticle;
  assignProfileToFlexTenant: FlexTenantMembership;
  assignUserToApartment?: Maybe<ActionResult>;
  /** @deprecated Use grantRoleToUser instead */
  associateRoleToUser: Policy;
  /** @deprecated Use grantRolesToUser instead */
  associateRolesToUser: Array<Policy>;
  authRegisterUser?: Maybe<AuthRegistrationResponse>;
  batchMetrics: Array<MutationResult>;
  beginAnonEmailVerification: Scalars['Boolean']['output'];
  /** Begin the process of changing the user's email (requires subsequent verification by clicking a link in an email that will be sent to this address). */
  beginEmailChange: Scalars['Boolean']['output'];
  /** Sends a verification email to a user's email address. */
  beginEmailVerification: Scalars['Boolean']['output'];
  billingCustomerCreate: BillingCustomer;
  billingCustomerDelete: Scalars['Boolean']['output'];
  bluepoint?: Maybe<BpMutation>;
  bookMultipleResource: MultipleBookingMutationResult;
  bookResource: BookingMutationResult;
  bookingMutations: BookingMutations;
  bulkDeleteUsers: DeleteUsersResult;
  cancelBookableResourceDeletion: BookableResource;
  /** Cancel Booking for a session. */
  cancelBooking: Scalars['Boolean']['output'];
  /** Cancel booking from a given order */
  cancelBookingByOrder: Scalars['Boolean']['output'];
  /** Cancel check-in operation for an event. */
  cancelCheckIn: Scalars['Boolean']['output'];
  cancelReceptionDeletion: VisitorMutationResult;
  cancelResourceBooking: BookingMutationResult;
  /**
   * Cancel RSVP operation for an event occurrence.
   * @deprecated use cancelRsvpV2
   */
  cancelRsvp: Scalars['Boolean']['output'];
  cancelRsvpV2: Scalars['Boolean']['output'];
  cancelSession: SessionCanceledResponse;
  cancelSubscription: ProductSubscription;
  /** @deprecated Use updateAuthProfile instead */
  changeEmail?: Maybe<MutationResult>;
  changePassword?: Maybe<MutationResult>;
  changePasswordV2: ChangePasswordResult;
  changeRegistrationTypeForDestination: Scalars['Boolean']['output'];
  changeVisitorInfo?: Maybe<Visitor>;
  changeVisitorStatus?: Maybe<Visitor>;
  /** Check-in to an event. */
  checkIn: Scalars['Boolean']['output'];
  /** Checkin by given token */
  checkInByToken: Scalars['Boolean']['output'];
  checkInMetric: MutationResult;
  /** @deprecated Field no longer supported */
  checkout: CheckoutOutput;
  checkoutV2: CheckoutOutputV2;
  clearChatHistory: Scalars['Boolean']['output'];
  clearOtpToken: MutationResult;
  cloneBookableResource: BookableResource;
  commentMetric: MutationResult;
  /** Verifies a user's email address using the token they received via email. */
  confirmEmailVerification: EmailVerificationResult;
  connectBookingPayment: ConnectDisconnectBookingPaymentResult;
  /** marks invites as converted that has the the given email address, returns number of invites converted */
  convertInvitesByEmail: Scalars['Int']['output'];
  /** Add AccessOne Integration to Site */
  createAc1Integration?: Maybe<CreateIntegrationPayload>;
  createActivityOfService: Scalars['Boolean']['output'];
  createActivityOfUser: Scalars['Boolean']['output'];
  createArticleUploadUrl: CreateImageResult;
  createAvatarUploadUrl: CreateAvatarResult;
  createBarrierControlAccess: BarrierControlAccess;
  createBarrierControlAccessConfig: BarrierControlAccessConfig;
  createBarrierControlConfig: BarrierControlConfig;
  createBarrierControlConfigCredentials: BarrierControlConfigCredentials;
  createBookableResource: BookableResource;
  createBookableResourceUploadUrl: CreateImageResult;
  /** @deprecated Use createUpdateBookingBlockOut */
  createBookingBlockOut: CreateBookingBlockOutResult;
  createBuldingUploadUrl: CreateImageResult;
  createCMSArticle: CmsArticle;
  createCustomCssUploadUrl: CreateImageResult;
  createDestinationUploadUrl: CreateImageResult;
  createFlexOperator: FlexOperator;
  createFlexTenant: FlexTenant;
  createLease: Lease;
  createOrUpdateApartment: ApartmentResult;
  createOrUpdateArea: AreaSyncResult;
  createOrUpdateBuilding: BuildingSyncResult;
  createOrUpdateCompanyV2: CompanyV2SyncResult;
  createOrUpdateFlexTenantBillingCustomer: BillingCustomer;
  createReceptionistNote: ReceptionistNote;
  createSecureUploadUrl: CreateFileResult;
  createServiceComment: Scalars['Boolean']['output'];
  createSupportTicketUploadUrl: CreateFileResult;
  createTemporaryUploadUrl: CreateImageResult;
  createUpdateBookingBlockOut: CreateBookingBlockOutResult;
  createUpdateBookingDiscount: BookingDiscount;
  createUpdateResourceFeature: BookableResourceFeature;
  createUpdateResourceRoomConfiguration: BookableResourceRoomConfiguration;
  createUpdateResourceType: BookableResourceTypeV2;
  createVisitorAppointment: VisitorAppointment;
  createVisitorAppointmentForBooking: VisitorAppointment;
  createVisitorReception: VisitorReception;
  createWorkRequest: WorkRequest;
  creditAccountAdjustCredits: CreditTransaction;
  creditAccountCharge: CreditTransaction;
  creditAccountCreate: CreditAccount;
  creditAccountPurchaseCredits: CreditTransaction;
  creditCustomerCreate: CreditCustomer;
  creditProviderCreate: CreditProvider;
  creditProviderUpdate: CreditProvider;
  creditTransactionExportCsv: ExportJobResponse;
  creditTransactionRefund: CreditTransaction;
  creditTransactionRefundAndCharge: CreditTransaction;
  deactivateOwnUser?: Maybe<DeactivateUserResponse>;
  /** Deletes AccessOne Integration */
  deleteAc1Integration?: Maybe<DeleteIntegrationPayload>;
  deleteAdminNotification: Scalars['Boolean']['output'];
  deleteAllAdminNotificationsForCurrentUser: Scalars['Boolean']['output'];
  deleteApartment: Scalars['Boolean']['output'];
  deleteArea: Scalars['Boolean']['output'];
  deleteAttribute: ActionResult;
  deleteAuthMap: Scalars['Boolean']['output'];
  deleteAuthUser: MutationResult;
  deleteBarrierControlAccess: ActionResult;
  deleteBarrierControlAccessConfig: ActionResult;
  deleteBarrierControlConfig: BarrierControlMutationResult;
  deleteBookableResource: BookableResource;
  deleteBookingBlockOut: DeleteBookingBlockOutResult;
  deleteBookingDiscount: Scalars['Boolean']['output'];
  deleteBookingInvoiceContact: BookingInvoiceContactDeleteResult;
  deleteBuilding: Scalars['Boolean']['output'];
  deleteBuildingLevel: Scalars['Boolean']['output'];
  deleteComment: DeleteResult;
  deleteCompanyAttribute: ActionResult;
  deleteCompanyV2: Scalars['Boolean']['output'];
  deleteContent: Scalars['Boolean']['output'];
  deleteContentCollection: Scalars['Boolean']['output'];
  deleteDestination: SyndicateActionResult;
  deleteFilterItem: Scalars['Boolean']['output'];
  deleteGroup: ActionResult;
  /** delete one specific home-widget */
  deleteHomeUIWidget: SyndicateActionResult;
  deleteIndustry: ActionResult;
  deleteLease: Scalars['Boolean']['output'];
  deleteLevel: Scalars['Boolean']['output'];
  deleteOwnCard: Scalars['Boolean']['output'];
  deleteOwnComment: DeleteResult;
  deleteReceptionistNote: VisitorMutationResult;
  deleteResourceFeature: Scalars['Boolean']['output'];
  deleteResourceRoomConfiguration: Scalars['Boolean']['output'];
  deleteResourceType: Scalars['Boolean']['output'];
  /** Deletes a saved push notification. */
  deleteSavedPushNotification: Scalars['Boolean']['output'];
  deleteSegmentationUser: Scalars['Boolean']['output'];
  deleteSiteAttribute: ActionResult;
  deleteSiteGroup: ActionResult;
  deleteSpace: Scalars['Boolean']['output'];
  deleteVisitorAppointment: VisitorMutationResult;
  deleteVisitorAppointmentByKey: VisitorMutationResult;
  deleteVisitorAppointmentForBooking: VisitorMutationResult;
  deleteVisitorReception: VisitorMutationResult;
  deleteWhitelabelApp: ActionResult;
  /** delete whitelabel app. */
  deleteWhitelabelAppDetails: SyndicateActionResult;
  /** Deregisters a device from the user's profile. */
  deregisterDevice: Scalars['Boolean']['output'];
  /** Intended only for the deactivation jobs coming from profile service. */
  disableUser: Scalars['Boolean']['output'];
  disconnectBookingPayment: ConnectDisconnectBookingPaymentResult;
  dismissFeedback: Scalars['Boolean']['output'];
  duplicateBuilding: Building;
  duplicateBuildingLevel: BuildingLevel;
  duplicateSpace: Space;
  /** create a new otp and email it to the user */
  emailChallenge: OtpChallengeResult;
  emailCommentReport: ReportResult;
  emailPollResponseReport: ReportResult;
  emailRsvpReport: ReportResult;
  eraseProfilePII: ProfileServiceResult;
  exitCarPark?: Maybe<ExitCarParkResult>;
  gallagher?: Maybe<GallagherMutation>;
  generateOtpUrl: Scalars['String']['output'];
  /** To be called when mobile wants to generate credentials for salto ks */
  generateSaltoKsCredential?: Maybe<GenerateCredentialPayload>;
  generateServiceToken: ServiceToken;
  getScopes: ScopesResponse;
  grantRolesToUser: Scalars['Boolean']['output'];
  indexArticle: Scalars['Boolean']['output'];
  indexBookableProduct: Scalars['Boolean']['output'];
  indexBookableResource: Scalars['Boolean']['output'];
  indexBuildingInfoPost: Scalars['Boolean']['output'];
  indexDealProduct: Scalars['Boolean']['output'];
  indexDealVendor: Scalars['Boolean']['output'];
  indexEventPost: Scalars['Boolean']['output'];
  indexFeedback: Scalars['Boolean']['output'];
  indexNewsPost: Scalars['Boolean']['output'];
  indexPlainProduct: Scalars['Boolean']['output'];
  indexPoll: Scalars['Boolean']['output'];
  indexQuickLink: Scalars['Boolean']['output'];
  indexSubscriptionProduct: Scalars['Boolean']['output'];
  indexUser: Scalars['Boolean']['output'];
  indexUsers: Scalars['Boolean']['output'];
  indexVendor: Scalars['Boolean']['output'];
  initiateSegmentReSync: Scalars['Boolean']['output'];
  /** @deprecated Syndicate doesnt contain companies */
  initiateSyndicateSync: Scalars['Boolean']['output'];
  /** @deprecated User inviteUsersV3 */
  inviteUser: ActionResult;
  /** @deprecated User inviteUsersV3 */
  inviteUsers: InviteUsersResult;
  inviteUsersV3: InviteUsersResult;
  keepChatHistoryAlive: Scalars['Boolean']['output'];
  like: Scalars['Boolean']['output'];
  likeMetric: MutationResult;
  logInviteAction: Scalars['Boolean']['output'];
  login?: Maybe<LoginResponse>;
  loginToBuildingEngines: Scalars['Boolean']['output'];
  makeBookingAdjustment: Scalars['Boolean']['output'];
  markAdminNotificationAsRead: AdminNotification;
  mergeCompanies: MergeCompaniesResult;
  /** @deprecated use metric v2 as the following are ignored: RG, CI, CN */
  metric: MutationResult;
  metricV2: MutationResult;
  modifyLease: Lease;
  notifyPropertyManagers: Array<AdminNotification>;
  /** Event occurrence upsert mutation. */
  occurrenceUpsert: Scalars['Boolean']['output'];
  openpath?: Maybe<OpenpathMutation>;
  /** verify the oobCode-otp pair */
  otpChallengeRespond: OtpChallengeRespondResult;
  /**
   * Used to persist files uploaded to the temporary upload location using Mutation.signTemporaryUploadUrl.
   *
   * Also accepts additional metadata about the file being persisted.
   */
  persistTemporaryUpload: DocumentV2;
  /** @deprecated Use processUserLoginAccess */
  processUserCompanyLogin: SyndicateActionResult;
  processUserLoginAccess: SyndicateActionResult;
  publishCMSArticle: CmsArticle;
  publishSnsTestMessage: SnsTestMessage;
  rateWorkRequest: WorkRequest;
  /** To be called when mobile wants to regenerate credentials for hid origo */
  refreshHidOrigoCredential?: Maybe<RefreshCredentialPayload>;
  refreshLogin?: Maybe<LoginResponse>;
  refundBookingItems: Scalars['Boolean']['output'];
  /** Registers a device to the user's profile. Can be called multiple times idempotently. */
  registerDevice: Device;
  registerExistingUser?: Maybe<ExistingUserRegistrationResult>;
  registerUser?: Maybe<RegistrationResponse>;
  registerUserV2?: Maybe<RegistrationResponseV2>;
  registerVendor?: Maybe<RegistrationResponse>;
  /** To be called when company is removed from a site. All company's users access to this site will be revoked as well */
  removeAc1CompanySiteConfig?: Maybe<RemoveCompanyConfigPayload>;
  /** Removes credential setup for site. Example: removes qrCode with Wiegand for available access_pass for integrations */
  removeAc1CredentialSetup?: Maybe<RemoveCredentialSetupPayload>;
  /** To be called when user is deleted to let AC1 know to remove access */
  removeAc1User?: Maybe<RemoveEmployeePayload>;
  /** To be called when visitor is deleted to let AC1 know to remove access */
  removeAc1Visitor?: Maybe<RemoveVisitorPayload>;
  removeProfileFromFlexTenant: ActionResult;
  /** @deprecated Use revokeRoleFromUser instead */
  removeRoleFromUser: Scalars['Boolean']['output'];
  /** @deprecated Use revokeRolesFromUser instead */
  removeRolesFromUser: Array<Scalars['Boolean']['output']>;
  /** @deprecated Using openfga for all roles */
  removeSiteManager: ActionResult;
  reportComment: Scalars['Boolean']['output'];
  reqMgt: ReqMgtMutation;
  resendAppointmentCodeEmail: VisitorMutationResult;
  resendBookingAccessCode: BookingAccessControlCode;
  resendInvite: Scalars['Boolean']['output'];
  restoreArchivedCMSArticle: CmsArticle;
  /** Sends another verification email to a user whose token has expired. */
  retryEmailVerification: Scalars['Boolean']['output'];
  retrySubscriptionCharge: RetrySubscriptionChargeOutput;
  revertDraftCMSArticle: CmsArticle;
  revokeRolesFromUser: Scalars['Boolean']['output'];
  rewriteLease: Lease;
  rsvpMetric: MutationResult;
  saveAvatar: Scalars['Boolean']['output'];
  /** Create or update a Company. */
  saveCompany: Company;
  saveEquiemOneShortcuts: Scalars['Boolean']['output'];
  saveFilterItem: SegmentationFilterItem;
  saveFiltersForApartment: Scalars['Boolean']['output'];
  saveFiltersForCompany: Scalars['Boolean']['output'];
  /** @deprecated No longer synced. */
  saveFiltersForPermission: Scalars['Boolean']['output'];
  saveOwnAvatar: Scalars['Boolean']['output'];
  /**
   * Create a read-copy of a credit/debit card that was added
   * on the payment gateway by the customer (during checkout)
   */
  saveOwnCard: Card;
  /**
   * Create or update the current customer's own Profile.
   * @deprecated Use saveOwnProfileV2 mutation
   */
  saveOwnProfile: Profile;
  /** Create or update the current customer's own Profile. */
  saveOwnProfileV2: Profile;
  /**
   * Create or update a customer's Profile.
   * @deprecated Use saveProfileV2 mutation
   */
  saveProfile: Profile;
  /**
   * Create or update a customer's Profile, passing through all site profiles
   * @deprecated Use saveProfileForAllSitesV2 mutation
   */
  saveProfileForAllSites: Profile;
  /** Create or update a customer's Profile, passing through all site profiles */
  saveProfileForAllSitesV2: Profile;
  /** Updates the user's profile with values from the Auth service */
  saveProfileFromAuth?: Maybe<Profile>;
  /** Create or update a customer's Profile. */
  saveProfileV2: Profile;
  saveSegmentV2: SavedSegmentV2;
  /** Updates the user with values from the Auth service */
  saveUserAuthProfile?: Maybe<User>;
  sendAiChatFeedback?: Maybe<Scalars['Boolean']['output']>;
  sendAiChatMessage: AiChatResponse;
  sendAiLlamaChatMessage: AiChatResponse;
  sendBarrierControlMessages: BarrierControlMutationResult;
  /** Sends a push notification to multiple recipients */
  sendBatchPushNotification: SendPushNotificationResult;
  sendBookingStatsProviderMessages: ActionResult;
  /** Sends a push notification to the recipient's device(s). */
  sendPushNotification: SendPushNotificationResult;
  sendRegistrationCompletionEmail: SendEmailResult;
  sendRequestStatsProviderMessages: ActionResult;
  sendResetPasswordEmail: MutationResult;
  /** Sends a SMS to the user. */
  sendSMS: Scalars['Boolean']['output'];
  /** Sends a push notification to all the users at a given site. */
  sendSitePushNotification: SendPushNotificationResult;
  /** @deprecated use sendSupportTicketEmailV2 */
  sendSupportTicketEmail: ActionResult;
  sendSupportTicketEmailV2: ActionResult;
  sendVisitorStatsProviderMessages: ActionResult;
  setAppointmentOrganizerRole: VisitorMutationResult;
  setCardId?: Maybe<Visitor>;
  setCardStatus?: Maybe<Visitor>;
  /** Updates a default card. */
  setDefaultCard: Card;
  setEmailVerified?: Maybe<MutationResult>;
  setOnSiteStatus: Scalars['Boolean']['output'];
  setSiteGroupCurationStatus: SiteGroupResult;
  setSitesForCMSArticle: CmsArticle;
  setSubscriptionPaymentMethod: ProductSubscription;
  setUserUbiparkEnabled: UbiparkStatusChangeResult;
  /**
   * Provides a signed upload url and a temporaryUuid to be passed on to Mutation.persistTemporaryUpload.
   *
   * Files which are not persisted with Mutation.persistTemporaryUpload within a set period of time will be automatically deleted.
   */
  signTemporaryUploadUrl: SignTemporaryUploadUrlResult;
  /** request a new sms challenge */
  smsChallenge: SmsChallengeResult;
  /**
   * respond to an existing sms challenge
   * @deprecated Use otpChallengeRespond mutation
   */
  smsChallengeRespond: SmsChallengeRespondResult;
  swiftconnect?: Maybe<SwiftconnectMutation>;
  /** To be called when a user is granted access or updated to let AC1 know to sync new data */
  syncAc1User?: Maybe<SyncEmployeePayload>;
  /** To be called when a visitor is created or updated to let AC1 know to sync new data */
  syncAc1Visitor?: Maybe<SyncVisitorPayload>;
  syncExternalBookings: ExternalBookingSyncResult;
  syncUserProfile: SyncProfileResponse;
  syncUserToEvolution: IntegrationServiceResult;
  terminateLease: Lease;
  toggleAndSyncEvolutionUser: IntegrationServiceResult;
  toggleBookingPaymentAdjustment?: Maybe<BookingSetting>;
  toggleEvolutionForUser: IntegrationServiceResult;
  /** Sends deactivation reminders for a specific site */
  triggerDeactivationFlowForSite: Scalars['Boolean']['output'];
  /** Sends deactivation reminders for a specific site */
  triggerDeactivationFlowForSiteV2: Scalars['Boolean']['output'];
  ubiparkCheckout?: Maybe<CheckoutOutputV2>;
  unlike: Scalars['Boolean']['output'];
  unpublishArticle: Scalars['Boolean']['output'];
  unpublishBookableProduct: Scalars['Boolean']['output'];
  unpublishBookableResource: Scalars['Boolean']['output'];
  unpublishBuildingInfoPost: Scalars['Boolean']['output'];
  unpublishCMSArticle: CmsArticle;
  unpublishDealProduct: Scalars['Boolean']['output'];
  unpublishDealVendor: Scalars['Boolean']['output'];
  unpublishEventPost: Scalars['Boolean']['output'];
  unpublishFeedback: Scalars['Boolean']['output'];
  unpublishNewsPost: Scalars['Boolean']['output'];
  unpublishPlainProduct: Scalars['Boolean']['output'];
  unpublishPoll: Scalars['Boolean']['output'];
  unpublishQuickLink: Scalars['Boolean']['output'];
  unpublishSubscriptionProduct: Scalars['Boolean']['output'];
  unpublishVendor: Scalars['Boolean']['output'];
  unsubscribeFromSiteEmails: Scalars['Boolean']['output'];
  /** Updates AccessOne Integration attributes */
  updateAc1Integration?: Maybe<UpdateIntegrationPayload>;
  updateApprovalNotificationsPreferences: ApprovalNotificationsPreferences;
  /** @deprecated use updateOccurrencesFromSessions */
  updateAttendeeLimitForSessions: UpdateAttendeeLimitMutationResult;
  updateAuthProfile?: Maybe<MutationResult>;
  updateBarrierControlConfig: BarrierControlConfig;
  updateBookableResource: BookableResource;
  updateBookingNotificationPreferences: BookingNotificationPreferences;
  updateBookingPaymentAdjustmentMaxDays?: Maybe<BookingSetting>;
  updateBuildingIntegrations: UpdateBuildingIntegrationsResult;
  updateBuildingLevel: BuildingLevel;
  updateBuildingLevelOrder: Scalars['Boolean']['output'];
  updateBuildingOrder: Scalars['Boolean']['output'];
  updateCMSArticle: CmsArticle;
  /** Mutation to be used by devs at this point. */
  updateClientRegion: Client;
  updateCompanyBookingSettings: CompanyBookingSettings;
  updateCompanyDestinationIntegrations: UpdateCompanyDestinationIntegrationsResult;
  /** @deprecated Incorrectly modeled. Use employeeCount on Lease instead. */
  updateCompanySize: CompanyV2;
  updateCompanyV2Integrations: UpdateCompanyV2IntegrationsResult;
  updateConsoleAccess: Scalars['Boolean']['output'];
  updateDestinationIntegrations: UpdateDestinationIntegrationsResult;
  updateDestinationLegal: SyndicateActionResult;
  updateDestinationType: Destination;
  updateFlexTenant: FlexTenant;
  updateFlexTenantDefaultWorkplaceManagers: FlexTenant;
  updateFlexTenantLocations: FlexTenant;
  updateLevel: Scalars['Boolean']['output'];
  updateOccurrencesFromSessions: UpdateOccurrencesFromSessionsMutationResult;
  updateOperationsCompany?: Maybe<OperationsCompany>;
  updateOwnLocalePreference: Scalars['Boolean']['output'];
  /** Update user's display name and mobile phone number - used by VMS's user management feature */
  updatePartialProfile: Profile;
  updateProfileActive: Profile;
  updateProfileFlexTenants: Array<FlexTenantMembership>;
  updateReceptionistNote: ReceptionistNote;
  updateResourceBooking: BookingMutationResult;
  updateResourceBookingApproval: BookingMutationResult;
  updateSpace: Space;
  updateSpaceOrder: Scalars['Boolean']['output'];
  updateUser: UpdateUserResult;
  updateUserEvolutionId: IntegrationServiceResult;
  updateUserStoreAdmin: ActionResult;
  updateUsersForDestination: UpdateUserStatusResult;
  updateUsersStatusForDestination: UpdateUserStatusResult;
  updateVisitorAppointment: VisitorAppointment;
  updateVisitorAppointmentForBooking: VisitorAppointment;
  updateVisitorBuilding: VisitorBuilding;
  updateVisitorReception: VisitorReception;
  /** Upsert AccessOne Integration to Building, create SQS queues, enable/disable Visitors/Employees. */
  upsertAc1BuildingIntegration?: Maybe<UpsertBuildingIntegrationPayload>;
  /** To be called when company is added to a site and when AC1 configuration is changed for a company in site */
  upsertAc1CompanySiteConfig?: Maybe<UpsertCompanyConfigPayload>;
  /**
   * Upserts QR code credential setup for site. Example: change qrCode with Wiegand
   * pass_name for site, available to be picked up by integrations as pass_name provider
   */
  upsertAc1QrCodeCredentialSetup?: Maybe<UpsertQrCodeCredentialSetupPayload>;
  /**
   * Update the last activity status for a user.
   *
   * This will only trigger a change if the activityTime is later than any previous activity recorded.
   */
  upsertActivityStatus: Scalars['Boolean']['output'];
  /**
   * Update the last activity status for a user by their email address.
   *
   * This will only trigger a change if the activityTime is later than any previous activity recorded.
   */
  upsertActivityStatusByEmail: Scalars['Boolean']['output'];
  upsertAttribute: AttributeResult;
  upsertAttributes: Array<AttributeResult>;
  /** @deprecated No longer used. Use upsertClients if needed. */
  upsertClient: ClientResult;
  upsertClients: Array<ClientResult>;
  upsertCompanyAttribute: CompanyAttributeResult;
  upsertCompanyAttributes: Array<CompanyAttributeResult>;
  upsertContentCollection: ContentCollection;
  /** Configure the custom MX settings for a destination. */
  upsertCustomMxSettings: Scalars['Boolean']['output'];
  upsertDestination: SyndicateActionResult;
  /** Upsert all the domains for a destination at once. */
  upsertDestinationDomains: DestinationInfrastructure;
  /**
   * Configure the email settings for a destination.
   *
   * This endpoint will always update both values. Inputs are nullable because the values
   * are nullable.
   */
  upsertDestinationEmailSettings: Scalars['Boolean']['output'];
  /** Configure the tier level for a destination. */
  upsertDestinationTierLevel: Scalars['Boolean']['output'];
  upsertFeedbackQuestionResponse: FeedbackQuestionResponse;
  upsertGroup: GroupResult;
  upsertGroups: Array<GroupResult>;
  /**
   * deprecated by upsertHomeUIWidgetV2() and upsertHomeUIWidgets() to upsert a single
   * widget and a list of widgets respectively
   * @deprecated Use singular upsertHomeUIWidgetV2() and plural upsertHomeUIWidgets()
   */
  upsertHomeUIWidget: SyndicateActionResult;
  /**
   * deprecates upsertHomeUIWidget, which should have been plural as that takes a list of widgets
   * this mutation inserts/updates a single widget
   */
  upsertHomeUIWidgetV2: SyndicateActionResult;
  /**
   * deprecates upsertHomeUIWidget, which should have been plural as that takes a list of widgets
   * this mutation inserts/updates a list widgets.
   * note: you can't change the widget name with this mutation, please use upsertHomeUIWidgetV2() to change
   * the widget name.
   */
  upsertHomeUIWidgets: SyndicateActionResult;
  upsertIndustries: Array<IndustryResult>;
  upsertIndustry: IndustryResult;
  upsertOccurrenceFromTemplate: Occurrence;
  upsertPropertyManagerMetrics: Scalars['Boolean']['output'];
  upsertResourceBookingAdminNote: BookingMutationResult;
  upsertSiteAttribute: SiteAttributeResult;
  upsertSiteAttributes: Array<SiteAttributeResult>;
  upsertSiteGroup: SiteGroupResult;
  upsertSiteGroups: Array<SiteGroupResult>;
  upsertStatsProviderConfiguration: StatsProviderConfiguration;
  upsertSyndicateClient: Client;
  upsertUser: Scalars['Boolean']['output'];
  upsertVisitorManagementSiteSettings: VisitorManagementSiteSettings;
  upsertWhitelabelApp: Whitelabel;
  /** Mutation to update/insert a whitelabel app . */
  upsertWhitelabelAppDetails: SyndicateActionResult;
  vicinitee?: Maybe<Scalars['String']['output']>;
  visitorAppointmentByAllBuildingsExportCsv: ExportJobResponse;
  visitorAppointmentExportCsv: ExportJobResponse;
  vms: VmsMutation;
  writeAuthRelationshipTuples: WriteAuthRelationshipTuplesResponse;
};


/** Mutations that are supported by the API. */
export type MutationAcceptTermsForDestinationArgs = {
  input: AcceptDestinationTermsInput;
};


/** Mutations that are supported by the API. */
export type MutationAccessPassMetricArgs = {
  input: AccessPassMetricInput;
};


/** Mutations that are supported by the API. */
export type MutationAddAdminNotificationArgs = {
  input: AdminNotificationInput;
};


/** Mutations that are supported by the API. */
export type MutationAddApartmentDestinationArgs = {
  apartments: Array<Scalars['UUID']['input']>;
  destination: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationAddBookingArgs = {
  acceptTermsAndConditions: Scalars['Boolean']['input'];
  attendees?: InputMaybe<Scalars['Int']['input']>;
  occurrenceUuid: Scalars['String']['input'];
  orderLineItemUuid?: InputMaybe<Scalars['String']['input']>;
  orderUuid: Scalars['String']['input'];
  siteUuid: Scalars['String']['input'];
  userUuid: Scalars['String']['input'];
};


/** Mutations that are supported by the API. */
export type MutationAddBuildingLevelArgs = {
  input: AddBuildingLevelInput;
};


/** Mutations that are supported by the API. */
export type MutationAddCheckInArgs = {
  occurrenceUuid: Scalars['UUID']['input'];
  portalUuid: Scalars['UUID']['input'];
  userUuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationAddCheckInTokenArgs = {
  occurrenceUuid: Scalars['String']['input'];
  siteUuid: Scalars['String']['input'];
  userUuid: Scalars['String']['input'];
};


/** Mutations that are supported by the API. */
export type MutationAddCommentArgs = {
  authorUuid?: InputMaybe<Scalars['UUID']['input']>;
  body: Scalars['String']['input'];
  contentType: ContentType;
  contentUuid: Scalars['UUID']['input'];
  parentUuid?: InputMaybe<Scalars['UUID']['input']>;
  siteUuid?: InputMaybe<Scalars['UUID']['input']>;
};


/** Mutations that are supported by the API. */
export type MutationAddCompanyV2DestinationArgs = {
  autoApproveRegistrations?: InputMaybe<Scalars['Boolean']['input']>;
  companies: Array<Scalars['UUID']['input']>;
  destination: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationAddLevelArgs = {
  input: AddLevelInput;
};


/** Mutations that are supported by the API. */
export type MutationAddLevelsArgs = {
  input: Array<AddLevelInput>;
};


/** Mutations that are supported by the API. */
export type MutationAddOrDeductContentPopularityScoreArgs = {
  input: AddOrDeductContentPopularityScoreInput;
};


/** Mutations that are supported by the API. */
export type MutationAddProfileCommentArgs = {
  destinationUuid?: InputMaybe<Scalars['UUID']['input']>;
  message: Scalars['String']['input'];
  profileUuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationAddProfileHistoryArgs = {
  actionType: ProfileEventLogAction;
  destinationUuid?: InputMaybe<Scalars['UUID']['input']>;
  message: Scalars['String']['input'];
  profileUuid: Scalars['UUID']['input'];
  triggeredBy?: InputMaybe<Scalars['UUID']['input']>;
};


/** Mutations that are supported by the API. */
export type MutationAddRsvpArgs = {
  acceptTermsAndConditions: Scalars['Boolean']['input'];
  occurrenceUuid: Scalars['String']['input'];
  portalUuid: Scalars['String']['input'];
  userUuid: Scalars['String']['input'];
};


/** Mutations that are supported by the API. */
export type MutationAddRsvpV2Args = {
  acceptTermsAndConditions?: InputMaybe<Scalars['Boolean']['input']>;
  occurrenceUuid: Scalars['String']['input'];
  showEventLinkInEmail?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Mutations that are supported by the API. */
export type MutationAddSiteManagerArgs = {
  destinationUuid: Scalars['UUID']['input'];
  userUuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationAddSpaceArgs = {
  input: AddSpaceInput;
};


/** Mutations that are supported by the API. */
export type MutationAddSpacesArgs = {
  input: BulkSpaceInput;
};


/** Mutations that are supported by the API. */
export type MutationAddToWalletMetricArgs = {
  input: AddToWalletMetricInput;
};


/** Mutations that are supported by the API. */
export type MutationAddUserDestinationArgs = {
  destinationUuid: Scalars['UUID']['input'];
  userUuid?: InputMaybe<Scalars['UUID']['input']>;
};


/** Mutations that are supported by the API. */
export type MutationAddUserEventCheckInArgs = {
  acceptTermsAndConditions?: InputMaybe<Scalars['Boolean']['input']>;
  contentId: Scalars['UUID']['input'];
  personQuery: EventCheckInPersonQuery;
  portal: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationAdminCreateDestinationArgs = {
  input: CreateDestinationInput;
};


/** Mutations that are supported by the API. */
export type MutationAdminDeleteDestinationArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationAdminDeleteSegmentationUserArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationAdminMigrateDestinationToEquiemOneArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationAdminUpdateConsoleAccessArgs = {
  accessGranted: Scalars['Boolean']['input'];
  triggeredBy: Scalars['UUID']['input'];
  userUuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationAdminUpdateDestinationLegalArgs = {
  input: AdminUpdateDestinationLegalInput;
};


/** Mutations that are supported by the API. */
export type MutationAdminUpsertDestinationArgs = {
  domainInput?: InputMaybe<UpsertDomainInput>;
  input: AdminUpsertDestinationInput;
};


/** Mutations that are supported by the API. */
export type MutationAdminUpsertHomeUiWidgetArgs = {
  destinationUuid: Scalars['UUID']['input'];
  input: Array<AdminUpsertHomeUiWidgetInput>;
  platform?: InputMaybe<AdminPlatformType>;
};


/** Mutations that are supported by the API. */
export type MutationAdminUpsertHomeUiWidgetsArgs = {
  destinationUuid: Scalars['UUID']['input'];
  input: Array<AdminUpsertHomeUiWidgetInputV2>;
  platform: AdminPlatformType;
};


/** Mutations that are supported by the API. */
export type MutationAnalyticsAffiliateClickArgs = {
  dealUuid: Scalars['UUID']['input'];
  timeMs: Scalars['DateTime']['input'];
};


/** Mutations that are supported by the API. */
export type MutationAnalyticsEnterCarparkArgs = {
  beaconName: Scalars['String']['input'];
  timeMs: Scalars['DateTime']['input'];
};


/** Mutations that are supported by the API. */
export type MutationAnalyticsExitCarparkArgs = {
  beaconName: Scalars['String']['input'];
  timeMs: Scalars['DateTime']['input'];
};


/** Mutations that are supported by the API. */
export type MutationAnalyticsLinkTrackArgs = {
  input: LinkTrackInput;
};


/** Mutations that are supported by the API. */
export type MutationAnalyticsPageViewArgs = {
  input: PageViewInput;
};


/** Mutations that are supported by the API. */
export type MutationAnalyticsPushTrackArgs = {
  input: PushTrackInput;
};


/** Mutations that are supported by the API. */
export type MutationAnalyticsSiteSwitchArgs = {
  input: SiteSwitchInput;
};


/** Mutations that are supported by the API. */
export type MutationAnalyticsTabViewArgs = {
  input: TabViewInput;
};


/** Mutations that are supported by the API. */
export type MutationAnalyticsViewBuildingInfoPostArgs = {
  input: AnalyticsViewContentInput;
};


/** Mutations that are supported by the API. */
export type MutationAnalyticsViewDealArgs = {
  dealUuid: Scalars['UUID']['input'];
  navPosition?: InputMaybe<Scalars['Int']['input']>;
  navSource?: InputMaybe<Scalars['String']['input']>;
  timeMs: Scalars['DateTime']['input'];
};


/** Mutations that are supported by the API. */
export type MutationAnalyticsViewEventPostArgs = {
  input: AnalyticsViewContentInput;
};


/** Mutations that are supported by the API. */
export type MutationAnalyticsViewNewsPostArgs = {
  input: AnalyticsViewContentInput;
};


/** Mutations that are supported by the API. */
export type MutationAnalyticsViewProductArgs = {
  navPosition?: InputMaybe<Scalars['Int']['input']>;
  navSource?: InputMaybe<Scalars['String']['input']>;
  productUuid: Scalars['UUID']['input'];
  timeMs: Scalars['DateTime']['input'];
};


/** Mutations that are supported by the API. */
export type MutationAnalyticsViewVendorArgs = {
  navPosition?: InputMaybe<Scalars['Int']['input']>;
  navSource?: InputMaybe<Scalars['String']['input']>;
  timeMs: Scalars['DateTime']['input'];
  vendorUuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationAnalyticsViewVendorCategoryArgs = {
  categoryUuid: Scalars['UUID']['input'];
  timeMs: Scalars['DateTime']['input'];
};


/** Mutations that are supported by the API. */
export type MutationAnonymousAnalyticsPageViewArgs = {
  input: PageViewInput;
};


/** Mutations that are supported by the API. */
export type MutationAnonymousLikeArgs = {
  contentType: ContentType;
  contentUuid: Scalars['UUID']['input'];
  siteUuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationAnonymousLikeMetricArgs = {
  input: AnonymousLikeMetricInput;
};


/** Mutations that are supported by the API. */
export type MutationAnswerPollQuestionArgs = {
  optionUuid?: InputMaybe<Scalars['UUID']['input']>;
  pollUuid: Scalars['UUID']['input'];
  questionUuid: Scalars['UUID']['input'];
  response: Scalars['String']['input'];
  submissionId: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationAppointmentsExportCsvArgs = {
  input: AppointmentsExportCsvInput;
};


/** Mutations that are supported by the API. */
export type MutationApproveUserInviteRegistrationArgs = {
  invite: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationArchiveCmsArticleArgs = {
  input: ArchiveCmsArticleInput;
};


/** Mutations that are supported by the API. */
export type MutationAssignProfileToFlexTenantArgs = {
  input: AssignProfileToFlexTenantInput;
};


/** Mutations that are supported by the API. */
export type MutationAssignUserToApartmentArgs = {
  input: AssignUserToApartmentInput;
};


/** Mutations that are supported by the API. */
export type MutationAssociateRoleToUserArgs = {
  input: AssociateRoleToUserInput;
};


/** Mutations that are supported by the API. */
export type MutationAssociateRolesToUserArgs = {
  input: Array<AssociateRoleToUserInput>;
};


/** Mutations that are supported by the API. */
export type MutationAuthRegisterUserArgs = {
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  scopes?: InputMaybe<Array<Scalars['String']['input']>>;
};


/** Mutations that are supported by the API. */
export type MutationBatchMetricsArgs = {
  metrics: Array<MetricInputV2>;
};


/** Mutations that are supported by the API. */
export type MutationBeginAnonEmailVerificationArgs = {
  email: Scalars['Email']['input'];
  siteUuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationBeginEmailChangeArgs = {
  newEmail: Scalars['Email']['input'];
};


/** Mutations that are supported by the API. */
export type MutationBillingCustomerCreateArgs = {
  input: BillingCustomerCreateInput;
};


/** Mutations that are supported by the API. */
export type MutationBillingCustomerDeleteArgs = {
  input: BillingCustomerDeleteInput;
};


/** Mutations that are supported by the API. */
export type MutationBookMultipleResourceArgs = {
  input: Array<BookingInput>;
};


/** Mutations that are supported by the API. */
export type MutationBookResourceArgs = {
  input: BookingInput;
};


/** Mutations that are supported by the API. */
export type MutationBulkDeleteUsersArgs = {
  destinationUuid?: InputMaybe<Scalars['UUID']['input']>;
  userUuids: Array<Scalars['UUID']['input']>;
};


/** Mutations that are supported by the API. */
export type MutationCancelBookableResourceDeletionArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationCancelBookingArgs = {
  occurrenceUuid: Scalars['UUID']['input'];
  siteUuid: Scalars['UUID']['input'];
  userUuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationCancelBookingByOrderArgs = {
  orderUuid: Scalars['String']['input'];
};


/** Mutations that are supported by the API. */
export type MutationCancelCheckInArgs = {
  occurrenceUuid: Scalars['UUID']['input'];
  portalUuid: Scalars['UUID']['input'];
  userUuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationCancelReceptionDeletionArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationCancelResourceBookingArgs = {
  acceptTerms?: InputMaybe<Scalars['Boolean']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  uuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationCancelRsvpArgs = {
  occurrenceUuid: Scalars['UUID']['input'];
  portalUuid: Scalars['UUID']['input'];
  userUuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationCancelRsvpV2Args = {
  occurrenceUuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationCancelSessionArgs = {
  occurrenceUuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationCancelSubscriptionArgs = {
  subscriptionUuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationChangeEmailArgs = {
  email: Scalars['String']['input'];
  userUuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationChangePasswordArgs = {
  email: Scalars['String']['input'];
  existingPassword: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
};


/** Mutations that are supported by the API. */
export type MutationChangePasswordV2Args = {
  newPassword: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


/** Mutations that are supported by the API. */
export type MutationChangeRegistrationTypeForDestinationArgs = {
  input: RegistrationTypeForDestinationInput;
};


/** Mutations that are supported by the API. */
export type MutationChangeVisitorInfoArgs = {
  uuid: Scalars['UUID']['input'];
  visitorInfo: Array<VisitorInfo>;
};


/** Mutations that are supported by the API. */
export type MutationChangeVisitorStatusArgs = {
  status: VisitorStatus;
  uuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationCheckInArgs = {
  occurrenceUuid: Scalars['UUID']['input'];
  portalUuid: Scalars['UUID']['input'];
  userUuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationCheckInByTokenArgs = {
  token: Scalars['String']['input'];
};


/** Mutations that are supported by the API. */
export type MutationCheckInMetricArgs = {
  input: CheckInMetricInput;
};


/** Mutations that are supported by the API. */
export type MutationCheckoutArgs = {
  order: OrderInput;
};


/** Mutations that are supported by the API. */
export type MutationCheckoutV2Args = {
  order: OrderInputV2;
};


/** Mutations that are supported by the API. */
export type MutationClearChatHistoryArgs = {
  threadId: Scalars['ID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationClearOtpTokenArgs = {
  userUuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationCloneBookableResourceArgs = {
  overrides?: InputMaybe<CloneBookableResourceOverrides>;
  uuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationCommentMetricArgs = {
  input: CommentMetricInput;
};


/** Mutations that are supported by the API. */
export type MutationConfirmEmailVerificationArgs = {
  token: Scalars['String']['input'];
};


/** Mutations that are supported by the API. */
export type MutationConnectBookingPaymentArgs = {
  code: Scalars['String']['input'];
  site: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationConvertInvitesByEmailArgs = {
  email: Scalars['String']['input'];
  pendingApproval?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Mutations that are supported by the API. */
export type MutationCreateAc1IntegrationArgs = {
  input: CreateIntegrationInput;
};


/** Mutations that are supported by the API. */
export type MutationCreateActivityOfServiceArgs = {
  input: ActivityOfServiceInput;
};


/** Mutations that are supported by the API. */
export type MutationCreateActivityOfUserArgs = {
  input: ActivityOfUserInput;
};


/** Mutations that are supported by the API. */
export type MutationCreateArticleUploadUrlArgs = {
  filename: Scalars['String']['input'];
};


/** Mutations that are supported by the API. */
export type MutationCreateAvatarUploadUrlArgs = {
  input: CreateAvatarInput;
};


/** Mutations that are supported by the API. */
export type MutationCreateBarrierControlAccessArgs = {
  input: BarrierControlAccessInput;
};


/** Mutations that are supported by the API. */
export type MutationCreateBarrierControlAccessConfigArgs = {
  input: BarrierControlAccessConfigInput;
};


/** Mutations that are supported by the API. */
export type MutationCreateBarrierControlConfigArgs = {
  input: BarrierControlConfigInput;
};


/** Mutations that are supported by the API. */
export type MutationCreateBarrierControlConfigCredentialsArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationCreateBookableResourceArgs = {
  input: BookableResourceInput;
};


/** Mutations that are supported by the API. */
export type MutationCreateBookableResourceUploadUrlArgs = {
  input: CreateImageInput;
};


/** Mutations that are supported by the API. */
export type MutationCreateBookingBlockOutArgs = {
  input: BookingBlockOutInput;
};


/** Mutations that are supported by the API. */
export type MutationCreateBuldingUploadUrlArgs = {
  input: CreateImageInput;
};


/** Mutations that are supported by the API. */
export type MutationCreateCmsArticleArgs = {
  input: CreateCmsArticleInput;
};


/** Mutations that are supported by the API. */
export type MutationCreateCustomCssUploadUrlArgs = {
  filename: Scalars['String']['input'];
};


/** Mutations that are supported by the API. */
export type MutationCreateDestinationUploadUrlArgs = {
  input: CreateImageInput;
};


/** Mutations that are supported by the API. */
export type MutationCreateFlexOperatorArgs = {
  input: FlexOperatorInput;
};


/** Mutations that are supported by the API. */
export type MutationCreateFlexTenantArgs = {
  input: FlexTenantInput;
};


/** Mutations that are supported by the API. */
export type MutationCreateLeaseArgs = {
  input: CreateLeaseInput;
};


/** Mutations that are supported by the API. */
export type MutationCreateOrUpdateApartmentArgs = {
  input: ApartmentInput;
};


/** Mutations that are supported by the API. */
export type MutationCreateOrUpdateAreaArgs = {
  input: SyncAreaInput;
};


/** Mutations that are supported by the API. */
export type MutationCreateOrUpdateBuildingArgs = {
  input: SyncBuildingInput;
};


/** Mutations that are supported by the API. */
export type MutationCreateOrUpdateCompanyV2Args = {
  input: SyncCompanyV2Input;
};


/** Mutations that are supported by the API. */
export type MutationCreateOrUpdateFlexTenantBillingCustomerArgs = {
  input: BillingCustomerInput;
};


/** Mutations that are supported by the API. */
export type MutationCreateReceptionistNoteArgs = {
  input: ReceptionistNoteInput;
};


/** Mutations that are supported by the API. */
export type MutationCreateSecureUploadUrlArgs = {
  filename: Scalars['String']['input'];
};


/** Mutations that are supported by the API. */
export type MutationCreateServiceCommentArgs = {
  input: ActivityOfServiceInput;
};


/** Mutations that are supported by the API. */
export type MutationCreateSupportTicketUploadUrlArgs = {
  filename: Scalars['String']['input'];
};


/** Mutations that are supported by the API. */
export type MutationCreateTemporaryUploadUrlArgs = {
  filename: Scalars['String']['input'];
};


/** Mutations that are supported by the API. */
export type MutationCreateUpdateBookingBlockOutArgs = {
  input: BookingBlockOutInput;
};


/** Mutations that are supported by the API. */
export type MutationCreateUpdateBookingDiscountArgs = {
  input: CreateUpdateBookingDiscountInput;
};


/** Mutations that are supported by the API. */
export type MutationCreateUpdateResourceFeatureArgs = {
  input: BookableResourceFeatureInput;
};


/** Mutations that are supported by the API. */
export type MutationCreateUpdateResourceRoomConfigurationArgs = {
  input: ResourceRoomConfigurationInput;
};


/** Mutations that are supported by the API. */
export type MutationCreateUpdateResourceTypeArgs = {
  input: BookableResourceTypeV2Input;
};


/** Mutations that are supported by the API. */
export type MutationCreateVisitorAppointmentArgs = {
  input: VisitorAppointmentInput;
};


/** Mutations that are supported by the API. */
export type MutationCreateVisitorAppointmentForBookingArgs = {
  bookingUuid: Scalars['UUID']['input'];
  siteUuid?: InputMaybe<Scalars['UUID']['input']>;
};


/** Mutations that are supported by the API. */
export type MutationCreateVisitorReceptionArgs = {
  input: VisitorReceptionInput;
};


/** Mutations that are supported by the API. */
export type MutationCreateWorkRequestArgs = {
  input: CreateWorkRequestInput;
};


/** Mutations that are supported by the API. */
export type MutationCreditAccountAdjustCreditsArgs = {
  input: CreditAccountAdjustCreditsInput;
};


/** Mutations that are supported by the API. */
export type MutationCreditAccountChargeArgs = {
  input: CreditAccountChargeInput;
};


/** Mutations that are supported by the API. */
export type MutationCreditAccountCreateArgs = {
  input: CreditAccountCreateInput;
};


/** Mutations that are supported by the API. */
export type MutationCreditAccountPurchaseCreditsArgs = {
  input: CreditAccountPurchaseCreditsInput;
};


/** Mutations that are supported by the API. */
export type MutationCreditCustomerCreateArgs = {
  input: CreditCustomerCreateInput;
};


/** Mutations that are supported by the API. */
export type MutationCreditProviderCreateArgs = {
  input: CreditProviderCreateInput;
};


/** Mutations that are supported by the API. */
export type MutationCreditProviderUpdateArgs = {
  input: CreditProviderUpdateInput;
};


/** Mutations that are supported by the API. */
export type MutationCreditTransactionExportCsvArgs = {
  input: CreditTransactionsExportCsvInput;
};


/** Mutations that are supported by the API. */
export type MutationCreditTransactionRefundArgs = {
  input: CreditTransactionRefundInput;
};


/** Mutations that are supported by the API. */
export type MutationCreditTransactionRefundAndChargeArgs = {
  input: CreditTransactionRefundAndChargeInput;
};


/** Mutations that are supported by the API. */
export type MutationDeleteAc1IntegrationArgs = {
  input: DeleteIntegrationInput;
};


/** Mutations that are supported by the API. */
export type MutationDeleteAdminNotificationArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationDeleteApartmentArgs = {
  destinationUuid?: InputMaybe<Scalars['UUID']['input']>;
  uuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationDeleteAreaArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationDeleteAttributeArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationDeleteAuthMapArgs = {
  authId: Scalars['String']['input'];
};


/** Mutations that are supported by the API. */
export type MutationDeleteAuthUserArgs = {
  userUuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationDeleteBarrierControlAccessArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationDeleteBarrierControlAccessConfigArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationDeleteBarrierControlConfigArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationDeleteBookableResourceArgs = {
  deletionTime: Scalars['DateTime']['input'];
  uuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationDeleteBookingBlockOutArgs = {
  bundle: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationDeleteBookingDiscountArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationDeleteBookingInvoiceContactArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationDeleteBuildingArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationDeleteBuildingLevelArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationDeleteCommentArgs = {
  commentUuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationDeleteCompanyAttributeArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationDeleteCompanyV2Args = {
  destinationUuid?: InputMaybe<Scalars['UUID']['input']>;
  uuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationDeleteContentArgs = {
  contentIds: Array<Scalars['String']['input']>;
};


/** Mutations that are supported by the API. */
export type MutationDeleteContentCollectionArgs = {
  siteUuid?: InputMaybe<Scalars['UUID']['input']>;
  uuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationDeleteDestinationArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationDeleteFilterItemArgs = {
  input: DeleteSegmentationFilterItemInput;
};


/** Mutations that are supported by the API. */
export type MutationDeleteGroupArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationDeleteHomeUiWidgetArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationDeleteIndustryArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationDeleteLeaseArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationDeleteLevelArgs = {
  destinationUuid?: InputMaybe<Scalars['UUID']['input']>;
  uuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationDeleteOwnCardArgs = {
  cardUuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationDeleteOwnCommentArgs = {
  commentUuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationDeleteReceptionistNoteArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationDeleteResourceFeatureArgs = {
  featureType: BookableResourceFeatureType;
  uuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationDeleteResourceRoomConfigurationArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationDeleteResourceTypeArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationDeleteSavedPushNotificationArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationDeleteSegmentationUserArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationDeleteSiteAttributeArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationDeleteSiteGroupArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationDeleteSpaceArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationDeleteVisitorAppointmentArgs = {
  deleteFollowingRecurringAppointments?: InputMaybe<Scalars['Boolean']['input']>;
  uuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationDeleteVisitorAppointmentByKeyArgs = {
  deleteFollowingRecurringAppointments?: InputMaybe<Scalars['Boolean']['input']>;
  key: Scalars['String']['input'];
};


/** Mutations that are supported by the API. */
export type MutationDeleteVisitorAppointmentForBookingArgs = {
  bookingUuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationDeleteVisitorReceptionArgs = {
  deletionTime?: InputMaybe<Scalars['DateTime']['input']>;
  uuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationDeleteWhitelabelAppArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationDeleteWhitelabelAppDetailsArgs = {
  editionId: Scalars['String']['input'];
};


/** Mutations that are supported by the API. */
export type MutationDeregisterDeviceArgs = {
  device: OwnDeviceInput;
};


/** Mutations that are supported by the API. */
export type MutationDisableUserArgs = {
  siteUuid: Scalars['UUID']['input'];
  userUuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationDisconnectBookingPaymentArgs = {
  site: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationDismissFeedbackArgs = {
  feedbackUuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationDuplicateBuildingArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationDuplicateBuildingLevelArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationDuplicateSpaceArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationEmailCommentReportArgs = {
  contentUuid: Scalars['UUID']['input'];
  email: Scalars['String']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};


/** Mutations that are supported by the API. */
export type MutationEmailPollResponseReportArgs = {
  email: Scalars['String']['input'];
  pollUuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationEmailRsvpReportArgs = {
  contentUuid: Scalars['UUID']['input'];
  email: Scalars['String']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};


/** Mutations that are supported by the API. */
export type MutationEraseProfilePiiArgs = {
  userUuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationExitCarParkArgs = {
  laneId: Scalars['Int']['input'];
  userUuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationGenerateOtpUrlArgs = {
  siteUuid?: InputMaybe<Scalars['UUID']['input']>;
  token: Scalars['String']['input'];
  userUuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationGenerateSaltoKsCredentialArgs = {
  input: GenerateCredentialInput;
};


/** Mutations that are supported by the API. */
export type MutationGenerateServiceTokenArgs = {
  data: EncryptedData;
};


/** Mutations that are supported by the API. */
export type MutationGetScopesArgs = {
  data: EncryptedData;
};


/** Mutations that are supported by the API. */
export type MutationGrantRolesToUserArgs = {
  tuples: Array<UserRelationTupleInput>;
};


/** Mutations that are supported by the API. */
export type MutationIndexArticleArgs = {
  input: ArticleInput;
};


/** Mutations that are supported by the API. */
export type MutationIndexBookableProductArgs = {
  input: IndexBookableProductInput;
};


/** Mutations that are supported by the API. */
export type MutationIndexBookableResourceArgs = {
  input: BookableResourcePostInput;
};


/** Mutations that are supported by the API. */
export type MutationIndexBuildingInfoPostArgs = {
  input: BuildingInfoPostInput;
};


/** Mutations that are supported by the API. */
export type MutationIndexDealProductArgs = {
  input: IndexDealProductInput;
};


/** Mutations that are supported by the API. */
export type MutationIndexDealVendorArgs = {
  input: IndexDealVendorInput;
};


/** Mutations that are supported by the API. */
export type MutationIndexEventPostArgs = {
  input: EventPostInput;
};


/** Mutations that are supported by the API. */
export type MutationIndexFeedbackArgs = {
  input: FeedbackInput;
};


/** Mutations that are supported by the API. */
export type MutationIndexNewsPostArgs = {
  input: NewsPostInput;
};


/** Mutations that are supported by the API. */
export type MutationIndexPlainProductArgs = {
  input: IndexPlainProductInput;
};


/** Mutations that are supported by the API. */
export type MutationIndexPollArgs = {
  input: PollInput;
};


/** Mutations that are supported by the API. */
export type MutationIndexQuickLinkArgs = {
  input: QuickLinkInput;
};


/** Mutations that are supported by the API. */
export type MutationIndexSubscriptionProductArgs = {
  input: IndexSubscriptionProductInput;
};


/** Mutations that are supported by the API. */
export type MutationIndexUserArgs = {
  include?: InputMaybe<Array<UserIndexFieldGroups>>;
  input: UserInput;
};


/** Mutations that are supported by the API. */
export type MutationIndexUsersArgs = {
  include?: InputMaybe<Array<UserIndexFieldGroups>>;
  input: UsersInput;
};


/** Mutations that are supported by the API. */
export type MutationIndexVendorArgs = {
  input: IndexVendorInput;
};


/** Mutations that are supported by the API. */
export type MutationInitiateSegmentReSyncArgs = {
  siteUuids: Array<Scalars['UUID']['input']>;
};


/** Mutations that are supported by the API. */
export type MutationInitiateSyndicateSyncArgs = {
  siteUuids: Array<Scalars['UUID']['input']>;
};


/** Mutations that are supported by the API. */
export type MutationInviteUserArgs = {
  destinationUuid: Scalars['UUID']['input'];
  fromName?: InputMaybe<Scalars['String']['input']>;
  user: InviteUserInput;
};


/** Mutations that are supported by the API. */
export type MutationInviteUsersArgs = {
  destinationUuid: Scalars['UUID']['input'];
  emails: Array<Scalars['String']['input']>;
  fromName?: InputMaybe<Scalars['String']['input']>;
};


/** Mutations that are supported by the API. */
export type MutationInviteUsersV3Args = {
  destinationUuid: Scalars['UUID']['input'];
  fromFirstName?: InputMaybe<Scalars['String']['input']>;
  fromLastName?: InputMaybe<Scalars['String']['input']>;
  users: Array<InviteUserV3Input>;
};


/** Mutations that are supported by the API. */
export type MutationKeepChatHistoryAliveArgs = {
  threadId: Scalars['ID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationLikeArgs = {
  contentType: ContentType;
  contentUuid: Scalars['UUID']['input'];
  siteUuid?: InputMaybe<Scalars['UUID']['input']>;
  userUuid?: InputMaybe<Scalars['UUID']['input']>;
};


/** Mutations that are supported by the API. */
export type MutationLikeMetricArgs = {
  input: LikeMetricInput;
};


/** Mutations that are supported by the API. */
export type MutationLogInviteActionArgs = {
  action: InviteHistoryAction;
  email: Scalars['String']['input'];
  triggeredBy: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationLoginArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  realm?: InputMaybe<Scalars['String']['input']>;
  scopes?: InputMaybe<Array<Scalars['String']['input']>>;
};


/** Mutations that are supported by the API. */
export type MutationLoginToBuildingEnginesArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


/** Mutations that are supported by the API. */
export type MutationMakeBookingAdjustmentArgs = {
  input: BookingAdjustmentInput;
};


/** Mutations that are supported by the API. */
export type MutationMarkAdminNotificationAsReadArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationMergeCompaniesArgs = {
  masterUuid: Scalars['UUID']['input'];
  mergeUuids: Array<Scalars['UUID']['input']>;
};


/** Mutations that are supported by the API. */
export type MutationMetricArgs = {
  input: MetricInput;
};


/** Mutations that are supported by the API. */
export type MutationMetricV2Args = {
  input: MetricInputV2;
};


/** Mutations that are supported by the API. */
export type MutationModifyLeaseArgs = {
  input: ModifyLeaseInput;
};


/** Mutations that are supported by the API. */
export type MutationNotifyPropertyManagersArgs = {
  input: PropertyManagerAdminNotificationInput;
};


/** Mutations that are supported by the API. */
export type MutationOccurrenceUpsertArgs = {
  active: Scalars['Boolean']['input'];
  attendeeLimit?: InputMaybe<Scalars['Int']['input']>;
  eventUuid: Scalars['String']['input'];
  hasRsvpTerms?: InputMaybe<Scalars['Boolean']['input']>;
  isCheckInEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isRsvpEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  occurrenceUuids?: InputMaybe<Array<Scalars['String']['input']>>;
  occurrences?: InputMaybe<Array<OccurrenceInput>>;
  portalUuid: Scalars['String']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};


/** Mutations that are supported by the API. */
export type MutationOtpChallengeRespondArgs = {
  input: OtpChallengeRespondInput;
};


/** Mutations that are supported by the API. */
export type MutationPersistTemporaryUploadArgs = {
  input: PersistTemporaryUploadInput;
};


/** Mutations that are supported by the API. */
export type MutationProcessUserCompanyLoginArgs = {
  destinationUuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationProcessUserLoginAccessArgs = {
  destinationUuid: Scalars['UUID']['input'];
  userUuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationPublishCmsArticleArgs = {
  input: PublishCmsArticleInput;
};


/** Mutations that are supported by the API. */
export type MutationPublishSnsTestMessageArgs = {
  input: SnsTestMessageInput;
};


/** Mutations that are supported by the API. */
export type MutationRateWorkRequestArgs = {
  input: RateWorkRequestInput;
};


/** Mutations that are supported by the API. */
export type MutationRefreshHidOrigoCredentialArgs = {
  input: RefreshCredentialInput;
};


/** Mutations that are supported by the API. */
export type MutationRefreshLoginArgs = {
  refreshToken: Scalars['String']['input'];
  scopes?: InputMaybe<Array<Scalars['String']['input']>>;
};


/** Mutations that are supported by the API. */
export type MutationRefundBookingItemsArgs = {
  input: RefundBookingItemInput;
};


/** Mutations that are supported by the API. */
export type MutationRegisterDeviceArgs = {
  device: OwnDeviceInput;
};


/** Mutations that are supported by the API. */
export type MutationRegisterExistingUserArgs = {
  input: ExistingUserRegistrationInput;
};


/** Mutations that are supported by the API. */
export type MutationRegisterUserArgs = {
  input: PublicUserRegistrationInput;
};


/** Mutations that are supported by the API. */
export type MutationRegisterUserV2Args = {
  input: PublicUserRegistrationV2Input;
  medium?: InputMaybe<Scalars['String']['input']>;
};


/** Mutations that are supported by the API. */
export type MutationRegisterVendorArgs = {
  input: PublicVendorRegistrationInput;
};


/** Mutations that are supported by the API. */
export type MutationRemoveAc1CompanySiteConfigArgs = {
  input: RemoveCompanyConfigInput;
};


/** Mutations that are supported by the API. */
export type MutationRemoveAc1CredentialSetupArgs = {
  input: RemoveCredentialSetupInput;
};


/** Mutations that are supported by the API. */
export type MutationRemoveAc1UserArgs = {
  input: RemoveEmployeeInput;
};


/** Mutations that are supported by the API. */
export type MutationRemoveAc1VisitorArgs = {
  input: RemoveVisitorInput;
};


/** Mutations that are supported by the API. */
export type MutationRemoveProfileFromFlexTenantArgs = {
  flexTenantMembershipUuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationRemoveRoleFromUserArgs = {
  input: RemoveRoleFromUserInput;
};


/** Mutations that are supported by the API. */
export type MutationRemoveRolesFromUserArgs = {
  input: Array<RemoveRoleFromUserInput>;
};


/** Mutations that are supported by the API. */
export type MutationRemoveSiteManagerArgs = {
  destinationUuid: Scalars['UUID']['input'];
  userUuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationReportCommentArgs = {
  commentUuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationResendAppointmentCodeEmailArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  updateRecord?: InputMaybe<Scalars['Boolean']['input']>;
  visitorUuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationResendBookingAccessCodeArgs = {
  bookingUuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationResendInviteArgs = {
  fromFirstName: Scalars['String']['input'];
  fromLastName: Scalars['String']['input'];
  inviteUuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationRestoreArchivedCmsArticleArgs = {
  input: RestoreArchivedCmsArticleInput;
};


/** Mutations that are supported by the API. */
export type MutationRetryEmailVerificationArgs = {
  token: Scalars['String']['input'];
};


/** Mutations that are supported by the API. */
export type MutationRetrySubscriptionChargeArgs = {
  paymentGatewayCustomerId: Scalars['String']['input'];
  paymentGatewayPaymentMethodId: Scalars['String']['input'];
  subscriptionUuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationRevertDraftCmsArticleArgs = {
  input: RevertDraftCmsArticleInput;
};


/** Mutations that are supported by the API. */
export type MutationRevokeRolesFromUserArgs = {
  tuples: Array<UserRelationTupleInput>;
};


/** Mutations that are supported by the API. */
export type MutationRewriteLeaseArgs = {
  input: RewriteLeaseInput;
};


/** Mutations that are supported by the API. */
export type MutationRsvpMetricArgs = {
  input: RsvpMetricInput;
};


/** Mutations that are supported by the API. */
export type MutationSaveAvatarArgs = {
  avatar: Scalars['String']['input'];
  profileUuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationSaveCompanyArgs = {
  company: CompanyInput;
};


/** Mutations that are supported by the API. */
export type MutationSaveEquiemOneShortcutsArgs = {
  input: EquiemOneShortcutInput;
};


/** Mutations that are supported by the API. */
export type MutationSaveFilterItemArgs = {
  input: SegmentationFilterItemInput;
};


/** Mutations that are supported by the API. */
export type MutationSaveFiltersForApartmentArgs = {
  input: SaveFiltersForApartmentInput;
};


/** Mutations that are supported by the API. */
export type MutationSaveFiltersForCompanyArgs = {
  input: SaveFiltersForCompanyInput;
};


/** Mutations that are supported by the API. */
export type MutationSaveFiltersForPermissionArgs = {
  input: Array<SaveFiltersForPermissionInput>;
};


/** Mutations that are supported by the API. */
export type MutationSaveOwnAvatarArgs = {
  avatar: Scalars['String']['input'];
};


/** Mutations that are supported by the API. */
export type MutationSaveOwnCardArgs = {
  card: OwnCardInput;
};


/** Mutations that are supported by the API. */
export type MutationSaveOwnProfileArgs = {
  profile: OwnProfileInput;
};


/** Mutations that are supported by the API. */
export type MutationSaveOwnProfileV2Args = {
  profile: OwnProfileInputV2;
};


/** Mutations that are supported by the API. */
export type MutationSaveProfileArgs = {
  profile: ProfileInput;
  skipEmailValidation?: InputMaybe<Scalars['Boolean']['input']>;
  triggeredBy?: InputMaybe<Scalars['UUID']['input']>;
  validate?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Mutations that are supported by the API. */
export type MutationSaveProfileForAllSitesArgs = {
  profile: AllSitesProfileInput;
  skipEmailValidation?: InputMaybe<Scalars['Boolean']['input']>;
  triggeredBy?: InputMaybe<Scalars['UUID']['input']>;
  validate?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Mutations that are supported by the API. */
export type MutationSaveProfileForAllSitesV2Args = {
  profile: AllSitesProfileInputV2;
  skipEmailValidation?: InputMaybe<Scalars['Boolean']['input']>;
  triggeredBy?: InputMaybe<Scalars['UUID']['input']>;
  validate?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Mutations that are supported by the API. */
export type MutationSaveProfileFromAuthArgs = {
  profile: AuthProfileInput;
  triggeredBy?: InputMaybe<Scalars['UUID']['input']>;
  userUuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationSaveProfileV2Args = {
  profile: ProfileInputV2;
  skipEmailValidation?: InputMaybe<Scalars['Boolean']['input']>;
  triggeredBy?: InputMaybe<Scalars['UUID']['input']>;
  validate?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Mutations that are supported by the API. */
export type MutationSaveSegmentV2Args = {
  input: SaveSegmentV2Input;
};


/** Mutations that are supported by the API. */
export type MutationSaveUserAuthProfileArgs = {
  profile: AuthProfileInput;
  triggeredBy?: InputMaybe<Scalars['UUID']['input']>;
  userUuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationSendAiChatFeedbackArgs = {
  isPositive: Scalars['Boolean']['input'];
  question: Scalars['String']['input'];
  response: Scalars['String']['input'];
  threadId?: InputMaybe<Scalars['ID']['input']>;
};


/** Mutations that are supported by the API. */
export type MutationSendAiChatMessageArgs = {
  lastMessageId?: InputMaybe<Scalars['String']['input']>;
  message: Scalars['String']['input'];
  threadId?: InputMaybe<Scalars['ID']['input']>;
};


/** Mutations that are supported by the API. */
export type MutationSendAiLlamaChatMessageArgs = {
  lastMessageId?: InputMaybe<Scalars['String']['input']>;
  message: Scalars['String']['input'];
  threadId?: InputMaybe<Scalars['ID']['input']>;
};


/** Mutations that are supported by the API. */
export type MutationSendBarrierControlMessagesArgs = {
  barrierControlAccessUuid?: InputMaybe<Scalars['String']['input']>;
  buildingUuids: Array<Scalars['UUID']['input']>;
  messages: Array<BarrierControlMessageInput>;
};


/** Mutations that are supported by the API. */
export type MutationSendBatchPushNotificationArgs = {
  input: SendBatchPushNotificationInput;
};


/** Mutations that are supported by the API. */
export type MutationSendBookingStatsProviderMessagesArgs = {
  messages: Array<StatsProviderBookingMessageInput>;
  siteUuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationSendPushNotificationArgs = {
  input: SendPushNotificationInput;
};


/** Mutations that are supported by the API. */
export type MutationSendRegistrationCompletionEmailArgs = {
  destinationUuid: Scalars['UUID']['input'];
  userUuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationSendRequestStatsProviderMessagesArgs = {
  messages: Array<StatsProviderRequestMessageInput>;
  siteUuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationSendResetPasswordEmailArgs = {
  email: Scalars['String']['input'];
  siteUuid?: InputMaybe<Scalars['String']['input']>;
  triggeredBy?: InputMaybe<Scalars['String']['input']>;
};


/** Mutations that are supported by the API. */
export type MutationSendSmsArgs = {
  input: SendSmsInput;
};


/** Mutations that are supported by the API. */
export type MutationSendSitePushNotificationArgs = {
  input: SendSitePushNotificationInput;
};


/** Mutations that are supported by the API. */
export type MutationSendSupportTicketEmailArgs = {
  attachments?: InputMaybe<Array<Scalars['URI']['input']>>;
  message: Scalars['String']['input'];
  subject: Scalars['String']['input'];
};


/** Mutations that are supported by the API. */
export type MutationSendSupportTicketEmailV2Args = {
  attachments?: InputMaybe<Array<Scalars['URI']['input']>>;
  message: Scalars['String']['input'];
  siteUuid: Scalars['UUID']['input'];
  subject: Scalars['String']['input'];
};


/** Mutations that are supported by the API. */
export type MutationSendVisitorStatsProviderMessagesArgs = {
  messages: Array<StatsProviderVisitorMessageInput>;
  siteUuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationSetAppointmentOrganizerRoleArgs = {
  company?: InputMaybe<Scalars['UUID']['input']>;
  input: Array<SetRoleInput>;
};


/** Mutations that are supported by the API. */
export type MutationSetCardIdArgs = {
  cardId: Scalars['String']['input'];
  visitorUuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationSetCardStatusArgs = {
  returned: Scalars['Boolean']['input'];
  visitorUuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationSetDefaultCardArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationSetEmailVerifiedArgs = {
  email: Scalars['String']['input'];
};


/** Mutations that are supported by the API. */
export type MutationSetOnSiteStatusArgs = {
  status: OnSiteStatus;
};


/** Mutations that are supported by the API. */
export type MutationSetSiteGroupCurationStatusArgs = {
  isCuratedForEquiemOne: Scalars['Boolean']['input'];
  uuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationSetSitesForCmsArticleArgs = {
  input: SetSitesForCmsArticleInput;
};


/** Mutations that are supported by the API. */
export type MutationSetSubscriptionPaymentMethodArgs = {
  paymentGatewayCustomerId: Scalars['String']['input'];
  paymentGatewayPaymentMethodId: Scalars['String']['input'];
  subscriptionUuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationSetUserUbiparkEnabledArgs = {
  enabled: Scalars['Boolean']['input'];
};


/** Mutations that are supported by the API. */
export type MutationSmsChallengeArgs = {
  input: SmsChallengeInput;
};


/** Mutations that are supported by the API. */
export type MutationSmsChallengeRespondArgs = {
  input: SmsChallengeRespondInput;
};


/** Mutations that are supported by the API. */
export type MutationSyncAc1UserArgs = {
  input: SyncEmployeeInput;
};


/** Mutations that are supported by the API. */
export type MutationSyncAc1VisitorArgs = {
  input: SyncVisitorInput;
};


/** Mutations that are supported by the API. */
export type MutationSyncExternalBookingsArgs = {
  fetchFromExternal?: InputMaybe<Scalars['Boolean']['input']>;
  resourceUuid: Scalars['UUID']['input'];
  sendToExternal?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Mutations that are supported by the API. */
export type MutationSyncUserProfileArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationSyncUserToEvolutionArgs = {
  destinationUuid: Scalars['UUID']['input'];
  userUuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationTerminateLeaseArgs = {
  input: TerminateLeaseInput;
};


/** Mutations that are supported by the API. */
export type MutationToggleAndSyncEvolutionUserArgs = {
  destinationUuid: Scalars['UUID']['input'];
  enabled: Scalars['Boolean']['input'];
  userUuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationToggleBookingPaymentAdjustmentArgs = {
  enable: Scalars['Boolean']['input'];
};


/** Mutations that are supported by the API. */
export type MutationToggleEvolutionForUserArgs = {
  enabled: Scalars['Boolean']['input'];
  userUuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationTriggerDeactivationFlowForSiteArgs = {
  input: TriggerDeactivationFlowForSiteInput;
};


/** Mutations that are supported by the API. */
export type MutationTriggerDeactivationFlowForSiteV2Args = {
  input: TriggerDeactivationFlowForSiteInputV2;
};


/** Mutations that are supported by the API. */
export type MutationUbiparkCheckoutArgs = {
  laneId: Scalars['Int']['input'];
  paymentGatewayCardId: Scalars['String']['input'];
};


/** Mutations that are supported by the API. */
export type MutationUnlikeArgs = {
  contentUuid: Scalars['UUID']['input'];
  siteUuid?: InputMaybe<Scalars['UUID']['input']>;
  userUuid?: InputMaybe<Scalars['UUID']['input']>;
};


/** Mutations that are supported by the API. */
export type MutationUnpublishArticleArgs = {
  siteArticleUuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationUnpublishBookableProductArgs = {
  id: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationUnpublishBookableResourceArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationUnpublishBuildingInfoPostArgs = {
  portalPostUuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationUnpublishCmsArticleArgs = {
  input: UnpublishCmsArticleInput;
};


/** Mutations that are supported by the API. */
export type MutationUnpublishDealProductArgs = {
  id: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationUnpublishDealVendorArgs = {
  id: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationUnpublishEventPostArgs = {
  occurrenceUuid: Scalars['UUID']['input'];
  portalPostUuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationUnpublishFeedbackArgs = {
  portalPostUuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationUnpublishNewsPostArgs = {
  portalPostUuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationUnpublishPlainProductArgs = {
  id: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationUnpublishPollArgs = {
  portalPostUuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationUnpublishQuickLinkArgs = {
  portalPostUuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationUnpublishSubscriptionProductArgs = {
  id: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationUnpublishVendorArgs = {
  id: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationUnsubscribeFromSiteEmailsArgs = {
  siteToken: Scalars['String']['input'];
  userToken: Scalars['String']['input'];
};


/** Mutations that are supported by the API. */
export type MutationUpdateAc1IntegrationArgs = {
  input: UpdateIntegrationInput;
};


/** Mutations that are supported by the API. */
export type MutationUpdateApprovalNotificationsPreferencesArgs = {
  input: ApprovalNotificationsPreferencesInput;
  profileUuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationUpdateAttendeeLimitForSessionsArgs = {
  input: Array<UpdateAttendeeLimitForSessionsInput>;
};


/** Mutations that are supported by the API. */
export type MutationUpdateAuthProfileArgs = {
  input: AuthProfileInput;
  triggeredBy?: InputMaybe<Scalars['UUID']['input']>;
  userUuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationUpdateBarrierControlConfigArgs = {
  input: BarrierControlConfigInput;
  uuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationUpdateBookableResourceArgs = {
  input: UpdateBookableResourceInput;
  uuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationUpdateBookingNotificationPreferencesArgs = {
  input: BookingNotificationsPreferencesInput;
  profileUuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationUpdateBookingPaymentAdjustmentMaxDaysArgs = {
  days: Scalars['Int']['input'];
};


/** Mutations that are supported by the API. */
export type MutationUpdateBuildingIntegrationsArgs = {
  input: BuildingIntegrationsInput;
};


/** Mutations that are supported by the API. */
export type MutationUpdateBuildingLevelArgs = {
  input: UpdateBuildingLevelInput;
};


/** Mutations that are supported by the API. */
export type MutationUpdateBuildingLevelOrderArgs = {
  uuids: Array<Scalars['UUID']['input']>;
};


/** Mutations that are supported by the API. */
export type MutationUpdateBuildingOrderArgs = {
  uuids: Array<Scalars['UUID']['input']>;
};


/** Mutations that are supported by the API. */
export type MutationUpdateCmsArticleArgs = {
  input: UpdateCmsArticleInput;
};


/** Mutations that are supported by the API. */
export type MutationUpdateClientRegionArgs = {
  region: ClientRegion;
  uuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationUpdateCompanyBookingSettingsArgs = {
  input: CompanyBookingSettingsInput;
};


/** Mutations that are supported by the API. */
export type MutationUpdateCompanyDestinationIntegrationsArgs = {
  input: CompanyDestinationIntegrationsInput;
};


/** Mutations that are supported by the API. */
export type MutationUpdateCompanySizeArgs = {
  input: UpdateCompanySizeInput;
};


/** Mutations that are supported by the API. */
export type MutationUpdateCompanyV2IntegrationsArgs = {
  input: CompanyV2IntegrationInput;
};


/** Mutations that are supported by the API. */
export type MutationUpdateConsoleAccessArgs = {
  accessGranted: Scalars['Boolean']['input'];
  triggeredBy: Scalars['UUID']['input'];
  userUuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationUpdateDestinationIntegrationsArgs = {
  input: DestinationIntegrationInput;
};


/** Mutations that are supported by the API. */
export type MutationUpdateDestinationLegalArgs = {
  input: UpdateDestinationLegalInput;
};


/** Mutations that are supported by the API. */
export type MutationUpdateDestinationTypeArgs = {
  type: DestinationType;
  uuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationUpdateFlexTenantArgs = {
  input: UpdateFlexTenantInput;
};


/** Mutations that are supported by the API. */
export type MutationUpdateFlexTenantDefaultWorkplaceManagersArgs = {
  input: UpdateFlexTenantDefaultWorkplaceManagersInput;
};


/** Mutations that are supported by the API. */
export type MutationUpdateFlexTenantLocationsArgs = {
  input: UpdateFlexTenantLocationsInput;
};


/** Mutations that are supported by the API. */
export type MutationUpdateLevelArgs = {
  input: UpdateLevelInput;
};


/** Mutations that are supported by the API. */
export type MutationUpdateOccurrencesFromSessionsArgs = {
  input: Array<UpdateOccurrencesFromSessionsInput>;
};


/** Mutations that are supported by the API. */
export type MutationUpdateOperationsCompanyArgs = {
  input: OperationsCompanyInput;
};


/** Mutations that are supported by the API. */
export type MutationUpdateOwnLocalePreferenceArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** Mutations that are supported by the API. */
export type MutationUpdatePartialProfileArgs = {
  input: PartialProfileInput;
};


/** Mutations that are supported by the API. */
export type MutationUpdateProfileActiveArgs = {
  active: Scalars['Boolean']['input'];
  triggeredBy?: InputMaybe<Scalars['UUID']['input']>;
  uuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationUpdateProfileFlexTenantsArgs = {
  input: UpdateProfileFlexTenantsInput;
};


/** Mutations that are supported by the API. */
export type MutationUpdateReceptionistNoteArgs = {
  input: ReceptionistNoteInput;
};


/** Mutations that are supported by the API. */
export type MutationUpdateResourceBookingArgs = {
  input: UpdateBookingInput;
  uuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationUpdateResourceBookingApprovalArgs = {
  input: UpdateBookingApprovalInput;
  uuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationUpdateSpaceArgs = {
  input: UpdateSpaceInput;
};


/** Mutations that are supported by the API. */
export type MutationUpdateSpaceOrderArgs = {
  uuids: Array<Scalars['UUID']['input']>;
};


/** Mutations that are supported by the API. */
export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


/** Mutations that are supported by the API. */
export type MutationUpdateUserEvolutionIdArgs = {
  accountId: Scalars['Int']['input'];
  destinationUuid: Scalars['UUID']['input'];
  userUuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationUpdateUserStoreAdminArgs = {
  isAdmin: Scalars['Boolean']['input'];
  userUuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationUpdateUsersForDestinationArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  attributeUuidsToAdd?: InputMaybe<Array<Scalars['String']['input']>>;
  attributeUuidsToRemove?: InputMaybe<Array<Scalars['String']['input']>>;
  destinationUuid: Scalars['UUID']['input'];
  groupUuidsToAdd?: InputMaybe<Array<Scalars['UUID']['input']>>;
  groupUuidsToRemove?: InputMaybe<Array<Scalars['UUID']['input']>>;
  userUuids: Array<Scalars['String']['input']>;
};


/** Mutations that are supported by the API. */
export type MutationUpdateUsersStatusForDestinationArgs = {
  destinationUuid: Scalars['UUID']['input'];
  input: Array<UpdateUserStatusInput>;
};


/** Mutations that are supported by the API. */
export type MutationUpdateVisitorAppointmentArgs = {
  input: VisitorAppointmentInput;
  updateFollowingRecurringAppointments?: InputMaybe<Scalars['Boolean']['input']>;
  uuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationUpdateVisitorAppointmentForBookingArgs = {
  bookingUuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationUpdateVisitorBuildingArgs = {
  input: VisitorBuildingInput;
  uuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationUpdateVisitorReceptionArgs = {
  input: VisitorReceptionInput;
  uuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationUpsertAc1BuildingIntegrationArgs = {
  input: UpsertBuildingIntegrationInput;
};


/** Mutations that are supported by the API. */
export type MutationUpsertAc1CompanySiteConfigArgs = {
  input: UpsertCompanyConfigInput;
};


/** Mutations that are supported by the API. */
export type MutationUpsertAc1QrCodeCredentialSetupArgs = {
  input: UpsertQrCodeCredentialSetupInput;
};


/** Mutations that are supported by the API. */
export type MutationUpsertActivityStatusArgs = {
  activity: Scalars['String']['input'];
  activityTime: Scalars['DateTime']['input'];
  user: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationUpsertActivityStatusByEmailArgs = {
  activity: Scalars['String']['input'];
  activityTime: Scalars['DateTime']['input'];
  email: Scalars['Email']['input'];
};


/** Mutations that are supported by the API. */
export type MutationUpsertAttributeArgs = {
  name: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


/** Mutations that are supported by the API. */
export type MutationUpsertAttributesArgs = {
  input: Array<UpsertAttributeInput>;
};


/** Mutations that are supported by the API. */
export type MutationUpsertClientArgs = {
  name: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


/** Mutations that are supported by the API. */
export type MutationUpsertClientsArgs = {
  input: Array<UpsertClientInput>;
};


/** Mutations that are supported by the API. */
export type MutationUpsertCompanyAttributeArgs = {
  name: Scalars['String']['input'];
  siteUuid: Scalars['UUID']['input'];
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


/** Mutations that are supported by the API. */
export type MutationUpsertCompanyAttributesArgs = {
  input: Array<UpsertCompanyAttributeInput>;
};


/** Mutations that are supported by the API. */
export type MutationUpsertContentCollectionArgs = {
  input: ContentCollectionInput;
};


/** Mutations that are supported by the API. */
export type MutationUpsertCustomMxSettingsArgs = {
  destination: Scalars['UUID']['input'];
  mxSettings?: InputMaybe<Scalars['String']['input']>;
};


/** Mutations that are supported by the API. */
export type MutationUpsertDestinationArgs = {
  input: UpsertDestinationInput;
  triggeredBy?: InputMaybe<Scalars['UUID']['input']>;
};


/** Mutations that are supported by the API. */
export type MutationUpsertDestinationDomainsArgs = {
  accountDomain?: InputMaybe<Scalars['String']['input']>;
  destination: Scalars['UUID']['input'];
  referenceDomainIsPrimary?: InputMaybe<Scalars['Boolean']['input']>;
  webDomains: Array<WebDomainInput>;
};


/** Mutations that are supported by the API. */
export type MutationUpsertDestinationEmailSettingsArgs = {
  destination: Scalars['UUID']['input'];
  fromEmailAddress?: InputMaybe<Scalars['String']['input']>;
  replyToEmailAddress?: InputMaybe<Scalars['String']['input']>;
};


/** Mutations that are supported by the API. */
export type MutationUpsertDestinationTierLevelArgs = {
  destination: Scalars['UUID']['input'];
  tierLevel?: InputMaybe<DestinationTierLevel>;
};


/** Mutations that are supported by the API. */
export type MutationUpsertFeedbackQuestionResponseArgs = {
  input: FeedbackResponseInput;
};


/** Mutations that are supported by the API. */
export type MutationUpsertGroupArgs = {
  name: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


/** Mutations that are supported by the API. */
export type MutationUpsertGroupsArgs = {
  input: Array<UpsertGroupInput>;
};


/** Mutations that are supported by the API. */
export type MutationUpsertHomeUiWidgetArgs = {
  destinationUuid: Scalars['UUID']['input'];
  input: Array<UpsertHomeUiWidgetInput>;
  platform?: InputMaybe<PlatformType>;
};


/** Mutations that are supported by the API. */
export type MutationUpsertHomeUiWidgetV2Args = {
  destinationUuid: Scalars['UUID']['input'];
  input: UpsertHomeUiWidgetInputV2;
  platform: PlatformType;
};


/** Mutations that are supported by the API. */
export type MutationUpsertHomeUiWidgetsArgs = {
  destinationUuid: Scalars['UUID']['input'];
  input: Array<UpsertHomeUiWidgetInputV2>;
  platform: PlatformType;
};


/** Mutations that are supported by the API. */
export type MutationUpsertIndustriesArgs = {
  input: Array<UpsertIndustryInput>;
};


/** Mutations that are supported by the API. */
export type MutationUpsertIndustryArgs = {
  name: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


/** Mutations that are supported by the API. */
export type MutationUpsertOccurrenceFromTemplateArgs = {
  input: OccurrenceFromSessionTemplateInput;
};


/** Mutations that are supported by the API. */
export type MutationUpsertPropertyManagerMetricsArgs = {
  metrics: Array<PropertyManagerEquiemOneMetrics>;
};


/** Mutations that are supported by the API. */
export type MutationUpsertResourceBookingAdminNoteArgs = {
  adminNote?: InputMaybe<Scalars['String']['input']>;
  uuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationUpsertSiteAttributeArgs = {
  name: Scalars['String']['input'];
  siteUuid: Scalars['UUID']['input'];
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


/** Mutations that are supported by the API. */
export type MutationUpsertSiteAttributesArgs = {
  input: Array<UpsertSiteAttributeInput>;
};


/** Mutations that are supported by the API. */
export type MutationUpsertSiteGroupArgs = {
  groupUuid?: InputMaybe<Scalars['UUID']['input']>;
  name: Scalars['String']['input'];
  siteUuid: Scalars['UUID']['input'];
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


/** Mutations that are supported by the API. */
export type MutationUpsertSiteGroupsArgs = {
  input: Array<UpsertSiteGroupInput>;
};


/** Mutations that are supported by the API. */
export type MutationUpsertStatsProviderConfigurationArgs = {
  input: StatsProviderConfigurationInput;
};


/** Mutations that are supported by the API. */
export type MutationUpsertSyndicateClientArgs = {
  name: Scalars['String']['input'];
  region?: InputMaybe<ClientRegion>;
  triggeredBy?: InputMaybe<Scalars['UUID']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


/** Mutations that are supported by the API. */
export type MutationUpsertUserArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  destinationUuids?: InputMaybe<Array<Scalars['UUID']['input']>>;
  installation: Scalars['String']['input'];
  triggeredBy?: InputMaybe<Scalars['UUID']['input']>;
  userUuid: Scalars['UUID']['input'];
};


/** Mutations that are supported by the API. */
export type MutationUpsertVisitorManagementSiteSettingsArgs = {
  input: VisitorManagementSiteSettingsInput;
};


/** Mutations that are supported by the API. */
export type MutationUpsertWhitelabelAppArgs = {
  input: UpsertWhitelabelAppInput;
};


/** Mutations that are supported by the API. */
export type MutationUpsertWhitelabelAppDetailsArgs = {
  input: UpsertWhitelabelAppDetailsInput;
};


/** Mutations that are supported by the API. */
export type MutationVisitorAppointmentByAllBuildingsExportCsvArgs = {
  input: VisitorAppointmentExportCsvInput;
};


/** Mutations that are supported by the API. */
export type MutationVisitorAppointmentExportCsvArgs = {
  input: VisitorAppointmentExportCsvInput;
};


/** Mutations that are supported by the API. */
export type MutationWriteAuthRelationshipTuplesArgs = {
  authorizationModelId: Scalars['String']['input'];
  deletes?: InputMaybe<Array<AuthRelationshipTupleInput>>;
  options?: InputMaybe<WriteAuthRelationshipTuplesOptionsInput>;
  storeId: Scalars['String']['input'];
  writes?: InputMaybe<Array<AuthRelationshipTupleInput>>;
};

export type MutationFailure = {
  __typename?: 'MutationFailure';
  reason: Scalars['String']['output'];
};

/** This type exists because GraphQL doesn't support returning null/void. */
export type MutationResult = {
  __typename?: 'MutationResult';
  /**
   * If returned, this should always be true. The case where this is false the whole object should be null
   * with an error reported via GraphQL's normal error reporting mechanisms.
   */
  success: Scalars['Boolean']['output'];
};

export type MutationResultV2 = MutationFailure | MutationSuccess;

export type MutationResultV3 = {
  __typename?: 'MutationResultV3';
  /** Any error, warning or success messages. */
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type MutationSuccess = {
  __typename?: 'MutationSuccess';
  success: Scalars['Boolean']['output'];
};

export type NewsAndEventsConnection = {
  __typename?: 'NewsAndEventsConnection';
  edges: Array<NewsAndEventsEdge>;
  pageInfo: PageInfo;
};

export type NewsAndEventsEdge = EventOccurrenceEdge | NewsEdge;

export type NewsAndEventsSettings = {
  __typename?: 'NewsAndEventsSettings';
  enabled: Scalars['Boolean']['output'];
};

export type NewsConnection = {
  __typename?: 'NewsConnection';
  edges: Array<NewsEdge>;
  pageInfo: PageInfo;
};

export type NewsEdge = ContentEdge & {
  __typename?: 'NewsEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<NewsPost>;
};

export type NewsFilters = {
  featured: Scalars['Boolean']['input'];
};

export type NewsPost = {
  __typename?: 'NewsPost';
  author?: Maybe<Profile>;
  body: Scalars['String']['output'];
  categories: Array<Category>;
  commentCount: Scalars['Int']['output'];
  /** Comments for the current user's active site. */
  comments: CommentConnection;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  downloads: Array<File>;
  excerpt: Scalars['String']['output'];
  featured: Scalars['Boolean']['output'];
  featuredImage: Image;
  images: Array<Image>;
  likeCount: Scalars['Int']['output'];
  likedByMe: Scalars['Boolean']['output'];
  publishDate: Scalars['DateTime']['output'];
  relatedInfo: Array<Link>;
  tags?: Maybe<Array<Scalars['String']['output']>>;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid: Scalars['UUID']['output'];
  viewCount: Scalars['Int']['output'];
};


export type NewsPostCommentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type NewsPostViewCountArgs = {
  companyUuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type NewsPostInput = {
  author?: InputMaybe<Scalars['Email']['input']>;
  body: Scalars['String']['input'];
  categories: Array<Scalars['String']['input']>;
  contentUuid: Scalars['UUID']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  downloads?: InputMaybe<Array<FileInput>>;
  excerpt: Scalars['String']['input'];
  featured?: InputMaybe<Scalars['Boolean']['input']>;
  images: Array<FileInput>;
  portalPostUuid: Scalars['UUID']['input'];
  /**
   * Iris incorrectly sends this timezone scoped timestamp as if it is in UTC.
   * Eg. 10:00:00AM Australia/Melbourne time is sent as 10:00:00AM UTC.
   * publishDateInUtc will contain the correct utc timestamp.
   */
  publishDate: Scalars['DateTime']['input'];
  publishDateInUtc: Scalars['DateTime']['input'];
  published: Scalars['Boolean']['input'];
  relatedInformation?: InputMaybe<Array<LinkInput>>;
  segmentIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  shareableLinkId?: InputMaybe<Scalars['String']['input']>;
  site: Scalars['UUID']['input'];
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Node = {
  uuid: Scalars['UUID']['output'];
};

export type NullDiscountCode = {
  __typename?: 'NullDiscountCode';
  reason: Scalars['String']['output'];
};

/** Event occurrence type. */
export type Occurrence = {
  __typename?: 'Occurrence';
  active: Scalars['Boolean']['output'];
  attendeeLimit?: Maybe<Scalars['Int']['output']>;
  /** This will only be set for session occurrences */
  bookableProductUuid?: Maybe<Scalars['UUID']['output']>;
  colors?: Maybe<Array<Scalars['String']['output']>>;
  commentCount: Scalars['Int']['output'];
  /** Comments for the current user's active site. */
  comments: CommentConnection;
  drupalUuid?: Maybe<Scalars['UUID']['output']>;
  endDate: Scalars['DateTime']['output'];
  eventPost?: Maybe<EventPost>;
  /** @deprecated this can now be event uuid or session template uuid. Use type uuid */
  eventUuid: Scalars['String']['output'];
  hasCapacity: Scalars['Boolean']['output'];
  hasCapacityForAttendees: Scalars['Boolean']['output'];
  hasRsvpTerms: Scalars['Boolean']['output'];
  hasUserAcceptedTermsAndConditions: Scalars['Boolean']['output'];
  hasUserRsvped: Scalars['Boolean']['output'];
  isCheckInEnabled: Scalars['Boolean']['output'];
  isRsvpEnabled: Scalars['Boolean']['output'];
  likeCount: Scalars['Int']['output'];
  likedByMe: Scalars['Boolean']['output'];
  location?: Maybe<Scalars['String']['output']>;
  pastEvent: Scalars['Boolean']['output'];
  portalUuids: Array<Scalars['String']['output']>;
  rsvpCount: Scalars['Int']['output'];
  rsvpCountFromMetrics: Scalars['Int']['output'];
  rsvpedByMe: Scalars['Boolean']['output'];
  rsvps: UserRsvpConnection;
  startDate: Scalars['DateTime']['output'];
  timezone: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type: OccurrenceType;
  typeUuid: Scalars['UUID']['output'];
  uuid: Scalars['UUID']['output'];
};


/** Event occurrence type. */
export type OccurrenceCommentCountArgs = {
  destinationUuid?: InputMaybe<Scalars['UUID']['input']>;
};


/** Event occurrence type. */
export type OccurrenceCommentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


/** Event occurrence type. */
export type OccurrenceDrupalUuidArgs = {
  destinationUuid: Scalars['UUID']['input'];
};


/** Event occurrence type. */
export type OccurrenceHasCapacityForAttendeesArgs = {
  attendees: Scalars['Int']['input'];
};


/** Event occurrence type. */
export type OccurrenceHasUserAcceptedTermsAndConditionsArgs = {
  userUuid: Scalars['UUID']['input'];
};


/** Event occurrence type. */
export type OccurrenceHasUserRsvpedArgs = {
  userUuid: Scalars['UUID']['input'];
};


/** Event occurrence type. */
export type OccurrenceLikeCountArgs = {
  destinationUuid?: InputMaybe<Scalars['UUID']['input']>;
};


/** Event occurrence type. */
export type OccurrenceRsvpCountFromMetricsArgs = {
  companyUuid?: InputMaybe<Scalars['UUID']['input']>;
};


/** Event occurrence type. */
export type OccurrenceRsvpsArgs = {
  activeOnly?: InputMaybe<Scalars['Boolean']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type OccurrenceAndSessionConnection = {
  __typename?: 'OccurrenceAndSessionConnection';
  edges: Array<Maybe<OccurrenceAndSessionsEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type OccurrenceAndSessionsEdge = {
  __typename?: 'OccurrenceAndSessionsEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<OccurrenceSessionUnion>;
};

export type OccurrenceConnection = {
  __typename?: 'OccurrenceConnection';
  edges: Array<Maybe<OccurrenceEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type OccurrenceEdge = {
  __typename?: 'OccurrenceEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Occurrence>;
};

export type OccurrenceFilter = {
  checkInEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  portalUuid: Scalars['String']['input'];
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type OccurrenceFromSessionTemplateInput = {
  bookableProductUuid: Scalars['UUID']['input'];
  capacity?: InputMaybe<Scalars['Int']['input']>;
  end: Scalars['DateTime']['input'];
  hasRsvpTerms?: InputMaybe<Scalars['Boolean']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  sessionTemplateUuid: Scalars['UUID']['input'];
  siteUuids: Array<Scalars['String']['input']>;
  start: Scalars['DateTime']['input'];
  timezone: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

/** Occurrence Input type */
export type OccurrenceInput = {
  endDate: Scalars['DateTime']['input'];
  startDate: Scalars['DateTime']['input'];
  timezone: Scalars['String']['input'];
  uuid: Scalars['UUID']['input'];
};

export type OccurrenceSessionUnion = Occurrence | SessionV2;

export type OccurrenceType =
  | 'EVENT'
  | 'SESSION';

export type OnSiteStatus =
  | 'OFF'
  | 'ON';

export type OpeningType =
  | 'tap'
  | 'twistAndGo';

export type OpenpathAuthenticationFailure = {
  __typename?: 'OpenpathAuthenticationFailure';
  code?: Maybe<Scalars['String']['output']>;
  reason: Scalars['String']['output'];
};

export type OpenpathAuthenticationResult = OpenpathAuthenticationFailure | OpenpathAuthenticationSuccess;

export type OpenpathAuthenticationSuccess = {
  __typename?: 'OpenpathAuthenticationSuccess';
  info: OpenpathDestinationInfo;
};

export type OpenpathDestinationInfo = {
  __typename?: 'OpenpathDestinationInfo';
  connectedTimestamp: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  label: Scalars['String']['output'];
  mobileCredentialTypeId: Scalars['Int']['output'];
  orgId: Scalars['Int']['output'];
};

export type OpenpathDestinationIntegration = {
  __typename?: 'OpenpathDestinationIntegration';
  enabled: Scalars['Boolean']['output'];
  info?: Maybe<OpenpathDestinationInfo>;
};

export type OpenpathMutation = {
  __typename?: 'OpenpathMutation';
  connect: OpenpathAuthenticationResult;
  disconnect: IntegrationServiceResult;
  /**
   * Update field associated with an existing connection, without overwriting the entire connection.
   *
   * Email and password cannot be updated and would require first disconnecting and then reconnecting with the new email and password.
   */
  patch: OpenpathAuthenticationResult;
  userOptIn: OpenpathUserOptInResult;
};


export type OpenpathMutationConnectArgs = {
  email: Scalars['String']['input'];
  label?: InputMaybe<Scalars['String']['input']>;
  orgId: Scalars['Int']['input'];
  password: Scalars['String']['input'];
  site: Scalars['UUID']['input'];
};


export type OpenpathMutationDisconnectArgs = {
  site: Scalars['UUID']['input'];
};


export type OpenpathMutationPatchArgs = {
  label?: InputMaybe<Scalars['String']['input']>;
  site: Scalars['UUID']['input'];
};


export type OpenpathMutationUserOptInArgs = {
  mobileCredentialName: Scalars['String']['input'];
};

export type OpenpathUser = {
  __typename?: 'OpenpathUser';
  opal: Scalars['String']['output'];
  userId: Scalars['Int']['output'];
};

export type OpenpathUserOptInFailure = {
  __typename?: 'OpenpathUserOptInFailure';
  localisedReason: LocalisedMessage;
  /** @deprecated use localisedReason instead */
  reason: Scalars['String']['output'];
};

export type OpenpathUserOptInResult = OpenpathUserOptInFailure | OpenpathUserOptInSuccess;

export type OpenpathUserOptInSuccess = {
  __typename?: 'OpenpathUserOptInSuccess';
  setupMobileToken: Scalars['String']['output'];
  user: OpenpathUser;
};

export type OperationsCompany = {
  __typename?: 'OperationsCompany';
  company?: Maybe<CompanyV2>;
  everyoneCanCreateAppointments: Scalars['Boolean']['output'];
  maxAppointmentCreationMonths?: Maybe<Scalars['Int']['output']>;
  users: Array<OperationsCompanyUserEdge>;
  uuid: Scalars['UUID']['output'];
};


export type OperationsCompanyUsersArgs = {
  roles?: InputMaybe<Array<OperationsRole>>;
};

export type OperationsCompanyInput = {
  everyoneCanCreateAppointments: Scalars['Boolean']['input'];
  maxAppointmentCreationMonths?: InputMaybe<Scalars['Int']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type OperationsCompanyUserEdge = {
  __typename?: 'OperationsCompanyUserEdge';
  profile?: Maybe<Profile>;
  roles: Array<OperationsRole>;
};

export type OperationsRole =
  | 'APPOINTMENT_ORGANIZER'
  | 'AUTHENTICATED_NO_PERMISSION'
  | 'BUILDING_RECEPTIONIST'
  | 'PROPERTY_MANAGER'
  | 'SERVICE'
  | 'TENANT_RECEPTIONIST'
  | 'WORKPLACE_MANAGER';

export type Order = Node & {
  __typename?: 'Order';
  /** The mutations which the current user can perform on this order. */
  allowedActions: AllowedOrderActions;
  /** The name of the customer's company */
  companyName: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  currencyCode: Scalars['String']['output'];
  customer?: Maybe<Profile>;
  /** Additional comments the customer may have for an order */
  customerComments?: Maybe<Scalars['String']['output']>;
  /** The location of the customer (eg. 1st desk level 4) for DELIVERY type orders */
  customerLocation?: Maybe<Scalars['String']['output']>;
  /** The name of the customer */
  customerName?: Maybe<Scalars['String']['output']>;
  customerUuid: Scalars['UUID']['output'];
  delay?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Please use customerLocation field instead */
  deliveryAddress?: Maybe<Scalars['String']['output']>;
  deliveryMethod?: Maybe<CartDeliveryMethod>;
  discount?: Maybe<Discount>;
  discountTotal: Scalars['Int']['output'];
  eta?: Maybe<Scalars['DateTime']['output']>;
  exTaxDiscountTotal: Scalars['Int']['output'];
  exTaxTotal: Scalars['Int']['output'];
  /** Short description of where the customer will receive their order */
  fulfilmentLocation: Scalars['String']['output'];
  hasBookableProduct: Scalars['Boolean']['output'];
  /** Default image for the order */
  image?: Maybe<Scalars['URI']['output']>;
  incTaxDiscountTotal: Scalars['Int']['output'];
  incTaxTotal: Scalars['Int']['output'];
  lineItems: Array<LineItem>;
  /** Fields for which values will be provided while processing this order. */
  outputFields: Array<ProductOutputFieldValue>;
  portal: Portal;
  preDiscountTotal: Scalars['Int']['output'];
  /** Profile fields which the customer provided when placing this order. */
  profileFields: Array<ProfileFieldValue>;
  referenceId: Scalars['String']['output'];
  status: OrderStatus;
  steps: Array<Maybe<OrderSteps>>;
  /** An optional subscription associated with this order. */
  subscription?: Maybe<ProductSubscription>;
  tax: Scalars['Int']['output'];
  /**
   * If there are multiple products with different turn around times,
   * highest time will be returned. In case none of the products have turn around times,
   * the vendors turn around time will be returned. In the absense of vendor turn around
   * time, null will be returned.
   */
  turnAroundTime?: Maybe<Scalars['Int']['output']>;
  updated: Scalars['DateTime']['output'];
  uuid: Scalars['UUID']['output'];
  vendor: Vendor;
};


export type OrderImageArgs = {
  input?: InputMaybe<ImageInput>;
};

/** The data payload attached to an order adjustment push notification. */
export type OrderAdjustmentPushNotificationItem = {
  __typename?: 'OrderAdjustmentPushNotificationItem';
  /** The dollar amount the order was adjusted with */
  amount: Scalars['String']['output'];
  /** The uuid of the customer who placed the order that was adjusted */
  customer: Scalars['UUID']['output'];
  /** Order adjustment reason */
  description: Scalars['String']['output'];
  /** The uuid of the order which was adjusted. */
  order: Scalars['UUID']['output'];
  /** The portal on which the order was placed. */
  portal: Scalars['UUID']['output'];
};

export type OrderConnection = {
  __typename?: 'OrderConnection';
  edges: Array<Maybe<OrderEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type OrderEdge = {
  __typename?: 'OrderEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Order>;
};

export type OrderHistoryV2 = {
  __typename?: 'OrderHistoryV2';
  order?: Maybe<Order>;
  orderUuid: Scalars['UUID']['output'];
  timestamp: Scalars['DateTime']['output'];
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** Ways to identify an Order */
export type OrderIdInput = {
  referenceId?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

/** The data payload attached to an order field update push notification. */
export type OrderInfoFieldPushNotification = {
  __typename?: 'OrderInfoFieldPushNotification';
  /** The name of the field that was updated. */
  fieldName: Scalars['String']['output'];
  /** The previous value of the field that was updated. */
  fromValue?: Maybe<Scalars['String']['output']>;
  /** The uuid of the order to which this update was made. */
  order: Scalars['UUID']['output'];
  /** The new value of the field that was updated. */
  toValue?: Maybe<Scalars['String']['output']>;
  /** Will always be the value: 'orderInfoFieldUpdatedEvent' */
  type: Scalars['String']['output'];
};

export type OrderInput = {
  coupon?: InputMaybe<Scalars['String']['input']>;
  customerComments?: InputMaybe<Scalars['String']['input']>;
  deliveryAddress?: InputMaybe<Scalars['String']['input']>;
  deliveryMethod: DeliveryMethodInput;
  discountCode?: InputMaybe<Scalars['String']['input']>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  orderLineItems: Array<OrderLineItemInput>;
  paymentGatewayCardId?: InputMaybe<Scalars['String']['input']>;
  paymentGatewayCustomerId?: InputMaybe<Scalars['String']['input']>;
  tip?: InputMaybe<Scalars['Int']['input']>;
};

export type OrderInputV2 = {
  coupon?: InputMaybe<Scalars['String']['input']>;
  customerComments?: InputMaybe<Scalars['String']['input']>;
  deliveryAddress?: InputMaybe<Scalars['String']['input']>;
  deliveryMethod: DeliveryMethodInput;
  discountCode?: InputMaybe<Scalars['String']['input']>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  orderLineItems: Array<OrderLineItemInput>;
  paymentGatewayCustomerId?: InputMaybe<Scalars['String']['input']>;
  paymentGatewayPaymentMethodId?: InputMaybe<Scalars['String']['input']>;
  tip?: InputMaybe<Scalars['Int']['input']>;
};

export type OrderLineItemInput = {
  modChoices?: InputMaybe<Array<OrderModChoiceInput>>;
  productUuid: Scalars['String']['input'];
  quantity: Scalars['Int']['input'];
};

/** A timestamped log of an event that occurred for this order. */
export type OrderLogEvent = {
  __typename?: 'OrderLogEvent';
  /** The type of operation that caused this order log */
  action: Scalars['String']['output'];
  /** A human-readable description of the event. */
  message: Scalars['String']['output'];
  profile?: Maybe<Profile>;
  /** The time at which the event occurred. */
  timestamp: Scalars['DateTime']['output'];
  /** The uuid of the user who triggered the event. */
  userUuid?: Maybe<Scalars['UUID']['output']>;
};

export type OrderModChoiceInput = {
  /** The choices of the mods. */
  choiceUuids: Array<Scalars['UUID']['input']>;
  modUuid: Scalars['UUID']['input'];
  /** The value of text input for FREE_TEXT mod only. */
  value?: InputMaybe<Scalars['String']['input']>;
};

/** The data payload attached to an order refund line items push notification. */
export type OrderRefundLineItemsPushNotification = {
  __typename?: 'OrderRefundLineItemsPushNotification';
  /** The human-readable amount by which the order was refunded */
  amount: Scalars['String']['output'];
  /** The uuid of the customer who placed the order that was refunded */
  customer: Scalars['UUID']['output'];
  /** Order refund reason */
  description: Scalars['String']['output'];
  /** The uuid of the order which was adjusted. */
  order: Scalars['UUID']['output'];
  /** The portal on which the order was placed. */
  portal: Scalars['UUID']['output'];
};

export type OrderStatus =
  | 'CANCELLED'
  | 'COMPLETED'
  | 'IN_PROGRESS'
  | 'NEW'
  | 'PAYMENT_FAILED'
  | 'PAYMENT_PENDING'
  | 'REJECTED';

/** The data payload attached to an order status update push notification. */
export type OrderStatusPushNotification = {
  __typename?: 'OrderStatusPushNotification';
  /** The uuid of the customer who placed the order. */
  customer: Scalars['UUID']['output'];
  /** The uuid of the order which has changed status. */
  order: Scalars['UUID']['output'];
  /** The portal on which the order was placed. */
  portal: Scalars['UUID']['output'];
  /** The new status of the order. */
  status: OrderStatus;
};

export type OrderSteps = {
  __typename?: 'OrderSteps';
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  status: StepStatus;
};

/** Filters on the order query. */
export type OrdersQueryFilterInput = {
  /** Match orders from customer's company whose name contains this string. */
  companyName?: InputMaybe<Scalars['String']['input']>;
  /** Match orders from customer whose name contains this string. */
  customerName?: InputMaybe<Scalars['String']['input']>;
  /** Match orders with these delivery methods */
  deliveryMethods?: InputMaybe<Array<Scalars['UUID']['input']>>;
  /** Match orders with this discount code */
  discountCode?: InputMaybe<Scalars['String']['input']>;
  /** Match orders with output field name or value that contains this string. */
  outputField?: InputMaybe<Scalars['String']['input']>;
  /** Match orders with these portal vendor delivery methods */
  portalVendorDeliveryMethods?: InputMaybe<Array<Scalars['UUID']['input']>>;
  /** Match orders on these portals. */
  portals?: InputMaybe<Array<Scalars['UUID']['input']>>;
  /** Match orders which contain this reference ID. */
  referenceId?: InputMaybe<Scalars['String']['input']>;
  /** Match orders with these statuses. */
  statuses?: InputMaybe<Array<OrderStatus>>;
  /** Match orders to these vendors. */
  vendors?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type OtpChallengeFailure = {
  __typename?: 'OtpChallengeFailure';
  localisedReason: LocalisedMessage;
  message: Scalars['String']['output'];
  reason: OtpChallengeFailureReason;
};

export type OtpChallengeFailureReason =
  | 'EMAIL_FAILURE'
  | 'EMAIL_VERIFICATION_REQUIRED'
  | 'UNKNOWN';

export type OtpChallengeRespondFailure = {
  __typename?: 'OtpChallengeRespondFailure';
  localisedReason: LocalisedMessage;
  message: Scalars['String']['output'];
  reason: OtpChallengeRespondFailureReason;
};

export type OtpChallengeRespondFailureReason =
  | 'EXPIRED'
  | 'INVALID_OTP'
  | 'UNKNOWN';

/** response to the otp challenge */
export type OtpChallengeRespondInput = {
  /** otp (6-digit) code of the challenge */
  bindingCode: Scalars['String']['input'];
  /** oob code received from the otp challenge */
  oobCode: Scalars['UUID']['input'];
};

/** whether the otp challenge response was successful, provide reason if not */
export type OtpChallengeRespondResult = OtpChallengeRespondFailure | OtpChallengeRespondSuccess;

export type OtpChallengeRespondSuccess = {
  __typename?: 'OtpChallengeRespondSuccess';
  success: Scalars['Boolean']['output'];
};

/** otp challenge with oobCode, if can't create it, why */
export type OtpChallengeResult = OtpChallengeFailure | OtpChallengeSuccess;

export type OtpChallengeSuccess = {
  __typename?: 'OtpChallengeSuccess';
  /** out of band code, think of it as an unique id of the otp challenge */
  oobCode: Scalars['UUID']['output'];
};

/** Input capturing debit/credit cards that were saved to the Payment gateway */
export type OwnCardInput = {
  /** Optional description for the card (eg. Visa work card) */
  description?: InputMaybe<Scalars['String']['input']>;
  /** How the payment gateway identifies this card */
  newCardToken: Scalars['String']['input'];
  /** Set this as the user's new default card. An input of false is ignored if the user has no other cards. */
  setDefault?: InputMaybe<Scalars['Boolean']['input']>;
};

/** A customer's device such as an IOS / Android phone or tablet. */
export type OwnDeviceInput = {
  /** Edition. */
  edition: Scalars['String']['input'];
  /** A human readable name for the device. */
  name: Scalars['String']['input'];
  /** The customer's Firebase Cloud Messaging (FCM) device registration id. */
  registrationId: Scalars['String']['input'];
  /**
   * false or null means the device is using the legacy firebase project.
   * true means the device is using an edition-specific firebase project.
   */
  usesEditionFirebaseProject?: InputMaybe<Scalars['Boolean']['input']>;
};

/** A customer's own profile. */
export type OwnProfileInput = {
  /** Uuids of site attributes associated with this site profile. */
  attributes?: InputMaybe<Array<Scalars['UUID']['input']>>;
  /** The avatar/picture of the profile. */
  avatar?: InputMaybe<Scalars['String']['input']>;
  /** A customers location eg. desk level building */
  deliveryLocation?: InputMaybe<Scalars['String']['input']>;
  /** The customer's email. */
  email?: InputMaybe<Scalars['Email']['input']>;
  /** The customer's profile field values. Fields for which inputs are not provided are left untouched. */
  fields?: InputMaybe<Array<ProfileFieldValueInput>>;
  /** The first name of the profile. */
  firstName: Scalars['String']['input'];
  /** The customer's job title. */
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  /** The last name of the profile. */
  lastName: Scalars['String']['input'];
  /** The customer's mobile number. */
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  /** Whether the profile is subscribed to emails or not. */
  subscribedToEmails: Scalars['Boolean']['input'];
};

/** A customer's own profile. */
export type OwnProfileInputV2 = {
  /** Uuids of site attributes associated with this site profile. */
  attributes?: InputMaybe<Array<Scalars['UUID']['input']>>;
  /** The avatar/picture of the profile. */
  avatar?: InputMaybe<Scalars['String']['input']>;
  /** A customers location eg. desk level building */
  deliveryLocation?: InputMaybe<Scalars['String']['input']>;
  /** The customer's email. */
  email?: InputMaybe<Scalars['Email']['input']>;
  /** The customer's profile field values. Fields for which inputs are not provided are left untouched. */
  fields?: InputMaybe<Array<ProfileFieldValueInput>>;
  /** The first name of the profile. */
  firstName: Scalars['String']['input'];
  /** The customer's job title. */
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  /** The last name of the profile. */
  lastName: Scalars['String']['input'];
  /** The customer's mobile number. */
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  /** Whether the profile is subscribed to emails or not. */
  subscribedToEmails: Scalars['Boolean']['input'];
  /** Whether the profile is subscribed to marketing notifications or not. */
  subscribedToNotifications: Scalars['Boolean']['input'];
};

/** Paagination info type */
export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
};

/** Standardised pagination options. */
export type PageOptions = {
  /** The cursor to paginate forwards using. */
  after?: InputMaybe<Scalars['String']['input']>;
  /** The cursor to paginate backwards using. */
  before?: InputMaybe<Scalars['String']['input']>;
  /** How many results to return in each page, between 1 and 50. */
  first: Scalars['Int']['input'];
};

export type PageViewInput = {
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  crossReference?: InputMaybe<Scalars['String']['input']>;
  pageTitle: Scalars['String']['input'];
  pageType: PageViewPageType;
  pageUrl: Scalars['URI']['input'];
  siteUuid: Scalars['UUID']['input'];
  timestamp: Scalars['DateTime']['input'];
};

export type PageViewPageType =
  | 'ARTICLE'
  | 'BOOKING'
  | 'EVENT'
  | 'HOME'
  | 'INFO'
  | 'NEWS'
  | 'PROFILE'
  | 'REGISTRATION'
  | 'RESOURCE_LIST'
  | 'STATIC'
  | 'STORE'
  | 'VISITOR_MANAGEMENT';

export type ParkingProduct = Product & {
  __typename?: 'ParkingProduct';
  /** If true the product is only available for a limited set of trading hours */
  availability: ProductAvailability;
  /** Whether the product is available or not. */
  available: Scalars['Boolean']['output'];
  /** Description of the product. */
  description?: Maybe<Scalars['String']['output']>;
  /** URL of the image for the product. */
  image?: Maybe<Scalars['URI']['output']>;
  /** S3 object key of the image for the product. */
  imageKey?: Maybe<Scalars['String']['output']>;
  /** A list of mods for the product. */
  mods: Array<Mod>;
  /** Name of the product. */
  name: Scalars['String']['output'];
  /**
   * Out of stock flag that can be set on products with or without a stock limit.
   * when true the stock count will be set to 0.
   * When true available will be false.
   */
  outOfStock: Scalars['Boolean']['output'];
  /** Fields for which values will be provided while processing an order for this product. */
  outputFields: Array<ProductOutputField>;
  /**
   * @deprecated renamed to sites.
   * @deprecated renamed to sites
   */
  portals: Array<Portal>;
  /** Product categories e.g. Breakfast, Lunch, Dinner */
  productCategories: Array<ProductCategory>;
  /** Profile fields which the customer is requested to provide when ordering this product. */
  profileFieldRequests: Array<ProductProfileField>;
  /** Show or hide terms and conditions. */
  showTermsAndConditions: Scalars['Boolean']['output'];
  /**
   * Portals associated with the product
   * @deprecated use sitesWithAudience endpoint
   */
  sites: Array<Portal>;
  /** Portals associated with the product */
  sitesWithAudience: Array<SiteSegment>;
  /** How many of this product are left. */
  stock?: Maybe<Scalars['Int']['output']>;
  /** Taxes applicable for this product */
  taxRates: Array<TaxRate>;
  /** Terms and conditions. */
  termsAndConditions?: Maybe<Scalars['String']['output']>;
  /** Overridden turn around time in minutes for the product */
  turnAroundTime?: Maybe<Scalars['Int']['output']>;
  /** Unit price in cents. May be tax inc or exc. */
  unitPrice: Scalars['Int']['output'];
  uuid: Scalars['UUID']['output'];
  /** The owner of the product. */
  vendor: Vendor;
  /** Whether product is visible to customer on the store */
  visibility: ProductVisibility;
};


export type ParkingProductImageArgs = {
  input?: InputMaybe<ImageInput>;
};

export type ParkingProvider =
  | 'NONE'
  | 'PARK_OFFICE'
  | 'UBIPARK';

export type ParkingSettings = {
  __typename?: 'ParkingSettings';
  parkingEnabled: Scalars['Boolean']['output'];
  parkingProvider: ParkingProvider;
};

export type PartialProfile = {
  __typename?: 'PartialProfile';
  /** Whether the customer is active (or blocked). */
  active: Scalars['Boolean']['output'];
  /** The customer's avatar. */
  avatar?: Maybe<Scalars['URI']['output']>;
  companyV2?: Maybe<CompanyV2>;
  /** The display name of the profile. */
  displayName: Scalars['String']['output'];
  /** The customer's email. */
  email: Scalars['Email']['output'];
  /** The first name of the profile. */
  firstName?: Maybe<Scalars['String']['output']>;
  /** The date when the profile was last active. */
  lastActivityTime: Scalars['DateTime']['output'];
  /** The last name of the profile. */
  lastName?: Maybe<Scalars['String']['output']>;
  /** The customer's mobile number. */
  mobileNumber?: Maybe<Scalars['String']['output']>;
  /** reference id used to identify user for deal redemption */
  referenceId: Scalars['String']['output'];
  status: ProfileStatus;
  /** An email address the customer is currently verifying. */
  unverifiedEmail?: Maybe<Scalars['Email']['output']>;
  /** Unique identifier for the profile. */
  uuid: Scalars['UUID']['output'];
  /** Has the user been active after initial creation */
  wasActivated: Scalars['Boolean']['output'];
};


export type PartialProfileAvatarArgs = {
  input?: InputMaybe<ImageInput>;
};

export type PartialProfileInput = {
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  userUuid: Scalars['UUID']['input'];
};

export type PartialUser = {
  __typename?: 'PartialUser';
  profile?: Maybe<PartialProfile>;
  uuid: Scalars['UUID']['output'];
};

export type PassPrintingConfiguration = {
  __typename?: 'PassPrintingConfiguration';
  marginTop?: Maybe<Scalars['Int']['output']>;
};

export type PassPrintingConfigurationInput = {
  marginTop?: InputMaybe<Scalars['Int']['input']>;
};

/** Enumeration of all the possible failure codes during payment */
export type PaymentFailureCodes =
  | 'AMOUNT_TOO_LARGE'
  | 'AMOUNT_TOO_SMALL'
  | 'BALANCE_INSUFFICIENT'
  | 'CARD_DECLINED'
  | 'EXPIRED_CARD'
  | 'OTHER'
  | 'STRIPE_REQUEST_INPUT';

/** See https://stripe.com/docs/api/payment_intents/object */
export type PaymentIntent = {
  __typename?: 'PaymentIntent';
  /** See https://stripe.com/docs/api/payment_intents/object#payment_intent_object-client_secret */
  clientSecret: Scalars['String']['output'];
  id: Scalars['String']['output'];
  /** See https://stripe.com/docs/api/payment_intents/object#payment_intent_object-status */
  status: PaymentIntentStatus;
};

/** See https://stripe.com/docs/api/payment_intents/object#payment_intent_object-client_secret */
export type PaymentIntentStatus =
  | 'CANCELED'
  | 'PROCESSING'
  | 'REQUIRES_ACTION'
  | 'REQUIRES_CAPTURE'
  | 'REQUIRES_CONFIRMATION'
  | 'REQUIRES_PAYMENT_METHOD'
  | 'SUCCEEDED';

export type PercentageDiscount = {
  __typename?: 'PercentageDiscount';
  percentage: Scalars['Int']['output'];
};

export type PeriodDiff = {
  __typename?: 'PeriodDiff';
  minus?: Maybe<MetricValue>;
  plus?: Maybe<MetricValue>;
};

export type PeriodMetric = {
  __typename?: 'PeriodMetric';
  diff: PeriodDiff;
  value: MetricValue;
};

/** Permission a user can have */
export type Permission = {
  __typename?: 'Permission';
  /** Machine name */
  key: Role;
  /** The name of the Permission. */
  name: Scalars['String']['output'];
  /** Unique identifier for the Permission. */
  uuid: Scalars['UUID']['output'];
};

export type PermissionAction = {
  __typename?: 'PermissionAction';
  action: Scalars['String']['output'];
  resource: Resource;
  userUuid: Scalars['UUID']['output'];
};

export type PermissionFilter = {
  action?: InputMaybe<Scalars['String']['input']>;
  resource: ResourceInput;
};

export type PermissionResource = {
  __typename?: 'PermissionResource';
  children: Array<PermissionResource>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
  viewerCanGrant?: Maybe<Scalars['Boolean']['output']>;
};

export type PermissionRoleResources = {
  __typename?: 'PermissionRoleResources';
  resources: Array<PermissionResource>;
  role: PermissionsRoleV2;
};

export type PermissionsRole = {
  __typename?: 'PermissionsRole';
  actions: Array<ActionResource>;
  allowedAssignors: Array<Scalars['String']['output']>;
  allowedResources: Array<PermissionResource>;
  name: Scalars['String']['output'];
  uuid: Scalars['UUID']['output'];
};


export type PermissionsRoleAllowedResourcesArgs = {
  assigneeUuid?: InputMaybe<Scalars['UUID']['input']>;
  refreshCache?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PermissionsRoleV2 = {
  __typename?: 'PermissionsRoleV2';
  /**
   * Note that the name will be localised once the content localisation system is in place.
   * See https://docs.google.com/document/d/17xkaYfVVyA1BQ4IpWF1AfVlbILQ9WsNIrABd0k67kR4
   */
  name: Scalars['String']['output'];
  relation: Scalars['String']['output'];
  tuple: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type PersistTemporaryUploadInput = {
  contentDisposition: DocumentContentDisposition;
  filename: Scalars['String']['input'];
  global?: InputMaybe<Scalars['Boolean']['input']>;
  metadata: Array<DocumentMetadataInput>;
  prefix: Scalars['String']['input'];
  public: Scalars['Boolean']['input'];
  rootPrefix: DocumentRootPrefixInput;
  temporaryUuid: Scalars['UUID']['input'];
};

export type PlainProduct = Product & {
  __typename?: 'PlainProduct';
  /** If true the product is only available for a limited set of trading hours */
  availability: ProductAvailability;
  /** Whether the product is available or not. */
  available: Scalars['Boolean']['output'];
  /** Description of the product. */
  description?: Maybe<Scalars['String']['output']>;
  excerpt: Scalars['String']['output'];
  /** URL of the image for the product. */
  image?: Maybe<Scalars['URI']['output']>;
  /** S3 object key of the image for the product. */
  imageKey?: Maybe<Scalars['String']['output']>;
  /** A list of mods for the product. */
  mods: Array<Mod>;
  /** Name of the product. */
  name: Scalars['String']['output'];
  /**
   * Out of stock flag that can be set on products with or without a stock limit.
   * when true the stock count will be set to 0.
   * When true available will be false.
   */
  outOfStock: Scalars['Boolean']['output'];
  /** Fields for which values will be provided while processing an order for this product. */
  outputFields: Array<ProductOutputField>;
  /**
   * @deprecated renamed to sites.
   * @deprecated renamed to sites
   */
  portals: Array<Portal>;
  /** Product categories e.g. Breakfast, Lunch, Dinner */
  productCategories: Array<ProductCategory>;
  /** Profile fields which the customer is requested to provide when ordering this product. */
  profileFieldRequests: Array<ProductProfileField>;
  /** Show or hide terms and conditions. */
  showTermsAndConditions: Scalars['Boolean']['output'];
  /**
   * Portals associated with the product
   * @deprecated use sitesWithAudience endpoint
   */
  sites: Array<Portal>;
  /** Portals associated with the product */
  sitesWithAudience: Array<SiteSegment>;
  /** How many of this product are left. */
  stock?: Maybe<Scalars['Int']['output']>;
  /** Taxes applicable for this product */
  taxRates: Array<TaxRate>;
  /** Terms and conditions. */
  termsAndConditions?: Maybe<Scalars['String']['output']>;
  /** Overridden turn around time in minutes for the product */
  turnAroundTime?: Maybe<Scalars['Int']['output']>;
  /** Unit price in cents. May be tax inc or exc. */
  unitPrice: Scalars['Int']['output'];
  uuid: Scalars['UUID']['output'];
  /** The owner of the product. */
  vendor: Vendor;
  /** Whether product is visible to customer on the store */
  visibility: ProductVisibility;
};


export type PlainProductImageArgs = {
  input?: InputMaybe<ImageInput>;
};

export type PlainProductContentEdge = ContentEdge & {
  __typename?: 'PlainProductContentEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<PlainProduct>;
};

export type PlatformType =
  | 'MOBILE'
  | 'WEB';

export type Policy = {
  __typename?: 'Policy';
  id: Scalars['UUID']['output'];
  resources: Array<Resource>;
  role: PermissionsRole;
};

export type PolicyChangeEventLogAction =
  | 'COOKIE_POLICY'
  | 'PRIVACY_POLICY'
  | 'TERMS_AND_CONDITIONS';

/** A timestamped log of an event that occurred for this profile. */
export type PolicyChangeLogEvent = {
  __typename?: 'PolicyChangeLogEvent';
  action: PolicyChangeEventLogAction;
  message: Scalars['String']['output'];
  timestamp: Scalars['DateTime']['output'];
  triggeredBy?: Maybe<PartialUser>;
  uuid: Scalars['UUID']['output'];
  versionText: Scalars['String']['output'];
};

export type Poll = {
  __typename?: 'Poll';
  allowMultipleEntries: Scalars['Boolean']['output'];
  closed: Scalars['Boolean']['output'];
  contrastColor?: Maybe<Scalars['String']['output']>;
  endDate: Scalars['DateTime']['output'];
  featured: Scalars['Boolean']['output'];
  publishDate: Scalars['DateTime']['output'];
  questions: Array<PollQuestion>;
  resultsArePublic: Scalars['Boolean']['output'];
  tags?: Maybe<Array<Scalars['String']['output']>>;
  title: Scalars['String']['output'];
  uuid: Scalars['UUID']['output'];
};

export type PollDurationInterval =
  | 'days'
  | 'months';

export type PollInput = {
  allowMultipleEntries: Scalars['Boolean']['input'];
  contentUuid: Scalars['UUID']['input'];
  contrastColor?: InputMaybe<Scalars['String']['input']>;
  durationInterval: PollDurationInterval;
  durationNumber: Scalars['Int']['input'];
  featured: Scalars['Boolean']['input'];
  isClosed: Scalars['Boolean']['input'];
  portalPostUuid: Scalars['UUID']['input'];
  publishDateInUtc: Scalars['DateTime']['input'];
  published: Scalars['Boolean']['input'];
  questions: Array<PollQuestionInput>;
  resultsArePublic: Scalars['Boolean']['input'];
  segmentIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  site: Scalars['UUID']['input'];
  title: Scalars['String']['input'];
};

export type PollQuestion = {
  __typename?: 'PollQuestion';
  alreadyResponded: Scalars['Boolean']['output'];
  options?: Maybe<Array<PollQuestionOption>>;
  order: Scalars['Int']['output'];
  responses: Array<PollQuestionResponse>;
  title: Scalars['String']['output'];
  totalParticipants: Scalars['Int']['output'];
  type: PollQuestionType;
  uuid: Scalars['UUID']['output'];
};

export type PollQuestionInput = {
  options?: InputMaybe<Array<PollQuestionOptionInput>>;
  order: Scalars['Int']['input'];
  title: Scalars['String']['input'];
  type: PollQuestionType;
  uuid: Scalars['UUID']['input'];
};

export type PollQuestionOption = {
  __typename?: 'PollQuestionOption';
  response: Scalars['String']['output'];
  uuid: Scalars['UUID']['output'];
};

export type PollQuestionOptionInput = {
  response: Scalars['String']['input'];
  uuid: Scalars['UUID']['input'];
};

export type PollQuestionResponse = {
  __typename?: 'PollQuestionResponse';
  count: Scalars['Int']['output'];
  responseText: Scalars['String']['output'];
};

export type PollQuestionResponseNode = {
  __typename?: 'PollQuestionResponseNode';
  created: Scalars['DateTime']['output'];
  pollUuid: Scalars['UUID']['output'];
  questionUuid: Scalars['UUID']['output'];
  responses: Array<Maybe<QuestionResponse>>;
  site?: Maybe<Destination>;
  siteUuid: Scalars['UUID']['output'];
  submissionId: Scalars['UUID']['output'];
  user?: Maybe<Profile>;
  userUuid?: Maybe<Scalars['UUID']['output']>;
};

export type PollQuestionType =
  | 'Comment'
  | 'MultipleChoice'
  | 'SingleChoice';

/** PollResponses connection type */
export type PollResponseConnection = {
  __typename?: 'PollResponseConnection';
  edges: Array<Maybe<PollResponseEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type PollResponseEdge = {
  __typename?: 'PollResponseEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<PollQuestionResponseNode>;
};

export type Portal = Node & {
  __typename?: 'Portal';
  /** Full address string */
  address: Scalars['String']['output'];
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  deliveryMethods: Array<DeliveryMethod>;
  locale: Scalars['String']['output'];
  name: Scalars['String']['output'];
  postCode: Scalars['String']['output'];
  products: ProductConnection;
  settings: PortalSettings;
  state: Scalars['String']['output'];
  streetAddress: Scalars['String']['output'];
  taxRegion: TaxRegion;
  timezone: Scalars['String']['output'];
  uuid: Scalars['UUID']['output'];
  /** contains vendors and deal vendors */
  vendorList: Array<VendorUnion>;
  vendors: VendorConnection;
};


export type PortalProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProductFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type PortalVendorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type PortalSettings = {
  __typename?: 'PortalSettings';
  /** Whether the store is enabled for customers. */
  enabled: Scalars['Boolean']['output'];
  /** @deprecated Redundant flag. Use enabled flag */
  open: Scalars['Boolean']['output'];
};

export type Portfolio = {
  __typename?: 'Portfolio';
  name: Scalars['String']['output'];
  portfolioSites: Array<PortfolioSite>;
  regionName: Scalars['String']['output'];
  /** @deprecated use portfolioSites */
  sites: Array<PortfolioSiteV2>;
  uuid: Scalars['UUID']['output'];
};

export type PortfolioSite = {
  __typename?: 'PortfolioSite';
  destination: Destination;
  iHaveManagerAccess: Scalars['Boolean']['output'];
  portfolio: Portfolio;
};

export type PortfolioSiteV2 = {
  __typename?: 'PortfolioSiteV2';
  destination: Destination;
  iHaveManagerAccess: Scalars['Boolean']['output'];
  portfolio: Portfolio;
};

/** A possible session is derived from the session template but not persisted. */
export type PossibleSession = {
  __typename?: 'PossibleSession';
  attendeeLimit?: Maybe<Scalars['Int']['output']>;
  day: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  duration: Scalars['Int']['output'];
  endDate: Scalars['DateTime']['output'];
  location: Scalars['String']['output'];
  sessionTemplateUuid: Scalars['UUID']['output'];
  siteUuids: Array<Scalars['String']['output']>;
  startDate: Scalars['DateTime']['output'];
  timezone: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type PostViewCountResult = {
  __typename?: 'PostViewCountResult';
  postUuid: Scalars['UUID']['output'];
  viewCount: Scalars['Int']['output'];
};

export type Product = {
  /** If true the product is only available for a limited set of trading hours */
  availability: ProductAvailability;
  /** Whether the product is available or not. */
  available: Scalars['Boolean']['output'];
  /** Description of the product. */
  description?: Maybe<Scalars['String']['output']>;
  /** URL of the image for the product. */
  image?: Maybe<Scalars['URI']['output']>;
  /** S3 object key of the image for the product. */
  imageKey?: Maybe<Scalars['String']['output']>;
  /** A list of mods for the product. */
  mods: Array<Mod>;
  /** Name of the product. */
  name: Scalars['String']['output'];
  /**
   * Out of stock flag that can be set on products with or without a stock limit.
   * when true the stock count will be set to 0.
   * When true available will be false.
   */
  outOfStock: Scalars['Boolean']['output'];
  /** Fields for which values will be provided while processing an order for this product. */
  outputFields: Array<ProductOutputField>;
  /**
   * @deprecated renamed to sites.
   * @deprecated renamed to sites
   */
  portals: Array<Portal>;
  /** Product categories e.g. Breakfast, Lunch, Dinner */
  productCategories: Array<ProductCategory>;
  /** Profile fields which the customer is requested to provide when ordering this product. */
  profileFieldRequests: Array<ProductProfileField>;
  /** Show or hide terms and conditions. */
  showTermsAndConditions: Scalars['Boolean']['output'];
  /**
   * Portals associated with the product
   * @deprecated use sitesWithAudience endpoint
   */
  sites: Array<Portal>;
  /** Portals associated with the product with optional audience */
  sitesWithAudience: Array<SiteSegment>;
  /** How many of this product are left. */
  stock?: Maybe<Scalars['Int']['output']>;
  /** Taxes applicable for this product */
  taxRates: Array<TaxRate>;
  /** Terms and conditions. */
  termsAndConditions?: Maybe<Scalars['String']['output']>;
  /** Overridden turn around time in minutes for the product */
  turnAroundTime?: Maybe<Scalars['Int']['output']>;
  /** Unit price in cents. May be tax inc or exc. */
  unitPrice: Scalars['Int']['output'];
  uuid: Scalars['UUID']['output'];
  /** The owner of the product. */
  vendor: Vendor;
  /** Whether product is visible to customer on the store */
  visibility: ProductVisibility;
};


export type ProductImageArgs = {
  input?: InputMaybe<ImageInput>;
};

export type ProductAudience = {
  __typename?: 'ProductAudience';
  segmentIds: Array<Scalars['UUID']['output']>;
  summary: Scalars['String']['output'];
};

export type ProductAvailability = {
  __typename?: 'ProductAvailability';
  dateRange?: Maybe<AvailabilityDateRange>;
  groupedTimes: Array<GroupedTradingHour>;
  /** @deprecated Use `Product -> available` */
  isAvailable: Scalars['Boolean']['output'];
  limitedAvailability: Scalars['Boolean']['output'];
  nextAvailable?: Maybe<Scalars['DateTime']['output']>;
  times?: Maybe<Array<TradingHour>>;
};

export type ProductCategory = {
  __typename?: 'ProductCategory';
  name: Scalars['String']['output'];
  productList: Array<ProductUnion>;
  products: Array<Product>;
  uuid: Scalars['UUID']['output'];
};

export type ProductConnection = {
  __typename?: 'ProductConnection';
  edges: Array<Maybe<ProductEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ProductEdge = {
  __typename?: 'ProductEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Product>;
};

export type ProductFilter = {
  /**  A search term to be searched against product name, description, category name, mods and choices  */
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Scalars['UUID']['input']>;
  visibility?: InputMaybe<ProductVisibility>;
};

/** A product field for which output can be provided by a vendor. */
export type ProductOutputField = {
  __typename?: 'ProductOutputField';
  /** The name of the output field. */
  name: Scalars['String']['output'];
};

/** A product field for which output can be provided by a vendor. */
export type ProductOutputFieldValue = {
  __typename?: 'ProductOutputFieldValue';
  /** The name of the output field. */
  name: Scalars['String']['output'];
  /** The value which has been provided by the vendor. */
  value: Scalars['String']['output'];
};

export type ProductProfileField = {
  __typename?: 'ProductProfileField';
  /** The field and any current value that is available. */
  field: ProfileFieldValue;
  /** Whether the customer is required to provide a value for this field. */
  required: Scalars['Boolean']['output'];
};

export type ProductSubscription = {
  __typename?: 'ProductSubscription';
  billingCycle: SubscriptionBillingCycle;
  canceled?: Maybe<Scalars['DateTime']['output']>;
  charges: SubscriptionChargeConnection;
  created: Scalars['DateTime']['output'];
  lastCharge: SubscriptionCharge;
  lastSuccessfulCharge: Scalars['DateTime']['output'];
  nextCharge?: Maybe<Scalars['DateTime']['output']>;
  nextCycle?: Maybe<Scalars['DateTime']['output']>;
  order: Order;
  /** @deprecated Use paymentGatewayPaymentMethodIdV2 instead */
  paymentGatewayPaymentMethodId: Scalars['String']['output'];
  paymentGatewayPaymentMethodIdV2?: Maybe<Scalars['String']['output']>;
  paymentStatus: SubscriptionPaymentStatus;
  product: SubscriptionProduct;
  /**
   * @deprecated(reason: Use statusV2 that supports paused status)
   * @deprecated Use statusV2 that supports paused status
   */
  status: ProductSubscriptionStatus;
  statusV2: ProductSubscriptionStatusV2;
  uuid: Scalars['UUID']['output'];
};


export type ProductSubscriptionChargesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductSubscriptionLastChargeArgs = {
  includePaused?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProductSubscriptionConnection = {
  __typename?: 'ProductSubscriptionConnection';
  edges: Array<Maybe<ProductSubscriptionEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ProductSubscriptionEdge = {
  __typename?: 'ProductSubscriptionEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<ProductSubscription>;
};

export type ProductSubscriptionStatus =
  | 'ACTIVE'
  | 'CANCELLED';

export type ProductSubscriptionStatusV2 =
  | 'ACTIVE'
  | 'CANCELLED'
  | 'PAUSED';

export type ProductType =
  | 'BOOKABLE_PRODUCT'
  | 'PLAIN_PRODUCT'
  | 'SUBSCRIPTION_PRODUCT';

export type ProductUnion = BookableProduct | Deal | ParkingProduct | PlainProduct | SubscriptionProduct;

export type ProductVisibility =
  | 'HIDDEN'
  | 'LIVE';

/** A user's profile. */
export type Profile = {
  __typename?: 'Profile';
  /**
   * Whether the customer is active (or blocked).
   * @deprecated Use active status in Syndicate API
   */
  active: Scalars['Boolean']['output'];
  /** old and new world permissions */
  allPermissions?: Maybe<Array<Permission>>;
  approvalNotificationsPreferences: ApprovalNotificationsPreferences;
  /** The customer's avatar. */
  avatar?: Maybe<Scalars['URI']['output']>;
  bookingNotificationPreferences?: Maybe<BookingNotificationPreferences>;
  canManageAnySite: Scalars['Boolean']['output'];
  /** A list of credit/debit cards associated with the profile */
  cards: Array<Card>;
  /** The company to which this customer belongs. */
  company?: Maybe<Company>;
  companyV2?: Maybe<CompanyV2>;
  /** Whether this user has access to console app */
  consoleAppAccess: Scalars['Boolean']['output'];
  /** The date when the profile was created. */
  created: Scalars['DateTime']['output'];
  /** Customer/profile's default card */
  defaultCard?: Maybe<Card>;
  /**
   * A customers location eg. desk level building
   * @deprecated Please use customerLocation field on order instead
   */
  deliveryLocation?: Maybe<Scalars['String']['output']>;
  /** A list of the devices associated with this customer's account. */
  devices: Array<Device>;
  /** The display name of the profile. */
  displayName: Scalars['String']['output'];
  /** The customer's email. */
  email: Scalars['Email']['output'];
  /** Whether the user's email is verified */
  emailVerified: Scalars['Boolean']['output'];
  /** The first name of the profile. */
  firstName?: Maybe<Scalars['String']['output']>;
  flexMemberships: Array<FlexTenantMembership>;
  grantedRoleTuples: Array<PermissionRoleResources>;
  isAppointmentOrganizer?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the user is an Equiem employee */
  isEquiemEmployee: Scalars['Boolean']['output'];
  /** @deprecated Use SiteProfile.isReceptionist */
  isReceptionist?: Maybe<Scalars['Boolean']['output']>;
  /** The customer's job title. */
  jobTitle?: Maybe<Scalars['String']['output']>;
  /** The last activity associated with the profile. */
  lastActivity: Scalars['String']['output'];
  /** The date when the profile was last active. */
  lastActivityTime: Scalars['DateTime']['output'];
  /** The last name of the profile. */
  lastName?: Maybe<Scalars['String']['output']>;
  /** The customer's mobile number. */
  mobileNumber?: Maybe<Scalars['String']['output']>;
  /** Permissions assigned to a profile from ucm */
  newWorldPermissions?: Maybe<Array<Permission>>;
  /** ID for customer according to the payment gateway */
  paymentGatewayCustomerId?: Maybe<Scalars['String']['output']>;
  /** Permissions assigned to a profile */
  permissions?: Maybe<Array<Permission>>;
  permissionsV2?: Maybe<Array<PermissionAction>>;
  /** The customer's phone number. */
  phoneNumber?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use grantedRoleTuples instead */
  policies?: Maybe<Array<Policy>>;
  /** Whether or not the profile can be viewed by other customers. */
  public: Scalars['Boolean']['output'];
  /** reference id used to identify user for deal redemption */
  referenceId: Scalars['String']['output'];
  /** A profiles site specific profile connection */
  siteProfiles: ProfileSiteProfileConnection;
  status: ProfileStatus;
  /** An email address the customer is currently verifying. */
  unverifiedEmail?: Maybe<Scalars['Email']['output']>;
  /** The date when the profile was last updated. */
  updated: Scalars['DateTime']['output'];
  /** Unique identifier for the profile. */
  uuid: Scalars['UUID']['output'];
  vicinitee?: Maybe<ViciniteeProfile>;
  /** Has the user been active after initial creation */
  wasActivated: Scalars['Boolean']['output'];
};


/** A user's profile. */
export type ProfileAvatarArgs = {
  input?: InputMaybe<ImageInput>;
};


/** A user's profile. */
export type ProfileFlexMembershipsArgs = {
  siteUuid?: InputMaybe<Scalars['UUID']['input']>;
};


/** A user's profile. */
export type ProfileSiteProfilesArgs = {
  filters?: InputMaybe<ProfileSiteProfileFilters>;
  page: PageOptions;
};

export type ProfileConnection = {
  __typename?: 'ProfileConnection';
  edges: Array<ProfileEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ProfileDateFilter = {
  comparisonOperator: Scalars['String']['input'];
  timestamp: Scalars['DateTime']['input'];
};

export type ProfileEdge = {
  __typename?: 'ProfileEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Profile>;
};

export type ProfileEventLogAction =
  | 'ATTRIBUTE_ADDED'
  | 'ATTRIBUTE_REMOVED'
  | 'COMMENT'
  | 'COMPANY_UPDATED'
  | 'CREDIT_CARD_ADDED'
  | 'CREDIT_CARD_DELETED'
  | 'CSV_EXPORT_REQUEST_MANAGEMENT'
  | 'DEACTIVATION_REMINDER_SENT'
  | 'EMAIL_SUBSCRIPTION_CHANGED'
  | 'EMAIL_UPDATED'
  | 'EMAIL_VERIFICATION_REQUESTED'
  | 'MOBILE_NUMBER_UPDATED'
  | 'NOTIFICATION_SUBSCRIPTION_CHANGED'
  | 'PASSWORD_RESET'
  | 'PERMISSION_ADDED'
  | 'PERMISSION_REMOVED'
  | 'ROLE_ADDED'
  | 'ROLE_REMOVED'
  | 'SITE_PROFILE_ADDED'
  | 'SITE_PROFILE_REMOVED'
  | 'STATUS_UPDATED'
  | 'USER_DISABLED';

/** A profile field for which values can be saved. */
export type ProfileField = {
  __typename?: 'ProfileField';
  /** The unique key for identifying this field. Can be referenced from external systems. */
  key: Scalars['ID']['output'];
  /** A human-readable description of the field. */
  title: Scalars['String']['output'];
};

/** The value saved for a profile field. */
export type ProfileFieldValue = {
  __typename?: 'ProfileFieldValue';
  /** The field to which the value was saved. */
  field: ProfileField;
  /** The value, if one has been saved. */
  value: Scalars['String']['output'];
};

export type ProfileFieldValueInput = {
  /** The key of the field to which this value should be saved. */
  key: Scalars['ID']['input'];
  /** The value to save. If null, the value is deleted. */
  value?: InputMaybe<Scalars['String']['input']>;
};

export type ProfileFilter = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  attributes?: InputMaybe<Array<Scalars['UUID']['input']>>;
  companyUuid?: InputMaybe<Scalars['UUID']['input']>;
  companyUuids?: InputMaybe<Array<Scalars['UUID']['input']>>;
  created?: InputMaybe<ProfileDateFilter>;
  email?: InputMaybe<Scalars['String']['input']>;
  emails?: InputMaybe<Array<Scalars['String']['input']>>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  groups?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  lastSeen?: InputMaybe<ProfileDateFilter>;
  name?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<Array<Scalars['String']['input']>>;
  registrationType?: InputMaybe<ProfileRegistrationType>;
  subscribedToEmails?: InputMaybe<Scalars['Boolean']['input']>;
  subscribedToNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  uuids?: InputMaybe<Array<Scalars['UUID']['input']>>;
  verified?: InputMaybe<Scalars['Boolean']['input']>;
  wasActivated?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProfileHistorySort = {
  asc: Scalars['Boolean']['input'];
  field: ProfileHistorySortField;
};

export type ProfileHistorySortField =
  | 'TIMESTAMP';

/** A customer's profile. */
export type ProfileInput = {
  /** The customer's avatar. */
  active?: InputMaybe<Scalars['Boolean']['input']>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  /** The customer's job title. */
  company?: InputMaybe<Scalars['UUID']['input']>;
  /** The date when the profile was created. */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  /** A customers location eg. desk level building */
  deliveryLocation?: InputMaybe<Scalars['String']['input']>;
  /** The display name of the profile. */
  displayName?: InputMaybe<Scalars['String']['input']>;
  /** The customer's email. */
  email: Scalars['Email']['input'];
  /** The first name of the profile. */
  firstName: Scalars['String']['input'];
  /** The UUID of the company to which this customer belongs. */
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  /** The last name of the profile. */
  lastName: Scalars['String']['input'];
  /** The customer's mobile number. */
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  /** Permission assigned to this profile from UCM */
  newWorldPermissions?: InputMaybe<Array<Role>>;
  /** Permission assigned to this profile */
  permissions?: InputMaybe<Array<Scalars['String']['input']>>;
  /** The customer's phone number. */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** Whether or not the profile can be viewed by other customers. */
  public: Scalars['Boolean']['input'];
  /** Site specific profile information */
  siteProfiles?: InputMaybe<Array<SiteProfileInput>>;
  /** Whether the customer is active (or blocked). */
  status?: InputMaybe<ProfileStatus>;
  /** The customers Stripe account id. */
  stripeCustomerId?: InputMaybe<Scalars['String']['input']>;
  /** Unique identifier for the profile. */
  uuid: Scalars['UUID']['input'];
};

/** A customer's profile. */
export type ProfileInputV2 = {
  /** The customer's avatar. */
  active?: InputMaybe<Scalars['Boolean']['input']>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  /** The customer's job title. */
  company?: InputMaybe<Scalars['UUID']['input']>;
  /** The date when the profile was created. */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  /** A customers location eg. desk level building */
  deliveryLocation?: InputMaybe<Scalars['String']['input']>;
  /** The display name of the profile. */
  displayName?: InputMaybe<Scalars['String']['input']>;
  /** The customer's email. */
  email: Scalars['Email']['input'];
  /** The first name of the profile. */
  firstName: Scalars['String']['input'];
  /** The UUID of the company to which this customer belongs. */
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  /** The last name of the profile. */
  lastName: Scalars['String']['input'];
  /** The customer's mobile number. */
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  /** Permission assigned to this profile from UCM */
  newWorldPermissions?: InputMaybe<Array<Role>>;
  /** Permission assigned to this profile */
  permissions?: InputMaybe<Array<Scalars['String']['input']>>;
  /** The customer's phone number. */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** Whether or not the profile can be viewed by other customers. */
  public: Scalars['Boolean']['input'];
  /** Site specific profile information */
  siteProfiles?: InputMaybe<Array<SiteProfileInputV2>>;
  /** Whether the customer is active (or blocked). */
  status?: InputMaybe<ProfileStatus>;
  /** The customers Stripe account id. */
  stripeCustomerId?: InputMaybe<Scalars['String']['input']>;
  /** Unique identifier for the profile. */
  uuid: Scalars['UUID']['input'];
};

/** A timestamped log of an event that occurred for this profile. */
export type ProfileLogEvent = {
  __typename?: 'ProfileLogEvent';
  /** string version of actionType if you don't want to deal with enums */
  action: Scalars['String']['output'];
  /** The type of operation that caused this profile log. keeping this nullable because I dont trust gql enums. */
  actionType: ProfileEventLogAction;
  /** A human-readable description of the event. */
  message: Scalars['String']['output'];
  /** The time at which the event occurred. */
  timestamp: Scalars['DateTime']['output'];
  /** The uuid of the user who triggered the event. */
  triggeredBy?: Maybe<PartialProfile>;
  uuid: Scalars['UUID']['output'];
};

export type ProfileLogEventConnection = {
  __typename?: 'ProfileLogEventConnection';
  edges: Array<ProfileLogEventEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ProfileLogEventEdge = {
  __typename?: 'ProfileLogEventEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<ProfileLogEvent>;
};

export type ProfileReadyCheck = {
  __typename?: 'ProfileReadyCheck';
  displayMessage: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  status: ProfileReadyCheckStatus;
};

export type ProfileReadyCheckInput = {
  id: Scalars['ID']['input'];
  status: ProfileReadyCheckStatus;
};

export type ProfileReadyCheckStatus =
  | 'DONE'
  | 'FAILED'
  | 'IN_PROGRESS';

export type ProfileReadyCheckSummary = {
  __typename?: 'ProfileReadyCheckSummary';
  allDone: Scalars['Boolean']['output'];
  checks: Array<ProfileReadyCheck>;
};

export type ProfileRegistrationType =
  | 'COMMERCIAL'
  | 'RESIDENTIAL'
  | 'VISITOR';

export type ProfileRole =
  | 'AccessAttendeeReport';

export type ProfileServiceResult = {
  __typename?: 'ProfileServiceResult';
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type ProfileSiteProfileConnection = {
  __typename?: 'ProfileSiteProfileConnection';
  edges: Array<ProfileSiteProfileEdge>;
  pageInfo: PageInfo;
  profile: Profile;
  totalCount: Scalars['Int']['output'];
};

export type ProfileSiteProfileEdge = {
  __typename?: 'ProfileSiteProfileEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<SiteProfile>;
};

export type ProfileSiteProfileFilters = {
  current?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type ProfileSort = {
  asc: Scalars['Boolean']['input'];
  field: ProfileSortField;
};

export type ProfileSortField =
  | 'COMPANY'
  | 'EMAIL'
  | 'LAST_SEEN'
  | 'NAME'
  | 'STATUS';

export type ProfileStatus =
  | 'ACTIVE'
  | 'DEACTIVATED'
  | 'PENDING_APPROVAL';

export type PromotedFilter = {
  home?: InputMaybe<Scalars['Boolean']['input']>;
  product?: InputMaybe<Scalars['UUID']['input']>;
  vendor?: InputMaybe<Scalars['UUID']['input']>;
  vendorCategory?: InputMaybe<Scalars['UUID']['input']>;
};

export type PropertyManagerAdminNotificationInput = {
  fromUserUuid?: InputMaybe<Scalars['UUID']['input']>;
  icon?: InputMaybe<Scalars['URI']['input']>;
  linkToWidget?: InputMaybe<EquiemOneWidgetId>;
  site: Scalars['UUID']['input'];
  title: Scalars['String']['input'];
  url?: InputMaybe<Scalars['URI']['input']>;
};

export type PropertyManagerEquiemOneMetrics =
  | 'ActiveUsers'
  | 'NewsletterOpenRate'
  | 'Registrations';

export type PubSubRole =
  | 'ANON'
  | 'SERVICE'
  | 'USER';

export type PublicContentUnion = BuildingInfoEdge | EventOccurrenceEdge | NewsEdge;

export type PublicUserRegistrationInput = {
  acceptTermsAndConditions: Scalars['Boolean']['input'];
  company: Scalars['UUID']['input'];
  destination: Scalars['UUID']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  logInUser?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  subscribedToEmails?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PublicUserRegistrationV2Input = {
  acceptTermsAndConditions: Scalars['Boolean']['input'];
  apartment?: InputMaybe<Scalars['UUID']['input']>;
  company?: InputMaybe<Scalars['UUID']['input']>;
  destination: Scalars['UUID']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  logInUser?: InputMaybe<Scalars['Boolean']['input']>;
  password: Scalars['String']['input'];
  registrationType?: InputMaybe<RegistrationType>;
  subscribedToEmails: Scalars['Boolean']['input'];
};

export type PublicVendorRegistrationInput = {
  acceptTermsAndConditions: Scalars['Boolean']['input'];
  destination: Scalars['UUID']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  mobile?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type PublicationAuthorInput = {
  uuid: Scalars['UUID']['input'];
};

export type PublicationAuthorType =
  | 'customAuthor'
  | 'siteDefaultAuthor';

export type PublicationDateType =
  | 'publishLater'
  | 'publishNow';

export type PublishCmsArticleInput = {
  /** @deprecated Moved to site settings */
  publicationAuthorType?: InputMaybe<PublicationAuthorType>;
  publicationDate?: InputMaybe<Scalars['String']['input']>;
  publicationDateType?: InputMaybe<PublicationDateType>;
  publicationTime?: InputMaybe<Scalars['Time']['input']>;
  uuid: Scalars['UUID']['input'];
};

export type PushNotification = {
  __typename?: 'PushNotification';
  data?: Maybe<Scalars['JSON']['output']>;
  notification?: Maybe<PushNotificationNotification>;
  timestamp: Scalars['DateTime']['output'];
  uuid: Scalars['UUID']['output'];
};

export type PushNotificationConnection = {
  __typename?: 'PushNotificationConnection';
  edges: Array<PushNotificationEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type PushNotificationEdge = {
  __typename?: 'PushNotificationEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<PushNotification>;
};

export type PushNotificationNotification = {
  __typename?: 'PushNotificationNotification';
  body?: Maybe<Scalars['String']['output']>;
  tag?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  webIcon?: Maybe<Scalars['String']['output']>;
};

export type PushNotificationRecipientInput = {
  edition?: InputMaybe<Scalars['String']['input']>;
  profile: Scalars['UUID']['input'];
  site?: InputMaybe<Scalars['UUID']['input']>;
};

export type PushTrackInput = {
  body: Scalars['String']['input'];
  link?: InputMaybe<Scalars['String']['input']>;
  siteUuid: Scalars['UUID']['input'];
  timestamp: Scalars['DateTime']['input'];
  title: Scalars['String']['input'];
};

export type QuantityLimit = {
  __typename?: 'QuantityLimit';
  available: Scalars['Int']['output'];
  limit: Scalars['Int']['output'];
};

/** Queries that are supported by the API. */
export type Query = {
  __typename?: 'Query';
  _helloDomain?: Maybe<Scalars['String']['output']>;
  /**
   * List of AccessOne credential setups that can be used as Virtual Credentials
   * Provider for specified integration or integration type
   */
  ac1AvailableVirtualCredentialsProviders: Array<Ac1CredentialSetup>;
  /** Exposed to setup access passes for company like auto-assignment and limit */
  ac1CompanySiteCredentialSetupConfigsFromAccessGroups: Array<Ac1CompanySiteCredentialSetupConfig>;
  /** Source for dropdowns in integration setup for access_passes types */
  ac1CredentialSetups: Array<Ac1CredentialSetup>;
  /** List of AccessOne integrations available for site */
  ac1Integrations: Array<Ac1Integration>;
  /** List of AccessOne providers */
  ac1Providers: Array<Ac1Provider>;
  /** List of AccessOne access groups available for site */
  accessGroups: Array<AccessGroup>;
  activities: ActivityConnection;
  activityFeedV2: Array<ActivityFeedResultV2>;
  /** @deprecated use bookableResourcesV2 */
  adminBookableResources: Array<BookableResource>;
  adminNotifications: AdminNotificationConnection;
  adminSearchDestinationsByClient: Array<Destination>;
  /** @deprecated Not used anymore */
  aggregations: Aggregations;
  aiChatSuggestions: Array<AiChatSuggestion>;
  allCommentsForContent: CommentConnection;
  allResponsesForPoll: PollResponseConnection;
  apartment?: Maybe<Apartment>;
  apartments: ApartmentConnection;
  apartmentsCSVForDestination: Scalars['String']['output'];
  article: Article;
  attributes: Array<Attribute>;
  authToUuid: Scalars['UUID']['output'];
  authmap: FullUser;
  barrierControlAccess: BarrierControlAccess;
  barrierControlAccessByName: BarrierControlAccess;
  barrierControlAccessValues: Array<Maybe<BarrierControlAccess>>;
  barrierControlAccesses: Array<Maybe<BarrierControlAccess>>;
  barrierControlConfig: BarrierControlConfig;
  barrierControlConfigCodeType: BarrierControlConfigCodeType;
  barrierControlConfigKeys: BarrierControlConfigKeys;
  barrierControlConfigPrefix?: Maybe<Scalars['String']['output']>;
  barrierControlConfigs: Array<BarrierControlConfig>;
  batchSessionTemplateOccurrences: Array<BatchSessionTemplateResults>;
  billingCustomer: BillingCustomer;
  bluepoint?: Maybe<BpQuery>;
  bookableResource: BookableResource;
  bookableResourceCounts: BookableResourceCounts;
  bookableResourceFeatures: Array<BookableResourceFeature>;
  bookableResourceFilterOptions: BookableResourceFilterOptions;
  bookableResourceProxyBookingRates: BookableResourceRates;
  bookableResourceRoomConfigurations: Array<BookableResourceRoomConfiguration>;
  bookableResourceTypes: Array<BookableResourceTypeV2>;
  /** @deprecated use bookableResourcesV2 */
  bookableResources: Array<BookableResource>;
  bookableResourcesV2: BookableResourceConnection;
  bookingBlockOuts: Array<BookingBlockOut>;
  bookingByUuid: Booking;
  bookingCharges: BookingChargesResult;
  bookingInvoiceContacts: Array<BookingInvoiceContact>;
  bookingSetting?: Maybe<BookingSetting>;
  bookingsReportReconciliation: BookingsReconciliationReport;
  building?: Maybe<Building>;
  /** @deprecated Use contentFeed instead */
  buildingInfoFeed: BuildingInfoConnection;
  buildingInfoPost: BuildingInfoPost;
  buildingInfoPostsForSite: BuildingInfoConnection;
  buildingLevel: BuildingLevel;
  buildingLevels: Array<BuildingLevel>;
  buildingPublic?: Maybe<BuildingPublic>;
  buildings: BuildingConnection;
  /** @deprecated No longer used */
  buildingsByPermissions: Array<Building>;
  categoriesForWorkRequestBuilding: Array<WorkRequestCategory>;
  checkEmail: EmailSearchResult;
  /** Get the checkin information by the check in token */
  checkInInfo?: Maybe<CheckInInfo>;
  checkInviteEmails: Array<Maybe<CheckInviteEmailResult>>;
  checkWhitelabelAppleAccount: Scalars['Boolean']['output'];
  client: Client;
  /** @deprecated Not used anymore */
  clientAggregations: Aggregations;
  clientPortfolioAggregations?: Maybe<SiteAggregations>;
  clients: Array<Client>;
  cmsArticle: CmsArticle;
  comment: Comment;
  commentsForContent: CommentConnection;
  /**
   * Retrieve all the available companies.
   * @deprecated Field no longer supported
   */
  companies: Array<Company>;
  companiesV2: CompanyV2Connection;
  companiesV2CSV: Scalars['String']['output'];
  companiesV2CSVForDestination: Scalars['String']['output'];
  /** Retrieve an individual company. */
  company?: Maybe<Company>;
  companyDestination: CompanyDestination;
  companyFlexLevelUuids: Array<Scalars['UUID']['output']>;
  companyFlexTenants: Array<FlexTenant>;
  companyIndustries: Array<CompanyIndustry>;
  companyLevelUuids: Array<Scalars['UUID']['output']>;
  companyV2?: Maybe<CompanyV2>;
  contentCollection?: Maybe<ContentCollection>;
  contentCollections: Array<ContentCollection>;
  contentFeed: ContentConnection;
  contentLikedByUser: Scalars['Boolean']['output'];
  contentLikes: LikeConnection;
  contentLookup?: Maybe<ContentUnion>;
  countCMSArticles: Scalars['Int']['output'];
  creditAccounts: Array<CreditAccount>;
  creditProvider?: Maybe<CreditProvider>;
  creditPurchasableItems: Array<CreditPurchasableItem>;
  creditStats: CreditStats;
  creditTransactionBreakdown: CreditTransactionBreakdown;
  creditTransactions: CreditTransactionConnection;
  creditTransactionsUsers: Array<User>;
  curatedFeed: ContentConnection;
  currentDestination?: Maybe<Destination>;
  /**
   * @deprecated unused. complain if that's not the case
   * @deprecated unused. complain if that's not the case
   */
  currentPortal: Portal;
  /** Return the checkin information from the provided checkin token. */
  customerCheckInInfo?: Maybe<CustomerCheckInInfo>;
  customerOrders: OrderConnection;
  customerSubscriptions: ProductSubscriptionConnection;
  deal?: Maybe<Deal>;
  dealVendors: DealVendorConnection;
  /** @deprecated Default destination no longer makes sense */
  defaultDestination?: Maybe<Destination>;
  destination: Destination;
  /**
   * Lookup a destination based on a URL.
   * @deprecated Use the global gateway for this endpoint instead.
   */
  destinationByUrl?: Maybe<Destination>;
  destinations?: Maybe<DestinationConnection>;
  destinationsWithoutWhitelabel: Array<Destination>;
  discount: Discount;
  discounts: DiscountConnection;
  documentV2: DocumentV2;
  /** @deprecated Use shouldAutoApproveRegistration instead */
  emailPassesCompanyCheck: Scalars['Boolean']['output'];
  emailToUuid: Scalars['UUID']['output'];
  estimateAudienceUserCount: Scalars['Int']['output'];
  /** @deprecated Use estimateUserCountV2 instead */
  estimateUserCount: Scalars['Int']['output'];
  estimateUserCountV2: Scalars['Int']['output'];
  eventCalendarFeed: Array<Occurrence>;
  eventOccurrence: Occurrence;
  featuredContentFeed: ContentConnection;
  feedback: Feedback;
  feedbackFeed: ContentConnection;
  filtersForSiteV2: Array<SegmentationFilterItem>;
  findAuth0UserByEmail: Array<Auth0User>;
  findOccurrenceByOrder?: Maybe<Occurrence>;
  findOccurrencesByOrder: Array<Occurrence>;
  flexOperators: Array<FlexOperator>;
  flexTenant: FlexTenant;
  flexTenantsForOperator: Array<FlexTenant>;
  forMeContentFeed: ContentConnection;
  gallagher?: Maybe<GallagherQuery>;
  generateRecurringDates: Array<Scalars['DateTime']['output']>;
  getCard?: Maybe<Card>;
  /** @deprecated renamed to way leader */
  getParkOfficeRedirectUrl: Scalars['String']['output'];
  getReceptionNote: ReceptionNote;
  getReceptionistNote: ReceptionistNote;
  getReceptionistNotes: Array<Maybe<ReceptionistNote>>;
  /** @deprecated Use getRoleV2 instead */
  getRole: PermissionsRole;
  getRoleV2: PermissionsRoleV2;
  /** @deprecated Use getRolesV2 instead */
  getRoles: Array<PermissionsRole>;
  getRolesV2?: Maybe<Array<PermissionsRoleV2>>;
  /** Retrieve an individual site */
  getSite: Site;
  getUsers: Array<User>;
  getWayLeaderRedirectUrl: Scalars['String']['output'];
  groups: Array<EndUserGroup>;
  hiddenFeedback: Array<Scalars['UUID']['output']>;
  installations: Array<Installation>;
  inviteForEmail?: Maybe<Invite>;
  invites: InviteConnection;
  irisContentSearch?: Maybe<Array<IrisSearchResult>>;
  irisPoll: Poll;
  /** @deprecated Use contentFeed instead */
  latestNewsFeed: NewsConnection;
  leases: Array<Lease>;
  level: Level;
  levels: Array<Level>;
  listBookings: Array<Booking>;
  listProfiles: ProfileConnection;
  localePreference: Scalars['String']['output'];
  locationsForWorkRequestBuilding: Array<WorkRequestLocation>;
  loggedInToBuildingEngines: Scalars['Boolean']['output'];
  matchingSegments: Array<Scalars['UUID']['output']>;
  matchingSegmentsV2: Array<Scalars['UUID']['output']>;
  membershipsForFlexTenant: FlexTenantMembershipConnection;
  mobileValidateBookingDiscount: MobileDiscountValidationResult;
  mobileValidateBookingDiscountV2: MobileDiscountValidationResult;
  mobileValidateDiscount: MobileDiscountValidationResult;
  multipleBookingCharges: Array<BookingChargesResult>;
  myBillingCustomers: Array<BillingCustomer>;
  myBooking: Booking;
  myBookingByReference: Booking;
  myBookingsList: BookingConnection;
  myCompaniesFlexTenants: Array<FlexTenant>;
  myCompanyUsers: ProfileConnection;
  myCreditAccounts: Array<CreditAccount>;
  myFlexBuildings: BuildingConnection;
  myFlexLevels: Array<BuildingLevel>;
  myFlexTenants: FlexTenantConnection;
  myManageableBookableResourceLocations: Array<CompanyBookableResourceDestination>;
  myOccurrencesAndSessions: OccurrenceAndSessionConnection;
  myPortfolios: Array<Portfolio>;
  /** @deprecated use bookableResourcesV2 */
  myResourceCatalogue: Array<BookableResource>;
  myRsvpdOccurrences: OccurrenceConnection;
  myWorkRequestBuildings: Array<WorkRequestBuilding>;
  myWorkRequestUser?: Maybe<WorkRequestUser>;
  myWorkRequestUsers: Array<WorkRequestUser>;
  myWorkRequests: WorkRequestConnection;
  myWorkRequestsStats: WorkRequestStats;
  /** @deprecated Use contentFeed instead */
  newsAndEventsFeed: NewsAndEventsConnection;
  newsContentFeed: ContentConnection;
  newsPost: NewsPost;
  /** Retrieves an event occurrence by the given UUID. */
  occurrence?: Maybe<Occurrence>;
  occurrences: OccurrenceConnection;
  occurrencesV2: OccurrenceAndSessionConnection;
  operationsCompany?: Maybe<OperationsCompany>;
  order?: Maybe<Order>;
  /** Public payment gateway ID */
  paymentGatewayPublicKey: Scalars['String']['output'];
  poll: Poll;
  /** Randomized popular products for the current site. if types is null only plain products are returned */
  popularProducts: Array<Product>;
  portfolio: Portfolio;
  /** @deprecated Use clientAggregations instead */
  portfolioAggregations: Aggregations;
  product?: Maybe<Product>;
  /** @deprecated unused. complain if that's not the case */
  products: ProductConnection;
  /** Retrieve an individual profile. If no uuid is specified, returns the logged in user's profile. */
  profile?: Maybe<Profile>;
  /** Retrieves all available profile fields. */
  profileFields: Array<ProfileField>;
  profileReadyChecks: ProfileReadyCheckSummary;
  /** get a list of profiles matching a user email */
  profileSearchByEmail?: Maybe<Profile>;
  /** Retrieve the profiles with the given UUIDs. */
  profiles: Array<Profile>;
  profilesByGroup: Array<Profile>;
  promotedHomeDiscounts: Array<Discount>;
  /**
   * public content query that serves requests of users from web-ng without having a login
   * takes a single shareableLinkId parameter
   */
  publicContentView: PublicContentUnion;
  publicVendors: VendorConnection;
  quickLink: QuickLink;
  quickLinks: Array<QuickLink>;
  /** Products that can be reordered. As line items. */
  reOrderableProducts: ReOrderableProductConnection;
  registeredUsersStatForSite: Scalars['Int']['output'];
  registrations: Array<RegistrationMetric>;
  reqMgt: ReqMgtQuery;
  /** @deprecated Use rolesICanGrant instead */
  rolesICanAssign: Array<PermissionsRole>;
  rolesICanGrant: Array<PermissionRoleResources>;
  rssCuratedFeed: ContentConnection;
  /** Retrieves saved push notifications. */
  savedPushNotifications: PushNotificationConnection;
  /** @deprecated Use savedSegmentV2 instead */
  savedSegment: SavedSegment;
  savedSegmentV2: SavedSegmentV2;
  /** @deprecated Use contentFeed instead */
  search: ContentConnection;
  /** @deprecated use bookableResourcesV2 */
  searchBookableResources: Array<BookableResource>;
  /** @deprecated use bookableResourcesV2 */
  searchBookableResourcesV2: Array<BookableResource>;
  searchCMSArticles: CmsArticleConnection;
  searchDestinations: Array<Destination>;
  searchDestinationsByClient: Array<Destination>;
  segmentationServiceCheck: Scalars['Boolean']['output'];
  segmentsForUser: Array<Scalars['UUID']['output']>;
  serviceContentFeed: ContentConnection;
  serviceContentLookup?: Maybe<ContentUnion>;
  serviceEventOccurrence: Occurrence;
  serviceUpcomingEventOccurrencesFeed: EventOccurrencesConnection;
  session?: Maybe<Session>;
  sessionTemplateOccurrences: Array<Occurrence>;
  /** Retrieves a session by the given UUID. */
  sessionV2?: Maybe<SessionV2>;
  sessionsByOrder: Array<SessionV2>;
  /**
   * Whether a user with the given email address should
   * be automatically approved for the given company at
   * at least one of the given destinations.
   */
  shouldAutoApproveRegistration: Scalars['Boolean']['output'];
  siteAggregations: AggregationsV2;
  siteAttributes: Array<SiteAttribute>;
  siteBooking?: Maybe<Booking>;
  siteBookingByReference?: Maybe<Booking>;
  siteBookingDiscountsList: BookingDiscountConnection;
  siteBookingsCalendar: Array<BookingsSiteCalendarInterval>;
  siteBookingsList: BookingConnection;
  siteCreditCardBookableResourceCount: Scalars['Int']['output'];
  siteGroups: Array<SiteGroup>;
  /** @deprecated Use siteRolesICanGrant instead */
  siteRolesICanAssign: Array<PermissionsRole>;
  siteRolesICanGrant: Array<PermissionRoleResources>;
  spaces: Array<Space>;
  statsProviderConfiguration?: Maybe<StatsProviderConfiguration>;
  subscription: ProductSubscription;
  swiftconnect?: Maybe<SwiftconnectQuery>;
  /**
   * @deprecated(reason: Management field, not for customers)
   * @deprecated unused. complain if that's not the case
   */
  taxRegionForPortal: TaxRegion;
  /**
   * @deprecated(reason: Management field, not for customers)
   * @deprecated Field no longer supported
   */
  taxRegions: Array<TaxRegion>;
  trendingContentFeed: ContentConnection;
  trendingContentFeedForCompany: ContentConnection;
  /** @deprecated Use contentFeed instead */
  upcomingEventOccurrencesFeed: EventOccurrencesConnection;
  upcomingEventsContentFeed: ContentConnection;
  user?: Maybe<FullUser>;
  userByUuid: FullUser;
  userCompaniesForSegmentsConnection: CompanyV2Connection;
  userIndexed: Scalars['Boolean']['output'];
  userMembershipsForFlexSite: Array<FlexTenantMembership>;
  users: UserConnection;
  /** @deprecated Use usersForSegmentsConnection instead */
  usersForSegment: Array<Profile>;
  /** @deprecated Use usersForSegmentsConnection instead */
  usersForSegments: Array<Profile>;
  usersForSegmentsConnection: ProfileConnection;
  /** @deprecated Use usersV4 */
  usersV2: UserConnectionV2;
  /** @deprecated Use usersV4 */
  usersV3: SegmentationUserConnection;
  usersV4: SegmentationUserConnection;
  /** @deprecated Use the more detailed versions on FullUser */
  uuidToAuthIds: Array<Scalars['String']['output']>;
  validateBooking: BookingCartOutput;
  validateBookingTimes: Array<ValidateBookingTimesResult>;
  validateBookingV2: BookingCartOutput;
  /** Price the cart etc */
  validateCart: CartOutput;
  validateDiscount: DiscountMutationResult;
  validateExternalSyncCalendarUrl: Scalars['Boolean']['output'];
  validateResetPasswordToken: ValidateResetPasswordResult;
  vendor?: Maybe<Vendor>;
  /** List all vendor categories that are associated with at least one live vendor. */
  vendorCategories: Array<VendorCategory>;
  vendorCategory?: Maybe<VendorCategory>;
  /** Returns either a vendor or a deal vendor */
  vendorUnion: VendorUnion;
  vendors: VendorConnection;
  vicinitee?: Maybe<Scalars['String']['output']>;
  viewCountForPosts: Array<PostViewCountResult>;
  viewer: User;
  /** Viewer has Permission */
  viewerHasPermission: Scalars['Boolean']['output'];
  /** @deprecated Use destination viewerRelations. */
  viewerRelations: ViewerRelations;
  visitorAllBuildingsReceptionsAvailable: Array<VisitorReception>;
  visitorAppointment: VisitorAppointment;
  visitorAppointmentBy?: Maybe<VisitorAppointment>;
  visitorAppointments: VisitorAppointmentConnection;
  visitorAppointmentsByReception: VisitorAppointmentConnection;
  /** Returns a list of all the companies that the current user may use when creating stand-alone hosts. */
  visitorCompanies: Array<Maybe<CompanyV2>>;
  /** Returns a list of all the users that the current user may use as as hosts on new appointments. */
  visitorHostSuggestions: Array<Maybe<Profile>>;
  visitorLogs: Array<Maybe<VisitorActivityHistory>>;
  visitorLogsByFilter: Array<Maybe<VisitorActivityHistory>>;
  visitorManagementSiteSettings: VisitorManagementSiteSettings;
  visitorReception: VisitorReception;
  visitorReceptions: VisitorReceptionConnection;
  visitorReceptionsAvailable: Array<VisitorReception>;
  visitorSuggestions: Array<VisitorSuggestion>;
  visitorTenantReceptionsByHost: Array<VisitorReception>;
  visitorTypes?: Maybe<Array<VisitorType>>;
  visitors: VisitorConnection;
  /** Returns a list of visitors for all receptions where user is receptionist. */
  visitorsByAllBuildingReceptions: VisitorConnection;
  /** Returns a list of visitors for reception desk screen. */
  visitorsByReception: VisitorConnection;
  vms: VmsQuery;
  whitelabelApp: Whitelabel;
  whitelabelApps: WhitelabelConnection;
  workRequest: WorkRequest;
  workRequestAttachments: Array<WorkRequestAttachment>;
  workRequestComments: Array<WorkRequestComment>;
};


/** Queries that are supported by the API. */
export type QueryAc1AvailableVirtualCredentialsProvidersArgs = {
  provider: Scalars['String']['input'];
  siteUuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryAc1CompanySiteCredentialSetupConfigsFromAccessGroupsArgs = {
  accessGroupUuids: Array<Scalars['UUID']['input']>;
  companyUuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryAc1CredentialSetupsArgs = {
  category?: InputMaybe<Ac1CredentialSetupCategory>;
  siteUuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryAc1IntegrationsArgs = {
  siteUuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryAccessGroupsArgs = {
  buildingUuid?: InputMaybe<Scalars['UUID']['input']>;
  kind?: InputMaybe<Ac1AccessGroupKind>;
  siteUuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryActivitiesArgs = {
  entityUuid: Scalars['UUID']['input'];
  page: PageOptions;
  sort?: InputMaybe<ActivitySort>;
};


/** Queries that are supported by the API. */
export type QueryActivityFeedV2Args = {
  current: Scalars['Boolean']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
};


/** Queries that are supported by the API. */
export type QueryAdminBookableResourcesArgs = {
  filters?: InputMaybe<BookableResourceFilters>;
};


/** Queries that are supported by the API. */
export type QueryAdminSearchDestinationsByClientArgs = {
  clientUuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryAggregationsArgs = {
  destinationUuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryAllCommentsForContentArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  contentUuid: Scalars['UUID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
};


/** Queries that are supported by the API. */
export type QueryAllResponsesForPollArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pollUuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryApartmentArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryApartmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  areVacant?: InputMaybe<Scalars['Boolean']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  destinationUuid?: InputMaybe<Scalars['UUID']['input']>;
  excludeDestination?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<ApartmentSort>;
};


/** Queries that are supported by the API. */
export type QueryApartmentsCsvForDestinationArgs = {
  destinationUuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryArticleArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryAuthToUuidArgs = {
  authId: Scalars['String']['input'];
};


/** Queries that are supported by the API. */
export type QueryAuthmapArgs = {
  authId: Scalars['String']['input'];
  connection?: InputMaybe<Scalars['String']['input']>;
};


/** Queries that are supported by the API. */
export type QueryBarrierControlAccessArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryBarrierControlAccessByNameArgs = {
  name: Scalars['String']['input'];
};


/** Queries that are supported by the API. */
export type QueryBarrierControlAccessValuesArgs = {
  buildingUuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryBarrierControlAccessesArgs = {
  siteUuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryBarrierControlConfigArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryBarrierControlConfigCodeTypeArgs = {
  buildingUuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryBarrierControlConfigKeysArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryBarrierControlConfigPrefixArgs = {
  buildingUuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryBarrierControlConfigsArgs = {
  siteUuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryBatchSessionTemplateOccurrencesArgs = {
  input: Array<BatchSessionTemplateOccurrenceInput>;
};


/** Queries that are supported by the API. */
export type QueryBillingCustomerArgs = {
  id: Scalars['ID']['input'];
};


/** Queries that are supported by the API. */
export type QueryBookableResourceArgs = {
  forAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  uuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryBookableResourceCountsArgs = {
  filters?: InputMaybe<BookableResourceFilters>;
  permissionFilters?: InputMaybe<BookableResourcePermissionFilters>;
};


/** Queries that are supported by the API. */
export type QueryBookableResourceFeaturesArgs = {
  featureType?: InputMaybe<BookableResourceFeatureType>;
  siteUuid?: InputMaybe<Scalars['UUID']['input']>;
};


/** Queries that are supported by the API. */
export type QueryBookableResourceFilterOptionsArgs = {
  permissionFilters?: InputMaybe<BookableResourcePermissionFilters>;
};


/** Queries that are supported by the API. */
export type QueryBookableResourceProxyBookingRatesArgs = {
  resourceUuid: Scalars['UUID']['input'];
  userUuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryBookableResourceRoomConfigurationsArgs = {
  siteUuid?: InputMaybe<Scalars['UUID']['input']>;
};


/** Queries that are supported by the API. */
export type QueryBookableResourceTypesArgs = {
  displayMode?: InputMaybe<BookableResourceTypeDisplayMode>;
  siteUuid?: InputMaybe<Scalars['UUID']['input']>;
};


/** Queries that are supported by the API. */
export type QueryBookableResourcesArgs = {
  destinationUuid: Scalars['UUID']['input'];
  excludeParentResources?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<BookableResourceStatus>;
};


/** Queries that are supported by the API. */
export type QueryBookableResourcesV2Args = {
  filters?: InputMaybe<BookableResourceFilters>;
  page?: InputMaybe<PageOptions>;
  permissionFilters?: InputMaybe<BookableResourcePermissionFilters>;
};


/** Queries that are supported by the API. */
export type QueryBookingBlockOutsArgs = {
  filter?: InputMaybe<BookingBlockOutsFilter>;
};


/** Queries that are supported by the API. */
export type QueryBookingByUuidArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryBookingChargesArgs = {
  existingBookingUuid?: InputMaybe<Scalars['UUID']['input']>;
  input: BookingChargesInput;
};


/** Queries that are supported by the API. */
export type QueryBookingSettingArgs = {
  site: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryBookingsReportReconciliationArgs = {
  filters: BookingsReconcilationReportFilters;
};


/** Queries that are supported by the API. */
export type QueryBuildingArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryBuildingInfoFeedArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


/** Queries that are supported by the API. */
export type QueryBuildingInfoPostArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryBuildingInfoPostsForSiteArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  siteUuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryBuildingLevelArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryBuildingLevelsArgs = {
  uuids: Array<Scalars['UUID']['input']>;
};


/** Queries that are supported by the API. */
export type QueryBuildingPublicArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryBuildingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  destinationUuid?: InputMaybe<Scalars['UUID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


/** Queries that are supported by the API. */
export type QueryCategoriesForWorkRequestBuildingArgs = {
  workRequestBuildingId: Scalars['ID']['input'];
};


/** Queries that are supported by the API. */
export type QueryCheckEmailArgs = {
  destination: Scalars['UUID']['input'];
  email: Scalars['String']['input'];
};


/** Queries that are supported by the API. */
export type QueryCheckInInfoArgs = {
  token: Scalars['String']['input'];
};


/** Queries that are supported by the API. */
export type QueryCheckInviteEmailsArgs = {
  destinationUuid: Scalars['UUID']['input'];
  emails: Array<Scalars['String']['input']>;
};


/** Queries that are supported by the API. */
export type QueryCheckWhitelabelAppleAccountArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryClientArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryClientAggregationsArgs = {
  clientUuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryClientPortfolioAggregationsArgs = {
  clientUuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryClientsArgs = {
  region?: InputMaybe<ClientRegion>;
};


/** Queries that are supported by the API. */
export type QueryCmsArticleArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryCommentArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryCommentsForContentArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  contentUuid: Scalars['UUID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  siteUuid?: InputMaybe<Scalars['UUID']['input']>;
};


/** Queries that are supported by the API. */
export type QueryCompaniesV2Args = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  buildingUuid?: InputMaybe<Scalars['UUID']['input']>;
  buildingUuids?: InputMaybe<Array<Scalars['UUID']['input']>>;
  destinationUuid?: InputMaybe<Scalars['UUID']['input']>;
  excludeDestination?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<CompanyV2Sort>;
};


/** Queries that are supported by the API. */
export type QueryCompaniesV2CsvArgs = {
  destinationUuid?: InputMaybe<Scalars['UUID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


/** Queries that are supported by the API. */
export type QueryCompaniesV2CsvForDestinationArgs = {
  destinationUuid?: InputMaybe<Scalars['UUID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


/** Queries that are supported by the API. */
export type QueryCompanyArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryCompanyDestinationArgs = {
  companyUuid: Scalars['UUID']['input'];
  destinationUuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryCompanyFlexLevelUuidsArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryCompanyFlexTenantsArgs = {
  companyUuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryCompanyLevelUuidsArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryCompanyV2Args = {
  uuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryContentCollectionArgs = {
  siteUuid?: InputMaybe<Scalars['UUID']['input']>;
  uuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryContentCollectionsArgs = {
  siteUuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryContentFeedArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters: ContentFilters;
  first?: InputMaybe<Scalars['Int']['input']>;
};


/** Queries that are supported by the API. */
export type QueryContentLikedByUserArgs = {
  contentUuid: Scalars['UUID']['input'];
  siteUuid?: InputMaybe<Scalars['UUID']['input']>;
  userUuid?: InputMaybe<Scalars['UUID']['input']>;
};


/** Queries that are supported by the API. */
export type QueryContentLikesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  contentUuid: Scalars['UUID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  siteUuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryContentLookupArgs = {
  id: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryCountCmsArticlesArgs = {
  filters: CmsSearchFilters;
  portfolio: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryCreditAccountsArgs = {
  hostUuid: Scalars['UUID']['input'];
  providerUuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryCreditProviderArgs = {
  providerUuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryCreditPurchasableItemsArgs = {
  providerUuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryCreditStatsArgs = {
  as: CreditStatsQueryRole;
  flexTenantUuids?: InputMaybe<Array<Scalars['UUID']['input']>>;
  monthOffset: Scalars['Int']['input'];
};


/** Queries that are supported by the API. */
export type QueryCreditTransactionBreakdownArgs = {
  transactionUuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryCreditTransactionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  as: CreditTransactionsQueryRole;
  filter?: InputMaybe<CreditTransactionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


/** Queries that are supported by the API. */
export type QueryCreditTransactionsUsersArgs = {
  as: CreditTransactionsQueryRole;
};


/** Queries that are supported by the API. */
export type QueryCuratedFeedArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  contentTypes: Array<CollectionContentType>;
  first?: InputMaybe<Scalars['Int']['input']>;
  uuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryCustomerCheckInInfoArgs = {
  token: Scalars['String']['input'];
};


/** Queries that are supported by the API. */
export type QueryCustomerOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  current?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<OrderStatus>;
};


/** Queries that are supported by the API. */
export type QueryCustomerSubscriptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  current: Scalars['Boolean']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
};


/** Queries that are supported by the API. */
export type QueryDealArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryDealVendorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


/** Queries that are supported by the API. */
export type QueryDefaultDestinationArgs = {
  userUuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryDestinationArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryDestinationByUrlArgs = {
  url: Scalars['String']['input'];
};


/** Queries that are supported by the API. */
export type QueryDestinationsArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  installation?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<DestinationType>;
  types?: InputMaybe<Array<DestinationType>>;
  uuids?: InputMaybe<Array<Scalars['UUID']['input']>>;
};


/** Queries that are supported by the API. */
export type QueryDiscountArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryDiscountsArgs = {
  filters?: InputMaybe<DiscountFilter>;
  pagination?: InputMaybe<DiscountPagination>;
};


/** Queries that are supported by the API. */
export type QueryDocumentV2Args = {
  global: Scalars['Boolean']['input'];
  key: Scalars['String']['input'];
  public: Scalars['Boolean']['input'];
};


/** Queries that are supported by the API. */
export type QueryEmailPassesCompanyCheckArgs = {
  companyUuid: Scalars['UUID']['input'];
  email: Scalars['String']['input'];
};


/** Queries that are supported by the API. */
export type QueryEmailToUuidArgs = {
  email: Scalars['String']['input'];
};


/** Queries that are supported by the API. */
export type QueryEstimateAudienceUserCountArgs = {
  input: Array<SaveSegmentV2Input>;
};


/** Queries that are supported by the API. */
export type QueryEstimateUserCountArgs = {
  input: SaveSegmentInput;
};


/** Queries that are supported by the API. */
export type QueryEstimateUserCountV2Args = {
  input: SaveSegmentV2Input;
};


/** Queries that are supported by the API. */
export type QueryEventCalendarFeedArgs = {
  from: Scalars['DateTime']['input'];
  restrictionLevel: ContentRestrictionLevel;
  until: Scalars['DateTime']['input'];
};


/** Queries that are supported by the API. */
export type QueryEventOccurrenceArgs = {
  occurrence: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryFeedbackArgs = {
  site: Scalars['UUID']['input'];
  uuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryFiltersForSiteV2Args = {
  segmentUuid?: InputMaybe<Scalars['UUID']['input']>;
  uuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryFindAuth0UserByEmailArgs = {
  email: Scalars['String']['input'];
};


/** Queries that are supported by the API. */
export type QueryFindOccurrenceByOrderArgs = {
  orderUuid: Scalars['String']['input'];
};


/** Queries that are supported by the API. */
export type QueryFindOccurrencesByOrderArgs = {
  orderUuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryFlexTenantArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryFlexTenantsForOperatorArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryGenerateRecurringDatesArgs = {
  appointmentUuid?: InputMaybe<Scalars['UUID']['input']>;
  settings: VisitorAppointmentRecurringInfoInput;
  timezone: Scalars['String']['input'];
};


/** Queries that are supported by the API. */
export type QueryGetCardArgs = {
  cardId: Scalars['String']['input'];
};


/** Queries that are supported by the API. */
export type QueryGetReceptionNoteArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryGetReceptionistNoteArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryGetReceptionistNotesArgs = {
  visitorUuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryGetRoleArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryGetRoleV2Args = {
  tuple: Scalars['String']['input'];
};


/** Queries that are supported by the API. */
export type QueryGetSiteArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryGetUsersArgs = {
  uuids: Array<Scalars['UUID']['input']>;
};


/** Queries that are supported by the API. */
export type QueryHiddenFeedbackArgs = {
  siteUuid: Scalars['UUID']['input'];
  userUuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryInviteForEmailArgs = {
  destinationUuid: Scalars['UUID']['input'];
  email: Scalars['String']['input'];
};


/** Queries that are supported by the API. */
export type QueryInvitesArgs = {
  destinationUuid?: InputMaybe<Scalars['UUID']['input']>;
  filter?: InputMaybe<InviteFilter>;
  page: PageOptions;
  sort?: InputMaybe<InviteSort>;
};


/** Queries that are supported by the API. */
export type QueryIrisContentSearchArgs = {
  filters: IrisContentSearchFilters;
};


/** Queries that are supported by the API. */
export type QueryIrisPollArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryLatestNewsFeedArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<NewsFilters>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


/** Queries that are supported by the API. */
export type QueryLeasesArgs = {
  destinationUuid: Scalars['UUID']['input'];
  includeCurrent?: InputMaybe<Scalars['Boolean']['input']>;
  includePast?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Queries that are supported by the API. */
export type QueryLevelArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryLevelsArgs = {
  destinationUuid?: InputMaybe<Scalars['UUID']['input']>;
};


/** Queries that are supported by the API. */
export type QueryListBookingsArgs = {
  filters: ListBookingsFilters;
};


/** Queries that are supported by the API. */
export type QueryListProfilesArgs = {
  filter?: InputMaybe<ProfileFilter>;
  page: PageOptions;
  siteUuid?: InputMaybe<Scalars['UUID']['input']>;
  siteUuids?: InputMaybe<Array<Scalars['UUID']['input']>>;
  sort?: InputMaybe<ProfileSort>;
};


/** Queries that are supported by the API. */
export type QueryLocalePreferenceArgs = {
  destinationUuid?: InputMaybe<Scalars['UUID']['input']>;
  useViewer?: InputMaybe<Scalars['Boolean']['input']>;
  userUuid?: InputMaybe<Scalars['UUID']['input']>;
};


/** Queries that are supported by the API. */
export type QueryLocationsForWorkRequestBuildingArgs = {
  workRequestBuildingId: Scalars['ID']['input'];
};


/** Queries that are supported by the API. */
export type QueryMatchingSegmentsArgs = {
  segmentUuid: Scalars['UUID']['input'];
  site: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryMatchingSegmentsV2Args = {
  segmentUuids: Array<Scalars['UUID']['input']>;
  site: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryMembershipsForFlexTenantArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  flexTenantUuid: Scalars['UUID']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
};


/** Queries that are supported by the API. */
export type QueryMobileValidateBookingDiscountArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
  items: Array<SessionLineItemInput>;
};


/** Queries that are supported by the API. */
export type QueryMobileValidateBookingDiscountV2Args = {
  code?: InputMaybe<Scalars['String']['input']>;
  input: ValidateBookingInput;
};


/** Queries that are supported by the API. */
export type QueryMobileValidateDiscountArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
  items: Array<OrderLineItemInput>;
};


/** Queries that are supported by the API. */
export type QueryMultipleBookingChargesArgs = {
  existingBookingUuid?: InputMaybe<Scalars['UUID']['input']>;
  input: Array<BookingChargesInput>;
};


/** Queries that are supported by the API. */
export type QueryMyBillingCustomersArgs = {
  flexTenantUuids?: InputMaybe<Array<Scalars['UUID']['input']>>;
};


/** Queries that are supported by the API. */
export type QueryMyBookingArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryMyBookingByReferenceArgs = {
  reference: Scalars['String']['input'];
};


/** Queries that are supported by the API. */
export type QueryMyBookingsListArgs = {
  after?: InputMaybe<Scalars['DateTime']['input']>;
  before?: InputMaybe<Scalars['DateTime']['input']>;
  page?: InputMaybe<PageOptions>;
  sort?: InputMaybe<BookingSortOrder>;
};


/** Queries that are supported by the API. */
export type QueryMyCompanyUsersArgs = {
  page: PageOptions;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};


/** Queries that are supported by the API. */
export type QueryMyCreditAccountsArgs = {
  providerUuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryMyFlexBuildingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


/** Queries that are supported by the API. */
export type QueryMyFlexLevelsArgs = {
  building: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryMyFlexTenantsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<FlexTenantFilters>;
  first?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


/** Queries that are supported by the API. */
export type QueryMyManageableBookableResourceLocationsArgs = {
  companyUuid?: InputMaybe<Scalars['UUID']['input']>;
};


/** Queries that are supported by the API. */
export type QueryMyOccurrencesAndSessionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  current?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


/** Queries that are supported by the API. */
export type QueryMyResourceCatalogueArgs = {
  filters?: InputMaybe<BookableResourceFilters>;
  permissionFilters?: InputMaybe<BookableResourcePermissionFilters>;
};


/** Queries that are supported by the API. */
export type QueryMyRsvpdOccurrencesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  current?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


/** Queries that are supported by the API. */
export type QueryMyWorkRequestsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<WorkRequestFilters>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


/** Queries that are supported by the API. */
export type QueryNewsAndEventsFeedArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<ContentFilters>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


/** Queries that are supported by the API. */
export type QueryNewsContentFeedArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


/** Queries that are supported by the API. */
export type QueryNewsPostArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryOccurrenceArgs = {
  uuid: Scalars['String']['input'];
};


/** Queries that are supported by the API. */
export type QueryOccurrencesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter: OccurrenceFilter;
  first?: InputMaybe<Scalars['Int']['input']>;
};


/** Queries that are supported by the API. */
export type QueryOccurrencesV2Args = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter: OccurrenceFilter;
  first?: InputMaybe<Scalars['Int']['input']>;
};


/** Queries that are supported by the API. */
export type QueryOperationsCompanyArgs = {
  companyUuid?: InputMaybe<Scalars['UUID']['input']>;
};


/** Queries that are supported by the API. */
export type QueryOrderArgs = {
  input: OrderIdInput;
};


/** Queries that are supported by the API. */
export type QueryPollArgs = {
  site: Scalars['UUID']['input'];
  uuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryPopularProductsArgs = {
  types?: InputMaybe<Array<ProductType>>;
};


/** Queries that are supported by the API. */
export type QueryPortfolioArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryPortfolioAggregationsArgs = {
  destinationUuids: Array<Scalars['UUID']['input']>;
};


/** Queries that are supported by the API. */
export type QueryProductArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProductFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


/** Queries that are supported by the API. */
export type QueryProfileArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


/** Queries that are supported by the API. */
export type QueryProfileReadyChecksArgs = {
  siteUuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryProfileSearchByEmailArgs = {
  email: Scalars['Email']['input'];
};


/** Queries that are supported by the API. */
export type QueryProfilesArgs = {
  uuids: Array<Scalars['UUID']['input']>;
};


/** Queries that are supported by the API. */
export type QueryProfilesByGroupArgs = {
  group: Scalars['UUID']['input'];
  siteUuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryPublicContentViewArgs = {
  shareableLinkId: Scalars['String']['input'];
};


/** Queries that are supported by the API. */
export type QueryPublicVendorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  site: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryQuickLinkArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryReOrderableProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


/** Queries that are supported by the API. */
export type QueryRegisteredUsersStatForSiteArgs = {
  companyUuid?: InputMaybe<Scalars['UUID']['input']>;
  periodInDays: Scalars['Int']['input'];
  siteUuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryRegistrationsArgs = {
  end: Scalars['String']['input'];
  start: Scalars['String']['input'];
};


/** Queries that are supported by the API. */
export type QueryRolesICanGrantArgs = {
  granteeCompanyUuid: Scalars['UUID']['input'];
  siteUuid?: InputMaybe<Scalars['UUID']['input']>;
};


/** Queries that are supported by the API. */
export type QueryRssCuratedFeedArgs = {
  contentTypes: Array<CollectionContentType>;
  siteUuid: Scalars['UUID']['input'];
  uuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QuerySavedPushNotificationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: Scalars['Int']['input'];
  page?: InputMaybe<PageOptions>;
};


/** Queries that are supported by the API. */
export type QuerySavedSegmentArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QuerySavedSegmentV2Args = {
  uuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QuerySearchArgs = {
  contentTypes: Array<SearchContentType>;
  term: Scalars['String']['input'];
};


/** Queries that are supported by the API. */
export type QuerySearchBookableResourcesArgs = {
  date?: InputMaybe<Scalars['DateTime']['input']>;
  destinationUuid: Scalars['UUID']['input'];
  maxCapacity?: InputMaybe<Scalars['Int']['input']>;
  minCapacity?: InputMaybe<Scalars['Int']['input']>;
  typeUuid?: InputMaybe<Scalars['UUID']['input']>;
};


/** Queries that are supported by the API. */
export type QuerySearchBookableResourcesV2Args = {
  filters?: InputMaybe<BookableResourceFilters>;
};


/** Queries that are supported by the API. */
export type QuerySearchCmsArticlesArgs = {
  filters: CmsSearchFilters;
  page: PageOptions;
  portfolio: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QuerySearchDestinationsArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  installation?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  searchClientName?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<DestinationType>;
  types?: InputMaybe<Array<DestinationType>>;
};


/** Queries that are supported by the API. */
export type QuerySearchDestinationsByClientArgs = {
  clientUuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QuerySegmentsForUserArgs = {
  site: Scalars['UUID']['input'];
  uuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryServiceContentFeedArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters: ContentFilters;
  first?: InputMaybe<Scalars['Int']['input']>;
  site: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryServiceContentLookupArgs = {
  id: Scalars['UUID']['input'];
  site: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryServiceEventOccurrenceArgs = {
  occurrence: Scalars['UUID']['input'];
  site: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryServiceUpcomingEventOccurrencesFeedArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters: EventOccurrenceFilters;
  first?: InputMaybe<Scalars['Int']['input']>;
  site: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QuerySessionArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QuerySessionTemplateOccurrencesArgs = {
  bookableProductUuid: Scalars['UUID']['input'];
  endDate: Scalars['DateTime']['input'];
  startDate: Scalars['DateTime']['input'];
};


/** Queries that are supported by the API. */
export type QuerySessionV2Args = {
  uuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QuerySessionsByOrderArgs = {
  orderUuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryShouldAutoApproveRegistrationArgs = {
  companyUuid: Scalars['UUID']['input'];
  destinationUuids: Array<Scalars['UUID']['input']>;
  email: Scalars['String']['input'];
};


/** Queries that are supported by the API. */
export type QuerySiteAttributesArgs = {
  destinationUuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QuerySiteBookingArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QuerySiteBookingByReferenceArgs = {
  reference: Scalars['String']['input'];
};


/** Queries that are supported by the API. */
export type QuerySiteBookingDiscountsListArgs = {
  page?: InputMaybe<PageOptions>;
};


/** Queries that are supported by the API. */
export type QuerySiteBookingsCalendarArgs = {
  buildingUuid?: InputMaybe<Array<Scalars['String']['input']>>;
  date: Scalars['DateTime']['input'];
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  levelUuid?: InputMaybe<Array<Scalars['String']['input']>>;
  maxBookingsPerDay?: InputMaybe<Scalars['Int']['input']>;
  resourceName?: InputMaybe<Scalars['String']['input']>;
  resourceTypeUuid?: InputMaybe<Array<Scalars['UUID']['input']>>;
  siteUuid?: InputMaybe<Array<Scalars['UUID']['input']>>;
  skipCancelled?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Array<BookingStatus>>;
  userCompanyUuid?: InputMaybe<Array<Scalars['UUID']['input']>>;
};


/** Queries that are supported by the API. */
export type QuerySiteBookingsListArgs = {
  buildingUuid?: InputMaybe<Array<Scalars['String']['input']>>;
  date: Scalars['DateTime']['input'];
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  levelUuid?: InputMaybe<Array<Scalars['String']['input']>>;
  page?: InputMaybe<PageOptions>;
  resourceName?: InputMaybe<Scalars['String']['input']>;
  resourceTypeUuid?: InputMaybe<Array<Scalars['UUID']['input']>>;
  returnFullDays?: InputMaybe<Scalars['Boolean']['input']>;
  siteUuid?: InputMaybe<Array<Scalars['UUID']['input']>>;
  skipCancelled?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Array<BookingStatus>>;
  userCompanyUuid?: InputMaybe<Array<Scalars['UUID']['input']>>;
};


/** Queries that are supported by the API. */
export type QuerySiteGroupsArgs = {
  destinationUuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QuerySiteRolesICanGrantArgs = {
  granteeCompanyUuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QuerySpacesArgs = {
  uuids: Array<Scalars['UUID']['input']>;
};


/** Queries that are supported by the API. */
export type QueryStatsProviderConfigurationArgs = {
  siteUuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QuerySubscriptionArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryTaxRegionForPortalArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryTrendingContentFeedArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  contentTypes?: InputMaybe<Array<SearchContentType>>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


/** Queries that are supported by the API. */
export type QueryTrendingContentFeedForCompanyArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  contentTypes?: InputMaybe<Array<SearchContentType>>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


/** Queries that are supported by the API. */
export type QueryUpcomingEventOccurrencesFeedArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


/** Queries that are supported by the API. */
export type QueryUpcomingEventsContentFeedArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


/** Queries that are supported by the API. */
export type QueryUserArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryUserByUuidArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryUserCompaniesForSegmentsConnectionArgs = {
  overrideUnsubscribes?: InputMaybe<Scalars['Boolean']['input']>;
  page: PageOptions;
  uuids: Array<Scalars['UUID']['input']>;
};


/** Queries that are supported by the API. */
export type QueryUserIndexedArgs = {
  siteUuid: Scalars['UUID']['input'];
  userUuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryUserMembershipsForFlexSiteArgs = {
  siteUuid: Scalars['UUID']['input'];
  userUuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryUsersArgs = {
  destinationUuid?: InputMaybe<Scalars['UUID']['input']>;
  filter?: InputMaybe<UserFilter>;
  page: PageOptions;
  sort?: InputMaybe<UserSort>;
};


/** Queries that are supported by the API. */
export type QueryUsersForSegmentArgs = {
  overrideUnsubscribes?: InputMaybe<Scalars['Boolean']['input']>;
  uuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryUsersForSegmentsArgs = {
  overrideUnsubscribes?: InputMaybe<Scalars['Boolean']['input']>;
  uuids: Array<Scalars['UUID']['input']>;
};


/** Queries that are supported by the API. */
export type QueryUsersForSegmentsConnectionArgs = {
  overrideUnsubscribes?: InputMaybe<Scalars['Boolean']['input']>;
  page: PageOptions;
  uuids: Array<Scalars['UUID']['input']>;
};


/** Queries that are supported by the API. */
export type QueryUsersV2Args = {
  filter?: InputMaybe<UserFilterV2>;
  page: PageOptions;
  sort?: InputMaybe<UserSortV2>;
};


/** Queries that are supported by the API. */
export type QueryUsersV3Args = {
  filter?: InputMaybe<UserFilterV2>;
  page: PageOptions;
  sort?: InputMaybe<UserSortV2>;
};


/** Queries that are supported by the API. */
export type QueryUsersV4Args = {
  filter?: InputMaybe<UserFilterV2>;
  page: PageOptions;
  sort?: InputMaybe<UserSortV2>;
};


/** Queries that are supported by the API. */
export type QueryUuidToAuthIdsArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryValidateBookingArgs = {
  input: Array<SessionLineItemInput>;
};


/** Queries that are supported by the API. */
export type QueryValidateBookingTimesArgs = {
  input: ValidateBookingTimesInput;
};


/** Queries that are supported by the API. */
export type QueryValidateBookingV2Args = {
  input: ValidateBookingInput;
};


/** Queries that are supported by the API. */
export type QueryValidateCartArgs = {
  input: CartInput;
};


/** Queries that are supported by the API. */
export type QueryValidateDiscountArgs = {
  code: Scalars['String']['input'];
  items: Array<OrderLineItemInput>;
};


/** Queries that are supported by the API. */
export type QueryValidateExternalSyncCalendarUrlArgs = {
  externalSyncCalendarUrl: Scalars['String']['input'];
};


/** Queries that are supported by the API. */
export type QueryValidateResetPasswordTokenArgs = {
  token: Scalars['String']['input'];
};


/** Queries that are supported by the API. */
export type QueryVendorArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryVendorCategoryArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryVendorUnionArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryVendorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<VendorFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


/** Queries that are supported by the API. */
export type QueryViewCountForPostsArgs = {
  company?: InputMaybe<Scalars['UUID']['input']>;
  site: Scalars['UUID']['input'];
  uuids: Array<Scalars['UUID']['input']>;
};


/** Queries that are supported by the API. */
export type QueryViewerHasPermissionArgs = {
  permissionName: Role;
};


/** Queries that are supported by the API. */
export type QueryVisitorAllBuildingsReceptionsAvailableArgs = {
  filters: VisitorAllBuildingsReceptionsAvailableInput;
};


/** Queries that are supported by the API. */
export type QueryVisitorAppointmentArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryVisitorAppointmentByArgs = {
  input: VisitorAppointmentByInput;
};


/** Queries that are supported by the API. */
export type QueryVisitorAppointmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  as?: InputMaybe<VisitorAppointmentsQueryRole>;
  beginDate?: InputMaybe<Scalars['DateTime']['input']>;
  buildingUuids?: InputMaybe<Array<Scalars['UUID']['input']>>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hostCompanyUuids?: InputMaybe<Array<Scalars['UUID']['input']>>;
  isRecurring?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<VisitorAppointmentsSortInput>>;
  viewScope?: InputMaybe<AppointmentsViewScope>;
  visitorTypeUuids?: InputMaybe<Array<Scalars['UUID']['input']>>;
};


/** Queries that are supported by the API. */
export type QueryVisitorAppointmentsByReceptionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  beginDate?: InputMaybe<Scalars['DateTime']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  receptionUuid: Scalars['UUID']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  visitorStatus?: InputMaybe<VisitorStatus>;
};


/** Queries that are supported by the API. */
export type QueryVisitorCompaniesArgs = {
  filter?: InputMaybe<VisitorCompaniesFilter>;
};


/** Queries that are supported by the API. */
export type QueryVisitorHostSuggestionsArgs = {
  companyUuid?: InputMaybe<Scalars['UUID']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
};


/** Queries that are supported by the API. */
export type QueryVisitorLogsArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryVisitorLogsByFilterArgs = {
  filter: VisitorLogsFilter;
};


/** Queries that are supported by the API. */
export type QueryVisitorManagementSiteSettingsArgs = {
  siteUuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryVisitorReceptionArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryVisitorReceptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  as?: InputMaybe<VisitorRole>;
  first?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


/** Queries that are supported by the API. */
export type QueryVisitorReceptionsAvailableArgs = {
  appointmentUuid?: InputMaybe<Scalars['UUID']['input']>;
  as?: InputMaybe<VisitorRole>;
  companyUuid?: InputMaybe<Scalars['UUID']['input']>;
  receptionUuid?: InputMaybe<Scalars['UUID']['input']>;
  userUuid?: InputMaybe<Scalars['UUID']['input']>;
};


/** Queries that are supported by the API. */
export type QueryVisitorSuggestionsArgs = {
  beginDate?: InputMaybe<Scalars['DateTime']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};


/** Queries that are supported by the API. */
export type QueryVisitorTenantReceptionsByHostArgs = {
  buildingUuid?: InputMaybe<Scalars['UUID']['input']>;
  companyUuid?: InputMaybe<Scalars['UUID']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


/** Queries that are supported by the API. */
export type QueryVisitorTypesArgs = {
  siteUuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryVisitorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


/** Queries that are supported by the API. */
export type QueryVisitorsByAllBuildingReceptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  beginDate?: InputMaybe<Scalars['DateTime']['input']>;
  buildingUuid?: InputMaybe<Scalars['UUID']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeCardId?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  siteUuid: Scalars['UUID']['input'];
  updatedSince?: InputMaybe<Scalars['DateTime']['input']>;
  visitorStatus?: InputMaybe<VisitorStatus>;
};


/** Queries that are supported by the API. */
export type QueryVisitorsByReceptionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  beginDate?: InputMaybe<Scalars['DateTime']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeCardId?: InputMaybe<Scalars['Boolean']['input']>;
  receptionUuid: Scalars['UUID']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  updatedSince?: InputMaybe<Scalars['DateTime']['input']>;
  visitorStatus?: InputMaybe<VisitorStatus>;
};


/** Queries that are supported by the API. */
export type QueryWhitelabelAppArgs = {
  uuid: Scalars['UUID']['input'];
};


/** Queries that are supported by the API. */
export type QueryWhitelabelAppsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


/** Queries that are supported by the API. */
export type QueryWorkRequestArgs = {
  id: Scalars['ID']['input'];
};


/** Queries that are supported by the API. */
export type QueryWorkRequestAttachmentsArgs = {
  workRequestId: Scalars['ID']['input'];
};


/** Queries that are supported by the API. */
export type QueryWorkRequestCommentsArgs = {
  workRequestId: Scalars['ID']['input'];
};

export type QuestionResponse = {
  __typename?: 'QuestionResponse';
  optionUuid?: Maybe<Scalars['UUID']['output']>;
  response: Scalars['String']['output'];
  uuid: Scalars['UUID']['output'];
};

export type QuickLink = {
  __typename?: 'QuickLink';
  iconUrl?: Maybe<Scalars['String']['output']>;
  iconUrlDark?: Maybe<Scalars['String']['output']>;
  iconUrlLight?: Maybe<Scalars['String']['output']>;
  iconUrlPrimaryColor?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
  uuid: Scalars['UUID']['output'];
};

export type QuickLinkInput = {
  contentUuid: Scalars['UUID']['input'];
  iconUrl?: InputMaybe<Scalars['String']['input']>;
  link: LinkInput;
  order?: InputMaybe<Scalars['Int']['input']>;
  portalPostUuid: Scalars['UUID']['input'];
  /**
   * Iris incorrectly sends this timezone scoped timestamp as if it is in UTC.
   * Eg. 10:00:00AM Australia/Melbourne time is sent as 10:00:00AM UTC.
   * publishDateInUtc will contain the correct utc timestamp.
   */
  publishDate: Scalars['DateTime']['input'];
  publishDateInUtc: Scalars['DateTime']['input'];
  published: Scalars['Boolean']['input'];
  segmentIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  site: Scalars['UUID']['input'];
};

export type RateWorkRequestInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  rating: Scalars['Int']['input'];
  workRequestId: Scalars['ID']['input'];
};

export type ReOrderableProductConnection = {
  __typename?: 'ReOrderableProductConnection';
  edges: Array<Maybe<ReOrderableProductEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ReOrderableProductEdge = {
  __typename?: 'ReOrderableProductEdge';
  cursor: Scalars['String']['output'];
  inStock: Scalars['Boolean']['output'];
  isProductCurrent: Scalars['Boolean']['output'];
  node?: Maybe<LineItem>;
  vendorOpen: Scalars['Boolean']['output'];
};

export type ReceptionNote = {
  __typename?: 'ReceptionNote';
  date: Scalars['DateTime']['output'];
  profile?: Maybe<Profile>;
  uuid: Scalars['UUID']['output'];
  value: Scalars['String']['output'];
  visitorUuid: Scalars['UUID']['output'];
};

export type ReceptionNoteInput = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
  value: Scalars['String']['input'];
  visitorUuid: Scalars['UUID']['input'];
};

export type ReceptionistNote = {
  __typename?: 'ReceptionistNote';
  date: Scalars['DateTime']['output'];
  profile?: Maybe<Profile>;
  uuid: Scalars['UUID']['output'];
  value: Scalars['String']['output'];
  viewed: Scalars['Boolean']['output'];
  visitorUuid: Scalars['UUID']['output'];
};

export type ReceptionistNoteInput = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
  value: Scalars['String']['input'];
  visitorUuid: Scalars['UUID']['input'];
};

export type ReconciliationReportBooking = {
  __typename?: 'ReconciliationReportBooking';
  addOnsPrice: Scalars['Int']['output'];
  adjustmentsTotalPrice: Scalars['Int']['output'];
  adminNote?: Maybe<Scalars['String']['output']>;
  allPartialRefundsTotalPrice: Scalars['Int']['output'];
  buildingName?: Maybe<Scalars['String']['output']>;
  buildingUuid?: Maybe<Scalars['UUID']['output']>;
  cancellationPrice: Scalars['Int']['output'];
  cancelled: Scalars['Boolean']['output'];
  companyName?: Maybe<Scalars['String']['output']>;
  companyUuid?: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTime']['output'];
  currencyCode?: Maybe<Scalars['String']['output']>;
  destinationName?: Maybe<Scalars['String']['output']>;
  destinationUuid?: Maybe<Scalars['UUID']['output']>;
  discountNames: Array<Scalars['String']['output']>;
  discountTotalAmount: Scalars['Int']['output'];
  discountTotalPercentage: Scalars['Int']['output'];
  durationHours: Scalars['Float']['output'];
  invoiceContactEmail?: Maybe<Scalars['String']['output']>;
  invoiceContactName?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  originalTotalPrice?: Maybe<Scalars['Int']['output']>;
  paymentMethod?: Maybe<BookableResourcePaymentMethod>;
  reference: Scalars['String']['output'];
  resourceName?: Maybe<Scalars['String']['output']>;
  resourcePrice: Scalars['Int']['output'];
  resourceUuid?: Maybe<Scalars['UUID']['output']>;
  startDate: Scalars['DateTime']['output'];
  totalPrice: Scalars['Int']['output'];
  totalTax: Scalars['Int']['output'];
  userName?: Maybe<Scalars['String']['output']>;
  userUuid: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
  yardiAccountId?: Maybe<Scalars['String']['output']>;
  yardiAccrualId?: Maybe<Scalars['String']['output']>;
  yardiChecknumId?: Maybe<Scalars['String']['output']>;
  yardiOffsetId?: Maybe<Scalars['String']['output']>;
  yardiPropertyCode?: Maybe<Scalars['String']['output']>;
  yardiTenantCode?: Maybe<Scalars['String']['output']>;
  yardiType?: Maybe<Scalars['String']['output']>;
};

export type ReconciliationReportTotals = {
  __typename?: 'ReconciliationReportTotals';
  addOnsPrice: Scalars['Int']['output'];
  adjustmentsTotalPrice: Scalars['Int']['output'];
  allPartialRefundsTotalPrice: Scalars['Int']['output'];
  cancellationPrice: Scalars['Int']['output'];
  discountTotalAmount: Scalars['Int']['output'];
  durationHours: Scalars['Float']['output'];
  resourcePrice: Scalars['Int']['output'];
  totalPrice: Scalars['Int']['output'];
  totalTax: Scalars['Int']['output'];
};

/** Autogenerated input type of RefreshCredential */
export type RefreshCredentialInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of RefreshCredential. */
export type RefreshCredentialPayload = {
  __typename?: 'RefreshCredentialPayload';
  ac1HidOrigo?: Maybe<Ac1HidOrigo>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type RefundBookingItem = {
  amount: Scalars['Int']['input'];
  type: RefundBookingItemType;
  typeUuid: Scalars['UUID']['input'];
};

export type RefundBookingItemInput = {
  booking: Scalars['UUID']['input'];
  reason: Scalars['String']['input'];
  refunds: Array<RefundBookingItem>;
};

export type RefundBookingItemType =
  | 'AddOn'
  | 'Adjustment'
  | 'Booking';

/** A stripe failure that can happen when refunding on an order */
export type RefundFailure = IPaymentFailure & {
  __typename?: 'RefundFailure';
  code: PaymentFailureCodes;
  message?: Maybe<Scalars['String']['output']>;
};

export type RegistrationMetric = {
  __typename?: 'RegistrationMetric';
  property_id: Scalars['String']['output'];
  user_reference: Scalars['String']['output'];
  utc_time: Scalars['String']['output'];
  value: Scalars['Int']['output'];
};

export type RegistrationResponse = {
  __typename?: 'RegistrationResponse';
  login?: Maybe<LoginResponse>;
  registeredDestination: UserDestinationEdge;
  success: Scalars['Boolean']['output'];
  user: User;
};

export type RegistrationResponseV2 = FailedRegistrationV2Response | SuccessfulRegistrationV2Response;

export type RegistrationSettings = {
  __typename?: 'RegistrationSettings';
  commercialSignupCTA: Scalars['String']['output'];
  commercialSignupEnabled: Scalars['Boolean']['output'];
  emailVerificationRequired: Scalars['Boolean']['output'];
  residentialSignupCTA: Scalars['String']['output'];
  residentialSignupEnabled: Scalars['Boolean']['output'];
  visitorCanSubscribeToEmails: Scalars['Boolean']['output'];
  visitorCanSubscribeToNotifications: Scalars['Boolean']['output'];
  visitorSignupCTA: Scalars['String']['output'];
  visitorSignupEnabled: Scalars['Boolean']['output'];
};

export type RegistrationType =
  | 'COMMERCIAL'
  | 'RESIDENTIAL'
  | 'VISITOR';

export type RegistrationTypeForDestinationInput = {
  destination: Scalars['UUID']['input'];
  registrationType: RegistrationType;
  user: Scalars['UUID']['input'];
};

/** Autogenerated input type of RemoveCompanyConfig */
export type RemoveCompanyConfigInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyUuid: Scalars['UUID']['input'];
  siteUuid: Scalars['UUID']['input'];
};

/** Autogenerated return type of RemoveCompanyConfig. */
export type RemoveCompanyConfigPayload = {
  __typename?: 'RemoveCompanyConfigPayload';
  ac1Config?: Maybe<Ac1CompanySiteConfig>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of RemoveCredentialSetup */
export type RemoveCredentialSetupInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  credentialSetupUuid: Scalars['UUID']['input'];
  siteUuid: Scalars['UUID']['input'];
};

/** Autogenerated return type of RemoveCredentialSetup. */
export type RemoveCredentialSetupPayload = {
  __typename?: 'RemoveCredentialSetupPayload';
  ac1CredentialSetup?: Maybe<Ac1CredentialSetup>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of RemoveEmployee */
export type RemoveEmployeeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  uuid: Scalars['UUID']['input'];
};

/** Autogenerated return type of RemoveEmployee. */
export type RemoveEmployeePayload = {
  __typename?: 'RemoveEmployeePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  user?: Maybe<FullUser>;
};

export type RemoveRoleFromUserInput = {
  policyId: Scalars['UUID']['input'];
  userUuid: Scalars['UUID']['input'];
};

/** Autogenerated input type of RemoveVisitor */
export type RemoveVisitorInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  uuid: Scalars['UUID']['input'];
};

/** Autogenerated return type of RemoveVisitor. */
export type RemoveVisitorPayload = {
  __typename?: 'RemoveVisitorPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  visitor?: Maybe<Visitor>;
};

export type ReportResult = {
  __typename?: 'ReportResult';
  successful: Scalars['Boolean']['output'];
};

export type ReqMgtAttachmentInput = {
  filename: Scalars['String']['input'];
  key?: InputMaybe<Scalars['String']['input']>;
  temporaryUuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type ReqMgtAttachments = {
  __typename?: 'ReqMgtAttachments';
  key: Scalars['String']['output'];
};

export type ReqMgtBuildingsQueryRole =
  | 'REQUEST_MANAGER';

export type ReqMgtCafmConfiguration = {
  __typename?: 'ReqMgtCafmConfiguration';
  cafmProviderSettings?: Maybe<ReqMgtCafmProviderSettings>;
  categoryConfigurations: Array<ReqMgtCategoryCafmConfiguration>;
  created: Scalars['DateTime']['output'];
  credentialsInfo: ReqMgtCafmConfigurationCredentialsInfo;
  endpoint: Scalars['String']['output'];
  flexOperatorUuid?: Maybe<Scalars['UUID']['output']>;
  name: Scalars['String']['output'];
  siteUuid?: Maybe<Scalars['UUID']['output']>;
  type: ReqMgtCafmConfigurationType;
  updated: Scalars['DateTime']['output'];
  uuid: Scalars['UUID']['output'];
};

export type ReqMgtCafmConfigurationCredentialsInfo = {
  __typename?: 'ReqMgtCafmConfigurationCredentialsInfo';
  isCallbackConnectionSet: Scalars['Boolean']['output'];
  isPasswordSet: Scalars['Boolean']['output'];
  username?: Maybe<Scalars['String']['output']>;
};

export type ReqMgtCafmConfigurationInput = {
  cafmProviderSettings?: InputMaybe<ReqMgtCafmProviderSettingsInput>;
  endpoint: Scalars['String']['input'];
  flexOperatorUuid?: InputMaybe<Scalars['UUID']['input']>;
  name: Scalars['String']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  siteUuid?: InputMaybe<Scalars['UUID']['input']>;
  type: ReqMgtCafmConfigurationType;
  user?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type ReqMgtCafmConfigurationType =
  | 'ConceptEvolution'
  | 'Elogbooks'
  | 'Excell'
  | 'Facilio'
  | 'Maximo'
  | 'NGBailey'
  | 'Simpro';

export type ReqMgtCafmProviderSettings = {
  __typename?: 'ReqMgtCafmProviderSettings';
  conceptEvolutionCreateMethodName?: Maybe<Scalars['String']['output']>;
  conceptEvolutionUpdateMethodName?: Maybe<Scalars['String']['output']>;
  elogbooksPriorityName?: Maybe<Scalars['String']['output']>;
  elogbooksServiceProviderName?: Maybe<Scalars['String']['output']>;
  elogbooksSiteName?: Maybe<Scalars['String']['output']>;
  maximoCreateMethodName?: Maybe<Scalars['String']['output']>;
  maximoUpdateMethodName?: Maybe<Scalars['String']['output']>;
  simproSiteId?: Maybe<Scalars['Int']['output']>;
};

export type ReqMgtCafmProviderSettingsInput = {
  conceptEvolutionCreateMethodName?: InputMaybe<Scalars['String']['input']>;
  conceptEvolutionUpdateMethodName?: InputMaybe<Scalars['String']['input']>;
  elogbooksPriorityName?: InputMaybe<Scalars['String']['input']>;
  elogbooksServiceProviderName?: InputMaybe<Scalars['String']['input']>;
  elogbooksSiteName?: InputMaybe<Scalars['String']['input']>;
  maximoCreateMethodName?: InputMaybe<Scalars['String']['input']>;
  maximoUpdateMethodName?: InputMaybe<Scalars['String']['input']>;
  simproSiteId?: InputMaybe<Scalars['Int']['input']>;
};

export type ReqMgtCategory = {
  __typename?: 'ReqMgtCategory';
  buildings: Array<Maybe<Building>>;
  cafmConfigurations: Array<ReqMgtCategoryCafmConfiguration>;
  destination?: Maybe<Destination>;
  iconName: Scalars['String']['output'];
  name: Scalars['String']['output'];
  ownerCompany: CompanyV2;
  presetUuid?: Maybe<Scalars['UUID']['output']>;
  queue: ReqMgtQueue;
  subCategories: Array<ReqMgtSubCategory>;
  type: Scalars['UUID']['output'];
  uuid: Scalars['UUID']['output'];
};

export type ReqMgtCategoryCafmConfiguration = {
  __typename?: 'ReqMgtCategoryCafmConfiguration';
  buildings: Array<Building>;
  cafmCategoryName?: Maybe<Scalars['String']['output']>;
  cafmConfiguration: ReqMgtCafmConfiguration;
  category: ReqMgtCategory;
  siteUuid: Scalars['UUID']['output'];
  uuid: Scalars['UUID']['output'];
};

export type ReqMgtCategoryCafmConfigurationInput = {
  buildingUuids: Array<Scalars['UUID']['input']>;
  cafmConfigurationUuid: Scalars['UUID']['input'];
  categoryUuid: Scalars['UUID']['input'];
  providerCategoryName?: InputMaybe<Scalars['String']['input']>;
  siteUuid: Scalars['UUID']['input'];
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type ReqMgtCategoryPreset = {
  __typename?: 'ReqMgtCategoryPreset';
  categoryName: Scalars['String']['output'];
  iconName: Scalars['String']['output'];
  subCategoryNames: Array<Scalars['String']['output']>;
  type: Scalars['UUID']['output'];
  uuid: Scalars['UUID']['output'];
};

export type ReqMgtCategoryPresetFilterInput = {
  /** exclude presets already used in current site. default true */
  excludeUsed?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ReqMgtCategoryType = {
  __typename?: 'ReqMgtCategoryType';
  key: Scalars['String']['output'];
  uuid: Scalars['UUID']['output'];
};

export type ReqMgtCommentInput = {
  audience: ActivityAudience;
  comment: Scalars['String']['input'];
  requestUuid: Scalars['UUID']['input'];
};

export type ReqMgtCompanyPermissionInput = {
  everyoneCanOpenRequest?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ReqMgtCreateCategoryInput = {
  buildingUuids: Array<Scalars['UUID']['input']>;
  iconName: Scalars['String']['input'];
  name: Scalars['String']['input'];
  ownerCompanyUuid?: InputMaybe<Scalars['UUID']['input']>;
  queueUuid: Scalars['UUID']['input'];
  siteUuid: Scalars['UUID']['input'];
  type: Scalars['UUID']['input'];
};

export type ReqMgtCreateQueueInput = {
  assignees: Array<Scalars['UUID']['input']>;
  managers: Array<Scalars['UUID']['input']>;
  name: Scalars['String']['input'];
  ownerCompanyUuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type ReqMgtCreateStatusInput = {
  name: Scalars['String']['input'];
  ownerCompanyUuid?: InputMaybe<Scalars['UUID']['input']>;
  type: ReqMgtStatusType;
};

export type ReqMgtCreateSubCategoryInput = {
  categoryUuid: Scalars['UUID']['input'];
  name: Scalars['String']['input'];
};

export type ReqMgtDeleteAttachmentInput = {
  filename: Scalars['String']['input'];
  key: Scalars['String']['input'];
};

export type ReqMgtFiltersQueryRole =
  | 'FLEX_MANAGER'
  | 'PROPERTY_MANAGER'
  | 'REQUEST_ASSIGNEE'
  | 'REQUEST_MANAGER'
  | 'WORKPLACE_MANAGER';

export type ReqMgtFullRequestInput = {
  assignee?: InputMaybe<Scalars['UUID']['input']>;
  category?: InputMaybe<Scalars['UUID']['input']>;
  completed?: InputMaybe<Scalars['DateTime']['input']>;
  deleteAttachments?: InputMaybe<Array<ReqMgtDeleteAttachmentInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  persistAttachments?: InputMaybe<Array<ReqMgtAttachmentInput>>;
  priority?: InputMaybe<ReqMgtPriority>;
  reported?: InputMaybe<Scalars['DateTime']['input']>;
  reporter?: InputMaybe<Scalars['UUID']['input']>;
  space?: InputMaybe<Scalars['UUID']['input']>;
  status?: InputMaybe<Scalars['UUID']['input']>;
  subCategory?: InputMaybe<Scalars['UUID']['input']>;
  watchers?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type ReqMgtMutation = {
  __typename?: 'ReqMgtMutation';
  createCafmConfigurationCallbackConnectionCredentials: Scalars['String']['output'];
  createCategory: ReqMgtCategory;
  createComment: Scalars['Boolean']['output'];
  createFullRequest: ReqMgtRequest;
  createPartialRequest: ReqMgtRequest;
  createQueue: ReqMgtQueue;
  createStatus: ReqMgtStatus;
  createSubCategory: ReqMgtSubCategory;
  deleteCafmConfiguration: Scalars['Boolean']['output'];
  deleteCategory: Scalars['Boolean']['output'];
  deleteCategoryCafmConfiguration: Scalars['Boolean']['output'];
  deleteStatus: Scalars['Boolean']['output'];
  deleteSubCategory: Scalars['Boolean']['output'];
  resendCafmMessage: Scalars['Boolean']['output'];
  resolveRequest: ReqMgtRequest;
  seedCategory: ReqMgtCategory;
  seedStatuses: Scalars['Boolean']['output'];
  syncQueues: ReqMgtQueueSyncResponse;
  toggleQueueStatus: ReqMgtQueue;
  toggleWatch: ReqMgtRequest;
  updateCategory: ReqMgtCategory;
  updateCompanyPermissions: CompanyV2;
  updateQueue: ReqMgtQueue;
  updateRequest: ReqMgtRequest;
  updateStatus: ReqMgtStatus;
  updateStatusOrder: Scalars['Boolean']['output'];
  updateSubCategory: ReqMgtSubCategory;
  upsertCafmConfiguration: ReqMgtCafmConfiguration;
  upsertCategoryCafmConfiguration: ReqMgtCategoryCafmConfiguration;
};


export type ReqMgtMutationCreateCafmConfigurationCallbackConnectionCredentialsArgs = {
  uuid: Scalars['UUID']['input'];
};


export type ReqMgtMutationCreateCategoryArgs = {
  input: ReqMgtCreateCategoryInput;
};


export type ReqMgtMutationCreateCommentArgs = {
  input: ReqMgtCommentInput;
};


export type ReqMgtMutationCreateFullRequestArgs = {
  input: ReqMgtFullRequestInput;
};


export type ReqMgtMutationCreatePartialRequestArgs = {
  input: ReqMgtPartialRequestInput;
};


export type ReqMgtMutationCreateQueueArgs = {
  input: ReqMgtCreateQueueInput;
};


export type ReqMgtMutationCreateStatusArgs = {
  input: ReqMgtCreateStatusInput;
};


export type ReqMgtMutationCreateSubCategoryArgs = {
  input: ReqMgtCreateSubCategoryInput;
};


export type ReqMgtMutationDeleteCafmConfigurationArgs = {
  uuid: Scalars['UUID']['input'];
};


export type ReqMgtMutationDeleteCategoryArgs = {
  uuid: Scalars['UUID']['input'];
};


export type ReqMgtMutationDeleteCategoryCafmConfigurationArgs = {
  uuid: Scalars['UUID']['input'];
};


export type ReqMgtMutationDeleteStatusArgs = {
  uuid: Scalars['UUID']['input'];
};


export type ReqMgtMutationDeleteSubCategoryArgs = {
  uuid: Scalars['UUID']['input'];
};


export type ReqMgtMutationResendCafmMessageArgs = {
  requestUuid: Scalars['UUID']['input'];
};


export type ReqMgtMutationResolveRequestArgs = {
  uuid: Scalars['UUID']['input'];
};


export type ReqMgtMutationSeedCategoryArgs = {
  input: ReqMgtSeedCategoryInput;
};


export type ReqMgtMutationSeedStatusesArgs = {
  site: Scalars['UUID']['input'];
};


export type ReqMgtMutationSyncQueuesArgs = {
  chunkSize: Scalars['Int']['input'];
};


export type ReqMgtMutationToggleQueueStatusArgs = {
  active: Scalars['Boolean']['input'];
  uuid: Scalars['UUID']['input'];
};


export type ReqMgtMutationToggleWatchArgs = {
  requestUuid: Scalars['UUID']['input'];
};


export type ReqMgtMutationUpdateCategoryArgs = {
  input: ReqMgtUpdateCategoryInput;
  uuid: Scalars['UUID']['input'];
};


export type ReqMgtMutationUpdateCompanyPermissionsArgs = {
  companyUuid: Scalars['UUID']['input'];
  input?: InputMaybe<ReqMgtCompanyPermissionInput>;
};


export type ReqMgtMutationUpdateQueueArgs = {
  input: ReqMgtUpdateQueueInput;
  uuid: Scalars['UUID']['input'];
};


export type ReqMgtMutationUpdateRequestArgs = {
  input: ReqMgtFullRequestInput;
  uuid: Scalars['UUID']['input'];
  version?: InputMaybe<Scalars['DateTime']['input']>;
};


export type ReqMgtMutationUpdateStatusArgs = {
  input: ReqMgtUpdateStatusInput;
  uuid: Scalars['UUID']['input'];
};


export type ReqMgtMutationUpdateStatusOrderArgs = {
  uuids: Array<Scalars['UUID']['input']>;
};


export type ReqMgtMutationUpdateSubCategoryArgs = {
  input: ReqMgtUpdateSubCategoryInput;
  uuid: Scalars['UUID']['input'];
};


export type ReqMgtMutationUpsertCafmConfigurationArgs = {
  input: ReqMgtCafmConfigurationInput;
};


export type ReqMgtMutationUpsertCategoryCafmConfigurationArgs = {
  input: ReqMgtCategoryCafmConfigurationInput;
};

export type ReqMgtPartialRequestInput = {
  attachments: Array<ReqMgtAttachmentInput>;
  categoryUuid: Scalars['UUID']['input'];
  description: Scalars['String']['input'];
  spaceUuid: Scalars['UUID']['input'];
};

export type ReqMgtPriority =
  | 'HIGH'
  | 'LOW'
  | 'MEDIUM'
  | 'URGENT';

export type ReqMgtQuery = {
  __typename?: 'ReqMgtQuery';
  assignees: Array<Profile>;
  buildingLevels: Array<BuildingLevel>;
  buildings: Array<Building>;
  cafmConfiguration?: Maybe<ReqMgtCafmConfiguration>;
  cafmConfigurationsByFlexOperatorUuid: Array<ReqMgtCafmConfiguration>;
  cafmConfigurationsBySiteUuid: Array<ReqMgtCafmConfiguration>;
  categories: Array<ReqMgtCategory>;
  category: ReqMgtCategory;
  categoryCafmConfiguration: ReqMgtCategoryCafmConfiguration;
  categoryCafmConfigurationsByCategoryUuid: Array<ReqMgtCategoryCafmConfiguration>;
  categoryPresets: Array<ReqMgtCategoryPreset>;
  categoryTypes: Array<ReqMgtCategoryType>;
  queue: ReqMgtQueue;
  queues: Array<ReqMgtQueue>;
  request: ReqMgtRequest;
  requestFilterEntities: ReqMgtRequestFilterEntities;
  requests: ReqMgtRequestConnection;
  requestsStats: ReqMgtRequestsStats;
  status: ReqMgtStatus;
  statuses: Array<ReqMgtStatus>;
  subCategories: Array<ReqMgtSubCategory>;
  subCategory: ReqMgtSubCategory;
};


export type ReqMgtQueryAssigneesArgs = {
  buildingUuid: Scalars['UUID']['input'];
  queueUuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type ReqMgtQueryBuildingLevelsArgs = {
  buildingUuid: Scalars['UUID']['input'];
};


export type ReqMgtQueryBuildingsArgs = {
  as?: InputMaybe<ReqMgtBuildingsQueryRole>;
};


export type ReqMgtQueryCafmConfigurationArgs = {
  uuid: Scalars['UUID']['input'];
};


export type ReqMgtQueryCafmConfigurationsByFlexOperatorUuidArgs = {
  flexOperatorUuid: Scalars['UUID']['input'];
};


export type ReqMgtQueryCafmConfigurationsBySiteUuidArgs = {
  siteUuid: Scalars['UUID']['input'];
};


export type ReqMgtQueryCategoriesArgs = {
  buildingUuid?: InputMaybe<Scalars['UUID']['input']>;
  ownerCompanyUuid?: InputMaybe<Scalars['UUID']['input']>;
  queueUuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type ReqMgtQueryCategoryArgs = {
  uuid: Scalars['UUID']['input'];
};


export type ReqMgtQueryCategoryCafmConfigurationArgs = {
  uuid: Scalars['UUID']['input'];
};


export type ReqMgtQueryCategoryCafmConfigurationsByCategoryUuidArgs = {
  categoryUuid: Scalars['UUID']['input'];
};


export type ReqMgtQueryCategoryPresetsArgs = {
  filter?: InputMaybe<ReqMgtCategoryPresetFilterInput>;
};


export type ReqMgtQueryQueueArgs = {
  uuid: Scalars['UUID']['input'];
};


export type ReqMgtQueryQueuesArgs = {
  ownerCompanyUuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type ReqMgtQueryRequestArgs = {
  uuid: Scalars['UUID']['input'];
};


export type ReqMgtQueryRequestFilterEntitiesArgs = {
  as?: InputMaybe<ReqMgtFiltersQueryRole>;
};


export type ReqMgtQueryRequestsArgs = {
  filter?: InputMaybe<ReqMgtRequestsFilterInput>;
  page?: InputMaybe<PageOptions>;
  sort?: InputMaybe<ReqMgtRequestsSortInput>;
};


export type ReqMgtQueryRequestsStatsArgs = {
  filter: ReqMgtRequestsStatsFilterInput;
};


export type ReqMgtQueryStatusArgs = {
  uuid: Scalars['UUID']['input'];
};


export type ReqMgtQueryStatusesArgs = {
  ownerCompanyUuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type ReqMgtQuerySubCategoriesArgs = {
  categoryUuid: Scalars['UUID']['input'];
};


export type ReqMgtQuerySubCategoryArgs = {
  uuid: Scalars['UUID']['input'];
};

export type ReqMgtQueue = {
  __typename?: 'ReqMgtQueue';
  active: Scalars['Boolean']['output'];
  assignees: Array<Profile>;
  managers: Array<Profile>;
  name: Scalars['String']['output'];
  ownerCompany: CompanyV2;
  uuid: Scalars['UUID']['output'];
  viewerRelations: ReqMgtQueueViewerRelations;
};

export type ReqMgtQueueSyncResponse = {
  __typename?: 'ReqMgtQueueSyncResponse';
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type ReqMgtQueueViewerRelations = {
  __typename?: 'ReqMgtQueueViewerRelations';
  requestAssignee: Scalars['Boolean']['output'];
  requestManager: Scalars['Boolean']['output'];
};

export type ReqMgtRequest = {
  __typename?: 'ReqMgtRequest';
  assignee?: Maybe<Profile>;
  attachments?: Maybe<Array<DocumentV2>>;
  cafmRequestId?: Maybe<Scalars['String']['output']>;
  cafmSendAttempts?: Maybe<Scalars['Int']['output']>;
  category: ReqMgtCategory;
  completed?: Maybe<Scalars['DateTime']['output']>;
  created: Scalars['DateTime']['output'];
  creator?: Maybe<Profile>;
  description: Scalars['String']['output'];
  priority?: Maybe<ReqMgtPriority>;
  queue: ReqMgtQueue;
  reference: Scalars['String']['output'];
  reported: Scalars['DateTime']['output'];
  reporter?: Maybe<Profile>;
  reporterCompany?: Maybe<CompanyV2>;
  space?: Maybe<Space>;
  status: ReqMgtStatus;
  subCategory?: Maybe<ReqMgtSubCategory>;
  updated: Scalars['DateTime']['output'];
  updator?: Maybe<Profile>;
  uuid: Scalars['UUID']['output'];
  version: Scalars['DateTime']['output'];
  viewerRelations: ReqMgtRequestViewerRelations;
  watchedByMe: Scalars['Boolean']['output'];
  watchers: Array<Maybe<Profile>>;
};

export type ReqMgtRequestConnection = {
  __typename?: 'ReqMgtRequestConnection';
  edges: Array<ReqMgtRequestEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ReqMgtRequestEdge = {
  __typename?: 'ReqMgtRequestEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<ReqMgtRequest>;
};

export type ReqMgtRequestFilterEntities = {
  __typename?: 'ReqMgtRequestFilterEntities';
  assignees: Array<Profile>;
  buildings: Array<Building>;
  categories: Array<ReqMgtCategory>;
  companies: Array<CompanyV2>;
  reporters: Array<Profile>;
  statuses: Array<ReqMgtStatus>;
};

export type ReqMgtRequestPermissions = {
  __typename?: 'ReqMgtRequestPermissions';
  everyoneCanOpenRequest: Scalars['Boolean']['output'];
};

export type ReqMgtRequestViewerRelations = {
  __typename?: 'ReqMgtRequestViewerRelations';
  assignee: Scalars['Boolean']['output'];
  canManage: Scalars['Boolean']['output'];
  externalParticipant: Scalars['Boolean']['output'];
  internalParticipant: Scalars['Boolean']['output'];
  manager: Scalars['Boolean']['output'];
};

export type ReqMgtRequestsDateFilter = {
  end: Scalars['DateTime']['input'];
  start: Scalars['DateTime']['input'];
};

export type ReqMgtRequestsFilterInput = {
  assigneeUuids?: InputMaybe<Array<Scalars['UUID']['input']>>;
  buildingUuids?: InputMaybe<Array<Scalars['UUID']['input']>>;
  categoryUuids?: InputMaybe<Array<Scalars['UUID']['input']>>;
  created?: InputMaybe<ReqMgtRequestsDateFilter>;
  forMenuDisplay?: InputMaybe<ReqMgtRequestsDateFilter>;
  queueUuids?: InputMaybe<Array<Scalars['UUID']['input']>>;
  reporterCompanyUuids?: InputMaybe<Array<Scalars['UUID']['input']>>;
  reporterUuids?: InputMaybe<Array<Scalars['UUID']['input']>>;
  scope?: InputMaybe<ReqMgtRequestsFilterScope>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  statusUuid?: InputMaybe<Scalars['UUID']['input']>;
  statusUuids?: InputMaybe<Array<Scalars['UUID']['input']>>;
  unassigned?: InputMaybe<Scalars['Boolean']['input']>;
  watchedByUuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type ReqMgtRequestsFilterScope =
  | 'All'
  | 'Assigned'
  | 'My';

export type ReqMgtRequestsSortInput = {
  asc: Scalars['Boolean']['input'];
  field: ReqMgtSortField;
};

export type ReqMgtRequestsStats = {
  __typename?: 'ReqMgtRequestsStats';
  averageResolutionTime: Scalars['Int']['output'];
  completedRequests: Scalars['Int']['output'];
  inProgressRequests: Scalars['Int']['output'];
  newRequests: Scalars['Int']['output'];
  notStartedRequests: Scalars['Int']['output'];
  trends: ReqMgtRequestsTrends;
  unassignedRequests: Scalars['Int']['output'];
};

export type ReqMgtRequestsStatsFilterInput = {
  buildingUuid?: InputMaybe<Scalars['UUID']['input']>;
  companyUuid?: InputMaybe<Scalars['UUID']['input']>;
  lastDays: Scalars['Int']['input'];
};

export type ReqMgtRequestsTrends = {
  __typename?: 'ReqMgtRequestsTrends';
  activeByStatus: Scalars['Int']['output'];
  averageResolutionTime: Scalars['Int']['output'];
  completedRequests: Scalars['Int']['output'];
  newRequests: Scalars['Int']['output'];
};

export type ReqMgtRole =
  | 'ANON'
  | 'SERVICE'
  | 'USER';

export type ReqMgtSeedCategoryInput = {
  buildingUuids: Array<Scalars['UUID']['input']>;
  iconName: Scalars['String']['input'];
  ownerCompanyUuid?: InputMaybe<Scalars['UUID']['input']>;
  queueUuid: Scalars['UUID']['input'];
  siteUuid: Scalars['UUID']['input'];
  uuid: Scalars['UUID']['input'];
};

export type ReqMgtSortField =
  | 'CREATED'
  | 'PRIORITY'
  | 'REFERENCE'
  | 'UPDATED';

export type ReqMgtStatus = {
  __typename?: 'ReqMgtStatus';
  isDefault: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  ownerCompany: CompanyV2;
  type: ReqMgtStatusType;
  uuid: Scalars['UUID']['output'];
  weight: Scalars['Int']['output'];
};

export type ReqMgtStatusType =
  | 'COMPLETED'
  | 'IN_PROGRESS'
  | 'NOT_STARTED';

export type ReqMgtSubCategory = {
  __typename?: 'ReqMgtSubCategory';
  category: ReqMgtCategory;
  name: Scalars['String']['output'];
  uuid: Scalars['UUID']['output'];
};

export type ReqMgtUpdateCategoryInput = {
  buildingUuids: Array<Scalars['UUID']['input']>;
  iconName: Scalars['String']['input'];
  name: Scalars['String']['input'];
  presetUuid?: InputMaybe<Scalars['UUID']['input']>;
  siteUuid: Scalars['UUID']['input'];
};

export type ReqMgtUpdateQueueInput = {
  assignees: Array<Scalars['UUID']['input']>;
  managers: Array<Scalars['UUID']['input']>;
  name: Scalars['String']['input'];
};

export type ReqMgtUpdateStatusInput = {
  name: Scalars['String']['input'];
  type: ReqMgtStatusType;
};

export type ReqMgtUpdateSubCategoryInput = {
  name: Scalars['String']['input'];
};

export type Resource = {
  __typename?: 'Resource';
  id: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type ResourceAddOnInput = {
  mandatory: Scalars['Boolean']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Array<ResourceAddOnOptionInput>>;
  tooltipTxt?: InputMaybe<Scalars['String']['input']>;
  type: BookableResourceAddOnType;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type ResourceAddOnOptionInput = {
  hasQuantity: Scalars['Boolean']['input'];
  maxQuantity?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  unitPrice: Scalars['Int']['input'];
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type ResourceInput = {
  id: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type ResourceRoomConfigurationInput = {
  name: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type ResourceSettingsDeleteResult = {
  __typename?: 'ResourceSettingsDeleteResult';
  localisedReason: LocalisedMessage;
  success: Scalars['Boolean']['output'];
};

export type RestoreArchivedCmsArticleInput = {
  article: Scalars['UUID']['input'];
};

export type RetrySubscriptionChargeFailure = {
  __typename?: 'RetrySubscriptionChargeFailure';
  code: CheckoutFailureCodesV2;
  message?: Maybe<Scalars['String']['output']>;
};

export type RetrySubscriptionChargeOutput = RetrySubscriptionChargeFailure | RetrySubscriptionChargeSuccess;

export type RetrySubscriptionChargeSuccess = {
  __typename?: 'RetrySubscriptionChargeSuccess';
  paymentIntent?: Maybe<PaymentIntent>;
  subscription: ProductSubscription;
};

export type RevertDraftCmsArticleInput = {
  article: Scalars['UUID']['input'];
};

export type RewriteLeaseInput = {
  breakDate?: InputMaybe<Scalars['DateTime']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  businessNumberType?: InputMaybe<Scalars['String']['input']>;
  employeeCount?: InputMaybe<Scalars['Int']['input']>;
  expiryDate: Scalars['DateTime']['input'];
  name: Scalars['String']['input'];
  notificationDate?: InputMaybe<Scalars['DateTime']['input']>;
  size: Scalars['Int']['input'];
  startDate: Scalars['DateTime']['input'];
  uuid: Scalars['UUID']['input'];
};

export type Role =
  | 'AUTHENTICATED'
  | 'AccessAttendeeReport'
  | 'Guest'
  | 'SERVICE';

export type RsvpFailure = {
  __typename?: 'RsvpFailure';
  reason: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type RsvpMetricInput = {
  appVersion?: InputMaybe<Scalars['String']['input']>;
  applicationId: Scalars['String']['input'];
  bookableProductUuid?: InputMaybe<Scalars['UUID']['input']>;
  contentId: Scalars['String']['input'];
  contentTitle: Scalars['String']['input'];
  contentType: CortexEnricherContentType;
  id: Scalars['String']['input'];
  portalUuid: Scalars['String']['input'];
  timestamp: Scalars['DateTime']['input'];
  userAgent?: InputMaybe<Scalars['String']['input']>;
  userUuid: Scalars['String']['input'];
  value: AttendanceUpdate;
};

export type RsvpSuccess = {
  __typename?: 'RsvpSuccess';
  success: Scalars['Boolean']['output'];
};

export type SaveFiltersForApartmentInput = {
  name: Scalars['String']['input'];
  sites: Array<Scalars['UUID']['input']>;
  uuid: Scalars['UUID']['input'];
};

export type SaveFiltersForCompanyInput = {
  name: Scalars['String']['input'];
  sites: Array<Scalars['UUID']['input']>;
  uuid: Scalars['UUID']['input'];
};

export type SaveFiltersForPermissionInput = {
  name: Scalars['String']['input'];
  uuid: Scalars['UUID']['input'];
};

export type SaveSegmentInput = {
  apartments?: InputMaybe<Array<Scalars['UUID']['input']>>;
  areas?: InputMaybe<Array<Scalars['UUID']['input']>>;
  buildings?: InputMaybe<Array<Scalars['UUID']['input']>>;
  companies?: InputMaybe<Array<Scalars['UUID']['input']>>;
  companyAttributes?: InputMaybe<Array<Scalars['UUID']['input']>>;
  flexOperator?: InputMaybe<Array<Scalars['UUID']['input']>>;
  flexTenant?: InputMaybe<Array<Scalars['UUID']['input']>>;
  levels?: InputMaybe<Array<Scalars['UUID']['input']>>;
  permissionRoles?: InputMaybe<Array<Scalars['UUID']['input']>>;
  roles?: InputMaybe<Array<Scalars['UUID']['input']>>;
  site: Scalars['UUID']['input'];
  userAttributes?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type SaveSegmentV2Input = {
  filters: Array<SegmentFilterInput>;
  registrationTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  requiresMobileNumber?: InputMaybe<Scalars['Boolean']['input']>;
  requiresSubscribedToEmails?: InputMaybe<Scalars['Boolean']['input']>;
  requiresSubscribedToNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  site: Scalars['UUID']['input'];
};

export type SavedSegment = {
  __typename?: 'SavedSegment';
  areas?: Maybe<Array<Scalars['UUID']['output']>>;
  buildings?: Maybe<Array<Scalars['UUID']['output']>>;
  companies?: Maybe<Array<Scalars['UUID']['output']>>;
  companyAttributes?: Maybe<Array<Scalars['UUID']['output']>>;
  levels?: Maybe<Array<Scalars['UUID']['output']>>;
  queryString: Scalars['String']['output'];
  roles?: Maybe<Array<Scalars['UUID']['output']>>;
  userAttributes?: Maybe<Array<Scalars['UUID']['output']>>;
  uuid: Scalars['UUID']['output'];
};

export type SavedSegmentV2 = {
  __typename?: 'SavedSegmentV2';
  filters: Array<SegmentFilter>;
  queryString: Scalars['String']['output'];
  registrationTypes?: Maybe<Array<Scalars['String']['output']>>;
  uuid: Scalars['UUID']['output'];
};

export type ScheduledHoursInput = {
  from: Scalars['DateTime']['input'];
  to: Scalars['DateTime']['input'];
};

export type ScopesResponse = FailedScopeResponse | SuccessFullScopesResponse;

export type SearchContentType =
  | 'ARTICLE'
  | 'BOOKABLE_PRODUCT'
  | 'BOOKABLE_RESOURCE'
  | 'BUILDING_INFO_POST'
  | 'DEAL_PRODUCT'
  | 'DEAL_VENDOR'
  | 'EVENT_POST'
  | 'FEEDBACK'
  | 'NEWS_POST'
  | 'PLAIN_PRODUCT'
  | 'POLL'
  | 'QUICK_LINK'
  | 'SUBSCRIPTION_PRODUCT'
  | 'VENDOR';

export type Segment = {
  __typename?: 'Segment';
  segmentId: Scalars['String']['output'];
  segmentSummary: Scalars['String']['output'];
};

export type SegmentFilter = {
  __typename?: 'SegmentFilter';
  checkedItems: Array<Scalars['String']['output']>;
  order: Scalars['Int']['output'];
  type: SegmentationFilterItemType;
};

export type SegmentFilterInput = {
  checkedItems: Array<Scalars['String']['input']>;
  order: Scalars['Int']['input'];
  type: SegmentationFilterItemType;
};

export type SegmentationFilterItem = {
  __typename?: 'SegmentationFilterItem';
  checked: Scalars['Boolean']['output'];
  filterType: SegmentationFilterItemType;
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
  parents: Array<Scalars['UUID']['output']>;
  site: Destination;
  /** @deprecated Use key instead */
  uuid: Scalars['String']['output'];
};

export type SegmentationFilterItemInput = {
  checkedByDefault: Scalars['Boolean']['input'];
  filterType: SegmentationFilterItemType;
  name: Scalars['String']['input'];
  parents: Array<Scalars['UUID']['input']>;
  site: Scalars['UUID']['input'];
  uuid: Scalars['UUID']['input'];
};

export type SegmentationFilterItemType =
  | 'APARTMENT'
  | 'AREA'
  | 'BUILDING'
  | 'COMPANY'
  | 'COMPANY_ATTRIBUTE'
  | 'FLEX_OPERATOR'
  | 'FLEX_TENANT'
  | 'LEVEL'
  | 'PERMISSION_ROLE'
  | 'ROLE'
  | 'USER_ATTRIBUTE';

export type SegmentationRegistrationType =
  | 'COMMERCIAL'
  | 'RESIDENTIAL'
  | 'VISITOR';

export type SegmentationRole =
  | 'SERVICE'
  | 'USER';

export type SegmentationUserConnection = {
  __typename?: 'SegmentationUserConnection';
  edges: Array<SegmentationUserEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type SegmentationUserEdge = {
  __typename?: 'SegmentationUserEdge';
  authorizationRoles: Array<Scalars['String']['output']>;
  cursor: Scalars['String']['output'];
  node?: Maybe<FullUser>;
  siteUuid?: Maybe<Scalars['UUID']['output']>;
  userUuid: Scalars['UUID']['output'];
};

export type SendBatchPushNotificationInput = {
  from?: InputMaybe<Scalars['UUID']['input']>;
  message: MessageInput;
  options?: InputMaybe<SendPushNotificationOptionsInput>;
  to: BatchPushNotificationInput;
};

export type SendEmailFailure = {
  __typename?: 'SendEmailFailure';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type SendEmailResult = SendEmailFailure | SendEmailSuccess;

export type SendEmailSuccess = {
  __typename?: 'SendEmailSuccess';
  message: Scalars['String']['output'];
};

export type SendPushNotificationInput = {
  from?: InputMaybe<Scalars['UUID']['input']>;
  message: MessageInput;
  options?: InputMaybe<SendPushNotificationOptionsInput>;
  to: PushNotificationRecipientInput;
};

export type SendPushNotificationOptionsInput = {
  contentAvailable?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SendPushNotificationResult = {
  __typename?: 'SendPushNotificationResult';
  successCount: Scalars['Int']['output'];
};

/** Input for the SMS. */
export type SendSmsInput = {
  /** Alphanumeric sender id. See https://www.twilio.com/docs/sms/send-messages#alpha-sender-id */
  alphanumericSenderId?: InputMaybe<Scalars['String']['input']>;
  /** Sms body. */
  body: Scalars['String']['input'];
  /** Unique identifier for the User. */
  uuid: Scalars['UUID']['input'];
};

export type SendSitePushNotificationInput = {
  message: MessageInput;
  options?: InputMaybe<SendPushNotificationOptionsInput>;
  to: SitePushNotificationRecipientInput;
};

export type ServiceToken = {
  __typename?: 'ServiceToken';
  accessToken: Scalars['String']['output'];
  expiresIn: Scalars['DateTime']['output'];
};

/** Event session type. */
export type Session = {
  __typename?: 'Session';
  active: Scalars['Boolean']['output'];
  attendeeLimit?: Maybe<Scalars['Int']['output']>;
  bookableProduct?: Maybe<BookableProduct>;
  bookableProductUuid: Scalars['UUID']['output'];
  bookings: UserBookingConnection;
  colors?: Maybe<Array<Scalars['String']['output']>>;
  day: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  duration: Scalars['Int']['output'];
  endDate: Scalars['DateTime']['output'];
  hasBookingTerms: Scalars['Boolean']['output'];
  hasCapacity: Scalars['Boolean']['output'];
  hasUserAcceptedTermsAndConditions: Scalars['Boolean']['output'];
  location: Scalars['String']['output'];
  sessionTemplate?: Maybe<SessionTemplate>;
  sessionTemplateUuid: Scalars['UUID']['output'];
  siteUuids: Array<Scalars['String']['output']>;
  startDate: Scalars['DateTime']['output'];
  timezone: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
  totalBookings: Scalars['Int']['output'];
  userHasBooked?: Maybe<Scalars['Boolean']['output']>;
  uuid: Scalars['String']['output'];
};


/** Event session type. */
export type SessionBookingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type SessionCanceledResponse = {
  __typename?: 'SessionCanceledResponse';
  affectedOrderLineItemUuids: Array<Scalars['UUID']['output']>;
  affectedOrderUuids: Array<Scalars['UUID']['output']>;
};

export type SessionGroup = {
  __typename?: 'SessionGroup';
  day: Scalars['String']['output'];
  sessions: Array<SessionUnion>;
  timestamp: Scalars['DateTime']['output'];
  timezone: Scalars['String']['output'];
};

export type SessionHistoryV2 = {
  __typename?: 'SessionHistoryV2';
  pastSession: Scalars['Boolean']['output'];
  session?: Maybe<SessionV2>;
  sessionUuid: Scalars['UUID']['output'];
  timestamp: Scalars['DateTime']['output'];
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type SessionLineItem = {
  __typename?: 'SessionLineItem';
  bookableProductUuid: Scalars['String']['output'];
  end: Scalars['DateTime']['output'];
  sessionTemplateUuid: Scalars['UUID']['output'];
  start: Scalars['DateTime']['output'];
};

export type SessionLineItemInput = {
  attendees?: InputMaybe<Scalars['Int']['input']>;
  bookableProductUuid: Scalars['String']['input'];
  end: Scalars['DateTime']['input'];
  sessionTemplateUuid: Scalars['UUID']['input'];
  start: Scalars['DateTime']['input'];
};

export type SessionOrderInput = {
  coupon?: InputMaybe<Scalars['String']['input']>;
  customerComments?: InputMaybe<Scalars['String']['input']>;
  discountCode?: InputMaybe<Scalars['String']['input']>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  modChoices?: InputMaybe<Array<OrderModChoiceInput>>;
  orderLineItems: Array<SessionLineItemInput>;
  paymentGatewayCardId?: InputMaybe<Scalars['String']['input']>;
  paymentGatewayCustomerId?: InputMaybe<Scalars['String']['input']>;
  tip?: InputMaybe<Scalars['Int']['input']>;
};

export type SessionTemplate = {
  __typename?: 'SessionTemplate';
  capacity?: Maybe<Scalars['Int']['output']>;
  days: Array<DayOfTheWeek>;
  end: Scalars['Time']['output'];
  info?: Maybe<Scalars['String']['output']>;
  location: Scalars['String']['output'];
  start: Scalars['Time']['output'];
  timezone: Scalars['String']['output'];
  uuid: Scalars['UUID']['output'];
};

export type SessionUnion = PossibleSession | Session;

/** An Occurrence of a bookable product session template (replaces Session). */
export type SessionV2 = {
  __typename?: 'SessionV2';
  active: Scalars['Boolean']['output'];
  attendeeLimit?: Maybe<Scalars['Int']['output']>;
  bookableProduct?: Maybe<BookableProduct>;
  bookableProductUuid?: Maybe<Scalars['UUID']['output']>;
  bookedByMe: Scalars['Boolean']['output'];
  bookedOrderUuid?: Maybe<Scalars['String']['output']>;
  bookings: UserBookingV2Connection;
  colors?: Maybe<Array<Scalars['String']['output']>>;
  comments: CommentConnection;
  day: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  duration: Scalars['Int']['output'];
  endDate: Scalars['DateTime']['output'];
  hasBookingTerms: Scalars['Boolean']['output'];
  hasCapacity: Scalars['Boolean']['output'];
  isCancelable: Scalars['Boolean']['output'];
  isCheckInEnabled: Scalars['Boolean']['output'];
  likedByMe: Scalars['Boolean']['output'];
  location?: Maybe<Scalars['String']['output']>;
  pastEvent: Scalars['Boolean']['output'];
  sessionTemplate?: Maybe<SessionTemplate>;
  sessionTemplateUuid: Scalars['UUID']['output'];
  siteUuids: Array<Scalars['String']['output']>;
  startDate: Scalars['DateTime']['output'];
  termsAndConditionsAcceptedByMe: Scalars['Boolean']['output'];
  timezone: Scalars['String']['output'];
  title: Scalars['String']['output'];
  totalBookings: Scalars['Int']['output'];
  userBookingOrderLineItemUuid?: Maybe<Scalars['UUID']['output']>;
  userBookingOrderUuid?: Maybe<Scalars['UUID']['output']>;
  uuid: Scalars['UUID']['output'];
};


/** An Occurrence of a bookable product session template (replaces Session). */
export type SessionV2BookingsArgs = {
  activeOnly?: InputMaybe<Scalars['Boolean']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


/** An Occurrence of a bookable product session template (replaces Session). */
export type SessionV2CommentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


/** An Occurrence of a bookable product session template (replaces Session). */
export type SessionV2UserBookingOrderLineItemUuidArgs = {
  activeOnly?: InputMaybe<Scalars['Boolean']['input']>;
  site: Scalars['UUID']['input'];
  user: Scalars['UUID']['input'];
};


/** An Occurrence of a bookable product session template (replaces Session). */
export type SessionV2UserBookingOrderUuidArgs = {
  site: Scalars['UUID']['input'];
  user: Scalars['UUID']['input'];
};

export type SetRoleInput = {
  unsetRole?: InputMaybe<Scalars['Boolean']['input']>;
  userUuid: Scalars['UUID']['input'];
};

export type SetSitesForCmsArticleInput = {
  sites: Array<CmsSiteArticleInput>;
  uuid: Scalars['UUID']['input'];
};

export type ShortcutInput = {
  icon: Scalars['String']['input'];
  title: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type SignTemporaryUploadUrlResult = {
  __typename?: 'SignTemporaryUploadUrlResult';
  signedUrl: Scalars['URI']['output'];
  temporaryUuid: Scalars['UUID']['output'];
};

export type Site = {
  __typename?: 'Site';
  destination?: Maybe<Destination>;
  /** Profiles assigned to this site */
  profiles: SiteProfileConnection;
  /** Unique identifier for the site. */
  uuid: Scalars['UUID']['output'];
};


export type SiteProfilesArgs = {
  filters?: InputMaybe<SiteProfileFilters>;
  page: PageOptions;
};

export type SiteAggregations = {
  __typename?: 'SiteAggregations';
  activeUsers: SiteIntMetric;
  attendancePerUser: SiteFloatMetric;
  attendees: SiteIntMetric;
  avgClickRate: SiteFloatMetric;
  avgOpenRate: SiteFloatMetric;
  avgPageViewsPerUser: SiteFloatMetric;
  avgSpendPerUser: SiteFloatMetric;
  currentUsers: SiteIntMetric;
  newslettersSent: SiteIntMetric;
  pageViews: SiteIntMetric;
  registeredUsers: SiteIntMetric;
  totalRevenue: SiteFloatMetric;
};

export type SiteAttribute = {
  __typename?: 'SiteAttribute';
  name: Scalars['String']['output'];
  uuid: Scalars['UUID']['output'];
};

export type SiteAttributeResult = FailureResponse | SiteAttributeSuccessResponse;

export type SiteAttributeSuccessResponse = {
  __typename?: 'SiteAttributeSuccessResponse';
  siteAttribute: SiteAttribute;
};

export type SiteCompanyRelations = {
  __typename?: 'SiteCompanyRelations';
  canListUsers: Scalars['Boolean']['output'];
  canManage: Scalars['Boolean']['output'];
  canManageCompanyBookingSettings: Scalars['Boolean']['output'];
  canManageSettings: Scalars['Boolean']['output'];
  /** @deprecated Wrong name use canReportRequests. */
  canOpenRequests: Scalars['Boolean']['output'];
  canReportRequests: Scalars['Boolean']['output'];
};

export type SiteDiscountReport = {
  __typename?: 'SiteDiscountReport';
  discountTotal: Scalars['Int']['output'];
  discountTotalPreTax: Scalars['Int']['output'];
  discountTotalTax: Scalars['Int']['output'];
  orderCount: Scalars['Int']['output'];
  orderTotal: Scalars['Int']['output'];
  orderTotalPreTax: Scalars['Int']['output'];
  orderTotalTax: Scalars['Int']['output'];
  preDiscountOrderTotal: Scalars['Int']['output'];
  preDiscountOrderTotalPreTax: Scalars['Int']['output'];
  preDiscountOrderTotalTax: Scalars['Int']['output'];
};

export type SiteFloatMetric = {
  __typename?: 'SiteFloatMetric';
  current: Scalars['Float']['output'];
  lastMonth: Scalars['Float']['output'];
};

export type SiteGroup = {
  __typename?: 'SiteGroup';
  isCuratedForEquiemOne: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  uuid: Scalars['UUID']['output'];
};

export type SiteGroupResult = FailureResponse | SiteGroupSuccessResponse;

export type SiteGroupSuccessResponse = {
  __typename?: 'SiteGroupSuccessResponse';
  siteGroup: SiteGroup;
};

export type SiteIntMetric = {
  __typename?: 'SiteIntMetric';
  current: Scalars['Int']['output'];
  lastMonth: Scalars['Int']['output'];
};

/** Per-site profile data. */
export type SiteProfile = {
  __typename?: 'SiteProfile';
  apartment?: Maybe<ApartmentDestination>;
  attributes?: Maybe<Array<SiteAttribute>>;
  equiemOneShortcuts: Array<EquiemOneShortcut>;
  /** All available fields / values for this customer at this site. */
  fields: Array<ProfileFieldValue>;
  groups?: Maybe<Array<SiteGroup>>;
  isReceptionist?: Maybe<Scalars['Boolean']['output']>;
  profile: Profile;
  profileUuid: Scalars['UUID']['output'];
  /** Metrics the property manager has enabled for this site */
  propertyManagerMetrics: Array<PropertyManagerEquiemOneMetrics>;
  receptionCount?: Maybe<Scalars['Int']['output']>;
  registrationType: ProfileRegistrationType;
  site: Site;
  siteUuid: Scalars['UUID']['output'];
  /** Whether the customer is subscribed to emails at this site. */
  subscribedToEmails: Scalars['Boolean']['output'];
  /** Whether the customer is subscribed to marketing notifications at this site. */
  subscribedToNotifications: Scalars['Boolean']['output'];
};

export type SiteProfileConnection = {
  __typename?: 'SiteProfileConnection';
  edges: Array<SiteProfileEdge>;
  pageInfo: PageInfo;
  site: Site;
  totalCount: Scalars['Int']['output'];
};

export type SiteProfileEdge = {
  __typename?: 'SiteProfileEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<SiteProfile>;
};

export type SiteProfileFilters = {
  active: Scalars['Boolean']['input'];
  registrationType?: InputMaybe<ProfileRegistrationType>;
  subscribedToEmails?: InputMaybe<Scalars['Boolean']['input']>;
  subscribedToNotifications?: InputMaybe<Scalars['Boolean']['input']>;
};

/** A customer's profile. */
export type SiteProfileInput = {
  /** Uuids of site attributes associated with this site profile. */
  attributes?: InputMaybe<Array<Scalars['UUID']['input']>>;
  /** The customer's profile field values. Fields for which inputs are not provided are left untouched. */
  fields?: InputMaybe<Array<ProfileFieldValueInput>>;
  /** Uuids of site groups associated with this site profile. */
  groups?: InputMaybe<Array<Scalars['UUID']['input']>>;
  /**
   * Allow the consuming service to specify if this site profile update represents a sign up.
   * A newly created profile will receive the sign up email regardless of this flag.
   */
  isUserSignUp?: InputMaybe<Scalars['Boolean']['input']>;
  /** Unique identifier for the profile. */
  profileUUID: Scalars['UUID']['input'];
  registrationType?: InputMaybe<ProfileRegistrationType>;
  /** Unique identifier for the site. */
  siteUUID: Scalars['UUID']['input'];
  /** Whether or not the profile is subscribed to emails on the site. */
  subscribedToEmails: Scalars['Boolean']['input'];
};

/** A customer's profile. */
export type SiteProfileInputV2 = {
  /** Uuids of site attributes associated with this site profile. */
  attributes?: InputMaybe<Array<Scalars['UUID']['input']>>;
  /** The customer's profile field values. Fields for which inputs are not provided are left untouched. */
  fields?: InputMaybe<Array<ProfileFieldValueInput>>;
  /** Uuids of site groups associated with this site profile. */
  groups?: InputMaybe<Array<Scalars['UUID']['input']>>;
  /**
   * Allow the consuming service to specify if this site profile update represents a sign up.
   * A newly created profile will receive the sign up email regardless of this flag.
   */
  isUserSignUp?: InputMaybe<Scalars['Boolean']['input']>;
  /** Unique identifier for the profile. */
  profileUUID: Scalars['UUID']['input'];
  registrationType?: InputMaybe<ProfileRegistrationType>;
  /** Unique identifier for the site. */
  siteUUID: Scalars['UUID']['input'];
  /** Whether or not the profile is subscribed to emails on the site. */
  subscribedToEmails: Scalars['Boolean']['input'];
  /** Whether or not the profile is subscribed to marketing notifications on the site. */
  subscribedToNotifications: Scalars['Boolean']['input'];
};

export type SitePushNotificationRecipientInput = {
  edition?: InputMaybe<Scalars['String']['input']>;
  site: Scalars['UUID']['input'];
};

export type SiteRelations = {
  __typename?: 'SiteRelations';
  canListUsers: Scalars['Boolean']['output'];
  canManage: Scalars['Boolean']['output'];
  canManageFlexTenants: Scalars['Boolean']['output'];
  canManageSettings: Scalars['Boolean']['output'];
  company: SiteCompanyRelations;
};


export type SiteRelationsCompanyArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type SiteSegment = {
  __typename?: 'SiteSegment';
  /** Audience information for a site */
  audience?: Maybe<ProductAudience>;
  /**
   * Segment information for a site
   * @deprecated Use audience field
   */
  segment?: Maybe<Segment>;
  site: Portal;
};

export type SiteSwitchInput = {
  siteUuid: Scalars['UUID']['input'];
  timestamp: Scalars['DateTime']['input'];
};

export type SmsChallengeFailure = {
  __typename?: 'SmsChallengeFailure';
  localisedReason: LocalisedMessage;
  message: Scalars['String']['output'];
  reason: SmsChallengeFailureReason;
};

export type SmsChallengeFailureReason =
  | 'INVALID_NUMBER'
  | 'SMS_DISABLED'
  | 'UNKNOWN';

/** input for creating a new sms challenge */
export type SmsChallengeInput = {
  to: Scalars['String']['input'];
};

export type SmsChallengeRespondFailure = {
  __typename?: 'SmsChallengeRespondFailure';
  localisedReason: LocalisedMessage;
  message: Scalars['String']['output'];
  reason: SmsChallengeRespondFailureReason;
};

export type SmsChallengeRespondFailureReason =
  | 'EXPIRED'
  | 'INVALID_OTP'
  | 'UNKNOWN';

/** response to a sms challenge */
export type SmsChallengeRespondInput = {
  /** otp (6-digit) code delivered via sms as part of the challenge */
  bindingCode: Scalars['String']['input'];
  /** oob code received from the sms challenge */
  oobCode: Scalars['UUID']['input'];
};

/** whether the sms challenge response was successful, if not, provide reason and message */
export type SmsChallengeRespondResult = SmsChallengeRespondFailure | SmsChallengeRespondSuccess;

export type SmsChallengeRespondSuccess = {
  __typename?: 'SmsChallengeRespondSuccess';
  success: Scalars['Boolean']['output'];
};

/** a sms challenge, if can't create a challenge, why */
export type SmsChallengeResult = SmsChallengeFailure | SmsChallengeSuccess;

export type SmsChallengeSuccess = {
  __typename?: 'SmsChallengeSuccess';
  /** out of band code, think of it as an unique id of a specific sms challenge */
  oobCode: Scalars['UUID']['output'];
};

export type SnsTestMessage = {
  __typename?: 'SnsTestMessage';
  message: Scalars['String']['output'];
  uuid: Scalars['UUID']['output'];
};

export type SnsTestMessageInput = {
  message?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type SocialLink = {
  __typename?: 'SocialLink';
  type: SocialLinkType;
  value: Scalars['String']['output'];
};

export type SocialLinkInput = {
  type: SocialLinkType;
  value: Scalars['String']['input'];
};

export type SocialLinkType =
  | 'EMAIL'
  | 'FACEBOOK'
  | 'INSTAGRAM'
  | 'LINKEDIN'
  | 'OTHER'
  | 'PHONE'
  | 'TWITTER';

export type Space = {
  __typename?: 'Space';
  buildingLevel: BuildingLevel;
  /** @deprecated Use VisibilityType instead. Always returns true. Will be removed eventually. */
  isPublic: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  ownerCompany: CompanyV2;
  providerSpaceName?: Maybe<Scalars['String']['output']>;
  siteAudiences: Array<SpaceSiteAudience>;
  uuid: Scalars['UUID']['output'];
  visibilityType: SpaceVisibilityType;
};

export type SpaceSiteAudience = {
  __typename?: 'SpaceSiteAudience';
  destination: Destination;
  segmentIds?: Maybe<Array<Scalars['UUID']['output']>>;
  segmentSummary?: Maybe<Scalars['String']['output']>;
};

export type SpaceSiteAudienceInput = {
  segmentIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  segmentSummary?: InputMaybe<Scalars['String']['input']>;
  siteUuid: Scalars['UUID']['input'];
};

export type SpaceVisibilityType =
  | 'CUSTOM'
  | 'MEMBERS'
  | 'PRIVATE'
  | 'PUBLIC';

export type StatsProviderBookingMessageInput = {
  /** Number of Additional Credits charged for the booking. */
  additionalCreditsCharged: Scalars['Float']['input'];
  /** End date and time of the meeting. */
  bookingEndTime: Scalars['DateTime']['input'];
  /** Start date and time of the meeting. */
  bookingStartTime: Scalars['DateTime']['input'];
  /** Unique identifier of the building the resource resides in. */
  buildingUuid: Scalars['UUID']['input'];
  /** Unique identifier of the company that the host of the meeting belongs to. */
  companyUuid: Scalars['UUID']['input'];
  /** Amount of money that the room has cost. This is the cost (if paying by card or invoice) in the local currency, there is no currency designation. £10.50 would be 10.50. */
  costCharged: Scalars['Float']['input'];
  /** Amount of discount applied to the room booking as a percentage of cost. */
  discountPercentage: Scalars['Float']['input'];
  /** Has the booking been cancelled. */
  isCancelled: Scalars['Boolean']['input'];
  /** Number of Monthly Credits charged for the booking. */
  monthlyCreditsCharged: Scalars['Float']['input'];
  /** Number of Visitors linked to the meeting. */
  numberOfAttendees?: InputMaybe<Scalars['Int']['input']>;
  /** Time the room is available from after disassembly. */
  prepEndTime: Scalars['DateTime']['input'];
  /** Name of the resource (eg Meeting Room 1). */
  resourceName: Scalars['String']['input'];
  /** Unique identifier of the resource. */
  resourceUuid: Scalars['UUID']['input'];
  /** Unique identifier of the booking. */
  uuid: Scalars['UUID']['input'];
};

export type StatsProviderConfiguration = {
  __typename?: 'StatsProviderConfiguration';
  enabled: Scalars['Boolean']['output'];
  siteUuid: Scalars['UUID']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type StatsProviderConfigurationInput = {
  enabled: Scalars['Boolean']['input'];
  siteUuid: Scalars['UUID']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
};

export type StatsProviderRequestMessageInput = {
  buildingLevelName?: InputMaybe<Scalars['String']['input']>;
  buildingLevelUuid?: InputMaybe<Scalars['UUID']['input']>;
  buildingName: Scalars['String']['input'];
  buildingUuid: Scalars['UUID']['input'];
  categoryName?: InputMaybe<Scalars['String']['input']>;
  categoryUuid?: InputMaybe<Scalars['UUID']['input']>;
  completedDateTime?: InputMaybe<Scalars['DateTime']['input']>;
  createdDateTime: Scalars['DateTime']['input'];
  lastEditDateTime: Scalars['DateTime']['input'];
  reference: Scalars['Int']['input'];
  requestorCompanyName: Scalars['String']['input'];
  requestorCompanyUuid: Scalars['UUID']['input'];
  spaceName?: InputMaybe<Scalars['String']['input']>;
  spaceUuid?: InputMaybe<Scalars['UUID']['input']>;
  statusName: Scalars['String']['input'];
  uuid: Scalars['UUID']['input'];
};

export type StatsProviderVisitorAppointmentType =
  | 'EveryDay'
  | 'EveryMonth'
  | 'EveryWeek'
  | 'PreBooked'
  | 'WalkIn';

export type StatsProviderVisitorMessageInput = {
  appointmentType: StatsProviderVisitorAppointmentType;
  appointmentUuid: Scalars['UUID']['input'];
  arrivalDateTime?: InputMaybe<Scalars['DateTime']['input']>;
  buildingName: Scalars['String']['input'];
  buildingUuid: Scalars['UUID']['input'];
  companyName: Scalars['String']['input'];
  companyUuid: Scalars['UUID']['input'];
  createdDateTime: Scalars['DateTime']['input'];
  departureDateTime?: InputMaybe<Scalars['DateTime']['input']>;
  isCancelled: Scalars['Boolean']['input'];
  isForgottenPass: Scalars['Boolean']['input'];
  startDateTime: Scalars['DateTime']['input'];
  visitorRecurringUuid?: InputMaybe<Scalars['UUID']['input']>;
  visitorType: StatsProviderVisitorType;
  visitorUuid: Scalars['UUID']['input'];
};

export type StatsProviderVisitorType =
  | 'Contractor'
  | 'Employee'
  | 'Visitor';

export type StepStatus =
  | 'COMPLETED'
  | 'IN_PROGRESS'
  | 'PENDING';

export type Store = {
  __typename?: 'Store';
  enabled: Scalars['Boolean']['output'];
  /** @deprecated renamed to enabled */
  mobileEnabled: Scalars['Boolean']['output'];
  /** @deprecated No longer needed */
  mobileModuleEnabled: Scalars['Boolean']['output'];
  title?: Maybe<Scalars['String']['output']>;
  v1Enabled: Scalars['Boolean']['output'];
  /** @deprecated temporary flag for use during transition period only */
  webEnabled: Scalars['Boolean']['output'];
  /** @deprecated No longer needed */
  webModuleEnabled: Scalars['Boolean']['output'];
};

export type StoreRole =
  | 'CUSTOMER'
  | 'EQUIEM_ADMIN'
  | 'SERVICE'
  | 'SITE_MANAGER'
  | 'VENDOR_USER';

export type StoreUserPermission =
  | 'ACCESS_REPORTS'
  | 'CREATE_DEALS_VENDOR'
  | 'EDIT_SITE_SETTINGS'
  | 'EDIT_STORE_SETTINGS'
  | 'MANAGE_DISCOUNTS'
  | 'MANAGE_MENU'
  | 'MANAGE_ORDER'
  | 'MANAGE_USERS'
  | 'MANAGE_VENDORS'
  | 'MANUALLY_ADJUST_ORDER'
  /** deprecated. Use MANUALLY_ADJUST_ORDER instead */
  | 'REFUND_ORDER';

export type SubscriptionBillingCycle =
  | 'MONTHLY'
  | 'WEEKLY';

export type SubscriptionCharge = {
  __typename?: 'SubscriptionCharge';
  created: Scalars['DateTime']['output'];
  currencyCode: Scalars['String']['output'];
  exTaxTotal: Scalars['Int']['output'];
  incTaxTotal: Scalars['Int']['output'];
  lineItem: LineItem;
  subscription: ProductSubscription;
  successfulCharge: Scalars['Boolean']['output'];
  tax: Scalars['Int']['output'];
  updated: Scalars['DateTime']['output'];
  uuid: Scalars['UUID']['output'];
};

export type SubscriptionChargeConnection = {
  __typename?: 'SubscriptionChargeConnection';
  edges: Array<Maybe<SubscriptionChargeEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type SubscriptionChargeEdge = {
  __typename?: 'SubscriptionChargeEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<SubscriptionCharge>;
};

export type SubscriptionHistoryV2 = {
  __typename?: 'SubscriptionHistoryV2';
  subscription?: Maybe<ProductSubscription>;
  subscriptionUuid: Scalars['UUID']['output'];
  timestamp: Scalars['DateTime']['output'];
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type SubscriptionPaymentStatus =
  | 'FAILED'
  | 'PAYMENT_PENDING'
  | 'REQUIRES_ACTION'
  | 'SUCCESSFUL';

export type SubscriptionProduct = Product & {
  __typename?: 'SubscriptionProduct';
  /** If true the product is only available for a limited set of trading hours */
  availability: ProductAvailability;
  /** Whether the product is available or not. */
  available: Scalars['Boolean']['output'];
  /** The billing cycle for this subcription. */
  billingCycle: SubscriptionBillingCycle;
  /** Description of the product. */
  description?: Maybe<Scalars['String']['output']>;
  excerpt: Scalars['String']['output'];
  /** URL of the image for the product. */
  image?: Maybe<Scalars['URI']['output']>;
  /** S3 object key of the image for the product. */
  imageKey?: Maybe<Scalars['String']['output']>;
  /** A list of mods for the product. */
  mods: Array<Mod>;
  /** Name of the product. */
  name: Scalars['String']['output'];
  /**
   * Out of stock flag that can be set on products with or without a stock limit.
   * when true the stock count will be set to 0.
   * When true available will be false.
   */
  outOfStock: Scalars['Boolean']['output'];
  /** Fields for which values will be provided while processing an order for this product. */
  outputFields: Array<ProductOutputField>;
  /**
   * @deprecated renamed to sites.
   * @deprecated renamed to sites
   */
  portals: Array<Portal>;
  /** Product categories e.g. Breakfast, Lunch, Dinner */
  productCategories: Array<ProductCategory>;
  /** Profile fields which the customer is requested to provide when ordering this product. */
  profileFieldRequests: Array<ProductProfileField>;
  /** Show or hide terms and conditions. */
  showTermsAndConditions: Scalars['Boolean']['output'];
  /**
   * Portals associated with the product
   * @deprecated use sitesWithAudience endpoint
   */
  sites: Array<Portal>;
  /** Portals associated with the product with optional audience */
  sitesWithAudience: Array<SiteSegment>;
  /** How many of this product are left. */
  stock?: Maybe<Scalars['Int']['output']>;
  /**
   * @deprecated(reason: Use subscriptionsV2 that supports ProductSubscriptionStatusV2 status filter)
   * @deprecated Use subscriptionsV2 that supports ProductSubscriptionStatusV2 status filter
   */
  subscriptions: ProductSubscriptionConnection;
  /** The subscriptions that exist for this product. */
  subscriptionsV2: ProductSubscriptionConnection;
  /** Taxes applicable for this product */
  taxRates: Array<TaxRate>;
  /** Terms and conditions. */
  termsAndConditions?: Maybe<Scalars['String']['output']>;
  /** Overridden turn around time in minutes for the product */
  turnAroundTime?: Maybe<Scalars['Int']['output']>;
  /** Unit price in cents. May be tax inc or exc. */
  unitPrice: Scalars['Int']['output'];
  uuid: Scalars['UUID']['output'];
  /** The owner of the product. */
  vendor: Vendor;
  /** Whether product is visible to customer on the store */
  visibility: ProductVisibility;
};


export type SubscriptionProductImageArgs = {
  input?: InputMaybe<ImageInput>;
};


export type SubscriptionProductSubscriptionsArgs = {
  filters?: InputMaybe<SubscriptionProductsQueryFilters>;
};


export type SubscriptionProductSubscriptionsV2Args = {
  filters?: InputMaybe<SubscriptionProductsQueryFiltersV2>;
};

export type SubscriptionProductContentEdge = ContentEdge & {
  __typename?: 'SubscriptionProductContentEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<SubscriptionProduct>;
};

export type SubscriptionProductsQueryFilters = {
  paymentStatuses?: InputMaybe<Array<SubscriptionPaymentStatus>>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<ProductSubscriptionStatus>>;
};

export type SubscriptionProductsQueryFiltersV2 = {
  paymentStatuses?: InputMaybe<Array<SubscriptionPaymentStatus>>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<ProductSubscriptionStatusV2>>;
};

export type SuccessFullScopesResponse = {
  __typename?: 'SuccessFullScopesResponse';
  data: EncryptedDataOutput;
  success: Scalars['Boolean']['output'];
};

export type SuccessResponse = {
  __typename?: 'SuccessResponse';
  message: Scalars['String']['output'];
};

export type SuccessfulDeactivateUserResponse = {
  __typename?: 'SuccessfulDeactivateUserResponse';
  success: Scalars['Boolean']['output'];
};

export type SuccessfulEventCheckInMutationResult = {
  __typename?: 'SuccessfulEventCheckInMutationResult';
  partialUser?: Maybe<PartialUser>;
  success: Scalars['Boolean']['output'];
  userUuid: Scalars['UUID']['output'];
};

export type SuccessfulRegistrationV2Response = {
  __typename?: 'SuccessfulRegistrationV2Response';
  login?: Maybe<LoginResponse>;
  registeredDestination: UserDestination;
  user: User;
};

export type SuccessfulUpdateAttendeeLimitMutationResult = {
  __typename?: 'SuccessfulUpdateAttendeeLimitMutationResult';
  success: Scalars['Boolean']['output'];
};

export type SuccessfulUpdateOccurrencesFromSessionsMutationResult = {
  __typename?: 'SuccessfulUpdateOccurrencesFromSessionsMutationResult';
  success: Scalars['Boolean']['output'];
};

export type SwiftConnectHidSdk = {
  __typename?: 'SwiftConnectHidSdk';
  applicationId: Scalars['String']['output'];
  hidOrigoId: Scalars['String']['output'];
};

export type SwiftConnectMobileCredentialsSdk = {
  __typename?: 'SwiftConnectMobileCredentialsSdk';
  instanceId: Scalars['String']['output'];
  organizationId: Scalars['String']['output'];
};

export type SwiftConnectSdk = SwiftConnectHidSdk | SwiftConnectMobileCredentialsSdk;

export type SwiftConnectSdkType =
  | 'SwiftConnectHIDSDK'
  | 'SwiftConnectMobileCredentialsSDK';

export type SwiftconnectAuthenticationFailure = {
  __typename?: 'SwiftconnectAuthenticationFailure';
  code?: Maybe<Scalars['String']['output']>;
  reason: Scalars['String']['output'];
};

export type SwiftconnectAuthenticationResult = SwiftconnectAuthenticationFailure | SwiftconnectAuthenticationSuccess;

export type SwiftconnectAuthenticationSuccess = {
  __typename?: 'SwiftconnectAuthenticationSuccess';
  info: SwiftconnectDestinationInfo;
};

export type SwiftconnectDestinationInfo = {
  __typename?: 'SwiftconnectDestinationInfo';
  baseUrl: Scalars['String']['output'];
  connectedTimestamp: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  label: Scalars['String']['output'];
  sdk: SwiftConnectSdk;
  /** @deprecated Use sdk instead */
  sdkType: SwiftConnectSdkType;
};

export type SwiftconnectDestinationIntegration = {
  __typename?: 'SwiftconnectDestinationIntegration';
  artWork?: Maybe<Scalars['URI']['output']>;
  enabled: Scalars['Boolean']['output'];
  info?: Maybe<SwiftconnectDestinationInfo>;
};

export type SwiftconnectMutation = {
  __typename?: 'SwiftconnectMutation';
  /** @deprecated Use connectHIDSDK or connectMobileCredentialsSDK instead */
  connect: SwiftconnectAuthenticationResult;
  connectHIDSDK: SwiftconnectAuthenticationResult;
  connectMobileCredentialsSDK: SwiftconnectAuthenticationResult;
  disconnect: IntegrationServiceResult;
  /**
   * Update one or more of the fields associated with an existing connection, without overwriting the entire connection.
   *
   * Email and password cannot be updated and would require first disconnecting and then reconnecting with the new email and password.
   */
  patchHIDSDK: SwiftconnectAuthenticationResult;
  /**
   * Update one or more of the fields associated with an existing connection, without overwriting the entire connection.
   *
   * Email and password cannot be updated and would require first disconnecting and then reconnecting with the new email and password.
   */
  patchMobileCredentialsSDK: SwiftconnectAuthenticationResult;
  updateArtwork: Scalars['Boolean']['output'];
};


export type SwiftconnectMutationConnectArgs = {
  email: Scalars['String']['input'];
  label?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  sdkType: SwiftConnectSdkType;
  site: Scalars['UUID']['input'];
};


export type SwiftconnectMutationConnectHidsdkArgs = {
  applicationId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  hidOrigoId: Scalars['String']['input'];
  label?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  site: Scalars['UUID']['input'];
};


export type SwiftconnectMutationConnectMobileCredentialsSdkArgs = {
  email: Scalars['String']['input'];
  instanceId: Scalars['String']['input'];
  label?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
  password: Scalars['String']['input'];
  site: Scalars['UUID']['input'];
};


export type SwiftconnectMutationDisconnectArgs = {
  site: Scalars['UUID']['input'];
};


export type SwiftconnectMutationPatchHidsdkArgs = {
  applicationId?: InputMaybe<Scalars['String']['input']>;
  hidOrigoId?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  site: Scalars['UUID']['input'];
};


export type SwiftconnectMutationPatchMobileCredentialsSdkArgs = {
  instanceId?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  site: Scalars['UUID']['input'];
};


export type SwiftconnectMutationUpdateArtworkArgs = {
  site: Scalars['UUID']['input'];
  url?: InputMaybe<Scalars['URI']['input']>;
};

export type SwiftconnectQuery = {
  __typename?: 'SwiftconnectQuery';
  userToken: SwiftconnectUserTokenResult;
};

export type SwiftconnectUserTokenFailure = {
  __typename?: 'SwiftconnectUserTokenFailure';
  localisedReason: LocalisedMessage;
  /** @deprecated use localisedReason instead */
  reason: Scalars['String']['output'];
};

export type SwiftconnectUserTokenResult = SwiftconnectUserTokenFailure | SwiftconnectUserTokenSuccess;

export type SwiftconnectUserTokenSuccess = {
  __typename?: 'SwiftconnectUserTokenSuccess';
  accessToken: Scalars['String']['output'];
  refreshToken: Scalars['String']['output'];
};

export type SyncAreaInput = {
  buildingLevelUuids: Array<Scalars['UUID']['input']>;
  destinationUuid: Scalars['UUID']['input'];
  name: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type SyncBuildingAddress = {
  address: Scalars['String']['input'];
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  postcode?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  streetName?: InputMaybe<Scalars['String']['input']>;
  timezone: Scalars['String']['input'];
};

export type SyncBuildingInput = {
  area: Scalars['Int']['input'];
  buildingAddress?: InputMaybe<SyncBuildingAddress>;
  destinationUuid: Scalars['UUID']['input'];
  integrations?: InputMaybe<SyncBuildingIntegrationsInput>;
  levels: Array<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  occupants: Scalars['Int']['input'];
  taxes?: InputMaybe<SyncBuildingTaxes>;
  units: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type SyncBuildingIntegrationsInput = {
  yardi?: InputMaybe<SyncYardiBuildingIntegrationInput>;
};

export type SyncBuildingTaxRate = {
  label: Scalars['String']['input'];
  rate: Scalars['Float']['input'];
};

export type SyncBuildingTaxes = {
  currency: Scalars['String']['input'];
  taxInclusive: Scalars['Boolean']['input'];
  taxRates: Array<SyncBuildingTaxRate>;
};

export type SyncCompanyV2Input = {
  accessGroupsUuids?: InputMaybe<Array<Scalars['UUID']['input']>>;
  accessPasses?: InputMaybe<Array<Ac1CompanySiteCredentialSetupConfigInput>>;
  addsCardsAutomatically?: InputMaybe<Scalars['Boolean']['input']>;
  companyDestination?: InputMaybe<CompanyDestinationInput>;
  /** when specified, users with verified email address will be added their company default access group on approved registration */
  defaultAccessGroupUuid?: InputMaybe<Scalars['UUID']['input']>;
  destinations?: InputMaybe<Array<Scalars['UUID']['input']>>;
  industry?: InputMaybe<Scalars['UUID']['input']>;
  name: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['UUID']['input']>;
  validation?: InputMaybe<AssociationValidationInput>;
};

/** Autogenerated input type of SyncEmployee */
export type SyncEmployeeInput = {
  /**
   * DEPRECATED: accessGroupUuid is replaced by companyUuid and siteUuids
   * @deprecated accessGroupUuid is replaced by companyUuid and siteUuids
   */
  accessGroupUuid?: InputMaybe<Scalars['UUID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  /** companyUuid will be required in the near future */
  companyUuid?: InputMaybe<Scalars['UUID']['input']>;
  email: Scalars['String']['input'];
  endsAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  licensePlate?: InputMaybe<Scalars['String']['input']>;
  physicalCard?: InputMaybe<Scalars['String']['input']>;
  /** siteUuids will be required in the near future */
  siteUuids?: InputMaybe<Array<Scalars['UUID']['input']>>;
  startsAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  uuid: Scalars['UUID']['input'];
};

/** Autogenerated return type of SyncEmployee. */
export type SyncEmployeePayload = {
  __typename?: 'SyncEmployeePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  user?: Maybe<FullUser>;
};

export type SyncProfileResponse = {
  __typename?: 'SyncProfileResponse';
  error?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type SyncStatus =
  | 'DELETED'
  | 'DELETE_REQUESTED'
  | 'NOT_SCHEDULED'
  | 'SYNCED'
  | 'SYNC_REQUESTED';

/** Autogenerated input type of SyncVisitor */
export type SyncVisitorInput = {
  accessGroupUuid: Scalars['UUID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  endsAt: Scalars['ISO8601DateTime']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  licensePlate?: InputMaybe<Scalars['String']['input']>;
  physicalCard?: InputMaybe<Scalars['String']['input']>;
  startsAt: Scalars['ISO8601DateTime']['input'];
  uuid: Scalars['UUID']['input'];
};

/** Autogenerated return type of SyncVisitor. */
export type SyncVisitorPayload = {
  __typename?: 'SyncVisitorPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  visitor?: Maybe<Visitor>;
};

export type SyncYardiBuildingIntegrationInput = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  accrualId?: InputMaybe<Scalars['String']['input']>;
  checknumId?: InputMaybe<Scalars['String']['input']>;
  offsetId?: InputMaybe<Scalars['String']['input']>;
  propertyCode?: InputMaybe<Scalars['String']['input']>;
};

export type SyndicateActionResult = SyndicateFailureResponse | SyndicateSuccessResponse;

export type SyndicateFailureResponse = {
  __typename?: 'SyndicateFailureResponse';
  code?: Maybe<Scalars['String']['output']>;
  reason: Scalars['String']['output'];
};

export type SyndicateRole =
  | 'AUTH0'
  | 'SERVICE';

export type SyndicateSuccessResponse = {
  __typename?: 'SyndicateSuccessResponse';
  message: Scalars['String']['output'];
};

export type TabName =
  | 'BUILDING_INFO'
  | 'EVENT'
  | 'HOME'
  | 'NEWS'
  | 'NEWS_EVENTS'
  | 'PASSES'
  | 'PROFILE'
  | 'STORE';

export type TabViewInput = {
  tabName: TabName;
  timestamp: Scalars['DateTime']['input'];
};

/** Tax rate for a given tax region */
export type TaxRate = {
  __typename?: 'TaxRate';
  /** e.g. GST */
  label: Scalars['String']['output'];
  /** e.g. 0.1 = 10% */
  rate: Scalars['Float']['output'];
  uuid: Scalars['UUID']['output'];
};

/** Tax rules/information provided to Portals */
export type TaxRegion = {
  __typename?: 'TaxRegion';
  /** Applicable currency for tax region */
  currency: Currency;
  name: Scalars['String']['output'];
  /**
   * Whether product unit prices are displayed inclusive or
   * exclusive of tax.
   */
  taxInclusive: Scalars['Boolean']['output'];
  taxRates: Array<TaxRate>;
  uuid: Scalars['UUID']['output'];
};

export type TerminateLeaseInput = {
  dateOfChange: Scalars['DateTime']['input'];
  uuid: Scalars['UUID']['input'];
};

/** Represents the opening hours of a vendor on a given day */
export type TradingHour = {
  __typename?: 'TradingHour';
  /** Closing time of the store in 24hr time. Format: 14:38  */
  close: Scalars['String']['output'];
  day: DayOfTheWeek;
  /** Opening time of the store in 24hr time. Format: 10:12  */
  open: Scalars['String']['output'];
  uuid: Scalars['UUID']['output'];
};

/** Represents the opening hours of a vendor on a given day */
export type TradingHourV2 = {
  __typename?: 'TradingHourV2';
  /** Closing time of the store in 24hr time. Format: 14:38  */
  closeTime?: Maybe<Scalars['String']['output']>;
  day: DayOfTheWeek;
  isClosed: Scalars['Boolean']['output'];
  /** Opening time of the store in 24hr time. Format: 10:12  */
  openTime?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export type TransitScreen = {
  __typename?: 'TransitScreen';
  apiKey: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  locationCode: Scalars['String']['output'];
};

export type TriggerDeactivationFlowForSiteInput = {
  reminders: Array<DeactivationReminderInput>;
  siteUuid: Scalars['UUID']['input'];
};

export type TriggerDeactivationFlowForSiteInputV2 = {
  send3Month: Scalars['Boolean']['input'];
  send6Month: Scalars['Boolean']['input'];
  send9Month: Scalars['Boolean']['input'];
  siteUuid: Scalars['UUID']['input'];
};

export type UiWidget = {
  __typename?: 'UIWidget';
  enabled: Scalars['Boolean']['output'];
  metricName: Scalars['String']['output'];
  name: Scalars['String']['output'];
  type: UiWidgetType;
  uuid: Scalars['UUID']['output'];
  weight: Scalars['Int']['output'];
};

export type UiWidgetType =
  | 'BOOKING'
  | 'CURATED'
  | 'DISCOUNT'
  | 'EVENTS'
  | 'FEATURED'
  | 'FEEDBACK'
  | 'FORME'
  | 'NEWS'
  | 'POPULAR'
  | 'QUICKLINKS'
  | 'REORDER'
  | 'TRANSPORT'
  | 'TRENDING';

export type UbiparkStatusChangeFailure = {
  __typename?: 'UbiparkStatusChangeFailure';
  localisedReason: LocalisedMessage;
  reason: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type UbiparkStatusChangeResult = UbiparkStatusChangeFailure | UbiparkUserActivated | UbiparkUserDeactivated;

export type UbiparkUser = {
  __typename?: 'UbiparkUser';
  authenticationToken: Scalars['String']['output'];
  id: Scalars['Int']['output'];
};

export type UbiparkUserActivated = {
  __typename?: 'UbiparkUserActivated';
  credentials: UbiparkUser;
  success: Scalars['Boolean']['output'];
};

export type UbiparkUserDeactivated = {
  __typename?: 'UbiparkUserDeactivated';
  success: Scalars['Boolean']['output'];
};

export type UbiparkUserIntegration = {
  __typename?: 'UbiparkUserIntegration';
  credentials?: Maybe<UbiparkUser>;
  enabled: Scalars['Boolean']['output'];
};

export type UnpublishCmsArticleInput = {
  uuid: Scalars['UUID']['input'];
};

/** Deprecated - UpdateAttendeeLimitForSessions Input type */
export type UpdateAttendeeLimitForSessionsInput = {
  attendeeLimit?: InputMaybe<Scalars['Int']['input']>;
  sessionTemplateUuid: Scalars['UUID']['input'];
};

export type UpdateAttendeeLimitMutationResult = FailedUpdateAttendeeLimitMutationResult | SuccessfulUpdateAttendeeLimitMutationResult;

export type UpdateBookableResourceInput = {
  addOns?: InputMaybe<Array<ResourceAddOnInput>>;
  allowRecurringBooking?: InputMaybe<Scalars['Boolean']['input']>;
  allowVisitorInvites?: InputMaybe<Scalars['Boolean']['input']>;
  /** @deprecated use bookingApprovalType */
  autoApproveBookings?: InputMaybe<Scalars['Boolean']['input']>;
  availabilityDateRange?: InputMaybe<Scalars['String']['input']>;
  barrierControlAccessUuid?: InputMaybe<Scalars['UUID']['input']>;
  barrierControlVisitorAccessUuid?: InputMaybe<Scalars['UUID']['input']>;
  bookingApprovalType?: InputMaybe<BookableResourceBookingApprovalType>;
  bookingWindowMaxInMinutes?: InputMaybe<Scalars['Int']['input']>;
  bookingWindowMinInMinutes?: InputMaybe<Scalars['Int']['input']>;
  buildingUuid?: InputMaybe<Scalars['UUID']['input']>;
  businessHoursEnd?: InputMaybe<Scalars['String']['input']>;
  businessHoursHalfDayDurationMinutes?: InputMaybe<Scalars['Int']['input']>;
  businessHoursStart?: InputMaybe<Scalars['String']['input']>;
  children?: InputMaybe<Array<Scalars['UUID']['input']>>;
  description: Scalars['String']['input'];
  displayCapacity?: InputMaybe<Scalars['Int']['input']>;
  /** @deprecated use editBookingNoticePeriodInMinutes */
  editBookingNoticePeriodInDays?: InputMaybe<Scalars['Int']['input']>;
  editBookingNoticePeriodInMinutes?: InputMaybe<Scalars['Int']['input']>;
  editBookingTermsAndConditions?: InputMaybe<Scalars['String']['input']>;
  externalSyncCalendarUrl?: InputMaybe<Scalars['String']['input']>;
  externalSyncEmailAddress?: InputMaybe<Scalars['String']['input']>;
  features?: InputMaybe<Array<Scalars['UUID']['input']>>;
  flexibleAvailability: Array<BookableResourceFlexibleAvailabilityInput>;
  hiddenCost?: InputMaybe<Scalars['Boolean']['input']>;
  images: Array<Scalars['String']['input']>;
  levelUuid?: InputMaybe<Scalars['UUID']['input']>;
  manualApprovalCompanies?: InputMaybe<Array<Scalars['UUID']['input']>>;
  name: Scalars['String']['input'];
  ownerCompanyUuid?: InputMaybe<Scalars['UUID']['input']>;
  paymentMethods?: InputMaybe<Array<BookableResourcePaymentMethod>>;
  paymentRateCancellation?: InputMaybe<Array<BookableResourceCancellationRateInput>>;
  paymentRateFullDay?: InputMaybe<Scalars['Int']['input']>;
  paymentRateHalfDay?: InputMaybe<Scalars['Int']['input']>;
  paymentRateHourly?: InputMaybe<Scalars['Int']['input']>;
  paymentRateHourlyAfterHours?: InputMaybe<Scalars['Int']['input']>;
  paymentRateHourlyWeekend?: InputMaybe<Scalars['Int']['input']>;
  prepTimeAfterInMinutes?: InputMaybe<Scalars['Int']['input']>;
  prepTimeBeforeInMinutes?: InputMaybe<Scalars['Int']['input']>;
  roomConfigurations?: InputMaybe<Array<Scalars['UUID']['input']>>;
  siteAudiences?: InputMaybe<Array<BookableResourceSiteAudienceInput>>;
  slotsAvailability: Array<BookableResourceSlotsAvailabilityInput>;
  status?: InputMaybe<BookableResourceStatus>;
  taxExempt?: InputMaybe<Scalars['Boolean']['input']>;
  taxes?: InputMaybe<Array<Scalars['UUID']['input']>>;
  termsAndConditions?: InputMaybe<Scalars['String']['input']>;
  typeV2: Scalars['UUID']['input'];
  userCanEditBookings?: InputMaybe<Scalars['Boolean']['input']>;
  video?: InputMaybe<Scalars['String']['input']>;
  videoTitle?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateBookingApprovalInput = {
  approved: Scalars['Boolean']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateBookingInput = {
  addOns?: InputMaybe<Array<BookingAddOnInput>>;
  adminNote?: InputMaybe<Scalars['String']['input']>;
  customPrice?: InputMaybe<Scalars['Int']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  invoiceContact?: InputMaybe<BookingInvoiceContactInput>;
  note?: InputMaybe<Scalars['String']['input']>;
  paymentMethod?: InputMaybe<BookableResourcePaymentMethod>;
  roomConfiguration?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  userUuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateBuildingIntegrationsResult = {
  __typename?: 'UpdateBuildingIntegrationsResult';
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type UpdateBuildingLevelInput = {
  name: Scalars['String']['input'];
  uuid: Scalars['UUID']['input'];
};

export type UpdateCmsArticleInput = {
  authorName?: InputMaybe<Scalars['String']['input']>;
  body?: InputMaybe<Scalars['String']['input']>;
  downloads?: InputMaybe<Array<CmsFileInput>>;
  excerpt?: InputMaybe<Scalars['String']['input']>;
  images?: InputMaybe<Array<CmsFileInput>>;
  title?: InputMaybe<Scalars['String']['input']>;
  uuid: Scalars['UUID']['input'];
};

export type UpdateCompanyDestinationIntegrationsResult = {
  __typename?: 'UpdateCompanyDestinationIntegrationsResult';
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type UpdateCompanySizeInput = {
  companyUuid: Scalars['UUID']['input'];
  dateOfChange: Scalars['DateTime']['input'];
  destinationUuid: Scalars['UUID']['input'];
  size: Scalars['Int']['input'];
};

export type UpdateCompanyV2IntegrationsResult = {
  __typename?: 'UpdateCompanyV2IntegrationsResult';
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type UpdateDestinationIntegrationsResult = {
  __typename?: 'UpdateDestinationIntegrationsResult';
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type UpdateDestinationLegalInput = {
  cookiePolicy?: InputMaybe<Scalars['String']['input']>;
  cookiePolicyChangeReason?: InputMaybe<Scalars['String']['input']>;
  privacyPolicy?: InputMaybe<Scalars['String']['input']>;
  privacyPolicyChangeReason?: InputMaybe<Scalars['String']['input']>;
  termsAndConditions?: InputMaybe<Scalars['String']['input']>;
  termsAndConditionsChangeReason?: InputMaybe<Scalars['String']['input']>;
  triggeredBy?: InputMaybe<Scalars['UUID']['input']>;
  uuid: Scalars['UUID']['input'];
};

export type UpdateFlexTenantDefaultWorkplaceManagersInput = {
  defaultWorkplaceManagerEmails: Array<Scalars['Email']['input']>;
  flexTenantUuid: Scalars['UUID']['input'];
};

export type UpdateFlexTenantInput = {
  expirationDate: Scalars['DateTime']['input'];
  flexTenantUuid: Scalars['UUID']['input'];
  memberLimit?: InputMaybe<Scalars['Int']['input']>;
  monthlyCreditAllowance?: InputMaybe<Scalars['Int']['input']>;
  moveInDate: Scalars['DateTime']['input'];
  name: Scalars['String']['input'];
  spendingCreditsRequiresApproval?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateFlexTenantLocationsInput = {
  flexTenantUuid: Scalars['UUID']['input'];
  locations: Array<FlexTenantLocationInput>;
};

/** Autogenerated input type of UpdateIntegration */
export type UpdateIntegrationInput = {
  /** Pass name will display on mobile in AccessPasses. Used only for virtual card integrations */
  accessPassName?: InputMaybe<Scalars['String']['input']>;
  buildingIntegrations: Array<Ac1BuildingIntegrationAttributes>;
  /** @deprecated Is not going to be used, we will set in different level */
  builtInVirtualCredentials?: InputMaybe<Scalars['Boolean']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  config?: InputMaybe<Scalars['JSON']['input']>;
  connectionAndKeyPassword?: InputMaybe<Scalars['String']['input']>;
  connectionCertificate?: InputMaybe<Scalars['String']['input']>;
  connectionKey?: InputMaybe<Scalars['String']['input']>;
  /** Backend has to create CredentialSetup for site to be enabled */
  employeePhysicalCardEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Ac1CredentialSetup UUID */
  employeeQrCodeCredentialSetupUuid?: InputMaybe<Scalars['UUID']['input']>;
  /** Ac1CredentialSetup UUID */
  employeeVirtualCredentialSetupUuid?: InputMaybe<Scalars['UUID']['input']>;
  externalApiBaseUrl: Scalars['URL']['input'];
  externalApiDataUrl?: InputMaybe<Scalars['URL']['input']>;
  externalApiKey?: InputMaybe<Scalars['String']['input']>;
  externalApiSuffix?: InputMaybe<Scalars['String']['input']>;
  externalApiTokenSuffix?: InputMaybe<Scalars['String']['input']>;
  externalClientId?: InputMaybe<Scalars['String']['input']>;
  externalClientPassword?: InputMaybe<Scalars['String']['input']>;
  externalClientScope?: InputMaybe<Scalars['String']['input']>;
  externalClientSecret?: InputMaybe<Scalars['String']['input']>;
  externalClientUsername?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  uuid: Scalars['UUID']['input'];
  /** @deprecated Is not going to be used, we will set in different level */
  virtualCredentialsProviderUuid?: InputMaybe<Scalars['UUID']['input']>;
  /** Backend has to create CredentialSetup for site to be enabled */
  visitorPhysicalCardEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Ac1CredentialSetup UUID */
  visitorQrCodeCredentialSetupUuid?: InputMaybe<Scalars['UUID']['input']>;
};

/** Autogenerated return type of UpdateIntegration. */
export type UpdateIntegrationPayload = {
  __typename?: 'UpdateIntegrationPayload';
  ac1Integration?: Maybe<Ac1Integration>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type UpdateLevelInput = {
  destinationUuid?: InputMaybe<Scalars['UUID']['input']>;
  name: Scalars['String']['input'];
  uuid: Scalars['UUID']['input'];
};

/** UpdateOccurrencesFromSessionsInput Input type */
export type UpdateOccurrencesFromSessionsInput = {
  attendeeLimit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  sessionTemplateUuid: Scalars['UUID']['input'];
};

export type UpdateOccurrencesFromSessionsMutationResult = FailedUpdateOccurrencesFromSessionsMutationResult | SuccessfulUpdateOccurrencesFromSessionsMutationResult;

export type UpdateProfileFlexTenantsInput = {
  flexTenantUuids: Array<Scalars['UUID']['input']>;
  profileUuid: Scalars['UUID']['input'];
};

export type UpdateSpaceInput = {
  /** @deprecated Use VisibilityType instead. Will be removed eventually. */
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  providerSpaceName?: InputMaybe<Scalars['String']['input']>;
  siteAudiences?: InputMaybe<Array<SpaceSiteAudienceInput>>;
  uuid: Scalars['UUID']['input'];
  visibilityType?: InputMaybe<SpaceVisibilityType>;
};

export type UpdateUserFailure = {
  __typename?: 'UpdateUserFailure';
  reason: Scalars['String']['output'];
};

export type UpdateUserInput = {
  profile: UpdateUserProfileInput;
  sites?: InputMaybe<Array<UpdateUserSiteInput>>;
};

export type UpdateUserProfileFieldValueInput = {
  key: Scalars['ID']['input'];
  value?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserProfileInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['UUID']['input']>;
  created?: InputMaybe<Scalars['DateTime']['input']>;
  deliveryLocation?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  newWorldPermissions?: InputMaybe<Array<ProfileRole>>;
  permissions?: InputMaybe<Array<Scalars['String']['input']>>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  public: Scalars['Boolean']['input'];
  siteProfileV2?: InputMaybe<UpdateUserSiteProfileInputV2>;
  status?: InputMaybe<AdminProfileStatus>;
  stripeCustomerId?: InputMaybe<Scalars['String']['input']>;
  uuid: Scalars['UUID']['input'];
};

export type UpdateUserResult = UpdateUserFailure | UpdateUserSuccess;

export type UpdateUserSiteInput = {
  portalUuid: Scalars['String']['input'];
};

export type UpdateUserSiteProfileInputV2 = {
  fields?: InputMaybe<Array<UpdateUserProfileFieldValueInput>>;
  profileUUID: Scalars['UUID']['input'];
  registrationType?: InputMaybe<AdminRegistrationType>;
  siteAttributes?: InputMaybe<Array<Scalars['UUID']['input']>>;
  siteGroups?: InputMaybe<Array<Scalars['UUID']['input']>>;
  siteGroupsToAdd?: InputMaybe<Array<Scalars['UUID']['input']>>;
  siteGroupsToRemove?: InputMaybe<Array<Scalars['UUID']['input']>>;
  siteUUID: Scalars['UUID']['input'];
  subscribedToEmails?: InputMaybe<Scalars['Boolean']['input']>;
  subscribedToNotifications?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateUserStatusError = {
  __typename?: 'UpdateUserStatusError';
  email?: Maybe<Scalars['String']['output']>;
  reason: Scalars['String']['output'];
  userUuid: Scalars['UUID']['output'];
};

export type UpdateUserStatusInput = {
  active: Scalars['Boolean']['input'];
  userUuid: Scalars['UUID']['input'];
};

export type UpdateUserStatusResult = {
  __typename?: 'UpdateUserStatusResult';
  errors?: Maybe<Array<UpdateUserStatusError>>;
  updatedCount: Scalars['Int']['output'];
};

export type UpdateUserSuccess = {
  __typename?: 'UpdateUserSuccess';
  user: FullUser;
};

export type UpsertAttributeInput = {
  name: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

/** Autogenerated input type of UpsertBuildingIntegration */
export type UpsertBuildingIntegrationInput = {
  ac1IntegrationUuid: Scalars['UUID']['input'];
  buildingUuid: Scalars['UUID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  employeesEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  visitorsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated return type of UpsertBuildingIntegration. */
export type UpsertBuildingIntegrationPayload = {
  __typename?: 'UpsertBuildingIntegrationPayload';
  ac1BuildingIntegration?: Maybe<Ac1BuildingIntegration>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type UpsertClientInput = {
  name: Scalars['String']['input'];
  region?: InputMaybe<AdminClientRegion>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpsertCompanyAttributeInput = {
  name: Scalars['String']['input'];
  siteUuid: Scalars['UUID']['input'];
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

/** Autogenerated input type of UpsertCompanyConfig */
export type UpsertCompanyConfigInput = {
  accessGroupsUuids: Array<Scalars['UUID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Whole list of credential setup configs for company */
  companySiteCredentialSetupConfigs?: InputMaybe<Array<Ac1CompanySiteCredentialSetupConfigInput>>;
  companyUuid: Scalars['UUID']['input'];
  defaultAccessGroupUuid: Scalars['UUID']['input'];
  siteUuid: Scalars['UUID']['input'];
};

/** Autogenerated return type of UpsertCompanyConfig. */
export type UpsertCompanyConfigPayload = {
  __typename?: 'UpsertCompanyConfigPayload';
  ac1Config?: Maybe<Ac1CompanySiteConfig>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type UpsertDestinationInput = {
  accessControl?: InputMaybe<AccessControlSystem>;
  accessControlSettings?: InputMaybe<AccessControlSettingsInput>;
  accessControls?: InputMaybe<Array<AccessControlSystem>>;
  address: Scalars['String']['input'];
  androidAppId?: InputMaybe<Scalars['String']['input']>;
  appHeroImage?: InputMaybe<Scalars['URI']['input']>;
  autoDeactivate3MonthReminder?: InputMaybe<Scalars['Boolean']['input']>;
  autoDeactivate6MonthReminder?: InputMaybe<Scalars['Boolean']['input']>;
  autoDeactivate9MonthReminder?: InputMaybe<Scalars['Boolean']['input']>;
  autoDeactivateUsers?: InputMaybe<Scalars['Boolean']['input']>;
  bookingModuleSettings?: InputMaybe<BookingModuleSettingsInput>;
  buildingInfoCategories?: InputMaybe<Array<BuildingInfoCategoryInput>>;
  buildingInfoHeaderImage?: InputMaybe<Scalars['URI']['input']>;
  buildingInfoTitle?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  client: Scalars['UUID']['input'];
  commercialSignupCTA?: InputMaybe<Scalars['String']['input']>;
  commercialSignupEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  cookiePolicy?: InputMaybe<Scalars['String']['input']>;
  cookiePolicyChangeReason?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  customStylesheet?: InputMaybe<Scalars['URI']['input']>;
  defaultIrisAssignee?: InputMaybe<Scalars['UUID']['input']>;
  defaultIrisAuthor?: InputMaybe<Scalars['UUID']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  emailFooterImage?: InputMaybe<Scalars['URI']['input']>;
  emailHeaderImage?: InputMaybe<Scalars['URI']['input']>;
  emailVerificationRequired?: InputMaybe<Scalars['Boolean']['input']>;
  enableReporting?: InputMaybe<Scalars['Boolean']['input']>;
  endpoint?: InputMaybe<Scalars['String']['input']>;
  featureModules?: InputMaybe<Array<FeatureModulesInput>>;
  hiddenStatusMessage?: InputMaybe<Scalars['String']['input']>;
  installation?: InputMaybe<Scalars['String']['input']>;
  iosAppBuildId?: InputMaybe<Scalars['String']['input']>;
  iosAppId?: InputMaybe<Scalars['String']['input']>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  liveChatPK?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<Scalars['URI']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  managedByUCM: Scalars['Boolean']['input'];
  marketingOptinLabel?: InputMaybe<Scalars['String']['input']>;
  marketplaceTitle?: InputMaybe<Scalars['String']['input']>;
  migratingToUCM?: InputMaybe<Scalars['Boolean']['input']>;
  mobileColor?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  newWorldRegistrationMetric?: InputMaybe<Scalars['Boolean']['input']>;
  newsAndEventsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  newsletterFooter?: InputMaybe<Scalars['String']['input']>;
  parkingProvider?: InputMaybe<ParkingProvider>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  primaryColor?: InputMaybe<Scalars['String']['input']>;
  privacyPolicy?: InputMaybe<Scalars['String']['input']>;
  privacyPolicyChangeReason?: InputMaybe<Scalars['String']['input']>;
  reportingRegion?: InputMaybe<Scalars['String']['input']>;
  residentialSignupCTA?: InputMaybe<Scalars['String']['input']>;
  residentialSignupEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  showLogoOnWhiteBackground?: InputMaybe<Scalars['Boolean']['input']>;
  socialLinks?: InputMaybe<Array<SocialLinkInput>>;
  state?: InputMaybe<Scalars['String']['input']>;
  status: DestinationStatus;
  storeEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  storeMobileEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  storeMobileModuleEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  storeWebEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  storeWebModuleEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  streetName?: InputMaybe<Scalars['String']['input']>;
  termsAndConditions?: InputMaybe<Scalars['String']['input']>;
  termsAndConditionsChangeReason?: InputMaybe<Scalars['String']['input']>;
  textOverAppHeroImage?: InputMaybe<Scalars['Boolean']['input']>;
  textOverBuildingPageImage?: InputMaybe<Scalars['Boolean']['input']>;
  textOverHomePageImage?: InputMaybe<Scalars['Boolean']['input']>;
  thirdPartyCookies?: InputMaybe<Array<CookieInput>>;
  thirdPartyServiceProviders?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  uuid: Scalars['UUID']['input'];
  visitorCanSubscribeToEmails?: InputMaybe<Scalars['Boolean']['input']>;
  visitorCanSubscribeToNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  visitorSignupCTA?: InputMaybe<Scalars['String']['input']>;
  visitorSignupEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  webBrowserIconImage?: InputMaybe<Scalars['URI']['input']>;
  webBuildingPageHeaderImage?: InputMaybe<Scalars['URI']['input']>;
  webFooterLogoImage?: InputMaybe<Scalars['URI']['input']>;
  webHomePageHeaderImage?: InputMaybe<Scalars['URI']['input']>;
  webSiteLogoImage?: InputMaybe<Scalars['URI']['input']>;
  webSiteLogoImageSize?: InputMaybe<Scalars['String']['input']>;
  webWelcomePageImage?: InputMaybe<Scalars['URI']['input']>;
  welcomePageHeading?: InputMaybe<Scalars['String']['input']>;
  welcomePageSubHeading?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertDomainInput = {
  accountDomain?: InputMaybe<Scalars['String']['input']>;
  destination: Scalars['UUID']['input'];
  fromEmailAddress?: InputMaybe<Scalars['String']['input']>;
  referenceDomainIsPrimary?: InputMaybe<Scalars['Boolean']['input']>;
  replyToEmailAddress?: InputMaybe<Scalars['String']['input']>;
  webDomains: Array<AdminWebDomainInput>;
};

export type UpsertGroupInput = {
  name: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpsertHomeUiWidgetInput = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  type: UiWidgetType;
};

export type UpsertHomeUiWidgetInputV2 = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * the name given here will be saved in the backend and it is returned in queries such as homeUIWidgets(),
   * otherwise one of the hardcoded names base on the type would be used,
   * "Unknown" if a name was never given and there are no predefined name.
   */
  name?: InputMaybe<Scalars['String']['input']>;
  type: UiWidgetType;
  uuid: Scalars['UUID']['input'];
};

export type UpsertIndustryInput = {
  name: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

/** Autogenerated input type of UpsertQrCodeCredentialSetup */
export type UpsertQrCodeCredentialSetupInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  generator: Ac1QrCodeGenerator;
  passName: Scalars['String']['input'];
  siteUuid: Scalars['UUID']['input'];
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

/** Autogenerated return type of UpsertQrCodeCredentialSetup. */
export type UpsertQrCodeCredentialSetupPayload = {
  __typename?: 'UpsertQrCodeCredentialSetupPayload';
  ac1CredentialSetup?: Maybe<Ac1CredentialSetup>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type UpsertSiteAttributeInput = {
  name: Scalars['String']['input'];
  siteUuid: Scalars['UUID']['input'];
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpsertSiteGroupInput = {
  groupUuid?: InputMaybe<Scalars['UUID']['input']>;
  name: Scalars['String']['input'];
  siteUuid: Scalars['UUID']['input'];
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpsertWhitelabelAppDetailsInput = {
  androidPackageName: Scalars['String']['input'];
  appName: Scalars['String']['input'];
  dynamicLinkUrl: Scalars['String']['input'];
  editionId: Scalars['UUID']['input'];
  iosAppStoreId: Scalars['String']['input'];
  iosBundleId: Scalars['String']['input'];
  sites: Array<Scalars['UUID']['input']>;
};

export type UpsertWhitelabelAppInput = {
  androidFlavor?: InputMaybe<Scalars['String']['input']>;
  androidImages: AndroidImagesInput;
  apnsKey?: InputMaybe<AppleApnsKeyInput>;
  appName?: InputMaybe<Scalars['String']['input']>;
  appleAccountName?: InputMaybe<Scalars['String']['input']>;
  appleAppStoreId?: InputMaybe<Scalars['String']['input']>;
  appleCodeSigningIdentity?: InputMaybe<Scalars['String']['input']>;
  appleCompanyName?: InputMaybe<Scalars['String']['input']>;
  appleEditVersion?: InputMaybe<Scalars['String']['input']>;
  appleITCTeamId?: InputMaybe<Scalars['String']['input']>;
  appleMembershipExpiryDate?: InputMaybe<Scalars['DateTime']['input']>;
  applePayMerchantId?: InputMaybe<Scalars['String']['input']>;
  appleReviewEmail?: InputMaybe<Scalars['String']['input']>;
  appleStoreKey?: InputMaybe<AppleStoreKeyInput>;
  appleStoreStatus?: InputMaybe<AppleStoreStatus>;
  appleTeamId?: InputMaybe<Scalars['String']['input']>;
  appleVersion?: InputMaybe<Scalars['String']['input']>;
  betaUsers?: InputMaybe<Array<BetaUsersInput>>;
  bundleId?: InputMaybe<Scalars['String']['input']>;
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  clientName?: InputMaybe<Scalars['String']['input']>;
  clientUuid?: InputMaybe<Scalars['String']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  contactEmail?: InputMaybe<Scalars['Email']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  devAccountName?: InputMaybe<Scalars['String']['input']>;
  distributionRegions?: InputMaybe<Array<Scalars['String']['input']>>;
  dynamicLinkUrl?: InputMaybe<Scalars['String']['input']>;
  editionId?: InputMaybe<Scalars['String']['input']>;
  equiemOwner?: InputMaybe<Scalars['UUID']['input']>;
  firebaseAndroidAppId?: InputMaybe<Scalars['String']['input']>;
  firebaseProjectId?: InputMaybe<Scalars['String']['input']>;
  firebaseServiceKey?: InputMaybe<GoogleServiceKeyInput>;
  firebaseWebApiKey?: InputMaybe<Scalars['String']['input']>;
  googleAccountName?: InputMaybe<Scalars['String']['input']>;
  googlePlayKey?: InputMaybe<GoogleServiceKeyInput>;
  googleReviewEmail?: InputMaybe<Scalars['String']['input']>;
  googleStoreStatus?: InputMaybe<GoogleStoreStatus>;
  googleVersion?: InputMaybe<Scalars['String']['input']>;
  iosImages: IosImagesInput;
  keywords?: InputMaybe<Array<Scalars['String']['input']>>;
  language: Scalars['String']['input'];
  matchGitBranch?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  packageName?: InputMaybe<Scalars['String']['input']>;
  privacyUrl?: InputMaybe<Scalars['URI']['input']>;
  releaseNotes?: InputMaybe<Scalars['String']['input']>;
  siteUuids?: InputMaybe<Array<Scalars['String']['input']>>;
  status?: InputMaybe<WhitelabelStatus>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  supportUrl?: InputMaybe<Scalars['URI']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type User = {
  __typename?: 'User';
  active: Scalars['Boolean']['output'];
  adminPermissions?: Maybe<AdminPermissions>;
  canChangeEmail: Scalars['Boolean']['output'];
  currentDestination?: Maybe<UserDestination>;
  currentRegion?: Maybe<ViewerRegion>;
  defaultDestination: UserDestination;
  destination?: Maybe<UserDestination>;
  /** @deprecated Use destinationsV2 to include those that haven't had T&C's accepted */
  destinations?: Maybe<UserDestinationConnection>;
  destinationsV2?: Maybe<UserDestinationConnection>;
  destinationsWithRelation: DestinationsWithRelation;
  integrations: UserIntegrations;
  integrationsV2?: Maybe<UserIntegrationsV2>;
  locale?: Maybe<Scalars['String']['output']>;
  profile?: Maybe<Profile>;
  storePermissions?: Maybe<VmsStorePermissions>;
  /** @deprecated use integrations instead */
  ubipark?: Maybe<UbiparkUser>;
  /** @deprecated use integrations instead */
  ubiparkEnabled: Scalars['Boolean']['output'];
  uuid: Scalars['UUID']['output'];
};


export type UserDestinationArgs = {
  uuid: Scalars['UUID']['input'];
};


export type UserDestinationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<DestinationType>;
  types?: InputMaybe<Array<DestinationType>>;
};


export type UserDestinationsV2Args = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  installation?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<DestinationType>;
  types?: InputMaybe<Array<DestinationType>>;
};

/** User booking type that contains information about an RSVP */
export type UserBooking = {
  __typename?: 'UserBooking';
  acceptedTerms: Scalars['Boolean']['output'];
  active: Scalars['Boolean']['output'];
  attendees: Scalars['Int']['output'];
  checkedIn: Scalars['Boolean']['output'];
  lineItem: LineItem;
  order: Order;
  partialProfile?: Maybe<PartialProfile>;
  profile?: Maybe<Profile>;
  site: Portal;
  userUuid: Scalars['UUID']['output'];
};

/** User connection type */
export type UserBookingConnection = {
  __typename?: 'UserBookingConnection';
  edges: Array<Maybe<UserBookingEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** User edge type */
export type UserBookingEdge = {
  __typename?: 'UserBookingEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<UserBooking>;
};

/** User Booking type that contains information about a Booking */
export type UserBookingV2 = {
  __typename?: 'UserBookingV2';
  acceptedTerms: Scalars['Boolean']['output'];
  active: Scalars['Boolean']['output'];
  checkedIn: Scalars['Boolean']['output'];
  destination?: Maybe<Destination>;
  order?: Maybe<Order>;
  profile?: Maybe<Profile>;
  session: SessionV2;
  sessionUuid: Scalars['UUID']['output'];
  site?: Maybe<Portal>;
  userUuid: Scalars['UUID']['output'];
};

/** User booking connection type */
export type UserBookingV2Connection = {
  __typename?: 'UserBookingV2Connection';
  edges: Array<Maybe<UserBookingV2Edge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** User booking edge type */
export type UserBookingV2Edge = {
  __typename?: 'UserBookingV2Edge';
  cursor: Scalars['String']['output'];
  node: UserBookingV2;
};

export type UserConnection = {
  __typename?: 'UserConnection';
  edges: Array<UserEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type UserConnectionV2 = {
  __typename?: 'UserConnectionV2';
  edges: Array<UserEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type UserDestination = {
  __typename?: 'UserDestination';
  destination: Destination;
  permissions: UserDestinationPermissions;
  registrationType: RegistrationType;
  status: UserDestinationStatusInfo;
  termsAccepted: Scalars['Boolean']['output'];
};

export type UserDestinationConnection = {
  __typename?: 'UserDestinationConnection';
  edges: Array<UserDestinationEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type UserDestinationEdge = {
  __typename?: 'UserDestinationEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<UserDestination>;
};

export type UserDestinationPermissions = {
  __typename?: 'UserDestinationPermissions';
  canSubscribeToEmails: Scalars['Boolean']['output'];
  canSubscribeToNotifications: Scalars['Boolean']['output'];
  readComments: Scalars['Boolean']['output'];
  writeComments: Scalars['Boolean']['output'];
};

export type UserDestinationStatusInfo = {
  __typename?: 'UserDestinationStatusInfo';
  active: Scalars['Boolean']['output'];
  localisedMessage?: Maybe<LocalisedMessage>;
  message?: Maybe<Scalars['String']['output']>;
};

export type UserEdge = {
  __typename?: 'UserEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<FullUser>;
};

export type UserFilter = {
  accountAge?: InputMaybe<DateFilter>;
  accountAgeV2?: InputMaybe<DateFilterV2>;
  attributes?: InputMaybe<Array<Scalars['UUID']['input']>>;
  companyUuid?: InputMaybe<Scalars['String']['input']>;
  companyUuids?: InputMaybe<Array<Scalars['UUID']['input']>>;
  email?: InputMaybe<Scalars['String']['input']>;
  emails?: InputMaybe<Array<Scalars['String']['input']>>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  groups?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  lastSeen?: InputMaybe<DateFilter>;
  lastSeenV2?: InputMaybe<DateFilterV2>;
  name?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<Array<Scalars['String']['input']>>;
  registrationType?: InputMaybe<AdminRegistrationType>;
  status?: InputMaybe<Scalars['String']['input']>;
  subscribedToEmails?: InputMaybe<Scalars['Boolean']['input']>;
  verified?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserFilterV2 = {
  authorizationRoles?: InputMaybe<Array<Scalars['String']['input']>>;
  companies?: InputMaybe<Array<Scalars['UUID']['input']>>;
  email?: InputMaybe<Scalars['String']['input']>;
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  emails?: InputMaybe<Array<Scalars['String']['input']>>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  groups?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  profileCreatedAt?: InputMaybe<DateFilterV3>;
  profileLastActiveAt?: InputMaybe<DateFilterV3>;
  regionalFlexTenants?: InputMaybe<Array<Scalars['UUID']['input']>>;
  registrationType?: InputMaybe<SegmentationRegistrationType>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Scalars['UUID']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  subscribedToEmails?: InputMaybe<Scalars['Boolean']['input']>;
  userAttributes?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type UserIndexFieldGroups =
  | 'AUTHORIZATION_ROLES'
  | 'BASE';

export type UserInput = {
  uuid: Scalars['UUID']['input'];
};

export type UserIntegrations = {
  __typename?: 'UserIntegrations';
  ubipark: UbiparkUserIntegration;
};

export type UserIntegrationsV2 = {
  __typename?: 'UserIntegrationsV2';
  evolution?: Maybe<EvolutionUser>;
  openpath?: Maybe<OpenpathUser>;
};

export type UserRelationTupleInput = {
  id: Scalars['String']['input'];
  relation: Scalars['String']['input'];
  type: Scalars['String']['input'];
  userUuid: Scalars['String']['input'];
};

export type UserRole =
  | 'ANON'
  | 'SERVICE'
  | 'USER';

/** User RSVP type that contains information about an RSVP */
export type UserRsvp = {
  __typename?: 'UserRsvp';
  acceptedTerms: Scalars['Boolean']['output'];
  active: Scalars['Boolean']['output'];
  attendees: Scalars['Int']['output'];
  checkedIn: Scalars['Boolean']['output'];
  destinationUuid: Scalars['UUID']['output'];
  occurrence: Occurrence;
  orderLineItemUuid?: Maybe<Scalars['UUID']['output']>;
  orderUuid?: Maybe<Scalars['UUID']['output']>;
  partialUser?: Maybe<PartialUser>;
  site?: Maybe<Destination>;
  userUuid: Scalars['UUID']['output'];
};

/** User connection type */
export type UserRsvpConnection = {
  __typename?: 'UserRsvpConnection';
  edges: Array<Maybe<UserRsvpEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** User edge type */
export type UserRsvpEdge = {
  __typename?: 'UserRsvpEdge';
  cursor: Scalars['String']['output'];
  node: UserRsvp;
};

export type UserSort = {
  asc: Scalars['Boolean']['input'];
  field: UserSortField;
};

export type UserSortField =
  | 'COMPANY'
  | 'EMAIL'
  | 'LAST_SEEN'
  | 'NAME'
  | 'STATUS';

export type UserSortFieldV2 =
  | 'active'
  | 'companyNameKeywords'
  | 'emailKeyword'
  | 'firstNameKeyword'
  | 'profileLastActiveAt';

export type UserSortV2 = {
  asc: Scalars['Boolean']['input'];
  field: UserSortFieldV2;
};

export type UsersInput = {
  uuids: Array<Scalars['UUID']['input']>;
};

export type ValidateBookingInput = {
  discountCode?: InputMaybe<Scalars['String']['input']>;
  modChoices?: InputMaybe<Array<OrderModChoiceInput>>;
  sessions: Array<SessionLineItemInput>;
};

export type ValidateBookingTimesInput = {
  resourceUuid: Scalars['UUID']['input'];
  times: Array<ValidateBookingTimesTimeInput>;
};

export type ValidateBookingTimesResult = {
  __typename?: 'ValidateBookingTimesResult';
  endTime: Scalars['DateTime']['output'];
  startTime: Scalars['DateTime']['output'];
  status: ValidateBookingTimesStatus;
};

export type ValidateBookingTimesStatus =
  | 'Available'
  | 'OutOfAvailability'
  | 'Taken';

export type ValidateBookingTimesTimeInput = {
  endTime: Scalars['DateTime']['input'];
  startTime: Scalars['DateTime']['input'];
};

export type ValidateResetPasswordError = {
  __typename?: 'ValidateResetPasswordError';
  code: ValidateResetPasswordErrorCode;
  message: Scalars['String']['output'];
};

export type ValidateResetPasswordErrorCode =
  | 'TOKEN_EXPIRED'
  | 'TOKEN_NOT_FOUND'
  | 'UNKNOWN';

export type ValidateResetPasswordResult = FullUser | ValidateResetPasswordError;

export type Vendor = VendorInterface & {
  __typename?: 'Vendor';
  /** Combined address field */
  address: Scalars['String']['output'];
  /** Duration the pending order will be auto rejected when its idle */
  autoOrderRejectionTime?: Maybe<Scalars['Int']['output']>;
  choices: ChoiceConnection;
  /** City component of address */
  city?: Maybe<Scalars['String']['output']>;
  country: Scalars['String']['output'];
  countryCode: Scalars['String']['output'];
  /** Add a delay to all current order turn around times */
  delay?: Maybe<Scalars['Int']['output']>;
  deliveryCharge: Scalars['Int']['output'];
  /**
   * @deprecated no longer used
   * @deprecated no longer used
   */
  deliveryInstructions?: Maybe<Scalars['String']['output']>;
  deliveryMethods: Array<VendorDeliveryMethod>;
  /** Short description of the vendor */
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  excerpt: Scalars['String']['output'];
  /** Grouped set of trading hours for vendor trading hours */
  groupedTradingHours: Array<GroupedTradingHour>;
  image?: Maybe<Scalars['URI']['output']>;
  location: Coordinate;
  /** Customer friendly vendor name */
  name: Scalars['String']['output'];
  /**
   * @deprecated no longer used
   * @deprecated no longer used
   */
  offersDelivery?: Maybe<Scalars['Boolean']['output']>;
  /**
   * @deprecated no longer used
   * @deprecated no longer used
   */
  offersPickup?: Maybe<Scalars['Boolean']['output']>;
  open: Scalars['Boolean']['output'];
  /** This query represents the vendor's view of the orders */
  orders: OrderConnection;
  phone?: Maybe<Scalars['String']['output']>;
  /**
   * @deprecated no longer used
   * @deprecated no longer used
   */
  pickupInstructions?: Maybe<Scalars['String']['output']>;
  portals: Array<Portal>;
  /** Post code component of address */
  postCode?: Maybe<Scalars['String']['output']>;
  /** Product categories that vendor has, e.g Drinks */
  productCategories: Array<ProductCategory>;
  productCount: Scalars['Int']['output'];
  /** Product types that vendor has */
  productTypes: Array<Scalars['String']['output']>;
  products: ProductConnection;
  promotedDiscounts: Array<Discount>;
  /** State component of address */
  state?: Maybe<Scalars['String']['output']>;
  /** Street component of address */
  street?: Maybe<Scalars['String']['output']>;
  taxId?: Maybe<Scalars['String']['output']>;
  taxRegion: TaxRegion;
  thirdPartyUrl?: Maybe<Scalars['URI']['output']>;
  /** Set of trading hours for specific days of the week */
  tradingHours: Array<TradingHour>;
  /** Set of trading hours for specific days of the week */
  tradingHoursV2: Array<TradingHourV2>;
  /** Default turn around time in minutes for the vendor */
  turnAroundTime?: Maybe<Scalars['Int']['output']>;
  uuid: Scalars['UUID']['output'];
  /** Vendor categories e.g. Breakfast, Lunch, Dinner */
  vendorCategories: Array<VendorCategory>;
  visibilityStatus: VendorVisibilityStatus;
  website?: Maybe<Scalars['String']['output']>;
};


export type VendorChoicesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Array<ChoicesSearchFilter>>;
};


export type VendorImageArgs = {
  input?: InputMaybe<ImageInput>;
};


export type VendorOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<OrderStatus>;
};


export type VendorPortalsArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
};


export type VendorProductCategoriesArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
};


export type VendorProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProductFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Array<VendorProductsSearchFilter>>;
};

export type VendorCategory = {
  __typename?: 'VendorCategory';
  /** URL of the image for the vendor category. */
  image?: Maybe<Scalars['URI']['output']>;
  name: Scalars['String']['output'];
  uuid: Scalars['UUID']['output'];
  /** contains vendors and deal vendors */
  vendorList: VendorListConnection;
  vendors: VendorConnection;
};


export type VendorCategoryImageArgs = {
  input?: InputMaybe<ImageInput>;
};


export type VendorCategoryVendorListArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type VendorCategoryVendorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type VendorConnection = {
  __typename?: 'VendorConnection';
  edges: Array<Maybe<VendorEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type VendorContentEdge = ContentEdge & {
  __typename?: 'VendorContentEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Vendor>;
};

export type VendorDeliveryMethod = {
  __typename?: 'VendorDeliveryMethod';
  active: Scalars['Boolean']['output'];
  customerInstructions?: Maybe<Scalars['String']['output']>;
  deliveryMethod: DeliveryMethod;
  portal: Portal;
};

export type VendorDiscountReport = {
  __typename?: 'VendorDiscountReport';
  discountTotal: Scalars['Int']['output'];
  discountTotalPreTax: Scalars['Int']['output'];
  discountTotalTax: Scalars['Int']['output'];
  orderCount: Scalars['Int']['output'];
  orderTotal: Scalars['Int']['output'];
  orderTotalPreTax: Scalars['Int']['output'];
  orderTotalTax: Scalars['Int']['output'];
  preDiscountOrderTotal: Scalars['Int']['output'];
  preDiscountOrderTotalPreTax: Scalars['Int']['output'];
  preDiscountOrderTotalTax: Scalars['Int']['output'];
  vendorName: Scalars['String']['output'];
};

export type VendorEdge = {
  __typename?: 'VendorEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Vendor>;
};

export type VendorFilter = {
  /** deprecated. Use `searchTerm` */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Search vendor by name, trading name or description */
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  /** Filter vendors by sites */
  sites?: InputMaybe<Array<Scalars['UUID']['input']>>;
  /** deprecated. Use `searchTerm` */
  title?: InputMaybe<Scalars['String']['input']>;
};

export type VendorInterface = {
  /** Combined address field */
  address: Scalars['String']['output'];
  /** City component of address */
  city?: Maybe<Scalars['String']['output']>;
  country: Scalars['String']['output'];
  /** Short description of the vendor */
  description?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Scalars['URI']['output']>;
  location: Coordinate;
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  portals: Array<Portal>;
  /** Post code component of address */
  postCode?: Maybe<Scalars['String']['output']>;
  /** State component of address */
  state?: Maybe<Scalars['String']['output']>;
  /** Street component of address */
  street?: Maybe<Scalars['String']['output']>;
  thirdPartyUrl?: Maybe<Scalars['URI']['output']>;
  uuid: Scalars['UUID']['output'];
  /** Vendor categories e.g. Breakfast, Lunch, Dinner */
  vendorCategories: Array<VendorCategory>;
  visibilityStatus: VendorVisibilityStatus;
  website?: Maybe<Scalars['String']['output']>;
};


export type VendorInterfaceImageArgs = {
  input?: InputMaybe<ImageInput>;
};


export type VendorInterfacePortalsArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type VendorListConnection = {
  __typename?: 'VendorListConnection';
  edges: Array<Maybe<VendorListEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type VendorListEdge = {
  __typename?: 'VendorListEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<VendorUnion>;
};

export type VendorOrderBy = {
  descending: Scalars['Boolean']['input'];
  field: VendorSortableFields;
};

export type VendorProductsSearchBy =
  | 'DESCRIPTION'
  | 'NAME';

export type VendorProductsSearchFilter = {
  by: VendorProductsSearchBy;
  value: Scalars['String']['input'];
};

export type VendorSortableFields =
  | 'NAME'
  | 'SITES'
  | 'STATUS'
  | 'STRIPE_CONNECTED';

export type VendorUnion = DealVendor | Vendor;

export type VendorVisibilityStatus =
  | 'HIDDEN'
  | 'LIVE';

export type ViciniteePass = {
  __typename?: 'ViciniteePass';
  address?: Maybe<Scalars['String']['output']>;
  barcode: Scalars['String']['output'];
  company?: Maybe<Scalars['String']['output']>;
  endDate: Scalars['DateTime']['output'];
  grantsAccessTo: Array<ViciniteePlace>;
  logo?: Maybe<Scalars['String']['output']>;
  meetingDate?: Maybe<Scalars['DateTime']['output']>;
  meetingWith?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  startDate: Scalars['DateTime']['output'];
  usageDate?: Maybe<Scalars['DateTime']['output']>;
  uuid: Scalars['String']['output'];
};

export type ViciniteePlace = {
  __typename?: 'ViciniteePlace';
  name: Scalars['String']['output'];
  uuid: Scalars['UUID']['output'];
};

export type ViciniteeProfile = {
  __typename?: 'ViciniteeProfile';
  passes: Array<ViciniteePass>;
};

export type ViciniteeRole =
  | 'SERVICE'
  | 'USER';

export type ViewerRegion = {
  __typename?: 'ViewerRegion';
  regionId: Scalars['ID']['output'];
  userUuid: Scalars['UUID']['output'];
  viewerRelations: ViewerRegionRelations;
};

export type ViewerRegionRelations = {
  __typename?: 'ViewerRegionRelations';
  canManage: Scalars['Boolean']['output'];
};

export type ViewerRelations = {
  __typename?: 'ViewerRelations';
  site: SiteRelations;
};


export type ViewerRelationsSiteArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type Visitor = {
  __typename?: 'Visitor';
  accessPermit?: Maybe<AccessPermit>;
  appointment: VisitorAppointment;
  cardId?: Maybe<Scalars['String']['output']>;
  cardReturned: Scalars['Boolean']['output'];
  code?: Maybe<Scalars['String']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTime']['output'];
  email?: Maybe<Scalars['Email']['output']>;
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  receptionNote?: Maybe<ReceptionNote>;
  receptionistNotes: Array<ReceptionistNote>;
  recurringUuid?: Maybe<Scalars['UUID']['output']>;
  status: VisitorStatus;
  updated: Scalars['DateTime']['output'];
  uuid: Scalars['UUID']['output'];
  visitorInfo?: Maybe<Array<VisitorInfo>>;
};

export type VisitorActivityHistory = {
  __typename?: 'VisitorActivityHistory';
  created: Scalars['DateTime']['output'];
  message?: Maybe<Scalars['String']['output']>;
  status: VisitorStatus;
  user?: Maybe<Profile>;
  uuid: Scalars['UUID']['output'];
  variables?: Maybe<VisitorActivityHistoryVariables>;
  visitor?: Maybe<Visitor>;
  visitorUuid: Scalars['UUID']['output'];
};

export type VisitorActivityHistoryVariables = {
  __typename?: 'VisitorActivityHistoryVariables';
  cardId?: Maybe<Scalars['String']['output']>;
};

export type VisitorAllBuildingsReceptionsAvailableInput = {
  companyUuid?: InputMaybe<Scalars['UUID']['input']>;
  receptionUuids?: InputMaybe<Array<Scalars['UUID']['input']>>;
  userUuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type VisitorAppointment = {
  __typename?: 'VisitorAppointment';
  attendeeCount: Scalars['Int']['output'];
  bookingInfo?: Maybe<VisitorAppointmentBookingInfo>;
  building?: Maybe<Building>;
  buildingLevel?: Maybe<BuildingLevel>;
  buildingReceptionNotes?: Maybe<Scalars['String']['output']>;
  companyReceptionNotes?: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  endTime: Scalars['DateTime']['output'];
  groupUuid?: Maybe<Scalars['UUID']['output']>;
  holdInLobby?: Maybe<Scalars['Boolean']['output']>;
  host: VisitorHost;
  isMadeByReceptionist?: Maybe<Scalars['Boolean']['output']>;
  isWalkIn?: Maybe<Scalars['Boolean']['output']>;
  notifyHost: Scalars['Boolean']['output'];
  notifyOrganizer?: Maybe<Scalars['Boolean']['output']>;
  organizer?: Maybe<Profile>;
  reception?: Maybe<VisitorReception>;
  /** @deprecated Use reception field instead. */
  receptionUuid?: Maybe<Scalars['UUID']['output']>;
  recurringInfo?: Maybe<VisitorAppointmentRecurringInfo>;
  recurringUuid?: Maybe<Scalars['UUID']['output']>;
  startTime: Scalars['DateTime']['output'];
  thirdPartyKey?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated: Scalars['DateTime']['output'];
  uuid: Scalars['UUID']['output'];
  visitorCount: Scalars['Int']['output'];
  visitorNotes?: Maybe<Scalars['String']['output']>;
  visitorType?: Maybe<VisitorType>;
  visitors: Array<Visitor>;
};

export type VisitorAppointmentBookingInfo = {
  __typename?: 'VisitorAppointmentBookingInfo';
  createdByUuid: Scalars['UUID']['output'];
  reference: Scalars['String']['output'];
  resource?: Maybe<VisitorAppointmentBookingResource>;
  resourceDestinationUuid: Scalars['UUID']['output'];
  userUuid: Scalars['UUID']['output'];
  uuid: Scalars['UUID']['output'];
};

export type VisitorAppointmentBookingResource = {
  __typename?: 'VisitorAppointmentBookingResource';
  building?: Maybe<VisitorAppointmentResourceBuilding>;
  level?: Maybe<VisitorAppointmentResourceLevel>;
  name: Scalars['String']['output'];
  uuid: Scalars['UUID']['output'];
};

export type VisitorAppointmentByInput = {
  bookingUuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type VisitorAppointmentConnection = {
  __typename?: 'VisitorAppointmentConnection';
  edges: Array<VisitorAppointmentEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type VisitorAppointmentDate = {
  endTime: Scalars['DateTime']['input'];
  startTime: Scalars['DateTime']['input'];
};

export type VisitorAppointmentEdge = {
  __typename?: 'VisitorAppointmentEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<VisitorAppointment>;
};

export type VisitorAppointmentExportCsvInput = {
  beginDate?: InputMaybe<Scalars['DateTime']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  receptionUuid?: InputMaybe<Scalars['UUID']['input']>;
  siteUuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type VisitorAppointmentInput = {
  additionalDates?: InputMaybe<Array<InputMaybe<VisitorAppointmentDate>>>;
  /** @deprecated No longer needed */
  as?: InputMaybe<VisitorRole>;
  bookingUuid?: InputMaybe<Scalars['UUID']['input']>;
  building?: InputMaybe<Scalars['UUID']['input']>;
  buildingLevel?: InputMaybe<Scalars['UUID']['input']>;
  buildingReceptionNotes?: InputMaybe<Scalars['String']['input']>;
  companyReceptionNotes?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  endTime: Scalars['DateTime']['input'];
  holdInLobby?: InputMaybe<Scalars['Boolean']['input']>;
  host: VisitorHostInput;
  isMadeByReceptionist?: InputMaybe<Scalars['Boolean']['input']>;
  isWalkIn?: InputMaybe<Scalars['Boolean']['input']>;
  notifyHost: Scalars['Boolean']['input'];
  notifyOrganizer?: InputMaybe<Scalars['Boolean']['input']>;
  receptionUuid?: InputMaybe<Scalars['UUID']['input']>;
  recurringInfo?: InputMaybe<VisitorAppointmentRecurringInfoInput>;
  recurringUuid?: InputMaybe<Scalars['UUID']['input']>;
  startTime: Scalars['DateTime']['input'];
  thirdPartyKey?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  visitorNotes?: InputMaybe<Scalars['String']['input']>;
  visitors: Array<VisitorInput>;
  visitorsTypeUuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type VisitorAppointmentRecurringAppointment = {
  __typename?: 'VisitorAppointmentRecurringAppointment';
  endDate: Scalars['DateTime']['output'];
  startDate: Scalars['DateTime']['output'];
  uuid: Scalars['UUID']['output'];
};

export type VisitorAppointmentRecurringInfo = {
  __typename?: 'VisitorAppointmentRecurringInfo';
  appointments: Array<VisitorAppointmentRecurringAppointment>;
  endDate: Scalars['DateTime']['output'];
  lastWeekDayEachMonth?: Maybe<Scalars['Boolean']['output']>;
  recurringType: VisitorAppointmentRecurringType;
  repeatEvery?: Maybe<Scalars['Int']['output']>;
  repeatOn?: Maybe<Array<VisitorAppointmentWeekday>>;
  repeatTimes?: Maybe<Scalars['Int']['output']>;
  repeatUntil?: Maybe<Scalars['DateTime']['output']>;
  sameDayEachMonth?: Maybe<Scalars['Boolean']['output']>;
  startDate: Scalars['DateTime']['output'];
  uuid: Scalars['UUID']['output'];
};

export type VisitorAppointmentRecurringInfoInput = {
  lastWeekDayEachMonth?: InputMaybe<Scalars['Boolean']['input']>;
  recurringType: VisitorAppointmentRecurringType;
  repeatEvery?: InputMaybe<Scalars['Int']['input']>;
  repeatOn?: InputMaybe<Array<VisitorAppointmentWeekday>>;
  repeatTimes?: InputMaybe<Scalars['Int']['input']>;
  repeatUntil?: InputMaybe<Scalars['DateTime']['input']>;
  sameDayEachMonth?: InputMaybe<Scalars['Boolean']['input']>;
  startDate: Scalars['DateTime']['input'];
};

export type VisitorAppointmentRecurringType =
  | 'DAILY'
  | 'MONTHLY'
  | 'WEEKLY';

export type VisitorAppointmentResourceBuilding = {
  __typename?: 'VisitorAppointmentResourceBuilding';
  name: Scalars['String']['output'];
  uuid: Scalars['UUID']['output'];
};

export type VisitorAppointmentResourceLevel = {
  __typename?: 'VisitorAppointmentResourceLevel';
  name: Scalars['String']['output'];
  uuid: Scalars['UUID']['output'];
};

export type VisitorAppointmentType =
  | 'PRE_BOOKED'
  | 'WALK_IN';

export type VisitorAppointmentWeekday =
  | 'FRIDAY'
  | 'MONDAY'
  | 'SATURDAY'
  | 'SUNDAY'
  | 'THURSDAY'
  | 'TUESDAY'
  | 'WEDNESDAY';

export type VisitorAppointmentsFilter = {
  search?: InputMaybe<Scalars['String']['input']>;
};

export type VisitorAppointmentsQueryRole =
  | 'PROPERTY_MANAGER'
  | 'WORKPLACE_MANAGER';

export type VisitorAppointmentsSortField =
  | 'DATE';

export type VisitorAppointmentsSortInput = {
  asc: Scalars['Boolean']['input'];
  field: VisitorAppointmentsSortField;
};

export type VisitorBuilding = {
  __typename?: 'VisitorBuilding';
  buildingUuid: Scalars['UUID']['output'];
  emailFooterImage?: Maybe<Scalars['URI']['output']>;
  emailFooterImageKey?: Maybe<Scalars['String']['output']>;
  emailFooterText?: Maybe<Scalars['String']['output']>;
  emailHeaderImage?: Maybe<Scalars['URI']['output']>;
  emailHeaderImageKey?: Maybe<Scalars['String']['output']>;
  passAccessEndTime?: Maybe<Scalars['Int']['output']>;
  passAccessStartTime?: Maybe<Scalars['Int']['output']>;
  passPrintingConfiguration?: Maybe<PassPrintingConfiguration>;
};

export type VisitorBuildingInput = {
  /** @deprecated emailFooterImage is deprecated. Use emailFooterImageKey instead. */
  emailFooterImage?: InputMaybe<Scalars['URI']['input']>;
  emailFooterImageKey?: InputMaybe<Scalars['String']['input']>;
  emailFooterText?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated emailHeaderImage is deprecated. Use emailHeaderImageKey instead. */
  emailHeaderImage?: InputMaybe<Scalars['URI']['input']>;
  emailHeaderImageKey?: InputMaybe<Scalars['String']['input']>;
  passAccessEndTime?: InputMaybe<Scalars['Int']['input']>;
  passAccessStartTime?: InputMaybe<Scalars['Int']['input']>;
  passPrintingConfiguration?: InputMaybe<PassPrintingConfigurationInput>;
};

export type VisitorCompaniesFilter = {
  /** @deprecated Filter by reception instead */
  buildingUuid?: InputMaybe<Scalars['UUID']['input']>;
  receptionUuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type VisitorConnection = {
  __typename?: 'VisitorConnection';
  edges: Array<VisitorEdge>;
  maxUpdated?: Maybe<Scalars['DateTime']['output']>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type VisitorEdge = {
  __typename?: 'VisitorEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Visitor>;
};

export type VisitorHost = {
  __typename?: 'VisitorHost';
  company?: Maybe<CompanyV2>;
  email?: Maybe<Scalars['Email']['output']>;
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  profile?: Maybe<Profile>;
  userUuid?: Maybe<Scalars['UUID']['output']>;
  uuid: Scalars['UUID']['output'];
};

export type VisitorHostInput = {
  companyUuid?: InputMaybe<Scalars['UUID']['input']>;
  email?: InputMaybe<Scalars['Email']['input']>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  userUuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type VisitorInfo =
  | 'REQUIRE_ASSISTANCE'
  | 'VIP';

export type VisitorInput = {
  cardId?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['Email']['input']>;
  firstName: Scalars['String']['input'];
  groupId?: InputMaybe<Scalars['UUID']['input']>;
  lastName: Scalars['String']['input'];
  receptionNoteMessage?: InputMaybe<Scalars['String']['input']>;
  recurringId?: InputMaybe<Scalars['UUID']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
  visitorInfo?: InputMaybe<Array<VisitorInfo>>;
};

export type VisitorLogsFilter = {
  appointmentHostCompanyUuids?: InputMaybe<Array<Scalars['UUID']['input']>>;
  appointmentUuids?: InputMaybe<Array<Scalars['UUID']['input']>>;
  beginDate?: InputMaybe<Scalars['DateTime']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  visitorUuids?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type VisitorManagementSiteSettings = {
  __typename?: 'VisitorManagementSiteSettings';
  isHostEmailMandatory?: Maybe<Scalars['Boolean']['output']>;
  isVisitorTypesEnabled?: Maybe<Scalars['Boolean']['output']>;
  maxAppointmentCreationMonths: Scalars['Int']['output'];
  visitorTypes?: Maybe<Array<VisitorType>>;
  walletPassBackgroundColor?: Maybe<Scalars['String']['output']>;
  walletPassForegroundColor?: Maybe<Scalars['String']['output']>;
  walletPassLabelColor?: Maybe<Scalars['String']['output']>;
};

export type VisitorManagementSiteSettingsInput = {
  isHostEmailMandatory?: InputMaybe<Scalars['Boolean']['input']>;
  isVisitorTypeFeatureEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  maxAppointmentCreationMonths: Scalars['Int']['input'];
  visitorTypes?: InputMaybe<Array<VisitorTypesInput>>;
  walletPassBackgroundColor?: InputMaybe<Scalars['String']['input']>;
  walletPassForegroundColor?: InputMaybe<Scalars['String']['input']>;
  walletPassLabelColor?: InputMaybe<Scalars['String']['input']>;
};

export type VisitorMutationResult = {
  __typename?: 'VisitorMutationResult';
  deletionTime?: Maybe<Scalars['DateTime']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type VisitorQueryResult = {
  __typename?: 'VisitorQueryResult';
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type VisitorReception = {
  __typename?: 'VisitorReception';
  accessGroup?: Maybe<AccessGroup>;
  barrierControlAccess?: Maybe<BarrierControlAccess>;
  building?: Maybe<Building>;
  buildingLevel?: Maybe<BuildingLevel>;
  company?: Maybe<CompanyV2>;
  created: Scalars['DateTime']['output'];
  dailyStats: Array<VisitorReceptionDailyStats>;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  deletionTime?: Maybe<Scalars['DateTime']['output']>;
  destination: Destination;
  enableAccessCard?: Maybe<Scalars['Boolean']['output']>;
  enableAutomaticPassPrinting?: Maybe<Scalars['Boolean']['output']>;
  enableHoldInLobby?: Maybe<Scalars['Boolean']['output']>;
  enablePassPrinting?: Maybe<Scalars['Boolean']['output']>;
  receptionists: Array<VisitorReceptionist>;
  recipients?: Maybe<Array<Maybe<VisitorRecipient>>>;
  sendPassOnCheckIn?: Maybe<Scalars['Boolean']['output']>;
  suffix: Scalars['String']['output'];
  timezone: Scalars['String']['output'];
  updated: Scalars['DateTime']['output'];
  uuid: Scalars['UUID']['output'];
};


export type VisitorReceptionDailyStatsArgs = {
  dates: Array<Scalars['DateTime']['input']>;
};

export type VisitorReceptionConnection = {
  __typename?: 'VisitorReceptionConnection';
  edges: Array<VisitorReceptionEdge>;
  pageInfo: PageInfo;
};

export type VisitorReceptionDailyStats = {
  __typename?: 'VisitorReceptionDailyStats';
  date: Scalars['DateTime']['output'];
  preBookedCount: Scalars['Int']['output'];
};

export type VisitorReceptionEdge = {
  __typename?: 'VisitorReceptionEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<VisitorReception>;
};

export type VisitorReceptionInput = {
  /**
   * new field for storing AC1’s access group.
   * you should use this field instead of barrierControlAccessUuid for sites using AC1.
   */
  accessGroupUuid?: InputMaybe<Scalars['String']['input']>;
  /**
   * for legacy visitor barrier control access uuid for sites not using AC1.
   * when you use this field, you should not use accessGroupUuid.
   */
  barrierControlAccessUuid?: InputMaybe<Scalars['String']['input']>;
  building?: InputMaybe<Scalars['UUID']['input']>;
  buildingLevel?: InputMaybe<Scalars['UUID']['input']>;
  company?: InputMaybe<Scalars['UUID']['input']>;
  enableAccessCard?: InputMaybe<Scalars['Boolean']['input']>;
  enableAutomaticPassPrinting?: InputMaybe<Scalars['Boolean']['input']>;
  enableHoldInLobby?: InputMaybe<Scalars['Boolean']['input']>;
  enablePassPrinting?: InputMaybe<Scalars['Boolean']['input']>;
  receptionists: Array<VisitorReceptionistInput>;
  recipients?: InputMaybe<Array<InputMaybe<VisitorRecipientInput>>>;
  sendPassOnCheckIn?: InputMaybe<Scalars['Boolean']['input']>;
  suffix?: InputMaybe<Scalars['String']['input']>;
};

export type VisitorReceptionist = {
  __typename?: 'VisitorReceptionist';
  profile?: Maybe<Profile>;
  uuid: Scalars['UUID']['output'];
};

export type VisitorReceptionistInput = {
  userUuid: Scalars['UUID']['input'];
};

export type VisitorRecipient = {
  __typename?: 'VisitorRecipient';
  profile?: Maybe<Profile>;
  uuid: Scalars['UUID']['output'];
};

export type VisitorRecipientInput = {
  userUuid: Scalars['UUID']['input'];
};

export type VisitorRole =
  | 'PROPERTY_MANAGER'
  | 'RECEPTIONIST'
  | 'WORKPLACE_MANAGER';

export type VisitorStatus =
  | 'CARD_ID_ASSIGNED'
  | 'CHECKED_IN'
  | 'CHECKED_OUT'
  | 'PRE_BOOKED'
  | 'VISITOR_IN_LOBBY';

export type VisitorSuggestion = {
  __typename?: 'VisitorSuggestion';
  companyName?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  suggestion: Scalars['String']['output'];
  uuid?: Maybe<Scalars['String']['output']>;
};

export type VisitorType = {
  __typename?: 'VisitorType';
  appointmentTypes?: Maybe<Array<VisitorAppointmentType>>;
  isDefault: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export type VisitorTypesInput = {
  appointmentTypes?: InputMaybe<Array<VisitorAppointmentType>>;
  isDefault: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type VmsAddOrRemoveSiteVendorInput = {
  siteUuid: Scalars['UUID']['input'];
  vendorUuid: Scalars['UUID']['input'];
};

export type VmsAddOrUpdateDealInput = {
  affiliateLinkName?: InputMaybe<Scalars['String']['input']>;
  affiliateLinkUrl?: InputMaybe<Scalars['String']['input']>;
  /** dates of availability in the format [YYYY-MM-DD, YYYY-MM-DD) where [ is inclusive and ) is exclusive */
  availabilityDateRange?: InputMaybe<Scalars['String']['input']>;
  /** Hours of availability, only relevant if limitedAvailability */
  availabilityHours?: InputMaybe<Array<VmsTradingHourInput>>;
  body?: InputMaybe<Scalars['String']['input']>;
  dealType: VmsDealType;
  description?: InputMaybe<Scalars['String']['input']>;
  focalPoint?: InputMaybe<VmsFocalPointInput>;
  imageKey?: InputMaybe<Scalars['String']['input']>;
  /** If true the product is only available for a limited set of trading hours */
  limitedAvailability?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  /** Deprecated: Use sites input. */
  portalUuids?: InputMaybe<Array<Scalars['UUID']['input']>>;
  productCategoryNames: Array<Scalars['String']['input']>;
  showOverlay?: InputMaybe<Scalars['Boolean']['input']>;
  /** Sites this product can be sold on with optional audience input */
  sites?: InputMaybe<Array<VmsSiteProductInput>>;
  termsAndConditions?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
  vendorUuid: Scalars['UUID']['input'];
  visibility: VmsProductVisibility;
};

export type VmsAddOrUpdateDealsVendorInput = {
  /** The vendor's address */
  address?: InputMaybe<VmsCreateVendorAddressInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  focalPoint?: InputMaybe<VmsFocalPointInput>;
  imageKey?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  reportingCategory?: InputMaybe<VmsReportingCategory>;
  siteUuids: Array<Scalars['UUID']['input']>;
  thirdPartyUrl?: InputMaybe<Scalars['URI']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
  vendorCategoryUuids: Array<Scalars['UUID']['input']>;
  visibilityStatus: VmsVendorVisibilityStatus;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type VmsAddOrUpdateDeliveryMethodInput = {
  availableFrom?: InputMaybe<Scalars['Time']['input']>;
  availableUntil?: InputMaybe<Scalars['Time']['input']>;
  customerInstructions?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  portalUuid: Scalars['UUID']['input'];
  showVendorLocation?: InputMaybe<Scalars['Boolean']['input']>;
  type: VmsDeliveryType;
  vendorInstructions?: InputMaybe<Scalars['String']['input']>;
};

export type VmsAddOrUpdateSiteInput = {
  enabled: Scalars['Boolean']['input'];
  siteUuid: Scalars['UUID']['input'];
  taxRegionUuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type VmsAddOrUpdateSiteManagerInput = {
  permissionNames: Array<VmsStoreUserPermission>;
  siteUuid: Scalars['UUID']['input'];
  userUuid: Scalars['UUID']['input'];
};

export type VmsAddOrUpdateSiteManagerInputV2 = {
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  permissionNames: Array<VmsStoreUserPermission>;
  siteUuid: Scalars['UUID']['input'];
  userUuid: Scalars['UUID']['input'];
};

export type VmsAddOrUpdateVendorUserInput = {
  permissionNames: Array<VmsStoreUserPermission>;
  userUuid: Scalars['UUID']['input'];
  vendorUuid: Scalars['UUID']['input'];
};

export type VmsAddOrUpdateVendorUserInputV2 = {
  allowSms?: InputMaybe<Scalars['Boolean']['input']>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  permissionNames: Array<VmsStoreUserPermission>;
  userUuid: Scalars['UUID']['input'];
  vendorUuid: Scalars['UUID']['input'];
};

/** A stripe failure that can happen when adjusting an order */
export type VmsAdjustmentFailure = VmsIPaymentFailure & {
  __typename?: 'VmsAdjustmentFailure';
  code: VmsPaymentFailureCodes;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type VmsAdjustmentFailureCodesV2 =
  | 'AMOUNT_TOO_LARGE'
  | 'AMOUNT_TOO_SMALL'
  | 'AUTHENTICATION_REQUIRED'
  | 'BALANCE_INSUFFICIENT'
  | 'CARD_DECLINED'
  | 'EXPIRED_CARD'
  | 'OTHER'
  | 'STRIPE_REQUEST_INPUT';

export type VmsAdjustmentFailureV2 = {
  __typename?: 'VmsAdjustmentFailureV2';
  code: VmsAdjustmentFailureCodesV2;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Represents the mutations allowed for a specific order. */
export type VmsAllowedOrderActions = {
  __typename?: 'VmsAllowedOrderActions';
  adjust: Scalars['Boolean']['output'];
  cancelSubscription: Scalars['Boolean']['output'];
  /** The next logical status for in a normal, successful workflow (eg. if Order is NEW, then IN_PROGRESS). */
  nextStatus?: Maybe<VmsOrderStatus>;
  refund: Scalars['Boolean']['output'];
  /** Only applicable to orders with parking products atm. */
  retryFailedPayment: Scalars['Boolean']['output'];
  retrySubscriptionCharge: Scalars['Boolean']['output'];
  /** All the status updates which are allowed for the current user (eg. If Order is NEW, [IN_PROGRESS, CANCELLED, REJECTED]). */
  statusUpdates: Array<VmsOrderStatus>;
};

/** Represents a list of mutation end points for Analytics. */
export type VmsAnalytics = {
  __typename?: 'VmsAnalytics';
  affiliateClick: VmsMutationResult;
  enterCarpark: VmsMutationResult;
  exitCarpark: VmsMutationResult;
  viewDeal: VmsMutationResult;
  /** A product has been viewed. */
  viewProduct: VmsMutationResult;
  /** A vendor has been viewed. */
  viewVendor: VmsMutationResult;
};


/** Represents a list of mutation end points for Analytics. */
export type VmsAnalyticsAffiliateClickArgs = {
  dealUuid: Scalars['UUID']['input'];
  timeMs: Scalars['DateTime']['input'];
};


/** Represents a list of mutation end points for Analytics. */
export type VmsAnalyticsEnterCarparkArgs = {
  beaconName: Scalars['String']['input'];
  timeMs: Scalars['DateTime']['input'];
};


/** Represents a list of mutation end points for Analytics. */
export type VmsAnalyticsExitCarparkArgs = {
  beaconName: Scalars['String']['input'];
  timeMs: Scalars['DateTime']['input'];
};


/** Represents a list of mutation end points for Analytics. */
export type VmsAnalyticsViewDealArgs = {
  dealUuid: Scalars['UUID']['input'];
  timeMs: Scalars['DateTime']['input'];
};


/** Represents a list of mutation end points for Analytics. */
export type VmsAnalyticsViewProductArgs = {
  productUuid: Scalars['UUID']['input'];
  timeMs: Scalars['DateTime']['input'];
};


/** Represents a list of mutation end points for Analytics. */
export type VmsAnalyticsViewVendorArgs = {
  timeMs: Scalars['DateTime']['input'];
  vendorUuid: Scalars['UUID']['input'];
};

export type VmsAvailabilityDateRange = {
  __typename?: 'VmsAvailabilityDateRange';
  endDate: Scalars['String']['output'];
  endDateTimestamp: Scalars['DateTime']['output'];
  endInclusive: Scalars['Boolean']['output'];
  startDate: Scalars['String']['output'];
  startDateTimestamp: Scalars['DateTime']['output'];
  startInclusive: Scalars['Boolean']['output'];
};

export type VmsBookableProduct = VmsProduct & {
  __typename?: 'VmsBookableProduct';
  /** Indicates whether the booking allows multiple booking / quantity. */
  allowAttendees?: Maybe<Scalars['Boolean']['output']>;
  /** Settings for the is cancellable by user */
  allowUserToCancel?: Maybe<Scalars['Boolean']['output']>;
  availability: VmsProductAvailability;
  /** properties after this point only exist to match interface */
  available: Scalars['Boolean']['output'];
  combinedSessions: Array<VmsSessionUnion>;
  dateRange?: Maybe<VmsAvailabilityDateRange>;
  /** Description of the product. */
  description?: Maybe<Scalars['String']['output']>;
  focalPoint?: Maybe<VmsFocalPoint>;
  groupedSessions: Array<VmsSessionGroup>;
  /** URL of the image for the product. */
  image?: Maybe<Scalars['URI']['output']>;
  /** S3 object key of the image for the product. */
  imageKey?: Maybe<Scalars['String']['output']>;
  /** In minutes */
  minimumNoticeTimeLimit?: Maybe<Scalars['Int']['output']>;
  mods: Array<VmsMod>;
  /** Name of the product. */
  name: Scalars['String']['output'];
  outOfStock: Scalars['Boolean']['output'];
  /** Fields for which values will be provided while processing an order for this product. */
  outputFields: Array<VmsProductOutputField>;
  portals: Array<VmsPortal>;
  possibleSessions: Array<VmsPossibleSession>;
  /** Product categories e.g. Breakfast, Lunch, Dinner */
  productCategories: Array<VmsProductCategory>;
  /** Profile fields which the customer is requested to provide when ordering this product. */
  profileFieldRequests: Array<VmsProductProfileField>;
  session: VmsSession;
  sessionTemplates: Array<VmsSessionTemplate>;
  sessions: Array<VmsSession>;
  /** Show or hide terms and conditions. */
  showTermsAndConditions: Scalars['Boolean']['output'];
  /**
   * Portals associated with the product
   * @deprecated use sitesWithAudience endpoint
   */
  sites: Array<VmsPortal>;
  /** Portals associated with the product with optional audience */
  sitesWithAudience: Array<VmsSiteSegment>;
  stock?: Maybe<Scalars['Int']['output']>;
  /** Taxes applicable for this product */
  taxRates: Array<VmsTaxRate>;
  /** Terms and conditions. */
  termsAndConditions?: Maybe<Scalars['String']['output']>;
  /** In minutes */
  timeLimit?: Maybe<Scalars['Int']['output']>;
  totalBookings: Scalars['Int']['output'];
  /** When capacity return null, then it's unlimited capacity */
  totalCapacity?: Maybe<Scalars['Int']['output']>;
  turnAroundTime?: Maybe<Scalars['Int']['output']>;
  /** Unit price in cents. May be tax inc or exc. */
  unitPrice: Scalars['Int']['output'];
  uuid: Scalars['UUID']['output'];
  /** The owner of the product. */
  vendor: VmsVendor;
  /** Whether the product is visible to the customer on the store */
  visibility: VmsProductVisibility;
};


export type VmsBookableProductCombinedSessionsArgs = {
  endDate: Scalars['DateTime']['input'];
  startDate: Scalars['DateTime']['input'];
};


export type VmsBookableProductGroupedSessionsArgs = {
  endDate: Scalars['DateTime']['input'];
  startDate: Scalars['DateTime']['input'];
};


export type VmsBookableProductImageArgs = {
  input?: InputMaybe<VmsImageInput>;
};


export type VmsBookableProductPossibleSessionsArgs = {
  endDate: Scalars['DateTime']['input'];
  startDate: Scalars['DateTime']['input'];
};


export type VmsBookableProductSessionArgs = {
  uuid: Scalars['UUID']['input'];
};


export type VmsBookableProductSessionsArgs = {
  endDate: Scalars['DateTime']['input'];
  startDate: Scalars['DateTime']['input'];
};


export type VmsBookableProductTotalBookingsArgs = {
  endDate: Scalars['DateTime']['input'];
  startDate: Scalars['DateTime']['input'];
};


export type VmsBookableProductTotalCapacityArgs = {
  endDate: Scalars['DateTime']['input'];
  startDate: Scalars['DateTime']['input'];
};

export type VmsBookableProductConnection = {
  __typename?: 'VmsBookableProductConnection';
  edges: Array<Maybe<VmsBookableProductEdge>>;
  pageInfo: VmsPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type VmsBookableProductEdge = {
  __typename?: 'VmsBookableProductEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<VmsBookableProduct>;
};

export type VmsBookableProductsQueryFilterInput = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type VmsBookingMutations = {
  __typename?: 'VmsBookingMutations';
  /** Cancel check-in operation for an event. */
  cancelCheckIn: VmsMutationResult;
  cancelSession: VmsMutationResult;
  /** Check-in operation for an event. */
  checkIn: VmsMutationResult;
  createOrUpdateBookableProduct: VmsBookableProduct;
  notifyBookingUsers: VmsMutationResultV3;
};


export type VmsBookingMutationsCancelCheckInArgs = {
  sessionUuid: Scalars['UUID']['input'];
  siteUuid: Scalars['UUID']['input'];
  userUuid: Scalars['UUID']['input'];
};


export type VmsBookingMutationsCancelSessionArgs = {
  uuid: Scalars['UUID']['input'];
};


export type VmsBookingMutationsCheckInArgs = {
  sessionUuid: Scalars['UUID']['input'];
  siteUuid: Scalars['UUID']['input'];
  userUuid: Scalars['UUID']['input'];
};


export type VmsBookingMutationsCreateOrUpdateBookableProductArgs = {
  input: VmsCreateOrUpdateBookableProductInput;
};


export type VmsBookingMutationsNotifyBookingUsersArgs = {
  message: Scalars['String']['input'];
  orderUuids: Array<Scalars['UUID']['input']>;
  session: Scalars['UUID']['input'];
};

export type VmsCartDeliveryMethod = {
  __typename?: 'VmsCartDeliveryMethod';
  available: Scalars['Boolean']['output'];
  availableFrom?: Maybe<Scalars['DateTime']['output']>;
  availableUntil?: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  name: Scalars['String']['output'];
  portalCustomerInstructions?: Maybe<Scalars['String']['output']>;
  showVendorLocation: Scalars['Boolean']['output'];
  type: VmsDeliveryType;
  /** Portal vendor delivery method uuid */
  uuid: Scalars['UUID']['output'];
  vendorCustomerInstructions?: Maybe<Scalars['String']['output']>;
  vendorInstructions?: Maybe<Scalars['String']['output']>;
};

export type VmsChoice = VmsNode & {
  __typename?: 'VmsChoice';
  available: Scalars['Boolean']['output'];
  /** If true choice can be delete */
  canDelete?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  /**
   * @deprecated unit price is from mod choice
   * @deprecated unit price is from mod choice
   */
  unitPrice?: Maybe<Scalars['Int']['output']>;
  uuid: Scalars['UUID']['output'];
};

export type VmsChoiceConnection = {
  __typename?: 'VmsChoiceConnection';
  edges: Array<Maybe<VmsChoiceEdge>>;
  pageInfo: VmsPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type VmsChoiceEdge = {
  __typename?: 'VmsChoiceEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<VmsChoice>;
};

export type VmsChoicesSearchBy =
  | 'NAME';

export type VmsChoicesSearchFilter = {
  by: VmsChoicesSearchBy;
  value: Scalars['String']['input'];
};

/** Coordinates for a location */
export type VmsCoordinate = {
  __typename?: 'VmsCoordinate';
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
};

export type VmsCreateDiscountInput = {
  audience?: InputMaybe<VmsProductAudienceInput>;
  code: Scalars['String']['input'];
  /** dates of availability in the format [YYYY-MM-DD, YYYY-MM-DD) where [ is inclusive and ) is exclusive */
  dateRange?: InputMaybe<Scalars['String']['input']>;
  inclusions: VmsDiscountInclusionsInput;
  internalNotes?: InputMaybe<Scalars['String']['input']>;
  perUserLineItemLimit?: InputMaybe<Scalars['Int']['input']>;
  perUserOrderLimit?: InputMaybe<Scalars['Int']['input']>;
  perUserSpendLimit?: InputMaybe<Scalars['Int']['input']>;
  promotion?: InputMaybe<VmsDiscountPromotionInput>;
  /** Deprecated: Use audience input. */
  segment?: InputMaybe<VmsSegmentInput>;
  siteUuid: Scalars['UUID']['input'];
  termsAndConditions?: InputMaybe<Scalars['String']['input']>;
  timeRange?: InputMaybe<Array<VmsTradingHourInput>>;
  totalLineItemLimit?: InputMaybe<Scalars['Int']['input']>;
  totalOrderLimit?: InputMaybe<Scalars['Int']['input']>;
  totalSpendLimit?: InputMaybe<Scalars['Int']['input']>;
  type: VmsDiscountType;
  value: Scalars['Int']['input'];
};

export type VmsCreateOrUpdateBookableProductInput = {
  allowAttendees?: InputMaybe<Scalars['Boolean']['input']>;
  allowUserToCancel?: InputMaybe<Scalars['Boolean']['input']>;
  /** dates of availability in the format [YYYY-MM-DD, YYYY-MM-DD) where [ is inclusive and ) is exclusive */
  availabilityDateRange?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  focalPoint?: InputMaybe<VmsFocalPointInput>;
  imageKey?: InputMaybe<Scalars['String']['input']>;
  /** In minutes */
  minimumNoticeTimeLimit?: InputMaybe<Scalars['Int']['input']>;
  /** Mod input for booking products */
  mods?: InputMaybe<Array<VmsUpsertModInput>>;
  name: Scalars['String']['input'];
  /** Fields for which values will be provided while processing an order for this product. */
  outputFields?: InputMaybe<Array<VmsProductOutputFieldInput>>;
  productCategoryNames: Array<Scalars['String']['input']>;
  /** Profile fields which the customer is requested to provide when ordering this product. */
  profileFieldRequests?: InputMaybe<Array<VmsProductProfileFieldInput>>;
  sessionTemplates: Array<VmsSessionTemplateInput>;
  /** Deprecated: Use sites input. */
  siteUuids?: InputMaybe<Array<Scalars['UUID']['input']>>;
  /** Sites this subscription can be sold on with optional audience input */
  sites?: InputMaybe<Array<VmsSiteProductInput>>;
  taxRateUuids: Array<Scalars['UUID']['input']>;
  termsAndConditions?: InputMaybe<Scalars['String']['input']>;
  unitPrice: Scalars['Int']['input'];
  /** In minutes */
  userCancelTimeLimit?: InputMaybe<Scalars['Int']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
  vendorUuid: Scalars['UUID']['input'];
  visibility: VmsProductVisibility;
};

export type VmsCreateOrUpdateSubscriptionProductInput = {
  available: Scalars['Boolean']['input'];
  billingCycle: VmsSubscriptionBillingCycle;
  description?: InputMaybe<Scalars['String']['input']>;
  focalPoint?: InputMaybe<VmsFocalPointInput>;
  imageKey?: InputMaybe<Scalars['String']['input']>;
  limitedAvailability?: InputMaybe<Scalars['Boolean']['input']>;
  mods?: InputMaybe<Array<VmsUpsertModInput>>;
  name: Scalars['String']['input'];
  outputFields?: InputMaybe<Array<VmsProductOutputFieldInput>>;
  /** Deprecated: Use sites input. */
  portalUuids?: InputMaybe<Array<Scalars['UUID']['input']>>;
  productCategoryNames: Array<Scalars['String']['input']>;
  profileFieldRequests?: InputMaybe<Array<VmsProductProfileFieldInput>>;
  /** Sites this subscription can be sold on with optional audience input */
  sites?: InputMaybe<Array<VmsSiteProductInput>>;
  stock?: InputMaybe<Scalars['Int']['input']>;
  taxRateUuids: Array<Scalars['UUID']['input']>;
  termsAndConditions?: InputMaybe<Scalars['String']['input']>;
  turnAroundTime?: InputMaybe<Scalars['Int']['input']>;
  unitPrice: Scalars['Int']['input'];
  uuid?: InputMaybe<Scalars['UUID']['input']>;
  vendorUuid: Scalars['UUID']['input'];
  visibility: VmsProductVisibility;
};

export type VmsCreatePlainProductInput = {
  /** dates of availability in the format [YYYY-MM-DD, YYYY-MM-DD) where [ is inclusive and ) is exclusive */
  availabilityDateRange?: InputMaybe<Scalars['String']['input']>;
  /** Hours of availability, only relevant if limitedAvailability */
  availabilityHours?: InputMaybe<Array<VmsTradingHourInput>>;
  /** Mark product as available for purchase */
  available: Scalars['Boolean']['input'];
  /** Optional product description */
  description?: InputMaybe<Scalars['String']['input']>;
  focalPoint?: InputMaybe<VmsFocalPointInput>;
  /** Optional product image S3 key */
  imageKey?: InputMaybe<Scalars['String']['input']>;
  /** If true the product is only available for a limited set of trading hours */
  limitedAvailability?: InputMaybe<Scalars['Boolean']['input']>;
  mods?: InputMaybe<Array<VmsUpsertModInput>>;
  /** Name of the product */
  name: Scalars['String']['input'];
  /** Fields for which values will be provided while processing an order for this product. */
  outputFields?: InputMaybe<Array<VmsProductOutputFieldInput>>;
  /**
   * Portals this product can be sold on.
   * Deprecated: reason - Use sites input
   */
  portalUuids?: InputMaybe<Array<Scalars['UUID']['input']>>;
  /** New/existing product category names to associate with the product */
  productCategoryNames: Array<Scalars['String']['input']>;
  /** Profile fields which the customer is requested to provide when ordering this product. */
  profileFieldRequests?: InputMaybe<Array<VmsProductProfileFieldInput>>;
  /** Sites this product can be sold on with optional audience input */
  sites?: InputMaybe<Array<VmsSiteProductInput>>;
  /** How many units of this product are currently in stock */
  stock?: InputMaybe<Scalars['Int']['input']>;
  /** Taxes applicable for this product */
  taxRateUuids: Array<Scalars['UUID']['input']>;
  /** Terms and conditions. */
  termsAndConditions?: InputMaybe<Scalars['String']['input']>;
  /** Overridden turn around time in minutes for the product */
  turnAroundTime?: InputMaybe<Scalars['Int']['input']>;
  /** Unit price of the product in cents */
  unitPrice: Scalars['Int']['input'];
  /** Vendor who sells this product */
  vendorUuid: Scalars['UUID']['input'];
  /** Whether product is visible to customers on the store */
  visibility: VmsProductVisibility;
};

export type VmsCreateTaxRegionInput = {
  /** Applicable currency code for tax region */
  currencyCode: Scalars['String']['input'];
  name: Scalars['String']['input'];
  /** Sites which belong to this tax region. */
  sites?: InputMaybe<Array<Scalars['UUID']['input']>>;
  /**
   * Whether product unit prices are displayed inclusive or
   * exclusive of tax.
   */
  taxInclusive: Scalars['Boolean']['input'];
  taxRates: Array<VmsTaxRateInput>;
};

export type VmsCreateUploadUrlInput = {
  contentType: VmsImageType;
  filename: Scalars['String']['input'];
};

export type VmsCreateUploadUrlResult = {
  __typename?: 'VmsCreateUploadUrlResult';
  key: Scalars['String']['output'];
  signedUrl: Scalars['URI']['output'];
};

export type VmsCreateVendorAddressInput = {
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  postCode: Scalars['String']['input'];
  state: Scalars['String']['input'];
  street: Scalars['String']['input'];
};

export type VmsCreateVendorCategoryInput = {
  imageKey: Scalars['String']['input'];
  name: Scalars['String']['input'];
  weight?: InputMaybe<Scalars['Int']['input']>;
};

export type VmsCreateVendorInput = {
  /** Add current user as a vendor user of this vendor with full permissions. */
  addToVendorUser: Scalars['Boolean']['input'];
  /** The vendor's address */
  address?: InputMaybe<VmsCreateVendorAddressInput>;
  /** Enticing description of the vendor */
  description?: InputMaybe<Scalars['String']['input']>;
  focalPoint?: InputMaybe<VmsFocalPointInput>;
  /**
   * Key contact details for the vendor, in the
   * event that Equiem wishes to contact them.
   */
  keyContact: VmsVendorKeyContactInput;
  /** Customer friendly vendor name */
  name: Scalars['String']['input'];
  /** Vendor phone number for customer enquiries */
  phone?: InputMaybe<Scalars['String']['input']>;
  /** Cover image for the vendor */
  profileImage?: InputMaybe<Scalars['String']['input']>;
  /** Company tax number */
  taxId: Scalars['String']['input'];
  /** Used when the addToVendorUser assigned to true. */
  termsAndConditionsAccepted: Scalars['Boolean']['input'];
  /** Vendor's 3rd party ordering system */
  thirdPartyUrl?: InputMaybe<Scalars['URI']['input']>;
  /** Mandatory trading hours for the vendor */
  tradingHours: Array<VmsTradingHourInput>;
  /** Tax compliant vendor name */
  tradingName: Scalars['String']['input'];
  /**
   * Existing vendor categories to associate with the vendor.
   * These are created per region by a super admin(?)
   */
  vendorCategoryUuids: Array<Scalars['String']['input']>;
  /** The vendor's website URL */
  website?: InputMaybe<Scalars['URI']['input']>;
};

/** Currency label */
export type VmsCurrency = {
  __typename?: 'VmsCurrency';
  /** e.g. AUD */
  code: Scalars['String']['output'];
  /** e.g. Australian Dollar */
  name: Scalars['String']['output'];
};

export type VmsCurrencyReport = {
  __typename?: 'VmsCurrencyReport';
  currencyCode: Scalars['String']['output'];
  totalPretax: Scalars['Int']['output'];
  totalTax: Scalars['Int']['output'];
  vendorPretax: Scalars['Int']['output'];
  vendorTax: Scalars['Int']['output'];
};

/** Day of the week for opening hours */
export type VmsDayOfTheWeek =
  | 'FRIDAY'
  | 'MONDAY'
  | 'SATURDAY'
  | 'SUNDAY'
  | 'THURSDAY'
  | 'TUESDAY'
  | 'WEDNESDAY';

export type VmsDeal = {
  __typename?: 'VmsDeal';
  affiliateLinkName?: Maybe<Scalars['String']['output']>;
  affiliateLinkUrl?: Maybe<Scalars['String']['output']>;
  availability: VmsProductAvailability;
  body: Scalars['String']['output'];
  dealType: VmsDealType;
  description?: Maybe<Scalars['String']['output']>;
  focalPoint?: Maybe<VmsFocalPoint>;
  image?: Maybe<Scalars['URI']['output']>;
  imageKey?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  productCategories: Array<VmsProductCategory>;
  /** Whether to show the overlay icons or not */
  showOverlay: Scalars['Boolean']['output'];
  showTermsAndConditions: Scalars['Boolean']['output'];
  /** @deprecated use sitesWithAudience endpoint */
  sites: Array<VmsPortal>;
  /** Portals associated with the product */
  sitesWithAudience: Array<VmsSiteSegment>;
  termsAndConditions?: Maybe<Scalars['String']['output']>;
  uuid: Scalars['UUID']['output'];
  vendor: VmsVendorInterface;
  visibility: VmsProductVisibility;
};


export type VmsDealImageArgs = {
  input?: InputMaybe<VmsImageInput>;
};

export type VmsDealConnection = {
  __typename?: 'VmsDealConnection';
  edges: Array<Maybe<VmsDealEdge>>;
  pageInfo: VmsPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type VmsDealEdge = {
  __typename?: 'VmsDealEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<VmsDeal>;
};

export type VmsDealType =
  | 'AFFILIATE_LINK'
  | 'NON_REDEEMABLE'
  | 'REDEEMABLE';

export type VmsDealVendor = VmsVendorInterface & {
  __typename?: 'VmsDealVendor';
  /** Combined address field */
  address: Scalars['String']['output'];
  /** City component of address */
  city?: Maybe<Scalars['String']['output']>;
  country: Scalars['String']['output'];
  countryCode: Scalars['String']['output'];
  /** Short description of the vendor */
  description?: Maybe<Scalars['String']['output']>;
  focalPoint?: Maybe<VmsFocalPoint>;
  image?: Maybe<Scalars['URI']['output']>;
  imageKey?: Maybe<Scalars['String']['output']>;
  location: VmsCoordinate;
  /** Customer friendly vendor name */
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  portals: Array<VmsPortal>;
  /** Post code component of address */
  postCode?: Maybe<Scalars['String']['output']>;
  /** Product categories that vendor has, e.g Drinks */
  productCategories: Array<VmsProductCategory>;
  productCount: Scalars['Int']['output'];
  products: VmsDealConnection;
  reportingCategory: VmsReportingCategory;
  /** State component of address */
  state?: Maybe<Scalars['String']['output']>;
  /** Street component of address */
  street?: Maybe<Scalars['String']['output']>;
  thirdPartyUrl?: Maybe<Scalars['URI']['output']>;
  uuid: Scalars['UUID']['output'];
  /** Vendor categories e.g. Breakfast, Lunch, Dinner */
  vendorCategories: Array<VmsVendorCategory>;
  visibilityStatus: VmsVendorVisibilityStatus;
  website?: Maybe<Scalars['String']['output']>;
};


export type VmsDealVendorImageArgs = {
  input?: InputMaybe<VmsImageInput>;
};


export type VmsDealVendorPortalsArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
};


export type VmsDealVendorProductCategoriesArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
};


export type VmsDealVendorProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<VmsProductFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Array<VmsVendorProductsSearchFilter>>;
};

export type VmsDealVendorConnection = {
  __typename?: 'VmsDealVendorConnection';
  edges: Array<Maybe<VmsDealVendorEdge>>;
  pageInfo: VmsPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type VmsDealVendorEdge = {
  __typename?: 'VmsDealVendorEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<VmsDealVendor>;
};

export type VmsDealVendorOrderBy = {
  descending: Scalars['Boolean']['input'];
  field: VmsDealVendorSortableFields;
};

export type VmsDealVendorSortableFields =
  | 'NAME'
  | 'SITES'
  | 'STATUS';

export type VmsDeliveryLocation = {
  __typename?: 'VmsDeliveryLocation';
  name: Scalars['String']['output'];
  uuid: Scalars['UUID']['output'];
};

export type VmsDeliveryMethod = {
  __typename?: 'VmsDeliveryMethod';
  active: Scalars['Boolean']['output'];
  availableFrom?: Maybe<Scalars['DateTime']['output']>;
  availableFromTime?: Maybe<Scalars['Time']['output']>;
  availableUntil?: Maybe<Scalars['DateTime']['output']>;
  availableUntilTime?: Maybe<Scalars['Time']['output']>;
  description: Scalars['String']['output'];
  name: Scalars['String']['output'];
  portalCustomerInstructions?: Maybe<Scalars['String']['output']>;
  showVendorLocation: Scalars['Boolean']['output'];
  type: VmsDeliveryType;
  uuid: Scalars['UUID']['output'];
  /**
   * @deprecated field is on portal vendor delivery method
   * @deprecated field is on portal vendor delivery method
   */
  vendorCustomerInstructions?: Maybe<Scalars['String']['output']>;
  vendorInstructions?: Maybe<Scalars['String']['output']>;
};

export type VmsDeliveryType =
  | 'DELIVERY'
  | 'PICKUP';

export type VmsDiscount = {
  __typename?: 'VmsDiscount';
  audience?: Maybe<VmsProductAudience>;
  availability: VmsDiscountAvailability;
  available: Scalars['Boolean']['output'];
  changeLog: Array<VmsDiscountChangeLog>;
  code: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  inclusions: VmsDiscountInclusions;
  internalNotes?: Maybe<Scalars['String']['output']>;
  owningVendor?: Maybe<VmsVendor>;
  perUserLineItemLimit?: Maybe<VmsQuantityLimit>;
  perUserOrderLimit?: Maybe<VmsQuantityLimit>;
  perUserSpendLimit?: Maybe<VmsQuantityLimit>;
  promotion: VmsDiscountPromotion;
  /** @deprecated Use audience field */
  segment?: Maybe<VmsSegment>;
  site: VmsPortal;
  siteReport: VmsSiteDiscountReport;
  status: VmsDiscountStatus;
  termsAndConditions?: Maybe<Scalars['String']['output']>;
  totalLineItemLimit?: Maybe<VmsQuantityLimit>;
  totalOrderLimit?: Maybe<VmsQuantityLimit>;
  totalSpendLimit?: Maybe<VmsQuantityLimit>;
  updated: Scalars['DateTime']['output'];
  userTermsAndConditions?: Maybe<Scalars['String']['output']>;
  uuid: Scalars['UUID']['output'];
  value: VmsDiscountValue;
  vendorReports: Array<Maybe<VmsVendorDiscountReport>>;
};

export type VmsDiscountAvailability = {
  __typename?: 'VmsDiscountAvailability';
  dateRange?: Maybe<VmsAvailabilityDateRange>;
  groupedTimes: Array<VmsGroupedTradingHour>;
  limitedAvailability: Scalars['Boolean']['output'];
  times?: Maybe<Array<VmsTradingHour>>;
};

export type VmsDiscountChangeLog = {
  __typename?: 'VmsDiscountChangeLog';
  after: Scalars['String']['output'];
  before: Scalars['String']['output'];
  date: Scalars['DateTime']['output'];
  field: Scalars['String']['output'];
  updatedBy?: Maybe<FullUser>;
};

export type VmsDiscountConnection = {
  __typename?: 'VmsDiscountConnection';
  edges: Array<Maybe<VmsDiscountEdge>>;
  pageInfo: VmsPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type VmsDiscountEdge = {
  __typename?: 'VmsDiscountEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<VmsDiscount>;
};

export type VmsDiscountFilter = {
  code?: InputMaybe<Scalars['String']['input']>;
  product?: InputMaybe<Scalars['UUID']['input']>;
  promoted?: InputMaybe<VmsPromotedFilter>;
  site?: InputMaybe<Scalars['UUID']['input']>;
  status?: InputMaybe<Array<VmsDiscountStatus>>;
  vendor?: InputMaybe<Scalars['UUID']['input']>;
};

export type VmsDiscountInclusions = {
  __typename?: 'VmsDiscountInclusions';
  products?: Maybe<Array<VmsProduct>>;
};

export type VmsDiscountInclusionsInput = {
  products: Array<Scalars['UUID']['input']>;
};

export type VmsDiscountMutationFailure = {
  __typename?: 'VmsDiscountMutationFailure';
  reason: Scalars['String']['output'];
};

export type VmsDiscountMutationResult = VmsDiscountMutationFailure | VmsDiscountMutationSuccess;

export type VmsDiscountMutationSuccess = {
  __typename?: 'VmsDiscountMutationSuccess';
  discount: VmsDiscount;
};

export type VmsDiscountPagination = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type VmsDiscountPromotion = {
  __typename?: 'VmsDiscountPromotion';
  promoted: Scalars['Boolean']['output'];
  subtitle?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type VmsDiscountPromotionInput = {
  promoted?: InputMaybe<Scalars['Boolean']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type VmsDiscountStatus =
  | 'ACTIVE'
  | 'COMPLETED'
  | 'INACTIVE';

export type VmsDiscountType =
  | 'AMOUNT'
  | 'PERCENTAGE';

export type VmsDiscountValue = VmsDollarDiscount | VmsPercentageDiscount;

export type VmsDiscountVendorInclusionInput = {
  productExclusions: Array<Scalars['UUID']['input']>;
  vendorUuid: Scalars['UUID']['input'];
};

export type VmsDollarDiscount = {
  __typename?: 'VmsDollarDiscount';
  cents: Scalars['Int']['output'];
};

export type VmsFocalPoint = {
  __typename?: 'VmsFocalPoint';
  x: Scalars['Float']['output'];
  y: Scalars['Float']['output'];
};

export type VmsFocalPointInput = {
  debug?: InputMaybe<Scalars['Boolean']['input']>;
  x: Scalars['Float']['input'];
  y: Scalars['Float']['input'];
};

/** Represents the opening hours of a vendor on a given day */
export type VmsGroupedTradingHour = {
  __typename?: 'VmsGroupedTradingHour';
  /** Closing time of the store in 24hr time. Format: 14:38  */
  close: Scalars['String']['output'];
  days: Array<VmsDayOfTheWeek>;
  /** Opening time of the store in 24hr time. Format: 10:12  */
  open: Scalars['String']['output'];
};

export type VmsIPaymentFailure = {
  code: VmsPaymentFailureCodes;
  message?: Maybe<Scalars['String']['output']>;
};

export type VmsImage = {
  __typename?: 'VmsImage';
  focalPoint?: Maybe<VmsFocalPoint>;
  imageKey?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['URI']['output']>;
};


export type VmsImageUrlArgs = {
  input?: InputMaybe<VmsImageInput>;
};

export type VmsImageInput = {
  focalPoint?: InputMaybe<VmsFocalPointInput>;
  /** Desired height. */
  height?: InputMaybe<Scalars['Int']['input']>;
  /** Preset image settings */
  preset?: InputMaybe<VmsImagePreset>;
  /** Desired width. */
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type VmsImagePreset =
  | 'THUMBNAIL';

export type VmsImageType =
  | 'GIF'
  | 'JPEG'
  | 'PNG';

export type VmsInviteSiteManagerInput = {
  email: Scalars['String']['input'];
  permissionNames: Array<VmsStoreUserPermission>;
  siteUuid: Scalars['String']['input'];
};

export type VmsInviteVendorUserInput = {
  email: Scalars['String']['input'];
  permissionNames: Array<VmsStoreUserPermission>;
  vendorUuid: Scalars['String']['input'];
};

export type VmsLineItem = {
  __typename?: 'VmsLineItem';
  /** comments coming in from the customer regarding this line item */
  comments?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  /** adjustment description */
  description?: Maybe<Scalars['String']['output']>;
  discount?: Maybe<VmsDiscount>;
  discountTotal: Scalars['Int']['output'];
  exTaxDiscountTotal: Scalars['Int']['output'];
  incTaxDiscountTotal: Scalars['Int']['output'];
  /** Whether this line item is a refund */
  isRefund: Scalars['Boolean']['output'];
  mods?: Maybe<Array<VmsLineItemMod>>;
  preDiscountTotal: Scalars['Int']['output'];
  product?: Maybe<VmsProduct>;
  profile?: Maybe<Profile>;
  quantity: Scalars['Int']['output'];
  /** Sub total of line item excluding modifiers */
  subTotal: Scalars['Int']['output'];
  /** Sub total of line item including modifiers */
  subTotalIncludingMods: Scalars['Int']['output'];
  /** The UUID of the user who added this line item (not set for customer-added line items). */
  userUuid?: Maybe<Scalars['UUID']['output']>;
  uuid: Scalars['String']['output'];
  /** An optional comment applied to the line item from the vendor */
  vendorComment?: Maybe<Scalars['String']['output']>;
};

export type VmsLineItemChoice = {
  __typename?: 'VmsLineItemChoice';
  /** Name of the choice or text value. */
  description: Scalars['String']['output'];
  /** Mod Choice combination price added. */
  unitPrice: Scalars['Int']['output'];
  /** Choice UUID, This can be null for mod free text. */
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export type VmsLineItemMod = {
  __typename?: 'VmsLineItemMod';
  /** List of choices added to the mod. */
  choices?: Maybe<Array<VmsLineItemChoice>>;
  /** Mod name. */
  name: Scalars['String']['output'];
  /** Type of the mod. */
  type: VmsModType;
  /** Mod UUID. */
  uuid: Scalars['UUID']['output'];
};

export type VmsLocalisedMessage = {
  __typename?: 'VmsLocalisedMessage';
  fallback: Scalars['String']['output'];
  key: Scalars['String']['output'];
  variablesJSON?: Maybe<Scalars['String']['output']>;
};

export type VmsMobileDiscountValidationResult = VmsDiscountMutationFailure | VmsDiscountMutationSuccess | VmsNullDiscountCode;

export type VmsMod = VmsNode & {
  __typename?: 'VmsMod';
  /**
   * @deprecated use modChoices instead
   * @deprecated use modChoices instead
   */
  choices?: Maybe<Array<Maybe<VmsChoice>>>;
  mandatory: Scalars['Boolean']['output'];
  modChoices?: Maybe<Array<VmsModChoice>>;
  name: Scalars['String']['output'];
  type: VmsModType;
  uuid: Scalars['UUID']['output'];
};

export type VmsModChoice = VmsNode & {
  __typename?: 'VmsModChoice';
  choice?: Maybe<VmsChoice>;
  unitPrice: Scalars['Int']['output'];
  uuid: Scalars['UUID']['output'];
  weight: Scalars['Int']['output'];
};

export type VmsModType =
  | 'FREE_TEXT'
  | 'MULTI_CHOICE'
  | 'SINGLE_CHOICE';

export type VmsMutation = {
  __typename?: 'VmsMutation';
  /** Accept policies for current user */
  acceptPolicy: VmsMutationResult;
  acceptSiteManagerInvitation: VmsMutationResult;
  acceptVendorUserInvitation: VmsMutationResult;
  addSiteDeliveryMethod: VmsDeliveryMethod;
  addSiteManager: VmsStoreUser;
  addSiteVendor: VmsMutationResult;
  addVendorUser: VmsStoreUser;
  bookingMutations: VmsBookingMutations;
  cancelSubscription: VmsProductSubscription;
  copyProduct: VmsProduct;
  createDiscount: VmsDiscountMutationResult;
  createOrUpdateDeal: VmsDeal;
  createOrUpdateDealVendor: VmsDealVendor;
  createOrUpdateLineItemVendorComment: VmsMutationResultV3;
  createOrUpdateSubscriptionProduct: VmsSubscriptionProduct;
  createPlainProduct: VmsProduct;
  createProductUploadUrl: VmsCreateUploadUrlResult;
  createTaxRegion?: Maybe<VmsTaxRegion>;
  createVendor: VmsVendor;
  createVendorCategory: VmsVendorCategory;
  createVendorCategoryUploadUrl: VmsCreateUploadUrlResult;
  createVendorUploadUrl: VmsCreateUploadUrlResult;
  deleteChoice: VmsMutationResult;
  deleteDeal: VmsMutationResult;
  deleteDealVendor: VmsMutationResult;
  deleteDiscount: VmsDiscountMutationResult;
  deletePlainProduct: VmsMutationResult;
  deleteSiteDeliveryMethod: VmsMutationResult;
  deleteUser: VmsMutationResult;
  deleteVendor: VmsMutationResult;
  deleteVendorCategory: VmsMutationResult;
  inviteSiteManager: VmsMutationResult;
  inviteVendorUser: VmsMutationResult;
  /** @deprecated Field no longer supported */
  makeOrderAdjustmentV2: VmsOrderAdjustmentOutput;
  makeOrderAdjustmentV3: VmsOrderAdjustmentOutputV2;
  patchDiscount: VmsDiscountMutationResult;
  pauseOrResumeSubscription: VmsProductSubscription;
  processOrderRefund: VmsMutationResult;
  refundLineItems: VmsRefundLineItemsResult;
  removeSiteManager: VmsMutationResult;
  removeSiteManagerInvitation: VmsMutationResult;
  removeSiteVendor: VmsMutationResult;
  /** Remove the Vendor's Stripe Connect account. */
  removeVendorStripeAccount: VmsMutationResult;
  removeVendorUser: VmsMutationResult;
  removeVendorUserInvitation: VmsMutationResult;
  /** Update display order of product categories belonging to a vendor */
  reorderProductCategories: VmsMutationResult;
  /** Update display order of products within a category */
  reorderProductsInCategory: VmsMutationResult;
  /** Only used for failed Ubipark orders. Not used for any other order types atm. */
  retryFailedOrder: VmsMutationResult;
  retrySubscriptionCharge: VmsProductSubscription;
  skipVendorStripeAccountSetup: VmsMutationResult;
  updateChoiceAvailability: VmsMutationResult;
  updateDealVisibility: VmsDeal;
  updateDiscount: VmsDiscountMutationResult;
  updateEquiemAdmin: Scalars['Boolean']['output'];
  /** Sets / replaces the product output fields on the order with the given uuid. */
  updateOrderProductOutputFields: VmsOrder;
  updateOrderStatus: VmsOrder;
  updatePlainProduct: VmsProduct;
  updatePlainProductOutOfStock: VmsProduct;
  updateProductVisibility: VmsProduct;
  updateSite: VmsPortal;
  updateSiteDeliveryMethod: VmsDeliveryMethod;
  /** @deprecated use updateSiteManagerV2 */
  updateSiteManager: VmsStoreUser;
  updateSiteManagerV2: VmsStoreUser;
  updateSiteSettings: VmsPortal;
  updateTaxRate: VmsTaxRate;
  updateTaxRegion?: Maybe<VmsTaxRegion>;
  updateVendor: VmsVendor;
  updateVendorCategory: VmsVendorCategory;
  /** @deprecated use updateVendorUserV2 */
  updateVendorUser: VmsStoreUser;
  updateVendorUserV2: VmsStoreUser;
  /**
   * @deprecated use vendor settings mutation
   * @deprecated use vendor settings mutation
   */
  updateVendorVisibilityStatus: VmsVendor;
};


export type VmsMutationAcceptPolicyArgs = {
  types: Array<VmsPolicyType>;
};


export type VmsMutationAcceptSiteManagerInvitationArgs = {
  acceptPolicies: Scalars['Boolean']['input'];
  invitationUuid: Scalars['UUID']['input'];
};


export type VmsMutationAcceptVendorUserInvitationArgs = {
  acceptPolicies: Scalars['Boolean']['input'];
  invitationUuid: Scalars['UUID']['input'];
};


export type VmsMutationAddSiteDeliveryMethodArgs = {
  input: VmsAddOrUpdateDeliveryMethodInput;
};


export type VmsMutationAddSiteManagerArgs = {
  input: VmsAddOrUpdateSiteManagerInput;
};


export type VmsMutationAddSiteVendorArgs = {
  input: VmsAddOrRemoveSiteVendorInput;
};


export type VmsMutationAddVendorUserArgs = {
  input: VmsAddOrUpdateVendorUserInput;
};


export type VmsMutationCancelSubscriptionArgs = {
  subscriptionUuid: Scalars['UUID']['input'];
};


export type VmsMutationCopyProductArgs = {
  productUuid: Scalars['UUID']['input'];
};


export type VmsMutationCreateDiscountArgs = {
  input: VmsCreateDiscountInput;
  vendor?: InputMaybe<Scalars['UUID']['input']>;
};


export type VmsMutationCreateOrUpdateDealArgs = {
  input: VmsAddOrUpdateDealInput;
};


export type VmsMutationCreateOrUpdateDealVendorArgs = {
  input: VmsAddOrUpdateDealsVendorInput;
};


export type VmsMutationCreateOrUpdateLineItemVendorCommentArgs = {
  comment: Scalars['String']['input'];
  lineItemUuid: Scalars['UUID']['input'];
};


export type VmsMutationCreateOrUpdateSubscriptionProductArgs = {
  input: VmsCreateOrUpdateSubscriptionProductInput;
};


export type VmsMutationCreatePlainProductArgs = {
  input: VmsCreatePlainProductInput;
};


export type VmsMutationCreateProductUploadUrlArgs = {
  input: VmsCreateUploadUrlInput;
};


export type VmsMutationCreateTaxRegionArgs = {
  input: VmsCreateTaxRegionInput;
};


export type VmsMutationCreateVendorArgs = {
  input: VmsCreateVendorInput;
};


export type VmsMutationCreateVendorCategoryArgs = {
  input: VmsCreateVendorCategoryInput;
};


export type VmsMutationCreateVendorCategoryUploadUrlArgs = {
  input: VmsCreateUploadUrlInput;
};


export type VmsMutationCreateVendorUploadUrlArgs = {
  input: VmsCreateUploadUrlInput;
};


export type VmsMutationDeleteChoiceArgs = {
  uuid: Scalars['UUID']['input'];
};


export type VmsMutationDeleteDealArgs = {
  uuid: Scalars['UUID']['input'];
};


export type VmsMutationDeleteDealVendorArgs = {
  uuid: Scalars['UUID']['input'];
};


export type VmsMutationDeleteDiscountArgs = {
  uuid: Scalars['UUID']['input'];
};


export type VmsMutationDeletePlainProductArgs = {
  uuid: Scalars['UUID']['input'];
};


export type VmsMutationDeleteSiteDeliveryMethodArgs = {
  uuid: Scalars['UUID']['input'];
};


export type VmsMutationDeleteUserArgs = {
  userUuid: Scalars['UUID']['input'];
};


export type VmsMutationDeleteVendorArgs = {
  vendorUuid: Scalars['UUID']['input'];
};


export type VmsMutationDeleteVendorCategoryArgs = {
  uuid: Scalars['UUID']['input'];
};


export type VmsMutationInviteSiteManagerArgs = {
  input: VmsInviteSiteManagerInput;
};


export type VmsMutationInviteVendorUserArgs = {
  input: VmsInviteVendorUserInput;
};


export type VmsMutationMakeOrderAdjustmentV2Args = {
  orderAdjustmentInput: VmsOrderAdjustmentInputV2;
};


export type VmsMutationMakeOrderAdjustmentV3Args = {
  orderAdjustmentInput: VmsOrderAdjustmentInputV2;
};


export type VmsMutationPatchDiscountArgs = {
  input: VmsUpdateDiscountInput;
};


export type VmsMutationPauseOrResumeSubscriptionArgs = {
  action: VmsPauseOrResumeSubscriptionAction;
  subscriptionUuid: Scalars['UUID']['input'];
};


export type VmsMutationProcessOrderRefundArgs = {
  orderUuid: Scalars['UUID']['input'];
};


export type VmsMutationRefundLineItemsArgs = {
  input: VmsRefundLineItemsInput;
};


export type VmsMutationRemoveSiteManagerArgs = {
  siteUuid: Scalars['UUID']['input'];
  userUuid: Scalars['UUID']['input'];
};


export type VmsMutationRemoveSiteManagerInvitationArgs = {
  invitationUuid: Scalars['UUID']['input'];
};


export type VmsMutationRemoveSiteVendorArgs = {
  input: VmsAddOrRemoveSiteVendorInput;
};


export type VmsMutationRemoveVendorStripeAccountArgs = {
  uuid: Scalars['UUID']['input'];
};


export type VmsMutationRemoveVendorUserArgs = {
  userUuid: Scalars['UUID']['input'];
  vendorUuid: Scalars['UUID']['input'];
};


export type VmsMutationRemoveVendorUserInvitationArgs = {
  invitationUuid: Scalars['UUID']['input'];
};


export type VmsMutationReorderProductCategoriesArgs = {
  productCategoryUuids: Array<Scalars['UUID']['input']>;
  vendorUuid: Scalars['UUID']['input'];
};


export type VmsMutationReorderProductsInCategoryArgs = {
  productCategoryUuid: Scalars['UUID']['input'];
  productUuids: Array<Scalars['UUID']['input']>;
};


export type VmsMutationRetryFailedOrderArgs = {
  orderUuid: Scalars['UUID']['input'];
};


export type VmsMutationRetrySubscriptionChargeArgs = {
  subscriptionUuid: Scalars['UUID']['input'];
};


export type VmsMutationSkipVendorStripeAccountSetupArgs = {
  vendorUuid: Scalars['UUID']['input'];
};


export type VmsMutationUpdateChoiceAvailabilityArgs = {
  available: Scalars['Boolean']['input'];
  uuid: Scalars['UUID']['input'];
};


export type VmsMutationUpdateDealVisibilityArgs = {
  dealUuid: Scalars['UUID']['input'];
  status: VmsProductVisibility;
};


export type VmsMutationUpdateDiscountArgs = {
  input: VmsUpdateDiscountInput;
};


export type VmsMutationUpdateEquiemAdminArgs = {
  isAdmin: Scalars['Boolean']['input'];
  userUuid: Scalars['UUID']['input'];
};


export type VmsMutationUpdateOrderProductOutputFieldsArgs = {
  fieldValues: Array<VmsProductOutputFieldValueInput>;
  uuid: Scalars['UUID']['input'];
};


export type VmsMutationUpdateOrderStatusArgs = {
  status: VmsOrderStatus;
  uuid: Scalars['UUID']['input'];
};


export type VmsMutationUpdatePlainProductArgs = {
  input: VmsUpdatePlainProductInput;
};


export type VmsMutationUpdatePlainProductOutOfStockArgs = {
  outOfStock: Scalars['Boolean']['input'];
  productUuid: Scalars['UUID']['input'];
};


export type VmsMutationUpdateProductVisibilityArgs = {
  productUuid: Scalars['UUID']['input'];
  status: VmsProductVisibility;
};


export type VmsMutationUpdateSiteArgs = {
  input: VmsAddOrUpdateSiteInput;
};


export type VmsMutationUpdateSiteDeliveryMethodArgs = {
  deliveryMethodUuid: Scalars['UUID']['input'];
  input: VmsAddOrUpdateDeliveryMethodInput;
};


export type VmsMutationUpdateSiteManagerArgs = {
  input: VmsAddOrUpdateSiteManagerInput;
};


export type VmsMutationUpdateSiteManagerV2Args = {
  input: VmsAddOrUpdateSiteManagerInputV2;
};


export type VmsMutationUpdateSiteSettingsArgs = {
  input: VmsUpdateSiteSettingsInput;
};


export type VmsMutationUpdateTaxRateArgs = {
  input: VmsTaxRateInput;
};


export type VmsMutationUpdateTaxRegionArgs = {
  input: VmsUpdateTaxRegionInput;
};


export type VmsMutationUpdateVendorArgs = {
  input: VmsUpdateVendorInput;
};


export type VmsMutationUpdateVendorCategoryArgs = {
  input: VmsUpdateVendorCategoryInput;
};


export type VmsMutationUpdateVendorUserArgs = {
  input: VmsAddOrUpdateVendorUserInput;
};


export type VmsMutationUpdateVendorUserV2Args = {
  input: VmsAddOrUpdateVendorUserInputV2;
};


export type VmsMutationUpdateVendorVisibilityStatusArgs = {
  status: VmsVendorVisibilityStatus;
  vendorUuid: Scalars['UUID']['input'];
};

export type VmsMutationFailure = {
  __typename?: 'VmsMutationFailure';
  reason: Scalars['String']['output'];
};

/** This type exists because GraphQL doesn't support returning null/void. */
export type VmsMutationResult = {
  __typename?: 'VmsMutationResult';
  /**
   * If returned, this should always be true. The case where this is false the whole object should be null
   * with an error reported via GraphQL's normal error reporting mechanisms.
   */
  success: Scalars['Boolean']['output'];
};

export type VmsMutationResultV2 = VmsMutationFailure | VmsMutationSuccess;

export type VmsMutationResultV3 = {
  __typename?: 'VmsMutationResultV3';
  /** Any error, warning or success messages. */
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type VmsMutationSuccess = {
  __typename?: 'VmsMutationSuccess';
  success: Scalars['Boolean']['output'];
};

export type VmsNode = {
  uuid: Scalars['UUID']['output'];
};

export type VmsNullDiscountCode = {
  __typename?: 'VmsNullDiscountCode';
  reason: Scalars['String']['output'];
};

export type VmsOrder = VmsNode & {
  __typename?: 'VmsOrder';
  /** The mutations which the current user can perform on this order. */
  allowedActions: VmsAllowedOrderActions;
  /** The name of the customer's company */
  companyName: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  currencyCode: Scalars['String']['output'];
  /** Additional comments the customer may have for an order */
  customerComments?: Maybe<Scalars['String']['output']>;
  /** The location of the customer (eg. 1st desk level 4) for DELIVERY type orders */
  customerLocation?: Maybe<Scalars['String']['output']>;
  /** The name of the customer */
  customerName?: Maybe<Scalars['String']['output']>;
  customerUuid: Scalars['UUID']['output'];
  delay?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Please use customerLocation field instead */
  deliveryAddress?: Maybe<Scalars['String']['output']>;
  deliveryMethod?: Maybe<VmsCartDeliveryMethod>;
  discount?: Maybe<VmsDiscount>;
  discountTotal: Scalars['Int']['output'];
  eta?: Maybe<Scalars['DateTime']['output']>;
  exTaxDiscountTotal: Scalars['Int']['output'];
  exTaxTotal: Scalars['Int']['output'];
  /** Short description of where the customer will receive their order */
  fulfilmentLocation: Scalars['String']['output'];
  hasBookableProduct: Scalars['Boolean']['output'];
  /** Default image for the order */
  image?: Maybe<Scalars['URI']['output']>;
  incTaxDiscountTotal: Scalars['Int']['output'];
  incTaxTotal: Scalars['Int']['output'];
  lineItems: Array<VmsLineItem>;
  /** A time-ordered list of events logged for this order. */
  log: Array<VmsOrderLogEvent>;
  /** Fields for which values will be provided while processing this order. */
  outputFields: Array<VmsProductOutputFieldValue>;
  portal: VmsPortal;
  preDiscountTotal: Scalars['Int']['output'];
  profile?: Maybe<FullProfile>;
  /** Profile fields which the customer provided when placing this order. */
  profileFields: Array<VmsProfileFieldValue>;
  referenceId: Scalars['String']['output'];
  status: VmsOrderStatus;
  steps: Array<Maybe<VmsOrderSteps>>;
  /** An optional subscription associated with this order. */
  subscription?: Maybe<VmsProductSubscription>;
  tax: Scalars['Int']['output'];
  /**
   * If there are multiple products with different turn around times,
   * highest time will be returned. In case none of the products have turn around times,
   * the vendors turn around time will be returned. In the absense of vendor turn around
   * time, null will be returned.
   */
  turnAroundTime?: Maybe<Scalars['Int']['output']>;
  updated: Scalars['DateTime']['output'];
  uuid: Scalars['UUID']['output'];
  vendor: VmsVendor;
};


export type VmsOrderImageArgs = {
  input?: InputMaybe<VmsImageInput>;
};

export type VmsOrderAdjustmentInputV2 = {
  /** Amount to adjust order in lowest denomination e.g. cents, pence etc */
  amount: Scalars['Int']['input'];
  /** Adjustment description that goes on the customer invoice */
  description: Scalars['String']['input'];
  /** UUID of the order to update  */
  orderUuid: Scalars['String']['input'];
  /** Tax rate UUID */
  taxRateUuids: Array<Scalars['UUID']['input']>;
};

export type VmsOrderAdjustmentOutput = VmsAdjustmentFailure | VmsOrderAdjustmentSuccess;

export type VmsOrderAdjustmentOutputV2 = VmsAdjustmentFailureV2 | VmsOrderAdjustmentSuccess;

/** The data payload attached to an order adjustment push notification. */
export type VmsOrderAdjustmentPushNotificationItem = {
  __typename?: 'VmsOrderAdjustmentPushNotificationItem';
  /** The dollar amount the order was adjusted with */
  amount: Scalars['String']['output'];
  /** The uuid of the customer who placed the order that was adjusted */
  customer: Scalars['UUID']['output'];
  /** Order adjustment reason */
  description: Scalars['String']['output'];
  /** The uuid of the order which was adjusted. */
  order: Scalars['UUID']['output'];
  /** The portal on which the order was placed. */
  portal: Scalars['UUID']['output'];
};

export type VmsOrderAdjustmentSuccess = {
  __typename?: 'VmsOrderAdjustmentSuccess';
  /** The order that was adjusted. */
  order: VmsOrder;
  /** Whether the adjustment succeeded. */
  success: Scalars['Boolean']['output'];
};

export type VmsOrderConnection = {
  __typename?: 'VmsOrderConnection';
  edges: Array<Maybe<VmsOrderEdge>>;
  pageInfo: VmsPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Per status order count */
export type VmsOrderCount = {
  __typename?: 'VmsOrderCount';
  count: Scalars['Int']['output'];
  status: VmsOrderStatus;
};

/** Filters on the order count query. */
export type VmsOrderCountFilterInput = {
  /** Match orders from customer whose name contains this string. */
  customerName?: InputMaybe<Scalars['String']['input']>;
  /** Match orders with these delivery methods */
  deliveryMethods?: InputMaybe<Array<Scalars['UUID']['input']>>;
  /** Match orders with these portal vendor delivery methods */
  portalVendorDeliveryMethods?: InputMaybe<Array<Scalars['UUID']['input']>>;
  /** Match orders on these portals. */
  portals?: InputMaybe<Array<Scalars['UUID']['input']>>;
  /** Match orders to these vendors. */
  vendors?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type VmsOrderEdge = {
  __typename?: 'VmsOrderEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<VmsOrder>;
};

/** Ways to identify an Order */
export type VmsOrderIdInput = {
  referenceId?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

/** The data payload attached to an order field update push notification. */
export type VmsOrderInfoFieldPushNotification = {
  __typename?: 'VmsOrderInfoFieldPushNotification';
  /** The name of the field that was updated. */
  fieldName: Scalars['String']['output'];
  /** The previous value of the field that was updated. */
  fromValue?: Maybe<Scalars['String']['output']>;
  /** The uuid of the order to which this update was made. */
  order: Scalars['UUID']['output'];
  /** The new value of the field that was updated. */
  toValue?: Maybe<Scalars['String']['output']>;
  /** Will always be the value: 'orderInfoFieldUpdatedEvent' */
  type: Scalars['String']['output'];
};

/** A timestamped log of an event that occurred for this order. */
export type VmsOrderLogEvent = {
  __typename?: 'VmsOrderLogEvent';
  /** The type of operation that caused this order log */
  action: Scalars['String']['output'];
  /** A human-readable description of the event. */
  message: Scalars['String']['output'];
  profile?: Maybe<Profile>;
  /** The time at which the event occurred. */
  timestamp: Scalars['DateTime']['output'];
  /** The uuid of the user who triggered the event. */
  userUuid?: Maybe<Scalars['UUID']['output']>;
};

/** The data payload attached to an order refund line items push notification. */
export type VmsOrderRefundLineItemsPushNotification = {
  __typename?: 'VmsOrderRefundLineItemsPushNotification';
  /** The human-readable amount by which the order was refunded */
  amount: Scalars['String']['output'];
  /** The uuid of the customer who placed the order that was refunded */
  customer: Scalars['UUID']['output'];
  /** Order refund reason */
  description: Scalars['String']['output'];
  /** The uuid of the order which was adjusted. */
  order: Scalars['UUID']['output'];
  /** The portal on which the order was placed. */
  portal: Scalars['UUID']['output'];
};

export type VmsOrderSortByField =
  | 'CREATED'
  | 'ETA';

export type VmsOrderSortByInput = {
  /** Order field to sort */
  field: VmsOrderSortByField;
  /** Sort order/direction */
  sortOrder: VmsSortOrder;
};

export type VmsOrderStatus =
  | 'CANCELLED'
  | 'COMPLETED'
  | 'IN_PROGRESS'
  | 'NEW'
  | 'PAYMENT_FAILED'
  | 'PAYMENT_PENDING'
  | 'REJECTED';

/** The data payload attached to an order status update push notification. */
export type VmsOrderStatusPushNotification = {
  __typename?: 'VmsOrderStatusPushNotification';
  /** The uuid of the customer who placed the order. */
  customer: Scalars['UUID']['output'];
  /** The uuid of the order which has changed status. */
  order: Scalars['UUID']['output'];
  /** The portal on which the order was placed. */
  portal: Scalars['UUID']['output'];
  /** The new status of the order. */
  status: VmsOrderStatus;
};

export type VmsOrderSteps = {
  __typename?: 'VmsOrderSteps';
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  status: VmsStepStatus;
};

/** Filters on the order query. */
export type VmsOrdersQueryFilterInput = {
  /** Match orders from customer's company whose name contains this string. */
  companyName?: InputMaybe<Scalars['String']['input']>;
  /** Match orders from customer whose name contains this string. */
  customerName?: InputMaybe<Scalars['String']['input']>;
  /** Match orders with these delivery methods */
  deliveryMethods?: InputMaybe<Array<Scalars['UUID']['input']>>;
  /** Match orders with this discount code */
  discountCode?: InputMaybe<Scalars['String']['input']>;
  /** Match orders with output field name or value that contains this string. */
  outputField?: InputMaybe<Scalars['String']['input']>;
  /** Match orders with these portal vendor delivery methods */
  portalVendorDeliveryMethods?: InputMaybe<Array<Scalars['UUID']['input']>>;
  /** Match orders on these portals. */
  portals?: InputMaybe<Array<Scalars['UUID']['input']>>;
  /** Match orders which contain this reference ID. */
  referenceId?: InputMaybe<Scalars['String']['input']>;
  /** Match orders with these statuses. */
  statuses?: InputMaybe<Array<VmsOrderStatus>>;
  /** Match orders to these vendors. */
  vendors?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

/** Input to the order query. */
export type VmsOrdersQueryInput = {
  /** Return only orders after this cursor. */
  after?: InputMaybe<Scalars['String']['input']>;
  /** Filters to apply to the orders query. */
  filter?: InputMaybe<VmsOrdersQueryFilterInput>;
  /** Return only this many orders. */
  first?: InputMaybe<Scalars['Int']['input']>;
  /** Optionally sort orders */
  sortBy?: InputMaybe<Array<VmsOrderSortByInput>>;
};

export type VmsPageInfo = {
  __typename?: 'VmsPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
};

export type VmsParkingProduct = VmsProduct & {
  __typename?: 'VmsParkingProduct';
  /** If true the product is only available for a limited set of trading hours */
  availability: VmsProductAvailability;
  /** Whether the product is available or not. */
  available: Scalars['Boolean']['output'];
  /** Description of the product. */
  description?: Maybe<Scalars['String']['output']>;
  focalPoint?: Maybe<VmsFocalPoint>;
  /** URL of the image for the product. */
  image?: Maybe<Scalars['URI']['output']>;
  /** S3 object key of the image for the product. */
  imageKey?: Maybe<Scalars['String']['output']>;
  /** A list of mods for the product. */
  mods: Array<VmsMod>;
  /** Name of the product. */
  name: Scalars['String']['output'];
  /**
   * Out of stock flag that can be set on products with or without a stock limit.
   * when true the stock count will be set to 0.
   * When true available will be false.
   */
  outOfStock: Scalars['Boolean']['output'];
  /** Fields for which values will be provided while processing an order for this product. */
  outputFields: Array<VmsProductOutputField>;
  /**
   * @deprecated renamed to sites.
   * @deprecated renamed to sites
   */
  portals: Array<VmsPortal>;
  /** Product categories e.g. Breakfast, Lunch, Dinner */
  productCategories: Array<VmsProductCategory>;
  /** Profile fields which the customer is requested to provide when ordering this product. */
  profileFieldRequests: Array<VmsProductProfileField>;
  /** Show or hide terms and conditions. */
  showTermsAndConditions: Scalars['Boolean']['output'];
  /**
   * Portals associated with the product
   * @deprecated use sitesWithAudience endpoint
   */
  sites: Array<VmsPortal>;
  /** Portals associated with the product */
  sitesWithAudience: Array<VmsSiteSegment>;
  /** How many of this product are left. */
  stock?: Maybe<Scalars['Int']['output']>;
  /** Taxes applicable for this product */
  taxRates: Array<VmsTaxRate>;
  /** Terms and conditions. */
  termsAndConditions?: Maybe<Scalars['String']['output']>;
  /** Overridden turn around time in minutes for the product */
  turnAroundTime?: Maybe<Scalars['Int']['output']>;
  /** Unit price in cents. May be tax inc or exc. */
  unitPrice: Scalars['Int']['output'];
  uuid: Scalars['UUID']['output'];
  /** The owner of the product. */
  vendor: VmsVendor;
  /** Whether product is visible to customer on the store */
  visibility: VmsProductVisibility;
};


export type VmsParkingProductImageArgs = {
  input?: InputMaybe<VmsImageInput>;
};

export type VmsPauseOrResumeSubscriptionAction =
  | 'Pause'
  | 'Resume';

/** Enumeration of all the possible failure codes during payment */
export type VmsPaymentFailureCodes =
  | 'AMOUNT_TOO_LARGE'
  | 'AMOUNT_TOO_SMALL'
  | 'BALANCE_INSUFFICIENT'
  | 'CARD_DECLINED'
  | 'EXPIRED_CARD'
  | 'OTHER'
  | 'STRIPE_REQUEST_INPUT';

/** See https://stripe.com/docs/api/payment_intents/object */
export type VmsPaymentIntent = {
  __typename?: 'VmsPaymentIntent';
  /** See https://stripe.com/docs/api/payment_intents/object#payment_intent_object-client_secret */
  clientSecret: Scalars['String']['output'];
  id: Scalars['String']['output'];
  /** See https://stripe.com/docs/api/payment_intents/object#payment_intent_object-status */
  status: VmsPaymentIntentStatus;
};

/** See https://stripe.com/docs/api/payment_intents/object#payment_intent_object-client_secret */
export type VmsPaymentIntentStatus =
  | 'CANCELED'
  | 'PROCESSING'
  | 'REQUIRES_ACTION'
  | 'REQUIRES_CAPTURE'
  | 'REQUIRES_CONFIRMATION'
  | 'REQUIRES_PAYMENT_METHOD'
  | 'SUCCEEDED';

export type VmsPercentageDiscount = {
  __typename?: 'VmsPercentageDiscount';
  percentage: Scalars['Int']['output'];
};

/** Permission type. Eg. VendorUserPermission/SiteUserPermission */
export type VmsPermissionType =
  | 'SiteUserPermission'
  | 'VendorUserPermission';

export type VmsPlainProduct = VmsProduct & {
  __typename?: 'VmsPlainProduct';
  /** If true the product is only available for a limited set of trading hours */
  availability: VmsProductAvailability;
  /** Whether the product is available or not. */
  available: Scalars['Boolean']['output'];
  /** Description of the product. */
  description?: Maybe<Scalars['String']['output']>;
  focalPoint?: Maybe<VmsFocalPoint>;
  /** URL of the image for the product. */
  image?: Maybe<Scalars['URI']['output']>;
  /** S3 object key of the image for the product. */
  imageKey?: Maybe<Scalars['String']['output']>;
  /** A list of mods for the product. */
  mods: Array<VmsMod>;
  /** Name of the product. */
  name: Scalars['String']['output'];
  /**
   * Out of stock flag that can be set on products with or without a stock limit.
   * when true the stock count will be set to 0.
   * When true available will be false.
   */
  outOfStock: Scalars['Boolean']['output'];
  /** Fields for which values will be provided while processing an order for this product. */
  outputFields: Array<VmsProductOutputField>;
  /**
   * @deprecated renamed to sites.
   * @deprecated renamed to sites
   */
  portals: Array<VmsPortal>;
  /** Product categories e.g. Breakfast, Lunch, Dinner */
  productCategories: Array<VmsProductCategory>;
  /** Profile fields which the customer is requested to provide when ordering this product. */
  profileFieldRequests: Array<VmsProductProfileField>;
  /** Show or hide terms and conditions. */
  showTermsAndConditions: Scalars['Boolean']['output'];
  /**
   * Portals associated with the product
   * @deprecated use sitesWithAudience endpoint
   */
  sites: Array<VmsPortal>;
  /** Portals associated with the product */
  sitesWithAudience: Array<VmsSiteSegment>;
  /** How many of this product are left. */
  stock?: Maybe<Scalars['Int']['output']>;
  /** Taxes applicable for this product */
  taxRates: Array<VmsTaxRate>;
  /** Terms and conditions. */
  termsAndConditions?: Maybe<Scalars['String']['output']>;
  /** Overridden turn around time in minutes for the product */
  turnAroundTime?: Maybe<Scalars['Int']['output']>;
  /** Unit price in cents. May be tax inc or exc. */
  unitPrice: Scalars['Int']['output'];
  uuid: Scalars['UUID']['output'];
  /** The owner of the product. */
  vendor: VmsVendor;
  /** Whether product is visible to customer on the store */
  visibility: VmsProductVisibility;
};


export type VmsPlainProductImageArgs = {
  input?: InputMaybe<VmsImageInput>;
};

export type VmsPolicy = {
  __typename?: 'VmsPolicy';
  type: VmsPolicyType;
  uuid: Scalars['UUID']['output'];
  value: Scalars['String']['output'];
};

export type VmsPolicyInput = {
  type: VmsPolicyType;
};

export type VmsPolicyType =
  | 'APP_PRIVACY'
  | 'APP_TERMS'
  | 'VENDOR_TERMS';

export type VmsPortal = VmsNode & {
  __typename?: 'VmsPortal';
  /** Full address string */
  address: Scalars['String']['output'];
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  deliveryMethods: Array<VmsDeliveryMethod>;
  locale: Scalars['String']['output'];
  name: Scalars['String']['output'];
  postCode: Scalars['String']['output'];
  products: VmsProductConnection;
  settings: VmsPortalSettings;
  state: Scalars['String']['output'];
  streetAddress: Scalars['String']['output'];
  taxRegion?: Maybe<VmsTaxRegion>;
  timezone: Scalars['String']['output'];
  user?: Maybe<VmsStoreUser>;
  /** Pending invitations for site managers */
  userInvitations?: Maybe<Array<VmsSiteManagerInvitation>>;
  users?: Maybe<VmsPortalUserConnection>;
  uuid: Scalars['UUID']['output'];
  /** contains vendors and deal vendors */
  vendorList: Array<VmsVendorUnion>;
  vendors: VmsVendorConnection;
};


export type VmsPortalProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<VmsProductFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type VmsPortalUserArgs = {
  uuid: Scalars['UUID']['input'];
};


export type VmsPortalUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type VmsPortalVendorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type VmsPortalSettings = {
  __typename?: 'VmsPortalSettings';
  /** Whether the store is enabled for customers. */
  enabled: Scalars['Boolean']['output'];
  /** @deprecated Redundant flag. Use enabled flag */
  open: Scalars['Boolean']['output'];
};

export type VmsPortalUserConnection = {
  __typename?: 'VmsPortalUserConnection';
  edges: Array<Maybe<VmsPortalUserEdge>>;
  pageInfo: VmsPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type VmsPortalUserEdge = {
  __typename?: 'VmsPortalUserEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<VmsStoreUser>;
};

/** A possible session is derived from the session template but not persisted. */
export type VmsPossibleSession = {
  __typename?: 'VmsPossibleSession';
  attendeeLimit?: Maybe<Scalars['Int']['output']>;
  day: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  duration: Scalars['Int']['output'];
  endDate: Scalars['DateTime']['output'];
  location: Scalars['String']['output'];
  sessionTemplateUuid: Scalars['UUID']['output'];
  siteUuids: Array<Scalars['String']['output']>;
  startDate: Scalars['DateTime']['output'];
  timezone: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type VmsProduct = {
  /** If true the product is only available for a limited set of trading hours */
  availability: VmsProductAvailability;
  /** Whether the product is available or not. */
  available: Scalars['Boolean']['output'];
  /** Description of the product. */
  description?: Maybe<Scalars['String']['output']>;
  focalPoint?: Maybe<VmsFocalPoint>;
  /** URL of the image for the product. */
  image?: Maybe<Scalars['URI']['output']>;
  /** S3 object key of the image for the product. */
  imageKey?: Maybe<Scalars['String']['output']>;
  /** A list of mods for the product. */
  mods: Array<VmsMod>;
  /** Name of the product. */
  name: Scalars['String']['output'];
  /**
   * Out of stock flag that can be set on products with or without a stock limit.
   * when true the stock count will be set to 0.
   * When true available will be false.
   */
  outOfStock: Scalars['Boolean']['output'];
  /** Fields for which values will be provided while processing an order for this product. */
  outputFields: Array<VmsProductOutputField>;
  /**
   * @deprecated renamed to sites.
   * @deprecated renamed to sites
   */
  portals: Array<VmsPortal>;
  /** Product categories e.g. Breakfast, Lunch, Dinner */
  productCategories: Array<VmsProductCategory>;
  /** Profile fields which the customer is requested to provide when ordering this product. */
  profileFieldRequests: Array<VmsProductProfileField>;
  /** Show or hide terms and conditions. */
  showTermsAndConditions: Scalars['Boolean']['output'];
  /**
   * Portals associated with the product
   * @deprecated use sitesWithAudience endpoint
   */
  sites: Array<VmsPortal>;
  /** Portals associated with the product with optional audience */
  sitesWithAudience: Array<VmsSiteSegment>;
  /** How many of this product are left. */
  stock?: Maybe<Scalars['Int']['output']>;
  /** Taxes applicable for this product */
  taxRates: Array<VmsTaxRate>;
  /** Terms and conditions. */
  termsAndConditions?: Maybe<Scalars['String']['output']>;
  /** Overridden turn around time in minutes for the product */
  turnAroundTime?: Maybe<Scalars['Int']['output']>;
  /** Unit price in cents. May be tax inc or exc. */
  unitPrice: Scalars['Int']['output'];
  uuid: Scalars['UUID']['output'];
  /** The owner of the product. */
  vendor: VmsVendor;
  /** Whether product is visible to customer on the store */
  visibility: VmsProductVisibility;
};


export type VmsProductImageArgs = {
  input?: InputMaybe<VmsImageInput>;
};

export type VmsProductAudience = {
  __typename?: 'VmsProductAudience';
  segmentIds: Array<Scalars['UUID']['output']>;
  summary: Scalars['String']['output'];
};

export type VmsProductAudienceInput = {
  segmentIds: Array<Scalars['UUID']['input']>;
  summary: Scalars['String']['input'];
};

export type VmsProductAvailability = {
  __typename?: 'VmsProductAvailability';
  dateRange?: Maybe<VmsAvailabilityDateRange>;
  groupedTimes: Array<VmsGroupedTradingHour>;
  /** @deprecated Use `Product -> available` */
  isAvailable: Scalars['Boolean']['output'];
  limitedAvailability: Scalars['Boolean']['output'];
  nextAvailable?: Maybe<Scalars['DateTime']['output']>;
  times?: Maybe<Array<VmsTradingHour>>;
};

export type VmsProductCategory = {
  __typename?: 'VmsProductCategory';
  name: Scalars['String']['output'];
  productList: Array<VmsProductUnion>;
  products: Array<VmsProduct>;
  uuid: Scalars['UUID']['output'];
};

export type VmsProductConnection = {
  __typename?: 'VmsProductConnection';
  edges: Array<Maybe<VmsProductEdge>>;
  pageInfo: VmsPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type VmsProductEdge = {
  __typename?: 'VmsProductEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<VmsProduct>;
};

export type VmsProductFilter = {
  /**  A search term to be searched against product name, description, category name, mods and choices  */
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Scalars['UUID']['input']>;
  visibility?: InputMaybe<VmsProductVisibility>;
};

/** A product field for which output can be provided by a vendor. */
export type VmsProductOutputField = {
  __typename?: 'VmsProductOutputField';
  /** The name of the output field. */
  name: Scalars['String']['output'];
};

/** A value the vendor will provide while processing an order for this product. */
export type VmsProductOutputFieldInput = {
  name: Scalars['String']['input'];
};

/** A product field for which output can be provided by a vendor. */
export type VmsProductOutputFieldValue = {
  __typename?: 'VmsProductOutputFieldValue';
  /** The name of the output field. */
  name: Scalars['String']['output'];
  /** The value which has been provided by the vendor. */
  value: Scalars['String']['output'];
};

export type VmsProductOutputFieldValueInput = {
  /** The name of the field to which the value should be saved. */
  name: Scalars['String']['input'];
  /** The value which should be saved. */
  value: Scalars['String']['input'];
};

export type VmsProductProfileField = {
  __typename?: 'VmsProductProfileField';
  /** The field and any current value that is available. */
  field: VmsProfileFieldValue;
  /** Whether the customer is required to provide a value for this field. */
  required: Scalars['Boolean']['output'];
};

/** Profile field which the customer is requested to provide when ordering this product. */
export type VmsProductProfileFieldInput = {
  /** A reference to a profile field in the Profile service. */
  key: Scalars['ID']['input'];
  /** Whether the customer is required to provide a value for this field. */
  required: Scalars['Boolean']['input'];
};

export type VmsProductSubscription = {
  __typename?: 'VmsProductSubscription';
  billingCycle: VmsSubscriptionBillingCycle;
  canceled?: Maybe<Scalars['DateTime']['output']>;
  charges: VmsSubscriptionChargeConnection;
  created: Scalars['DateTime']['output'];
  lastCharge: VmsSubscriptionCharge;
  lastSuccessfulCharge: Scalars['DateTime']['output'];
  nextCharge?: Maybe<Scalars['DateTime']['output']>;
  nextCycle?: Maybe<Scalars['DateTime']['output']>;
  order: VmsOrder;
  /** @deprecated Use paymentGatewayPaymentMethodIdV2 instead */
  paymentGatewayPaymentMethodId: Scalars['String']['output'];
  paymentGatewayPaymentMethodIdV2?: Maybe<Scalars['String']['output']>;
  paymentStatus: VmsSubscriptionPaymentStatus;
  product: VmsSubscriptionProduct;
  /**
   * @deprecated(reason: Use statusV2 that supports paused status)
   * @deprecated Use statusV2 that supports paused status
   */
  status: VmsProductSubscriptionStatus;
  statusV2: VmsProductSubscriptionStatusV2;
  uuid: Scalars['UUID']['output'];
};


export type VmsProductSubscriptionChargesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type VmsProductSubscriptionLastChargeArgs = {
  includePaused?: InputMaybe<Scalars['Boolean']['input']>;
};

export type VmsProductSubscriptionConnection = {
  __typename?: 'VmsProductSubscriptionConnection';
  activeCount: Scalars['Int']['output'];
  edges: Array<Maybe<VmsProductSubscriptionEdge>>;
  inactiveCount: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type VmsProductSubscriptionEdge = {
  __typename?: 'VmsProductSubscriptionEdge';
  node?: Maybe<VmsProductSubscription>;
};

export type VmsProductSubscriptionStatus =
  | 'ACTIVE'
  | 'CANCELLED';

export type VmsProductSubscriptionStatusV2 =
  | 'ACTIVE'
  | 'CANCELLED'
  | 'PAUSED';

export type VmsProductType =
  | 'BOOKABLE_PRODUCT'
  | 'PLAIN_PRODUCT'
  | 'SUBSCRIPTION_PRODUCT';

export type VmsProductUnion = VmsBookableProduct | VmsDeal | VmsParkingProduct | VmsPlainProduct | VmsSubscriptionProduct;

export type VmsProductVisibility =
  | 'HIDDEN'
  | 'LIVE';

/** A profile field for which values can be saved. */
export type VmsProfileField = {
  __typename?: 'VmsProfileField';
  /** The unique key for identifying this field. Can be referenced from external systems. */
  key: Scalars['ID']['output'];
  /** A human-readable description of the field. */
  title: Scalars['String']['output'];
};

/** The value saved for a profile field. */
export type VmsProfileFieldValue = {
  __typename?: 'VmsProfileFieldValue';
  /** The field to which the value was saved. */
  field: VmsProfileField;
  /** The value, if one has been saved. */
  value: Scalars['String']['output'];
};

export type VmsPromotedFilter = {
  home?: InputMaybe<Scalars['Boolean']['input']>;
  product?: InputMaybe<Scalars['UUID']['input']>;
  vendor?: InputMaybe<Scalars['UUID']['input']>;
  vendorCategory?: InputMaybe<Scalars['UUID']['input']>;
};

export type VmsQuantityLimit = {
  __typename?: 'VmsQuantityLimit';
  available: Scalars['Int']['output'];
  limit: Scalars['Int']['output'];
};

export type VmsQuery = {
  __typename?: 'VmsQuery';
  /** All site user permissions */
  allSiteUserPermissions: Array<VmsStorePermission>;
  /** All vendor user permissions */
  allVendorUserPermissions: Array<VmsStorePermission>;
  bookableProduct?: Maybe<VmsBookableProduct>;
  bookableProducts: VmsBookableProductConnection;
  /** Current users */
  currentUser: VmsStoreUser;
  deal?: Maybe<VmsDeal>;
  dealVendor: VmsDealVendor;
  dealVendors: VmsDealVendorConnection;
  discount: VmsDiscount;
  discounts: VmsDiscountConnection;
  /** Returns an embeddable dashboard URL for use with the QuickSight embedding API */
  embedSalesDashboard?: Maybe<Scalars['URI']['output']>;
  /** Returns an imgix URL for the provided S3 key */
  imageUrl: Scalars['URI']['output'];
  order: VmsOrder;
  /** Count the number of orders, grouped by status, matching the provided filter. */
  orderCount: Array<VmsOrderCount>;
  orders: VmsOrderConnection;
  /** Returns the term and conditions as well as privacy policy */
  policy?: Maybe<VmsPolicy>;
  /** Returns the Product with the specified uuid */
  product?: Maybe<VmsProduct>;
  promotedDiscount?: Maybe<VmsDiscount>;
  site?: Maybe<VmsPortal>;
  /** Find the site manager invitation by Token */
  siteManagerInvitation?: Maybe<VmsSiteManagerInvitation>;
  /** List of all sites for region */
  sites: Array<VmsPortal>;
  storeAdmins: Array<FullUser>;
  /** Report of total store revenue broken down by currency */
  storeReport: Array<VmsCurrencyReport>;
  /**
   * @deprecated(reason: Use subscriptionProductsV2 that supports ProductSubscriptionStatusV2 status type)
   * @deprecated Use subscriptionProductsV2 that supports ProductSubscriptionStatusV2 status type
   */
  subscriptionProducts: VmsSubscriptionProductConnection;
  /** List of all subscription products */
  subscriptionProductsV2: VmsSubscriptionProductConnection;
  /** Load the TaxRegion with the given uuid. */
  taxRegion?: Maybe<VmsTaxRegion>;
  taxRegions: VmsTaxRegionConnection;
  userBookings: VmsUserBookingConnection;
  vendor?: Maybe<VmsVendor>;
  /** List of all vendor categories */
  vendorCategories: Array<VmsVendorCategory>;
  /** Find the vendor user invitation by Token */
  vendorUserInvitation?: Maybe<VmsVendorUserInvitation>;
  vendors: VmsVendorConnection;
};


export type VmsQueryBookableProductArgs = {
  uuid: Scalars['UUID']['input'];
};


export type VmsQueryBookableProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<VmsBookableProductsQueryFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  vendorUuid: Scalars['UUID']['input'];
};


export type VmsQueryDealArgs = {
  uuid: Scalars['UUID']['input'];
};


export type VmsQueryDealVendorArgs = {
  uuid: Scalars['UUID']['input'];
};


export type VmsQueryDealVendorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<VmsVendorFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<VmsDealVendorOrderBy>;
};


export type VmsQueryDiscountArgs = {
  uuid: Scalars['UUID']['input'];
};


export type VmsQueryDiscountsArgs = {
  filters?: InputMaybe<VmsDiscountFilter>;
  pagination?: InputMaybe<VmsDiscountPagination>;
};


export type VmsQueryEmbedSalesDashboardArgs = {
  vendorUuid: Scalars['UUID']['input'];
};


export type VmsQueryImageUrlArgs = {
  input?: InputMaybe<VmsImageInput>;
  key: Scalars['String']['input'];
};


export type VmsQueryOrderArgs = {
  input: VmsOrderIdInput;
};


export type VmsQueryOrderCountArgs = {
  filter: VmsOrderCountFilterInput;
};


export type VmsQueryOrdersArgs = {
  query?: InputMaybe<VmsOrdersQueryInput>;
};


export type VmsQueryPolicyArgs = {
  input: VmsPolicyInput;
};


export type VmsQueryProductArgs = {
  uuid: Scalars['UUID']['input'];
};


export type VmsQueryPromotedDiscountArgs = {
  site: Scalars['UUID']['input'];
  vendor?: InputMaybe<Scalars['UUID']['input']>;
};


export type VmsQuerySiteArgs = {
  uuid: Scalars['UUID']['input'];
};


export type VmsQuerySiteManagerInvitationArgs = {
  token: Scalars['String']['input'];
};


export type VmsQuerySubscriptionProductsArgs = {
  filters?: InputMaybe<VmsSubscriptionProductsQueryFilters>;
  vendors: Array<Scalars['UUID']['input']>;
};


export type VmsQuerySubscriptionProductsV2Args = {
  filters?: InputMaybe<VmsSubscriptionProductsQueryFiltersV2>;
  vendors: Array<Scalars['UUID']['input']>;
};


export type VmsQueryTaxRegionArgs = {
  uuid: Scalars['UUID']['input'];
};


export type VmsQueryTaxRegionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<VmsTaxRegionsFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type VmsQueryUserBookingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sessionUuid: Scalars['UUID']['input'];
};


export type VmsQueryVendorArgs = {
  uuid: Scalars['UUID']['input'];
};


export type VmsQueryVendorUserInvitationArgs = {
  token: Scalars['String']['input'];
};


export type VmsQueryVendorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<VmsVendorFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<VmsVendorOrderBy>;
};

export type VmsReOrderableProductConnection = {
  __typename?: 'VmsReOrderableProductConnection';
  edges: Array<Maybe<VmsReOrderableProductEdge>>;
  pageInfo: VmsPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type VmsReOrderableProductEdge = {
  __typename?: 'VmsReOrderableProductEdge';
  cursor: Scalars['String']['output'];
  inStock: Scalars['Boolean']['output'];
  isProductCurrent: Scalars['Boolean']['output'];
  node?: Maybe<VmsLineItem>;
  vendorOpen: Scalars['Boolean']['output'];
};

/** A stripe failure that can happen when refunding on an order */
export type VmsRefundFailure = VmsIPaymentFailure & {
  __typename?: 'VmsRefundFailure';
  code: VmsPaymentFailureCodes;
  message?: Maybe<Scalars['String']['output']>;
};

export type VmsRefundLineItemInput = {
  /** The amount to refund for this line item */
  amount: Scalars['Int']['input'];
  /** UUID of the line item to refund */
  lineItemUuid: Scalars['UUID']['input'];
};

export type VmsRefundLineItemsInput = {
  /** Reason that gets logged, and emailed to the customer. */
  description: Scalars['String']['input'];
  /** The line item refunds to apply. */
  lineItems: Array<VmsRefundLineItemInput>;
  /** The uuid of the order to which the line items being refunded belong. */
  orderUuid: Scalars['UUID']['input'];
};

export type VmsRefundLineItemsResult = {
  __typename?: 'VmsRefundLineItemsResult';
  /** Any error messages or warning. If present should be displayed to the user. */
  message?: Maybe<Scalars['String']['output']>;
  /** The update order after the refunds were applied. */
  order: VmsOrder;
  /** Whether the refund for the specified line items succeeded. */
  success: Scalars['Boolean']['output'];
};

export type VmsReportingCategory =
  | 'Alcohol'
  | 'Asian'
  | 'Bar'
  | 'Cafe'
  | 'Catering'
  | 'Charging'
  | 'Chinese'
  | 'Deals'
  | 'Desserts'
  | 'Drinks'
  | 'DryCleaning'
  | 'Entertainment'
  | 'European'
  | 'Events'
  | 'Facilities'
  | 'Fashion'
  | 'FastFood'
  | 'Fitness'
  | 'Flowers'
  | 'FoodAndBeverage'
  | 'French'
  | 'Halal'
  | 'Health'
  | 'Homeware'
  | 'Italian'
  | 'Japanese'
  | 'Kosher'
  | 'Lockers'
  | 'Mexican'
  | 'Misc'
  | 'None'
  | 'Offers'
  | 'Parking'
  | 'Restaurant'
  | 'Salad'
  | 'Services'
  | 'Soups'
  | 'SouthAmerican'
  | 'Sports'
  | 'Tickets'
  | 'Wellness';

export type VmsSegment = {
  __typename?: 'VmsSegment';
  segmentId: Scalars['String']['output'];
  segmentSummary: Scalars['String']['output'];
};

export type VmsSegmentInput = {
  segmentId: Scalars['String']['input'];
  segmentSummary: Scalars['String']['input'];
};

/** Event session type. */
export type VmsSession = {
  __typename?: 'VmsSession';
  active: Scalars['Boolean']['output'];
  attendeeLimit?: Maybe<Scalars['Int']['output']>;
  bookableProduct?: Maybe<VmsBookableProduct>;
  bookableProductUuid: Scalars['UUID']['output'];
  bookings: VmsUserBookingConnection;
  colors?: Maybe<Array<Scalars['String']['output']>>;
  day: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  duration: Scalars['Int']['output'];
  endDate: Scalars['DateTime']['output'];
  hasBookingTerms: Scalars['Boolean']['output'];
  hasCapacity: Scalars['Boolean']['output'];
  hasUserAcceptedTermsAndConditions: Scalars['Boolean']['output'];
  location: Scalars['String']['output'];
  sessionTemplate?: Maybe<VmsSessionTemplate>;
  sessionTemplateUuid: Scalars['UUID']['output'];
  siteUuids: Array<Scalars['String']['output']>;
  startDate: Scalars['DateTime']['output'];
  timezone: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
  totalBookings: Scalars['Int']['output'];
  userHasBooked?: Maybe<Scalars['Boolean']['output']>;
  uuid: Scalars['String']['output'];
};


/** Event session type. */
export type VmsSessionBookingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type VmsSessionGroup = {
  __typename?: 'VmsSessionGroup';
  day: Scalars['String']['output'];
  sessions: Array<VmsSessionUnion>;
  timestamp: Scalars['DateTime']['output'];
  timezone: Scalars['String']['output'];
};

export type VmsSessionTemplate = {
  __typename?: 'VmsSessionTemplate';
  capacity?: Maybe<Scalars['Int']['output']>;
  days: Array<VmsDayOfTheWeek>;
  end: Scalars['Time']['output'];
  info?: Maybe<Scalars['String']['output']>;
  location: Scalars['String']['output'];
  start: Scalars['Time']['output'];
  timezone: Scalars['String']['output'];
  uuid: Scalars['UUID']['output'];
};

export type VmsSessionTemplateInput = {
  capacity?: InputMaybe<Scalars['Int']['input']>;
  days: Array<VmsDayOfTheWeek>;
  end: Scalars['Time']['input'];
  info: Scalars['String']['input'];
  location?: InputMaybe<Scalars['String']['input']>;
  start: Scalars['Time']['input'];
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type VmsSessionUnion = VmsPossibleSession | VmsSession;

export type VmsSiteDiscountReport = {
  __typename?: 'VmsSiteDiscountReport';
  discountTotal: Scalars['Int']['output'];
  discountTotalPreTax: Scalars['Int']['output'];
  discountTotalTax: Scalars['Int']['output'];
  orderCount: Scalars['Int']['output'];
  orderTotal: Scalars['Int']['output'];
  orderTotalPreTax: Scalars['Int']['output'];
  orderTotalTax: Scalars['Int']['output'];
  preDiscountOrderTotal: Scalars['Int']['output'];
  preDiscountOrderTotalPreTax: Scalars['Int']['output'];
  preDiscountOrderTotalTax: Scalars['Int']['output'];
};

export type VmsSiteManagerInvitation = {
  __typename?: 'VmsSiteManagerInvitation';
  email: Scalars['String']['output'];
  permissions: Array<VmsStorePermission>;
  token: Scalars['String']['output'];
  uuid: Scalars['UUID']['output'];
};

/** Type that accepts a site uuid and optional audience inputs */
export type VmsSiteProductInput = {
  audience?: InputMaybe<VmsProductAudienceInput>;
  /** Deprecated: Use audience input. */
  segment?: InputMaybe<VmsSegmentInput>;
  siteUuid: Scalars['UUID']['input'];
};

export type VmsSiteSegment = {
  __typename?: 'VmsSiteSegment';
  /** Audience information for a site */
  audience?: Maybe<VmsProductAudience>;
  /**
   * Segment information for a site
   * @deprecated Use audience field
   */
  segment?: Maybe<VmsSegment>;
  site: VmsPortal;
};

export type VmsSortOrder =
  | 'ASC'
  | 'DESC';

export type VmsStepStatus =
  | 'COMPLETED'
  | 'IN_PROGRESS'
  | 'PENDING';

/** Store Permission type */
export type VmsStorePermission = {
  __typename?: 'VmsStorePermission';
  label: Scalars['String']['output'];
  name: Scalars['String']['output'];
  types: Array<VmsPermissionType>;
  uuid: Scalars['UUID']['output'];
};

export type VmsStorePermissions = {
  __typename?: 'VmsStorePermissions';
  equiemAdmin: Scalars['Boolean']['output'];
};

export type VmsStoreUser = {
  __typename?: 'VmsStoreUser';
  acceptedPolicies: Array<VmsPolicy>;
  isEquiemAdmin: Scalars['Boolean']['output'];
  portal?: Maybe<VmsStoreUserPortalEdge>;
  portals: Array<VmsStoreUserPortalEdge>;
  profile?: Maybe<Profile>;
  uuid: Scalars['UUID']['output'];
  vendor?: Maybe<VmsStoreUserVendorEdge>;
  vendors: Array<VmsStoreUserVendorEdge>;
};


export type VmsStoreUserPortalArgs = {
  uuid: Scalars['UUID']['input'];
};


export type VmsStoreUserVendorArgs = {
  uuid: Scalars['UUID']['input'];
};

export type VmsStoreUserPermission =
  | 'ACCESS_REPORTS'
  | 'CREATE_DEALS_VENDOR'
  | 'EDIT_SITE_SETTINGS'
  | 'EDIT_STORE_SETTINGS'
  | 'MANAGE_DISCOUNTS'
  | 'MANAGE_MENU'
  | 'MANAGE_ORDER'
  | 'MANAGE_USERS'
  | 'MANAGE_VENDORS'
  | 'MANUALLY_ADJUST_ORDER'
  /** deprecated. Use MANUALLY_ADJUST_ORDER instead */
  | 'REFUND_ORDER';

export type VmsStoreUserPortalEdge = {
  __typename?: 'VmsStoreUserPortalEdge';
  /** @deprecated Use permissionsV2 to get more fields on permissions */
  permissions: Array<VmsStoreUserPermission>;
  permissionsV2: Array<VmsStorePermission>;
  portal: VmsPortal;
  portalUuid: Scalars['UUID']['output'];
};

export type VmsStoreUserVendorEdge = {
  __typename?: 'VmsStoreUserVendorEdge';
  allowSms: Scalars['Boolean']['output'];
  /** @deprecated Use permissionsV2 to get more fields on permissions */
  permissions: Array<VmsStoreUserPermission>;
  permissionsV2: Array<VmsStorePermission>;
  vendor: VmsVendor;
  vendorUuid: Scalars['UUID']['output'];
};

export type VmsSubscriptionBillingCycle =
  | 'MONTHLY'
  | 'WEEKLY';

export type VmsSubscriptionCharge = {
  __typename?: 'VmsSubscriptionCharge';
  created: Scalars['DateTime']['output'];
  currencyCode: Scalars['String']['output'];
  exTaxTotal: Scalars['Int']['output'];
  incTaxTotal: Scalars['Int']['output'];
  lineItem: VmsLineItem;
  subscription: VmsProductSubscription;
  successfulCharge: Scalars['Boolean']['output'];
  tax: Scalars['Int']['output'];
  updated: Scalars['DateTime']['output'];
  uuid: Scalars['UUID']['output'];
};

export type VmsSubscriptionChargeConnection = {
  __typename?: 'VmsSubscriptionChargeConnection';
  edges: Array<Maybe<VmsSubscriptionChargeEdge>>;
  pageInfo: VmsPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type VmsSubscriptionChargeEdge = {
  __typename?: 'VmsSubscriptionChargeEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<VmsSubscriptionCharge>;
};

export type VmsSubscriptionPaymentStatus =
  | 'FAILED'
  | 'PAYMENT_PENDING'
  | 'REQUIRES_ACTION'
  | 'SUCCESSFUL';

export type VmsSubscriptionProduct = VmsProduct & {
  __typename?: 'VmsSubscriptionProduct';
  /** If true the product is only available for a limited set of trading hours */
  availability: VmsProductAvailability;
  /** Whether the product is available or not. */
  available: Scalars['Boolean']['output'];
  /** The billing cycle for this subcription. */
  billingCycle: VmsSubscriptionBillingCycle;
  /** Description of the product. */
  description?: Maybe<Scalars['String']['output']>;
  focalPoint?: Maybe<VmsFocalPoint>;
  /** URL of the image for the product. */
  image?: Maybe<Scalars['URI']['output']>;
  /** S3 object key of the image for the product. */
  imageKey?: Maybe<Scalars['String']['output']>;
  /** A list of mods for the product. */
  mods: Array<VmsMod>;
  /** Name of the product. */
  name: Scalars['String']['output'];
  /**
   * Out of stock flag that can be set on products with or without a stock limit.
   * when true the stock count will be set to 0.
   * When true available will be false.
   */
  outOfStock: Scalars['Boolean']['output'];
  /** Fields for which values will be provided while processing an order for this product. */
  outputFields: Array<VmsProductOutputField>;
  /**
   * @deprecated renamed to sites.
   * @deprecated renamed to sites
   */
  portals: Array<VmsPortal>;
  /** Product categories e.g. Breakfast, Lunch, Dinner */
  productCategories: Array<VmsProductCategory>;
  /** Profile fields which the customer is requested to provide when ordering this product. */
  profileFieldRequests: Array<VmsProductProfileField>;
  /** Show or hide terms and conditions. */
  showTermsAndConditions: Scalars['Boolean']['output'];
  /**
   * Portals associated with the product
   * @deprecated use sitesWithAudience endpoint
   */
  sites: Array<VmsPortal>;
  /** Portals associated with the product with optional audience */
  sitesWithAudience: Array<VmsSiteSegment>;
  /** How many of this product are left. */
  stock?: Maybe<Scalars['Int']['output']>;
  /**
   * @deprecated(reason: Use subscriptionsV2 that supports ProductSubscriptionStatusV2 status filter)
   * @deprecated Use subscriptionsV2 that supports ProductSubscriptionStatusV2 status filter
   */
  subscriptions: VmsProductSubscriptionConnection;
  /** The subscriptions that exist for this product. */
  subscriptionsV2: VmsProductSubscriptionConnection;
  /** Taxes applicable for this product */
  taxRates: Array<VmsTaxRate>;
  /** Terms and conditions. */
  termsAndConditions?: Maybe<Scalars['String']['output']>;
  /** Overridden turn around time in minutes for the product */
  turnAroundTime?: Maybe<Scalars['Int']['output']>;
  /** Unit price in cents. May be tax inc or exc. */
  unitPrice: Scalars['Int']['output'];
  uuid: Scalars['UUID']['output'];
  /** The owner of the product. */
  vendor: VmsVendor;
  /** Whether product is visible to customer on the store */
  visibility: VmsProductVisibility;
};


export type VmsSubscriptionProductImageArgs = {
  input?: InputMaybe<VmsImageInput>;
};


export type VmsSubscriptionProductSubscriptionsArgs = {
  filters?: InputMaybe<VmsSubscriptionProductsQueryFilters>;
};


export type VmsSubscriptionProductSubscriptionsV2Args = {
  filters?: InputMaybe<VmsSubscriptionProductsQueryFiltersV2>;
};

export type VmsSubscriptionProductConnection = {
  __typename?: 'VmsSubscriptionProductConnection';
  edges: Array<Maybe<VmsSubscriptionProductEdge>>;
  pageInfo: VmsPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type VmsSubscriptionProductEdge = {
  __typename?: 'VmsSubscriptionProductEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<VmsSubscriptionProduct>;
};

export type VmsSubscriptionProductsQueryFilters = {
  paymentStatuses?: InputMaybe<Array<VmsSubscriptionPaymentStatus>>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<VmsProductSubscriptionStatus>>;
};

export type VmsSubscriptionProductsQueryFiltersV2 = {
  paymentStatuses?: InputMaybe<Array<VmsSubscriptionPaymentStatus>>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<VmsProductSubscriptionStatusV2>>;
};

export type VmsSyncDeliveryMethodInput = {
  availableFrom?: InputMaybe<Scalars['Time']['input']>;
  availableUntil?: InputMaybe<Scalars['Time']['input']>;
  customerInstructions?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  portalUuid: Scalars['UUID']['input'];
  showVendorLocation?: InputMaybe<Scalars['Boolean']['input']>;
  type: VmsDeliveryType;
  uuid?: InputMaybe<Scalars['String']['input']>;
  vendorInstructions?: InputMaybe<Scalars['String']['input']>;
};

/** Tax rate for a given tax region */
export type VmsTaxRate = {
  __typename?: 'VmsTaxRate';
  /** e.g. GST */
  label: Scalars['String']['output'];
  /** The number of products which use this tax rate. */
  productCount: Scalars['Int']['output'];
  /** e.g. 0.1 = 10% */
  rate: Scalars['Float']['output'];
  uuid: Scalars['UUID']['output'];
};

export type VmsTaxRateInput = {
  /** e.g. GST */
  label: Scalars['String']['input'];
  /** e.g. 0.1 = 10% */
  rate: Scalars['Float']['input'];
  uuid: Scalars['UUID']['input'];
};

/** Tax rules/information provided to Portals */
export type VmsTaxRegion = {
  __typename?: 'VmsTaxRegion';
  /** Applicable currency for tax region */
  currency: VmsCurrency;
  name: Scalars['String']['output'];
  sites: Array<VmsPortal>;
  /**
   * Whether product unit prices are displayed inclusive or
   * exclusive of tax.
   */
  taxInclusive: Scalars['Boolean']['output'];
  taxRates: Array<VmsTaxRate>;
  uuid: Scalars['UUID']['output'];
};

export type VmsTaxRegionConnection = {
  __typename?: 'VmsTaxRegionConnection';
  edges: Array<VmsTaxRegionEdge>;
  pageInfo: VmsPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type VmsTaxRegionEdge = {
  __typename?: 'VmsTaxRegionEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<VmsTaxRegion>;
};

export type VmsTaxRegionsFilterInput = {
  /** Fuzzy match on tax region name */
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Represents the opening hours of a vendor on a given day */
export type VmsTradingHour = {
  __typename?: 'VmsTradingHour';
  /** Closing time of the store in 24hr time. Format: 14:38  */
  close: Scalars['String']['output'];
  day: VmsDayOfTheWeek;
  /** Opening time of the store in 24hr time. Format: 10:12  */
  open: Scalars['String']['output'];
  uuid: Scalars['UUID']['output'];
};

export type VmsTradingHourInput = {
  /** Closing time of the store in 24hr time. Format: 14:38  */
  close?: InputMaybe<Scalars['String']['input']>;
  day: VmsDayOfTheWeek;
  /** Opening time of the store in 24hr time. Format: 10:12  */
  open?: InputMaybe<Scalars['String']['input']>;
};

/** Represents the opening hours of a vendor on a given day */
export type VmsTradingHourV2 = {
  __typename?: 'VmsTradingHourV2';
  /** Closing time of the store in 24hr time. Format: 14:38  */
  closeTime?: Maybe<Scalars['String']['output']>;
  day: VmsDayOfTheWeek;
  isClosed: Scalars['Boolean']['output'];
  /** Opening time of the store in 24hr time. Format: 10:12  */
  openTime?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export type VmsUpdateDiscountInput = {
  audience?: InputMaybe<VmsProductAudienceInput>;
  dateRange?: InputMaybe<Scalars['String']['input']>;
  inactiveOnlyFields?: InputMaybe<VmsUpdateInactiveInput>;
  inclusions?: InputMaybe<VmsDiscountInclusionsInput>;
  internalNotes?: InputMaybe<Scalars['String']['input']>;
  perUserLineItemLimit?: InputMaybe<Scalars['Int']['input']>;
  perUserOrderLimit?: InputMaybe<Scalars['Int']['input']>;
  perUserSpendLimit?: InputMaybe<Scalars['Int']['input']>;
  promotion?: InputMaybe<VmsDiscountPromotionInput>;
  /** Deprecated: Use audience input. */
  segment?: InputMaybe<VmsSegmentInput>;
  status?: InputMaybe<VmsDiscountStatus>;
  termsAndConditions?: InputMaybe<Scalars['String']['input']>;
  timeRange?: InputMaybe<Array<VmsTradingHourInput>>;
  totalLineItemLimit?: InputMaybe<Scalars['Int']['input']>;
  totalOrderLimit?: InputMaybe<Scalars['Int']['input']>;
  totalSpendLimit?: InputMaybe<Scalars['Int']['input']>;
  uuid: Scalars['UUID']['input'];
};

export type VmsUpdateInactiveInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<VmsDiscountType>;
  value?: InputMaybe<Scalars['Int']['input']>;
};

export type VmsUpdatePlainProductInput = {
  /** dates of availability in the format [YYYY-MM-DD, YYYY-MM-DD) where [ is inclusive and ) is exclusive */
  availabilityDateRange?: InputMaybe<Scalars['String']['input']>;
  /** Hours of availability, only relevant if limitedAvailability */
  availabilityHours?: InputMaybe<Array<VmsTradingHourInput>>;
  /** deprecated. Not used in UI. */
  available?: InputMaybe<Scalars['Boolean']['input']>;
  /** Optional product description */
  description?: InputMaybe<Scalars['String']['input']>;
  focalPoint?: InputMaybe<VmsFocalPointInput>;
  /** Optional product image S3 key */
  imageKey?: InputMaybe<Scalars['String']['input']>;
  /** If true the product is only available for a limited set of trading hours */
  limitedAvailability?: InputMaybe<Scalars['Boolean']['input']>;
  mods?: InputMaybe<Array<VmsUpsertModInput>>;
  /** Name of the product */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Fields for which values will be provided while processing an order for this product. */
  outputFields?: InputMaybe<Array<VmsProductOutputFieldInput>>;
  /**
   * Portals this product can be sold on.
   * Deprecated: reason - Use sites input
   */
  portalUuids?: InputMaybe<Array<Scalars['UUID']['input']>>;
  /** New/existing product category names to associate with the product */
  productCategoryNames: Array<Scalars['String']['input']>;
  /** UUID of the product to update */
  productUuid: Scalars['UUID']['input'];
  /** Profile fields which the customer is requested to provide when ordering this product. */
  profileFieldRequests?: InputMaybe<Array<VmsProductProfileFieldInput>>;
  /** Sites this product can be sold on with optional audience input */
  sites?: InputMaybe<Array<VmsSiteProductInput>>;
  /** How many units of this product are currently in stock */
  stock?: InputMaybe<Scalars['Int']['input']>;
  /** Taxes applicable for this product */
  taxRateUuids: Array<Scalars['UUID']['input']>;
  /** Terms and conditions. */
  termsAndConditions?: InputMaybe<Scalars['String']['input']>;
  /** Overridden turn around time in minutes for the product */
  turnAroundTime?: InputMaybe<Scalars['Int']['input']>;
  /** Unit price of the product in cents  */
  unitPrice: Scalars['Int']['input'];
  /** Whether product is visible to customers on the store */
  visibility: VmsProductVisibility;
};

export type VmsUpdateSiteSettingsInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  country: Scalars['String']['input'];
  deliveryMethods?: InputMaybe<Array<VmsSyncDeliveryMethodInput>>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  postCode: Scalars['String']['input'];
  siteUuid: Scalars['UUID']['input'];
  state: Scalars['String']['input'];
  streetAddress: Scalars['String']['input'];
  /** @deprecated site trading hours will be removed in the future */
  tradingHours?: InputMaybe<Array<VmsTradingHourInput>>;
};

export type VmsUpdateTaxRegionInput = {
  name: Scalars['String']['input'];
  /** Sites which belong to this tax region. */
  sites?: InputMaybe<Array<Scalars['UUID']['input']>>;
  taxRates: Array<VmsTaxRateInput>;
  uuid: Scalars['UUID']['input'];
};

export type VmsUpdateVendorCategoryInput = {
  imageKey?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  uuid: Scalars['UUID']['input'];
  weight?: InputMaybe<Scalars['Int']['input']>;
};

export type VmsUpdateVendorInput = {
  autoOrderRejectionTime?: InputMaybe<Scalars['Int']['input']>;
  city: Scalars['String']['input'];
  country?: InputMaybe<Scalars['String']['input']>;
  delay?: InputMaybe<Scalars['Int']['input']>;
  /** deprecated, not in use */
  deliveryInstructions?: InputMaybe<Scalars['String']['input']>;
  deliveryMethods?: InputMaybe<Array<VmsUpsertVendorPortalDeliveryMethodInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  equiemCommissionRate?: InputMaybe<Scalars['Float']['input']>;
  focalPoint?: InputMaybe<VmsFocalPointInput>;
  keyContact: VmsVendorKeyContactInput;
  name: Scalars['String']['input'];
  /** deprecated, not in use */
  offersDelivery?: InputMaybe<Scalars['Boolean']['input']>;
  /** deprecated, not in use */
  offersPickup?: InputMaybe<Scalars['Boolean']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  /** deprecated, not in use */
  pickupInstructions?: InputMaybe<Scalars['String']['input']>;
  /** Settings indicate to play order chime */
  playOrderChime?: InputMaybe<Scalars['Boolean']['input']>;
  /** Interval for order chime reminders in seconds */
  playOrderChimeInterval?: InputMaybe<Scalars['Int']['input']>;
  portalUuids?: InputMaybe<Array<Scalars['UUID']['input']>>;
  postCode: Scalars['String']['input'];
  profileImage?: InputMaybe<Scalars['String']['input']>;
  /** Reporting category for Cortex */
  reportingCategory?: InputMaybe<VmsReportingCategory>;
  state: Scalars['String']['input'];
  street: Scalars['String']['input'];
  taxId?: InputMaybe<Scalars['String']['input']>;
  thirdPartyUrl?: InputMaybe<Scalars['URI']['input']>;
  tradingHours?: InputMaybe<Array<VmsTradingHourInput>>;
  tradingName?: InputMaybe<Scalars['String']['input']>;
  turnAroundTime?: InputMaybe<Scalars['Int']['input']>;
  /** UUID of an existing vendor */
  uuid: Scalars['UUID']['input'];
  vendorCategories?: InputMaybe<Array<Scalars['UUID']['input']>>;
  visibilityStatus?: InputMaybe<VmsVendorVisibilityStatus>;
  website?: InputMaybe<Scalars['URI']['input']>;
};

export type VmsUpsertModChoiceInput = {
  /**
   * Name of new/existing choice.
   * If choice does not exist for vendor,
   * then it will be created else reused.
   */
  name: Scalars['String']['input'];
  /** Additional price in cents */
  unitPrice: Scalars['Int']['input'];
};

export type VmsUpsertModInput = {
  mandatory: Scalars['Boolean']['input'];
  modChoices?: InputMaybe<Array<VmsUpsertModChoiceInput>>;
  /**
   * Name of new/existing mod.
   * If mod does not exist for product,
   * then it will be created else reused.
   */
  name: Scalars['String']['input'];
  type: VmsModType;
};

export type VmsUpsertVendorPortalDeliveryMethodInput = {
  customerInstructions: Scalars['String']['input'];
  deliveryMethodUuid: Scalars['String']['input'];
  portalUuid: Scalars['String']['input'];
};

/** User booking type that contains information about an RSVP */
export type VmsUserBooking = {
  __typename?: 'VmsUserBooking';
  acceptedTerms: Scalars['Boolean']['output'];
  active: Scalars['Boolean']['output'];
  attendees: Scalars['Int']['output'];
  checkedIn: Scalars['Boolean']['output'];
  fullProfile?: Maybe<FullProfile>;
  lineItem: VmsLineItem;
  order: VmsOrder;
  partialProfile?: Maybe<PartialProfile>;
  profile?: Maybe<Profile>;
  site: VmsPortal;
  userUuid: Scalars['UUID']['output'];
};

/** User connection type */
export type VmsUserBookingConnection = {
  __typename?: 'VmsUserBookingConnection';
  edges: Array<Maybe<VmsUserBookingEdge>>;
  pageInfo: VmsPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** User edge type */
export type VmsUserBookingEdge = {
  __typename?: 'VmsUserBookingEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<VmsUserBooking>;
};

export type VmsVendor = VmsVendorInterface & {
  __typename?: 'VmsVendor';
  /** Combined address field */
  address: Scalars['String']['output'];
  /** Duration the pending order will be auto rejected when its idle */
  autoOrderRejectionTime?: Maybe<Scalars['Int']['output']>;
  choices: VmsChoiceConnection;
  /** City component of address */
  city?: Maybe<Scalars['String']['output']>;
  country: Scalars['String']['output'];
  countryCode: Scalars['String']['output'];
  /** Add a delay to all current order turn around times */
  delay?: Maybe<Scalars['Int']['output']>;
  deliveryCharge: Scalars['Int']['output'];
  /**
   * @deprecated no longer used
   * @deprecated no longer used
   */
  deliveryInstructions?: Maybe<Scalars['String']['output']>;
  deliveryMethods: Array<VmsVendorDeliveryMethod>;
  /** Short description of the vendor */
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  equiemCommissionRate?: Maybe<Scalars['Float']['output']>;
  focalPoint?: Maybe<VmsFocalPoint>;
  /** Grouped set of trading hours for vendor trading hours */
  groupedTradingHours: Array<VmsGroupedTradingHour>;
  image?: Maybe<Scalars['URI']['output']>;
  /**
   * Key contact details for the vendor, in the
   * event that Equiem wishes to contact them.
   */
  keyContact?: Maybe<VmsVendorKeyContact>;
  location: VmsCoordinate;
  /** Customer friendly vendor name */
  name: Scalars['String']['output'];
  /**
   * @deprecated no longer used
   * @deprecated no longer used
   */
  offersDelivery?: Maybe<Scalars['Boolean']['output']>;
  /**
   * @deprecated no longer used
   * @deprecated no longer used
   */
  offersPickup?: Maybe<Scalars['Boolean']['output']>;
  open: Scalars['Boolean']['output'];
  /** This query represents the vendor's view of the orders */
  orders: VmsOrderConnection;
  paymentAccountId?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  /**
   * @deprecated no longer used
   * @deprecated no longer used
   */
  pickupInstructions?: Maybe<Scalars['String']['output']>;
  /** Settings indicate to play order chime */
  playOrderChime: Scalars['Boolean']['output'];
  /** Interval for order chime reminders in seconds */
  playOrderChimeInterval: Scalars['Int']['output'];
  portals: Array<VmsPortal>;
  /** Post code component of address */
  postCode?: Maybe<Scalars['String']['output']>;
  /** Product categories that vendor has, e.g Drinks */
  productCategories: Array<VmsProductCategory>;
  productCount: Scalars['Int']['output'];
  products: VmsProductConnection;
  profileImageKey?: Maybe<Scalars['String']['output']>;
  /** Reporting category for Cortex */
  reportingCategory?: Maybe<VmsReportingCategory>;
  skipStripeAccountSetup: Scalars['Boolean']['output'];
  /** State component of address */
  state?: Maybe<Scalars['String']['output']>;
  /** Street component of address */
  street?: Maybe<Scalars['String']['output']>;
  taxId?: Maybe<Scalars['String']['output']>;
  taxRegion?: Maybe<VmsTaxRegion>;
  thirdPartyUrl?: Maybe<Scalars['URI']['output']>;
  /** Set of trading hours for specific days of the week */
  tradingHours: Array<VmsTradingHour>;
  /** Set of trading hours for specific days of the week */
  tradingHoursV2: Array<VmsTradingHourV2>;
  /** Incorporated company name */
  tradingName?: Maybe<Scalars['String']['output']>;
  /** Default turn around time in minutes for the vendor */
  turnAroundTime?: Maybe<Scalars['Int']['output']>;
  user?: Maybe<VmsStoreUser>;
  /** Pending invitations for vendor users */
  userInvitations?: Maybe<Array<VmsVendorUserInvitation>>;
  /** The vendor users */
  users?: Maybe<VmsVendorUserConnection>;
  uuid: Scalars['UUID']['output'];
  /** Vendor categories e.g. Breakfast, Lunch, Dinner */
  vendorCategories: Array<VmsVendorCategory>;
  visibilityStatus: VmsVendorVisibilityStatus;
  website?: Maybe<Scalars['String']['output']>;
};


export type VmsVendorChoicesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Array<VmsChoicesSearchFilter>>;
};


export type VmsVendorImageArgs = {
  input?: InputMaybe<VmsImageInput>;
};


export type VmsVendorOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<VmsOrderStatus>;
};


export type VmsVendorPortalsArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
};


export type VmsVendorProductCategoriesArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
};


export type VmsVendorProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<VmsProductFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Array<VmsVendorProductsSearchFilter>>;
};


export type VmsVendorUserArgs = {
  uuid: Scalars['UUID']['input'];
};


export type VmsVendorUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type VmsVendorCategory = {
  __typename?: 'VmsVendorCategory';
  /** URL of the image for the vendor category. */
  image?: Maybe<Scalars['URI']['output']>;
  name: Scalars['String']['output'];
  uuid: Scalars['UUID']['output'];
  /** contains vendors and deal vendors */
  vendorList: VmsVendorListConnection;
  vendors: VmsVendorConnection;
};


export type VmsVendorCategoryImageArgs = {
  input?: InputMaybe<VmsImageInput>;
};


export type VmsVendorCategoryVendorListArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type VmsVendorCategoryVendorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type VmsVendorConnection = {
  __typename?: 'VmsVendorConnection';
  edges: Array<Maybe<VmsVendorEdge>>;
  pageInfo: VmsPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type VmsVendorDeliveryMethod = {
  __typename?: 'VmsVendorDeliveryMethod';
  active: Scalars['Boolean']['output'];
  customerInstructions?: Maybe<Scalars['String']['output']>;
  deliveryMethod: VmsDeliveryMethod;
  portal: VmsPortal;
};

export type VmsVendorDiscountReport = {
  __typename?: 'VmsVendorDiscountReport';
  discountTotal: Scalars['Int']['output'];
  discountTotalPreTax: Scalars['Int']['output'];
  discountTotalTax: Scalars['Int']['output'];
  orderCount: Scalars['Int']['output'];
  orderTotal: Scalars['Int']['output'];
  orderTotalPreTax: Scalars['Int']['output'];
  orderTotalTax: Scalars['Int']['output'];
  preDiscountOrderTotal: Scalars['Int']['output'];
  preDiscountOrderTotalPreTax: Scalars['Int']['output'];
  preDiscountOrderTotalTax: Scalars['Int']['output'];
  vendorName: Scalars['String']['output'];
};

export type VmsVendorEdge = {
  __typename?: 'VmsVendorEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<VmsVendor>;
};

export type VmsVendorFilter = {
  /** deprecated. Use `searchTerm` */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Search vendor by name, trading name or description */
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  /** Filter vendors by sites */
  sites?: InputMaybe<Array<Scalars['UUID']['input']>>;
  /** deprecated. Use `searchTerm` */
  title?: InputMaybe<Scalars['String']['input']>;
};

export type VmsVendorInterface = {
  /** Combined address field */
  address: Scalars['String']['output'];
  /** City component of address */
  city?: Maybe<Scalars['String']['output']>;
  country: Scalars['String']['output'];
  /** Short description of the vendor */
  description?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Scalars['URI']['output']>;
  location: VmsCoordinate;
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  portals: Array<VmsPortal>;
  /** Post code component of address */
  postCode?: Maybe<Scalars['String']['output']>;
  /** State component of address */
  state?: Maybe<Scalars['String']['output']>;
  /** Street component of address */
  street?: Maybe<Scalars['String']['output']>;
  thirdPartyUrl?: Maybe<Scalars['URI']['output']>;
  uuid: Scalars['UUID']['output'];
  /** Vendor categories e.g. Breakfast, Lunch, Dinner */
  vendorCategories: Array<VmsVendorCategory>;
  visibilityStatus: VmsVendorVisibilityStatus;
  website?: Maybe<Scalars['String']['output']>;
};


export type VmsVendorInterfaceImageArgs = {
  input?: InputMaybe<VmsImageInput>;
};


export type VmsVendorInterfacePortalsArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type VmsVendorKeyContact = {
  __typename?: 'VmsVendorKeyContact';
  /** Email address to direct enquiries to */
  email: Scalars['String']['output'];
  /** Full name */
  name: Scalars['String']['output'];
  /** Primary contact phone number */
  phone: Scalars['String']['output'];
};

export type VmsVendorKeyContactInput = {
  /** Email address for Equiem to direct enquiries to */
  email: Scalars['Email']['input'];
  /** Key vendor contact person for Equiem to contact */
  name: Scalars['String']['input'];
  /** Key contact phone number when Equiem needs to contact vendor */
  phone: Scalars['String']['input'];
};

export type VmsVendorListConnection = {
  __typename?: 'VmsVendorListConnection';
  edges: Array<Maybe<VmsVendorListEdge>>;
  pageInfo: VmsPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type VmsVendorListEdge = {
  __typename?: 'VmsVendorListEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<VmsVendorUnion>;
};

export type VmsVendorOrderBy = {
  descending: Scalars['Boolean']['input'];
  field: VmsVendorSortableFields;
};

export type VmsVendorProductsSearchBy =
  | 'DESCRIPTION'
  | 'NAME';

export type VmsVendorProductsSearchFilter = {
  by: VmsVendorProductsSearchBy;
  value: Scalars['String']['input'];
};

export type VmsVendorSortableFields =
  | 'NAME'
  | 'SITES'
  | 'STATUS'
  | 'STRIPE_CONNECTED';

export type VmsVendorUnion = VmsDealVendor | VmsVendor;

export type VmsVendorUserConnection = {
  __typename?: 'VmsVendorUserConnection';
  edges: Array<Maybe<VmsVendorUserEdge>>;
  pageInfo: VmsPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type VmsVendorUserEdge = {
  __typename?: 'VmsVendorUserEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<VmsStoreUser>;
};

export type VmsVendorUserInvitation = {
  __typename?: 'VmsVendorUserInvitation';
  email: Scalars['String']['output'];
  permissions: Array<VmsStorePermission>;
  token: Scalars['String']['output'];
  uuid: Scalars['UUID']['output'];
};

export type VmsVendorVisibilityStatus =
  | 'HIDDEN'
  | 'LIVE';

export type WebAppApplication =
  | 'DRUPAL'
  | 'WEB_NG';

export type WebBranding = {
  __typename?: 'WebBranding';
  browserIconImage?: Maybe<Scalars['URI']['output']>;
  buildingPageHeaderImage?: Maybe<Scalars['URI']['output']>;
  customStylesheet?: Maybe<Scalars['URI']['output']>;
  footerLogoImage?: Maybe<Scalars['URI']['output']>;
  homePageHeaderImage?: Maybe<Scalars['URI']['output']>;
  showLogoOnWhiteBackground: Scalars['Boolean']['output'];
  siteLogoImage?: Maybe<Scalars['URI']['output']>;
  siteLogoImageSize?: Maybe<Scalars['String']['output']>;
  textOverBuildingPageImage: Scalars['Boolean']['output'];
  textOverHomePageImage: Scalars['Boolean']['output'];
  welcomePageHeading?: Maybe<Scalars['String']['output']>;
  welcomePageImage?: Maybe<Scalars['URI']['output']>;
  welcomePageSubHeading?: Maybe<Scalars['String']['output']>;
};


export type WebBrandingBrowserIconImageArgs = {
  imageOptions?: InputMaybe<ImageOptions>;
};


export type WebBrandingBuildingPageHeaderImageArgs = {
  imageOptions?: InputMaybe<ImageOptions>;
};


export type WebBrandingFooterLogoImageArgs = {
  imageOptions?: InputMaybe<ImageOptions>;
};


export type WebBrandingHomePageHeaderImageArgs = {
  imageOptions?: InputMaybe<ImageOptions>;
};


export type WebBrandingSiteLogoImageArgs = {
  imageOptions?: InputMaybe<ImageOptions>;
};


export type WebBrandingWelcomePageImageArgs = {
  imageOptions?: InputMaybe<ImageOptions>;
};

/** Encapsulates a few properties about a domain as a web domain. */
export type WebDomain = {
  __typename?: 'WebDomain';
  /** Domain name for this web domain. */
  domain: DomainName;
  /** This domain is the primary domain. */
  primary: Scalars['Boolean']['output'];
  /** Whether this web domain redirects to the primary domain. */
  redirect: Scalars['Boolean']['output'];
};

/** Input version of a WebDomain. */
export type WebDomainInput = {
  domain: Scalars['String']['input'];
  primary: Scalars['Boolean']['input'];
  redirect?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Weekday =
  | 'FRI'
  | 'MON'
  | 'SAT'
  | 'SUN'
  | 'THU'
  | 'TUE'
  | 'WED';

export type Whitelabel = {
  __typename?: 'Whitelabel';
  androidFlavor?: Maybe<Scalars['String']['output']>;
  androidImages?: Maybe<AndroidImages>;
  apnsKeyId?: Maybe<Scalars['String']['output']>;
  appName: Scalars['String']['output'];
  appleAccountName?: Maybe<Scalars['String']['output']>;
  appleAppStoreId?: Maybe<Scalars['String']['output']>;
  appleCodeSigningIdentity?: Maybe<Scalars['String']['output']>;
  appleCompanyName?: Maybe<Scalars['String']['output']>;
  appleEditVersion?: Maybe<Scalars['String']['output']>;
  appleITCTeamId?: Maybe<Scalars['String']['output']>;
  appleMembershipExpiryDate?: Maybe<Scalars['DateTime']['output']>;
  applePayMerchantId?: Maybe<Scalars['String']['output']>;
  appleReviewEmail?: Maybe<Scalars['String']['output']>;
  appleStatusUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  appleStoreKeyId?: Maybe<Scalars['String']['output']>;
  appleStoreStatus?: Maybe<AppleStoreStatus>;
  appleTeamId?: Maybe<Scalars['String']['output']>;
  appleVersion?: Maybe<Scalars['String']['output']>;
  betaUsers: Array<BetaUsers>;
  bundleId?: Maybe<Scalars['String']['output']>;
  categories?: Maybe<Array<Scalars['String']['output']>>;
  client?: Maybe<Client>;
  color?: Maybe<Scalars['String']['output']>;
  contactEmail?: Maybe<Scalars['Email']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  devAccountName?: Maybe<Scalars['String']['output']>;
  distributionRegions: Array<Scalars['String']['output']>;
  dynamicLinkUrl?: Maybe<Scalars['String']['output']>;
  editionId?: Maybe<Scalars['String']['output']>;
  equiemOwner?: Maybe<PartialUser>;
  firebaseAndroidAppId?: Maybe<Scalars['String']['output']>;
  firebaseProjectId?: Maybe<Scalars['String']['output']>;
  firebaseServiceKeyId?: Maybe<Scalars['String']['output']>;
  firebaseWebApiKey?: Maybe<Scalars['String']['output']>;
  googleAccountName?: Maybe<Scalars['String']['output']>;
  googleReviewEmail?: Maybe<Scalars['String']['output']>;
  googleStatusUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  googleStoreStatus?: Maybe<GoogleStoreStatus>;
  googleVersion?: Maybe<Scalars['String']['output']>;
  iosImages?: Maybe<IosImages>;
  jiraIssueUrl?: Maybe<Scalars['String']['output']>;
  keywords?: Maybe<Array<Scalars['String']['output']>>;
  language: Scalars['String']['output'];
  matchGitBranch?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  packageName?: Maybe<Scalars['String']['output']>;
  playStoreKeyId?: Maybe<Scalars['String']['output']>;
  privacyUrl?: Maybe<Scalars['URI']['output']>;
  releaseNotes?: Maybe<Scalars['String']['output']>;
  siteUuids: Array<Scalars['String']['output']>;
  status: WhitelabelStatus;
  subtitle?: Maybe<Scalars['String']['output']>;
  supportUrl?: Maybe<Scalars['URI']['output']>;
  uuid: Scalars['UUID']['output'];
};

export type WhitelabelConnection = {
  __typename?: 'WhitelabelConnection';
  edges: Array<WhitelabelEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type WhitelabelEdge = {
  __typename?: 'WhitelabelEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Whitelabel>;
};

export type WhitelabelStatus =
  | 'AWAITING_APPROVAL'
  | 'DEVELOPED'
  | 'DEVELOPMENT'
  | 'DEVELOPMENT_BLOCKED'
  | 'DRAFT'
  | 'LIVE'
  | 'READY_FOR_DEVELOPMENT'
  | 'RETIRED';

export type WorkRequest = {
  __typename?: 'WorkRequest';
  assignedTo?: Maybe<WorkRequestUser>;
  category: WorkRequestCategory;
  cost: WorkRequestCostBreakdown;
  createdAt: Scalars['DateTime']['output'];
  currentStatus: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  identifier: Scalars['String']['output'];
  location: WorkRequestLocation;
  nextAllowableStatuses: Array<Scalars['String']['output']>;
  rating?: Maybe<Scalars['Int']['output']>;
  requester: WorkRequestUser;
  scheduledHours?: Maybe<WorkRequestScheduledHours>;
  specificLocation?: Maybe<Scalars['String']['output']>;
  submittedBy: WorkRequestUser;
  updatedAt: Scalars['DateTime']['output'];
};

export type WorkRequestAttachment = {
  __typename?: 'WorkRequestAttachment';
  filename: Scalars['String']['output'];
  url: Scalars['URI']['output'];
};

export type WorkRequestBuilding = {
  __typename?: 'WorkRequestBuilding';
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
};

export type WorkRequestCategory = {
  __typename?: 'WorkRequestCategory';
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  subCategories?: Maybe<Array<WorkRequestCategory>>;
};

export type WorkRequestComment = {
  __typename?: 'WorkRequestComment';
  author: WorkRequestUser;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  text: Scalars['String']['output'];
};

export type WorkRequestConfiguration = {
  __typename?: 'WorkRequestConfiguration';
  buildings: Array<WorkRequestBuilding>;
};

export type WorkRequestConnection = {
  __typename?: 'WorkRequestConnection';
  edges: Array<WorkRequestEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type WorkRequestCostBreakdown = {
  __typename?: 'WorkRequestCostBreakdown';
  totalCost: Scalars['String']['output'];
  totalCostCurrency: Scalars['String']['output'];
};

export type WorkRequestEdge = {
  __typename?: 'WorkRequestEdge';
  cursor: Scalars['String']['output'];
  node: WorkRequest;
};

export type WorkRequestFilters = {
  createdDate?: InputMaybe<Scalars['DateTime']['input']>;
  issueType?: InputMaybe<Scalars['ID']['input']>;
  requestedFor?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type WorkRequestLocation = {
  __typename?: 'WorkRequestLocation';
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
};

export type WorkRequestRole =
  | 'SERVICE';

export type WorkRequestScheduledHours = {
  __typename?: 'WorkRequestScheduledHours';
  endDate: Scalars['DateTime']['output'];
  startDate: Scalars['DateTime']['output'];
};

export type WorkRequestStats = {
  __typename?: 'WorkRequestStats';
  completed: Scalars['Int']['output'];
  onHold: Scalars['Int']['output'];
  open: Scalars['Int']['output'];
};

export type WorkRequestUser = {
  __typename?: 'WorkRequestUser';
  avatar?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
};

export type WriteAuthRelationshipTupleResponse = {
  __typename?: 'WriteAuthRelationshipTupleResponse';
  err?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type WriteAuthRelationshipTuplesOptionsInput = {
  idempotent?: InputMaybe<Scalars['Boolean']['input']>;
  transaction?: InputMaybe<WriteAuthRelationshipTuplesTransactionInput>;
};

export type WriteAuthRelationshipTuplesResponse = {
  __typename?: 'WriteAuthRelationshipTuplesResponse';
  deletes: Array<WriteAuthRelationshipTupleResponse>;
  writes: Array<WriteAuthRelationshipTupleResponse>;
};

export type WriteAuthRelationshipTuplesTransactionInput = {
  disable?: InputMaybe<Scalars['Boolean']['input']>;
  maxParallelRequests?: InputMaybe<Scalars['Int']['input']>;
  maxPerChunk?: InputMaybe<Scalars['Int']['input']>;
};

export type YardiBuildingIntegration = {
  __typename?: 'YardiBuildingIntegration';
  accountId?: Maybe<Scalars['String']['output']>;
  accrualId?: Maybe<Scalars['String']['output']>;
  checknumId?: Maybe<Scalars['String']['output']>;
  offsetId?: Maybe<Scalars['String']['output']>;
  propertyCode?: Maybe<Scalars['String']['output']>;
};

export type YardiBuildingIntegrationInput = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  accrualId?: InputMaybe<Scalars['String']['input']>;
  checknumId?: InputMaybe<Scalars['String']['input']>;
  offsetId?: InputMaybe<Scalars['String']['input']>;
  propertyCode?: InputMaybe<Scalars['String']['input']>;
};

export type YardiCompanyDestinationIntegration = {
  __typename?: 'YardiCompanyDestinationIntegration';
  tenantCode?: Maybe<Scalars['String']['output']>;
};

export type YardiCompanyDestinationIntegrationInput = {
  tenantCode?: InputMaybe<Scalars['String']['input']>;
};

export type InviteUsersMutationVariables = Exact<{
  users: Array<InviteUserV3Input> | InviteUserV3Input;
  destinationUuid: Scalars['UUID']['input'];
  fromFirstName: Scalars['String']['input'];
  fromLastName: Scalars['String']['input'];
}>;


export type InviteUsersMutation = { __typename?: 'Mutation', inviteUsersV3: { __typename?: 'InviteUsersResult', invitedCount: number, invitedEmails?: string | null, invalidEmails?: string | null, existingEmails?: string | null, existingInviteEmails?: string | null } };


export const InviteUsersDocument = gql`
    mutation InviteUsers($users: [InviteUserV3Input!]!, $destinationUuid: UUID!, $fromFirstName: String!, $fromLastName: String!) {
  inviteUsersV3(
    users: $users
    destinationUuid: $destinationUuid
    fromFirstName: $fromFirstName
    fromLastName: $fromLastName
  ) {
    invitedCount
    invitedEmails
    invalidEmails
    existingEmails
    existingInviteEmails
  }
}
    `;
export type InviteUsersMutationFn = Apollo.MutationFunction<InviteUsersMutation, InviteUsersMutationVariables>;

/**
 * __useInviteUsersMutation__
 *
 * To run a mutation, you first call `useInviteUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUsersMutation, { data, loading, error }] = useInviteUsersMutation({
 *   variables: {
 *      users: // value for 'users'
 *      destinationUuid: // value for 'destinationUuid'
 *      fromFirstName: // value for 'fromFirstName'
 *      fromLastName: // value for 'fromLastName'
 *   },
 * });
 */
export function useInviteUsersMutation(baseOptions?: Apollo.MutationHookOptions<InviteUsersMutation, InviteUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteUsersMutation, InviteUsersMutationVariables>(InviteUsersDocument, options);
      }
export type InviteUsersMutationHookResult = ReturnType<typeof useInviteUsersMutation>;
export type InviteUsersMutationResult = Apollo.MutationResult<InviteUsersMutation>;
export type InviteUsersMutationOptions = Apollo.BaseMutationOptions<InviteUsersMutation, InviteUsersMutationVariables>;