import React, { useCallback, useMemo } from "react";

import { stringIsEmpty, stringNotEmpty, useSaferFormikContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Dropdown, Form, Skeleton, Text, useTheme, useToast } from "@equiem/react-admin-ui";
import { RiMapPinLine } from "@equiem/react-admin-ui/icons";

import { ReqMgtBuildingsQueryRole, useRequestDestinationBuildingsQuery } from "../../generated/requests-client";
import type { NewRequestFormValues } from "../create-request/NewRequest";
import { useAutoWidth } from "../request-details/hooks/useAutoWidth";

interface Space {
  uuid: string;
  name: string;
}
type Levels = Record<
  string,
  {
    uuid: string;
    name: string;
    spaces: Space[];
  }
>;
type Building = {
  uuid: string;
  name: string;
  levels: Levels;
};
type Buildings = Record<string, Building>;

export const NewRequestLocation = ({ disabled }: { disabled: boolean }) => {
  const { ref, width } = useAutoWidth();
  const theme = useTheme();
  const { t } = useTranslation();
  const { values, setValues, resetForm } = useSaferFormikContext<NewRequestFormValues>();
  const toast = useToast();

  const resetSelection = useCallback(() => {
    resetForm({
      values: {
        ...values,
        buildingLevelUuid: undefined,
        buildingUuid: undefined,
        space: undefined,
        subCategory: undefined,
        category: undefined,
        queue: undefined,
      },
    });
  }, []);

  const { data, loading: buildingLoading } = useRequestDestinationBuildingsQuery({
    variables: { as: ReqMgtBuildingsQueryRole.RequestManager },
  });
  const buildings: Buildings = useMemo(() => {
    return (data?.reqMgt.buildings ?? [])
      .map((item) => ({
        ...item,
        buildingLevels: item.buildingLevels.filter(({ spaces }) => spaces.length > 0),
      }))
      .filter(({ buildingLevels }) => buildingLevels.length > 0)
      .reduce<Buildings>((prev, curr) => {
        prev[curr.uuid] = {
          uuid: curr.uuid,
          name: curr.name,
          levels: curr.buildingLevels.reduce<Levels>((lprev, lcurr) => {
            lprev[lcurr.uuid] = { ...lcurr };

            return lprev;
          }, {}),
        };

        return prev;
      }, {});
  }, [data?.reqMgt.buildings]);

  const location = useMemo(() => {
    if (stringIsEmpty(values.buildingUuid) || stringIsEmpty(values.buildingLevelUuid)) {
      return t("requests.newRequest.selectLocation");
    }
    const building = buildings[values.buildingUuid as string] as Building;
    const level = building.levels[values.buildingLevelUuid as string] as Building["levels"][string];
    const space = level.spaces.find(({ uuid }: Space) => uuid === values.space?.uuid);

    return [building.name, level.name, space?.name].filter(stringNotEmpty).join(", ");
  }, [buildings, values.buildingUuid, values.buildingLevelUuid, values.space?.uuid]);

  const spacesOptions = useMemo(() => {
    if (values.buildingUuid != null && values.buildingLevelUuid != null) {
      const building = buildings[values.buildingUuid];
      const level = building.levels[values.buildingLevelUuid];

      return level.spaces.map((space) => ({
        value: space.uuid,
        label: space.name,
      }));
    }

    return [];
  }, [buildings, values.buildingUuid, values.buildingLevelUuid]);

  const levelsOptions = useMemo(() => {
    return values.buildingUuid != null
      ? Object.keys(buildings[values.buildingUuid].levels).map((uuid) => ({
          value: buildings[values.buildingUuid!].levels[uuid].uuid,
          label: buildings[values.buildingUuid!].levels[uuid].name,
        }))
      : [];
  }, [buildings, values.buildingUuid]);

  return (
    <>
      <div ref={ref} className="building-container">
        <Dropdown.Container
          mobileView="minimal"
          maxHeight={240}
          placement="bottom-start"
          width={width}
          trigger={
            <Form.SelectFacade disabled={disabled} showChrome>
              <div className={"d-flex align-items-center location"}>
                <RiMapPinLine className="mr-2" size="16px" /> {location}
              </div>
            </Form.SelectFacade>
          }
          onClose={() => {
            if (stringIsEmpty(values.space?.uuid)) {
              resetSelection();
              toast.negative(t("requests.details.spaceIsRequired"));
            }
          }}
        >
          <div className="inside">
            {buildingLoading ? (
              <Skeleton.Line width="100%" height="40px" borderRadius={theme.borderRadius} />
            ) : (
              <>
                <div>
                  <div className="pb-3">
                    <Text variant="label" color={theme.colors.grayscale["50"]}>
                      {t("common.building")}
                    </Text>
                  </div>
                  <Form.DynamicSelect
                    className="building"
                    value={values.buildingUuid ?? ""}
                    mobileView="minimal"
                    name="building"
                    options={Object.keys(buildings).map((uuid) => ({
                      value: buildings[uuid].uuid,
                      label: buildings[uuid].name,
                    }))}
                    onChange={(e) => {
                      void setValues((prev) => ({
                        ...prev,
                        buildingUuid: e.target.value as string,
                        buildingLevelUuid: undefined,
                        space: undefined,
                        subCategory: undefined,
                        category: undefined,
                        queue: undefined,
                      }));
                    }}
                  />
                </div>
                {values.buildingUuid != null && buildings[values.buildingUuid] != null && (
                  <>
                    <div>
                      <div className="pb-3">
                        <Text variant="label" color={theme.colors.grayscale["50"]}>
                          {t("requests.details.level")}
                        </Text>
                      </div>
                      <Form.DynamicSelect
                        className="level"
                        disabled={values.buildingUuid == null}
                        value={values.buildingLevelUuid ?? ""}
                        mobileView="minimal"
                        name="level"
                        options={levelsOptions}
                        onChange={(e) => {
                          void setValues((prev) => ({
                            ...prev,
                            buildingLevelUuid: e.target.value as string,
                            space: undefined,
                            subCategory: undefined,
                            category: undefined,
                            queue: undefined,
                          }));
                        }}
                      />
                    </div>

                    {values.buildingLevelUuid != null &&
                      buildings[values.buildingUuid].levels[values.buildingLevelUuid] != null && (
                        <div>
                          <div className="pb-3">
                            <Text variant="label" color={theme.colors.grayscale["50"]}>
                              {t("requests.details.space")}
                            </Text>
                          </div>
                          <Form.DynamicSelect
                            className="space"
                            disabled={values.buildingLevelUuid == null}
                            value={values.space?.uuid ?? ""}
                            mobileView="minimal"
                            name="level"
                            options={spacesOptions}
                            onChange={(e) => {
                              if (values.buildingUuid != null && values.buildingLevelUuid != null) {
                                const space = buildings[values.buildingUuid].levels[
                                  values.buildingLevelUuid
                                ].spaces.find(({ uuid }) => uuid === e.target.value);

                                void setValues((prev) => ({
                                  ...prev,
                                  space,
                                  subCategory: undefined,
                                  category: undefined,
                                  queue: undefined,
                                }));
                              }
                            }}
                          />
                        </div>
                      )}
                  </>
                )}
              </>
            )}
          </div>
        </Dropdown.Container>
      </div>

      <style jsx>{`
        .location {
          color: ${theme.colors.grayscale["60"]};
        }
        .building-container {
          margin-left: -${theme.spacers.s3};
        }
        .inside {
          display: flex;
          flex-direction: column;
          gap: ${theme.spacers.s4};
          padding: ${theme.spacers.s3};
        }
        .disabled {
          color: ${theme.colors.grayscale["40"]};
        }
      `}</style>
    </>
  );
};
