import { useTranslation } from "@equiem/localisation-eq1";
import { DateTime } from "@equiem/react-admin-ui";
import React from "react";
import { BookingActivityListItem } from "./BookingActivityList";
import { ValuePills } from "../../../components/ValuePills";

type ChangeType =
  | "created"
  | "created"
  | "time"
  | "roomConfiguration"
  | "user"
  | "cancelledReason"
  | "approvalStatus"
  | "adjustment"
  | "partialRefund"
  | "note"
  | "adminNote"
  | "totalPrice";

interface Props {
  changeInitiator: string;
  timestamp: number;
  timezone: string;
  changeType: ChangeType;
  value?: React.ReactNode | null;
  boldValue?: boolean;
}

export const BookingActivityLogChangeRow: React.FC<Props> = ({
  changeInitiator,
  timestamp,
  timezone,
  changeType,
  value,
  boldValue = true,
}) => {
  const { t, i18n } = useTranslation();

  const changeDescriptions: Record<ChangeType, (val: React.ReactNode | null) => React.ReactNode> = {
    created: () => <>{t("bookings.operations.createdBooking")}</>,
    time: (val) => (
      <>
        {t("bookings.operations.updatedTheBooking")} {val}
      </>
    ),
    roomConfiguration: (val) => (
      <>
        {t("bookings.operations.changedTheResource")} {val}
      </>
    ),
    user: (val) => (
      <>
        {t("bookings.operations.changedTheUser")} {val}
      </>
    ),
    cancelledReason: () => <>{t("bookings.operations.hasCancelledTheBooking")}</>,
    adjustment: () => <>{t("bookings.operations.hasChargedTheBooking")}</>,
    partialRefund: () => <>{t("bookings.operations.hasPartiallyRefundedTheBooking")}</>,
    approvalStatus: (val) => (
      <>
        {t("bookings.operations.updatedApprovalStatus")} {val}
      </>
    ),
    note: (val) => (
      <>
        {t("bookings.operations.addedHostNote")} {val}
      </>
    ),
    adminNote: (val) => (
      <>
        {t("bookings.operations.addedAdminNote")} {val}
      </>
    ),
    totalPrice: (val) => <>{val}</>,
  };

  return (
    <BookingActivityListItem>
      <ValuePills>
        <span>
          <span className="font-weight-bold">{changeInitiator}</span>{" "}
          {changeDescriptions[changeType](
            <span className={boldValue ? "font-weight-bold" : ""} style={{ wordWrap: "break-word" }}>
              {value ?? `(${t("bookings.operations.unspecified")})`}
            </span>,
          )}
        </span>
        <span className="text-muted">
          <DateTime.DateDisplay language={i18n.language} datetime={timestamp} timezone={timezone} />
        </span>
        <span className="text-muted">
          <DateTime.TimeDisplay language={i18n.language} datetime={timestamp} timezone={timezone} />
        </span>
      </ValuePills>
    </BookingActivityListItem>
  );
};
