import React, { useEffect, useMemo } from "react";

import { stringIsEmpty, useSaferFormikContext, useShowError } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Dropdown, Form, Skeleton, Text, useDebounced, useTheme } from "@equiem/react-admin-ui";
import { RiQuestionLine } from "@equiem/react-admin-ui/icons";

import { CategoryDisplay } from "../../components/CategoryDisplay";
import { useCategoriesDropdownQuery, useSubCategoriesQuery } from "../../generated/requests-client";
import type { NewRequestFormValues } from "../create-request/NewRequest";
import { useAutoWidth } from "../request-details/hooks/useAutoWidth";

export const NewRequestCategory = ({ disabled }: { disabled: boolean }) => {
  const { ref, width } = useAutoWidth();
  const theme = useTheme();
  const { t } = useTranslation();
  const showError = useShowError();
  const { values, setValues } = useSaferFormikContext<NewRequestFormValues>();
  const {
    data: catData,
    loading: catLoading,
    error: catError,
  } = useCategoriesDropdownQuery({
    variables: { buildingUuid: values.buildingUuid, ownerCompanyUuid: values.space?.ownerCompany?.uuid },
    skip: stringIsEmpty(values.space?.uuid),
  });

  const {
    data: subData,
    loading: subLoading,
    error: subError,
    refetch: subRefect,
  } = useSubCategoriesQuery({
    variables: { categoryUuid: values.category as string },
    skip: values.category == null,
  });

  const currentCategory = catData?.reqMgt.categories.find((item) => item.uuid === values.category)?.name;

  const debouncedCategory = useDebounced(values.category, 500);
  useEffect(() => {
    if (debouncedCategory == null) {
      return;
    }

    subRefect({ categoryUuid: debouncedCategory }).catch(showError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedCategory]);

  const categories = useMemo(
    () => (catData?.reqMgt.categories ?? []).filter((cat) => cat.queue.viewerRelations.requestManager),
    [catData?.reqMgt.categories],
  );
  const subcategories = subData?.reqMgt.subCategories ?? [];
  const categoriesOptions = categories.map((cat) => ({
    value: cat.uuid,
    label: cat.name,
    queue: cat.queue.uuid,
  }));

  useEffect(() => {
    if (catError != null) {
      showError(catError);
    }
  }, [catError]);

  useEffect(() => {
    if (subError != null) {
      showError(subError);
    }
  }, [subError]);

  return (
    <div ref={ref} className="categories">
      <Dropdown.Container
        mobileView="minimal"
        disabled={values.buildingUuid == null}
        placement="bottom-start"
        width={width}
        trigger={
          <Form.SelectFacade disabled={disabled || stringIsEmpty(values.space?.uuid)} showChrome>
            {values.category == null || stringIsEmpty(currentCategory) ? (
              <div className="placeholder">
                <RiQuestionLine size="18px" className="mr-2" color={theme.colors.grayscale[50]} />
                <span color={theme.colors.grayscale["50"]}>{t("requests.newRequest.selectCategory")}</span>
              </div>
            ) : (
              <CategoryDisplay
                name={catData?.reqMgt.categories.find((item) => item.uuid === values.category)?.name ?? ""}
                subcategory={subData?.reqMgt.subCategories.find((item) => item.uuid === values.subCategory)?.name ?? ""}
                icon={categories.find((item) => item.uuid === values.category)?.iconName}
                disabled={stringIsEmpty(values.space?.uuid)}
              />
            )}
          </Form.SelectFacade>
        }
        onClose={() => {
          if (values.category == null && values.subCategory == null) {
            return;
          }

          void setValues((prev) => ({
            ...prev,
            category: values.category,
            subCategory: values.subCategory,
            queue: values.queue,
          }));
        }}
      >
        <div className="inside">
          <div>
            <div className="pb-3">
              <Text variant="label" color={theme.colors.grayscale["50"]}>
                {t("requests.details.category")}
              </Text>
            </div>
            {catLoading ? (
              <Skeleton.Line width="100%" height="42px" borderRadius={theme.borderRadius} />
            ) : categoriesOptions.length === 0 ? (
              <div>{t("requests.details.noCategory")}</div>
            ) : (
              <Form.DynamicSelect
                className="category"
                disabled={values.buildingUuid == null}
                value={values.category ?? ""}
                mobileView="minimal"
                name="category"
                options={categoriesOptions}
                onChange={(e) => {
                  void setValues((prev) => ({
                    ...prev,
                    category: e.target.value,
                    queue: categoriesOptions.find((item) => item.value === e.target.value)?.queue,
                    subCategory: undefined,
                  }));
                }}
              />
            )}
          </div>

          <div>
            <div className="pb-3">
              <Text variant="label" color={theme.colors.grayscale["50"]} className="pb-2">
                {t("requests.details.subcategory")}
              </Text>
            </div>
            {subLoading ? (
              <Skeleton.Line width="100%" height="40px" borderRadius={theme.borderRadius} />
            ) : (
              <>
                {subcategories.length === 0 ? (
                  <div>{t("requests.details.noSubcategory")}</div>
                ) : (
                  <Form.DynamicSelect
                    className="subcategory"
                    disabled={values.buildingUuid == null}
                    value={values.subCategory ?? ""}
                    mobileView="minimal"
                    name="category"
                    options={[
                      { value: "", label: `${t("common.select")} ${t("requests.details.subcategory")}` },
                    ].concat(
                      subcategories.map((sub) => ({
                        value: sub.uuid,
                        label: sub.name,
                      })),
                    )}
                    onChange={(e) => {
                      void setValues((prev) => ({ ...prev, subCategory: e.target.value }));
                    }}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </Dropdown.Container>
      <style jsx>{`
        .categories {
          margin-left: -${theme.spacers.s3};
        }

        .placeholder {
          display: flex;
          align-items: center;
          color: ${theme.colors.grayscale["50"]};
        }

        .inside {
          display: flex;
          flex-direction: column;
          gap: ${theme.spacers.s4};
          padding: ${theme.spacers.s3};
        }
      `}</style>
    </div>
  );
};
