import type { DateTime, WeekdayNumbers } from "luxon";
import type { ValidateBookingTimesQuery } from "../../../generated/gateway-client";

export interface RecurringDate {
  startTime: DateTime;
  endTime: DateTime;
}

export type ValidatedRecurringDate = ValidateBookingTimesQuery["validateBookingTimes"][number];

export interface RecurringSettings {
  start: DateTime;
  end: DateTime;
  recurringType: number;
  repeatEvery: number;
  repeatOn: WeekdayNumbers[];
  repeatEndsTimes?: number;
  repeatEndsUntil?: DateTime;
  sameWeekDayEachMonth: boolean;
  lastWeekDayEachMonth: boolean;
  // The original selected time and end.
  selectedStartTime: DateTime;
  selectedEndTime: DateTime;
  // Consist of selected dates and its availability from backend.
  validatedRecurringDates: ValidatedRecurringDate[];
  // The simplest way I can think of to detect changes.
  version?: string;
}

export enum RecurringType {
  Daily,
  Weekly,
  Monthly,
}
