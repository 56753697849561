import type { TFunction } from "@equiem/localisation-eq1";

import type { SpaceVisibilityType } from "../../../generated/settings-client";

export function translateVisibilityType(t: TFunction, visibilityType?: SpaceVisibilityType | null) {
  switch (visibilityType) {
    case "PUBLIC":
      return t("settings.build.widget.public");
    case "MEMBERS":
      return t("settings.build.widget.members");
    case "PRIVATE":
      return t("settings.build.widget.private");
    default:
      return t("common.unknown");
  }
}
