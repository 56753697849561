import { stringNotEmpty, useSiteContext, withDefaultPrevented } from "@equiem/lib";
import { formatters, useTranslation } from "@equiem/localisation-eq1";
import { Skeleton, Tag, useTheme, useWindowDimensions } from "@equiem/react-admin-ui";
import { DateTime } from "luxon";
import React, { useMemo } from "react";
import { ResouceCardImage } from "../../resources/components/ResourceCardImage";
import { ValuePills } from "../../../components/ValuePills";
import { resourceTypeToString } from "../../../lib/resourceTypeToString";
import { ResourceCardCapacity } from "../../../components/ResourceCardCapacity";
import { BookingStatus } from "../../../generated/gateway-client";
import { useRouter } from "next/router";

const imgWidth = 307;
const imgHeight = 170;
const imgWidthMobile = 96;
const imgHeightMobile = 96;

export const MyBookingCardLoading = () => {
  const { width: windowWidth } = useWindowDimensions();
  const { borderRadius, breakpoints, spacers } = useTheme(true);

  const isMobile = windowWidth <= breakpoints.md;
  const cardImgWidth = isMobile ? imgWidthMobile : imgWidth;
  const cardImgHeight = isMobile ? imgHeightMobile : imgHeight;

  return (
    <>
      <div className="card-container">
        <div className="image-section">
          <Skeleton.Line width={`${cardImgWidth}px`} height={`${cardImgHeight}px`} borderRadius={borderRadius} />
        </div>
        <div>
          <div className="mb-3">
            <Skeleton.Line width="250px" height="18px" />
          </div>
          <div>
            <Skeleton.Line width="120px" height="10px" />
          </div>
          <div>
            <Skeleton.Line width="150px" height="10px" />
          </div>
          {isMobile && (
            <div className="mt-4">
              <Skeleton.Line width="180px" height="20px" />
            </div>
          )}
        </div>
      </div>
      <style jsx>{`
        .card-container {
          display: flex;
          flex-direction: column;
        }
        .card-container .image-section {
          margin-bottom: ${spacers.s4};
          margin-right: ${spacers.s0};
        }
        @media screen and (max-width: ${breakpoints.md}px) {
          .card-container {
            display: flex;
            flex-direction: row;
            width: 100%;
          }
          .card-container .image-section {
            margin-bottom: ${spacers.s0};
            margin-right: ${spacers.s5};
          }
        }
      `}</style>
    </>
  );
};

interface Props {
  reference: string;
  title?: string | null | undefined;
  status: BookingStatus;
  cancelled: boolean;
  startDate: number;
  endDate: number;
  resource: {
    images: string[];
    typeInfo: { name: string };
    name: string;
    displayCapacity?: number | null | undefined;
    destination: {
      name: string;
    };
    building?: {
      name: string;
    } | null;
    level?: {
      name: string;
    } | null;
  };
  currentTime: number;
}

export const MyBookingCard: React.FC<Props> = ({
  reference,
  title,
  status,
  cancelled,
  startDate,
  endDate,
  resource,
  currentTime,
}) => {
  const { i18n, t } = useTranslation();
  const { timezone } = useSiteContext();
  const { width: windowWidth } = useWindowDimensions();
  const { breakpoints, colors, spacers } = useTheme(true);
  const router = useRouter();

  const isMobile = windowWidth <= breakpoints.md;

  const cardImgWidth = isMobile ? imgWidthMobile : imgWidth;
  const cardImgHeight = isMobile ? imgHeightMobile : imgHeight;

  const url = stringNotEmpty(resource.images[0])
    ? `${resource.images[0]}?auto=compress&fit=crop&crop=entropy&h=${cardImgHeight}&w=${cardImgWidth}`
    : "";

  const handleCardClick = () => {
    const newUrl = new URL(router.asPath, window.location.origin);
    newUrl.searchParams.set("booking", reference);
    const newAsPath = `${newUrl.pathname}${newUrl.search}`;

    if (newAsPath !== router.asPath) {
      void router.push(newAsPath, undefined, { scroll: false });
    }
  };

  const isPast = currentTime > startDate;
  const isUpcoming = currentTime + 30 * 60 * 1000 < startDate;
  const isImminent = !isPast && !isUpcoming;

  const timeTagVariant = useMemo(() => {
    if (isPast) {
      return "dark";
    } else if (cancelled) {
      return "negative";
    } else if (isImminent) {
      return "positive";
    } else {
      return "bright";
    }
  }, [cancelled, isImminent, isPast]);

  const today = DateTime.fromMillis(currentTime, { zone: timezone });
  const date = DateTime.fromMillis(startDate, { zone: timezone });
  const dayDiff = date.diff(today).as("days");
  const dateDisplay =
    dayDiff === 0
      ? t("common.today")
      : dayDiff === 1
      ? t("common.tomorrow")
      : formatters.dateshort(startDate, i18n.language, { timezone });

  const timestamp =
    isImminent && !cancelled
      ? t("bookings.operations.starts", {
          time: formatters.relativeshort(startDate, i18n.language, { timezone }),
        })
      : `${dateDisplay}, ${formatters.timeshort(startDate, i18n.language, { timezone })} - ${formatters.timeshort(
          endDate,
          i18n.language,
          { timezone },
        )}`;

  return (
    <>
      <a className="my-booking-card" onClick={withDefaultPrevented(() => handleCardClick())}>
        <div className="image-container">
          <ResouceCardImage
            url={url}
            rounded
            width={`${cardImgWidth}px`}
            height={`${cardImgHeight}px`}
            topFloatingEl={
              <div className="d-flex justify-content-end">
                <ResourceCardCapacity capacity={resource.displayCapacity} />
              </div>
            }
            bottomFloatingEl={
              <div className="img-booking-time">
                <Tag variant={timeTagVariant} size="large">
                  {timestamp}
                </Tag>
              </div>
            }
          />
        </div>
        <div className="body">
          <span className="title pb-2">{(stringNotEmpty(title) ? `[${title}] | ` : "") + resource.name}</span>
          <div>
            <Tag variant="default">#{reference}</Tag>
          </div>
          <div>
            {status === BookingStatus.PendingApproval && (
              <Tag variant="default">{t("bookings.operations.status.pendingApproval")}</Tag>
            )}
            {status === BookingStatus.PendingWorkplaceManagerApproval && (
              <Tag variant="default">{t("bookings.operations.status.pendingWorkplaceManagerApproval")}</Tag>
            )}
            {status === BookingStatus.Declined && (
              <Tag variant="negative">{t("bookings.operations.status.declined")}</Tag>
            )}
            {status === BookingStatus.Cancelled && <Tag variant="negative">{t("bookings.operations.cancelled")}</Tag>}
          </div>
          <ValuePills>
            <span>{resourceTypeToString(resource.typeInfo.name, t)}</span>
          </ValuePills>
          <ValuePills>
            <span>
              {resource.destination.name}
              {resource.building != null && `, ${resource.building.name}`}
              {resource.building != null && resource.level != null && `, ${resource.level.name}`}
            </span>
          </ValuePills>
          <div className="booking-time">
            <Tag variant={timeTagVariant} size="large">
              {timestamp}
            </Tag>
          </div>
        </div>
      </a>
      <style jsx>{`
        .my-booking-card {
          display: flex;
          flex-direction: column;
          position: relative;
          width: ${cardImgWidth}px;
          height: 100%;
          overflow: hidden;
          text-decoration: none;
          gap: ${spacers.s4};
          cursor: pointer;
        }
        @media screen and (max-width: ${breakpoints.md}px) {
          .my-booking-card {
            flex-direction: row;
            width: 100%;
            gap: ${spacers.s5};
          }
        }

        .booking-time {
          margin-top: ${spacers.s4};
        }
        .img-booking-time {
          display: none;
        }
        @media screen and (min-width: ${breakpoints.md + 1}px) {
          .booking-time {
            display: none;
          }
          .img-booking-time {
            display: block;
          }
        }

        .image-container {
          overflow: hidden;
        }
        .body {
          display: flex;
          flex-direction: column;
          gap: ${spacers.s2};
        }
        .title {
          color: ${colors.dark};
          font-size: 16px;
          font-weight: 700;
        }
        @media screen and (max-width: ${breakpoints.md}px) {
          .title {
            padding-bottom: ${spacers.s0};
          }
        }
        :hover .title {
          text-decoration: underline;
        }
        .cancelled {
          color: red;
        }
        .my-booking-card :global(.value-pills) {
          font-size: 12px;
          color: ${colors.muted0};
        }
      `}</style>
    </>
  );
};
