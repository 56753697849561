import { stringNotEmpty } from "@equiem/lib";

import type { ReportParams } from "./ReportParams";
import { formatDuration, formatDateTime, formatPaymentMethod, formatCurrency } from "./formatters";
import { getCsvFileName } from "./getCsvFileName";
import { downloadCsv } from "./downloadCsv";

interface CsvRow {
  company: string;
  userName: string;
  invoiceContactName: string;
  invoiceContactEmail: string;
  location: string;
  resource: string;
  reference: string;
  created: string;
  startDate: string;
  duration: string;
  resourceCost: string;
  discountNames: string;
  discountTotalPercentage: string;
  discountTotalAmount: string;
  addOnsCost: string;
  additionalCharges: string;
  refunds: string;
  cancellationCost: string;
  totalTax: string;
  totalCost: string;
  originalTotalCost: string;
  paymentMethod: string;
  hostNote: string;
  adminNote: string;
  cancelled: string;
  totalsRowLabel?: "Totals" | "";
}

export const downloadReconciliationReport = (params: ReportParams) => {
  const { timezone, bookings, totals, currencyCode, fallbackCurrencyCode } = params;

  downloadCsv<CsvRow>({
    fileName: getCsvFileName("reconciliation", params),
    columns: [
      { key: "company", header: "Company" },
      { key: "userName", header: "Host" },
      { key: "invoiceContactName", header: "Person invoiced" },
      { key: "invoiceContactEmail", header: "Invoicing email" },
      { key: "location", header: "Location" },
      { key: "resource", header: "Resource" },
      { key: "reference", header: "Reference" },
      { key: "created", header: "Creation date" },
      { key: "startDate", header: "Date of booking" },
      { key: "duration", header: "Duration" },
      { key: "resourceCost", header: "Booking cost" },
      { key: "discountNames", header: "Discount name" },
      { key: "discountTotalPercentage", header: "Discount percentage" },
      { key: "discountTotalAmount", header: "Discount amount" },
      { key: "addOnsCost", header: "Extras cost" },
      { key: "additionalCharges", header: "Additional charges" },
      { key: "refunds", header: "Refunds" },
      { key: "cancellationCost", header: "Cancellation cost" },
      { key: "totalTax", header: "Tax" },
      { key: "totalCost", header: "Total cost" },
      { key: "originalTotalCost", header: "Original total cost" },
      { key: "paymentMethod", header: "Payment method" },
      { key: "hostNote", header: "Host note" },
      { key: "adminNote", header: "Admin note" },
      { key: "cancelled", header: "Cancelled" },
      { key: "totalsRowLabel", header: "" },
    ],
    rows: [
      ...bookings.map((booking) => ({
        company: booking.companyName ?? "",
        userName: booking.userName ?? "",
        invoiceContactName: booking.invoiceContactName ?? "",
        invoiceContactEmail: booking.invoiceContactEmail ?? "",
        location: [booking.buildingName, booking.destinationName].filter(stringNotEmpty).join(", "),
        resource: booking.resourceName ?? "",
        reference: booking.reference,
        created: formatDateTime(booking.created, timezone),
        startDate: formatDateTime(booking.startDate, timezone),
        duration: formatDuration(booking.durationHours),
        resourceCost: formatCurrency(booking.resourcePrice, booking.currencyCode ?? fallbackCurrencyCode),
        discountNames: booking.discountNames.join(", "),
        discountTotalPercentage: `${booking.discountTotalPercentage}`,
        discountTotalAmount: formatCurrency(booking.discountTotalAmount, booking.currencyCode ?? fallbackCurrencyCode),
        addOnsCost: formatCurrency(booking.addOnsPrice, booking.currencyCode ?? fallbackCurrencyCode),
        additionalCharges: formatCurrency(booking.adjustmentsTotalPrice, booking.currencyCode ?? fallbackCurrencyCode),
        refunds: formatCurrency(booking.allPartialRefundsTotalPrice, booking.currencyCode ?? fallbackCurrencyCode),
        cancellationCost: formatCurrency(booking.cancellationPrice, booking.currencyCode ?? fallbackCurrencyCode),
        totalTax: formatCurrency(booking.totalTax, booking.currencyCode ?? fallbackCurrencyCode),
        totalCost: formatCurrency(booking.totalPrice, booking.currencyCode ?? fallbackCurrencyCode),
        originalTotalCost:
          booking.originalTotalPrice != null
            ? formatCurrency(booking.originalTotalPrice, booking.currencyCode ?? fallbackCurrencyCode)
            : "",
        paymentMethod: formatPaymentMethod(booking.paymentMethod),
        hostNote: booking.note ?? "",
        adminNote: booking.adminNote ?? "",
        cancelled: booking.cancelled ? "TRUE" : "FALSE",
      })),
      {
        company: "",
        userName: "",
        invoiceContactName: "",
        invoiceContactEmail: "",
        location: "",
        resource: "",
        reference: "",
        created: "",
        startDate: "",
        duration: formatDuration(totals.durationHours),
        resourceCost: formatCurrency(totals.resourcePrice, currencyCode),
        discountNames: "",
        discountTotalPercentage: "",
        discountTotalAmount: formatCurrency(totals.discountTotalAmount, currencyCode),
        addOnsCost: formatCurrency(totals.addOnsPrice, currencyCode),
        additionalCharges: formatCurrency(totals.adjustmentsTotalPrice, currencyCode),
        refunds: formatCurrency(totals.allPartialRefundsTotalPrice, currencyCode),
        cancellationCost: formatCurrency(totals.cancellationPrice, currencyCode),
        totalTax: formatCurrency(totals.totalTax, currencyCode),
        totalCost: formatCurrency(totals.totalPrice, currencyCode),
        originalTotalCost: "",
        paymentMethod: "",
        hostNote: "",
        adminNote: "",
        cancelled: "",
        totalsRowLabel: "Totals",
      },
    ],
  });
};
