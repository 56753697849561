import { useApolloErrorTranslation, useTranslation } from "@equiem/localisation-eq1";
import { Button, Skeleton, Text, Tooltip, useTheme } from "@equiem/react-admin-ui";
import { RiInformationLine } from "@equiem/react-admin-ui/icons";
import React, { useCallback, useMemo, useState } from "react";
import type { BookingFragmentFragment } from "../../../generated/gateway-client";
import { useExistingBookingChargesQuery } from "../../../generated/gateway-client";
import { formatCurrency } from "../../../lib/formatCurrency";
import { BookingAdjustmentChargeModal } from "./BookingAdjustmentChargeModal";
import { BookingAdjustmentRefundModal } from "./BookingAdjustmentRefundModal";
import { flatExistingBookingAddOns } from "../libs/groupExistingBookingAddOns";
import { BookingChargeAdjustmentAndRefund } from "./BookingChargeAdjustmentAndRefund";
import { DiscountPercentageLineItem } from "./bookingPayment/BookingPaymentSummary";

interface P {
  booking: BookingFragmentFragment;
  bookingRefetch: () => void;
}
export const BookingDetailsCharges: React.FC<P> = ({ booking, bookingRefetch }) => {
  const freeBooking = booking.paymentMethod == null;
  const { t, i18n } = useTranslation();
  const { tError } = useApolloErrorTranslation();
  const theme = useTheme();
  const [showCharge, setShowCharge] = useState(false);
  const [showRefund, setShowRefund] = useState(false);
  const { data, loading, error, refetch } = useExistingBookingChargesQuery({
    fetchPolicy: "network-only",
    variables: { reference: booking.reference },
    skip: freeBooking,
  });
  const displayPrice = useCallback(
    (amount: number, code: string) => {
      return amount === 0 ? t("common.free") : formatCurrency(amount, code, i18n.language);
    },
    [i18n.language, t],
  );
  const info = data?.siteBookingByReference;
  const addOns = useMemo(() => flatExistingBookingAddOns(info?.addOns ?? [], t), [info?.addOns, t]);

  if (booking.paymentMethod == null) {
    return null;
  }

  if (loading) {
    return (
      <div className="loading">
        <Skeleton.Line width="100px" height="15px" block className="mb-4" dark />
        <Skeleton.Line width="250px" height="20px" dark />
      </div>
    );
  }

  if (error != null) {
    return <div>{tError(error)}</div>;
  }
  if (info == null) {
    return null;
  }

  const currencyCode = info.currencyCode ?? "AUD";
  const bookingName = t("bookings.operations.bookingReference", { reference: booking.reference }) as string;

  return (
    <>
      <div className="pb-5 pricing booking-charges">
        <div className="pb-6 text-medium">
          <Text variant="label">{t("bookings.common.bookingCharges")}</Text>
        </div>
        <div>
          <div className="info">
            <div>{bookingName}</div>
            <div className={info.discounts.length > 0 ? "strikethrough" : ""}>
              {displayPrice(info.resourcePrice, currencyCode)}
            </div>
          </div>
          {info.discounts.length > 0 && (
            <>
              {info.discounts.map((d, key) => (
                <DiscountPercentageLineItem
                  key={key}
                  className="ps-4 mb-2 discount-info"
                  name={d.title}
                  percentage={d.percentage}
                />
              ))}
              <div className="info ps-4">
                <div>{t("bookings.discounts.priceWithDiscount")}</div>
                <div>{displayPrice(info.resourcePriceWithDiscount, currencyCode)}</div>
              </div>
            </>
          )}
          {info.partialRefunds.map((r) => (
            <BookingChargeAdjustmentAndRefund
              key={r.uuid}
              name={
                t("bookings.operations.refundLineItem", {
                  item: bookingName,
                  user: r.user.profile?.displayName ?? t("common.unknown"),
                }) as string
              }
              amount={displayPrice(r.amount, currencyCode)}
              description={r.reason}
              className="mb-2"
            />
          ))}
          {addOns.map((ao) => (
            <>
              <div className="info" key={ao.uuid}>
                <div>
                  {ao.description} {ao.quantity > 1 ? `(x${ao.quantity})` : null}
                </div>
                <div>{displayPrice(ao.amount, currencyCode)}</div>
              </div>
              {ao.partialRefunds.map((r) => (
                <BookingChargeAdjustmentAndRefund
                  key={r.uuid}
                  name={
                    t("bookings.operations.refundLineItem", {
                      item: ao.description,
                      user: r.user,
                    }) as string
                  }
                  amount={displayPrice(r.amount, currencyCode)}
                  description={r.reason}
                  className="mb-2"
                />
              ))}
            </>
          ))}
          {info.adjustments.map((adj) => (
            <>
              <BookingChargeAdjustmentAndRefund
                name={
                  t("bookings.operations.adjustmentLineItem", {
                    user: adj.user.profile?.displayName ?? t("common.unknown"),
                  }) as string
                }
                amount={displayPrice(adj.amount, currencyCode)}
                mode="adjustment"
                description={adj.reason}
                className="mb-2"
              />
              {adj.partialRefunds.map((r) => (
                <BookingChargeAdjustmentAndRefund
                  key={r.uuid}
                  name={
                    t("bookings.operations.refundLineItem", {
                      item: t("bookings.operations.additionalCharge"),
                      user: r.user.profile?.displayName ?? t("common.unknown"),
                    }) as string
                  }
                  amount={displayPrice(r.amount, currencyCode)}
                  description={r.reason}
                  className="mb-2"
                />
              ))}
            </>
          ))}
        </div>
        <div className="final mt-auto">
          {info.taxes.map((tax, i) => (
            <div className="info text-muted" key={i}>
              <div>{tax.description}</div>
              <div>{displayPrice(tax.price, currencyCode)}</div>
            </div>
          ))}
          <div className="total font-weight-bold">
            <div className="d-flex justify-content-between">
              <div>{t("common.total")}</div>
              <div className="d-flex">
                <span>{displayPrice(info.totalPrice, currencyCode)}</span>
                {booking.originalTotalPrice != null && (
                  <Tooltip
                    title={t("bookings.reports.totalPriceOverrideTooltip", {
                      originalTotalPrice: formatCurrency(booking.originalTotalPrice, currencyCode, i18n.language),
                    })}
                    showArrow
                  >
                    <span className="ml-2" style={{ display: "inline-flex", fontSize: 16 }}>
                      <RiInformationLine size={16} color={theme.colors.grayscale[50]} />
                    </span>
                  </Tooltip>
                )}
              </div>
            </div>
            <div className="pt-5 cta">
              <Button
                variant="secondary"
                onClick={() => setShowRefund(true)}
                disabled={!booking.isPaymentAdjustmentAllowed}
              >
                {t("bookings.operations.addRefund")}
              </Button>
              <Button
                variant="secondary"
                onClick={() => setShowCharge(true)}
                disabled={!booking.isPaymentAdjustmentAllowed}
              >
                {t("bookings.operations.addCharge")}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <BookingAdjustmentChargeModal
        booking={booking}
        onHide={() => {
          refetch().catch(console.log);
          bookingRefetch();
          setShowCharge(false);
        }}
        showModal={showCharge}
      />
      <BookingAdjustmentRefundModal
        addOns={addOns}
        charges={info}
        booking={booking}
        onHide={() => {
          refetch().catch(console.log);
          bookingRefetch();
          setShowRefund(false);
        }}
        showModal={showRefund}
      />
      <style jsx>{`
        .info {
          margin-bottom: ${theme.spacers.s3};
          display: flex;
          justify-content: space-between;
        }
        .info div {
          word-break: break-word;
        }
        .info div:first-child {
          max-width: 80%;
        }
        .strikethrough {
          text-decoration: line-through;
        }
        .final,
        .total {
          border-top: 1px solid ${theme.colors.border};
          padding-top: ${theme.spacers.s5};
          margin-top: ${theme.spacers.s5};
        }
        .pricing {
          display: flex;
          height: 100%;
          flex-direction: column;
        }
        .cta {
          display: flex;
          padding-top: ${theme.spacers.s5};
          justify-content: space-between;
        }
        .cta :global(button) {
          width: 49%;
        }
      `}</style>
    </>
  );
};
