import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { isEmpty } from "lodash";

import type { OnChange } from "@equiem/lib";
import { CurrentProfile, stringIsEmpty, useSaferFormikContext, useShowError } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Avatar, Form, Skeleton, useTheme } from "@equiem/react-admin-ui";

import { HumanTag } from "../../components/HumanTag";
import { ProfileTag } from "../../components/ProfileTag";
import type { ProfileFragmentFragment } from "../../generated/requests-client";
import { useListProfilesQuery } from "../../generated/requests-client";
import type { NewRequestFormValues } from "../create-request/NewRequest";

export const NewRequestWatchers = ({ disabled }: { disabled: boolean }) => {
  const showError = useShowError();
  const theme = useTheme();
  const { t } = useTranslation();
  const profile = useContext(CurrentProfile);
  const { values, setFieldValue } = useSaferFormikContext<NewRequestFormValues>();
  const [selectedWatchers, setSelectedWatchers] = useState<
    Array<{
      label: { searchText: string; element: React.JSX.Element; facadeElement: React.JSX.Element };
      value: string;
      watcher: ProfileFragmentFragment;
    }>
  >([]);

  const isRequestWatchersDisabled =
    stringIsEmpty(values.space?.uuid) || stringIsEmpty(values.category) || stringIsEmpty(values.status);

  const { data, loading, error, refetch } = useListProfilesQuery({
    fetchPolicy: "network-only",
    skip: isEmpty(profile.siteProfile?.site.destination?.uuid),
    variables: {
      page: {
        first: 5,
      },
      filter: {
        active: true,
      },
      siteUuids: [profile.siteProfile?.site.destination?.uuid ?? ""],
    },
  });

  useEffect(() => {
    if (error != null) {
      showError(error);
    }
  }, [error]);

  const searchCb = useCallback(
    async (keyword: string) => {
      const kl = keyword.toLowerCase();
      const filter = kl.length === 0 ? {} : kl.includes("@") ? { email: kl } : { name: kl, email: kl };

      try {
        await refetch({ filter: { active: true, ...filter } });
      } catch (e: unknown) {
        showError(e);
      }
    },
    [refetch, showError],
  );

  const options = useMemo(() => {
    const createWatcherOption = (watcher: ProfileFragmentFragment) => ({
      label: {
        searchText: `${watcher.firstName} ${watcher.lastName}`,
        element: (
          <ProfileTag
            profileIconUrl={watcher.avatar ?? undefined}
            companyName={watcher.companyV2?.name ?? ""}
            email={watcher.email}
            firstName={watcher.firstName ?? ""}
            lastName={watcher.lastName ?? ""}
          />
        ),
        facadeElement: (
          <Avatar
            size="small"
            imageUrl={watcher.avatar ?? undefined}
            firstName={watcher.firstName ?? ""}
            lastName={watcher.lastName ?? ""}
          />
        ),
      },
      watcher,
      value: watcher.uuid,
    });

    const lists = (data?.listProfiles.edges ?? []).flatMap((edge) => {
      if (edge.node == null) {
        return [];
      }

      return [createWatcherOption(edge.node)];
    });

    if (selectedWatchers.length === 0) {
      return lists;
    }

    const filteredList = lists.filter(
      ({ value }) => !selectedWatchers.some(({ value: watcherValue }) => watcherValue === value),
    );

    return selectedWatchers
      .flatMap(({ watcher }) => {
        if (watcher == null) {
          return [];
        }

        return createWatcherOption(watcher);
      })
      .concat(filteredList);
  }, [data?.listProfiles.edges, selectedWatchers]);

  const onChange = useCallback(
    (e: OnChange) => {
      setSelectedWatchers((prev) => {
        const targetValues = new Set(e.target.value);

        // Filter new options not in prev that intersect with target values
        const newItems = options.filter((o) => !prev.some((p) => p.value === o.value));

        // Combine and return the final filtered array
        return [...prev, ...newItems].filter((p) => targetValues.has(p.value));
      });

      void setFieldValue("watchers", e.target.value);
    },
    [options, setSelectedWatchers],
  );

  if (loading) {
    return <Skeleton.Line width="100%" height="36px" />;
  }

  return (
    <>
      <div>
        <Form.MultiSelect
          options={options}
          mobileView="minimal"
          onChange={onChange}
          facade="summary"
          variant="wrap"
          className="watchers"
          searchCb={searchCb}
          searchPlaceholder={t("common.findAPerson")}
          emptyLabel={<HumanTag size="18px" text={t("requests.unassigned") as string} />}
          value={values.watchers}
          disabled={disabled || isRequestWatchersDisabled}
          onClose={(selected) => {
            void setFieldValue("watchers", selected);
          }}
          showChrome
        />
      </div>
      <style jsx>{`
        div {
          margin-left: -${theme.spacers.s3};
        }
      `}</style>
    </>
  );
};
