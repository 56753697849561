import type { DateTime, WeekdayNumbers } from "luxon";
import { Interval } from "luxon";
import { notNullOrUndefined } from "@equiem/lib";
import type { TFunction } from "@equiem/localisation-eq1";

export enum RecurringType {
  Daily = "DAILY",
  Monthly = "MONTHLY",
  Weekly = "WEEKLY",
}

export enum RecurringError {
  NO_LIMIT = "Occurrences has passed maximum limit.",
  INVALID_INPUT = "Invalid",
  MISSING_REPEAT_INFO = "No boundaries for calculating recurring.",
  EXCEED_MAX_DEFAULT_INDEX = "Exceed maximum default index.",
}

interface Current {
  index: number;
  readonly nthOccuranceOfWeekDayInMonth: number;
  limit: number;
  repeatTimes?: number;
  error?: RecurringError;
  result: DateTime[];
  currentDate: DateTime;
}

interface InputProp {
  t: TFunction;
  recurringType: RecurringType;
  repeatEvery?: number;
  repeatOn?: WeekdayNumbers[];
  startDate: DateTime;
  selectedStartTime: DateTime;
  selectedEndTime: DateTime;
  repeatEndsUntil?: DateTime;
  repeatEndsTimes?: number;
  sameDayEachMonth?: boolean;
  lastWeekDayEachMonth?: boolean;
}

export const defaultLimit = 100;
const RecurringDefaultIndexLimit = 356;

export const numberToRecurringType: Record<number, RecurringType> = {
  0: RecurringType.Daily,
  1: RecurringType.Weekly,
  2: RecurringType.Monthly,
};

export const getWeekdayOccuranceOfWeekdayInMonth = (value: DateTime): number => {
  return Interval.fromDateTimes(value.startOf("month"), value.endOf("month"))
    .splitBy({ day: 1 })
    .map((d) => d.start)
    .filter(notNullOrUndefined)
    .filter((day) => day.weekday === value.weekday && value.startOf("day").valueOf() >= day.startOf("day").valueOf())
    .length;
};

const validate = (params: InputProp, current: Current): { isValid: boolean; error?: RecurringError } => {
  if (current.error != null) {
    return { isValid: false, error: current.error };
  }

  if ((params.repeatEndsUntil == null && params.repeatEndsTimes == null) || params.repeatEvery == null) {
    return {
      error: RecurringError.MISSING_REPEAT_INFO,
      isValid: false,
    };
  }

  if (current.limit === 0) {
    return { error: RecurringError.NO_LIMIT, isValid: false };
  }

  if (current.index > RecurringDefaultIndexLimit) {
    return { error: RecurringError.EXCEED_MAX_DEFAULT_INDEX, isValid: false };
  }

  return { isValid: true };
};

const calculateFirstWeekDaysToAdd = (params: Readonly<InputProp>, current: Current) => {
  if (current.index !== 0 || params.recurringType !== RecurringType.Weekly) {
    return [];
  }

  const daysToInclude = Interval.fromDateTimes(params.startDate.startOf("week"), params.startDate.endOf("week"))
    .splitBy({ day: 1 })
    .map((d) => d.start)
    .filter(notNullOrUndefined)
    .filter(
      (x) =>
        (params.repeatOn ?? []).includes(x.weekday) &&
        x.startOf("day") > params.startDate.startOf("day") &&
        (params.repeatEndsUntil == null || params.repeatEndsUntil.startOf("day") >= x.startOf("day")),
    );

  return daysToInclude;
};

const shouldExit = (params: Readonly<InputProp>, current: Current): boolean => {
  const repeatTimesIsOver = current.repeatTimes != null && current.repeatTimes <= 0;
  const repeatUntilIsOver =
    params.repeatEndsUntil != null &&
    params.repeatEndsUntil.startOf(params.recurringType === RecurringType.Weekly ? "week" : "day") <
      current.currentDate.startOf(params.recurringType === RecurringType.Weekly ? "week" : "day");

  return repeatUntilIsOver || repeatTimesIsOver;
};

const calculateWeekDaysToAdd = (params: Readonly<InputProp>, current: Current) => {
  return Interval.fromDateTimes(current.currentDate.startOf("week"), current.currentDate.endOf("week"))
    .splitBy({ day: 1 })
    .map((d) => d.start)
    .filter(notNullOrUndefined)
    .filter(
      (x) =>
        (params.repeatOn ?? []).includes(x.weekday) &&
        (params.repeatEndsUntil == null ||
          params.repeatEndsUntil.startOf("day").valueOf() >= x.startOf("day").valueOf()),
    );
};

const calculateNthWeekdayInMonthDate = (
  value: DateTime,
  originalWeekday: number,
  nthOccuranceOfWeekDayInMonth: number,
  returnLast: boolean,
): DateTime | undefined => {
  const result = Interval.fromDateTimes(value.startOf("month"), value.endOf("month"))
    .splitBy({ day: 1 })
    .map((d) => d.start)
    .filter(notNullOrUndefined)
    .filter((d) => d.weekday === originalWeekday);

  return result[returnLast ? result.length - 1 : nthOccuranceOfWeekDayInMonth - 1];
};

// eslint-disable-next-line complexity
export function accumulateDates(
  params: InputProp,
  current: Current = {
    index: 0,
    limit: defaultLimit,
    nthOccuranceOfWeekDayInMonth: getWeekdayOccuranceOfWeekdayInMonth(params.startDate),
    // As we already included the start date in result array, we should reduce it by 1.
    repeatTimes: params.repeatEndsTimes != null ? params.repeatEndsTimes - 1 : undefined,
    result: [params.startDate],
    currentDate: params.startDate,
  },
): { result: DateTime[]; error?: RecurringError } {
  const validationResult = validate(params, current);

  if (!validationResult.isValid) {
    current.error = validationResult.error;
    return { result: [], error: current.error };
  }

  let newDatesAdded = false;

  // fill out the first week, if it's a weekly appointment
  const firstWeekDays = calculateFirstWeekDaysToAdd(params, current);
  current.result = current.result.concat(firstWeekDays);
  newDatesAdded = firstWeekDays.length > 0;
  if (newDatesAdded && params.recurringType === "WEEKLY" && current.repeatTimes != null) {
    current.repeatTimes -= firstWeekDays.length;
  }

  // increment & check if we should exit
  current.currentDate = current.currentDate.plus({
    days: params.recurringType === RecurringType.Daily ? params.repeatEvery : 0,
    weeks: params.recurringType === RecurringType.Weekly ? params.repeatEvery : 0,
    months: params.recurringType === RecurringType.Monthly ? params.repeatEvery : 0,
  });

  if (shouldExit(params, current)) {
    return { result: current.result, error: current.error };
  }

  // calculate dates to add
  switch (params.recurringType) {
    case RecurringType.Daily:
      current.result.push(current.currentDate);
      newDatesAdded = true;
      break;
    case RecurringType.Weekly:
      {
        const daysToAdd = calculateWeekDaysToAdd(params, current);
        current.result = current.result.concat(daysToAdd);
        // for the first week -- if we had any 'leftover' days in a week, assume that it counts.
        newDatesAdded = newDatesAdded ? true : daysToAdd.length > 0;
      }

      break;
    case RecurringType.Monthly:
      {
        const dayToAdd =
          params.sameDayEachMonth === true || params.lastWeekDayEachMonth === true
            ? calculateNthWeekdayInMonthDate(
                current.currentDate,
                params.startDate.weekday,
                current.nthOccuranceOfWeekDayInMonth,
                params.lastWeekDayEachMonth ?? false,
              )
            : current.currentDate;

        if (dayToAdd != null) {
          current.result.push(dayToAdd);
          newDatesAdded = true;
        }
      }
      break;
    default:
      break;
  }

  return accumulateDates(params, {
    ...current,
    index: current.index + 1,
    limit: newDatesAdded ? current.limit - 1 : current.limit,
    repeatTimes: current.repeatTimes != null && newDatesAdded ? current.repeatTimes - 1 : current.repeatTimes,
  });
}

// This function copied from VM generateRecurringDates query.
export const generateRecurringDates = (input: InputProp) => {
  const correctEndSettings =
    (input.repeatEndsTimes != null || input.repeatEndsUntil != null) &&
    !(input.repeatEndsTimes != null && input.repeatEndsUntil != null) &&
    input.repeatEvery != null;

  const isValidInput =
    (input.recurringType === RecurringType.Daily && correctEndSettings) ||
    (input.recurringType === RecurringType.Weekly && correctEndSettings && (input.repeatOn ?? []).length > 0) ||
    (input.recurringType === RecurringType.Monthly &&
      correctEndSettings &&
      !(input.sameDayEachMonth === true && input.lastWeekDayEachMonth === true));

  if (!isValidInput) {
    return { error: RecurringError.INVALID_INPUT, result: [] };
  }

  const calculate = accumulateDates(input);

  return {
    error: calculate.error,
    result: calculate.result.map((r) => ({
      startTime: r.set({
        hour: input.selectedStartTime.hour,
        minute: input.selectedStartTime.minute,
        second: 0,
        millisecond: 0,
      }),
      endTime: r.set({
        hour: input.selectedEndTime.hour,
        minute: input.selectedEndTime.minute,
        second: 0,
        millisecond: 0,
      }),
    })),
  };
};
