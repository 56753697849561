import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { CurrentProfile, CurrentRole, notNullOrUndefined, Role, useShowError } from "@equiem/lib";

import type { Building, BuildingLevel, ProfileSiteProfileConnection, Space } from "../../../generated/requests-client";
import {
  ProfileRegistrationType,
  SpaceVisibilityType,
  useCategoriesLazyQuery,
  useFlexManagerBuildingsLazyQuery,
  useFlexManagerLevelsLazyQuery,
  useMyResidenceLazyQuery,
  useQueuesListQuery,
  useRequestBuildingLevelsLazyQuery,
  useRequestBuildingsLazyQuery,
} from "../../../generated/requests-client";
import { RequestsAccessContext } from "../../requests/contexts/RequestsAccessContext";
import { CreateRequestContext } from "../CreateRequestContext";

export function useBuildingData() {
  const showError = useShowError();
  const { currentRole } = useContext(CurrentRole);
  const { profile } = useContext(CurrentProfile);
  const isFlexManager = useMemo(() => currentRole === Role.FlexManager, [currentRole]);
  const isUserResidential = useMemo(
    () =>
      profile?.siteProfiles.edges.some((sp) => sp.node?.registrationType === ProfileRegistrationType.Residential) ??
      false,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const [flexBuildingsQuery, { data: flexBuildingsData, loading: flexBuildingsLoading, error: flexBuildingsError }] =
    useFlexManagerBuildingsLazyQuery({
      variables: {
        first: 100,
      },
    });
  const [buildingQuery, { data: buildingsData, loading: buildingsLoading, error: buildingsError }] =
    useRequestBuildingsLazyQuery();
  const [myResidenceQuery, { data: myResidenceData, loading: myResidenceLoading }] = useMyResidenceLazyQuery();
  const [categoriesQuery, { data: categoriesData, loading: categoriesLoading }] = useCategoriesLazyQuery();
  const [flexLevelQuery, { data: flexLevelData, loading: flexLevelLoading }] = useFlexManagerLevelsLazyQuery();
  const [levelQuery, { data: levelsData, loading: levelLoading }] = useRequestBuildingLevelsLazyQuery();
  const { values } = useContext(CreateRequestContext);
  const [searchQuery, setSearchQuery] = useState("");
  const access = useContext(RequestsAccessContext);
  const { data: queuesForUserData } = useQueuesListQuery({
    fetchPolicy: "cache-and-network",
  });
  const isRequestManagerFromQueue = useMemo(
    () => queuesForUserData?.reqMgt.queues.some((queue) => queue.viewerRelations.requestManager) ?? false,
    [queuesForUserData],
  );
  const isManager = useMemo(
    () => currentRole === Role.PropertyManager || currentRole === Role.WorkplaceManager,
    [currentRole],
  );

  const spacesFilter = useCallback(
    (list: Space[] | []) => {
      if (isFlexManager) {
        return list;
      }
      // list of unique flex operator uuids
      const profileFlexOperatorUuids = [
        ...new Set(profile?.flexMemberships.map((flexMembership) => flexMembership.flexTenant.flexOperator.uuid)),
      ];
      return list.filter((space) => {
        // if space is public or user is part of the company that owns the space, or has a flex membership
        return (
          space.visibilityType === SpaceVisibilityType.Public ||
          (space.ownerCompany.flexOperator?.uuid != null
            ? profileFlexOperatorUuids.includes(space.ownerCompany.flexOperator.uuid)
            : profile?.companyV2?.uuid === space.ownerCompany.uuid)
        );
      });
    },
    [isFlexManager, profile],
  );

  const extractBuildings = useCallback(
    (myResidenceSitesProfile: ProfileSiteProfileConnection | undefined): Array<Pick<Building, "uuid" | "name">> => {
      const buildingsWithSpaces = (myResidenceSitesProfile?.edges ?? [])
        .flatMap((prof) => prof.node?.apartment?.buildingLevels.filter((level) => level.spaces.length > 0))
        .filter(notNullOrUndefined)
        .reduce<Record<string, { uuid: string; name: string }>>((obj, level) => {
          obj[level.building.uuid] = { uuid: level.building.uuid, name: level.building.name };
          return obj;
        }, {});
      return Object.values(buildingsWithSpaces);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [myResidenceData?.profile?.siteProfiles],
  );

  const extractBuildingLevels = useCallback(
    (myResidenceSitesProfile: ProfileSiteProfileConnection | undefined, buildingUuid: string) => {
      if (myResidenceSitesProfile != null) {
        const residence = myResidenceSitesProfile.edges.find(
          (sp) => sp.node?.apartment?.buildingLevels != null && sp.node.apartment.buildingLevels.length > 0,
        );
        return (
          residence?.node?.apartment?.buildingLevels
            .map((level) =>
              values.buildingUuid != null &&
              level.spaces.length > 0 && // don't show levels without spaces
              (level.building as Building).uuid === buildingUuid
                ? level
                : null,
            )
            .filter(notNullOrUndefined) ?? []
        );
      }

      return [];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [myResidenceData?.profile?.siteProfiles],
  );

  const buildings: Building[] = useMemo(() => {
    if (isUserResidential) {
      return extractBuildings(myResidenceData?.profile?.siteProfiles as ProfileSiteProfileConnection);
    }
    if (isFlexManager && flexBuildingsData != null) {
      return flexBuildingsData.myFlexBuildings.edges.flatMap((edge) => edge.node);
    }
    return buildingsData?.reqMgt.buildings ?? [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildingsData?.reqMgt.buildings, myResidenceData?.profile?.siteProfiles, flexBuildingsData]) as Building[];

  const buildingLevels = useMemo(() => {
    if (isUserResidential) {
      return extractBuildingLevels(
        myResidenceData?.profile?.siteProfiles as ProfileSiteProfileConnection,
        values.buildingUuid ?? "",
      );
    }
    if (isFlexManager && flexLevelData != null) {
      return flexLevelData.myFlexLevels.filter((level) => spacesFilter(level.spaces as Space[]).length > 0);
    }
    return levelsData?.reqMgt.buildingLevels ?? [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [levelsData?.reqMgt.buildingLevels, myResidenceData?.profile?.siteProfiles, flexLevelData]);

  if (buildingsError != null) {
    showError(buildingsError);
  }

  if (flexBuildingsError != null) {
    showError(flexBuildingsError);
  }

  const spaces: Space[] = useMemo(() => {
    const buildingLevel = buildingLevels.find((bl: { uuid: string }) => bl.uuid === values.levelUuid);
    if (buildingLevel == null) {
      return [];
    }
    if (isFlexManager && flexLevelData != null) {
      return flexLevelData.myFlexLevels
        .flatMap((level) =>
          buildingLevel.uuid === level.uuid && spacesFilter(level.spaces as Space[]).length > 0
            ? (level.spaces as Space[])
            : null,
        )
        .filter(notNullOrUndefined);
    }
    const result = spacesFilter(buildingLevel.spaces as BuildingLevel["spaces"]);

    return searchQuery.length > 0
      ? result.filter((space: { name: string }) => space.name.toLowerCase().includes(searchQuery.toLowerCase()))
      : result;
  }, [buildingLevels, searchQuery, values.levelUuid, spacesFilter, flexLevelData, isFlexManager]);

  useEffect(() => {
    if (isUserResidential) {
      myResidenceQuery().catch(showError);
    } else if (isFlexManager) {
      flexBuildingsQuery().catch(showError);
    } else {
      buildingQuery().catch(showError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (values.buildingUuid != null) {
      if (!isUserResidential) {
        if (isFlexManager) {
          flexLevelQuery({
            variables: {
              building: values.buildingUuid,
            },
          }).catch(showError);
        } else {
          levelQuery({ variables: { buildingUuid: values.buildingUuid } }).catch(showError);
        }
      }
      // after space is selected, we need to fetch categories
      if (spaces.length > 0 && values.spaceUuid != null) {
        const space = spaces.find((s) => s.uuid === values.spaceUuid);
        const variables = {
          variables: {
            buildingUuid: values.buildingUuid,
            ownerCompanyUuid: space?.ownerCompany != null ? space.ownerCompany.uuid : undefined,
          },
        };
        categoriesQuery(variables).catch(showError);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.buildingUuid, spaces]);

  const levels = useMemo(() => {
    return searchQuery.length > 0
      ? buildingLevels.filter(
          (level) => level.name.toLowerCase().includes(searchQuery.toLowerCase()) && level.spaces.length > 0,
        )
      : buildingLevels;
  }, [buildingLevels, searchQuery]);
  const categories = useMemo(() => {
    // Only Request Managers which are not property or workplace managers
    if (!isManager && (access.requestManager || isRequestManagerFromQueue)) {
      return (
        // we need to check if manager is part of the queue to display list of available categories
        categoriesData?.reqMgt.categories
          .filter((category) => Boolean(category.queue.viewerRelations.requestManager))
          .filter(notNullOrUndefined) ?? []
      );
    }
    return categoriesData?.reqMgt.categories ?? [];
  }, [categoriesData, isManager, access.requestManager, isRequestManagerFromQueue]);

  const locationName = useMemo(() => {
    const building = buildings.find((b) => b.uuid === values.buildingUuid);
    const level = levels.find((l) => l.uuid === values.levelUuid);
    const space = spaces.find((s) => s.uuid === values.spaceUuid);

    if (building == null || level == null || space == null) {
      return "";
    }

    return `${building.name}, ${level.name}, ${space.name}`;
  }, [buildings, levels, spaces, values]);

  const currentCategory = useMemo(() => {
    return categories.find((c) => c.uuid === values.categoryUuid);
  }, [categories, values]);

  const loading: boolean = useMemo(() => {
    return (
      flexBuildingsLoading ||
      flexLevelLoading ||
      categoriesLoading ||
      levelLoading ||
      buildingsLoading ||
      myResidenceLoading
    );
  }, [categoriesLoading, flexLevelLoading, levelLoading, buildingsLoading, myResidenceLoading, flexBuildingsLoading]);

  return {
    buildings,
    levels,
    spaces,
    categories,
    locationName,
    currentCategory,
    loading,
    searchQuery,
    setSearchQuery,
  };
}
