import { CurrentProfile, Site } from "@equiem/lib";
import type { PropsWithChildren } from "react";
import React, { createContext, useContext, useMemo } from "react";
import { getCreditCurrency } from "../libs/getCreditCurrency";
import { isAutoApproveBookings } from "../libs/isAutoApproveBookings";
import { type BookableResourceFragmentFragment, type BookingFragmentFragment } from "../../../generated/gateway-client";

type Resource = BookableResourceFragmentFragment;
type Booking = BookingFragmentFragment;

export interface BookingModalContentContext {
  resource: Resource;
  booking?: Booking;
  start?: number;
  end?: number;
  timezone: string;
  buildingUuid?: string | undefined | null;
  currency?: string;
  autoApprove: boolean;
}

export const BookingModalInfo = createContext<BookingModalContentContext>({
  resource: {} as unknown as Resource,
  timezone: "Australia/Melbourne",
  autoApprove: false,
});

interface P extends PropsWithChildren {
  resource?: Resource;
  booking?: Booking;
  start?: number;
  end?: number;
}
export const BookingModalInfoProvider: React.FC<P> = ({ resource, booking, start, end, children }) => {
  const site = useContext(Site);
  const { profile } = useContext(CurrentProfile);

  if (booking?.resource == null && resource == null) {
    throw new Error("Missing a resource.");
  }

  const inputResource = booking?.resource ?? resource!;

  const autoApprove = useMemo(
    () => isAutoApproveBookings(inputResource, profile?.companyV2?.uuid),
    [profile?.companyV2?.uuid, inputResource],
  );

  return (
    <BookingModalInfo.Provider
      value={{
        resource: inputResource,
        booking,
        start,
        end,
        timezone: booking?.timezone ?? resource!.building?.timezone ?? site.timezone,
        buildingUuid: inputResource.building?.uuid ?? null,
        currency: getCreditCurrency(inputResource.paymentMethods),
        autoApprove,
      }}
    >
      {children}
    </BookingModalInfo.Provider>
  );
};
