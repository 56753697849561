import {
  BookableResourceBookingApprovalType,
  type BookableResourceFragmentFragment,
} from "../../../generated/gateway-client";

export const isAutoApproveBookings = (resource: BookableResourceFragmentFragment, companyUuid?: string) =>
  resource.viewerPermissions?.canManageBookings === true ||
  resource.bookingApprovalType === BookableResourceBookingApprovalType.AutoForAll ||
  (resource.bookingApprovalType === BookableResourceBookingApprovalType.ManualForCompanies &&
    !resource.manualApprovalCompanies.includes(companyUuid ?? ""));
