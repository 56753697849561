import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { isEmpty } from "lodash";

import { CurrentProfile, stringIsEmpty, stringNotEmpty, useSaferFormikContext, useShowError } from "@equiem/lib";
import type { ProfileFragmentFragment } from "@equiem/lib/generated/gateway-client";
import { useTranslation } from "@equiem/localisation-eq1";
import { Form, Skeleton, useTheme } from "@equiem/react-admin-ui";

import { HumanTag } from "../../components/HumanTag";
import { ProfileTag } from "../../components/ProfileTag";
import { useListProfilesQuery } from "../../generated/requests-client";
import type { NewRequestFormValues } from "../create-request/NewRequest";

export const NewRequestReporter = ({ disabled }: { disabled: boolean }) => {
  const showError = useShowError();
  const theme = useTheme();
  const profile = useContext(CurrentProfile);
  const { t } = useTranslation();
  const { values, setFieldValue } = useSaferFormikContext<NewRequestFormValues>();
  const { data, loading, error, refetch } = useListProfilesQuery({
    fetchPolicy: "network-only",
    skip: isEmpty(profile.siteProfile?.site.destination?.uuid),
    variables: {
      page: {
        first: 5,
      },
      filter: {
        active: true,
      },
      siteUuids: [profile.siteProfile?.site.destination?.uuid ?? ""],
    },
  });

  const isRequestReporterDisabled =
    stringIsEmpty(values.space?.uuid) || stringIsEmpty(values.category) || stringIsEmpty(values.status);

  useEffect(() => {
    if (error != null) {
      showError(error);
    }
  }, [error]);

  const searchCb = useCallback(
    async (keyword: string) => {
      const kl = keyword.toLowerCase();
      const filter = kl.length === 0 ? {} : kl.includes("@") ? { email: kl } : { name: kl, email: kl };

      try {
        await refetch({ filter: { active: true, ...filter } });
      } catch (e: unknown) {
        showError(e);
      }
    },
    [refetch, showError],
  );

  const options = useMemo(() => {
    const lists = (data?.listProfiles.edges ?? []).flatMap((edge) => {
      if (edge.node == null || values.reporter?.uuid === edge.node.uuid) {
        return [];
      }
      const params = {
        profileIconUrl: edge.node.avatar ?? undefined,
        companyName: edge.node.companyV2?.name ?? "",
        email: edge.node.email,
        firstName: edge.node.firstName ?? "",
        lastName: edge.node.lastName ?? "",
      };

      return [
        {
          label: <ProfileTag {...params} />,
          facadeLabel: <ProfileTag {...params} hideDetails />,
          value: edge.node.uuid,
        },
      ];
    });

    if (values.reporter == null) {
      return lists;
    }

    const reporterP = {
      profileIconUrl: values.reporter.avatar ?? undefined,
      companyName: values.reporter.companyV2?.name ?? "",
      email: values.reporter.email,
      firstName: values.reporter.firstName ?? "",
      lastName: values.reporter.lastName ?? "",
    };

    return [
      {
        label: <ProfileTag {...reporterP} />,
        facadeLabel: <ProfileTag {...reporterP} hideDetails />,
        value: values.reporter.uuid,
      },
    ].concat(lists);
  }, [data?.listProfiles.edges, values.reporter]);

  if (loading) {
    return <Skeleton.Line width="100%" height="36px" />;
  }

  return (
    <>
      <div>
        <Form.DynamicSelect
          size="sm"
          search
          noneLabel={<HumanTag size="18px" text={t("requests.newRequest.selectReporter")} />}
          searchPlaceholder={t("common.findAPerson")}
          searchCb={searchCb}
          disabled={disabled || isRequestReporterDisabled}
          showSelectedOnList
          mobileView="minimal"
          name="rreporter"
          showChrome
          value={values.reporter?.uuid ?? ""}
          options={options}
          onChange={(event) => {
            const uuid = event.target.value;
            if (stringNotEmpty(uuid) && uuid !== values.reporter?.uuid) {
              void setFieldValue(
                "reporter",
                (data?.listProfiles.edges.find((edge) => edge.node?.uuid === uuid)?.node ?? undefined) as
                  | ProfileFragmentFragment
                  | undefined,
              );
            }
          }}
        />
      </div>
      <style jsx>{`
        div {
          margin-left: -${theme.spacers.s3};
        }
      `}</style>
    </>
  );
};
