import React, { useMemo } from "react";

import { stringIsEmpty, stringNotEmpty, useSaferFormikContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Form, Skeleton, useTheme } from "@equiem/react-admin-ui";

import { AssigneeTag } from "../../components/AssigneeTag";
import { ProfileTag } from "../../components/ProfileTag";
import type { Profile } from "../../generated/requests-client";
import { useRequestAssigneesQuery } from "../../generated/requests-client";
import type { NewRequestFormValues } from "../create-request/NewRequest";

export const NewRequestAssignee = ({ disabled }: { disabled: boolean }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const { values, setFieldValue } = useSaferFormikContext<NewRequestFormValues>();
  const { data, loading } = useRequestAssigneesQuery({
    fetchPolicy: "network-only",
    variables: { buildingUuid: values.buildingUuid as string, queueUuid: values.queue as string },
    skip: stringIsEmpty(values.space?.uuid),
  });
  const isRequestAssigneeDisabled =
    stringIsEmpty(values.space?.uuid) || stringIsEmpty(values.category) || stringIsEmpty(values.status);

  const options = useMemo(() => {
    const lists = (data?.reqMgt.assignees ?? []).flatMap((assignee) => {
      const params = {
        profileIconUrl: assignee.avatar ?? undefined,
        companyName: assignee.companyV2?.name ?? "",
        email: assignee.email,
        firstName: assignee.firstName ?? "",
        lastName: assignee.lastName ?? "",
      };

      return [
        {
          label: <ProfileTag {...params} />,
          facadeLabel: <ProfileTag {...params} hideDetails />,
          searchKeywords: [`${params.firstName} ${params.lastName}`, params.email],
          value: assignee.uuid,
        },
      ];
    });

    if (values.assignee == null) {
      return lists;
    }

    const params = {
      profileIconUrl: values.assignee.avatar ?? undefined,
      companyName: values.assignee.companyV2?.name ?? "",
      email: values.assignee.email,
      firstName: values.assignee.firstName ?? "",
      lastName: values.assignee.lastName ?? "",
    };

    return [
      {
        label: <ProfileTag {...params} />,
        facadeLabel: <ProfileTag {...params} hideDetails />,
        searchKeywords: [`${params.firstName} ${params.lastName}`, params.email],
        value: values.assignee.uuid,
      },
    ].concat(lists.filter((list) => list.value !== values.assignee?.uuid));
  }, [data?.reqMgt.assignees, values.assignee]);

  if (loading) {
    return <Skeleton.Line width="250px" height="50px" />;
  }

  return (
    <>
      <div>
        <Form.DynamicSelect
          mobileView="minimal"
          size="sm"
          search
          className="assignee"
          searchPlaceholder={t("common.findAPerson")}
          disabled={disabled || isRequestAssigneeDisabled}
          showSelectedOnList
          name="rassignee"
          showChrome
          value={values.assignee?.uuid ?? ""}
          noneLabel={<AssigneeTag size="18px" />}
          options={options}
          onChange={(event) => {
            const uuid = event.target.value;
            if (stringNotEmpty(uuid) && uuid !== values.assignee?.uuid) {
              // Update function already handle the nice catch.
              void setFieldValue("assignee", data?.reqMgt.assignees.find((item) => item.uuid === uuid) as Profile);
            }
          }}
        />
      </div>
      <style jsx>{`
        div {
          margin-left: -${theme.spacers.s3};
        }
      `}</style>
    </>
  );
};
