import { useContext, useMemo } from "react";

import { CurrentRole, Role, useSiteContext } from "@equiem/lib";

import { useDestinationBuildingsAccessQuery, useQueuesListQuery } from "../../../generated/requests-client";

export interface RequestsAccess {
  buildings: Array<{ uuid: string; name: string }>;
  propertyManager: boolean;
  workplaceManager: boolean;
  requestManager: boolean;
  requestAssignee: boolean;
  showAssignee: boolean;
  canReportRequests: boolean;
}

export function useRequestBuildingPermission() {
  const { uuid } = useSiteContext();
  const { currentRole } = useContext(CurrentRole);
  const { data, loading, error } = useDestinationBuildingsAccessQuery({
    variables: { uuid },
  });
  const { data: queuesForUserData } = useQueuesListQuery({
    fetchPolicy: "cache-and-network",
  });
  const isRequestManagerFromQueue = useMemo(
    () => queuesForUserData?.reqMgt.queues.some((queue) => queue.viewerRelations.requestManager) ?? false,
    [queuesForUserData],
  );

  const accessInfo = useMemo<RequestsAccess | null>(() => {
    if (data?.destination == null) {
      return null;
    }

    const buildings = data.destination.buildings ?? [];

    const access = {
      propertyManager: currentRole === Role.PropertyManager,
      workplaceManager: currentRole === Role.WorkplaceManager,
      requestManager: buildings.some((b) => b.viewerRelations.requestManager) || isRequestManagerFromQueue,
      requestAssignee: buildings.some((b) => b.viewerRelations.requestAssignee),
      canReportRequests: data.destination.viewerRelations.company.canReportRequests,
    };

    const info = {
      ...access,
      showAssignee: access.propertyManager || (!access.workplaceManager && access.requestManager),
    };

    if (access.propertyManager || access.workplaceManager) {
      return { buildings: buildings, ...info };
    }

    if (access.requestManager || access.requestAssignee) {
      return {
        buildings: buildings.filter((b) => b.viewerRelations.requestManager || b.viewerRelations.requestAssignee),
        ...info,
      };
    }

    return { buildings: [], ...info };
  }, [currentRole, data?.destination, isRequestManagerFromQueue]);

  return {
    loading,
    error,
    accessInfo,
  };
}
